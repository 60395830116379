
  import userCanSee from '@/middlewares/canSee'
  import { computed, defineComponent, ref, onMounted } from 'vue'
  import Unauthorized from '@/views/auth/unauthorized.vue'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    setup() {
      const route = useRoute()
      const isLoading = ref(true)
      const canAccess = computed(() => userCanSee(route))
      onMounted(() => {
        setTimeout(() => {
          isLoading.value = false
        }, 1000)
      })
      return { Unauthorized, canAccess, isLoading }
    }
  })
