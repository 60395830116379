import { NavigationGuardWithThis } from 'vue-router'
import store from '@/store'
import { getFormStorage } from '@/services/Storage'
import { storageKeys } from '../utils/global'

const checkCompanyExist: NavigationGuardWithThis<undefined> = async (
  to,
  from,
  next
) => {
  try {
    let companyId =
      to.params.id ||
      getFormStorage(storageKeys.currentCompany) ||
      localStorage.getItem(storageKeys.currentCompany)

    console.log('companyId', companyId)

    const companies = store.getters['organizations/companies']
    if (!companyId && companies) {
      companyId = companies[0]?.id
    }

    if (to.params.id === companyId) {
      return next()
    } else if (companyId && companyId !== 'uplaw') {
      return next({
        name: to.name?.toString(),
        params: { id: companyId },
        query: { ...to.query }
      })
    } else {
      next()
    }
  } catch (error) {
    console.error(error)
  }
}

export default checkCompanyExist
