<template>
  <div class="event-documents">
    <h5 class="text-left pb-4 text-sm">
      {{ $t('events.elements.commonDocuments') }}
    </h5>

    <div
      v-if="isLoading"
      class="w-full h-full flex justify-center items-center"
    >
      <Loader />
    </div>
    <data-table
      v-else-if="documents.length > 0"
      :columns="columns"
      :rows="documents"
      index-key="id"
    >
      <template #name="{ row }">
        <h5 class="font-normal text-sm">{{ row?.docSubCategory?.name }}</h5>
        <p class="font-normal text-sm">{{ row.name || row.label }}</p>
      </template>
      <template #actions="slotProps">
        <div class="flex justify-end">
          <Button
            variant="secondary"
            size="medium"
            class="p-1 rounded-sm view-btn mr-2"
            label=""
            @click="handleDocumentView(slotProps.row)"
          >
            <i class="bi bi-eye text-xl"></i>
          </Button>
          <el-popconfirm
            :confirm-button-text="$t('confirm.yes')"
            :cancel-button-text="$t('confirm.no')"
            :title="$t('global.confirmDelete')"
            @confirm="removeDocument(slotProps.row)"
          >
            <template #reference>
              <Button
                variant="secondary"
                size="medium"
                class="p-1 rounded-sm action-btn"
              >
                <i class="bi bi-trash text-xl"></i>
              </Button>
            </template>
          </el-popconfirm>
        </div>
      </template>
    </data-table>
    <div
      v-if="documents.length > 0 && isOverLimit"
      class="flex justify-end items-end"
    >
      <h5 class="pt-3 px-2 text-md text-primary4">
        <router-link
          :to="{
            name: isPortfolio ? 'company.eventDetails' : 'company.eventDetails',
            params: { eventId: event?.id }
          }"
          >...{{ $t('documents.seeMore') }}</router-link
        >
      </h5>
    </div>
    <div v-else-if="documents.length == 0 && !isLoading" class="flex">
      <h5>{{ $t('documents.noEventDoc') }}</h5>
    </div>
    <event-part-linked-documents
      v-if="parts.length > 0"
      :event="event"
      :only-display="true"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, toRefs, ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import { CompanyModel, Document, Event } from '@/services/api/models'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { ElPopconfirm } from 'element-plus'
  import EventPartLinkedDocuments from '@/components/Event/EventPartLinkedDocuments.vue'
  import { Pagination } from '@/store/modules/Organization/models'
  import Loader from '@/components/Loader/index.vue'
  export default defineComponent({
    name: 'EventDocumentsCard',
    components: {
      Button,
      Loader,
      DataTable,
      ElPopconfirm,
      EventPartLinkedDocuments
    },
    props: {
      event: {
        type: Object as () => Event,
        default: null
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },

    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { event } = toRefs(props)
      const { t } = useI18n()
      const isLoading = ref(false)

      const documents = ref<any[]>([])
      const limit = computed<number>(
        () => store.getters['config/eventDocumentsLimit']
      )
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const parts = computed(
        () =>
          event.value?.optionIssuance?.optionIssuanceParts ||
          event.value?.stockIssuance?.stockIssuanceParts ||
          event.value?.conversion?.conversionParts ||
          event.value?.compositionIssuance?.compositionIssuanceParts ||
          event.value?.optionExercise?.optionExerciseParts ||
          event.value?.transfer?.transferParts ||
          event.value?.capitalReduction?.capitalReductionParts ||
          []
      )
      const columns = ref([
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const partsColumns = ref([
        {
          label: t('documents.elements.contact'),
          field: 'contact',
          width: '10%',
          custom: true
        },
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const isOverLimit = computed(
        () => pagination.value.currentPage !== pagination.value.lastPage
      )

      const handleDocumentView = async (doc: Document) => {
        if (currentDocument?.value?.id === doc.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }

      const getEventDocuments = (eventId: string, page = 1) => {
        if (!eventId) return
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: currentCompany.value.id,
          eventId: props.event.id,
          page,
          onSuccess: (response: any) => {
            const { data, metadata } = response
            documents.value = [...data]
            pagination.value = { ...metadata, total: metadata.count }
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const handlePageChange = (page: number) => {
        getEventDocuments(props.event?.id, page)
      }

      const removeDocument = (doc: Document) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: currentCompany.value.id,
          eventId: event.value?.id,
          documentId: doc.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      onMounted(() => {
        getEventDocuments(props.event?.id, 1)
      })
      return {
        handleDocumentView,
        columns,
        getEventDocuments,
        handlePageChange,
        partsColumns,
        isOverLimit,
        parts,
        isLoading,
        documents,
        pagination,
        removeDocument
      }
    }
  })
</script>

<style lang="scss">
  .event-documents {
    .actions {
      padding: 5px !important;

      .action-btn {
        border-radius: 5px !important;
        &:hover {
          @apply bg-error text-white border-error;
        }
      }

      .view-btn {
        border-radius: 5px !important;
      }
    }
    .button {
      color: #fb4b4b;
      .button:hover {
        color: #ffffff !important;
        background-color: #fb4b4b !important;
      }
    }
  }
</style>
