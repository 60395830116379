
  import { AppointmentTermination, Company } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { calculateTimeDuration } from '@/utils/global'
  export default defineComponent({
    name: 'AppointmentTerminationCard',
    components: { ContactBadge },
    props: {
      event: {
        type: Object as () => AppointmentTermination | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const store = useStore()
      const lang = computed(() => store.getters['config/lang'])

      return { lang, moment, calculateTimeDuration }
    }
  })
