<template>
  <p v-if="value === 0 && zeroPresents !== 0">{{ zeroPresents }}</p>
  <p
    v-else-if="value !== undefined"
    :class="`${classes} ${value < 0 ? 'text-error' : ''} ${
      mode === NumberType.percentage && value > 100 ? 'text-error' : ''
    } px-2`"
  >
    {{ formatNumber(value || 0) }} {{ display[mode] }}
  </p>
  <p v-else :class="classes">-</p>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import { formatNumber } from '@/utils/global'
  import { NumberType } from '@/types'
  import { useStore } from 'vuex'

  export default defineComponent({
    props: {
      value: { type: Number, default: 0 },
      zeroPresents: { type: [String, Number], required: false, default: 0 },
      mode: { type: String as () => NumberType, default: NumberType.price },
      classes: { type: String, default: 'text-left pl-1' }
    },
    setup() {
      const store = useStore()
      const currency = computed(() => store.getters['organizations/currency'])

      const display = ref({
        [NumberType.percentage]: '%',
        [NumberType.price]: currency.value,
        [NumberType.quantity]: ''
      })
      return { formatNumber, NumberType, display }
    }
  })
</script>
