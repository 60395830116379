import { addToStorage } from '@/services/Storage'
import router from '@/router'
import { MutationTree } from 'vuex'
import { storageKeys } from '@/utils/global'
import {
  IndividualContact,
  ProfessionalContact,
  InvestorContact,
  CompanyCommittee,
  StockAlias,
  OptionStockAlias,
  CompositionStockAlias,
  CompanyVesting,
  UpFunction,
  Delegation,
  Transfer,
  VestingSuspension,
  VestingAcceleration,
  VestingDelayProrogation,
  FinancialEnding,
  StockIssuance,
  OptionIssuance,
  CompanyCapitalByGroup,
  CompanyCapitalByStockCategory
} from '@/services/api/models/company'
import { STOCKTYPE } from '@/types/config'
import { STOCKTYPESALIAS } from '@/types/forms'
import { AccountApproval, CommonCeiling, Document } from '@/services/api/models'
import { NominalValue, CompanyModel, Event } from '@/services/api/models'
import { CompanyGroup } from '@/services/api/models/company'
import {
  StockGroup,
  CapitalReduction,
  Operation
} from '@/services/api/models/company'
import { ucFirst } from '@/utils/global'
import {
  SET_CONVERSIONS,
  IS_EXPORTING,
  SET_CONTACT_CAPTABLE,
  SET_CONTEXT_COMPANY,
  INIT_PART_DOCUMENTS,
  SET_PROFIT_SHARING_PLANS,
  SET_PROFIT_SHARING_PLAN,
  INIT_CURRENT_CONTACT_PART_DOCUMENTS,
  SET_CURRENT_PART_CONTACT_ID,
  SET_ACCOUNTS_PAGINATION_PAGE,
  SET_DOCUMENTS_GETTING,
  SET_CONTACT_PICTURE,
  SET_DELEGATION_PAGINATION_PAGE,
  SET_BODAC,
  SET_NOMINAL_VALUES,
  SET_DELEGATED_TRANSACTIONS,
  SET_DELEGATED_TRANSACTION_PAGINATION_PAGE,
  SET_FILTERED_CONTACTS,
  SET_NOMINATIONS_PAGINATION_PAGE,
  LOADING_COMPANY_DATA,
  LOADING_USER_DATA,
  SET_CONTACT_DETAILS,
  SET_VESTING_SYNTHESES,
  DocumentResult,
  IS_DOCUMENT_SEARCHING,
  SET_TEXT_SEARCHED,
  SET_EVENT,
  SET_EXERCISE_CALENDARS,
  SET_CONTACT_EXERCISE_CALENDARS,
  SET_DOCUMENTS_LOADING,
  SET_EVENTS_PAGINATION_PAGE,
  SET_CAPITAL_REDUCTION,
  SET_EVENTS_LOADING,
  SET_COMPANY_STOCKS_GROUPS,
  SET_CURRENT_STOCK_GROUP,
  SET_COMMITTEES_PAGINATION_PAGE,
  SET_LOADER_COMPANIES,
  SET_COMPANIES_PAGINATION_PAGE,
  SET_CAPTABLE_PAGINATION_PAGE,
  SET_COMPANY_GROUPS,
  SET_CURRENT_CONTACT_GROUP,
  SET_TRANSACTIONS_PAGE,
  SET_DOC_SEARCH_RESULT,
  SET_DOCUMENTS_PAGINATION_PAGE,
  SET_STOCK_ALIAS,
  SET_OPTIONS_STOCK_ALIAS,
  SET_COMPANY_SINGLE_DOCUMENT,
  SET_CURRENT_CONTACT,
  SET_CONTACT_AUDITS,
  SET_EVENT_AUDITS,
  SET_DOCUMENT_AUDITS,
  SET_CEILINGS,
  SET_FUNCTIONS,
  SET_NOMINATIONS,
  SET_TRANSFER,
  SET_DELEGATIONS,
  SET_VESTING_SUSPENSION,
  SET_VESTING_ACCELERATION,
  SET_VESTING_PROROGATION,
  SET_EVENTS,
  SET_PRIMARY_STOCK,
  SET_PRIMARY_OPTIONS,
  SET_PRIMARY_COMPOSITION,
  SET_FINANCIAL_ENDING,
  SET_APPROVAL,
  SET_FUND_RAISING_ROUNDS,
  SET_FUND_RAISING_ROUND,
  INIT_CONTACTS_PICTURE,
  SET_COMPANY_STOCKS_DATA,
  SET_ACCOUNT_USERS,
  ATTACH_FILES_TO_EVENT,
  SET_AUDIT_PAGINATION,
  SET_COMPANY_USERS,
  SET_NOMINAL_VALUE,
  SET_COMPANY_TRANSACTIONS,
  SET_CAPTABLE,
  CompanyStock,
  OrganizationMutations,
  OrganizationState,
  SET_COMPANIES,
  SET_COMPANY,
  SET_COMPANY_CONTACTS,
  SET_COMPANY_DOCUMENTS,
  SET_COMPANY_COMMITTEES,
  SET_COMPANY_STOCKS,
  SET_COMPANY_UUID,
  SET_COMPANY_VESTINGS,
  SET_LOADING,
  SET_MODAL_FORM,
  SET_COMPOSITION_STOCK_ALIAS,
  SET_CURRENT_STOCK,
  SET_CURRENT_COMMITTEE,
  SET_CURRENT_VESTING,
  SET_OPERATIONS,
  SET_OPERATION,
  SET_COMPANY_CAPITAL_BY_GROUP,
  SET_COMPANY_CAPITAL_BY_STOCK_CATEGORY,
  IS_LOADING_VESTING,
  SET_COMPANY_CAPITAL_BY_ESOP_CATEGORY,
  SET_CURRENT_ORGANIGRAM,
  SET_SEARCHED_EVENTS,
  SET_EVENTS_SEARCHING
} from './models'
import { Conversion } from '@/services/api/models/events'
import { FundRaisingRound } from '@/services/api/models/company'
import {
  SET_FUNDING_DATA,
  ADD_OPTION_ALIAS,
  PUSH_COMPANY_CONTACTS
} from './models'
import {
  FundingData,
  VestingSynthesis
} from '../../../services/api/models/company'
import {
  SET_EXERCISE_CALENDARS_TOTAL,
  SET_EXERCISE_CALENDARS_PAGE
} from './models'
import {
  SET_PROFIT_SHARING_EVOLUTION,
  GET_ESOP_DATA,
  SET_ESOP_DATA
} from './models'
import {
  CompanyProfitSharingItem,
  EsopData
} from '../../../services/api/models/company'
import {
  SET_COMPANY_LOGO,
  INIT_COMPANIES_LOGOS,
  SET_USER_PROFILE_IMAGE,
  INIT_USERS_PROFILE_IMAGES
} from './models'
import {
  CompanyGlobalStockData,
  ProfitSharingPlan
} from '@/services/api/models/company'
import { alertMutations } from './alertMutations'
import {
  UPDATE_OPTIONS_STOCK_ALIAS,
  APPEND_DELEGATIONS,
  UPDATE_CONTACT_EXERCISE_CALENDARS
} from './models'
import { IS_LOADING_SINGLE_STOCK } from './models'
import { freeSharesMutations } from './freeSharesMutations'

export const PRIMARY = 'primary5'
export const SECONDARY = 'warning'
export const TERTIARY = 'success'

export const mutations: MutationTree<OrganizationState> &
  OrganizationMutations = {
  [SET_COMPANIES](state: OrganizationState, result: any) {
    state.companies = [...result.data]
    state.companiesPagination.currentPage = result.metadata.currentPage
    state.companiesPagination.lastPage = result.metadata.lastPage
    state.companiesPagination.total = result.metadata.count
  },
  [SET_LOADER_COMPANIES](state: OrganizationState, list: CompanyModel[]) {
    state.loaderCompanies = [...list]
  },
  [SET_COMPANY](state: OrganizationState, company: CompanyModel) {
    state.company = { ...company }
  },
  [SET_CONTEXT_COMPANY](state: OrganizationState, company: CompanyModel) {
    addToStorage(storageKeys.currentCompany, company?.id || '')
    addToStorage(storageKeys.currentCompanyName, company?.name || '')

    state.contextCompany = { ...company }

    if (
      !['accountCompanies.details'].includes(
        router.currentRoute.value.name?.toString() || ''
      )
    )
      router.push({
        name: router.currentRoute.value.name?.toString(),
        params: {
          ...router.currentRoute.value.params,
          id: state.contextCompany.id
        }
      })
  },
  [SET_LOADING](state: OrganizationState, loading: boolean) {
    state.isLoading = loading
  },
  [SET_DOC_SEARCH_RESULT](state: OrganizationState, data: DocumentResult[]) {
    state.documentSearchResult = data
  },
  [IS_DOCUMENT_SEARCHING](state: OrganizationState, searching: boolean) {
    state.searching = searching
  },
  [SET_COMPANY_CONTACTS](
    state: OrganizationState,
    list: (IndividualContact | ProfessionalContact | InvestorContact)[]
  ) {
    state.contacts = [...list].map((x) => ({ ...x, name: ucFirst(x.name) }))
  },
  [SET_FILTERED_CONTACTS](
    state: OrganizationState,
    list: (IndividualContact | ProfessionalContact | InvestorContact)[]
  ) {
    state.contactsResult = [...list].map((x) => ({
      ...x,
      name: ucFirst(x.name)
    }))
  },
  [SET_COMPANY_VESTINGS](state: OrganizationState, list: CompanyVesting[]) {
    state.vestings = [...list]
  },
  [SET_TEXT_SEARCHED](state: OrganizationState, text: string | null) {
    state.searchedText = text
  },
  [SET_COMPANY_COMMITTEES](state: OrganizationState, list) {
    state.committees = [...list.data].map((x) => ({
      ...x,
      name: x.denomination
    }))
    state.committeesPagination.lastPage = list.metadata.lastPage
    state.committeesPagination.currentPage = list.metadata.currentPage
    state.committeesPagination.total = list.metadata.count
  },
  [SET_CEILINGS](state: OrganizationState, list: CommonCeiling[]) {
    state.ceilings = [...list].map((x) => ({ ...x, name: x?.label }))
  },
  [SET_CONVERSIONS](state: OrganizationState, list: Conversion[]) {
    state.conversions = [...list]
  },
  [SET_NOMINAL_VALUES](state: OrganizationState, list: NominalValue[]) {
    state.nominalValues = [...list]
  },
  [SET_NOMINATIONS](state: OrganizationState, result: any) {
    state.nominations = [...result.data]
    state.nominationsPagination.lastPage = result.metadata.lastPage
    state.nominationsPagination.currentPage = result.metadata.currentPage
    state.nominationsPagination.total = result.metadata.count
  },
  [SET_CURRENT_COMMITTEE](state: OrganizationState, data: CompanyCommittee) {
    state.currentCommittee = data
  },
  [SET_NOMINATIONS_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.nominationsPagination.currentPage = page
  },
  [SET_COMMITTEES_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.committeesPagination.currentPage = page
  },
  [SET_APPROVAL](state: OrganizationState, list: AccountApproval[]) {
    state.approvals = [...list]
  },
  [SET_DELEGATED_TRANSACTIONS](state: OrganizationState, result: any) {
    state.delegatedTransactions = [...result.data]
    state.delegTransactionsPagination = {
      lastPage: result.metadata.lastPage,
      limit: 20,
      currentPage: result.metadata.currentPage,
      total: result.metadata.count
    }
  },
  [SET_FUNCTIONS](state: OrganizationState, list: UpFunction[]) {
    state.functions = [...list]
  },
  [SET_EVENT](state: OrganizationState, event: Event) {
    state.event = event
  },
  [SET_EXERCISE_CALENDARS](state: OrganizationState, payload) {
    state.exerciseCalendars = [...payload.data]
    state.exerciseCalendarPagination = {
      lastPage: payload.metadata?.lastPage,
      limit: 50,
      currentPage: payload.metadata?.currentPage,
      total: payload.metadata?.count
    }
  },
  [SET_EXERCISE_CALENDARS_TOTAL](
    state: OrganizationState,
    payload: VestingSynthesis | null
  ) {
    state.exerciseCalendarsTotal = payload
  },
  [SET_VESTING_SYNTHESES](state: OrganizationState, payload) {
    state.vestingSyntheses = [...payload.data]
    state.exerciseCalendarSynthesisPagination = {
      lastPage: payload.metadata?.lastPage,
      limit: 5,
      currentPage: payload.metadata?.currentPage,
      total: payload.metadata?.count
    }
  },
  [UPDATE_CONTACT_EXERCISE_CALENDARS](
    state: OrganizationState,
    { data, contactId }
  ) {
    if (contactId) {
      state.contactExtraData = {
        ...state.contactExtraData,
        [contactId]: {
          ...state.contactExtraData[contactId],
          options: [...data].map((x) => ({
            ...x.optionAlias,
            exerciseId: x.id
          }))
        }
      }
    }
  },
  [SET_CONTACT_EXERCISE_CALENDARS](
    state: OrganizationState,
    { list, contactId, stocks }
  ) {
    if (contactId) {
      if (list.length) {
        state.contactExtraData = {
          ...state.contactExtraData,
          [contactId]: {
            ...state.contactExtraData[contactId],
            options: [...list].map((x) => ({
              ...x.optionAlias,
              exerciseId: x.id
            }))
          }
        }
      } else
        state.contactExtraData = {
          ...state.contactExtraData,
          [contactId]: {
            ...state.contactExtraData[contactId],
            stocks: stocks
          }
        }
    }
  },
  [SET_CONTACT_CAPTABLE](state: OrganizationState, { data, contactId }) {
    if (contactId) {
      state.contactExtraData = {
        ...state.contactExtraData,
        [contactId]: {
          ...state.contactExtraData[contactId],
          stocks: [...(data[0]?.stocks || [])]
        }
      }
    }
  },
  [SET_DELEGATIONS](state: OrganizationState, list: Delegation[]) {
    state.delegations = [...list]
      .filter((x) => x !== null)
      .map((x) => ({ ...x, name: x?.label }))
  },
  [APPEND_DELEGATIONS](state: OrganizationState, delegs: Delegation[]) {
    const news = delegs
      .filter((y) => y !== null)
      .filter((x) => !state.delegations.map((d) => d.id).includes(x?.id))
    state.delegations = [
      ...state.delegations,
      ...news.map((n) => ({ ...n, name: n?.label }))
    ]
  },
  [SET_PRIMARY_STOCK](state: OrganizationState, list: StockIssuance[]) {
    state.primaryStockEmission = [...list]
  },
  [SET_PRIMARY_OPTIONS](state: OrganizationState, list: OptionIssuance[]) {
    state.primaryOptionsEmission = [...list]
  },
  [SET_PRIMARY_COMPOSITION](state: OrganizationState, list: any[]) {
    state.primaryCompositionEmission = [...list]
  },
  [SET_TRANSFER](state: OrganizationState, list: Transfer[]) {
    state.transfers = [...list]
  },
  [SET_VESTING_SUSPENSION](
    state: OrganizationState,
    list: VestingSuspension[]
  ) {
    state.vestingSuspension = [...list]
  },
  [SET_VESTING_ACCELERATION](
    state: OrganizationState,
    list: VestingAcceleration[]
  ) {
    state.vestingAcceleration = [...list]
  },
  [SET_VESTING_PROROGATION](
    state: OrganizationState,
    list: VestingDelayProrogation[]
  ) {
    state.vestingDelayProrogation = [...list]
  },
  [SET_CAPITAL_REDUCTION](state: OrganizationState, list: CapitalReduction[]) {
    state.capitalReductions = [...list]
  },
  [SET_FINANCIAL_ENDING](state: OrganizationState, list: FinancialEnding[]) {
    state.financialEndings = [...list]
  },
  [SET_COMPANY_STOCKS](state: OrganizationState, list: CompanyStock[]) {
    state.companyStocks = [...list]
  },
  [SET_COMPANY_UUID](state: OrganizationState, list: CompanyStock[]) {
    state.companyStocks = [...list]
  },
  [SET_DOCUMENTS_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.documentsPagination.currentPage = page
  },
  [SET_DELEGATED_TRANSACTION_PAGINATION_PAGE](
    state: OrganizationState,
    page: number
  ) {
    state.delegTransactionsPagination.currentPage = page
  },
  [SET_EXERCISE_CALENDARS_PAGE](state: OrganizationState, page: number) {
    state.exerciseCalendarPagination.currentPage = page
  },
  [SET_DELEGATION_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.delegationsPagination.currentPage = page
  },
  [SET_AUDIT_PAGINATION](state: OrganizationState, page: number) {
    state.auditPagination.currentPage = page
  },
  [SET_COMPANIES_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.companiesPagination.currentPage = page
  },
  [SET_STOCK_ALIAS](state: OrganizationState, data: StockAlias[]) {
    state.stockAliases = [...data].map((x) => ({
      ...x,
      name: x.alias.toUpperCase(),
      alias: x.alias.toUpperCase(),
      type: STOCKTYPE.SAMPLE,
      class: PRIMARY
    }))
  },
  // [ADD_OPTION_ALIAS]( state: OrganizationState,
  //   data: OptionStockAlias[]) {
  //     state.optionStockAliases
  //   },
  [SET_OPTIONS_STOCK_ALIAS](
    state: OrganizationState,
    data: OptionStockAlias[]
  ) {
    state.optionStockAliases = [...data].map((x) => ({
      ...x,
      name: x.alias.toUpperCase(),
      alias: x.alias.toUpperCase(),
      type: STOCKTYPE.OPTION,
      class: SECONDARY
    }))
  },
  [UPDATE_OPTIONS_STOCK_ALIAS](
    state: OrganizationState,
    data: OptionStockAlias[]
  ) {
    const ids = state.optionStockAliases.map((x) => x.id)
    const stocks = data
      .filter((d) => d !== null)
      .filter((o) => !ids.includes(o?.id))
    state.optionStockAliases = [...state.optionStockAliases, ...stocks]
  },
  [SET_COMPOSITION_STOCK_ALIAS](
    state: OrganizationState,
    data: CompositionStockAlias[]
  ) {
    state.compositionStockAliases = [...data].map((x) => ({
      ...x,
      alias: x.alias.toUpperCase(),
      name: x.alias.toUpperCase(),
      type: STOCKTYPE.COMPOSITION,
      class: TERTIARY
    }))
  },
  [SET_CURRENT_STOCK](state: OrganizationState, { data, type }) {
    let stock = data
    if (data) {
      stock = {
        ...data,
        name: data.alias,
        type:
          type === STOCKTYPESALIAS.COMPOSITION
            ? STOCKTYPE.COMPOSITION
            : type === STOCKTYPESALIAS.OPTION
            ? STOCKTYPE.OPTION
            : STOCKTYPE.SAMPLE,
        class: TERTIARY
      }
    }
    state.currentStock = stock
  },
  [IS_LOADING_SINGLE_STOCK](state: OrganizationState, isLoading) {
    state.isLoadingStock = isLoading
  },
  [SET_COMPANY_DOCUMENTS](state: OrganizationState, { list, update = false }) {
    state.documentsPagination = {
      ...list.metadata,
      total: list.metadata.count,
      limit: state.documentsPagination.limit
    }
    if (update) {
      state.documents =
        state.documents.length >= list.metadata.count
          ? state.documents
          : [...state.documents, ...list.data]
    } else {
      state.documents = [...list.data]
    }
    state.documentList = [...state.documentList, ...list.data]
  },
  [SET_COMPANY_SINGLE_DOCUMENT](
    state: OrganizationState,
    document: Document | null
  ) {
    state.currentDocument = document
  },
  [SET_MODAL_FORM](state: OrganizationState, data) {
    state.forms = data
  },
  [SET_COMPANY_USERS](state: OrganizationState, data: any) {
    state.accountsPagination = {
      ...data.metadata,
      total: data.metadata.count,
      limit: state.accountsPagination.limit
    }
    state.accounts = [...data.data]
  },
  [SET_ACCOUNT_USERS](state: OrganizationState, data: any) {
    state.accountsPagination = {
      ...data.metadata,
      total: data.metadata.count,
      limit: state.accountsPagination.limit
    }
    state.accountUsers = [...data.data]
  },
  [SET_ACCOUNTS_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.accountsPagination.currentPage = page
  },
  [SET_COMPANY_VESTINGS](state: OrganizationState, vestings: CompanyVesting[]) {
    state.vestings = [...vestings]
  },
  [SET_EVENTS](state: OrganizationState, { list, update = false }) {
    if (update) {
      state.events = [...state.events, ...list.data]
    } else {
      state.events = [...list.data]
      state.eventsPagination = {
        ...list.metadata,
        total: list.metadata.count,
        limit: 10
      }
    }
  },
  [SET_SEARCHED_EVENTS](state: OrganizationState, { list, update = false }) {
    if (update) {
      state.searchedEvents = [...state.searchedEvents, ...list.data]
    } else {
      state.searchedEvents = [...list.data]
      state.searchedEventsPagination = {
        ...list.metadata,
        total: list.metadata.count,
        limit: 10
      }
    }
  },
  [SET_EVENTS_LOADING](state: OrganizationState, isLoading: boolean) {
    state.loadingEvents = isLoading
  },
  [SET_EVENTS_SEARCHING](state: OrganizationState, isLoading: boolean) {
    state.searchingEvents = isLoading
  },
  [SET_DOCUMENTS_LOADING](state: OrganizationState, isLoading: boolean) {
    state.loadingDocuments = isLoading
  },
  [SET_DOCUMENTS_GETTING](state: OrganizationState, isLoading: boolean) {
    state.gettingDocuments = isLoading
  },
  [SET_CURRENT_VESTING](state: OrganizationState, vesting: CompanyVesting) {
    state.currentVesting = { ...vesting }
  },
  [IS_LOADING_VESTING](state: OrganizationState, vesting: boolean) {
    state.isLoadingVesting = vesting
  },
  [SET_CAPTABLE](state: OrganizationState, captable) {
    state.captable = captable.data
    state.captablePagination.lastPage = captable.metadata.lastPage
    state.captablePagination.currentPage = captable.metadata.currentPage
    state.captablePagination.total = captable.metadata.count
  },
  [SET_EVENTS_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.eventsPagination.currentPage = page
  },
  [SET_CAPTABLE_PAGINATION_PAGE](state: OrganizationState, page: number) {
    state.captablePagination.currentPage = page
  },
  [SET_CURRENT_ORGANIGRAM](state: OrganizationState, organigram: any | null) {
    state.currentOrganigram = organigram
  },
  [SET_CURRENT_CONTACT](
    state: OrganizationState,
    contact: IndividualContact | ProfessionalContact | InvestorContact | null
  ) {
    state.currentContact = contact
  },
  [PUSH_COMPANY_CONTACTS](
    state: OrganizationState,
    contact: IndividualContact | ProfessionalContact | InvestorContact | null
  ) {
    if (contact)
      state.contacts = [
        ...state.contacts,
        { ...contact, name: ucFirst(contact.name) }
      ]
  },
  [SET_CONTACT_DETAILS](
    state: OrganizationState,
    contact: IndividualContact | ProfessionalContact | InvestorContact | null
  ) {
    state.contactDetails = contact
  },
  [SET_CURRENT_CONTACT_GROUP](
    state: OrganizationState,
    group: CompanyGroup | null
  ) {
    state.group = group
  },
  [SET_CONTACT_AUDITS](state: OrganizationState, list: any) {
    state.contactAudits = [...list.data]
    state.auditPagination = {
      total: list.metadata.count,
      currentPage: list.metadata.currentPage,
      lastPage: list.metadata.lastPage,
      limit: 10
    }
  },
  [SET_EVENT_AUDITS](state: OrganizationState, list: any) {
    state.eventAudits = [...list.data]
    state.auditPagination = {
      total: list.metadata.count,
      currentPage: list.metadata.currentPage,
      lastPage: list.metadata.lastPage,
      limit: 10
    }
  },
  [SET_DOCUMENT_AUDITS](state: OrganizationState, list: any) {
    state.documentAudits = [...list.data]
    state.auditPagination = {
      total: list.metadata.count,
      currentPage: list.metadata.currentPage,
      lastPage: list.metadata.lastPage,
      limit: 10
    }
  },
  [ATTACH_FILES_TO_EVENT](state, { files, docId, remove }) {
    if (state.currentPartContactId) {
      let file = null
      let data = files
      if (remove) {
        data = state.eventPartLinkedDocuments[
          state.currentPartContactId
        ].filter((x) => x.id !== docId)
        state.eventPartLinkedDocuments[state.currentPartContactId] = [...data]
      } else file = state.documents.find((x) => x.id === docId)
      if (
        file &&
        !state.eventPartLinkedDocuments[state.currentPartContactId]
          .map((x) => x.id)
          .includes(file.id)
      )
        state.eventPartLinkedDocuments[state.currentPartContactId].push(file)
    } else {
      state.attachedFiles = [...(files ?? [])]
    }
  },
  [SET_TRANSACTIONS_PAGE](state, page) {
    state.transactionsPagination.currentPage = page
  },
  [SET_COMPANY_TRANSACTIONS](state, result) {
    state.transactions = [...result.data]
    state.transactionsPagination = {
      total: result.metadata.count,
      currentPage: result.metadata.currentPage,
      lastPage: result.metadata.lastPage,
      limit: 100
    }
  },
  [SET_NOMINAL_VALUE](state, data: NominalValue) {
    state.nominalValue = data ? data.nominalValue : 0
  },
  [SET_COMPANY_GROUPS](state, groups: CompanyGroup[]) {
    state.groups = [...groups]
  },
  [SET_CURRENT_STOCK_GROUP](state, group: StockGroup | null) {
    state.stockGroup = group
  },
  [SET_COMPANY_STOCKS_GROUPS](state, groups: StockGroup[]) {
    state.stockGroups = [...groups]
  },
  [SET_LOADING](state, loader: boolean) {
    state.isLoading = loader
  },
  [LOADING_COMPANY_DATA](state, loading: boolean) {
    state.loadingCompanyData = loading
  },
  [LOADING_USER_DATA](state, loading: boolean) {
    state.loadingUserData = loading
  },
  [IS_EXPORTING](state, loading: boolean) {
    state.downloading = loading
  },
  [SET_BODAC](state, data: any) {
    state.bodac = data
  },
  [SET_CONTACT_PICTURE](state, { contactId, url }) {
    state.contactsPicture[contactId] = url ? url : null
  },
  [INIT_CONTACTS_PICTURE](state) {
    state.contactsPicture = {}
  },
  [SET_COMPANY_STOCKS_DATA](state, data: CompanyGlobalStockData) {
    state.companyGlobalData = { ...data }
  },
  [SET_COMPANY_CAPITAL_BY_GROUP](state, data: CompanyCapitalByGroup[]) {
    state.companyCapitalByGroup = [...data]
  },
  [SET_COMPANY_CAPITAL_BY_STOCK_CATEGORY](
    state,
    data: CompanyCapitalByStockCategory[]
  ) {
    state.companyCapitalByStockCategory = [...data]
  },
  [SET_COMPANY_CAPITAL_BY_ESOP_CATEGORY](
    state,
    data: CompanyCapitalByStockCategory[]
  ) {
    state.companyCapitalByEsopCategory = [...data]
  },
  [SET_PROFIT_SHARING_EVOLUTION](state, data: CompanyProfitSharingItem[]) {
    state.profitSharingEvolution = [...data]
  },
  [SET_ESOP_DATA](state, data: EsopData) {
    state.esopData = data
  },
  [SET_FUNDING_DATA](state, data: FundingData) {
    state.fundingData = data
  },
  [INIT_CURRENT_CONTACT_PART_DOCUMENTS](state, contactId) {
    state.eventPartLinkedDocuments[contactId] =
      state.eventPartLinkedDocuments[contactId] ?? []
  },
  [SET_CURRENT_PART_CONTACT_ID](state, contactId: string | null) {
    state.currentPartContactId = contactId
  },
  [INIT_PART_DOCUMENTS](state, partId: string) {
    if (partId) state.eventPartLinkedDocuments[partId] = []
    else state.eventPartLinkedDocuments = {}
  },
  [SET_PROFIT_SHARING_PLANS](state, plans: ProfitSharingPlan[]) {
    state.profitSharingPlans = plans
  },
  [SET_PROFIT_SHARING_PLAN](state, plan: ProfitSharingPlan | null) {
    state.currentProfitSharingPlan = plan
  },
  [SET_FUND_RAISING_ROUNDS](state, plans: FundRaisingRound[]) {
    state.fundRaisingRounds = plans
  },
  [SET_FUND_RAISING_ROUND](state, plan: FundRaisingRound | null) {
    state.currentFundRaisingRound = plan
  },
  [SET_OPERATIONS](state, operations: Operation[]) {
    state.operations = operations
  },
  [SET_OPERATION](state, operation: Operation | null) {
    state.currentOperation = operation
  },
  [SET_COMPANY_LOGO](state, { companyId, url }) {
    state.companiesLogo[companyId] = url ? url : null
  },
  [INIT_COMPANIES_LOGOS](state) {
    state.companiesLogo = {}
  },
  [SET_USER_PROFILE_IMAGE](state, { userId, url }) {
    state.usersProfilesImages[userId] = url ? url : null
  },
  [INIT_USERS_PROFILE_IMAGES](state) {
    state.usersProfilesImages = {}
  },
  ...alertMutations,
  ...freeSharesMutations
}
