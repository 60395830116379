<template>
  <div
    :class="`${classes} border border-secondary4 rounded flex flex-col items-start p-3 `"
  >
    <p>
      <strong>{{ $t('vestingBook.endOfContracts.nv') }}</strong
      >: <span>{{ formatNumber(nonExercisableQuantity) }}</span>
    </p>
    <p>
      <strong>{{ $t('vestingBook.endOfContracts.v') }}</strong> :
      <span> {{ formatNumber(exercisableQuantity) }}</span>
    </p>
  </div>
</template>

<script lang="ts">
  import { ExerciseCalendar } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { formatNumber } from '@/utils/global'

  export default defineComponent({
    name: 'StockQuantity',
    props: {
      classes: {
        type: String,
        default: ''
      }
    },
    setup() {
      const store = useStore()
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, v) => (acc += v.exercisableBalance),
          0
        )
      )
      const nonExercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, v) => (acc += v.optionIssuancePart.quantity - v.vestedQuantity),

          0
        )
      )

      return { exercisableQuantity, nonExercisableQuantity, formatNumber }
    }
  })
</script>
