<template>
  <div :class="[{ 'contactCard--extended': noPadding }, ' contactCard']">
    <div class="contactCard__visual">
      <img
        :src="url || `${require('@/assets/images/profile/company.png')}`"
        class="object-cover w-full h-full"
      />
    </div>

    <div class="contactCard__content">
      <div class="contactCard__content__information_lvl-1">
        <div class="flex flex-col items-start">
          <h2 class="contactCard__content__information_lvl-1__name capitalize">
            {{ contact.name }}
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('tooltips.contactInfo')"
              placement="top"
            >
              <span class="ml-2">
                <i
                  :class="`bi bi-${
                    display ? 'info-circle' : 'info-circle-fill'
                  } text-xl text-primary4`"
                  @click="display = !display"
                ></i>
              </span>
            </el-tooltip>
          </h2>
          <div
            v-if="contact.group"
            class="contactCard__content__information_lvl-1__role"
          >
            {{ contact.group.name }}
          </div>
        </div>
        <ActionList
          v-can.any="['admin', 'write-contacts', 'employee-writeContact']"
          class="right"
          :actions="actionsListItems"
          @on-click="handleAction"
          icon-class="bi-three-dots text-md text-primary3"
        />
      </div>

      <transition name="collapse">
        <div v-show="display" class="contactCard__content__information_lvl-2">
          <div
            class="
              row
              contactCard__content__information_lvl-2__infos
              flex
              justify-between
              w-5/6
            "
          >
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.formType') }}</label>
              <p v-if="contact.legalStatus">
                {{ contact.legalStatus.legalStatus }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.denomination') }}</label>
              <p v-if="contact.name">
                {{ contact.name }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.representedBy') }}</label>
              <router-link
                v-if="contact.representativeContact"
                :to="{
                  name: 'company.directory',
                  params: {
                    id: currentCompany.id,
                    contactId: contact.representativeContact.id
                  }
                }"
              >
                <ContactBadge
                  :contact="contact.representativeContact"
                  class="flat light"
                  type="light"
                />
              </router-link>
              <i v-else class="bi-dash" />
            </div>
            <div class="col form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.address') }}</label>
              <p v-if="contact.address">
                {{ contact.address }} - {{ contact.postalCode }}
                {{ contact.city }}
              </p>
              <i v-else class="bi-dash" />
            </div>
          </div>

          <div
            class="
              row
              contactCard__content__information_lvl-2__infos
              flex
              justify-between
            "
          >
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.commercialName') }}</label>
              <p v-if="contact.tradeName">
                {{ contact.tradeName }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label class="text-left">{{
                $t('contactBook.fields.constitutedAt')
              }}</label>
              <p v-if="contact.birthDate" class="text-left">
                {{ moment.utc(contact.birthDate).format('LL') }}
              </p>
              <p class="text-left">-</p>
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.phoneNumber') }}</label>
              <p v-if="contact.phoneNumber">
                {{ contact.phoneNumber }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.email') }}</label>
              <p v-if="contact.email">
                <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
              </p>
              <i v-else class="bi-dash" />
            </div>
          </div></div
      ></transition>
    </div>
  </div>
</template>

<script lang="ts">
  import { useStore } from 'vuex'

  import ContactBadge from '@/components/ContactBadge/index.vue'
  import ActionList from '@/components/ActionList/index.vue'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import moment from 'moment'
  import { CompanyModel } from '@/services/api/models1'
  import { MAINACTIONS, ActionListItem } from '@/types'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { useAcl } from 'vue-simple-acl/src'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'ContactCard',
    components: {
      ActionList,
      ContactBadge,
      ElTooltip
    },
    directives: {},
    props: {
      contact: {
        type: Object,
        required: true
      },
      noPadding: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['delete-contact'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const router = useRouter()
      const store = useStore()
      const route = useRoute()
      const url = ref('')
      const display = ref(false)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const acl = useAcl()
      const canDelete = computed(
        () =>
          acl.role('admin') ||
          acl.permission('is-manager') ||
          acl.permission('write-contacts')
      )
      const actionsListItems = ref<ActionListItem[]>([
        {
          name: t('global.actions.edit'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.EDIT_CONTACT,
          type: ''
        }
      ])

      if (canDelete.value) {
        actionsListItems.value.push({
          name: t('global.actions.delete'),
          action: 'delete',
          children: [],
          icon: '',
          key: MAINACTIONS.DELETE_CONTACT,
          type: ''
        })
      }
      const openContactModal = () => {
        router.push({
          name: 'company.contact',
          params: {
            id: currentCompany.value?.id,
            contactId: route.params.contactId
          }
        })
      }
      const handleAction = (key: MAINACTIONS) => {
        if (key === MAINACTIONS.EDIT_CONTACT) {
          openContactModal()
        } else if (key === MAINACTIONS.DELETE_CONTACT) {
          deleteContact()
        }
      }

      const deleteContact = () => {
        emit('delete-contact', props.contact.id)
      }
      const loadImage = async () => {
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_PICTURE',
          {
            companyId: currentCompany.value.id,
            contactId: props.contact.id
          }
        )
        if (response) url.value = response
        else url.value = ''
      }
      watch(
        () => props.contact,
        async () => {
          await loadImage()
        }
      )
      onBeforeMount(async () => {
        await loadImage()
      })
      return {
        moment,
        url,
        display,
        currentCompany,
        actionsListItems,
        handleAction
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'contactCard.scss';
</style>
