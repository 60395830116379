<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >
    <Field v-slot="{ field }" v-model="formValue" :name="data.name">
      <Multiselect
        :value="formValue"
        :name="data.name"
        :show-labels="true"
        mode="single"
        v-bind="field"
        :options="ceilings"
        :placeholder="$t('events.elements.selectCeiling')"
        :groups="false"
        class="w-full"
        group-label="label"
        label="label"
        :open-direction="openDirection"
        @search-change="searchText = $event"
        @change="updateValue"
      >
        <template #noOptions>
          <span v-if="false"> {{ $t('events.elements.noCeiling') }} </span>
          <span v-else>{{ $t('events.elements.search') }}</span>
        </template>

        <template #noResults>
          <span>{{ $t('global.placeholder.noResult') }}</span>
        </template>
      </Multiselect>
    </Field>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    onBeforeMount,
    watch
  } from 'vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { CommonCeiling } from '@/services/api/models'
  import { Field } from 'vee-validate'
  import { useRoute } from 'vue-router'
  export default defineComponent({
    name: 'DropdownCeilings',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      allData: {
        default: () => ({}),
        type: Object
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      value: {
        type: String,
        default: ''
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'top'
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { data } = toRefs(props)

      const formValue = ref('')
      const searchText = ref('')

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      const ceilings = computed<CommonCeiling[]>(
        () => store.getters['organizations/ceilings']
      )

      const updateValue = (value: any) => {
        formValue.value = value
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      watch(
        () => props.value,
        (value: string) => {
          formValue.value = value
        }
      )

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_CEILINGS', {
          companyId: currentCompany.value?.id || route.params.id
        })
      })

      return {
        formValue,
        searchText,
        ceilings,
        updateValue
      }
    }
  })
</script>
