
  import { Company, Transfer } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { Tag } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'

  export default defineComponent({
    name: 'TransferCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => Transfer | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const store = useStore()
      const currency = computed(() => store.getters['organizations/currency'])
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const globalUnitPrice = computed(() => {
        return (props.event?.transferParts || []).reduce(
          (sale, p) => (sale += p.totalAmount / p.quantity),
          0
        )
      })
      const globalSalePrice = computed(() => {
        return (props.event?.transferParts || []).reduce(
          (sale, p) => (sale += p.totalAmount),
          0
        )
      })
      const totalQuantity = computed(() => {
        return (props.event?.transferParts || []).reduce(
          (sale, p) => (sale += p.quantity),
          0
        )
      })

      return {
        totalQuantity,
        globalUnitPrice,
        globalSalePrice,
        currency,
        contactsId,
        moment,
        formatNumber
      }
    }
  })
