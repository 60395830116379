
  import {
    StockGroup,
    CompanyModel,
    ProfitSharingPlan
  } from '@/services/api/models'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') &&
            acl.permission('write-profitSharingPlan'))
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const plan = computed<ProfitSharingPlan | null>(
        () => store.getters['organizations/profitSharingPlan']
      )

      const getPlans = (search = '') => {
        store.dispatch('organizations/GET_PROFIT_SHARING_PLANS', {
          companyId: currentCompany.value.id,
          filter: { search }
        })
      }

      const plans = computed<ProfitSharingPlan[]>(
        () => store.getters['organizations/profitSharingPlans']
      )

      const initForm = () => {
        store.commit('organizations/SET_PROFIT_SHARING_PLAN', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (planId: string) => {
        store.dispatch('organizations/GET_PROFIT_SHARING_PLAN', {
          companyId: currentCompany.value.id,
          id: planId
        })
      }

      onBeforeMount(() => {
        getPlans()
      })

      watch(search, (text: string) => {
        getPlans(text)
      })

      return { canDo, plans, search, plan, initForm, handleSelect }
    }
  })
