
  import { useStore } from 'vuex'
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { auditTableColumns } from '@/utils/data'
  import DocumentAudit from '@/components/Audit/Document.vue'
  import ContactAudit from '@/components/Audit/Contact.vue'
  import EventAudit from '@/components/Audit/Event.vue'
  import { CompanyModel } from '@/services/api/models1'
  import { AuditForm, AuditStatusType, AuditType } from '@/types'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import UplTab from '@/components/Tab/index.vue'

  export default defineComponent({
    name: 'Audit',
    components: {
      UplTab
    },
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const currentTab = ref('doc_tab')

      const documentAudits = computed(
        () => store.getters['organizations/documentAudits']
      )

      const eventAudits = computed(
        () => store.getters['organizations/eventAudits']
      )

      const contactAudits = computed(
        () => store.getters['organizations/contactAudits']
      )

      const companyData = computed(() => ({
        documents: documentAudits.value,
        contacts: contactAudits.value,
        events: eventAudits.value
      }))

      const columns = ref(auditTableColumns)
      const pagination = computed(
        () => store.getters['organizations/auditPagination']
      )
      const onPageChange = (page: number, type: AuditType) => {
        store.commit('organizations/SET_AUDIT_PAGINATION', page)
        if (type === AuditType.DOCUMENT) getDocumentAudits()
        if (type === AuditType.CONTACT) getContactAudits()
      }
      const getDocumentAudits = () =>
        store.dispatch('organizations/GET_DOCUMENT_AUDITS', {
          companyId: route.params.id
        })

      const getEventAudits = () =>
        store.dispatch('organizations/GET_EVENT_AUDITS', {
          companyId: route.params.id
        })

      const getContactAudits = () =>
        store.dispatch('organizations/GET_CONTACT_AUDITS', {
          companyId: route.params.id
        })

      const tabList = [
        { key: 'events', langKey: 'events', component: EventAudit },
        { key: 'documents', langKey: 'documents', component: DocumentAudit },
        { key: 'contacts', langKey: 'contacts', component: ContactAudit }
      ]

      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      onMounted(() => {
        getDocumentAudits()
        getContactAudits()
        getEventAudits()
      })

      const updateAudit = (
        type: AuditType,
        id: string,
        auditId: string,
        formData: AuditForm
      ) => {
        store.dispatch('organizations/UPDATE_AUDIT', {
          companyId: company.value.id,
          type,
          typeDataId: id,
          auditId,
          formData,
          onSuccess: () => {
            if (type === AuditType.DOCUMENT) getDocumentAudits()
            if (type === AuditType.CONTACT) getContactAudits()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.context'),
              message: t(`audits.editSuccess`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.context'),
              message: t(`audits.editError`)
            })
          }
        })
      }

      return {
        currentTab,
        documentAudits,
        eventAudits,
        contactAudits,
        columns,
        tabList,
        company,
        companyData,
        pagination,
        updateAudit,
        onPageChange
      }
    }
  })
