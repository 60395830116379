
  import { defineComponent, ref } from 'vue'
  import Tab from '@/components/Tab/index.vue'
  import ByDelegationTab from './tabs/ByDelegationTab.vue'
  import ByHolderTab from './tabs/ByHolderTab.vue'
  export default defineComponent({
    components: { Tab },
    setup() {
      const tabList = [
        {
          key: 'byDelegate',
          langKey: 'byDelegate',
          component: ByDelegationTab
        },
        { key: 'byHolder', langKey: 'byHolder', component: ByHolderTab }
      ]
      const tabData = ref({
        capital: []
      })
      return { tabData, tabList }
    }
  })
