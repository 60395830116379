import * as yup from 'yup'

export const documentSchema = yup.object({
  signedAt: yup
    .string()
    .optional()
    .nullable()
    .typeError('dateInvalid')
    .default(''),
  docSubCategoryId: yup.string().required('docCategoryRequired'),
  contacts: yup.array().of(yup.string()).min(0, 'minContacts'),
  operations: yup.array().nullable().of(yup.string())
})
