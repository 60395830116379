
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DocumentCard from '@/components/Event/DocumentCard.vue'
  import { Pagination } from '@/store/modules/Organization/models'
  import { DocumentSearchProps } from '@/types'
  import { Document } from '@/services/api/models'
  import Loader from '@/components/Loader/index.vue'
  import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
  import { Button } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'

  export default defineComponent({
    name: 'CorporateDocumentTab',
    components: {
      EmptyState,
      DocumentCard,
      VueEternalLoading,
      Loader,
      Button,
      Modal
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object
      }
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const { filters } = toRefs(props)
      const selectedCards = ref<string[]>([])
      const canDelete = ref(false)
      const currentFilters = ref<DocumentSearchProps>(filters.value)
      const loading = ref(true)
      const items = computed<Document[]>(
        () => store.getters['portfolio/documents']
      )

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const documentsList = ref<Document[]>(
        store.getters['portfolio/documents']
      )
      const isLoading = computed(
        () => store.getters['organizations/gettingDocuments']
      )

      const pagination = computed<Pagination>(
        () => store.getters['portfolio/documentsPagination']
      )
      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      watch(
        () => filters.value,
        async () => {
          store.commit('portfolio/SET_DOCUMENTS_PAGINATION_PAGE', 1)
          loading.value = true
          getDocuments()
        }
      )

      const load = async ({ loaded }: LoadAction) => {
        store.commit(
          'portfolio/SET_DOCUMENTS_PAGINATION_PAGE',
          pagination.value.currentPage + 1
        )
        const data = { ...filters.value }
        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft
        const response: any = await store.dispatch('portfolio/LOAD_DOCUMENTS', {
          companyId: route.params.id,
          filter: data,
          offset: documentsList.value.length
        })

        if (response && response.data) {
          documentsList.value.push(...response.data)
          loaded(response.data.length, pagination.value.currentPage)
        }
      }

      const handleDocumentClick = async (doc: Document) => {
        if (currentDocument?.value?.id === doc.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: isPortfolioRoute.value ? doc.company?.id : route.params.id,
          documentId: doc.id
        })
      }

      const getDocuments = async () => {
        loading.value = true
        await store.dispatch('portfolio/GET_DOCUMENTS', {
          companyId: route.params.id,
          filter: filters.value,
          getting: true
        })
        loading.value = false
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        const { check, id } = data
        if (check) {
          selectedCards.value.push(id)
        } else {
          selectedCards.value.splice(
            selectedCards.value.findIndex((item: string) => item === id),
            1
          )
        }
      }
      const removeDocument = (id: string) => {
        store.dispatch('portfolio/DELETE_COMPANY_DOCUMENT', {
          companyId: route.params.id,
          documentId: id
        })
      }
      const submitDeletion = () => {
        Promise.all(selectedCards.value.map((x) => removeDocument(x))).then(
          () => {
            getDocuments()
            canDelete.value = false
            selectedCards.value = []
          }
        )
      }

      watch(items, (val) => {
        documentsList.value = [...val]
      })

      watch(
        () => route.params.id,
        () => {
          filters.value = {}
          getDocuments()
        }
      )

      onMounted(async () => {
        loading.value = true
        filters.value = {}
        // call api with filter here
        getDocuments()
      })

      return {
        loading,
        noMore,
        documentsList,
        currentDocument,
        isLoading,
        selectedCards,
        canDelete,
        load,
        selectCard,
        submitDeletion,
        handleDocumentClick
      }
    }
  })
