<template>
  <div v-if="event" class="flex">
    <router-link
      :target="`${company?.id ? '_blank' : ''}`"
      :to="{
        name: 'company.directory',
        params: {
          id: company?.id || $route.params.id,
          contactId: event.appointment.contact?.id
        }
      }"
    >
      <ContactBadge
        class="p-1 my-3 m-0 border border-secondary5 rounded"
        :contact="event.appointment.contact"
        :details="false"
        type="light"
      />
    </router-link>
  </div>
  <p class="py-3 text-left">{{ $t('events.labels.appointment') }}</p>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.function') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ event?.appointment?.function?.name }}
      </p>
    </div>
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.committee') }}
      </h1>
      <p
        v-if="event?.appointment.committee"
        class="text-blackp text-sm text-left"
      >
        {{ event.appointment.committee.denomination }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">-</h1>
      <p class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-secondary6 border-r w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.startDate') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ moment.utc(event.appointment.startDate).format('L') }}
      </p>
    </div>

    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.expirationDate') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ moment.utc(event?.appointment.endDate).format('L') }}
      </p>
    </div>

    <div class="p-3 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.duration') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{
          calculateTimeDuration(
            event?.appointment.startDate || '',
            event?.appointment.endDate || '',
            lang
          )
        }}
      </p>
    </div>
  </div>

  <p class="py-3 text-left">{{ $t('events.labels.appointmentEnd') }}</p>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.appointmentEndDate') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ moment.utc(event?.endDate).format('L') }}
      </p>
    </div>
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.reason') }}
      </h1>
      <p v-if="event.reason" class="text-blackp text-sm text-left">
        {{ $t(`appointmentTerminationReasons.${event.reason}`) }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">-</h1>
      <p class="text-blackp text-sm text-left">-</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { AppointmentTermination, Company } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { calculateTimeDuration } from '@/utils/global'
  export default defineComponent({
    name: 'AppointmentTerminationCard',
    components: { ContactBadge },
    props: {
      event: {
        type: Object as () => AppointmentTermination | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const store = useStore()
      const lang = computed(() => store.getters['config/lang'])

      return { lang, moment, calculateTimeDuration }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
