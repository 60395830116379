import axios from '../axios'
import { CompanyVesting } from '../models/company'
import { company } from '../endpoints'
import { VestingForm } from '@/types/forms'
import { CancelTokenSource } from 'axios'
let vestingsCancelToken: CancelTokenSource | null = null

const createVesting = async (companyId: string, formData: VestingForm) => {
  return await axios.post(company.createVesting(companyId), formData)
}

const updateVesting = async (
  companyId: string,
  vestingId: string,
  formData: VestingForm
) => {
  return await axios.put(company.singleVesting(companyId, vestingId), formData)
}
const getVestings = async (companyId: string, filter = {}) => {
  if (typeof vestingsCancelToken != undefined) {
    vestingsCancelToken?.cancel(
      'Operation (VESTINGS_GET) canceled due to new request.'
    )
  }
  vestingsCancelToken = axios.CancelToken.source()
  return await axios.get(company.createVesting(companyId), {
    cancelToken: vestingsCancelToken.token,
    params: { limit: 30, offset: 0, ...filter }
  })
}

const getVesting = async (
  companyId: string,
  id: string
): Promise<CompanyVesting> => {
  const { data } = await axios.get(company.singleVesting(companyId, id), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const removeVesting = async (companyId: string, id: string) => {
  return axios.delete(company.singleVesting(companyId, id))
}

const createFreeSharesVesting = async (
  companyId: string,
  formData: VestingForm
) => {
  return await axios.post(company.freeSharesVestings(companyId), formData)
}

const createFreeSharesIssuance = async (
  companyId: string,
  formData: VestingForm
) => {
  return await axios.post(company.freeSharesIssuance(companyId), formData)
}

const editFreeSharesIssuance = async (
  companyId: string,
  issuanceId: string,
  formData: VestingForm
) => {
  return await axios.put(
    company.getFreeSharesIssuance(companyId, issuanceId),
    formData
  )
}

const updateFreeSharesVesting = async (
  companyId: string,
  vestingId: string,
  formData: VestingForm
) => {
  return await axios.put(
    company.freeSharesVesting(companyId, vestingId),
    formData
  )
}
const getFreeSharesVestings = async (companyId: string, filter = {}) => {
  return await axios.get(company.freeSharesVestings(companyId), {
    params: { limit: 300, offset: 0 }
  })
}

const getFreeSharesVesting = async (
  companyId: string,
  id: string
): Promise<CompanyVesting> => {
  const { data } = await axios.get(company.freeSharesVesting(companyId, id), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const removeFreeSharesVesting = async (companyId: string, id: string) => {
  return axios.delete(company.freeSharesVesting(companyId, id))
}

const getFreeSharesAcquisitionCalendars = async (
  companyId: string,
  filter = { limit: 50, offset: 0 }
) => {
  const response = await axios.get(
    company.freeSharesAcquisitionCalendars(companyId),
    {
      params: { ...filter }
    }
  )
  return response
}

const createFreeSharesFinalAcquisition = async (
  companyId: string,
  data: any
) => {
  const response = await axios.post(company.finalAcquisition(companyId), data)
  return response
}

const createFreeSharesSunsets = async (companyId: string, data: any) => {
  const response = await axios.post(company.freeSharesSunsets(companyId), data)
  return response
}

export {
  createVesting,
  getVesting,
  getVestings,
  removeVesting,
  updateVesting,
  removeFreeSharesVesting,
  getFreeSharesVesting,
  getFreeSharesVestings,
  updateFreeSharesVesting,
  createFreeSharesVesting,
  createFreeSharesIssuance,
  editFreeSharesIssuance,
  getFreeSharesAcquisitionCalendars,
  createFreeSharesFinalAcquisition,
  createFreeSharesSunsets
}
