
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { MULTISELECTMODE } from '@/types'
  import { Field } from 'vee-validate'
  import {
    defineComponent,
    ref,
    toRefs,
    computed,
    watch,
    onMounted,
    onBeforeMount
  } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { Contact, ExerciseCalendar } from '@/services/api/models'
  import { ucFirst } from '@/utils'

  interface DataInputContact {
    label: string
    name: string
    type: string
    fieldId: string
    hint?: string
    isNeeded?: boolean
  }
  interface ExeriseReponse {
    data: ExerciseCalendar[]
  }
  export default defineComponent({
    name: 'ContactGeneric',
    components: { DropdownContacts, Field },
    inheritAttrs: false,
    props: {
      data: {
        type: Object as () => DataInputContact,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      excludesId: {
        type: Object,
        default: () => ({
          value: [],
          index: -1
        })
      },
      allExcludesId: {
        type: Object,
        default: () => ({
          value: [],
          index: -1
        })
      },
      onlyOptionUsers: {
        type: Boolean,
        default: false
      },
      allData: {
        type: Object,
        required: false
      },
      lineData: {
        type: Object,
        required: false
      },
      mode: {
        required: false,
        type: String,
        default: MULTISELECTMODE.SINGLE
      },
      value: {
        type: [String, Number, Object],
        default: ''
      },
      index: {
        type: Number,
        default: -1
      },
      classes: {
        type: String,
        default: ''
      },
      filterResults: { type: Boolean, default: false },
      label: {
        type: Boolean,
        default: false
      },
      grid: {
        type: Boolean,
        default: true
      },
      tableId: {
        type: String,
        default: null
      },
      openDirection: {
        type: String,
        default: 'bottom'
      },
      canAddNew: { type: Boolean, default: true }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const store = useStore()
      const route = useRoute()
      const fieldKey = ref(0)
      const contacts = ref<any[]>([])

      const excludedIds = ref<any>({ currentIndex: -1, index: -1, value: [] })
      const filteredContacts = ref<any[]>(
        props.onlyOptionUsers
          ? []
          : contacts.value.map((x) => ({ ...x, name: ucFirst(x.name) }))
      )
      const fieldName = computed<any>(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )

      let selectedContact = ref(value.value)

      const updateSelected = (val: any) => {
        selectedContact.value = val

        emit('onUpdated', {
          ...data.value,
          value: val
        })
        fieldKey.value++
      }

      const getUsersWithOptions = () => {
        store.dispatch('organizations/GET_USERS_WITH_OPTIONS', {
          companyId: route.params.id,
          filter: {
            limit: 300
          },
          onSuccess: ({ data }: ExeriseReponse) => {
            const cts = data.reduce(
              (acc: Contact[], line: ExerciseCalendar) => {
                if (!acc.map((x) => x.id).includes(line.contact.id))
                  acc.push({
                    ...line.contact,
                    name: ucFirst(line.contact.name)
                  })
                return acc
              },
              []
            )

            filteredContacts.value = cts
          }
        })
      }

      const getContacts = async (search = '') => {
        if (!route.params?.id) return

        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params?.id,
          filter: {
            limit: 5,
            offset: 0,
            name: search
          },
          onSuccess: (res: Contact[]) => {
            contacts.value = [...res]
            filteredContacts.value = [...res]
            if (props.excludesId.index === props.index) {
              filteredContacts.value = [...res].filter(
                (x: any) => !excludedIds.value.value.includes(x.id)
              )
            }
          }
        })
      }

      watch(
        () => props.excludesId,
        (values) => {
          if (props.excludesId.index === props.index) {
            excludedIds.value = { ...values }

            filteredContacts.value = contacts.value.filter(
              (x: any) => !excludedIds.value.value.includes(x.id)
            )
          }
        }
      )

      watch(
        () => props.value,
        (val) => {
          selectedContact.value = val
        }
      )
      watch(
        () => props.allExcludesId,
        (val) => {
          if (props.index !== val.currentIndex) {
            excludedIds.value = { ...val }

            const values = excludedIds.value.value.filter(
              (x: any) => x != selectedContact.value
            )

            filteredContacts.value = contacts.value.filter(
              (x: any) => !values.includes(x.id)
            )
          }
        }
      )

      watch(
        () => contacts.value,
        () => {
          if (excludedIds.value.index === props.index) {
            filteredContacts.value = contacts.value.filter(
              (x: Contact) => ![...excludedIds.value.value].includes(x.id)
            )
          }
          if (props.index !== excludedIds.value?.currentIndex) {
            const values = [...excludedIds.value.value].filter(
              (x) => x != selectedContact.value
            )
            filteredContacts.value = contacts.value.filter(
              (x: Contact) => !values.includes(x.id)
            )
          } else {
            filteredContacts.value = props.onlyOptionUsers
              ? []
              : contacts.value.map((x) => ({ ...x, name: ucFirst(x.name) }))
          }
        }
      )

      onBeforeMount(() => {
        if (props.onlyOptionUsers) getUsersWithOptions()
        else if (props.index == -1 && !props.lineData?.selection) getContacts()
        else if (
          props.lineData &&
          props.lineData[`${data.value.name}Selection`]
        ) {
          filteredContacts.value = [
            props.lineData[`${data.value.name}Selection`]
          ]
        }
      })

      return {
        selectedContact,
        fieldKey,
        fieldName,
        filteredContacts,
        updateSelected
      }
    }
  })
