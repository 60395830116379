<template>
  <div class="text-right">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          :class="`inline-flex w-full justify-center items-center rounded-md ${callerClass}`"
        >
          <slot>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
              />
            </svg>
          </slot>
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="
            absolute
            right-0
            mt-2
            w-80
            px-2
            origin-top-right
            divide-y divide-secondary4
            rounded-md
            bg-white
            shadow-lg
            ring-1 ring-black ring-opacity-5
            focus:outline-none
            z-50
          "
        >
          <div class="py-1" v-for="[key, list] in groupedActions" :key="key">
            <MenuItem
              v-slot="{ active }"
              v-for="(action, index) in list"
              :key="index"
            >
              <button
                @click="($event) => handleAction(action.key)"
                :class="[
                  active ? 'bg-primary1 text-primary4' : 'text-secondary8',
                  'group flex w-full justify-start items-center rounded-sm border-none px-2 py-3 text-md my-1 font-medium text-start'
                ]"
              >
                <i
                  :class="`bi bi-${action.icon} text-primary3 text-2xl ml-1 mr-2`"
                ></i>
                {{ action.name }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script lang="ts">
  import { ActionListItem, MAINACTIONS } from '@/types/config'
  import { groupItemBy } from '@/utils'
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { computed, defineComponent, onMounted } from 'vue'

  export default defineComponent({
    components: {
      Menu,
      MenuButton,
      MenuItems,
      MenuItem
    },
    props: {
      callerClass: {
        type: String,
        default:
          'bg-white bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
      },
      actions: {
        type: Object as () => Array<ActionListItem>,
        required: false,
        default: null
      }
    },
    emits: ['onClick'],
    setup(props, { emit }) {
      const groupedActions = computed(() =>
        groupItemBy(
          [...(props.actions || [])].sort(
            (a: any, b: any) => a.section - b.section
          ),
          (i) => i.section || 1
        )
      )

      const handleAction = (action: MAINACTIONS) => {
        emit('onClick', action)
      }
      return { groupedActions, handleAction }
    }
  })
</script>
