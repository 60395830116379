import axios from '../axios'
import { CompanyModel, CompanyOrganigram } from '../models'
import { company, toolbox, auth } from '../endpoints'
import { CompanyModelForm } from '@/types/company'
import { CancelTokenSource } from 'axios'
import { OrganigramFormData } from '@/types'
let companiesCancelToken: CancelTokenSource | null = null
let companiesLoaderCancelToken: CancelTokenSource | null = null
let companiesUsersCancelToken: CancelTokenSource | null = null
let orgChartCancelToken: CancelTokenSource | null = null

const onboardingCompany = async (companyData: CompanyModel) => {
  const { data } = await axios.post(company.onboarding(), companyData)
  return data
}
const getCapitalData = async (companyId: string): Promise<Document[]> => {
  return await axios.get(company.capital(companyId))
}

const getCapitalByGroup = async (companyId: string): Promise<Document[]> => {
  return await axios.get(company.capitalByGroup(companyId))
}

const getCapitalByStockCategory = async (
  companyId: string
): Promise<Document[]> => {
  return await axios.get(company.capitalByStockCategory(companyId))
}

const getCapitalByEsopCategory = async (
  companyId: string
): Promise<Document[]> => {
  return await axios.get(company.capitalByEsopCategory(companyId))
}

const getProfitSharingEvolution = async (
  companyId: string
): Promise<Document[]> => {
  const resp = await axios.get(company.getProfiltSharingEvolution(companyId))

  return resp.data
}

const getEsopInfo = async (companyId: string): Promise<Document[]> => {
  const resp = await axios.get(company.esopData(companyId))

  return resp.data
}

const getFundingData = async (companyId: string): Promise<Document[]> => {
  const resp = await axios.get(company.fundingData(companyId))

  return resp.data
}
const createNewCompany = async (data: CompanyModelForm) => {
  return await (
    await axios.post(company.create(), data)
  ).data
}

const updateCompany = async (id: string, data: CompanyModelForm) => {
  return await axios.put(company.get(id), data)
}

const getCompany = async (id: string) => {
  return await (
    await axios.get(company.get(id))
  ).data
}
const deleteCompany = async (id: string) => {
  return await axios.delete(company.get(id))
}

const getCompanyUserAccounts = async (companyId: string, filter = {}) => {
  const { data } = await axios.get(company.users(companyId), {
    params: filter
  })
  return data
}

const getCompanyInformationsBySiret = async (
  siret: string
): Promise<CompanyModel> => {
  const { data } = await axios.get(toolbox.companyInformations(siret))
  return data
}

const searchCompanies = (query: string): Promise<CompanyModel[]> => {
  return axios.get(toolbox.searchCompanies(query))
}

const getCompanies = async (filter: any, offset = 0, limit = 20) => {
  if (typeof companiesCancelToken != undefined) {
    companiesCancelToken?.cancel('Operation canceled due to new request.')
  }
  companiesCancelToken = axios.CancelToken.source()
  const response = await axios.get(company.list(), {
    cancelToken: companiesCancelToken.token,
    params: { offset, limit: filter?.shortVersion ? 50 : limit, ...filter }
  })

  return response
}

const getLoaderCompanies = async (filter: any, offset = 0, limit = 20) => {
  if (typeof companiesLoaderCancelToken != undefined) {
    companiesLoaderCancelToken?.cancel('Operation canceled due to new request.')
  }
  const params = { offset, limit: filter?.shortVersion ? 50 : limit, ...filter }

  companiesLoaderCancelToken = axios.CancelToken.source()
  const response = await axios.get(company.list(), {
    cancelToken: companiesLoaderCancelToken.token,
    params
  })

  return response
}

const getAccountUsers = async (filter: any, offset = 0, limit = 20) => {
  if (typeof companiesUsersCancelToken != undefined) {
    companiesUsersCancelToken?.cancel('Operation canceled due to new request.')
  }
  companiesUsersCancelToken = axios.CancelToken.source()
  const { data } = await axios.get(auth.accountUsers(), {
    cancelToken: companiesUsersCancelToken.token,
    params: { ...filter, offset, limit }
  })

  return data
}

const getVestingSynthesis = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 5
) => {
  return await axios.get(company.getVestingSyntesis(companyId), {
    params: { ...filter, offset, limit }
  })
}

const getBodac = async (companyId: string) => {
  return await axios.get(company.bodac(companyId), {})
}

const getBodacPdfUrl = async (companyId: string, filter: any) => {
  return await axios.get(company.bodacUrl(companyId), { params: { ...filter } })
}

const changeCompanyImage = async (
  companyId: string,
  form: FormData,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const resp = await axios.post(company.picture(companyId), form, {
    onUploadProgress
  })
  return resp
}

const getCompanyLogo = async (companyId: string) => {
  return await axios.get(company.picture(companyId))
}

const createCompanyOrganigram = async (
  companyId: string,
  formData: OrganigramFormData
): Promise<any> => {
  if (typeof orgChartCancelToken != undefined) {
    orgChartCancelToken?.cancel('Operation canceled due to new request.')
  }
  orgChartCancelToken = axios.CancelToken.source()
  const data = await axios.post(company.getOrganigrams(companyId), formData, {
    cancelToken: orgChartCancelToken.token
  })
  return data
}

export {
  getCompanies,
  createNewCompany,
  getCompanyInformationsBySiret,
  searchCompanies,
  getLoaderCompanies,
  deleteCompany,
  updateCompany,
  getCompany,
  getCompanyUserAccounts,
  onboardingCompany,
  getVestingSynthesis,
  getBodac,
  getBodacPdfUrl,
  getCapitalData,
  getAccountUsers,
  changeCompanyImage,
  getCompanyLogo,
  getCapitalByGroup,
  getCapitalByStockCategory,
  getCapitalByEsopCategory,
  getProfitSharingEvolution,
  getEsopInfo,
  getFundingData,
  createCompanyOrganigram
}
