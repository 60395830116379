
  import { Company, Notification } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { useAcl } from 'vue-simple-acl/src'

  import { GlobalConfig } from '@/types'
  import conf from '@/config'
  import { PopoverButton, Popover, PopoverPanel } from '@headlessui/vue'

  export default defineComponent({
    components: { PopoverButton, PopoverPanel, Popover },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const acl = useAcl()

      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )
      const notifications = computed<Notification[]>(
        () => store.getters['organizations/notifications']
      )
      const config = conf as GlobalConfig

      const notSeenotifications = computed(() =>
        notifications.value.filter((x) => !x.seen)
      )
      const lastNotifications = computed<Notification[]>(() =>
        notifications.value.slice(0, 5)
      )

      const hasWaitingAlert = computed(
        () => notifications.value.filter((x) => !x.seen).length > 0
      )

      const handleNotificationClicked = (row: Notification) => {
        if (!row.seen)
          store.dispatch('organizations/MARK_AS_SEEN', {
            companyId: currentCompany.value.id,
            notificationId: row.id
          })
        router.push({
          name: 'company.eventDetails',
          params: { eventId: row?.event?.id, id: currentCompany.value.id }
        })
      }
      return {
        currentCompany,
        lastNotifications,
        hasWaitingAlert,
        notSeenotifications,
        moment,
        handleNotificationClicked
      }
    }
  })
