import store from '@/store/index'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const access = (identifier: string, customEmpowerments = false) => {
  const empowerments =
    customEmpowerments || store.getters['organizations/currentEmpowerments']
  return empowerments
    ? empowerments.find(
        (empowerment: any) => empowerment.identifier === identifier
      )
    : false
}
