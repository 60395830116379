<template>
  <div id="pdf-viewer" ref="root" class="h-full"></div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import WebViewer from '@pdftron/pdfjs-express-viewer'
  import { Document } from '@/services/api/models'
  import { useStore } from 'vuex'

  export default defineComponent({
    props: {
      document: { type: Object as () => Document, default: null },
      url: {
        type: String,
        default: ''
      },
      documentId: { type: String, default: null },
      page: { type: Number, required: true },
      searchedString: { type: String, required: true },
      isSelected: { type: Boolean, default: false }
    },
    setup(props) {
      const store = useStore()
      let instanceDoc: any = null
      const root = ref(null)
      const lang = computed(() => store.getters['config/lang'] ?? 'fr')
      const text = computed(() => store.getters['organizations/searchedText'])
      const load = () => {
        WebViewer(
          {
            path: '/plugins/pdfexpress',
            extension: 'pdf',
            licenseKey: 'KmxVr5xBkVwdIr0k32oC',
            disabledElements: [
              'selectToolButton',
              'panToolButton',
              'menuButton'
            ]
          },
          root.value
        ).then((instance: any) => {
          instance.UI.loadDocument(props.url, {
            documentId: props.documentId,
            filename: 'sample-1.pdf'
          })
          instanceDoc = instance
          function searchListener(
            searchValue: string,
            options: any,
            results: any
          ) {
            console.log(searchValue, options, results)
          }

          instance.UI.addSearchListener(searchListener)
          watch(
            () => props.url,
            (newUrl) => {
              instance.UI.loadDocument(newUrl, {
                documentId: props.documentId,
                filename: 'sample-1.pdf'
              })
            }
          )

          instance.UI.setLanguage(lang.value)

          var Feature = instance.UI.Feature
          var docViewer = instance.Core.documentViewer
          var FitMode = instance.UI.FitMode
          docViewer.addEventListener('documentLoaded', function () {
            console.log('documentLoaded', text.value)

            instance.UI.setFitMode(FitMode.FitWidth)

            if (text.value) {
              instance.UI.searchTextFull(text.value, {
                caseSensitive: false,
                wholeWord: false
              })
              instance.UI.searchTextFull(text.value)
            }
          })

          instance.UI.disableFeatures([
            Feature.Copy,
            Feature.Print,
            Feature.Menu,
            Feature.Annotations
          ])
        })
      }

      onMounted(() => {
        load()
      })
      return { root }
    }
  })
</script>

<style lang="scss">
  #pdf-viewer {
    height: 100%;
  }
</style>
