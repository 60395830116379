import {
  STOCKTYPESALIAS,
  SampleStockTypeFormData,
  CompositeStockTypeFormData,
  OptionStockTypeFormData
} from '@/types/forms'
import {
  StockAlias,
  OptionStockAlias,
  CompositionStockAlias,
  StockIssuance,
  OptionIssuance
} from '../models/company'
import { company } from '../endpoints'
import axios from '../axios'
import { OptionIssuanceFormData, StockIssuanceFormData } from '@/types/forms'

const createStock = async (
  formData: any,
  companyId: string,
  type: STOCKTYPESALIAS
) => {
  return await axios.post(company.stockCreation(companyId, type), formData)
}

const editStock = async (
  formData:
    | SampleStockTypeFormData
    | CompositeStockTypeFormData
    | OptionStockTypeFormData,
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
) => {
  return await axios.put(
    company.singleStockAlias(companyId, type, stockId),
    formData
  )
}

const deleteStock = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
) => {
  return await axios.delete(company.singleStockAlias(companyId, type, stockId))
}

const getStockAlias = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  filter = {}
): Promise<StockAlias[]> => {
  const { data } = await axios.get(company.getStockAlias(companyId, type), {
    params: { limit: 30, offset: 0, ...filter }
  })
  return data
}

const verifyStockAlias = async (
  companyId: string,
  stockId: string
): Promise<any> => {
  const { data } = await axios.patch(company.verifyStock(companyId, stockId))
  return data
}

const getSingleStockAlias = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
): Promise<StockAlias | OptionStockAlias | CompositionStockAlias> => {
  const { data } = await axios.get(
    company.singleStockAlias(companyId, type, stockId),
    { params: { limit: 30, offset: 0 } }
  )
  return data
}

const createPrimaryStockEmission = async (
  companyId: string,
  data: StockIssuanceFormData
): Promise<StockIssuance> => {
  return await axios.post(company.getPrimaryStocks(companyId), data)
}

const editPrimaryStockEmission = async (
  companyId: string,
  eventId: string,
  data: StockIssuanceFormData
): Promise<StockIssuance> => {
  return await axios.put(company.singlePrimaryStock(companyId, eventId), data)
}
const createPrimaryOptionsEmission = async (
  companyId: string,
  data: OptionIssuanceFormData
): Promise<OptionIssuance> => {
  return await axios.post(company.getPrimaryOptions(companyId), data)
}

const getStockIssuanceParts = async (
  companyId: string,
  stockIssuanceId: string,
  offset = 0,
  limit = 10
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getStockIssuanceParts(companyId, stockIssuanceId),
    { params: { offset, limit } }
  )
}

const editPrimaryOptionsEmission = async (
  companyId: string,
  eventId: string,
  data: OptionIssuanceFormData
): Promise<OptionIssuance> => {
  return await axios.put(company.singlePrimaryOption(companyId, eventId), data)
}
const createPrimaryCompositionEmission = async (
  companyId: string,
  data: OptionIssuanceFormData
): Promise<any> => {
  return await axios.post(company.getPrimaryComposition(companyId), data)
}
const editPrimaryCompositionEmission = async (
  companyId: string,
  eventId: string,
  data: OptionIssuanceFormData
): Promise<any> => {
  return await axios.put(
    company.singlePrimaryComposition(companyId, eventId),
    data
  )
}
const getPrimaryStockEmission = async (
  companyId: string
): Promise<StockIssuance[]> => {
  return await axios.get(company.getPrimaryStocks(companyId))
}
const getPrimaryOptionsEmission = async (
  companyId: string
): Promise<OptionIssuance[]> => {
  return await axios.get(company.getPrimaryOptions(companyId))
}
const getPrimaryCompositionEmission = async (
  companyId: string
): Promise<any> => {
  return await axios.get(company.getPrimaryComposition(companyId))
}

const removeStockIssuancePart = async (
  companyId: string,
  stockIssuanceId: string,
  partId: string
) => {
  return await axios.delete(
    company.getStockIssuancePart(companyId, stockIssuanceId, partId)
  )
}

const removeOptionIssuancePart = async (
  companyId: string,
  optionIssuanceId: string,
  partId: string
) => {
  return await axios.delete(
    company.getOptionIssuancePart(companyId, optionIssuanceId, partId)
  )
}

export {
  getSingleStockAlias,
  getStockAlias,
  deleteStock,
  editStock,
  createStock,
  createPrimaryCompositionEmission,
  getPrimaryCompositionEmission,
  getPrimaryOptionsEmission,
  getPrimaryStockEmission,
  createPrimaryOptionsEmission,
  createPrimaryStockEmission,
  editPrimaryCompositionEmission,
  editPrimaryStockEmission,
  editPrimaryOptionsEmission,
  getStockIssuanceParts,
  removeStockIssuancePart,
  removeOptionIssuancePart,
  verifyStockAlias
}
