<template>
  <div class="px-4 py-5 w-full">
    <div class="flex flex-col justify-start w-full items-start text-xl">
      <h1 class="text-left text-secondary9">
        {{ $t('account.accountInformations.technicalIntegration') }}
      </h1>

      <div class="flex justify-between w-full items-center text-xl ml-2">
        <h3 class="text-left text-secondary">
          {{ $t('account.accountInformations.apiCall') }}
        </h3>
      </div>

      <div class="ml-10 flex flex-col justify-start items-start">
        <div class="mt-3">
          <code class="rounded text-white bg-blackp px-2 py-1">
            Authorization
          </code>
          <code class="rounded text-white bg-blackp px-2 py-1 ml-2">
            Bearer
          </code>
        </div>

        <div class="mt-3">
          <code class="rounded text-white bg-blackp px-2 py-1 w-1/12">
            Api Token
          </code>
          <code class="break-all rounded text-white bg-blackp px-2 py-1 ml-2">
            {{ truncToken }}
          </code>
          <span
            @click="copyText"
            class="text-primary5 cursor-pointer p-1 bg-primary2 rounded ml-2"
            >{{ $t('global.actions.copy') }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, onMounted, defineComponent, ref } from 'vue'
  import { getInstance } from '@/plugins/auth'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'

  export default defineComponent({
    setup(props) {
      const displayAll = ref(false)
      const store = useStore()
      const { t } = useI18n()
      const accessToken = ref<string | null>(null)
      const truncToken = computed(() => `${accessToken.value?.slice(0, 50)}...`)

      const copyText = () => {
        navigator.clipboard.writeText(accessToken.value || '')

        store.dispatch('toolbox/displayToast', {
          type: TYPE.DEFAULT,
          context: t('account.integrationContext'),
          message: t(`account.accountInformations.tokenCopied`)
        })
      }
      onMounted(async () => {
        accessToken.value = await getInstance().getTokenSilently()
      })
      return { displayAll, accessToken, truncToken, copyText }
    }
  })
</script>
