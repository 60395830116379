
  import { AuditStatusType } from '@/types'
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Button } from '@up.law/uplaw-ui'

  export default defineComponent({
    components: { Field, Multiselect, Button },
    props: { selectedRows: { type: Boolean, default: false } },
    emits: ['filterChange', 'updateStatus'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const selectedStatus = ref('')
      const updateStatus = ref('')
      const status = computed(() =>
        Object.values(AuditStatusType).map((key) => ({
          id: key,
          name: t(`auditStatus.${key}`)
        }))
      )

      const updateFilters = (value: string) => {
        selectedStatus.value = value
        emit('filterChange', selectedStatus.value)
      }

      const update = () => {
        emit('updateStatus', updateStatus.value)
      }

      return { status, selectedStatus, updateStatus, updateFilters, update }
    }
  })
