<template>
  <div @click="handleSelect">
    <input
      id="fileLoader"
      ref="fileInputRef"
      type="file"
      hidden
      :multiple="multiple"
      name="fileLoader"
      :accept="accept"
      @change="handleChange"
    />
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      accept: {
        type: String,
        default: ''
      }
    },
    emits: ['filesChanged'],
    setup(props, { emit }) {
      const handleChange = (e: Event) => {
        const target = e.target as HTMLInputElement
        emit('filesChanged', target.files)
        target.value = ''
      }
      const handleSelect = (e: Event) => {
        document.getElementById('fileLoader')?.click()
      }
      return { handleChange, handleSelect }
    }
  })
</script>
