<template>
  <div v-if="event">
    <div class="flex overflow-scroll grid grid-cols-11">
      <div class="col-span-2 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.contact') }}
        </h1>
      </div>
      <div class="col-span-2 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.option') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
      </div>

      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.issuedQuantity') }}
        </h1>
      </div>

      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.unitPrice') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.totalPrice') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.issuancePremiumPerAction') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.totalIssuancePremium') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.nominalIncrease') }}
        </h1>
      </div>
    </div>
    <div class="event-bg">
      <div
        v-for="(part, index) in parts"
        :key="part.id"
        :class="`w-full flex-wrap flex grid grid-cols-11 ${
          contactsId.includes(part.contact?.id) ? 'bg-active font-bold' : ''
        } ${index > 0 ? 'border-t border-secondary6 ' : ''}`"
      >
        <div
          class="
            p-2
            border-r border-secondary6
            col-span-2
            flex
            items-center
            justify-start
          "
        >
          <p v-if="part.contact" class="text-blackp text-sm text-left">
            <router-link
              :target="`${company?.id ? '_blank' : ''}`"
              :to="{
                name: 'company.directory',
                params: {
                  id: company?.id || $route.params.id,
                  contactId: part.contact.id
                }
              }"
            >
              <ContactBadge
                class="p-0 text-xs"
                :contact="part.contact"
                :details="false"
                type="light"
              />
            </router-link>
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div class="p-3 border-r border-secondary6 col-span-2">
          <p v-if="part.optionAlias" class="text-blackp text-sm text-left">
            <Tag
              :text="part.optionAlias.alias.toUpperCase()"
              :value="part.optionAlias.id"
              :with-initials="false"
              :with-remove="false"
            />
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p class="text-blackp text-sm text-left">
            {{ part.quantity }}
          </p>
        </div>

        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p class="text-blackp text-sm text-left">
            {{ part.issuedQuantity || '-' }}
          </p>
        </div>

        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p
            v-if="part.unitPrice !== null"
            class="text-blackp text-sm text-left"
          >
            {{ formatNumber(part.unitPrice) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p
            v-if="part.unitPrice !== null"
            class="text-blackp text-sm text-left"
          >
            {{ formatNumber(getLineTotalPrice(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p
            v-if="part.unitPrice !== null"
            class="text-blackp text-sm text-left"
          >
            {{ formatNumber(emitPerAction(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p v-if="emitTotal(part)" class="text-blackp text-sm text-left">
            {{ formatNumber(emitTotal(part)) }} {{ currency }}
          </p>
          <p
            v-else
            class="text-blackp text-sm text-left flex items-center justify-end"
          >
            -
          </p>
        </div>
        <div class="p-3 flex items-center justify-end">
          <p
            v-if="part.unitPrice !== null"
            class="text-blackp text-sm text-left"
          >
            {{ formatNumber(augNominal(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
      </div>
      <div
        :class="`grid grid-cols-11 ${
          parts.length > 0 ? 'border-t border-secondary6 ' : ''
        } h-10 flex`"
      >
        <div
          class="
            col-span-4
            border-r border-secondary6
            h-10
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>

        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalQuantity) }}
          </p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalIssuedQuantity) }}
          </p>
        </div>
        <div class="border-r border-secondary6 flex items-center justify-end">
          <p class="text-blackp text-right px-2 font-semibold">
            {{ formatNumber(globalUnitPrice) }} {{ currency }}
          </p>
        </div>
        <div class="border-r border-secondary6 flex items-center justify-end">
          <p class="text-blackp text-right px-2 font-semibold">
            {{ formatNumber(globalTotalPrice) }} {{ currency }}
          </p>
        </div>
        <div class="border-r border-secondary6 flex items-center justify-end">
          <p class="text-right px-1 font-semibold">
            {{ formatNumber(globalIssuancePerAction) }} {{ currency }}
          </p>
        </div>
        <div class="border-r border-secondary6 flex items-center justify-end">
          <p class="text-right px-1 font-semibold">
            {{ formatNumber(globalEmit) }} {{ currency }}
          </p>
        </div>
        <div class="flex items-center justify-end">
          <p class="text-right px-1 font-semibold">
            {{ formatNumber(globalNominalIncrease) }} {{ currency }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRefs, computed } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { isNumber } from 'lodash'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { OptionExercise } from '@/types'
  import { Company, NominalValue } from '@/services/api/models'
  export default defineComponent({
    name: 'IssuanceCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => OptionExercise | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { event } = toRefs(props)
      const store = useStore()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed(() => props.event?.optionExerciseParts || [])

      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.issuanceDate).unix() >
            moment.utc(x.date).unix()
        )

        return nv?.nominalValue || 0
      })
      const totalQuantity = computed(() => {
        return (props.event?.optionExerciseParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })

      const totalIssuedQuantity = computed(() => {
        return (props.event?.optionExerciseParts || []).reduce(
          (sale: number, p: any) => (sale += p.issuedQuantity),
          0
        )
      })

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      const emitTotal = (row: any) => {
        return (row.unitPrice - nominalValue.value) * row.quantity
      }

      const emitPerAction = (row: any) => {
        if (!nominalValue.value || !isNumber(row.unitPrice)) {
          return '-'
        }

        return row.unitPrice - nominalValue.value
      }

      const totalPrice = computed((row) => {
        return row.quantity * row.unitPrice
      })

      const globalTotal = computed(() => {
        return (props.event?.optionExerciseParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity * p.unitPrice),
          0
        )
      })

      const getLineTotalPrice = (line: any) => {
        return line.issuedQuantity * line.unitPrice
      }

      const globalIssuancePerAction = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              (line.quantity * line.unitPrice) / parseInt(line.quantity || 1) -
              nominalValue.value),
          0
        )
      )

      const globalEmit = computed(() => {
        return parts.value.reduce(
          (acc: number, line: any) =>
            (acc += (line.unitPrice - nominalValue.value) * line.quantity),
          0
        )
      })

      const globalNominalIncrease = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value = globalTotal.value / totalQuantity.value
        }
        return value
      })

      const globalTotalPrice = computed(() => {
        return parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.issuedQuantity || 0) * line.unitPrice),
          0
        )
      })

      return {
        parts,
        totalQuantity,
        totalIssuedQuantity,
        totalPrice,
        globalUnitPrice,
        globalTotalPrice,
        globalIssuancePerAction,
        globalNominalIncrease,
        globalEmit,
        currency,
        contactsId,
        emitPerAction,
        getLineTotalPrice,
        moment,
        formatNumber,
        emitTotal,
        augNominal
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
