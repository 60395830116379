
  import { defineComponent, ref } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field, Form } from 'vee-validate'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '@/components/Loader/index.vue'
  import { User } from '@/services/api/models'
  import * as yup from 'yup'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: { Input, Field, Form, Button, Loader },
    setup() {
      const store = useStore()
      const router = useRouter()
      const { t } = useI18n()
      const email = ref('')
      const user = ref<User | null>(null)
      const isLoading = ref(false)
      const success = ref(false)

      const schema = yup.object({
        email: yup.string().required('emailRequired')
      })
      const gotoLogin = () => {
        router.replace({ name: 'auth.connect' })
      }

      const handleForm = () => {
        isLoading.value = true
        store.dispatch('auth/RESET_PASSWORD', {
          email: email.value,
          onSuccess: () => {
            isLoading.value = false
            success.value = true
          },
          onError: (err: string) => {
            success.value = false
            isLoading.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              message:
                err ||
                t(
                  `account.accountInformations.apiResponse.errorPasswordEdition`
                ),
              context: t('account.context')
            })
            success.value = true
          }
        })
      }

      return {
        email,
        success,
        isLoading,
        schema,
        user,
        handleForm,
        gotoLogin
      }
    }
  })
