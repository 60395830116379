
  import {
    computed,
    defineComponent,
    onMounted,
    watch,
    ref,
    onBeforeMount
  } from 'vue'
  import DataBoxes from '@/components/CompanyOverview/DataBoxes.vue'
  import UplChart from '@/components/Graphs/UplChart.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import {
    CompanyCapitalByStockCategory,
    CompanyGroup,
    CompanyProfitSharingItem,
    EsopData,
    VestingSynthesis
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    name: 'ESOPTab',
    components: { DataBoxes, UplChart },

    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const esopData = computed<EsopData>(
        () => store.getters['organizations/esopData']
      )

      const exerciceCalendarsTotal = computed<VestingSynthesis>(
        () => store.getters['organizations/exerciseCalendarsTotal']
      )

      const vestingSynthesis = computed<VestingSynthesis[]>(
        () => store.getters['organizations/vestingSyntheses']
      )
      const vestedQuantity = computed(() =>
        vestingSynthesis.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const loadingData = ref(false)
      const loadingEsop = ref(false)
      const loadingSynth = ref(false)

      const data = computed(() => ({
        attributedQuantity: {
          value: exerciceCalendarsTotal.value?.assignedAmount || 0,
          type: 'number',
          isLoading: loadingEsop.value
        },
        vestedQuantity: {
          value: exerciceCalendarsTotal.value?.vestedQuantity || 0,
          type: 'number',
          isLoading: loadingEsop.value
        },
        sold: {
          value: exerciceCalendarsTotal.value?.exercisableBalance || 0,
          type: 'number',
          isLoading: loadingEsop.value
        },
        exercisedQuantity: {
          value: exerciceCalendarsTotal.value?.exercisedQuantity || 0,
          type: 'number',
          isLoading: loadingEsop.value
        },
        cancelledQuantity: {
          value: exerciceCalendarsTotal.value?.numberOfSunsetedOptions || 0,
          type: 'number',
          isLoading: loadingData.value
        },

        shareholders: {
          value: esopData.value?.countContacts || 0,
          type: 'number',
          isLoading: loadingEsop.value
        }
      }))

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )
      const companyCapitalByEsopCategory = computed<
        CompanyCapitalByStockCategory[]
      >(() => store.getters['organizations/companyCapitalByEsopCategory'])
      const planOptions = computed(() => ({
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      }))
      const profitSahringEvolution = computed<CompanyProfitSharingItem[]>(
        () => store.getters['organizations/profitSharingEvolution']
      )

      const formattedCapStockGrouped = computed(() =>
        [...companyCapitalByEsopCategory.value].sort(
          (a, b) => b.value - a.value
        )
      )

      const obsolete = computed(() => ({
        label: t('charts.obsolete'),
        data: profitSahringEvolution.value.map(
          (x) => x.numberOfSunsetedOptions
        ),
        backgroundColor: '#5c6dac',
        hoverBackgroundColor: '#5aa4ac',
        borderWidth: 0,
        order: 3
      }))

      const exercisable = computed(() => ({
        label: t('charts.exercisable'),
        data: profitSahringEvolution.value.map((x) => x.exercisableBalance),
        backgroundColor: '#80ebf6',
        hoverBackgroundColor: '#5aa4ac',
        borderWidth: 0,
        order: 1
      }))

      const nonExercisable = computed(() => ({
        label: t('charts.non-exercisable'),
        data: profitSahringEvolution.value.map((x) => x.nonExercisableBalance),
        backgroundColor: '#aa80f6',
        hoverBackgroundColor: '#5aa4ac',
        borderWidth: 0,
        order: 2
      }))
      const profitSharingDataset = computed(() => [
        exercisable.value,
        nonExercisable.value,
        obsolete.value
      ])

      const planLabels = computed(() =>
        profitSahringEvolution.value.map((x) => x.profitSharingPlanName)
      )

      const categoryDatasets = computed(() => [
        {
          data: [
            ...formattedCapStockGrouped.value.map((x) => Math.abs(x.value))
          ],

          hoverBorderColor: [
            '#8580F6',
            '#5c6dac',
            '#C3FDFF',
            '#aa80f6',
            '#5aa4ac',
            ...groups.value.map((x) => x.colorCode)
          ],
          backgroundColor: [
            '#8580F6',
            '#5c6dac',
            '#C3FDFF',
            '#aa80f6',
            '#5aa4ac',
            ...groups.value.map((x) => x.colorCode)
          ]
        }
      ])

      const categoryLabels = computed(() =>
        formattedCapStockGrouped.value.map((x) => x.categoryName)
      )

      const dataBoxes = [
        {
          icon: 'bi-bar-chart-line-fill',
          key: 'attributedQuantity'
        },

        {
          icon: 'bi-bar-chart-line-fill',
          key: 'sold'
        },
        {
          icon: 'bi-bar-chart-line-fill',
          key: 'cancelledQuantity'
        },

        {
          icon: 'bi-bar-chart-line-fill',
          key: 'exercisedQuantity'
        },
        {
          icon: 'bi-people-fill',
          key: 'shareholders'
        }
      ]

      const getCapitalByStockCategory = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_COMPANY_CAPITAL_BY_ESOP_CATEGORY', {
          companyId
        })
      }

      const getProfitSharingPlanEvolution = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_PROFIT_SHARING_EVOLUTION', {
          companyId
        })
      }

      const getEsopData = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_ESOP_DATA', {
          companyId,
          onSuccess: () => {
            loadingEsop.value = false
          }
        })
      }

      const nowDate = new Date().toISOString()

      const getTotal = () => {
        store.dispatch('organizations/GET_EXERCISE_CALENDARS_TOTAL', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            date: nowDate
          },
          onSuccess: () => {
            loadingData.value = false
          }
        })
      }

      const getVesting = async () => {
        await store.dispatch('organizations/GET_VESTING_SYNTHESIS', {
          companyId: route.params.id,
          filter: {
            date: nowDate
          },
          onSuccess: () => {
            loadingSynth.value = false
          }
        })
      }

      onBeforeMount(() => {
        loadingSynth.value = true
        loadingData.value = true
        loadingEsop.value = true
        getTotal()
        getVesting()
      })

      onMounted(() => {
        getCapitalByStockCategory()
        getProfitSharingPlanEvolution()
        getEsopData()
      })

      watch(
        () => route.params.id,
        () => {
          getTotal()
          getVesting()
        }
      )

      return {
        dataBoxes,
        data,
        loadingData,
        profitSharingDataset,
        planLabels,
        planOptions,
        categoryLabels,
        categoryDatasets,
        vestedQuantity
      }
    }
  })
