<template>
  <div class="documentModal">
    <div v-show="!document" class="pdfviewer-wrapper">
      <div class="pdf-edition">
        <div class="pdf-menu">
          <div class="pdf-menu--start">
            <div class="status completed" />
            <p class="m-0 ml-3 name">--</p>
          </div>
          <div class="pdf-menu--end">
            <p class="date">
              <i class="bi-calendar mr-2" />
              --
            </p>
            <div class="signers">
              <i class="bi bi-pen" />
              <span class="ml-1">{{ $t('extraction.extract.signers') }}</span>
              <div class="ml-2 upl-badge primary">--</div>
            </div>
          </div>
        </div>
        <div
          v-if="displayStyle !== 'integred'"
          class="close__button"
          @click="$emit('close-preview')"
        >
          <i class="bi-x" />
        </div>
      </div>
      <div
        class="
          pdf-layout
          h-100
          w-100
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <div class="page-template">
          <EmptyState
            image="choose-document.svg"
            title="Choisissez un document"
            subtitle="Choisissez un document pour accéder à sa prévisualisation"
          />
        </div>
      </div>
    </div>
    <div
      v-if="document"
      class="pdfviewer-wrapper pdfviewer-wrapper--dark flex flex-col"
    >
      <div class="pdf-edition">
        <div class="pdf-menu">
          <div class="pdf-menu--start">
            <div
              :class="`mr-1 status border-2 border-${
                document.isDraft
                  ? 'warning'
                  : !document.isDraft
                  ? 'success'
                  : 'blackp'
              }`"
            />
            <div class="flex items-center">
              <i class="bi-calendar mx-2" />
              <p v-if="document && document.signedAt" class="date">
                {{ moment.utc(document.signedAt).format('L') }}
              </p>
              <p v-else>--/--/----</p>
            </div>
            <p
              v-if="document && document.docSubCategory"
              class="m-0 ml-3 name text-left text-xs"
            >
              {{ document.docSubCategory?.name || '-' }} /
              {{ truncText(document.label || document.name) }}
            </p>
            <p v-else-if="document" class="m-0 ml-3 name">
              {{ document.label || document.name }}
            </p>
          </div>

          <div class="pdf-menu--end">
            <div class="flex justify-end">
              <div
                class="
                  h-8
                  rounded
                  flex
                  justify-center
                  items-center
                  px-2
                  border border-primary5
                "
              >
                <i class="bi bi-lightning text-xl text-primary5"></i>
                <p class="px-1 text-lg text-primary5">
                  {{ document?.events.length }}
                </p>
              </div>
              <div
                class="
                  mx-2
                  h-8
                  rounded
                  flex
                  justify-center
                  items-center
                  px-2
                  border border-warning
                "
              >
                <i class="bi bi-bookmark-check text-xl text-warning"></i>
                <p class="px-1 text-lg text-warning">
                  {{ document?.docAudits.length }}
                </p>
              </div>
            </div>
            <div
              class="infos__wrapper border border-secondary3 text-secondary4"
            >
              <div
                v-can.any="['download-documents', 'employee-readDocuments']"
                :class="[
                  {
                    'infos__item--active flex items-center':
                      document?.signedAt && document?.contacts?.length > 0
                  },
                  'infos__item'
                ]"
                @click="downloadFile(document)"
              >
                <i class="bi bi-download text-secondary7" />
              </div>
              <el-popover
                v-can.any="['write-documents']"
                placement="bottom"
                width="25%"
                trigger="click"
                :visible="updateDisplay"
              >
                <template #reference>
                  <div
                    v-can.any="['write-documents']"
                    v-if="document"
                    :class="[
                      {
                        'infos__item--active flex items-center':
                          document.signedAt && document.contacts.length > 0
                      },
                      'infos__item'
                    ]"
                    @click="updateDisplay = !updateDisplay"
                  >
                    <i class="bi bi-files text-secondary7" />
                  </div>
                </template>
                <div class="p-2">
                  <DocumentUpdate
                    :document-id="document.id"
                    @updated="documentUpdated"
                  />
                </div>
              </el-popover>

              <div
                v-if="onEventRoute()"
                :class="`infos__item border-secondary3 bg-${
                  isAlreadyAttached ? 'primary8' : ''
                } text-${isAlreadyAttached ? 'white' : 'secondary7'}`"
                @click="() => attachDocumentToEvent(document.id)"
              >
                <i
                  :class="`bi bi-link-45deg text-2xl ${
                    isAlreadyAttached ? 'fill-white' : ''
                  }`"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="displayStyle !== 'integred'"
          class="close__button"
          @click="$emit('close-preview')"
        >
          <i class="bi-x" />
        </div>
      </div>
      <div
        v-if="document"
        :class="`pdf-layout ${
          isAlreadyAttached ? 'border-2 border-primary8' : ''
        }`"
      >
        <PDFViewer
          :page="0"
          searched-string="test"
          :url="documentUrl"
          :document-id="document.id"
          @updateCurrentPage="$emit('update-current-page', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import PDFViewer from '@/components/PDFViewer/express.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { Document } from '@/services/api/models'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { truncText } from '@/utils'
  import DocumentUpdate from './DocumentUpdate.vue'
  import { ElPopover } from 'element-plus'

  export default defineComponent({
    name: 'DocPreview',
    components: {
      PDFViewer,
      ElPopover,
      EmptyState,
      DocumentUpdate
    },
    props: {
      document: {
        type: Object as () => Document,
        default: null
      },
      displayStyle: { type: String, default: '' }
    },
    emits: ['close-preview', 'update-current-page'],
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const updateDisplay = ref(false)

      const fileLabel = ref('')
      const attachedFiles = computed<Document[]>(
        () => store.getters['organizations/attachedFiles']
      )
      const isAlreadyAttached = computed<boolean>(() =>
        attachedFiles.value.map((x) => x.id).includes(props.document?.id)
      )
      const fileReadUrl = computed(
        () =>
          props.document.documentVersions?.find((x) => x.isMaster)?.documentURL ||
          ''
      )
      const downloadFile = (document: Document) => {
        window.open(
          document.documentVersions.find((x) => x.isMaster)?.documentURL,
          document.label || document.name || ''
        )
      }
      const documentUrl = computed(() => decodeURIComponent(fileReadUrl.value))
      const onEventRoute = () =>
        ['company.eventDetails', 'createEvent', 'company.eventUpdate'].includes(
          route.name?.toString() || ''
        )

      const attachDocumentToEvent = (docId: string) => {
        console.log(isAlreadyAttached.value, 'attachedFile')
        let remove = false
        let files = [...attachedFiles.value]
        let message = t('extraction.secondLevel.events.attachedFile')
        if (!attachedFiles.value.map((x) => x.id).includes(docId)) {
          files.push(props.document)
        } else {
          message = t('extraction.secondLevel.events.detachedFile')
          remove = true
          files = attachedFiles.value.filter(
            (doc) => doc.id !== props.document.id
          )
        }

        store.commit('organizations/ATTACH_FILES_TO_EVENT', {
          files,
          docId,
          remove
        })

        store.dispatch('toolbox/displayToast', {
          type: TYPE.INFO,
          context: t('extraction.secondLevel.events.context'),
          message
        })
      }

      const documentUpdated = () => {
        updateDisplay.value = false
      }

      const handleFilesSelection = (files: File[]) => {
        const [file] = files
        fileLabel.value = file.name.replace('.pdf', '')
      }

      return {
        documentUrl,
        isAlreadyAttached,
        moment,
        fileLabel,
        updateDisplay,
        onEventRoute,
        documentUpdated,
        downloadFile,
        truncText,
        attachDocumentToEvent,
        handleFilesSelection
      }
    },
    data() {
      return {
        toggleAudit: false
      }
    }
  })
</script>

<style lang="scss" scoped>
@import 'docpreview.scss';
</style>
