<template>
  <div>
    <div class="w-full flex-wrap flex event-bg">
      <div class="p-3 border-r border-secondary6 w-1/4">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.fieldsLabels.stock') }}
        </h1>
        <p v-if="stock" class="text-blackp text-sm text-left">
          <Tag
            v-if="stock.stockAlias"
            :value="stock.stockAlias.id"
            :text="stock.stockAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="stock.optionAlias"
            :text="stock.optionAlias.alias.toUpperCase()"
            :value="stock.optionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="stock.compositionAlias"
            :value="stock.compositionAlias.id"
            :text="stock.compositionAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
          />
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>

      <div class="p-3 border-r border-secondary6 w-1/4">
        <h1 class="text-left text-secondary7 text-xs font-light">
          {{ $t('events.fieldsLabels.delegation') }}
        </h1>
        <p
          v-if="stock && stock.delegation"
          class="text-blackp text-sm text-left"
        >
          {{ stock.delegation.label }}
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
      <div class="p-3 border-secondary6 border-r w-1/4">
        <h1 class="text-left text-secondary7 text-xs font-light">
          {{ $t('events.fieldsLabels.profitSharingPlan') }}
        </h1>
        <p
          v-if="stock?.profitSharingPlan"
          class="text-blackp text-sm text-left"
        >
          {{ stock?.profitSharingPlan?.name }}
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
      <div class="p-3 border-secondary6 w-1/4">
        <h1 class="text-left text-secondary7 text-xs font-light">
          {{ $t('events.fieldsLabels.financingRound') }}
        </h1>
        <p v-if="stock?.financingRound" class="text-blackp text-sm text-left">
          {{ stock?.financingRound?.name }}
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
    </div>
    <div :class="`relative flex overflow-scroll grid grid-cols-${colsCount}`">
      <div class="p-3 flex items-end col-span-2">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.contact') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.totalPrice') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.unitPrice') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.releasedAmount') }}
        </h1>
      </div>

      <div v-if="!isOptionIssuance" class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.issuancePremiumPerAction') }}
        </h1>
      </div>
      <div v-if="!isOptionIssuance" class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.totalIssuancePremium') }}
        </h1>
      </div>
      <div v-if="!isOptionIssuance" class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.nominalIncrease') }}
        </h1>
      </div>
      <div v-if="isOptionIssuance" class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.startAt') }}
        </h1>
      </div>

      <div v-if="isOptionIssuance" class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.vestingName') }}
        </h1>
      </div>

      <div v-if="canDisplayAction" class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light"></h1>
      </div>
    </div>
    <div class="event-bg relative">
      <div
        v-if="isLoadingParts"
        class="absolute w-full h-full z-50 flex justify-center items-center"
      >
        <EclipseLoader></EclipseLoader>
      </div>
      <div
        v-for="(part, index) in isOverLimit
          ? parts.slice(0, limit)
          : isStockIssuance && isDetailPage
          ? issuanceParts
          : parts"
        :key="part.id"
        :class="`w-full flex-wrap flex ${
          contactsId.includes(part.contact?.id) ? 'bg-active font-bold' : ''
        } grid grid-cols-${colsCount} ${
          index > 0 ? 'border-t border-secondary6' : ''
        } ${
          isOverLimit && index === limit - 1 ? 'border-b border-secondary6' : ''
        } `"
      >
        <div
          class="
            p-2
            border-r border-secondary6
            col-span-2
            flex
            justify-start
            items-center
            overflow-hidden
          "
        >
          <p v-if="part.contact" class="text-blackp text-sm text-left">
            <router-link
              :target="`${isPortfolio ? '_blank' : ''}`"
              :to="{
                name: 'company.directory',
                params: {
                  id: companyId || $route?.params?.id,
                  contactId: part.contact.id
                }
              }"
            >
              <ContactBadge
                class="p-0 text-xs"
                :contact="part.contact"
                :details="false"
                type="light"
              />
            </router-link>
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(part.quantity) }}
          </p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(part.totalPrice) }} {{ currency }}
          </p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(calculateUnitPrice(part)) }} {{ currency }}
          </p>
        </div>
        <div
          :class="`p-3 border-r${
            isOptionIssuance ? '-0' : ''
          } border-secondary6 flex justify-end items-center`"
        >
          <p
            v-if="part.totalPrice !== null"
            class="text-blackp text-sm text-right"
          >
            {{ formatNumber(part.releasedAmount) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-right">-</p>
        </div>
        <div
          v-if="!isOptionIssuance"
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p
            v-if="calculateUnitPrice(part) > 0"
            class="text-blackp text-sm text-right"
          >
            {{ formatNumber(emitPerAction(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-right">-</p>
        </div>
        <div
          v-if="!isOptionIssuance"
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p
            v-if="emitPerAction(part) !== '-'"
            class="text-blackp text-sm text-right"
          >
            {{ formatNumber(emitTotal(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          v-if="!isOptionIssuance"
          class="p-3 border-secondary6 flex justify-end items-center"
        >
          <p
            v-if="augNominal(part) !== '-'"
            class="text-blackp text-sm text-right"
          >
            {{ formatNumber(augNominal(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          v-if="isOptionIssuance"
          class="p-3 border-l border-secondary6 flex justify-end items-center"
        >
          <p
            v-if="part.vestingStartDate"
            class="text-blackp text-sm text-right"
          >
            {{ moment.utc(part.vestingStartDate).format('l') }}
          </p>
        </div>
        <div
          v-if="isOptionIssuance"
          class="p-3 border-l border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ part?.vestingCalendar?.name || '-' }}
          </p>
        </div>
        <div
          v-if="canDisplayAction"
          class="p-3 border-l border-secondary6 flex justify-end items-center"
        >
          <el-popconfirm
            :confirm-button-text="$t('confirm.yes')"
            :cancel-button-text="$t('confirm.no')"
            :title="$t('global.confirmDelete')"
            @confirm="removePart(part.id)"
          >
            <template #reference>
              <Button
                variant="secondary"
                size="medium"
                class="p-1 rounded-sm action-btn"
              >
                <i class="bi bi-trash text-xl"></i>
              </Button>
            </template>
          </el-popconfirm>
        </div>
      </div>

      <div v-if="isOverLimit" class="flex justify-end items-end">
        <h5 class="py-3 px-2 text-md text-primary4">
          <router-link
            :to="{
              name: isPortfolio
                ? 'company.eventDetails'
                : 'company.eventDetails',
              params: { eventId: event?.id }
            }"
            >...{{ $t('global.seeMoreParts') }}</router-link
          >
        </h5>
      </div>
      <div
        v-if="isDetailPage || !isOverLimit"
        :class="`grid grid-cols-${colsCount} ${
          parts?.length > 0 ? 'border-t border-secondary6 ' : ''
        } h-10 flex`"
      >
        <div
          class="
            col-span-2
            border-r border-secondary6
            h-10
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalQuantity) }}
          </p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalTotal) }} {{ currency }}
          </p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
        <div
          :class="`border-r${
            isOptionIssuance ? '-0' : ''
          } border-secondary6 h-10 flex items-center justify-end`"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalReleased) }} {{ currency }}
          </p>
        </div>
        <div
          v-if="!isOptionIssuance"
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalIssuancePerAction) }} {{ currency }}
          </p>
        </div>
        <div
          v-if="!isOptionIssuance"
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalEmit) }} {{ currency }}
          </p>
        </div>
        <div
          v-if="!isOptionIssuance"
          class="h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalNominalIncrease) }} {{ currency }}
          </p>
        </div>
        <div
          v-if="isOptionIssuance"
          class="h-10 flex items-center justify-end border-l border-secondary6"
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
        <div
          v-if="isOptionIssuance"
          class="h-10 flex items-center justify-end border-l border-secondary6"
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
        <div
          v-if="canDisplayAction"
          class="
            h-10
            flex
            items-center
            justify-end
            border-l border-r border-secondary6
          "
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-5" v-if="canDisplayAction">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        class="pr-0"
        :page-size="pagination.limit"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        @current-change="handlePageSelection"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRefs, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { Tag, Button } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { isNumber } from 'lodash'
  import { CompanyModel, Event, NominalValue } from '@/services/api/models'
  import { EventType } from '@/types/event'
  import { useRoute, useRouter } from 'vue-router'
  import { Pagination } from '@/store/modules/Organization/models'
  import { ElPagination } from 'element-plus'
  import EclipseLoader from '@/components/Loader/eclipse.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { ElPopconfirm } from 'element-plus'

  export default defineComponent({
    name: 'IssuanceCard',
    components: {
      Tag,
      ContactBadge,
      ElPagination,
      EclipseLoader,
      ElPopconfirm,
      Button
    },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      // const { nominalValue } = toRefs(props)
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const companyId = computed<string | undefined>(
        () => props.event?.company?.id
      )
      const isLoadingParts = ref(false)

      const issuanceParts = ref<any[]>(
        props.event?.stockIssuance?.stockIssuanceParts ?? []
      )
      const limit = computed<number>(
        () => store.getters['config/eventPartsLimit']
      )
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })
      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )
      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.date).unix() > moment.utc(x.date).unix()
        )
        return nv?.nominalValue
      })

      const isLoading = ref(false)
      const stock = computed<any>(() => {
        switch (props.event?.type?.toString()) {
          case 'OPTION_ISSUANCE':
            return props.event?.optionIssuance
          case 'COMPOSITION_ISSUANCE':
            return props.event?.compositionIssuance
          default:
            return props.event?.stockIssuance
        }
      })

      const isDetailPage = computed(() => route.name === 'company.eventDetails')

      const isOptionIssuance = computed(
        () => props.event?.type?.toString() === EventType.optionIssuance
      )

      const isStockIssuance = computed(
        () => props.event?.type?.toString() === EventType.stockIssuance
      )

      const isCompositionIssuance = computed(
        () => props.event?.type?.toString() === EventType.compositionIssuance
      )
      const canDisplayAction = computed(
        () =>
          isDetailPage.value &&
          (isStockIssuance.value || isOptionIssuance.value)
      )

      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed<any[]>(() => {
        switch (props.event?.type?.toString()) {
          case 'OPTION_ISSUANCE':
            return stock.value?.optionIssuanceParts
          case 'COMPOSITION_ISSUANCE':
            return stock.value?.compositionIssuanceParts || []
          default:
            return stock.value?.stockIssuanceParts || []
        }
      })

      const colsCount = computed(() => {
        let value = 8
        if (!canDisplayAction.value) {
          if (!isOptionIssuance.value) value = 9
        } else {
          if (!isCompositionIssuance.value && !isOptionIssuance.value)
            value = 10
          else value = 9
        }

        return value
      })

      const isOverLimit = computed(
        () =>
          !isDetailPage.value &&
          isStockIssuance.value &&
          parts.value.length > limit.value
      )

      const totalQuantity = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce((total: number, p: any) => (total += p.quantity), 0)
      )
      const globalReduction = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )

      const globalReleased = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce(
          (acc: number, line: any) =>
            (acc += parseFloat(line.releasedAmount || 0)),
          0
        )
      )
      const globalEmit = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce(
          (acc: number, line: any) =>
            (acc +=
              (parseFloat(line.totalPrice) / parseInt(line.quantity || 0) -
                nominalValue.value) *
              parseInt(line.quantity || 0)),
          0
        )
      )

      const globalIssuancePerAction = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce(
          (acc: number, line: any) =>
            (acc +=
              parseInt(line.totalPrice) / parseInt(line.quantity || 0) -
              nominalValue.value),
          0
        )
      )
      const globalNominalIncrease = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )
      const globalTotal = computed(() =>
        (
          (isStockIssuance.value ? issuanceParts.value : parts.value) || []
        ).reduce(
          (acc: number, line: any) => (acc += parseFloat(line.totalPrice || 0)),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value =
            parseFloat(globalTotal.value?.toString()) / totalQuantity.value
        }
        return value
      })

      const calculateUnitPrice = (row: any) => {
        if (!row.totalPrice || !row.quantity) return 0

        return row.totalPrice / row.quantity
      }

      const emitPerAction = (row: any) => {
        const unitPrice = calculateUnitPrice(row)

        if (!isNumber(unitPrice)) {
          return '-'
        }

        return Math.abs(unitPrice - nominalValue.value)
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseFloat(ePerAction.toString()) * row.quantity
      }

      const augNominal = (row: any) => {
        if (nominalValue.value < 0) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      const handlePageSelection = (page: number) => {
        getSTockIssuanceParts(props.event?.stockIssuance?.id, page)
      }

      const removePart = (partId: string) => {
        let id = props.event?.optionIssuance?.id
        let action = 'REMOVE_OPTION_ISSUANCE_PART'
        if (isStockIssuance.value) {
          id = props.event?.stockIssuance?.id
          action = 'REMOVE_STOCK_ISSUANCE_PART'
        }
        store.dispatch(`organizations/${action}`, {
          companyId: company.value?.id,
          stockIssuanceId: id,
          partId,
          onSuccess: () => {
            if (
              pagination.value.lastPage === 1 &&
              (parts.value.length === 1 || issuanceParts.value.length === 1)
            ) {
              router.go(-1)
            } else handlePageSelection(pagination.value.currentPage || 1)
          },
          onError: () => {
            //Oooops
          }
        })
      }

      const getSTockIssuanceParts = (id: string | undefined, page = 1) => {
        if (!id) return

        isLoadingParts.value = true
        store.dispatch('organizations/GET_STOCK_ISSUANCE_PARTS', {
          companyId: company.value?.id,
          stockIssuanceId: id,
          page,
          limit: isDetailPage.value ? 500 : null,
          onSuccess: (response: any, pageLimit = 0) => {
            const { data, metadata } = response
            pagination.value = {
              ...metadata,
              total: metadata?.count,
              limit: pageLimit
            }
            issuanceParts.value = [...data]
            isLoadingParts.value = false
          },
          onError: () => {
            isLoadingParts.value = false
          }
        })
      }

      watch(
        () => props.event,
        () => {
          getSTockIssuanceParts(props.event?.stockIssuance?.id)
        }
      )

      onMounted(() => {
        getSTockIssuanceParts(props.event?.stockIssuance?.id)
      })

      return {
        currency,
        parts,
        stock,
        limit,
        moment,
        globalReduction,
        totalQuantity,
        globalReleased,
        globalUnitPrice,
        globalNominalIncrease,
        globalIssuancePerAction,
        globalEmit,
        globalTotal,
        isLoading,
        isOptionIssuance,
        contactsId,
        issuanceParts,
        isStockIssuance,
        isOverLimit,
        pagination,
        isDetailPage,
        isLoadingParts,
        canDisplayAction,
        colsCount,
        companyId,
        isCompositionIssuance,
        formatNumber,
        calculateUnitPrice,
        emitPerAction,
        emitTotal,
        handlePageSelection,
        augNominal,
        removePart
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;

    .grid-cols-10 {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    .action-btn {
      border-radius: 5px !important;

      &:hover {
        @apply bg-error text-white border-error;
      }
    }
    .w-1-7 {
      width: calc(100% / 7);
    }
  }
</style>
