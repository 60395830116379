
  import { computed, defineComponent, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import {
    ENDOFPERIOD,
    PERIODBEHAVIOR,
    ROUNDINGMETHOD,
    STARTOFPERIOD,
    TIMEUNIT,
    VestingForm,
    VestingPlanning,
    VESTINGTYPE
  } from '@/types'
  import { vestingTableColumns } from '@/utils/data'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { VESTINGVALUEUNIT } from '@/types/forms'
  import { formatNumber } from '@/utils'
  import { ElPopover } from 'element-plus'

  export default defineComponent({
    components: {
      DataTable,
      Multiselect,
      Field,
      Button,
      UplInput: Input,
      ElPopover
    },
    props: {
      vesting: {
        type: Object as () => VestingForm,
        required: true
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['removeItem', 'addNewItem', 'duplicateLine', 'fieldChanged'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const periods = ref<VestingPlanning[]>(props.vesting.vestingPeriods)
      const rowsToAdd = ref(1)
      const timesToDuplicate = ref(1)
      const startOfPeriods = ref(
        Object.keys(STARTOFPERIOD).map((x) => ({
          name: t(`startOfPeriods.${x}`),
          value: x,
          id: x
        }))
      )

      const fisrtStartOfPeriods = computed(() =>
        startOfPeriods.value.filter(
          (x) => x.id !== STARTOFPERIOD.LAST_PERIOD_END_PLUS_ONE
        )
      )
      const timeUnits = ref(
        Object.keys(TIMEUNIT).map((x) => ({
          name: t(`timeUnits.${x}`),
          value: x,
          id: x
        }))
      )
      const endOfPeriods = ref(
        Object.keys(ENDOFPERIOD).map((x) => ({
          name: t(`endOfPeriods.${x}`),
          value: x,
          id: x
        }))
      )
      const vestingTypes = Object.values(VESTINGTYPE).map((x) => ({
        name: t(`vestingTypes.${x}`),
        value: x,
        id: x
      }))
      const roundingMethods = ref(
        Object.keys(ROUNDINGMETHOD).map((x) => ({
          name: t(`roundingMethods.${x}`),
          value: x,
          id: x
        }))
      )

      const periodBehaviors = ref(
        Object.keys(PERIODBEHAVIOR).map((x) => ({
          name: t(`periodBehaviors.${x}`),
          value: x,
          id: x
        }))
      )

      const columns = computed(() => vestingTableColumns)

      const inputValueChanged = (value: any) => {
        timesToDuplicate.value = value.target.value
      }

      const rowValueChanged = (value: any) => {
        rowsToAdd.value = value.target.value
      }
      const calculateValue = () =>
        parseFloat(
          periods.value
            .reduce(
              (acc: number, p: any) =>
                (acc += parseFloat(p?.value?.toString() || '0') || 0),
              0
            )
            .toFixed(4)
        )

      const calculateGlobalUnit = () =>
        periods.value.reduce(
          (acc, p) => (acc += parseInt(p?.nbTimeUnits?.toString() || '0') || 0),
          0
        )
      const addRows = () => {
        emit('addNewItem', rowsToAdd.value || 1)
        rowsToAdd.value = 1
      }

      const duplicate = (index: number) => {
        emit('duplicateLine', index, timesToDuplicate.value)
        timesToDuplicate.value = 1
      }

      const removeRow = (row: any) => {
        emit('removeItem', row)
      }

      watch(props, (newProps) => {
        periods.value = (newProps.vesting.vestingPeriods || [])?.sort(
          (a, b) => a.order - b.order
        )

        rowsToAdd.value = 1
      })
      return {
        periods,
        columns,
        periodBehaviors,
        roundingMethods,
        vestingTypes,
        endOfPeriods,
        timeUnits,
        startOfPeriods,
        rowsToAdd,
        fisrtStartOfPeriods,
        addRows,
        removeRow,
        calculateValue,
        inputValueChanged,
        rowValueChanged,
        calculateGlobalUnit,
        duplicate,
        formatNumber,
        timesToDuplicate,
        VESTINGVALUEUNIT
      }
    }
  })
