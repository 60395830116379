
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Datepicker from '@/components/DatePicker/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { eventCategoriesList } from '@/utils/data'
  import moment from 'moment'
  import { Field } from 'vee-validate'
  import SearchResult from '@/components/DocumentSearchResult/index.vue'
  import { ElTooltip } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'FiltersCorporate',
    components: {
      Datepicker,
      SearchResult,
      DropdownContacts,
      Multiselect,
      DropdownCompany,
      Input,
      ElTooltip,
      Button,
      Field
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object
      },
      type: {
        type: String,
        required: true
      }
    },
    emits: ['update-filters'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const search = ref('')
      const { t } = useI18n()
      const resultPopover = ref(null)
      const { filters } = toRefs(props)
      const filtersData = ref<any>(filters.value)
      const contactsId = ref([])
      const draft = ref([])
      const categories = ref([])
      const stockIds = ref<string[]>([])
      const aliasesId = ref<string[]>([])
      const companyIds = ref<string[]>([])
      const eventTypes = ref([])
      const operationsId = ref([])
      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])
      const picked = ref([])
      const status = computed(() => [
        { id: true, name: t('events.draftStatus.isDraft') },
        { id: false, name: t('events.draftStatus.validated') }
      ])
      const allContacts = computed(() => store.getters['portfolio/contacts'])
      const contacts = computed(() =>
        allContacts.value.reduce((acc: any[], c: any) => {
          if (!c?.internalId1) acc.push(c)
          else if (
            c?.internalId1 &&
            !acc.map((x: any) => x.internalId1).includes(c.internalId1)
          )
            acc.push(c)
          else {
            const already = acc.find((y) => y.internalId1 === c.internalId1)

            if (already) already.id = `${already.id}@_${c.id}`
          }

          return acc
        }, [])
      )
      const organs = computed(() => store.getters['organizations/committees'])
      const eventCategories = ref(eventCategoriesList)
      const documents = computed(
        () => store.getters['organizations/corporateDocuments']
      )
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const operations = computed(
        () => store.getters['organizations/operations']
      )
      const resetFilters = () => {
        picked.value = []

        filtersData.value = {
          endDate: moment().format('YYYY-MM-DD'),
          startDate: '1970-01-01'
        }
        aliasesId.value = []
        stockIds.value = []
        contactsId.value = []
        categories.value = []
        eventTypes.value = []
        operationsId.value = []
        getContacts()
        emit('update-filters', filtersData.value, props.type)
      }

      const updateFilters = (e: any, filter: string, field = '') => {
        filtersData.value[filter] = e
        const data = { ...filtersData.value }
        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft

        emit('update-filters', data, props.type, field)

        if (filter == 'companyIds') {
          getContacts()
        }
      }

      watch(picked, (newDateRange) => {
        let dateFilter: any = {
          endDate: moment.utc().format('YYYY-MM-DD'),
          startDate: '1970-01-01'
        }
        if (newDateRange && newDateRange.length > 0) {
          dateFilter = {
            startDate:
              newDateRange && newDateRange[0]
                ? moment.utc(newDateRange[0]).startOf('day').toISOString()
                : '1970-01-01',
            endDate:
              newDateRange && newDateRange[1]
                ? moment.utc(newDateRange[1]).endOf('day').toISOString()
                : moment().format('YYYY-MM-DD')
          }
        }
        filtersData.value = { ...filtersData.value, ...dateFilter }
        emit('update-filters', filtersData.value, props.type)
      })

      const initSearch = () => {
        filtersData.value.search = null
      }
      const hideSearchResults = () => {
        filtersData.value.search = null
      }

      const handleContactSearch = (e: string) => {
        getContacts(e)
      }

      const getContacts = (search = '') => {
        let data: any = {
          companyIds: filtersData.value['companyIds'],
          limit: 20,
          offset: 0
        }

        if (search) data = { ...data, name: search }
        else delete data.name
        store.dispatch('portfolio/GET_CONTACTS', {
          filter: data
        })
      }

      onMounted(() => {
        getContacts()
      })

      return {
        search,
        eventTypes,
        status,
        draft,
        documentCategories,
        eventCategories,
        categories,
        contacts,
        contactsId,
        organs,
        documents,
        stockIds,
        aliasesId,
        picked,
        companyIds,
        filtersData,
        updateFilters,
        resetFilters,
        hideSearchResults,
        handleContactSearch,
        searchResults,
        resultPopover,
        initSearch,
        operationsId,
        operations
      }
    }
  })
