
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { computed, defineComponent, ref } from 'vue'
  import FormWrapper from '@/components/Forms/Event/Generic/FormWrapper.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { NominalValue } from '@/services/api/models'
  import { EventType } from '@/types/event'
  export default defineComponent({
    name: 'EventForm',
    components: {
      FormWrapper,
      Multiselect,
      Checkbox
    },
    props: {
      uuid: {
        type: String,
        default: ''
      }
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()

      const isDraft = ref(false)

      const selectedCat = ref('')

      const onOptionSelected = (value: string) => {
        selectedCat.value = value
      }
      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const mockCats = [
        {
          id: '10',
          name: t(`events.categories.stockOperations`),
          value: 'cancel',
          group: [
            {
              id: EventType.capitalReduction,
              name: t(`events.categories.cancel`),
              value: 'cancel'
            },
            {
              id: EventType.conversion,
              name: t(`events.categories.conversion`),
              value: 'conversion'
            },
            {
              id: EventType.parityChange,
              name: t(`events.categories.parityChange`),
              value: 'parityChange'
            },
            {
              id: EventType.optionSunset,
              name: t(`events.categories.lapseOptions`),
              value: 'lapseOptions'
            },
            {
              id: EventType.freeSharesSunset,
              name: t(`events.categories.freeSharesSunsets`),
              value: 'freeSharesSunsets'
            }
          ]
        },
        {
          id: '31',
          name: t(`events.categories.emit`),
          value: 'emit',
          group: [
            {
              id: EventType.stockIssuance,
              name: t(`events.categories.stockIssuance`),
              value: 'primary'
            },
            {
              id: EventType.optionIssuance,
              name: t(`events.categories.optionIssuance`),
              value: 'options'
            },
            {
              id: EventType.compositionIssuance,
              name: t(`events.categories.compositionIssuance`),
              value: 'composition'
            },
            {
              id: EventType.freeShareIssuance,
              name: t(`events.categories.freeShareIssuance`),
              value: 'freeShareIssuance'
            },
            {
              id: EventType.optionExercise,
              name: t(`events.categories.exercise`),
              value: 'exercise'
            },
            {
              id: EventType.freeSharesFinalAcquisition,
              name: t(`events.categories.finalAcquisition`),
              value: 'finalAcquisition'
            }
          ]
        },
        {
          id: '40',
          name: t(`events.categories.transfer`),
          value: 'transfer',
          group: [
            {
              id: EventType.transfer,
              name: t(`events.categories.transfer`),
              value: 'transfer'
            }
          ]
        },
        {
          id: '50',
          name: t(`events.categories.nominal`),
          value: 'nominal',
          group: [
            {
              id: EventType.nominalValue,
              name: t(
                `events.categories.${
                  nominalValues.value.length
                    ? 'edit_nominal_value'
                    : 'fix_nominal_value'
                }`
              ),
              value: 'fix_nominal_value'
            }
          ]
        },
        {
          id: '60',
          name: t(`events.categories.delegation`),
          value: 'delegation',
          group: [
            {
              id: EventType.commongCeiling,
              name: t(`events.categories.commonCeiling`),
              value: 'commonCeiling'
            },
            {
              id: EventType.delegation,
              name: t(`events.categories.delegation`),
              value: 'delegation'
            }
            // {
            //   id: EventType.delegationSunset,
            //   name: t(`events.categories.delegationSunset`),
            //   value: 'delegationSunset'
            // }
          ]
        },
        {
          id: '70',
          name: t(`events.categories.vesting`),
          value: 'vesting',
          group: [
            {
              id: EventType.vestingSuspension,
              name: t(`events.categories.suspension`),
              value: 'suspension'
            },
            {
              id: EventType.vestingAcceleration,
              name: t(`events.categories.acceleration`),
              value: 'acceleration'
            },
            {
              id: EventType.exerciseDelayProrogation,
              name: t(`events.categories.prorogation`),
              value: 'prorogation'
            }
          ]
        },
        {
          id: '80',
          name: t(`events.categories.mandates`),
          value: 'nominal',
          group: [
            {
              id: EventType.appointment,
              name: t(`events.categories.nomination`),
              value: 'nomination'
            },
            {
              id: EventType.appointmentTermination,
              name: t(`events.categories.officeTermination`),
              value: 'appointmentTermination'
            }
          ]
        },

        {
          id: '100',
          name: t(`events.categories.social`),
          value: 'social',
          group: [
            {
              id: EventType.financialEnding,
              name: t(`events.categories.socialEnd`),
              value: 'socialEnd'
            },
            {
              id: EventType.accountApproval,
              name: t(`events.categories.approbation`),
              value: 'socialAppro'
            }
          ]
        }
      ]
      const categories = computed(() => mockCats)

      return {
        selectedCat,
        categories,
        onOptionSelected,
        isDraft
      }
    }
  })
