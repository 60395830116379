<template>
  <div
    class="
      authentifications-wrapper
      container-fluid
      px-4
      py-5
      flex flex-col
      w-full
      justify-start
      items-start
    "
  >
    <h1 class="text-left text-secondary9">
      {{ $t('account.accountAuthentifications.baseline') }}
    </h1>
    <section
      class="py-4 mt-4 w-full flex flex-col items-start md:w-full lg:w-3/4"
    >
      <h3>{{ $t('account.accountAuthentifications.updatePassword') }}</h3>

      <!-- CURRENT PASSWORD -->
      <Form
        v-slot="{ errors, values, meta }"
        :validation-schema="schema"
        class="flex flex-col w-full mt-5"
      >
        <div class="flex w-full">
          <!-- NEW PASSWORD -->
          <div class="w-1/2 form-group pr-2 mr-3">
            <label class="text-left">{{
              $t('account.accountAuthentifications.form.newPassword.label')
            }}</label>
            <Field
              v-slot="{ field }"
              v-model="password.new"
              type="password"
              name="password"
            >
              <UplInput
                v-bind="field"
                type="password"
                :error="
                  errors && errors.password
                    ? $t(`errors.${errors.password}`)
                    : null
                "
                autocomplete="new-password"
                class="mw-100 w-100"
                :placeholder="
                  $t(
                    'account.accountAuthentifications.form.newPassword.placeholder'
                  )
                "
              />
            </Field>
          </div>

          <!-- CONFIRM PASSWORD -->
          <div class="w-1/2 form-group">
            <label class="text-left">{{
              $t(
                'account.accountAuthentifications.form.newPasswordConfirm.label'
              )
            }}</label>

            <Field
              v-slot="{ field }"
              v-model="password.confirm"
              type="password"
              name="confirmation"
              ><UplInput
                v-bind="field"
                autocomplete="new-password"
                :error="
                  errors && errors.passwordConfirmation
                    ? $t(`errors.${errors.passwordConfirmation}`)
                    : null
                "
                class="mw-100 w-100"
                name="confirmation"
                type="password"
                :placeholder="
                  $t(
                    'account.accountAuthentifications.form.newPasswordConfirm.placeholder'
                  )
                "
              />
            </Field>
          </div>
        </div>
        <div class="mt-7 text-xl w-full flex justify-end items-center">
          <Btn
            class=""
            :disabled="
              password.new !== password.confirm || !password.new || !meta.valid
            "
            name="fo_accountAuthentication_submit"
            :label="$t('account.accountAuthentifications.form.submit')"
            @click="updatePassword(values)"
          />
        </div>
      </Form>
    </section>

    <div class="flex justify-center items-center text-warning mt-10 w-full">
      <i class="bi bi-exclamation-triangle mr-3"></i>
      <h5 class="text-warning">
        {{ $t('account.accountInformations.passwordNotice') }}
      </h5>
    </div>

    <!-- <hr class="divide-y divide-secondary8 w-full" /> -->
    <section
      v-if="false"
      class="doubleAuth__wrapper flex flex-col justify-start items-start w-full"
    >
      <h3 class="s-title-2">Double authentification</h3>
      <h5 class="sub-title">
        Choisisez un moyen de recevoir votre code d'activation
      </h5>

      <div class="doubleAuth__mode mt-2 flex w-1/2 justify-start items-start">
        <div
          :class="[
            'doubleAuth__mode_item w-1/2',
            { 'doubleAuth__mode_item--disabled': dblAuthType !== 'PHONE' }
          ]"
        >
          <input
            id="phone_form"
            v-model="dblAuthType"
            type="radio"
            name="dblAuth"
            value="PHONE"
          />
          <label class="rounded" for="phone_form" />
          <div class="form-group w-1/3 flex flex-col justify-start items-start">
            <label class="w-100 m-0 ml-2">Par téléphone</label>
            <input
              v-model="dblAuthNumber"
              :disabled="dblAuthType !== 'PHONE'"
              type="text"
              class="w-100"
              placeholder="ex: 0123456789"
            />
          </div>
        </div>

        <hr class="mx-0 my-3 w-25" />
        <div
          :class="[
            'doubleAuth__mode_item flex w-1/2',
            { 'doubleAuth__mode_item--disabled': dblAuthType !== 'MAIL' }
          ]"
        >
          <input
            id="email_form"
            v-model="dblAuthType"
            type="radio"
            name="dblAuth"
            value="MAIL"
          />
          <label class="rounded" for="email_form" />
          <div class="form-group w-1/3 flex flex-col justify-start items-start">
            <label class="w-100 m-0 ml-2">Par email</label>
            <input
              v-model="dblAuthMail"
              :disabled="dblAuthType !== 'MAIL'"
              type="text"
              class="w-100"
              placeholder="ex: address@email.com"
            />
          </div>
        </div>
      </div>

      <Btn
        v-if="!isTwoFACodeValid"
        :spinner="false"
        class="mt-7"
        label="recevoir mon code de validation"
        @action="sendCode()"
      />
      <Btn
        v-if="isTwoFACodeValid"
        :spinner="false"
        class="mt-2"
        label="desactiver la double authentification"
        @action="isTwoFACodeValid = false"
      />

      <!-- Auth modal -->
      <div v-if="dblAuthCodeForm" class="doubleAuth__modal w-full h-full">
        <div class="doubleAuth__modal_inner z-50">
          <div class="flex justify-between items-center">
            <h3><i class="bi-lock" />Verification</h3>
            <div class="close__button" @click="dblAuthCodeForm = false">
              <i class="bi-x" />
            </div>
          </div>
          <h5 class="mt-1">
            Nous vous avons envoyé un
            {{ dblAuthType === 'MAIL' ? 'email' : 'SMS' }} avec le code de
            validation
          </h5>

          <div v-show="!checkCode" class="mt-2">
            <Box :type="'danger'"> le code saisi n'est pas correct. </Box>
          </div>
          <hr />
          <div class="flex flex-col justify-between items-center">
            <div class="form-group w-100" @click="checkCode = true">
              <h6 class="uppercase">code</h6>
              <UplInput
                v-model="authCode"
                type="number"
                name="authCode"
                class="w-48"
                max="6"
                :error="authCodeError ? $t(`errors.${authCodeError}`) : null"
              />
            </div>

            <Btn
              variant="secondary"
              class="sm mt-5 mt-1"
              label="Valider"
              @action="checkDblAuthCode()"
            >
              <template #left>
                <i class="bi-lock"></i>
              </template>
            </Btn>
          </div>

          <hr />

          <div class="form-group doubleAuth__modal_noreception">
            <label class="w-100 text text-lg"
              >Vous n'avez pas reçu le code ?</label
            >
            <div class="w-100 d-flex align-items-center">
              <i class="bi-arrow-clockwise" />
              <p class="ml-1">renvoyer un nouveau code</p>
            </div>
          </div>
        </div>

        <div class="overlay" @click="dblAuthCodeForm = false" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
  import { Button, Input } from '@up.law/uplaw-ui'
  import Box from '@/components/Box/index.vue'
  import { defineComponent, ref } from 'vue'
  import { useField, Form, Field } from 'vee-validate'
  import * as yup from 'yup'
  import { useStore } from '@/store'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { AxiosError } from 'axios'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    name: 'AccountAuthentifications',
    components: { Btn: Button, Box, UplInput: Input, Form, Field },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const schema = yup.object({
        password: yup.string().min(5),
        oldPassword: yup.string().min(5),
        passwordConfirmation: yup.string().min(4)
      })
      const password = ref({ password: '', confirmation: '' })
      const {
        value: authCode,
        meta: authMeta,
        errorMessage: authCodeError
      } = useField('authCode', yup.string().length(4).required(), {
        validateOnValueUpdate: true
      })
      const updatePassword = (values: any) => {
        console.log(values)
        store.dispatch(`auth/UPDATE_USER_PASSWORD`, {
          data: values,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.DEFAULT,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.successPasswordEdition`
              )
            })

            router.replace('/connect')
          },
          onError: (err: AxiosError) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`auth0.${err.message}`)
            })
          }
        })
      }
      return {
        schema,
        authCode,
        authCodeError,
        authMeta,
        password,
        updatePassword
      }
    },
    watch: {
      dblAuthType() {
        // this.dblAuthNumber = null
        // this.dblAuthMail = null
      }
    },
    methods: {
      sendCode() {
        const data = {
          // type: this.dblAuthType,
        }
      },
      checkDblAuthCode() {
        // const self = this
        // apiMe.validateDblAuthCode(this.validate2FA, (response) => {
        //   self.checkCode =
        //     this.validate2FA.twoFACode &&
        //     this.validate2FA.twoFACode.length === 5
        //   self.isTwoFACodeValid = self.checkCode
        //   self.dblAuthCodeForm = !self.checkCode
        // })
      }
    }
  })
</script>

<style scoped>
  .authentifications-wrapper {
    height: 100%;
    overflow-y: auto;
  }
</style>
