
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DataTable, { Column } from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Contact,
    FreeSharesAcquisitionCalendar,
    OptionStockAlias,
    VestingSynthesis
  } from '@/services/api/models'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { formatNumber, ucFirst } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { ElTooltip } from 'element-plus'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownFreeSharesVesting.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  export default defineComponent({
    components: {
      DataTable,
      Tag,
      DropdownActions,
      ContactBadge,
      EmptyState,
      Multiselect,
      DatePicker,
      Checkbox,
      Filter,
      Button,
      ElTooltip,
      Loader,
      DownloadButton,
      Number,
      DropdownVesting
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const selectedColumns = ref<string[]>([])

      const filter = ref<any>({
        vestingId: '',
        contactId: [],
        delegationId: [],
        stockAliasId: '',
        contactGroupId: '',
        date: new Date().toISOString()
      })
      const exerciceCalendars = computed<FreeSharesAcquisitionCalendar[]>(
        () => store.getters['organizations/freeSharesAcquisitionCalendars']
      )
      const pagination = computed(
        () =>
          store.getters[
            'organizations/freeSharesAcquisitionCalendarsPagination'
          ]
      )
      const exerciceCalendarsTotal = computed<VestingSynthesis>(
        () => store.getters['organizations/exerciseCalendarsTotal']
      )
      const currency = computed(() => store.getters['organizations/currency'])
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const loading = ref(true)

      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData'] && loading
      )

      const optionAliases = ref<OptionStockAlias[]>([])

      const contacts = computed<Contact[]>(() =>
        store.getters['organizations/contacts'].map((x: any) => ({
          ...x,
          name: ucFirst(x.name)
        }))
      )

      const total = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.freeSharesIssuancePart.quantity),
          0
        )
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c?.vestedQuantity || 0),
          0
        )
      )

      const ceil = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              (c?.vestedQuantity || 0) -
              (c?.acquiredQuantity || 0) -
              (c?.numberOfSunseted || 0)),
          0
        )
      )

      const obsoleteQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.numberOfSunseted || 0),
          0
        )
      )
      const exercisedQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.acquiredQuantity || 0),
          0
        )
      )

      const heldBalance = computed(
        () => total.value - exercisableQuantity.value
      )

      const exercisePrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )

      const lastStockPrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )
      const portentialGainAtDate = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )

      const totalExercisePrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )
      const potentialGain100Percent = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )
      const allColumns = computed(() => [
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          custom: true,
          order: 2
        },
        {
          label: t('events.fieldsLabels.contact'),
          field: 'user',
          custom: true,
          order: 1,
          sortBy: 'contact',
          sortFunction: (a: any, b: any) => {
            return a?.contact.name.localeCompare(b.contact.name)
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingStartDate'),
          field: 'vestingStartDate',
          custom: true,
          order: 3,
          sortBy: 'vestingStartDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].startDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].startDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingEndDate'),
          field: 'vestingEndDate',
          custom: true,
          filterable: true,
          selected: true,
          order: 4,
          sortBy: 'vestingEndDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].endDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].endDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.delegation'),
          field: 'delegation',
          custom: true,
          order: 4.5,
          filterable: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          order: 6,
          sortBy: 'allocatedQuantity',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity - b?.optionIssuancePart.quantity
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          filterable: true,
          order: 9,
          selected: true,
          sortBy: 'exercisableQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisableQuantity - b?.exercisableQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.free_shares_balance'),
          field: 'ceil',
          custom: true,
          filterable: true,
          order: 10,
          selected: true,
          sortBy: 'ceil',
          sortFunction: (a: any, b: any) => {
            return a?.exercisableBalance - b?.exercisableBalance
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          filterable: true,
          order: 8,
          selected: true,
          sortBy: 'lapsedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.numberOfSunsetedOptions - b?.numberOfSunsetedOptions
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.freeSharesExercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          filterable: true,
          order: 7,
          selected: true,
          sortBy: 'exercisedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisedQuantity - b?.exercisedQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.heldBalance'),
          field: 'heldBalance',
          custom: true,
          filterable: true,
          order: 10,
          selected: true,
          sortBy: 'heldBalance',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity -
              a?.vestedQuantity -
              (b?.optionIssuancePart.quantity - b?.vestedQuantity)
            )
          },
          type: 'function'
        },

        {
          label: t('events.fieldsLabels.totalExercisePrice'),
          field: 'totalExercisePrice',
          custom: true,
          filterable: true,
          order: 12.5,
          selected: false
        },

        {
          label: t('events.fieldsLabels.vestingPlan'),
          field: 'vesting',
          custom: true,
          filterable: true,
          order: 15
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          custom: true,

          order: 15,
          filterable: true,
          selected: true
        }
      ])
      const existingColumns = ref(allColumns.value.filter((x) => !x.filterable))
      const columns = ref(
        allColumns.value
          .filter((x) => !x.filterable || x.selected)
          .sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable || x.selected)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      selectedColumns.value = allColumns.value
        .filter((x) => x.selected)
        .map((x) => x.field)
      const handleFilterSelected = (value: string[]) => {
        columns.value = [
          ...existingColumns.value,
          ...allColumns.value.filter((x) => value.includes(x.field))
        ].sort((a: any, b: any) => a.order - b.order)

        selectedColumns.value = value
      }
      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }
      const getVesting = async (page = 1) => {
        await store.dispatch(
          'organizations/GET_FREE_SHARES_ACQUISITION_CALENDARS',
          {
            companyId: route.params.id,
            page,
            filter: {
              ...filter.value
            }
          }
        )

        loading.value = false
      }

      const getTotal = () => {
        store.dispatch('organizations/GET_EXERCISE_CALENDARS_TOTAL', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            ...filter.value
          }
        })
      }

      const onPageChange = (page: number) => {
        getVesting(page)
      }

      const resetFilters = () => {
        filter.value = {
          vestingId: '',
          contactId: [],
          delegationId: [],
          stockAliasId: '',
          contactGroupId: '',
          date: new Date().toISOString()
        }
      }
      const download = () => {
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS', {
          name: `${t('exports.shareholding')} `,
          companyId,
          filter: filter.value
        })
      }
      const getOrder = (field: string) =>
        columns.value.find((x) => x.field === field)?.order || 0

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: route.params.id,
          filter,
          onSuccess: (data: OptionStockAlias[]) => {
            optionAliases.value = [...data].map((x) => ({
              ...x,
              name: x.alias
            }))
          }
        })
      }

      const handleOptionChange = (data: any, field: string) => {
        console.log('=> ', field, data)
        if (field === 'stockAliasId') filter.value[field] = data.value
        else filter.value[field] = data
        // getTotal()
        onPageChange(1)
      }

      watch(
        () => route.params.id,
        () => {
          // getTotal()
          onPageChange(1)
        }
      )

      watch(
        () => filter.value.date,
        (val) => {
          onPageChange(1)

          filter.value.date = val || new Date().toISOString()
          // getTotal()
        }
      )

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          }
        })
      }

      onMounted(async () => {
        await store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id
        })
        // getTotal()
        onPageChange(1)

        getStocks()

        getContacts()
      })

      onBeforeMount(() => (loading.value = true))

      return {
        currency,
        contacts,
        columns,
        availableColumns,
        exerciceCalendars,
        exercisableQuantity,
        obsoleteQuantity,
        ceil,
        pagination,
        selectedColumns,
        delegations,
        heldBalance,
        exercisedQuantity,
        lastStockPrice,
        exercisePrice,
        portentialGainAtDate,
        potentialGain100Percent,
        total,
        optionAliases,
        filter,
        isLoading,
        loading,
        moment,
        companyGroups,
        totalExercisePrice,
        NumberType,
        exerciceCalendarsTotal,
        resetFilters,
        getStocks,
        formatNumber,
        download,
        onPageChange,
        handleFilterSelected,
        getOrder,
        gotoEvent,
        handleOptionChange,
        handleContactSearch
      }
    }
  })
