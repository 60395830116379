<template>
  <Multiselect
    id="language"
    class="lang-select"
    :value="lang"
    name="language"
    :options="options"
    :placeholder="$t('account.accountInformations.languagePlaceholder')"
    :groups="false"
    @change="selectionChanged"
  >
    <template #option="{ option }">
      <div class="w-full flex items-center justify-start px-2">
        <div
          class="
            w-16
            rounded
            overflow-hidden
            mr-2
            flex
            justify-start
            items-center
          "
        >
          <img
            :src="option.image"
            :alt="option.value"
            class="rounded overflow-hidden object-fill"
          />
        </div>
        <span v-if="option?.id != 0" class="w-full text-sm font-normal"
          >{{ option.name }}
        </span>
      </div>
    </template>
    <template #singlelabel="{ item }">
      <div class="w-full flex items-center justify-start px-2 z-40 relative">
        <div
          class="
            w-14
            rounded
            overflow-hidden
            mr-2
            ml-3
            flex
            justify-start
            items-center
          "
        >
          <img
            :src="item.image"
            :alt="item.value"
            class="rounded overflow-hidden object-fill"
          />
        </div>
        <span class="w-full text-sm text-left font-normal"
          >{{ item.name }}
        </span>
      </div>
    </template>
  </Multiselect>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import Multiselect from '../Select/multiselect.vue'
  import { languages } from '@/utils'

  export default defineComponent({
    name: 'UplLangSelect',
    components: { Multiselect },
    props: {
      value: { type: String, default: '' }
    },
    emits: ['onSelected'],
    setup(props, { emit }) {
      const lang = ref(props.value)

      const selectionChanged = ($event: string) => {
        emit('onSelected', $event)
      }
      const options = ref(languages)

      watch(props, (newProps) => {
        lang.value = newProps.value
      })

      return { lang, options, selectionChanged }
    }
  })
</script>

<style lang="scss">
  .lang-select {
    multiselect-dropdown {
      width: 100%;
      ul {
        li.multiselect-option {
          padding: 0 !important;
          height: 40px !important;
          .w-full {
            padding: 0 !important;
          }
        }
      }
    }
    .multiselect-option {
      padding: 0 !important;
      div {
        padding: 0 !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        min-height: 45px !important;
      }
    }
  }
</style>
