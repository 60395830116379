
  import { defineComponent } from 'vue'
  import Tab from '@/components/Tab/index.vue'
  import PortfolioUsers from './tabs/portfolioUsers.vue'
  export default defineComponent({
    components: { Tab },
    setup() {
      const tabList = [
        {
          type: 'portfolioUsers',
          key: 'portfolioUsers',
          langKey: 'users',
          component: PortfolioUsers
        }
        // {
        //   type: 'companyUsers',
        //   key: 'companyUsers',
        //   langKey: 'companyUsers',
        //   component: CompanyUsers
        // }
      ]
      return { tabList }
    }
  })
