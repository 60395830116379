
  import { computed, defineComponent, onMounted, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import Loader from '@/components/Loader/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'

  export default defineComponent({
    name: 'BodaccTab',
    components: { DataTable, Loader, EmptyState },
    props: {},
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData']
      )
      const columns = computed(() => [
        {
          label: 'Date',
          field: 'date',
          custom: true,
          width: '5%'
        },
        {
          label: 'Type',
          field: 'publicationAvis',
          custom: true,
          width: '2%'
        },
        {
          label: t('datatable.column.publicationNumber'),
          field: 'id',
          custom: true,
          width: '2%'
        },
        {
          label: t('datatable.column.registerDate'),
          field: 'createdAt',
          custom: true,
          width: '5%'
        },
        {
          label: t('datatable.column.publication'),
          field: 'type',
          width: '30%',
          custom: true
        },
        {
          label: t('datatable.column.details'),
          field: 'details',
          width: '30%',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          width: '10%',
          custom: true
        }
      ])
      const bodacc = computed(() => store.getters['organizations/bodac'])
      const data = computed(() =>
        [...bodacc.value].sort((a: any, b: any) =>
          moment(b.releasedAt).diff(moment(a.releasedAt))
        )
      )

      const getLink = (data: any) => {
        const [year] = data.releasedAt.split('-')
        const base = `https://fr.ftp.opendatasoft.com/datadila/BODACC/COMMERCIALES/PDF/`
        if (year < 2017) {
          return `${base}${data.releasedType}/BODACC-${data.releasedType}_${data.release}_0001_p000.pdf`
        }
        const link = `${base}${data.releasedType}/${year}/${data.release}/${
          data.folder
        }/BODACC_${data.releasedType}_PDF_Unitaire_${data.release}_${data.adId
          .toString()
          .padStart(5, '0')}.pdf`
        return link
      }
      watch(
        () => route.params.id,
        (val: any) => {
          store.dispatch('organizations/GET_BODAC', {
            companyId: val
          })
        }
      )
      onMounted(() => {
        store.dispatch('organizations/GET_BODAC', {
          companyId: route.params.id
        })
      })

      return { columns, data, moment, isLoading, getLink }
    }
  })
