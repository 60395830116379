
  import { Company, OptionSunset } from '@/services/api/models'
  import { defineComponent, computed, watch, onMounted } from 'vue'
  import moment from 'moment'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  export default defineComponent({
    name: 'SunsetCard',
    components: { ContactBadge, Tag },
    props: {
      event: {
        type: Object as () => OptionSunset | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      // const contactsId = ref<string[]>([])
      const parts = computed(() => props.event?.optionSunsetParts || [])
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const totalVested = computed(() =>
        (props.event?.optionSunsetParts || []).reduce(
          (acc, v) => (acc += v.exercisableQuantity),
          0
        )
      )
      const totalNonVested = computed(() =>
        (props.event?.optionSunsetParts || []).reduce(
          (acc, v) => (acc += v.nonVestedQuantity),
          0
        )
      )

      watch(
        () => props.event,
        (val) => {
          console.log('watchhh', val)
        }
      )

      onMounted(() => {
        console.log(
          'onMounted',
          props.event?.optionSunsetParts,
          parts.value.length
        )
      })
      return {
        moment,
        contactsId,
        parts,
        totalVested,
        totalNonVested,
        formatNumber
      }
    }
  })
