<template>
  <Form
    v-slot="{ errors, meta, values }"
    class="w-full mb-40"
    :validation-schema="schema"
    :initial-values="newDocument"
    @submit="handleFormSubmit"
  >
    <section
      :class="`flex flex-col w-${documentPreviewCollapsed ? 4 : 2}/5 md:w-${
        documentPreviewCollapsed ? 4 : 2
      }/5 sm:w-2/3 xs:w-full`"
    >
      <div class="flex flex-col items-start mt-3 w-full">
        <label class="text-xs text-secondary8 font-semibold">{{
          $t('extraction.firstlevel.informations.labels.label')
        }}</label>
        <Field
          v-slot="{ field }"
          v-model="newDocument.label"
          type="text"
          name="label"
        >
          <Input
            v-model="newDocument.label"
            placeholder=""
            v-bind="field"
            type="text"
            class="h-10 w-full"
            :disabled="false"
            name="label"
          />
        </Field>
      </div>
      <div class="text-left flex flex-col justify-start mb-3 mt-3 w-full">
        <label class="text-xs text-secondary8 font-semibold">{{
          $t('extraction.firstlevel.informations.labels.signedAt')
        }}</label>
        <Field v-model="newDocument.signedAt" name="signedAt">
          <UplDatePicker
            v-model="newDocument.signedAt"
            :value="newDocument.signedAt"
            name="signedAt"
            class="hover:border-primary3 h-10"
            :enable-time-picker="false"
            :placeholder="$t('global.datePicker.placeholder')"
            close-on-scroll
          />
        </Field>
      </div>
      <div class="flex flex-col items-start mb-3 w-full">
        <label class="text-xs text-secondary8 font-semibold">{{
          $t('extraction.firstlevel.informations.labels.documentType')
        }}</label>
        <Field
          v-slot="{ field }"
          v-model="newDocument.docSubCategoryId"
          name="docSubCategoryId"
        >
          <Multiselect
            :value="newDocument.docSubCategoryId"
            :show-labels="true"
            mode="single"
            v-bind="field"
            :options="categories"
            group-options-field="docSubCategories"
            placeholder="Document Type"
            :groups="true"
            class="w-full"
            name="docSubCategoryId"
            @change="onOptionSelected"
          >
          </Multiselect>
        </Field>
      </div>
      <div class="flex flex-col items-start w-full">
        <label class="text-xs text-secondary8 font-semibold">{{
          $t('extraction.firstlevel.informations.labels.operationTypes')
        }}</label>
        <Field
          v-slot="{ field }"
          v-model="newDocument.operationsId"
          name="operationsId"
        >
          <Multiselect
            :model-value="newDocument.operationsId"
            :show-labels="true"
            mode="tags"
            v-bind="field"
            :options="operations"
            :placeholder="$t('global.placeholder.operations')"
            class="w-full"
            name="operations"
          >
          </Multiselect>
        </Field>
      </div>
    </section>

    <section class="w-full mt-4">
      <div class="flex flex-col justify-start items-start mb-3">
        <h3>{{ $t('extraction.firstlevel.state.title') }}</h3>
        <h5 class="">{{ $t('extraction.firstlevel.state.subTitle') }}</h5>
      </div>

      <div class="w-full flex flex-col">
        <el-radio-group
          v-model="hasAnomaly"
          size="large"
          :fill="UplTheme.colors.primary5"
        >
          <el-radio-button :label="false">{{
            $t('extraction.firstlevel.state.labels.completed')
          }}</el-radio-button>
          <el-radio-button :label="true">
            {{
              $t('extraction.firstlevel.state.labels.uncompleted')
            }}</el-radio-button
          >
        </el-radio-group>
      </div>
    </section>
    <section class="w-full">
      <div
        v-if="hasAnomaly"
        class="missingReason-wrapper mt-3 flex flex-col items-start"
      >
        <div
          v-if="currentDocument.docAudits?.length > 0"
          class="flex flex-col w-full mb-5"
        >
          <h5 class="text-uppercase text-md mb-4 text-left">
            {{ $t('extraction.firstlevel.state.labels.audits') }}
          </h5>
          <el-collapse class="w-full">
            <el-collapse-item
              v-for="audit in currentDocument.docAudits"
              :key="audit.id"
              :title="`${moment.utc(audit.createdAt).format('L')}`"
              :name="audit.id"
            >
              <div class="text-left">
                {{ audit.comment }}
              </div>
              <div>
                <span
                  :class="`border border-warning text-${
                    audit.processed ? 'success' : 'warning'
                  } rounded px-2`"
                  >{{
                    $t(
                      `extraction.firstlevel.state.labels.${
                        audit.processed ? 'resolved' : 'notResolved'
                      }`
                    )
                  }}</span
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <h5 class="text-uppercase text-md mb-4">
          {{ $t('extraction.firstlevel.state.labels.newAudit') }}
        </h5>

        <UplAuditForm
          :reasons="Object.values(DocumentAuditAnomalies)"
          :type="AuditType.DOCUMENT"
          @change="handleAuditForm"
        />
      </div>
    </section>

    <hr class="my-4" />

    <section class="flex flex-col justify-start items-start">
      <h3>{{ $t('extraction.firstlevel.signers.title') }}</h3>
      <h5 class="text-left">
        {{ $t('extraction.firstlevel.signers.subTitle') }}
      </h5>

      <div class="w-11/12 w-auto">
        <div class="w-full mt-3">
          <dropdown-contacts
            v-model="newDocument.contactsId"
            class="w-full"
            name="contactsId"
            :filterResults="false"
            mode="tags"
            open-direction="bottom"
            :model-value="newDocument.contactsId"
            :elements="contacts"
          />
        </div>
      </div>
    </section>

    <FormActions
      v-if="canDo"
      :errors="errors"
      :meta="meta"
      @resetForm="handleFormReset"
      @onSubmit="handleFormSubmit(values)"
    />
  </Form>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, onMounted, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import {
    DocumentAuditAnomalies,
    AuditStatus,
    AuditForm,
    DocumentEditForm,
    AuditType
  } from '@/types/forms'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import {
    Contact,
    Document,
    DocumentAudit,
    Operation
  } from '@/services/api/models'
  import DropdownContacts from '../DropDowns/DropdownContacts.vue'
  import { documentSchema } from '@/utils'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { CompanyModel } from '@/services/api/models1'
  import moment from 'moment'
  import { useRoute } from 'vue-router'
  import { Input, UplTheme } from '@up.law/uplaw-ui'
  import {
    ElRadioGroup,
    ElRadioButton,
    ElCollapse,
    ElCollapseItem
  } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      UplDatePicker,
      FormActions,
      UplAuditForm,
      DropdownContacts,
      Input,
      ElRadioButton,
      ElRadioGroup,
      ElCollapse,
      ElCollapseItem
    },
    props: {
      document: { type: Object as () => Document, default: () => null }
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-documents'))
      )
      const newDocument = ref<DocumentEditForm>({
        docSubCategoryId: props.document?.docSubCategory?.id,
        operationsId: props.document?.operations.map((o) => o.id) || [],
        isDraft: props.document?.isDraft,
        signedAt:
          moment
            .utc(props.document?.signedAt)
            .set({ hour: 12, minute: 0 })
            .toISOString() || '',
        contactsId: props.document?.contacts.map((c) => c.id) || [],
        label:
          props.document?.label ||
          props.document?.name.replace('.pdf', '') ||
          ''
      })
      const documentAudit = ref<AuditForm>()
      const hasAnomaly = ref((props.document?.docAudits || []).length > 0)
      const reasons = computed(() => Object.values(DocumentAuditAnomalies))
      const currentDocument = computed<Document>(() => props.document)
      const isPreviewDisplayed = computed(() => {
        return store.getters['ui/displayDocumentPreview']
      })

      const documentPreviewCollapsed = computed(() => {
        return store.getters['ui/documentPreviewCollapsed']
      })
      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const categories = computed(
        () => store.getters[`toolbox/documentCategories`]
      )

      const operations = computed<Operation[]>(
        () => store.getters['organizations/operations']
      )

      const schema = documentSchema
      const onOptionSelected = () => {
        console.log('')
      }

      const updateSelected = (values: string[]) => {
        // newDocument.value.contactsId = values
      }

      const createAudit = (documentAudit: AuditForm) => {
        const formData = {
          motive: documentAudit.motives.join(','),
          processed: false,
          comment: documentAudit.comment
        }
        store.dispatch('organizations/CREATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.DOCUMENT,
          typeDataId: currentDocument.value.id,
          formData,
          onSuccess: (audit: DocumentAudit) => {
            store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
              companyId: currentCompany.value.id,
              documentId: currentDocument.value.id
            })
          },
          onError: () => {
            console.log('ERROR CREATE_AUDIT')
          }
        })
      }

      const handleAuditForm = (values: AuditForm) => {
        documentAudit.value = values
      }

      const displayMessage = () => {
        store.dispatch('toolbox/displayToast', {
          type: TYPE.ERROR,
          context: t('uploads.editDocument'),
          message: t(`uploads.editDocumentError`)
        })
      }

      const handleFormSubmit = (values: DocumentEditForm) => {
        const form = values
        form.contactsId = newDocument.value.contactsId
        form.operationsId = newDocument.value.operationsId
        form.signedAt = moment(form.signedAt)
          .set({ hour: 12, minute: 0 })
          .toISOString()
        form.isDraft = hasAnomaly.value
        store.dispatch('organizations/EDIT_COMPANY_DOCUMENT', {
          companyId: currentCompany.value?.id,
          documentId: currentDocument.value?.id,
          form,
          onSuccess: async () => {
            store.dispatch('organizations/ATTACH_CONTACTS_TO_DOCUMENT', {
              companyId: currentCompany.value.id,
              documentId: currentDocument.value.id,
              form: { contacts: newDocument.value.contactsId },
              onSuccess: () => {
                if (documentAudit.value) {
                  createAudit(documentAudit.value)
                } else {
                  store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
                    companyId: currentCompany.value.id,
                    documentId: currentDocument.value.id
                  })
                }
                store.dispatch('toolbox/displayToast', {
                  type: TYPE.SUCCESS,
                  context: t('uploads.editDocument'),
                  message: t(`uploads.editDocumentSuccess`)
                })
              },
              onError: () => {
                console.log('Error on ATTACH_CONTACTS_TO_DOCUMENT')
                displayMessage()
              }
            })
            if (
              hasAnomaly.value &&
              documentAudit.value &&
              documentAudit.value.comment
            ) {
              await handleAuditForm(documentAudit.value)
            }
          },
          onError: () => {
            displayMessage()
          }
        })
      }

      const handleFormReset = () => {
        store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
      }

      onMounted(() => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id || route.params.id,
          filter: { limit: 10, offset: 0 }
        })
        store.dispatch('organizations/GET_OPERATIONS', {
          companyId: currentCompany.value?.id || route.params.id
        })
      })

      watch(currentDocument, (doc) => {
        newDocument.value = {
          docSubCategoryId: doc?.docSubCategory?.id,
          isDraft: doc.isDraft,
          signedAt:
            moment
              .utc(doc.signedAt)
              .set({ hour: 12, minute: 0 })
              .toISOString() || '',
          label: doc?.label || doc?.name.replace('.pdf', '') || '',
          contactsId: doc.contacts?.map((c) => c.id),
          operationsId: doc.operations?.map((o) => o.id)
        }
      })

      watch(
        () => route.params?.documentId,
        () => {
          if (route.name === 'companyDocuments') {
            store.commit('ui/DISPLAY_PREVIEW', false)
            store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
          }
        }
      )

      return {
        newDocument,
        categories,
        currentDocument,
        currentCompany,
        AuditStatus,
        documentAudit,
        reasons,
        hasAnomaly,
        contacts,
        schema,
        moment,
        DocumentAuditAnomalies,
        AuditType,
        UplTheme,
        documentPreviewCollapsed,
        isPreviewDisplayed,
        updateSelected,
        canDo,
        onOptionSelected,
        handleAuditForm,
        handleFormSubmit,
        handleFormReset,
        operations
      }
    }
  })
</script>
