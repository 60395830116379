<template>
  <div class="w-full h-full ownership flex flex-col">
    <div class="flex justify-end mt-3">
      <div class="bg-secondary5 flex items-center rounded cursor-pointer h-8">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.listView')"
          placement="top"
        >
          <div
            :class="`
            ${mode === 'list' ? 'bg-primary4 text-white' : ''}
              rounded-l
              h-full
              w-20
              flex
              justify-center
              text-secondary9
              items-center
              hover:bg-primary3 hover:text-white
              border-r border-secondary2 transition transition-all
            `"
            @click="mode = 'list'"
          >
            <i class="bi bi-view-list font-bold"></i>
          </div>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.graphView')"
          placement="top"
        >
          <div
            :class="`
             ${mode === 'graph' ? 'bg-primary4 text-white' : ''}
              h-full
              w-20
              flex
              justify-center
              items-center
              rounded-r
              text-secondary9
              hover:bg-primary3 hover:text-white
              font-bold transition transition-all
            `"
            @click="mode = 'graph'"
          >
            <i class="bi bi-graph-up"></i>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div v-if="mode === 'list'" class="flex my-5 flex justify-between">
      <div class="md:w-4/5 lg:w-2/3 xl:w-1/2 flex justify-start">
        <DatePicker
          v-model="filter.date"
          class="max-w-xs rounded text-xs h-10 mr-2"
          placeholder="--/--/----"
        />

        <div class="w-1/4 lg:w-1/3 xl:w-1/3 h-10 mr-2">
          <Field
            v-slot="{ field }"
            v-model="filter.optionAliasId"
            name="optionAliasId"
          >
            <Multiselect
              v-model="filter.optionAliasId"
              :can-add-new="false"
              :value="filter.optionAliasId"
              open-direction="bottom"
              :has-label="false"
              mode="single"
              :options="optionAliases"
              :placeholder="$t('global.placeholder.option')"
              name="optionAliasId"
              :groups="false"
              v-bind="field"
            />
          </Field>
        </div>
      </div>
      <div class="filters-date flex justify-between">
        <div class="h-10 flex">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('tooltips.columns')"
            placement="top"
            ><Filter
              class="relative mx-2"
              :options="availableColumns"
              :value="selectedColumns"
              @on-change="handleFilterSelected"
            >
              <template #option="{ option, checked }">
                <div
                  :id="option.id"
                  class="
                    rounded
                    border-2 border-primary4
                    w-5
                    h-5
                    flex
                    items-center
                    justify-center
                    mr-2
                  "
                >
                  <i
                    v-if="checked"
                    class="bi bi-check text-primary4 text-md"
                  ></i>
                </div>
              </template>
              <i class="bi bi-gear"></i> </Filter
          ></el-tooltip>
          <DownloadButton class="mr-2" :on-click="download"></DownloadButton>

          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('tooltips.resetFilter')"
            placement="top"
            ><Button
              variant="tertiary"
              class="button-filter ml-2"
              @click="resetFilters"
            >
              <i class="bi bi-filter text-2xl"></i> </Button
          ></el-tooltip>
        </div>
      </div>
    </div>

    <div class="mb-5 mt-2 text-sm flex flex-col h-full w-full">
      <div v-if="isLoading" class="flex h-full justify-center items-center">
        <Loader />
      </div>
      <DataTable
        v-else-if="exerciceCalendars.length > 0 && mode === 'list'"
        :has-checkbox="false"
        :columns="columns"
        :rows="exerciceCalendars"
      >
        <template #stock="{ row }">
          <Tag
            :value="row.optionAlias.id"
            :text="row.optionAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
            :color="rgbToHex(row.optionAlias.colorCode)"
          />
        </template>

        <template #vestingStartDate="{ row }">
          <p v-if="row.exercisePeriods[0]?.startDate">
            {{
              moment
                .utc(row.exercisePeriods[0].startDate)
                .format($t('dateFormat'))
            }}
          </p>
          <p v-else>-</p>
        </template>
        <template #allocatedQuantity="{ row }">
          <Number
            :value="row.optionIssuancePart.quantity"
            :mode="NumberType.quantity"
          />
        </template>
        <template #vestingEndDate="{ row }">
          <p
            v-if="row.exercisePeriods[row.exercisePeriods.length - 1]?.endDate"
          >
            {{
              moment
                .utc(
                  row.exercisePeriods[row.exercisePeriods.length - 1].endDate
                )
                .format($t('dateFormat'))
            }}
          </p>
          <p v-else>-</p>
        </template>
        <template #exercisableQuantity="{ row }">
          <Number :value="row.vestedQuantity" :mode="NumberType.quantity" />
        </template>
        <template #ceil="{ row }">
          <Number :value="row.exercisableBalance" :mode="NumberType.quantity" />
        </template>
        <template #lapsedQuantity="{ row }">
          <Number
            zero-presents="-"
            :value="row.numberOfSunsetedOptions"
            :mode="NumberType.quantity"
          />
        </template>
        <template #exercisedQuantity="{ row }">
          <Number
            zero-presents="-"
            :value="row.exercisedQuantity"
            :mode="NumberType.quantity"
          />
        </template>
        <template #heldBalance="{ row }">
          <Number :value="row.notVestedQuantity" :mode="NumberType.quantity" />
        </template>
        <template #exercisePrice="{ row }">
          <Number
            :value="row.optionIssuancePart?.optionIssuance?.exercisePrice"
            :mode="NumberType.price"
          />
        </template>
        <template #totalExercisePrice="{ row }">
          <Number
            :value="
              row.optionIssuancePart?.optionIssuance?.exercisePrice *
              row.optionIssuancePart.quantity
            "
            :mode="NumberType.price"
          />
        </template>

        <template #lastActionPrice="{ row }">
          <div>{{ formatNumber(row.lastStockPrice) }} {{ currency }}</div>
        </template>
        <template #pv="{ row }">
          <div>
            {{ formatNumber(row.potentialGain100Percent) }} {{ currency }}
          </div>
        </template>
        <template #pv_vested="{ row }">
          <div>{{ formatNumber(row.portentialGainAtDate) }} {{ currency }}</div>
        </template>
        <template #vesting="{ row }">
          <div>
            {{ row.vestingCalendar?.name || '-' }}
          </div>
        </template>
        <template #delegation="{ row }">
          <p>
            {{ row.delegation?.label || '-' }}
          </p>
        </template>
        <template #eventName="{ row }">
          <div
            v-if="row.optionIssuancePart?.optionIssuance?.event?.id"
            class="flex justify-end"
          >
            <div
              class="
                text-left
                cursor-pointer
                bg-primary1
                rounded
                w-8
                h-8
                flex
                justify-center
                items-center
                hover:bg-primary2
              "
              @click="
                gotoEvent(row.optionIssuancePart?.optionIssuance?.event?.id)
              "
            >
              <i class="bi bi-lightning text-xl text-primary4"></i>
            </div>
          </div>
          <p v-else>-</p>
        </template>
        <template #total>
          <tr>
            <td :colspan="`${getOrder('delegation') > 0 ? '4' : '3'}`">
              <p class="text-right font-bold py-3 px-2 text-primary8">Total</p>
            </td>
            <td></td>
            <td v-if="getOrder('allocatedQuantity') > 0">
              <p
                :class="`text-left font-bold py-3 px-2 text-${
                  total >= 0 ? 'primary8' : 'error'
                }`"
              >
                {{ formatNumber(total) }}
              </p>
            </td>
            <td v-if="getOrder('exercisedQuantity') > 0">
              <p class="text-left font-bold py-3 px-2 text-primary8">
                {{ formatNumber(exercisedQuantity) }}
              </p>
            </td>
            <td v-if="getOrder('lapsedQuantity') > 0">
              <p class="text-left font-bold py-3 px-2 text-primary8">
                {{ formatNumber(obsoleteQuantity) }}
              </p>
            </td>
            <td v-if="getOrder('heldBalance') > 0">
              <p class="text-left font-bold py-3 px-2 text-primary8">
                {{ formatNumber(heldBalance) }}
              </p>
            </td>
            <td v-if="selectedColumns.includes('exercisableQuantity')">
              <p
                :class="`text-left font-bold py-3 px-2 text-${
                  exercisableQuantity >= 0 ? 'primary8' : 'error'
                }`"
              >
                {{ formatNumber(exercisableQuantity) }}
              </p>
            </td>

            <td v-if="getOrder('ceil') > 0">
              <p class="text-left font-bold py-3 px-2 text-primary8">
                {{ formatNumber(ceil) }}
              </p>
            </td>

            <td v-if="getOrder('lastActionPrice') > 0">
              <p class="text-left font-bold py-3 px-2 text-secondary7">-</p>
            </td>

            <td v-if="getOrder('totalExercisePrice') > 0">
              <p class="text-left font-bold py-3 px-2 text-secondary7">-</p>
            </td>

            <td v-if="getOrder('pv') > 0">
              <p class="text-left font-bold py-3 px-2 text-secondary7">-</p>
            </td>
            <td></td>
          </tr>
        </template>
      </DataTable>
      <div v-if="mode === 'graph'" class="w-full bg-white">
        <div class="md:w-4/5 lg:w-2/3 xl:w-1/2 flex justify-start">
          <div class="w-1/4 lg:w-1/3 xl:w-1/3 h-10 my-3">
            <Field v-slot="{ field }" v-model="optionId" name="optionId">
              <Multiselect
                v-model="optionId"
                :can-add-new="false"
                :value="optionId"
                open-direction="bottom"
                :has-label="false"
                mode="single"
                :options="optionAliases"
                :placeholder="$t('global.placeholder.option')"
                name="optionAliasId"
                :groups="false"
                v-bind="field"
              />
            </Field>
          </div>
        </div>

        <upl-chart
          id="vestingScheduleGraph"
          :title="optionPlanHistory.title"
          :dataset="optionPlanHistory.dataset"
          :labels="optionPlanHistory.labels"
          :options="optionPlanHistory.options"
          type="bar"
          :legend="false"
        />
      </div>
      <div
        v-else-if="
          exerciceCalendars.length === 0 && mode === 'list' && !isLoading
        "
        class="
          flex
          justify-center
          items-center
          w-full
          mt-4
          transition transition-all
        "
      >
        <EmptyState
          image="no-result.svg"
          class="d-block mx-auto"
          :title="$t('global.noResult')"
          :subtitle="$t('global.noData')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { Field } from 'vee-validate'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Contact,
    ExercicePeriod,
    ExerciseCalendar,
    OptionStockAlias
  } from '@/services/api/models'
  import { formatNumber, rgbToHex, ucFirst } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import UplChart from '@/components/Graphs/UplChart.vue'
  import { ElTooltip } from 'element-plus'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  export default defineComponent({
    components: {
      Field,
      DataTable,
      Tag,
      Number,
      EmptyState,
      Multiselect,
      DatePicker,
      Filter,
      UplChart,
      Button,
      Loader,
      ElTooltip,
      DownloadButton
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const selectedColumns = ref<string[]>([])
      const mode = ref('list')
      const filter = ref({
        contactId: '',
        optionAliasId: '',
        date: new Date().toISOString()
      })

      const router = useRouter()

      const optionId = ref('')

      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const period = computed<ExerciseCalendar | undefined>(() =>
        exerciceCalendars.value.find(
          (x) => x.optionAlias.id === optionId.value.split('@_')[0]
        )
      )
      const currency = computed(() => store.getters['organizations/currency'])
      const currentContact = computed(
        () => store.getters['organizations/contactDetails']
      )
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const download = () => {
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS', {
          name: `${currentContact.value.name}-${t('exports.shareholding')} `,
          companyId,
          filter: { contactId: [currentContact.value.id] }
        })
      }

      const isLoading = ref(false)

      const optionAliases = computed(() =>
        exerciceCalendars.value.reduce((acc: OptionStockAlias[], x) => {
          if (!acc.map((y: any) => y.id).includes(x.optionAlias.id)) {
            acc.push({ ...x.optionAlias, name: x.optionAlias.alias })
          }
          return acc
        }, [])
      )

      const contacts = computed<Contact>(() =>
        store.getters['organizations/contacts'].map((x: any) => ({
          ...x,
          name: ucFirst(x.name)
        }))
      )

      const total = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.optionIssuancePart.quantity),
          0
        )
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const ceil = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisableBalance),
          0
        )
      )

      const obsoleteQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.numberOfSunsetedOptions),
          0
        )
      )
      const exercisedQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisedQuantity),
          0
        )
      )

      const heldBalance = computed(
        () => total.value - exercisableQuantity.value
      )

      const exercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.optionIssuancePart.unitPrice ||
              c.optionIssuancePart.totalPrice / c.optionIssuancePart.quantity),
          0
        )
      )

      const lastStockPrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.lastStockPrice), 0)
      )
      const portentialGainAtDate = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.portentialGainAtDate),
          0
        )
      )

      const totalExercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.optionIssuancePart?.optionIssuance?.exercisePrice *
              c.optionIssuancePart.quantity),
          0
        )
      )
      const potentialGain100Percent = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.potentialGain100Percent),
          0
        )
      )

      const exercisePeriods = computed<ExercicePeriod[]>(() =>
        [...(period.value?.exercisePeriods ?? [])].sort(
          (a, b) => a.vestingPeriod.order - b.vestingPeriod.order
        )
      )

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }
      const generateData = () => ({
        labels: exercisePeriods.value.map((x) =>
          moment(x.endDate).format(t('dateFormat'))
        ),
        dataset: [
          {
            type: 'line',
            label: 'Vestable quantity',
            data: exercisePeriods.value.map((x) => x.cumulativeQuantity),
            lineTension: 0,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgb(24,15,239)',
            pointBorderWidth: 3,
            pointRadius: 5,
            borderColor: 'rgb(24,15,239)',
            borderWidth: 3,
            order: 1,
            fill: false
          }
        ],
        title: '',
        options: {
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      })
      const optionPlanHistory = computed(() => generateData())

      const allColumns = ref([
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          custom: true,
          order: 2,
          tooltipText: t('events.tooltips.stock'),
          tooltipWidth: '12%',
          hasTooltip: true
        },

        {
          label: t('events.fieldsLabels.vestingStartDate'),
          field: 'vestingStartDate',
          custom: true,
          order: 3,
          tooltipText: t('events.tooltips.vestingStartDate'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.vestingEndDate'),
          field: 'vestingEndDate',
          custom: true,
          filterable: true,
          selected: true,
          tooltipText: t('events.tooltips.vestingEndDate'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 4
        },
        {
          label: t('events.fieldsLabels.delegation'),
          field: 'delegation',
          custom: true,
          order: 4.5,
          tooltipText: t('events.tooltips.delegation'),
          tooltipWidth: '12%',
          hasTooltip: true,
          filterable: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          width: '2%',
          tooltipText: t('events.tooltips.assignedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 6
        },

        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          filterable: true,
          order: 9,
          tooltipText: t('events.tooltips.vestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.balance'),
          field: 'ceil',
          custom: true,
          filterable: true,
          order: 10,
          tooltipText: t('events.tooltips.exercisableBalance'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          filterable: true,
          order: 8,
          tooltipText: t('events.tooltips.lapsedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,

          selected: true
        },
        {
          label: t('events.fieldsLabels.exercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          filterable: true,
          order: 7,
          tooltipText: t('events.tooltips.exercisedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.heldBalance'),
          field: 'heldBalance',
          custom: true,
          filterable: true,
          order: 8,
          tooltipText: t('events.tooltips.notVestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.exercisePrice'),
          field: 'exercisePrice',
          custom: true,
          filterable: true,
          order: 5,
          tooltipText: t('events.tooltips.exercisePrice'),
          tooltipWidth: '12%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.totalExercisePrice'),
          field: 'totalExercisePrice',
          custom: true,
          filterable: true,
          order: 12.5,
          tooltipText: t('events.tooltips.totalExercisePrice'),
          tooltipWidth: '17%',
          hasTooltip: true,
          selected: false
        },
        {
          label: t('events.fieldsLabels.lastStockPrice'),
          field: 'lastActionPrice',
          custom: true,
          tooltipText: t('events.tooltips.lastStockPrice'),
          tooltipWidth: '20%',
          hasTooltip: true,
          filterable: true,
          order: 12
        },
        {
          label: t('events.fieldsLabels.potentialPv'),
          field: 'pv',
          custom: true,
          tooltipText: t('events.tooltips.potentialPv'),
          tooltipWidth: '17%',
          hasTooltip: true,

          filterable: true,
          order: 13
        },

        {
          label: t('events.fieldsLabels.vestingPlan'),
          field: 'vesting',
          custom: true,
          tooltipText: t('events.tooltips.vestingPlan'),
          tooltipWidth: '17%',
          hasTooltip: true,
          filterable: true,
          order: 15
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          custom: true,
          width: '3%',
          order: 15,
          tooltipText: t('events.tooltips.event'),
          tooltipWidth: '20%',
          hasTooltip: true,
          filterable: true,
          selected: true
        }
      ])

      const existingColumns = ref(allColumns.value.filter((x) => !x.filterable))
      const columns = ref(
        allColumns.value
          .filter((x) => !x.filterable || x.selected)
          .sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable || x.selected)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      selectedColumns.value = allColumns.value
        .filter((x) => x.selected)
        .map((x) => x.field)
      const handleFilterSelected = (value: string[]) => {
        columns.value = [
          ...existingColumns.value,
          ...allColumns.value.filter((x) => value.includes(x.field))
        ].sort((a: any, b: any) => a.order - b.order)

        selectedColumns.value = value
      }

      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            ...filter.value,
            contactId: route.params.contactId
          },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const resetFilters = () => {
        filter.value = {
          contactId: route.params.contactId?.toString(),
          optionAliasId: '',
          date: new Date().toISOString()
        }
      }

      const getOrder = (field: string) =>
        columns.value.find((x) => x.field === field)?.order || 0

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_EXERCISE_CALENDARS_PAGE', page)
        getVesting()
      }

      onBeforeUnmount(() =>
        store.commit('organizations/SET_EXERCISE_CALENDARS', {
          ...exerciceCalendars,

          data: []
        })
      )
      watch(
        () => route.params.id,
        () => {
          getVesting()
        }
      )

      watch(
        () => filter.value.contactId,
        (val) => {
          filter.value.contactId = val
          getVesting()
        }
      )

      watch(
        () => route.params.contactId,
        (val) => {
          filter.value.contactId = val?.toString()
          getVesting()
        }
      )

      watch(
        () => filter.value.optionAliasId,
        (val) => {
          filter.value.optionAliasId = val
          getVesting()
        }
      )

      watch(optionAliases, ([val]) => {
        optionId.value = val?.id
      })

      watch(
        () => filter.value.date,
        (val) => {
          filter.value.date = val || new Date().toISOString()
          getVesting()
        }
      )

      onMounted(async () => {
        isLoading.value = true

        await store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id
        })

        onPageChange(1)
      })
      return {
        currency,
        optionPlanHistory,
        mode,
        contacts,
        columns,
        availableColumns,
        exerciceCalendars,
        exercisableQuantity,
        obsoleteQuantity,
        ceil,
        optionId,
        selectedColumns,
        delegations,
        heldBalance,
        exercisedQuantity,
        lastStockPrice,
        exercisePrice,
        portentialGainAtDate,
        potentialGain100Percent,
        total,
        optionAliases,
        filter,
        isLoading,
        download,
        moment,
        totalExercisePrice,
        resetFilters,
        NumberType,
        formatNumber,
        handleFilterSelected,
        getOrder,
        gotoEvent,
        rgbToHex
      }
    }
  })
</script>

<style lang="scss">
  .ownership {
    .filters-date {
      .button-filter {
        @apply h-full flex px-2 justify-center items-center border border-inputBorder rounded-sm hover:bg-primary5 hover:text-white;
        &:hover img {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }
    .allocatedQuantity {
      width: 12%;
    }
    .stock {
      padding: 5px !important;
    }
    .user {
      padding: 5px !important;
    }
  }
</style>
