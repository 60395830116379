<template>
  <el-popover
    v-if="text && text.length > maxLenght"
    placement="top-start"
    width="30%"
    trigger="hover"
    :content="text"
  >
    <template #reference>
      <component :is="tag" :class="classes">
        {{ truncText(text, maxLenght) }}
      </component>
    </template>
  </el-popover>
  <component :is="tag" v-else :class="classes">{{ text }}</component>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { truncText } from '@/utils'
  import { ElPopover } from 'element-plus'

  export default defineComponent({
    name: 'UplLongText',
    components: { ElPopover },
    props: {
      maxLenght: {
        type: Number,
        required: false,
        default: 25
      },
      text: {
        type: String,
        required: true,
        default: ''
      },
      tag: {
        type: String,
        required: false,
        default: 'h5'
      },
      classes: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup() {
      return { truncText }
    }
  })
</script>
