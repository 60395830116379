
  import { defineComponent, ref, computed, watch, onMounted } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { LegalStatus } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CONTACTTYPE, ProfessionalContactForm } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { professionalSchema } from '@/utils/schema'
  import { CompanyModel } from '@/services/api/models1'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import {
    CompanyContact,
    ContactStatus,
    IndividualContact,
    ProfessionalContact
  } from '@/services/api/models/company'
  import AddressInput from '@/components/Address/index.vue'
  import { AddressData } from '@/types/config'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      Input,
      UplDatePicker,
      FormActions,
      ProfileAvatarForm,
      AddressInput
    },
    props: {
      contact: {
        type: Object as () => ProfessionalContact | null,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const newValues = (): ProfessionalContactForm => ({
        id: props.contact?.id,
        name: props.contact?.name || '',
        tradeName: props.contact?.name || '',
        type: CONTACTTYPE.PROFESSIONAL,
        nationality: props.contact?.nationality || null,
        country: props.contact?.country || null,
        contactGroupsId: props.contact?.contactGroups?.map((x) => x.id) || [],
        contactStatusId: null,
        birthDate: props.contact?.birthDate || null,
        email: props.contact?.email || null,
        phoneNumber: props.contact?.phoneNumber || null,
        address: props.contact?.address || null,
        postalcode: props.contact?.postcode || null,
        city: props.contact?.city || null,
        representativeContactId:
          props.contact?.representativeContact?.id || null,
        legalStatusId: props.contact?.legalStatus?.id || '',
        siret: props.contact?.siret || null,
        siren: props.contact?.siren || null,
        website: props.contact?.website || null,
        tva: props.contact?.tva || '',
        ape: props.contact?.ape || null,
        internalId1: props.contact?.internalId1 || null,
        internalId2: props.contact?.internalId2 || null,
        mappingId: props.contact?.mappingId || null
      })

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const modalForm = computed(() => store.getters['organizations/form'])

      const newContact = ref<ProfessionalContactForm>(newValues())

      const allContacts = computed<any[]>(
        () => store.getters['organizations/contacts']
      )

      const contacts = computed(() =>
        allContacts.value.filter(
          (x) =>
            x.id !== route.params?.contactId &&
            [CONTACTTYPE.INDIVIDUAL, CONTACTTYPE.PROFESSIONAL].includes(x.type)
        )
      )

      const groups = computed<ContactStatus[]>(
        () => store.getters['organizations/groups']
      )

      const legalStatus = computed<LegalStatus[]>(
        () => store.getters['toolbox/legalStatus']
      )
      const investorLegalStatus = computed<LegalStatus[]>(() =>
        legalStatus.value.filter(
          (x) => x.contactType === CONTACTTYPE.PROFESSIONAL
        )
      )

      const status = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )
      const professionalStatus = computed(() =>
        status.value.filter((x) => x.type === CONTACTTYPE.PROFESSIONAL)
      )
      const schema = professionalSchema

      const handleForm = async (formData: ProfessionalContactForm) => {
        formData.contactGroupsId = newContact.value.contactGroupsId
        emit('submitForm', formData)
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const onAddressInput = ($event: AddressData) => {
        newContact.value.postalcode = $event.postalCode
        newContact.value.city = $event.city
        newContact.value.country = $event.country
        newContact.value.address = $event.address
      }

      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.INDIVIDUAL,
            name: e,
            limit: 10
          }
        })

        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.PROFESSIONAL,
            name: e,
            limit: 10
          }
        })
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            contactGroupsId:
              newProps.contact.contactGroups?.map((x) => x.id) || [],
            contactStatusId: newProps.contact.status?.id || '',
            legalStatusId: newProps.contact.legalStatus.id || '',
            representativeContactId:
              newProps.contact.representativeContact?.id || '',
            siren: newProps.contact.siren,
            postalcode: newProps.contact.postcode || '',
            internalId1: newProps.contact.internalId1 || '',
            internalId2: newProps.contact.internalId2 || '',
            mappingId: newProps.contact.mappingId || ''
          }
        } else {
          newContact.value = newValues()
        }
      })

      onMounted(() => {
        handleSearch('')
      })

      return {
        newContact,
        professionalStatus,
        contacts,
        groups,
        schema,
        investorLegalStatus,
        handleForm,
        handleSearch,
        fileChanged,
        handleReset,
        onAddressInput
      }
    }
  })
