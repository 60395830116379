import { company } from '../endpoints'
import axios from '../axios'
import { CancelTokenSource } from 'axios'

let captableCancelToken: CancelTokenSource | null = null

const getCaptable = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 200
) => {
  if (typeof captableCancelToken != undefined) {
    captableCancelToken?.cancel('Operation canceled due to new request.')
  }
  captableCancelToken = axios.CancelToken.source()
  const response = await axios.get(company.getCaptable(companyId), {
    cancelToken: captableCancelToken.token,
    params: {  ...filter,offset, limit, }
  })

  return response
}

export { getCaptable }

export * from './contact'
export * from './stock'
export * from './vesting'
export * from './organ'
export * from './document'
export * from './contactGroup'
export * from './company'
export * from './audit'
export * from './event'
export * from './stockGroup'
export * from './user'
export * from './profitSharing'
export * from './fundRaisingRound'
export * from './alerts'
