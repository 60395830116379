<template>
  <div class="h-full">
    <Field v-slot="{ field }" v-model="search" type="text" name="search">
      <Input
        v-model="search"
        v-bind="field"
        :disabled="false"
        :placeholder="$t('global.search')"
      >
      </Input>
    </Field>
    <div class="alias-list h-4/5 overflow-scroll">
      <div
        v-for="g in listedGroups"
        :key="g.id"
        :class="`p-1 my-1 rounded cursor-pointer flex items-center ${
          group?.id == g.id ? 'bg-secondary5' : 'hover:bg-secondary5'
        }`"
        @click="handleSelect(g.id)"
      >
        <div
          class="w-2 h-2 rounded-full mr-2"
          :style="`background-color: ${g.colorCode}`"
        ></div>
        <h5 class="text-blackp text-md">{{ g.name }}</h5>
      </div>
    </div>
    <div v-if="canDo" class="bottom-container bg-white text-xl">
      <Button
        :disabled="false"
        :label="$t('stockGroupBook.form.new')"
        size="medium"
        variant="secondary"
        class="w-full h-10 flex justify-center items-center"
        @click="initForm"
      >
        <i class="bi bi-plus text-md"></i>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { StockGroup, CompanyModel } from '@/services/api/models'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const group = computed<StockGroup | null>(
        () => store.getters['organizations/stockGroup']
      )

      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )

      const getGroups = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_GROUPS', {
          companyId: currentCompany.value.id,
          filter: { search }
        })
      }

      const groups = computed<StockGroup[]>(
        () => store.getters['organizations/stockGroups']
      )

      const listedGroups = ref(groups.value)

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_STOCK_GROUP', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (groupId: string) => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_GROUP', {
          companyId: currentCompany.value.id,
          groupId
        })
      }

      onBeforeMount(() => {
        getGroups()
      })

      watch(search, (text: string) => {
        listedGroups.value = groups.value.filter((x) =>
          x.name.toLowerCase().includes(text.toLowerCase())
        )
      })

      watch(groups, (gps) => {
        listedGroups.value = gps
      })

      return { canDo, listedGroups, search, group, initForm, handleSelect }
    }
  })
</script>
<style lang="scss" scoped>
  .bottom-container {
    @apply absolute bottom-0 right-0 left-0 z-10 h-20 flex justify-center items-center px-4;
  }
</style>
