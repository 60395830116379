<template>
  <div class="h-full flex flex-col items-start overflow-y-scroll pb-10">
    <div
      v-if="company"
      class="flex items-center text text-blackp text-xl py-8 ml-12"
    >
      <div
        class="w-16 h-16 rounded-full bg-cover bg-primary1"
        :style="`background-image: url(${
          companyImage ?? require('@/assets/images/profile/company.png')
        })`"
      ></div>
      <p class="px-2 font-semibold">{{ company.name }}</p>
    </div>
    <div class="px-12 w-full">
      <Tab :tabs="tabList"></Tab>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import CapitalTab from '@/components/CompanyOverview/CapitalTab.vue'
  import FinancialsTab from '@/components/CompanyOverview/FinancialsTab.vue'
  import ESOPTab from '@/components/CompanyOverview/ESOPTab.vue'
  import ResultsTab from '@/components/CompanyOverview/ResultsTab.vue'
  import BodaccTab from '@/components/CompanyOverview/BodaccTab.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import Tab from '@/components/Tab/index.vue'

  export default defineComponent({
    name: 'CompanyOverview',
    components: {
      Tab
    },
    setup() {
      const store = useStore()
      const companyImage = ref<null | string>()
      const tabList = [
        { key: 'capital', langKey: 'capital', component: CapitalTab },
        { key: 'financials', langKey: 'financials', component: FinancialsTab },
        { key: 'esop', langKey: 'esop', component: ESOPTab },
        // { key: 'results', langKey: 'results', component: ResultsTab },
        { key: 'bodacc', langKey: 'bodacc', component: BodaccTab }
      ]

      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      const loadImage = async () => {
        const response = await store.dispatch(
          `organizations/LOAD_COMPANY_LOGO`,
          {
            companyId: company.value?.id
          }
        )
        if (response) companyImage.value = response
        else companyImage.value = null
      }

      onMounted(loadImage)

      watch(
        () => company.value,
        () => loadImage()
      )

      return {
        companyData: CompanyOverviewExample,
        tabList,
        company,
        companyImage
      }
    }
  })
</script>

<style lang="scss" scoped>
  .tabList {
    @apply px-12 w-full flex justify-start border-solid border-b border-secondary4 space-x-4;
    .tabBtn {
      @apply px-8 pb-2;
      &.selected {
        @apply border-solid border-b-2 border-primary text-primary;
      }
      &.not-selected {
        @apply text-secondary8 hover:text-primary;
      }
    }
  }
</style>
