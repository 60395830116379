
  import { defineComponent, computed, toRefs, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import { CompanyModel, Document, Event } from '@/services/api/models'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { ElPopconfirm } from 'element-plus'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { EventPartDocuments } from '@/store/modules/Organization/models'
  import EventDocumentTag from '@/components/Event/EventDocument.vue'
  export default defineComponent({
    name: 'EventPartDocuments',
    components: {
      Button,
      DataTable,
      ElPopconfirm,
      ContactBadge,
      Checkbox,
      EventDocumentTag
    },
    props: {
      documents: {
        type: Array as () => Document[],
        default: () => []
      },
      onlyDisplay: {
        type: Boolean,
        default: true
      },
      event: {
        type: Object as () => Event,
        default: null
      }
    },
    emits: ['fileDeleted'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { event } = toRefs(props)
      const { t } = useI18n()
      const selectedLine = ref()
      const eventPartLinkedDocuments = computed<EventPartDocuments>(
        () => store.getters['organizations/eventPartLinkedDocuments']
      )
      const allParts = computed<any[]>(
        () =>
          event.value?.optionIssuance?.optionIssuanceParts ||
          event.value?.stockIssuance?.stockIssuanceParts ||
          event.value?.conversion?.conversionParts ||
          event.value?.compositionIssuance?.compositionIssuanceParts ||
          event.value?.optionExercise?.optionExerciseParts ||
          event.value?.transfer?.transferParts ||
          event.value?.capitalReduction?.capitalReductionParts ||
          []
      )
      const parts = computed<any[]>(() =>
        allParts.value.map((x: any) => ({
          ...x,
          current: x.id === selectedLine.value
        }))
      )

      const partsColumns = ref([
        {
          label: t('documents.elements.contact'),
          field: 'contact',
          width: '10%',
          custom: true
        },
        {
          label: 'Documents',
          field: 'name',
          custom: true
        }
      ])

      if (!props.onlyDisplay) {
        partsColumns.value = [
          {
            label: '',
            field: 'select',
            width: '1%',
            custom: true
          },
          ...partsColumns.value
        ]
      }
      const isIncluded = (documents: Document[], id: string) => {
        return (documents || []).map((x) => x.id).includes(id)
      }

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const handleDocumentView = async (doc: Document) => {
        if (currentDocument?.value?.id === doc.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }
      const getDocuments = (contactId: string) => {
        return eventPartLinkedDocuments.value[contactId]
      }
      const removeFile = (docId: string, contactId: string) => {
        store.commit('organizations/SET_CURRENT_PART_CONTACT_ID', contactId)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', {
          files: [],
          remove: true,
          docId
        })
      }
      const updateSelection = (row: any) => {
        store.commit(
          'organizations/INIT_CURRENT_CONTACT_PART_DOCUMENTS',
          row.id
        )
        if (selectedLine.value === row.id) {
          selectedLine.value = ''
          store.commit('organizations/SET_CURRENT_PART_CONTACT_ID', null)
        } else {
          selectedLine.value = row.id
          store.commit('organizations/SET_CURRENT_PART_CONTACT_ID', row.id)
        }
      }

      const unlinkDocument = (partId: string, documentId: string) => {
        store.dispatch('organizations/DETACH_DOCUMENTS_TO_EVENT_PART', {
          type: event.value?.type,
          eventTypeId:
            event.value?.optionExercise?.id ||
            event.value?.optionIssuance?.id ||
            event.value?.stockIssuance?.id ||
            event.value?.conversion?.id ||
            event.value?.transfer?.id ||
            event.value?.capitalReduction?.id ||
            event.value?.compositionIssuance?.id,
          partId,
          companyId: route.params.id,
          eventId: route.params.eventId,
          documentId,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            emit('fileDeleted', documentId)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      const removeDocument = (doc: Document) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: currentCompany.value.id,
          eventId: event.value?.id,
          documentId: doc.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            emit('fileDeleted', doc.id)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      return {
        partsColumns,
        eventPartLinkedDocuments,
        parts,
        selectedLine,
        isIncluded,
        updateSelection,
        getDocuments,
        handleDocumentView,
        removeDocument,
        removeFile,
        unlinkDocument
      }
    }
  })
