import { company } from '../endpoints'
import { CompanyCommittee } from '../models/company'
import axios from '../axios'
import { CompanyCommitteeFormData } from '@/types/forms'

const getCompanyCommittees = async (
  companyId: string
): Promise<CompanyCommittee[]> => {
  const { data } = await axios.get(company.getCommittees(companyId), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const createCompanyCommittee = async (
  companyId: string,
  formData: CompanyCommitteeFormData
): Promise<CompanyCommittee> => {
  const { data } = await axios.post(company.getCommittees(companyId), formData)
  return data
}

const updateCompanyCommittee = async (
  companyId: string,
  committeeId: string,
  formData: CompanyCommitteeFormData
): Promise<CompanyCommittee> => {
  const { data } = await axios.put(
    company.singleCommittee(companyId, committeeId),
    formData
  )
  return data
}

const getCommittee = async (
  companyId: string,
  committeeId: string
): Promise<CompanyCommittee> => {
  const data: CompanyCommittee = await axios.get(
    company.singleCommittee(companyId, committeeId)
  )
  return data
}

const deleteCompanyCommittee = async (
  companyId: string,
  committeeId: string
) => {
  return await axios.delete(company.singleCommittee(companyId, committeeId))
}

export {
  deleteCompanyCommittee,
  getCommittee,
  updateCompanyCommittee,
  createCompanyCommittee,
  getCompanyCommittees
}
