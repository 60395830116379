import { CancelTokenSource } from 'axios'
import axios from './axios'
import { portfolio } from './endpoints'
let eventsCancelToken: CancelTokenSource | null = null
let documentsCancelToken: CancelTokenSource | null = null
let contactsCancelToken: CancelTokenSource | null = null
let companiesCancelToken: CancelTokenSource | null = null
let textSearchCancelToken: CancelTokenSource | null = null

const getEvents = async (
  filter: any,
  offset = 0,
  limit = 10
): Promise<any[]> => {
  if (typeof eventsCancelToken != undefined) {
    eventsCancelToken?.cancel(
      'Operation (GET_EVENTS) canceled due to new request.'
    )
  }

  eventsCancelToken = axios.CancelToken.source()
  return await axios.get(portfolio.events(), {
    cancelToken: eventsCancelToken.token,
    params: {
      offset,
      limit,
      ...filter
    }
  })
}

const getEventDocuments = async (eventId: string, offset = 0, limit = 50) => {
  return await axios.get(portfolio.eventDocuments(eventId), {
    params: { offset, limit }
  })
}

const getDocuments = async (
  filter: any,
  offset = 0,
  limit = 30
): Promise<any[]> => {
  if (typeof documentsCancelToken != undefined) {
    documentsCancelToken?.cancel(
      'Operation (GET_EVENTS) canceled due to new request.'
    )
  }
  documentsCancelToken = axios.CancelToken.source()
  return await axios.get(portfolio.documents(), {
    cancelToken: documentsCancelToken.token,
    params: {
      offset,
      limit,
      ...filter
    }
  })
}

const getCompanies = async (
  filter: any,
  offset = 0,
  limit = 30
): Promise<any[]> => {
  if (typeof companiesCancelToken != undefined) {
    companiesCancelToken?.cancel(
      'Operation (GET COMPANIES) canceled due to new request.'
    )
  }
  companiesCancelToken = axios.CancelToken.source()
  return await axios.get(portfolio.companies(), {
    cancelToken: companiesCancelToken.token,
    params: {
      offset,
      limit,
      ...filter
    }
  })
}

const getContacts = async (
  filter: any,
  offset = 0,
  limit = 30
): Promise<any[]> => {
  if (typeof contactsCancelToken != undefined) {
    contactsCancelToken?.cancel(
      'Operation (GET CONTACTS) canceled due to new request.'
    )
  }

  contactsCancelToken = axios.CancelToken.source()
  return await axios.get(portfolio.contacts(), {
    cancelToken: contactsCancelToken.token,
    params: {
      offset,
      limit,
      ...filter
    }
  })
}

const getEvent = async (eventId: string, type: string): Promise<any> => {
  return await axios.get(portfolio.event(eventId), {
    params: { type }
  })
}

const searchInDocuments = async (
  query: string,
  ids: string[]
): Promise<Document[]> => {
  if (typeof textSearchCancelToken != undefined) {
    textSearchCancelToken?.cancel(
      'Operation (GET CONTACTS) canceled due to new request.'
    )
  }
  textSearchCancelToken = axios.CancelToken.source()
  return await axios.get(portfolio.searchInDocuments(), {
    cancelToken: textSearchCancelToken.token,
    params: { text: query, companyIds: ids }
  })
}

const getDocument = async (documentId: string, type: string): Promise<any> => {
  return await axios.get(portfolio.document(documentId), {
    params: { type }
  })
}

export {
  getEvents,
  getEvent,
  getDocuments,
  getDocument,
  searchInDocuments,
  getContacts,
  getEventDocuments,
  getCompanies
}
