
  import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
  import { useStore } from 'vuex'
  import EventForm from '@/components/Event/EventForm.vue'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import { defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    name: 'CreateEvent',
    components: {
      DocumentTreeview,
      EventForm,
      Loader
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const getEventCategories = async () => {
        await store.dispatch('toolbox/getEventCategories')
      }

      const getEvents = async (companyId = route.params.id) => {
        await store.dispatch('organizations/GET_EVENTS', {
          companyId,
          isDraft: false,
          startDate: '1970-01-01',
          endDate: moment().format('YYYY-MM-DD')
        })
      }

      const events = computed(() => store.getters['organizations/events'])
      const isLoading = computed(() => store.getters['organizations/isLoading'])

      onMounted(async () => {
        await getEventCategories()
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: [] })
        getEvents()
      })

      onBeforeUnmount(() => {
        store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
        store.commit('ui/DISPLAY_PREVIEW', false)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: [] })
      })

      watch(
        () => route.params.id,
        (val) => {
          getEvents(val)
        }
      )

      return {
        events,
        isLoading
      }
    },
    data() {
      return {
        document: null,
        uuid: null,
        hightLightDocument: false,
        currentPage: 0,
        showTreeview: true
      }
    }
  })
