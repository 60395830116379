
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { ExercicePeriod, ExerciseCalendar } from '@/services/api/models'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { formatNumber } from '@/utils'
  import Loader from '@/components/Loader/index.vue'
  import { VESTINGVALUEUNIT } from '@/types'
  import { ElPopover, ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      DataTable,
      Multiselect,
      ElPopover,
      Field,
      EmptyState,
      Loader,
      ElTooltip
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const date = ref(new Date().toDateString())
      const mode = ref('list')
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const isLoading = ref(false)

      const optionId = ref('')

      const selectedOption = computed<ExerciseCalendar | undefined>(() =>
        exerciceCalendars.value.find(
          (x) => x.id === optionId.value.split('@_')[0]
        )
      )

      const selectedOptionId = computed(() =>
        optionId.value && optionId.value.split('@_').length > 1
          ? optionId.value.split('@_')[1]
          : ''
      )
      const vestingType = computed(
        () => period.value?.vestingCalendar?.vestingValueUnit
      )
      const period = computed<ExerciseCalendar | undefined>(() =>
        exerciceCalendars.value.find(
          (x) => x.id === optionId.value.split('@_')[0]
        )
      )

      const sold = computed(
        () =>
          assignedQuantity.value -
          lapsedQuantity.value -
          (period.value?.exercisedQuantity || 0)
      )
      const lapsedQuantity = ref(0)

      const assignedQuantity = ref(0)

      const exercisePeriods = computed<ExercicePeriod[]>(() =>
        [...(period.value?.exercisePeriods ?? [])].sort(
          (a, b) => a.vestingPeriod.order - b.vestingPeriod.order
        )
      )

      const options = computed(() =>
        exerciceCalendars.value.map((x) => ({
          ...x.optionAlias,
          name: `${x.optionAlias.alias} ${
            x.optionIssuancePart?.vestingCalendar
              ? `(${x.optionIssuancePart?.vestingCalendar?.name})`
              : ''
          }`,
          id: `${x.id}@_${x.optionAlias.id}`,
          exerciseId: x.id
        }))
      )

      const columns = computed(() => [
        {
          label: '#',
          field: 'index',
          custom: true,
          width: '5%'
        },
        {
          label: t(`datatable.column.date`),
          field: 'date',
          custom: true,
          width: '20%'
        },
        {
          label:
            vestingType.value === 'PERCENT'
              ? t(`datatable.column.pVested`)
              : vestingType.value === VESTINGVALUEUNIT.FRACTION
              ? t(`datatable.column.fVested`)
              : t(`datatable.column.qVested`),
          field: 'pVested',
          custom: true,
          width: '10%'
        },
        {
          label: t(`datatable.column.qVested`),
          field: 'qVested',
          custom: true,
          width: '12%'
        },
        {
          label: t(`datatable.column.qAccelerated`),
          field: 'qAccelerated',
          custom: true,
          width: '13%'
        },
        {
          label: t(`datatable.column.cumulatedVestedQuantity`),
          field: 'cumulatedVestedQuantity',
          custom: true,
          width: '25%'
        }
      ])

      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            contactId: route.params.contactId,
            date: moment(date.value).endOf('day').toISOString()
          },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const updateSold = ({ attributedQuantity, obsoleteQuantity }: any) => {
        lapsedQuantity.value = obsoleteQuantity
        assignedQuantity.value = attributedQuantity
      }

      const totalVested = computed(() =>
        period.value?.exercisePeriods.reduce(
          (acc, elm) => (acc += elm.vestingPeriod?.value || 0),
          0
        )
      )

      const totalQuantity = computed(() =>
        period.value?.exercisePeriods.reduce(
          (acc, elm) => (acc += elm.quantity),
          0
        )
      )

      const totalAcceleratedQuantity = computed(() =>
        period.value?.exercisePeriods.reduce((acc, elm) => {
          const [lastAcceleration] = elm.periodAccelerations.sort(
            (x: any, y: any) =>
              moment(y.createdAt).unix() - moment(x.createdAt).unix()
          )

          return (acc += lastAcceleration?.quantity || 0)
        }, 0)
      )

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_EXERCISE_CALENDARS_PAGE', page)
        getVesting()
      }

      onMounted(() => {
        isLoading.value = true
        onPageChange(1)
      })

      watch(
        () => route.params.contactId,
        (newContactId: any) => {
          if (newContactId) {
            optionId.value = options.value[0]?.id
            getVesting()
          }
        }
      )

      watch(options, (val: any) => {
        optionId.value = val.map((op: any) => op.id).includes(optionId.value)
          ? optionId.value
          : options.value[0]?.id
      })

      return {
        columns,
        moment,
        optionId,
        options,
        mode,
        period,
        totalQuantity,
        totalAcceleratedQuantity,
        totalVested,
        vestingType,
        date,
        selectedOptionId,
        exercisePeriods,
        sold,
        selectedOption,
        isLoading,
        formatNumber,
        updateSold
      }
    }
  })
