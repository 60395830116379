<template>
  <div v-if="parts.length > 0" class="w-full flex flex-col">
    <div class="event-bg w-auto mb-4">
      <div class="p-3 border-r border-secondary6 w-1/3">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
        <p class="text-blackp text-sm text-left">
          {{ $t(`optionSunsetMode.${event?.mode}`) }}
        </p>
      </div>
    </div>

    <div :class="`flex overflow-scroll grid grid-cols-8`">
      <div class="p-3 flex items-end col-span-2">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.contact') }}
        </h1>
      </div>
      <div class="p-3 flex items-end col-span-2">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.option') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.exercisableBalanceDate') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.nonVestedDate') }}
        </h1>
      </div>

      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.sunsetNotVestedQuantity') }}
        </h1>
      </div>

      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.sunsetVestedQuantity') }}
        </h1>
      </div>
    </div>

    <div class="event-bg">
      <div class="">
        <div
          v-for="(part, index) in parts"
          :key="part.id"
          :class="`w-full flex-wrap flex grid grid-cols-8 ${
            contactsId.includes(part.exerciseCalendar.contact?.id)
              ? 'bg-active font-bold'
              : ''
          }  ${index > 0 ? 'border-t border-secondary6 ' : ''}`"
        >
          <div
            class="
              p-2
              border-r border-secondary6
              flex
              items-center
              justify-start
              col-span-2
            "
          >
            <p
              v-if="part.exerciseCalendar?.contact"
              class="text-blackp text-sm text-left"
            >
              <router-link
                :target="`${company?.id ? '_blank' : ''}`"
                :to="{
                  name: 'company.directory',
                  params: {
                    id: company?.id || $route.params.id,
                    contactId: part.exerciseCalendar?.contact.id
                  }
                }"
              >
                <ContactBadge
                  class="p-0 text-xs"
                  :contact="part.exerciseCalendar?.contact"
                  :details="false"
                  type="light"
              /></router-link>
            </p>
            <p v-else class="text-blackp text-sm text-left">-</p>
          </div>
          <div class="p-3 border-r border-secondary6 col-span-2">
            <Tag
              v-if="part.exerciseCalendar.optionAlias"
              :text="part.exerciseCalendar.optionAlias.alias.toUpperCase()"
              :value="part.exerciseCalendar.optionAlias.id"
              :with-initials="false"
            />

            <p v-else class="text-blackp text-sm text-left">-</p>
          </div>
          <div
            class="p-3 border-r border-secondary6 flex items-center justify-end"
          >
            <p class="text-blackp text-sm text-left">
              {{ moment.utc(part.exercisableBalanceDate).format('L') }}
            </p>
          </div>
          <div
            class="p-3 border-r border-secondary6 flex items-center justify-end"
          >
            <p class="text-blackp text-sm text-left">
              {{ moment.utc(part.nonVestedDate).format('L') }}
            </p>
          </div>
          <div
            class="p-3 border-r border-secondary6 flex items-center justify-end"
          >
            <p class="text-blackp text-sm text-left">
              {{ part.nonVestedQuantity }}
            </p>
          </div>

          <div class="p-3 flex items-center justify-end">
            <p class="text-blackp text-sm text-left">
              {{ part.exercisableQuantity }}
            </p>
          </div>
        </div>
      </div>
      <div
        :class="`grid grid-cols-8 ${
          parts.length > 0 ? ' border-t border-secondary6 ' : ''
        } flex`"
      >
        <div
          class="
            col-span-4
            border-r border-secondary6
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>

        <div class="border-r border-secondary6 flex items-center justify-end">
          <p class="text-right px-2 font-semibold">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p class="text-blackp text-sm text-left">-</p>
        </div>

        <div
          class="p-3 flex items-center border-r border-secondary6 justify-end"
        >
          <p class="text-blackp text-sm text-left">
            {{ formatNumber(totalNonVested) }}
          </p>
        </div>
        <div class="p-3 flex items-center justify-end">
          <p class="text-blackp text-sm text-left">
            {{ formatNumber(totalVested) }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="event?.contact?.id" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.option') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        <Tag
          v-for="stock in event?.optionAliases || []"
          :key="stock.id"
          :text="stock.alias"
          :value="stock.id"
          :with-initials="false"
          :with-remove="false"
        />
      </p>
    </div>

    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.contact') }}
      </h1>
      <p v-if="event?.contact" class="text-blackp text-sm text-left">
        <router-link
          :to="{
            name: 'company.directory',
            params: {
              id: $route.params.id,
              contactId: event.contact?.id
            }
          }"
        >
          <ContactBadge
            class="p-1 m-0"
            :contact="event.contact"
            :details="false"
            type="light"
          />
        </router-link>
      </p>
      <p class="text-left" v-else>-</p>
    </div>
  </div>
  <div v-if="event?.contact?.id" class="flex event-bg mt-5 grid grid-cols-3">
    <div class="p-3 flex items-start flex-col border-r border-secondary6">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.nonExercisableSunsetDate') }}
      </h1>
      <p
        v-if="event.nonExercisableSunsetDate"
        class="text-right px-2 font-semibold"
      >
        {{ moment.utc(event.nonExercisableSunsetDate).format('L') }}
      </p>
      <p v-else class="text-right px-2 font-semibold">-</p>
    </div>
    <div class="p-3 flex items-start flex-col border-r border-secondary6">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.exercisableSunsetDate') }}
      </h1>
      <p
        v-if="event.exercisableSunsetDate"
        class="text-right px-2 font-semibold"
      >
        {{ moment.utc(event.exercisableSunsetDate).format('L') }}
      </p>
      <p v-else class="text-right px-2 font-semibold">-</p>
    </div>
    <div
      v-if="event.sunsetedExercisablePercentage"
      class="p-3 flex flex-col items-start border-r border-secondary6"
    >
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.sunsetPercent') }}
      </h1>
      <p class="text-right px-2 font-semibold">
        {{ event.sunsetedExercisablePercentage }} %
      </p>
    </div>
    <div
      v-if="
        event.sunsetExercisableQuantity && !event.sunsetedExercisablePercentage
      "
      class="p-3 flex items-start flex-col border-r border-secondary6"
    >
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.sunsetExercisabelQuantity') }}
      </h1>
      <p class="text-right px-2 font-semibold">
        {{ event.sunsetExercisableQuantity }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { Company, OptionSunset } from '@/services/api/models'
  import { defineComponent, computed, watch, onMounted } from 'vue'
  import moment from 'moment'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  export default defineComponent({
    name: 'SunsetCard',
    components: { ContactBadge, Tag },
    props: {
      event: {
        type: Object as () => OptionSunset | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      // const contactsId = ref<string[]>([])
      const parts = computed(() => props.event?.optionSunsetParts || [])
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const totalVested = computed(() =>
        (props.event?.optionSunsetParts || []).reduce(
          (acc, v) => (acc += v.exercisableQuantity),
          0
        )
      )
      const totalNonVested = computed(() =>
        (props.event?.optionSunsetParts || []).reduce(
          (acc, v) => (acc += v.nonVestedQuantity),
          0
        )
      )

      watch(
        () => props.event,
        (val) => {
          console.log('watchhh', val)
        }
      )

      onMounted(() => {
        console.log(
          'onMounted',
          props.event?.optionSunsetParts,
          parts.value.length
        )
      })
      return {
        moment,
        contactsId,
        parts,
        totalVested,
        totalNonVested,
        formatNumber
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
