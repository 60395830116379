
  import { ActionListItem, MAINACTIONS } from '@/types'
  import { Button } from '@up.law/uplaw-ui'
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
    name: 'ActionList',
    components: { Button },
    props: {
      actionButton: {
        type: Boolean,
        required: false,
        default: false
      },
      iconClass: {
        type: String,
        default: 'bi-three-dots'
      },
      hoverClass: {
        type: String,
        default:
          'hover:bg-primary hover:bg-opacity-20 rounded-full text-secondary4'
      },
      actions: {
        type: Object as () => Array<ActionListItem>,
        required: false,
        default: null
      },
      actionTable: {
        type: Boolean,
        required: false
      }
    },
    emits: ['onClick', 'onDelete'],
    setup(props, { emit }) {
      const canDisplay = ref<boolean>(true)
      const confirm = ref<boolean>(false)
      const setCanDisplay = (value: boolean) => {
        canDisplay.value = value
      }
      const click = (eventKey: MAINACTIONS) => {
        emit('onClick', eventKey)
        canDisplay.value = false
      }

      const deleteEvent = (eventKey: string) => {
        emit('onDelete', eventKey)
        canDisplay.value = false
      }

      const confirmDelete = () => {
        confirm.value = true
      }

      const init = () => {
        confirm.value = false
      }

      return {
        click,
        canDisplay,
        confirm,
        setCanDisplay,
        init,
        confirmDelete,
        deleteEvent
      }
    }
  })
