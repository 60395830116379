
  import { CompanyModel, ContactCardProps } from '@/services/api/models'
  import { useStore } from '@/store'
  import { abbreviateNumber, createInitials, truncText } from '@/utils'
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    ref,
    watch
  } from 'vue'
  import { useRoute } from 'vue-router'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'ContactBadge',
    components: { ElTooltip },
    props: {
      usePicture: {
        type: Boolean,
        default: true
      },
      contact: {
        type: Object as () => ContactCardProps,
        required: false,
        default: () => ({})
      },
      details: {
        type: Boolean,
        required: false,
        default: false
      },
      initialsOnly: {
        type: Boolean,
        required: false,
        default: false
      },
      type: {
        type: String,
        required: false,
        default: 'primary'
      },
      mode: {
        type: String,
        required: false,
        default: 'contact'
      },
      size: {
        type: String,
        required: false,
        default: '45' //size in em
      },
      blockType: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const url = ref()
      const isLoading = ref(false)
      const isLoadingData = ref(props.details)
      const contactsPictures = computed(
        () => store.getters['organizations/contactsPictures']
      )

      const actions: any = {
        contact: 'LOAD_CONTACT_PICTURE',
        company: 'LOAD_COMPANY_LOGO',
        user: 'LOAD_USER_PROFILE_IMAGE'
      }

      const companiesLogos = computed(
        () => store.getters['organizations/companiesLogo']
      )
      const documentCount = ref(
        abbreviateNumber(Math.abs(props.contact?.documentsCount) || 0)
      )
      const stockCount = ref(abbreviateNumber(props.contact?.stocksCount || 0))

      const ucFirst = (text = '') => {
        const value = text
          .split(' ')
          .map(
            (elm) =>
              `${elm.charAt(0).toUpperCase()}${elm.slice(1).toLowerCase()}`
          )
          .join(' ')
        return value
      }
      const loadImage = async () => {
        if (
          props.contact?.id &&
          (contactsPictures.value[props.contact?.id] !== undefined ||
            companiesLogos.value[props.contact?.id] !== undefined)
        ) {
          url.value =
            contactsPictures.value[props.contact?.id] ||
            companiesLogos.value[props.contact?.id]

          return
        }
        isLoading.value = true
        const action: any = actions[props.mode]
        const response = await store.dispatch(`organizations/${action}`, {
          companyId:
            props.mode === 'company'
              ? props.contact?.id
              : currentCompany.value?.id ||
                route.params.id ||
                props.contact?.id,
          contactId: props.contact?.id,
          userId: props.contact?.id
        })
        if (response) url.value = response
        else url.value = ''

        isLoading.value = false
      }

      const loadComputedData = async () => {
        isLoadingData.value = true
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_COMPUTED_DATA',
          {
            companyId: currentCompany.value.id,
            contactId: props.contact?.id
          }
        )

        documentCount.value = abbreviateNumber(response.documentsCount || 0)
        stockCount.value = abbreviateNumber(response.stocksCount || 0)
        isLoadingData.value = false
      }
      const name = computed(() =>
        props.contact?.name
          ? props.contact?.name
          : `${props.contact?.lastName} ${props.contact?.firstName}`
      )
      const initials = computed(() => createInitials(name.value))

      onBeforeMount(async () => {
        url.value = ''
        if (
          props.mode === 'contact' &&
          props.usePicture &&
          props.contact?.picture
        ) {
          loadImage()
        } else {
          if (props.mode !== 'contact' && props.usePicture) loadImage()
        }
        // if (props.details) await loadComputedData()
      })

      onBeforeUnmount(() => {
        url.value = ''
      })

      watch(
        () => props,
        () => {
          if (
            props.usePicture &&
            props.mode === 'contact' &&
            props.contact?.picture
          )
            loadImage()
          else {
            if (props.mode !== 'contact' && props.usePicture) loadImage()
          }
        }
      )

      return {
        initials,
        name,
        documentCount,
        stockCount,
        url,
        isLoadingData,
        isLoading,
        truncateText: truncText
      }
    }
  })
