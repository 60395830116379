
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import { Event } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'

  export default defineComponent({
    components: { DataTable, EmptyState },
    setup() {
      const { t } = useI18n()
      const isLoading = ref(false)
      const store = useStore()
      const route = useRoute()
      const event = ref<Event | null>(null)
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const auditColumns = [
        {
          label: t(`datatable.column.date`),
          custom: true,
          field: 'createdAt',
          width: '3%'
        },
        { label: t(`datatable.column.motive`), custom: true, field: 'motive' },
        {
          label: t(`datatable.column.comment`),
          custom: true,
          field: 'comment'
        },
        {
          label: t(`datatable.column.processed`),
          custom: true,
          field: 'processed'
        }
      ]

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data

            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      onMounted(getEvent)

      return { auditColumns, moment, event }
    }
  })
