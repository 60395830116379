<template>
  <div
    :class="`flex flex-1 justify-${
      $can.any(['is-manager', 'admin']) ? 'start' : 'start'
    } items-center h-full`"
  >
    <ul v-if="currentCompany" class="layout_menu-list">
      <router-link
        v-can.any="['admin', 'is-manager', 'read-dashboard']"
        :to="{ name: 'companyOverview', params: { id: currentCompany.id } }"
      >
        <li>{{ $t('menu.overview') }}</li>
      </router-link>
      <router-link
        v-can.any="[
          'admin',
          'is-manager',
          'read-directory',
          'read-contacts',
          'self-contact',
          'employee'
        ]"
        :to="{ name: 'company.directory', params: { id: currentCompany.id } }"
      >
        <li>{{ $t('menu.directory') }}</li>
      </router-link>
      <router-link
        v-can.any="['admin', 'is-manager', 'read-documents', 'write-documents']"
        :to="{
          name: 'company.corporateDocuments',
          params: { id: currentCompany.id }
        }"
      >
        <li>{{ $t('menu.documents') }}</li>
      </router-link>

      <router-link
        v-can.any="['admin', 'is-manager', ['read-corporate']]"
        v-role.not="'employee'"
        :to="{
          name: 'company.corporateEvents',
          params: { id: currentCompany.id }
        }"
      >
        <li>{{ $t('menu.events') }}</li>
      </router-link>
      <router-link
        v-can.any="['admin', 'is-manager', ['read-captable']]"
        v-role.not="'employee'"
        :to="{ name: 'companyCaptable', params: { id: currentCompany.id } }"
      >
        <li>{{ $t('menu.captable') }}</li>
      </router-link>
      <router-link
        v-can.any="['admin', 'is-manager', 'read-ownership']"
        v-role.not="'employee'"
        :to="{
          name: 'company.shareholding',
          params: { id: currentCompany.id }
        }"
      >
        <li>{{ $t('menu.vesting') }}</li>
      </router-link>
      <router-link
        v-role.not="'employee'"
        v-can.any="[
          'admin',
          'is-manager',
          'read-transactions',
          'read-decisions',
          'read-mandates',
          'read-delegations',
          'read-mandates',
          'read-meetings'
        ]"
        :to="{
          name: 'register.stockMovement',
          params: { id: currentCompany.id }
        }"
        :class="`${
          isInRegisterMenu
            ? 'router-link-active router-link-exact-active'
            : 'hover:border-b-0'
        }`"
      >
        <li class="hover:border-b-0">
          <span class="flex items-center">
            {{ $t('menu.registers') }}
            <i
              class="ml-2 mt-1 text-secondary font-weight-bold bi-chevron-down"
            />
          </span>
          <ul
            class="
              layout_menu-sublist
              border border-secondary4
              rounded
              transition transition-all
            "
          >
            <router-link
              v-can.any="['admin', 'is-manager', ['read-transactions']]"
              :to="{
                name: 'register.stockMovement',
                params: { id: currentCompany.id }
              }"
            >
              <li class="text-left">{{ $t('menu.movements') }}</li>
            </router-link>
            <router-link
              v-can.any="['admin', 'is-manager', 'read-delegations']"
              :to="{
                name: 'register.delegates',
                params: { id: currentCompany.id }
              }"
            >
              <li class="text-left">{{ $t('menu.delegations') }}</li>
            </router-link>
            <router-link
              v-can.any="['admin', 'is-manager', ['read-mandates']]"
              :to="{
                name: 'register.mandates',
                params: { id: currentCompany.id }
              }"
            >
              <li class="text-left">{{ $t('menu.mandates') }}</li>
            </router-link>
            <router-link
              v-can.any="['admin', 'is-manager', ['read-meetings']]"
              :to="{
                name: 'register.meetings',
                params: { id: currentCompany.id }
              }"
            >
              <li class="text-left">{{ $t('menu.meetings') }}</li>
            </router-link>
            <router-link
              v-can.any="['admin', 'is-manager', ['read-committees']]"
              :to="{
                name: 'register.committees',
                params: { id: currentCompany.id }
              }"
            >
              <li class="text-left">{{ $t('menu.boards') }}</li>
            </router-link>
            <router-link
              v-can.any="['admin', 'is-manager', ['read-decisions']]"
              :to="{
                name: 'register.presidentDecisions',
                params: { id: currentCompany.id }
              }"
            >
              <li class="text-left">{{ $t('menu.presidentDecisions') }}</li>
            </router-link>
          </ul>
        </li>
      </router-link>
      <router-link
        v-can.any="['admin', 'is-manager', 'read-audits', 'write-audits']"
        :to="{ name: 'company.audits', params: { id: currentCompany.id } }"
      >
        <li>{{ $t('menu.audits') }}</li>
      </router-link>
    </ul>
  </div>
</template>
<script lang="ts">
  import { CompanyModel } from '@/services/api/models1'
  import { computed, defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  import { useAcl } from 'vue-simple-acl/src'
  import { useStore } from 'vuex'

  export default defineComponent({
    inject: ['$access'],

    setup() {
      const acl = useAcl()
      const store = useStore()
      const route = useRoute()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const isInRegisterMenu = computed(
        () =>
          route.name?.toString() !== 'register.stockMovement' &&
          route.name?.toString().split('.').indexOf('register') == 0
      )

      return { currentCompany, isInRegisterMenu, acl }
    }
  })
</script>
