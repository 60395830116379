<template>
  <section
    :class="`transition transition-all duration-[3000ms] event__wrapper mt-3 mb-10 ${
      isSelected ? 'selected' : ''
    } ${isRemoved ? 'hidden' : ''}`"
  >
    <div class="event__header flex justify-between mb-2">
      <div class="event__infos flex flex-col justify-left items-left">
        <div class="font-bold text-left">
          <i
            :class="`bi bi-chevron-${
              displayed ? 'down' : 'up'
            } mr-2 transition transition-all duration-75`"
            @click="collapseCard()"
          ></i>
          {{ $t(`events.labels.${event.type}`) }}
          <span
            v-if="event.isDraft"
            class="border border-error rounded my-2 py-1 px-2 text-error"
          >
            {{ $t('events.draftEvent') }}
          </span>
        </div>
        <div class="text-primary4 text-left">
          <p v-if="event.date" class="py-2 font-bold">
            {{ moment.utc(event.date).format('L') }}
          </p>
          <p v-else>-</p>
        </div>
      </div>
      <event-actions
        :is-portfolio="isPortfolio"
        :item-id="event.id"
        :item="event"
        @toggle-side="toggleSide"
        @select-card="selectCard"
        @removed="onRemoved"
        @updated="$emit('updated')"
      />
    </div>
    <div v-if="currentSide === CardType.EVENT">
      <loader v-if="isLoading"></loader>
      <div v-else>
        <transition name="coll">
          <div v-show="displayed" class="event-card-details">
            <delegation
              v-if="event.type?.toString() === EventType.delegation"
              :event="currentEvent.delegation"
            />
            <delegation-sunset
              v-if="event.type?.toString() === EventType.delegationSunset"
              :event="currentEvent.delegationSunset"
            />
            <sunset
              v-if="event.type?.toString() === EventType.optionSunset"
              :event="currentEvent.optionSunsets"
              :filters="filters"
              :company="currentEvent.company"
            />
            <ceiling
              v-else-if="event.type?.toString() === EventType.commongCeiling"
              :event="currentEvent.commonCeiling"
            />
            <transfer
              v-else-if="event.type?.toString() === EventType.transfer"
              :event="currentEvent.transfer"
              :filters="filters"
              :is-portfolio="isPortfolio"
              :company="currentEvent.company"
            />
            <nomination
              v-if="event.type?.toString() === EventType.appointment"
              :event="currentEvent.appointment"
              :company="currentEvent.company"
            />
            <conversion
              v-if="event.type?.toString() === EventType.conversion"
              :event="currentEvent.conversion"
              :filters="filters"
              :company="currentEvent.company"
            />
            <option-exercise
              v-if="event.type?.toString() === EventType.optionExercise"
              :event="currentEvent.optionExercise"
              :filters="filters"
              :company="currentEvent.company"
            />
            <nominal-value
              v-if="event.type?.toString() === EventType.nominalValue"
              :event="currentEvent.nominalValue"
              :company="currentEvent.company"
            />
            <financial-ending
              v-if="event.type?.toString() === EventType.financialEnding"
              :event="currentEvent.financialEnding"
              :company="currentEvent.company"
            />

            <parity-change
              v-if="event.type?.toString() === EventType.parityChange"
              :event="currentEvent.parityChange"
              :company="currentEvent.company"
            />

            <vesting
              v-else-if="
                event.type?.toString() === EventType.exerciseDelayProrogation
              "
              :event="currentEvent.exerciseDelayProrogation"
              :company="currentEvent.company"
            />
            <approval
              v-else-if="event.type?.toString() === EventType.accountApproval"
              :event="currentEvent.accountApproval"
              :company="currentEvent.company"
            />
            <reduction
              v-else-if="event.type?.toString() === EventType.capitalReduction"
              :event="currentEvent.capitalReduction"
              :filters="filters"
              :company="currentEvent.company"
            />
            <vesting
              v-else-if="event.type?.toString() === EventType.vestingSuspension"
              :event="currentEvent.vestingSuspension"
              :company="currentEvent.company"
            />
            <appointment-termination
              v-else-if="
                event.type?.toString() === EventType.appointmentTermination
              "
              :company="currentEvent.company"
              :event="currentEvent.appointmentTermination"
            />

            <issuance
              v-else-if="
                event.type?.toString() === 'STOCK_ISSUANCE' ||
                event.type?.toString() === 'OPTION_ISSUANCE' ||
                event.type?.toString() === 'COMPOSITION_ISSUANCE'
              "
              :is-portfolio="isPortfolio"
              :nominal-value="nominalValue"
              :event="currentEvent"
              :filters="filters"
              :type="event.type"
            />
            <final-acquisition
              :event="currentEvent.freeSharesFinalAcquisition"
              v-else-if="
                event.type?.toString() === EventType.freeSharesFinalAcquisition
              "
            />
            <free-share-sunset
              :event="currentEvent.freeSharesSunset"
              v-else-if="event.type?.toString() === EventType.freeSharesSunset"
            />
            <FreeSharesIssuance
              v-else-if="event.type?.toString() === EventType.freeShareIssuance"
              :event="currentEvent"
              :filters="filters"
              :type="event.type"
            />

            <div
              v-else-if="
                event.type?.toString() === EventType.vestingAcceleration
              "
              class="flex justify-start"
            >
              <acceleration
                :event="currentEvent?.vestingAcceleration"
              ></acceleration>
            </div>
            <div v-else>
              <div v-for="element in elements" :key="element.id" class="flex">
                <div>{{ element.name }}:</div>
                <div class="ml-4">
                  {{ parseValue(element.value) }}
                </div>
              </div>
              <data-table
                v-if="scalarElements.length > 0"
                container-class="overflow-x-scroll"
                :has-checkbox="false"
                :columns="tableColumns(scalarElements[0])"
                :rows="data"
                index-key="id"
                text-size=""
                cell-padding=""
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div v-else>
      <transition name="coll">
        <event-documents-card
          v-show="displayed"
          :is-portfolio="isPortfolio"
          :event="event"
          :documents="event.documents"
        />
      </transition>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent, computed, toRefs, ref, watch, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import EventActions from '@/components/Event/EventActions.vue'
  import Delegation from './types/delegation.vue'
  import moment from 'moment'
  import Ceiling from './types/ceiling.vue'
  import Transfer from './types/transfer.vue'
  import Nomination from './types/nomination.vue'
  import Reduction from './types/reduction.vue'
  import Vesting from './types/vesting.vue'
  import Acceleration from './types/acceleration.vue'
  import OptionExercise from './types/exercise.vue'
  import NominalValue from './types/nominalValue.vue'
  import FinancialEnding from './types/finacialEnding.vue'
  import AppointmentTermination from './types/appointmentTermination.vue'
  import Conversion from './types/conversion.vue'
  import ParityChange from './types/parityChange.vue'
  import Sunset from './types/sunset.vue'
  import Approval from './types/approval.vue'
  import DelegationSunset from './types/delegationSunset.vue'
  import FreeSharesIssuance from './types/freeSharesIssuance.vue'
  import FinalAcquisition from './types/finalAcquisition.vue'
  import Issuance from './types/issuance.vue'
  import FreeShareSunset from './types/freeShareSunset.vue'
  import { CardType } from '@/types/config'
  import EventDocumentsCard from './EventDocumentsCard.vue'
  import { Event } from '@/services/api/models'
  import { EventType } from '@/types/event'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Loader from '../Loader/index.vue'
  export default defineComponent({
    name: 'EventCard',
    components: {
      EventActions,
      Acceleration,
      Delegation,
      DelegationSunset,
      Sunset,
      ParityChange,
      Ceiling,
      Transfer,
      Nomination,
      Vesting,
      Approval,
      Issuance,
      FinalAcquisition,
      FreeSharesIssuance,
      FreeShareSunset,
      Reduction,
      OptionExercise,
      NominalValue,
      FinancialEnding,
      EventDocumentsCard,
      DataTable,
      AppointmentTermination,
      Conversion,
      Loader
    },
    props: {
      event: {
        type: Object as () => Event,
        default: () => ({})
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select-card', 'collapse', 'updated'],
    setup(props, { emit }) {
      const { event } = toRefs(props)
      const currentEvent = ref(props.event)
      const defaultFilters = computed(() => props.filters)
      const isRemoved = ref(false)
      const isLoading = ref(false)
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const displayed = ref(!props.collapsed)
      const currentSide = ref(CardType.EVENT)
      const toggleAudit = ref(false)
      const isSelected = ref(false)
      const nominalValue = ref(0)
      const nominalValues = computed(
        () => store.getters['organizations/nominalValues']
      )

      const collapseCard = () => {
        emit('collapse', !displayed.value)
      }
      // TODO: WTF IS THIS???????? Reduce on an empty array??????
      const elements = computed<any>(() => {
        const els = [].reduce((acc: any, item: any) => {
          acc = acc.concat(
            item.filter((item: any) => !item?.isScalar ?? true) ?? []
          )
          return acc
        }, [])
        return [
          ...new Map(
            els.map((item: any) => [JSON.stringify(item), item])
          ).values()
        ]
      })
      // TODO: WTF IS THIS???????? Reduce on an empty array??????
      const scalarElements = computed(() => {
        return [].reduce((acc: any, item: any) => {
          acc.push(item.filter((item: any) => item.isScalar) ?? [])
          return acc
        }, [])
      })

      const tableColumns = (item: any) => {
        return item.reduce((acc: any, item: any) => {
          acc.push({
            label: t(`events.elements.${item.name}`),
            field: item.name,
            width: item.width ?? '10%',
            sortable: item.sortable ?? true,
            custom: item.custom ?? false,
            customDefault: item.customDefault ?? false
          })
          return acc
        }, [])
      }

      const getValue = (value: string | undefined) => {
        return value && value.charAt(0) === '{'
          ? JSON.parse(value)
          : value ?? ''
      }

      const parseValue = (val: string | undefined) => {
        const value = getValue(val)
        return typeof value === 'object'
          ? value.name ||
              (typeof value.key === 'string' ? value.key : value.key.name)
          : value
          ? value
          : '-'
      }

      const data = computed(() => {
        return scalarElements.value.reduce((acc: any, item: any) => {
          const el = item.reduce((acc2: any, item2: any) => {
            acc2[item2.name] = parseValue(item2.value)
            return acc2
          }, {})
          acc.push(el)
          return acc
        }, [])
      })

      const getEvent = () => {
        let module = 'organizations'
        if (props.isPortfolio) module = 'portfolio'
        isLoading.value = true
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId: props.event?.id,
          type: props.event?.type,
          onSuccess: (data: Event) => {
            isLoading.value = false
            event.value = { ...data }
            currentEvent.value = { ...data }
          },
          onError: (error: any) => {
            isLoading.value = false
          }
        })
      }

      const toggleSide = (what: CardType) => {
        currentSide.value = what
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        isSelected.value = data.check
        emit('select-card', data)
      }

      const onRemoved = (id: string) => {
        isRemoved.value = true
      }

      const calculateNominalValue = async () => {
        const value = await store.dispatch(
          'organizations/COMPUTE_NOMINAL_VALUE',
          { date: event.value?.date, setAsGlobal: false }
        )
        if (value?.nominalValue) nominalValue.value = value?.nominalValue
      }

      watch(
        () => props.collapsed,
        (val) => {
          displayed.value = !val
        }
      )

      watch(
        () => displayed.value,
        (val) => {
          if (val) {
            getEvent()
          }
        }
      )

      onMounted(async () => {
        isLoading.value = true
        calculateNominalValue()
        if (!props.collapsed) {
          getEvent()
        }

        isLoading.value = false
      })

      return {
        displayed,
        collapseCard,
        currentSide,
        toggleAudit,
        isSelected,
        elements,
        isLoading,
        scalarElements,
        currentEvent,
        nominalValue,
        tableColumns,
        parseValue,
        data,
        isRemoved,
        defaultFilters,
        toggleSide,
        selectCard,
        moment,
        onRemoved,
        CardType,
        EventType
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/eventcard.scss';

  .coll-enter-active,
  .coll-leave-active {
    transition: max-height 0.5s ease-in-out;
    max-height: 200px;
    overflow: hidden;
  }

  .coll-enter-from,
  .coll-leave-to {
    max-height: 0;
  }

  .event-docView {
    border-radius: 4px;
    border: 1px solid rgb(208, 210, 214);
    background-color: rgb(245, 247, 250);

    .event-doc-wrapper {
      @apply p-4 pt-2 flex;

      .event-doc {
        @apply p-1 px-3 border-r-2 text-sm;
        text-align: left;
        border-radius: 10px;
        border: 1px solid rgb(208, 210, 214);
        background-color: #ffffff;
      }
    }
  }
</style>
