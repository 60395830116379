
  import { defineComponent, onMounted } from 'vue'

  export default defineComponent({
    name: 'UplAddress',
    props: {
      name: { type: String, value: '' }
    },
    emits: ['onChange'],
    setup(props, { emit }) {
      const setPlace = ($event: any) => {
        const fullAddress = $event.formatted_address
        const postalCode = ($event.address_components || []).find((x: any) =>
          x.types.includes('postal_code')
        )?.long_name

        const city = ($event.address_components || []).find((x: any) =>
          x.types.includes('locality')
        )?.long_name

        const country = ($event.address_components || []).find((x: any) =>
          x.types.includes('country')
        )?.long_name

        const streetNumber = ($event.address_components || []).find((x: any) =>
          x.types.includes('street_number')
        )?.long_name

        const route = ($event.address_components || []).find((x: any) =>
          x.types.includes('route')
        )?.long_name

        let address = ''
        if (streetNumber) address += streetNumber
        if (route) address += ` ${route}`

        const data = {
          fullAddress,
          address,
          country,
          city,
          postalCode
        }
        emit('onChange', data)
      }

      onMounted(() => {
        console.log()
      })
      return { setPlace }
    }
  })
