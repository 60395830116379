import {
  CREATE_FREE_SHARES_VESTING,
  GET_FREE_SHARES_VESTINGS,
  OrganizationState,
  SET_FREE_SHARES_VESTINGS
} from './models'
import { ActionContext } from 'vuex'
import { RootState } from '../../index'
import { FreeShareIssuancePart } from '../../../services/api/models/events'
import {
  CREATE_FREE_SHARE_FINAL_ACQUISITION,
  CREATE_FREE_SHARE_SUNSET
} from './models'
import {
  SET_FREE_SHARES_ACQUISITION_CALENDARS,
  GET_CONTACT_FINAL_ACQUISITION_CALENDARS,
  SET_CONTACT_EXERCISE_CALENDARS
} from './models'
import {
  GET_FREE_SHARES_ACQUISITION_CALENDAR,
  GET_FREE_SHARES_ACQUISITION_CALENDARS
} from './models'
import {
  SET_CURRENT_VESTING,
  CREATE_FREE_SHARES_ISSUANCE,
  EDIT_FREE_SHARES_ISSUANCE
} from './models'
import {
  createFreeSharesFinalAcquisition,
  createFreeSharesIssuance,
  createFreeSharesSunsets,
  createFreeSharesVesting,
  editFreeSharesIssuance,
  getFreeSharesAcquisitionCalendars,
  getFreeSharesVesting,
  getFreeSharesVestings,
  removeFreeSharesVesting,
  updateFreeSharesVesting
} from '@/services/api'
import {
  GET_FREE_SHARES_VESTING,
  SET_FREE_SHARES_VESTING,
  EDIT_FREE_SHARES_VESTING,
  DELETE_FREE_SHARES_VESTING
} from './models'
const context = 'FREE_SHARES'

export const freeSharesActions = {
  async [GET_FREE_SHARES_VESTINGS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const { data } = await getFreeSharesVestings(companyId, filter)
      if (onSuccess) onSuccess(data)
      else commit(SET_FREE_SHARES_VESTINGS, data)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [GET_FREE_SHARES_VESTING](
    { commit }: ActionContext<OrganizationState, RootState>,
    { vestingId, companyId, onSuccess, onError }: any
  ) {
    try {
      const vesting = await getFreeSharesVesting(companyId, vestingId)
      if (onSuccess) onSuccess(vesting)
      else commit(SET_CURRENT_VESTING, { ...vesting, isFreeShareVesting: true })
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [CREATE_FREE_SHARES_VESTING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, companyId, onSuccess, onError }: any
  ) {
    try {
      await createFreeSharesVesting(companyId, form)
      dispatch(GET_FREE_SHARES_VESTINGS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [CREATE_FREE_SHARES_ISSUANCE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId }: any
  ) {
    try {
      await createFreeSharesIssuance(companyId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async [EDIT_FREE_SHARES_ISSUANCE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, eventId, companyId }: any
  ) {
    try {
      await editFreeSharesIssuance(companyId, eventId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async [EDIT_FREE_SHARES_VESTING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, vestingId, companyId, onSuccess, onError }: any
  ) {
    try {
      await updateFreeSharesVesting(companyId, vestingId, form)
      dispatch(GET_FREE_SHARES_VESTINGS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [DELETE_FREE_SHARES_VESTING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { vestingId, companyId, onSuccess, onError }: any
  ) {
    try {
      await removeFreeSharesVesting(companyId, vestingId)
      dispatch(GET_FREE_SHARES_VESTINGS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [GET_FREE_SHARES_ACQUISITION_CALENDARS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, page, filter, onSuccess, onError }: any
  ) {
    try {
      for (const key in filter) {
        if (filter[key] === '' || !filter[key]) {
          delete filter[key]
        }
      }
      const params = {
        ...filter,
        limit: state.freeSharesAcquisitionCalendarsPagination.limit,
        offset:
          (page - 1) * state.freeSharesAcquisitionCalendarsPagination.limit
      }
      const data = await getFreeSharesAcquisitionCalendars(companyId, params)

      commit(SET_FREE_SHARES_ACQUISITION_CALENDARS, data)
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [CREATE_FREE_SHARE_FINAL_ACQUISITION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }: any
  ) {
    try {
      await createFreeSharesFinalAcquisition(companyId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_FREE_SHARE_SUNSET](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }: any
  ) {
    try {
      await createFreeSharesSunsets(companyId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async [GET_CONTACT_FINAL_ACQUISITION_CALENDARS](
    //Used to build actions in free Share final acquisition
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }: any
  ) {
    try {
      const response = await getFreeSharesAcquisitionCalendars(
        companyId,
        filter
      )

      commit(SET_CONTACT_EXERCISE_CALENDARS, {
        list: [],
        contactId: filter.contactId,
        stocks: response.data.map((x: any) => ({
          ...(x?.freeSharesIssuancePart?.freeShareIssuance?.stockAlias || {}),
          id: `${x.id}@_${x?.freeSharesIssuancePart?.freeShareIssuance?.stockAlias?.id}`,
          name: x?.freeSharesIssuancePart?.freeShareIssuance?.stockAlias.alias,
          endDate:
            x?.acquisitionPeriods[x?.acquisitionPeriods?.length - 1]?.endDate
        }))
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
