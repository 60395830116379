
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'
  import {
    CompanyModel,
    ContactGroup,
    ContactStatus,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { useStore } from 'vuex'
  import Multiselect from '../Select/multiselect.vue'
  import DropdownStockByGroup from '../Forms/DropDowns/DropdownStockByGroup.vue'
  import { sortObjectByKeys } from '@/utils'
  import ContactBadge from '../ContactBadge/index.vue'
  import EmptyState from '../EmptyState/index.vue'
  export default defineComponent({
    components: {
      Field,
      Button,
      Input,
      Multiselect,
      DropdownStockByGroup,
      ContactBadge,
      EmptyState
    },
    props: {
      handleNavigation: { type: Boolean, default: true }
    },
    emits: ['onClick'],
    setup(props, { emit }) {
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      const filter = ref<any>({
        name: '',
        contactStatusId: '',
        type: '',
        contactGroupId: ''
      })
      const isLoadingData = ref(false)

      const groupsDisplay = ref<any>({})
      const aliasesId = ref([])
      const contactStatusId = ref('')
      const contactType = ref('')
      const acl = useAcl()
      const canSeeFilter = computed(() =>
        acl.anyPermission(['write-contacts', 'read-contacts'])
      )
      const filtered = computed(
        () =>
          aliasesId.value?.length !== 0 ||
          contactType.value !== '' ||
          contactStatusId.value != '' ||
          filter.value.name != ''
      )
      const isVisible = ref(true)

      const companyGroups = computed<ContactGroup[]>(
        () => store.getters['organizations/groups']
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const allGroups = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contactsResult'])

      const groups = computed(() => {
        const noGroupContacts = allGroups.value.filter(
          (c) => c.contactGroups?.length == 0
        )
        const map = new Map()
        if (noGroupContacts.length > 0) {
          map.set('zz-other', noGroupContacts)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          groupsDisplay.value['zz-other'] = false
        }
        allGroups.value
          .filter((c) => c.contactGroups?.length)
          .forEach((elm, i) => {
            elm?.contactGroups?.forEach((group) => {
              const collection = map.get(group.name)
              if (!collection) {
                map.set(group.name, [elm])
                groupsDisplay.value[group.name] = false
              } else {
                collection.push(elm)
              }
            })
          })
        const sortedMap = sortObjectByKeys(map)

        return sortedMap
      })

      const onEnterClick = () => {
        getContacts()
      }

      const toggleGroup = (id: string, value = false) => {
        groupsDisplay.value[id] = !groupsDisplay.value[id] || value
      }

      const getCountContact = (id: string) => {
        return companyGroups.value.find((x) => x.name === id)?.countContacts
      }

      const resetFilter = () => {
        aliasesId.value = []
        contactType.value = ''

        filter.value = {
          name: '',
          contactGroupId: '',
          contactStatusId: '',
          type: '',
          stockAliasIds: [],
          optionAliasIds: [],
          compositionAliasIds: []
        }
        getContacts()
        isVisible.value = false
      }
      const getContacts = () => {
        if (!currentCompany.value) return

        store.dispatch('organizations/FILTER_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: { ...filter.value, limit: 10, offset: 0 }
        })
      }

      const handleSelection = ({ selected }: any) => {
        filter.value = { ...filter.value, ...selected }
      }

      const handleUserClick = async (
        contact: IndividualContact | ProfessionalContact | InvestorContact
      ) => {
        if (props.handleNavigation)
          router.push({
            name: 'company.directory',
            params: { id: currentCompany.value.id, contactId: contact.id }
          })
        else emit('onClick', contact)
        isLoadingData.value = true
      }

      const getGroups = () => {
        store.dispatch('organizations/GET_COMPANY_GROUPS', {
          companyId: route.params.id
        })
      }

      watch(
        () => groups.value,
        () => {
          const iterator = groups.value.keys()
          let key = 'zz-other'
          if (!groups.value.has(key)) {
            key = iterator.next().value
          }
          toggleGroup(key, true)
        }
      )

      onMounted(() => {
        getGroups()
      })
      return {
        filtered,
        isVisible,
        filter,
        companyGroups,
        aliasesId,
        groups,
        canSeeFilter,
        isLoadingData,
        getContacts,
        toggleGroup,
        resetFilter,
        handleSelection,
        getCountContact,
        handleUserClick,
        groupsDisplay,
        onEnterClick
      }
    }
  })
