<template>
  <div class="w-full h-full overflow-y-scroll pb-5 px-12">
    <div class="flex items-center justify-start">
      <h3 class="text text-blackp text-2xl py-8">
        {{ $t('dropzone.title') }}
      </h3>
    </div>

    <div class="w-full flex">
      <div class="w-1/3">
        <Field v-slot="{ field }" v-model="term" name="term">
          <UplInput
            v-model="term"
            v-bind="field"
            name="term"
            autocomplete="off"
            class=""
            type="text"
            :disbaled="false"
            :placeholder="$t('document.searchPlaceholder')"
          >
            <template #left>
              <i class="bi-search"></i>
            </template>
          </UplInput>
        </Field>
      </div>
      <div class="w-1/3 ml-2">
        <Datepicker
          v-model="picked"
          range
          :format="$t('format')"
          class="rounded h-10 text-xs mr-2"
          :placeholder="$t('global.placeholder.dateRange')"
        />
      </div>

      <div class="h-10 w-1/3 flex justify-end">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.resetFilter')"
          placement="left"
        >
          <Button variant="custom" class="button-filter" @click="resetFilters">
            <i class="bi bi-filter text-2xl"></i> </Button
        ></el-tooltip>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="flex w-full min-h-full h-full justify-center items-center mt-10"
    >
      <Loader />
    </div>

    <div v-else-if="formattedFiles.length" class="mt-5">
      <data-table
        :columns="columns"
        :rows="formattedFiles"
        index-key="id"
        :pagination="pagination"
        :isFiltering="loading"
        @onPageChange="onPageChange"
      >
        <template #status="{ row }">
          <span
            :class="`rounded p-2 text-white bg-${
              row.isDraft ? 'warning' : 'success'
            }`"
          >
            {{ $t(`document.status.${row.isDraft ? 'draft' : 'not-draft'}`) }}
          </span>
        </template>
        <template #name="{ row }">
          <div>
            <LongText
              :text="row.name"
              tag="p"
              classes="text-left"
              :max-lenght="100"
            />
            <p class="text-secondary8">
              {{ row.docSubCategory?.name }} /
              {{ row.docSubCategory?.docCategory?.name }}
            </p>
          </div>
        </template>
        <template #actions="slotProps">
          <div class="flex justify-end">
            <Btn
              variant="secondary"
              size="medium"
              class="p-1 rounded-sm"
              label=""
              @click="handleClick(slotProps.row)"
            >
              <i class="bi bi-eye text-xl"></i>
            </Btn>
            <Btn
              variant="secondary"
              size="medium"
              class="p-1 rounded-sm ml-2"
              label=""
              @click="handleClick(slotProps.row)"
            >
              <i class="bi bi-lightning text-xl"></i>
            </Btn>
          </div>
        </template>
      </data-table>
    </div>

    <div
      v-if="!isLoading && formattedFiles.length == 0"
      class="flex justify-center mt-10"
    >
      <EmptyState
        image="no-result.svg"
        :title="$t('global.noResult')"
        :subtitle="$t('global.selectFilters')"
      />
    </div>
  </div>
</template>
<script lang="ts">
  import { Button, Input } from '@up.law/uplaw-ui'
  import DataTable from '@/components/DataTable/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'

  import {
    defineComponent,
    ref,
    computed,
    onBeforeUnmount,
    onMounted,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import { Document } from '@/services/api/models'
  import moment from 'moment'
  import { useRoute, useRouter } from 'vue-router'
  import LongText from '@/components/LongText/index.vue'
  import { useI18n } from 'vue-i18n'
  import { Field } from 'vee-validate'
  import Datepicker from '@/components/DatePicker/index.vue'
  import { ElTooltip } from 'element-plus'
  const limit = 20
  export default defineComponent({
    name: 'UplFileListing',
    components: {
      UplInput: Input,
      DataTable,
      Btn: Button,
      EmptyState,
      LongText,
      ElTooltip,
      Field,
      Loader,
      Datepicker
    },
    setup() {
      const term = ref('')
      const picked = ref([])
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const router = useRouter()
      const isLoading = ref(false)
      const loading = ref(false)
      const filters = ref({ orderBy: 'createdAt,DESC', name: '' })
      const handleClick = (data: Document) => {
        router.push({
          name: 'companyDocuments',
          params: {
            id: store.getters['organizations/contextCompany'].id,
            documentId: data.id
          }
        })
      }

      const documents = ref<any[]>([])
      const pagination = ref({
        total: 0,
        limit: limit,
        currentPage: 1,
        lastPage: 1
      })
      const onPageChange = (page: number) => {
        loading.value = true

        getDocuments(page)
      }

      const resetFilters = () => {
        term.value = ''
        picked.value = []
        filters.value = {
          orderBy: 'createdAt,DESC',
          name: ''
        }
        getDocuments(1)
      }

      const getDocuments = (page: number) => {
        store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: {
            offset: (page - 1) * limit,
            limit: limit,
            ...filters.value
          },
          onSuccess: (result: any) => {
            documents.value = [...result.data]
            pagination.value = {
              total: result.metadata.count,
              limit: limit,
              currentPage: result.metadata.currentPage,
              lastPage: result.metadata.lastPage
            }

            loading.value = false

            isLoading.value = false
          },
          onError: () => {
            loading.value = false

            isLoading.value = false
          }
        })
      }

      onMounted(() => {
        isLoading.value = true
        getDocuments(1)
      })

      onBeforeUnmount(() => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', 1)
      })

      const formattedFiles = computed(() =>
        [...documents.value]
          .sort(
            (a: Document, b: Document) =>
              moment.utc(b.createdAt).unix() - moment.utc(a.createdAt).unix()
          )
          .map((x: Document) => ({
            ...x,
            createdAt: moment.utc(x.createdAt).format('L')
          }))
      )

      const columns = computed(() => [
        {
          label: 'Date',
          field: 'createdAt',
          width: '5%',
          sortable: true
        },
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: 'Status',
          field: 'status',
          width: '10%',
          custom: true
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      watch(
        () => term.value,
        (val) => {
          filters.value = { ...filters.value, name: val }

          getDocuments(1)
        }
      )
      watch(picked, (newDateRange) => {
        let dateFilter: any = {
          endDate: moment.utc().format('YYYY-MM-DD'),
          startDate: '1970-01-01'
        }
        if (newDateRange && newDateRange.length > 0) {
          dateFilter = {
            startDate:
              newDateRange && newDateRange[0]
                ? moment.utc(newDateRange[0]).startOf('day').toISOString()
                : '1970-01-01',
            endDate:
              newDateRange && newDateRange[1]
                ? moment.utc(newDateRange[1]).endOf('day').toISOString()
                : moment().format('YYYY-MM-DD')
          }
        }
        filters.value = { ...filters.value, ...dateFilter }
        getDocuments(1)
      })

      return {
        term,
        picked,
        columns,
        isLoading,
        loading,
        handleClick,
        formattedFiles,
        pagination,
        resetFilters,
        onPageChange
      }
    }
  })
</script>
