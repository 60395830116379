<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >
    <Field
      v-slot="{ field }"
      v-model="exercisePrice"
      :name="fieldName"
      :placeholder="placeholder"
    >
      <Multiselect
        v-model="exercisePrice"
        v-bind="field"
        :name="fieldName"
        :value="exercisePrice"
        class="w-full min-h-full"
        :show-labels="true"
        mode="single"
        group-label="name"
        :options="prices"
        :placeholder="$t(`global.placeholder.exercisePrice`)"
        :groups="false"
        label="name"
        :disabled="disabled"
        open-direction="bottom"
        @change="$emit('onUpdated', fieldName, exercisePrice)"
      >
        <template #option="{ option }">
          <span v-if="option?.id != 0" class="w-full text-sm font-normal"
            >{{ option.name }}
          </span>
        </template>

        <template #noResults>
          <span>{{ $t('global.placeholder.noResult') }}</span>
        </template>
      </Multiselect>
    </Field>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'

  export default defineComponent({
    name: 'GenericExercisePrice',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },

      hasLabel: {
        default: true,
        type: Boolean
      },
      options: { type: Object as () => number[] },
      value: {
        default: '',
        type: [String, Array]
      },

      tableId: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const exercisePrice = ref(props.value.toString())
      const prices = computed(() =>
        props.options?.map((x) => ({
          id: x.toString(),
          name: `${x} €`,
          value: x
        }))
      )
      const { data } = toRefs(props)

      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )
      watch(
        () => props.options,
        (val) => {
          if (val?.length === 1) {
            const [firstPrice] = val

            exercisePrice.value = firstPrice.toString()
          } else if (!val?.length) exercisePrice.value = ''

          emit('onUpdated', fieldName.value, exercisePrice.value)
        }
      )

      onMounted(() => {
        if (props.options?.length === 1) {
          const [firstPrice] = props.options

          exercisePrice.value = firstPrice.toString()
        } else if (!props.options?.length) exercisePrice.value = ''

        emit('onUpdated', fieldName.value, exercisePrice.value)
      })

      return { prices, exercisePrice, fieldName }
    }
  })
</script>
