<template>
  <div class="">
    <Menu as="div" class="inline-block text-left" :dir="direction">
      <div>
        <MenuButton
          :class="`inline-flex w-full justify-center items-center rounded-md ${callerClass}`"
        >
          <slot>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </slot>
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="
            absolute
            right-30
            mt-2
            px-2
            divide-y divide-secondary4
            rounded-md
            bg-white
            shadow-lg
            ring-1 ring-black ring-opacity-5
            focus:outline-none
            z-10
          "
          style="min-width: 250px"
        >
          <div
            :class="`py-1 `"
            v-for="[key, list] in groupedActions"
            :key="key"
          >
            <MenuItem
              v-slot="{ active }"
              v-for="(action, index) in list"
              :key="index"
            >
              <button
                @click="($event) => handleAction(action.key)"
                :class="[
                  active
                    ? `bg-primary1 ${action.textClass ?? 'text-primary4'}`
                    : `${action.textClass ?? 'text-secondary8'}`,
                  `group flex ${
                    direction == 'rtl' ? 'flex-row-reverse' : ''
                  } w-full justify-start items-center rounded-sm border-none px-2 py-2 text-md my-1 font-medium text-start`
                ]"
              >
                <i
                  :class="`bi bi-${action.icon} ${
                    action.iconClass ?? 'text-primary3'
                  }  text-lg ml-1 mr-2`"
                ></i>
                {{ action.name }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script lang="ts">
  import { ActionListItem, MAINACTIONS } from '@/types/config'
  import { groupItemBy } from '@/utils'
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { computed, defineComponent, onMounted } from 'vue'
  export default defineComponent({
    components: {
      Menu,
      MenuButton,
      MenuItems,
      MenuItem
    },
    props: {
      direction: {
        type: String,
        default: 'rtl'
      },
      callerClass: {
        type: String,
        default:
          'bg-white bg-opacity-20 px-2 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
      },
      actions: {
        type: Object as () => Array<ActionListItem>,
        required: false,
        default: null
      }
    },
    emits: ['onClick'],
    setup(props, { emit }) {
      const groupedActions = computed(() =>
        groupItemBy(props.actions, (i) => i.section || 1)
      )
      const handleAction = (action: MAINACTIONS) => {
        emit('onClick', action)
      }

      return { groupedActions, handleAction }
    }
  })
</script>
