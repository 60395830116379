
  import { defineComponent, onMounted, ref, watch } from 'vue'
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle
  } from '@headlessui/vue'

  import { Button } from '@up.law/uplaw-ui'
  export default defineComponent({
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogPanel,
      Button,
      DialogTitle
    },
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      disableCancel: {
        type: Boolean,
        default: false
      },
      disableValidate: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: 'w-3/5'
      },
      title: {
        type: String,
        default: 'Modal'
      }
    },
    emits: ['cancel', 'submit'],
    setup(props, { emit }) {
      const isOpen = ref(false)

      const closeModal = (value = true) => {
        isOpen.value = value
      }

      const openModal = () => {
        isOpen.value = true
      }

      const handleCancel = () => {
        closeModal(false)
        emit('cancel')
      }

      const handleValidate = () => {
        emit('submit')
      }

      watch(
        () => props.isVisible,
        (value) => {
          isOpen.value = value
        }
      )

      onMounted(() => {
        isOpen.value = props.isVisible
      })
      return { isOpen, openModal, closeModal, handleCancel, handleValidate }
    }
  })
