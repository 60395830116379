
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import { Operation } from '@/services/api/models'
  import { OperationFormData } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_OPERATION,
    EDIT_OPERATION
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { operationSchema } from '@/utils/schema/operation'
  import ColorPicker from '@/components/ColorPicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'UplOperation',
    components: {
      Form,
      ElPopconfirm,
      Field,
      Input,
      FormActions,
      Button,
      ColorPicker
    },
    emits: [],
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()

      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-operations'))
      )
      const colorCode = ref('#409EFF')
      const newOperation = ref<OperationFormData>({
        name: '',
        colorCode: '',
        description: null
      })

      const resetForm = () => {
        newOperation.value = {
          name: '',
          colorCode: '',
          description: null
        }
      }

      const modalForm = computed(() => store.getters['organizations/form'])

      const operation = computed<Operation | null>(
        () => store.getters['organizations/operation']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = ref(operationSchema)

      const initForm = () => {
        store.commit('organizations/SET_OPERATION', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleForm = (values: OperationFormData) => {
        let edition = false
        let action = CREATE_OPERATION
        let formData = {
          companyId: currentCompany.value.id,
          data: { ...values },
          operationId: ''
        }
        edition = !!operation.value?.id
        formData['operationId'] = operation.value?.id || ''
        action = operation.value?.id ? EDIT_OPERATION : CREATE_OPERATION

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(`operationBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('operationBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('operationBook.form.title'),
              message: t(
                `operationBook.apiResponse.${
                  edition ? 'edit' : 'create'
                }Success`
              )
            })
          }
        })
      }

      const deleteGroup = () => {
        store.dispatch(`organizations/REMOVE_OPERATION`, {
          companyId: currentCompany.value.id,
          id: operation.value?.id || '',
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('operationBook.form.title'),
              message: t(`operationBook.apiResponse.deleteSuccess`)
            })
          },
          onError: (error = null) => {
            const message = error || t(`operationBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('operationBook.form.title'),
              message
            })
          }
        })
      }
      const handleReset = () => {
        initForm()
      }

      const toggleModal = (state: boolean) =>
        store.dispatch('ui/toggleModal', { display: state })

      watch(operation, (newValue) => {
        if (newValue) {
          newOperation.value = {
            name: newValue.name,
            colorCode: newValue.colorCode,
            description: newValue.description || null
          }
        }
      })

      watch(modalForm, (newValues) => {
        if (newValues.initCurrentForm) resetForm()
      })

      return {
        newOperation,
        canDo,
        operation,
        schema,
        colorCode,
        toggleModal,
        handleForm,
        deleteGroup,
        handleReset
      }
    }
  })
