
  import { CompanyCommittee } from '@/services/api/models'
  import { CompanyModel } from '@/services/api/models1'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-committees'))
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentCommittee = computed<CompanyCommittee>(
        () => store.getters['organizations/currentCommittee']
      )

      const getOrgans = () => {
        store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: currentCompany.value.id
        })
      }
      const committees = computed<CompanyCommittee[]>(
        () => store.getters['organizations/committees']
      )
      const listedOrgans = ref(committees.value)

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (committeeId: string) => {
        store.dispatch('organizations/GET_COMPANY_COMMITTEE', {
          companyId: currentCompany.value.id,
          committeeId
        })
      }

      onBeforeMount(() => {
        getOrgans()
      })

      watch(search, (text: string) => {
        listedOrgans.value = committees.value.filter((x) =>
          x.denomination.toLowerCase().includes(text.toLowerCase())
        )
      })

      watch(committees, (list) => {
        listedOrgans.value = list
      })
      return {
        canDo,
        listedOrgans,
        search,
        currentCommittee,
        initForm,
        handleSelect
      }
    }
  })
