<template>
  <TransitionRoot
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="duration-200 ease-in"
    leave-from="opacity-100"
    leave-to="opacity-0"
    appear
    :show="isOpen"
  >
    <Dialog
      as="div"
      @close.prevent.stop="closeModal(false)"
      class="relative z-50"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-blackp bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto backdrop-blur">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="`${width} modal-height transform max-w-md2 overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl`"
            >
              <DialogTitle
                as="h2"
                class="text-xl font-medium leading-9 text-gray-900 px-5 pt-3"
              >
                <div class="flex justify-end py-3">
                  <div
                    @click.prevent.stop="closeModal(false)"
                    class="
                      bg-primary4
                      w-10
                      flex
                      rounded
                      justify-center
                      items-center
                    "
                  >
                    <i class="bi bi-x text-2xl text-white"></i>
                  </div>
                </div>
                <div class="py-3">
                  <Field v-slot="{ field }" name="search" v-model="search">
                    <Input
                      v-bind="field"
                      :placeholder="$t('global.placeholder.globalSearch')"
                    >
                      <template #right>
                        <i
                          class="bi bi-x"
                          @click.prevent.stop="clearSearch"
                        ></i> </template
                    ></Input>
                  </Field>
                </div>
              </DialogTitle>
              <div class="px-6 flex" v-show="displayResults">
                <div
                  v-for="tab in tabs"
                  :key="tab.id"
                  @click.prevent.stop="selectTab(tab.id)"
                  :class="`w-36 relative cursor-pointer h-28 rounded-lg flex flex-col justify-start mr-8 ${
                    selectedTab == tab.id
                      ? 'bg-primary6 border-b-0 border border-secondary6'
                      : 'border-dashed border border-secondary7'
                  } `"
                >
                  <span
                    class="
                      absolute
                      bg-primary6
                      rounded-2xl
                      px-3
                      py-1
                      bg-opacity-55
                      text-white
                      font-semibold
                      border border-white
                      -top-4
                      -right-3
                      flex
                      justify-center
                      items-center
                    "
                  >
                    <i v-if="loading[tab.key]" class="spinner rotate my-1"></i>
                    <span v-else> {{ count[tab.key] }}</span>
                  </span>
                  <div
                    class="
                      bg-white
                      rounded-md
                      flex flex-col
                      items-center
                      px-4
                      py-4
                    "
                    style="height: 95%"
                  >
                    <i :class="`bi bi-${tab.icon} text-xl text-secondary8`"></i>
                    <h4 class="text-md text-secondary8 font-medium py-4">
                      {{ tab.name }}
                    </h4>
                  </div>
                </div>
              </div>
              <div
                class="px-6 py-6 overflow-scroll"
                style="height: 55vh"
                v-show="displayResults"
              >
                <DocumentResult
                  v-show="selectedTab == 0"
                  :search="search"
                  @dataCount="(field, data) => (count[field] = data)"
                  @loading="(field, data) => (loading[field] = data)"
                  @close="closeModal(false)"
                />
                <EventsResult
                  v-show="selectedTab == 1"
                  :search="search"
                  @dataCount="(field, data) => (count[field] = data)"
                  @loading="(field, data) => (loading[field] = data)"
                  @close="closeModal(false)"
                />

                <ContactsResult
                  v-show="selectedTab == 2"
                  :search="search"
                  @dataCount="(field, data) => (count[field] = data)"
                  @loading="(field, data) => (loading[field] = data)"
                  @close="closeModal(false)"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle
  } from '@headlessui/vue'
  import { Input } from '@up.law/uplaw-ui'
  import DocumentResult from './ModalDocumentSearch.vue'
  import EventsResult from './ModalEventResult.vue'
  import ContactsResult from './ModalContactResult.vue'
  import { useI18n } from 'vue-i18n'
  import { Field } from 'vee-validate'

  export default defineComponent({
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogPanel,
      Input,
      EventsResult,
      ContactsResult,
      Field,
      DocumentResult,
      DialogTitle
    },
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      disableCancel: {
        type: Boolean,
        default: false
      },
      disableValidate: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: 'w-3/5'
      },
      title: {
        type: String,
        default: 'Modal'
      }
    },
    emits: ['cancel', 'submit'],
    setup(props, { emit }) {
      const isOpen = ref(false)
      const { t } = useI18n()
      const closeModal = (value = true) => {
        isOpen.value = value
        displayResults.value = true
        if (!value) emit('cancel')
      }
      const search = ref('')
      const count = ref({ documents: 0, events: 0, contacts: 0 })
      const loading = ref({ documents: false, events: false, contacts: false })
      const countDocuments = ref(0)
      const countEvents = ref(0)
      const countContacts = ref(0)
      const tabs = computed(() => [
        {
          id: 0,
          key: 'documents',
          name: t('documents.context'),
          icon: 'files'
        },
        {
          id: 1,
          key: 'events',
          icon: 'lightning',
          name: t('events.context')
        },
        {
          id: 2,
          key: 'contacts',
          icon: 'people',
          name: t('contactBook.title')
        }
      ])

      const selectTab = (index: number) => {
        selectedTab.value = index
      }
      const selectedTab = ref(0)
      const displayResults = ref(false)

      const openModal = () => {
        isOpen.value = true
      }

      const handleCancel = () => {
        closeModal(false)
        emit('cancel')
      }

      const clearSearch = () => {
        search.value = ''
        displayResults.value = false
      }

      const handleValidate = () => {
        emit('submit')
      }

      watch(
        () => props.isVisible,
        (value) => {
          isOpen.value = value
        }
      )

      watch(
        () => search.value,
        (value) => {
          displayResults.value =
            value.length > 0 ||
            count.value.documents > 0 ||
            count.value.contacts > 0 ||
            count.value.events > 0
        }
      )

      onBeforeUnmount(() => {
        displayResults.value = false
      })

      onMounted(() => {
        isOpen.value = props.isVisible
        displayResults.value = false
      })

      return {
        isOpen,
        search,
        tabs,
        clearSearch,
        count,
        loading,
        countContacts,
        countDocuments,
        countEvents,
        selectedTab,
        displayResults,
        openModal,
        selectTab,
        closeModal,
        handleCancel,
        handleValidate
      }
    }
  })
</script>
<style lang="scss">
  .backdrop-blur {
    backdrop-filter: blur(5px) !important;
  }
  // .modal-height {
  //   -webkit-transition: height 1s;
  //   -moz-transition: height 1s;
  //   -ms-transition: height 1s;
  //   -o-transition: height 1s;
  //   transition: height 1s;
  // }

  .rotate {
    animation: rotate 1.5s linear infinite;
  }
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  /* SPINNER JUST FOR DEMO */
  .spinner {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset -1px 0 0 1px white;
  }
</style>
