<template>
  <div>
    <div class="w-full flex-wrap flex event-bg">
      <div class="p-3 border-r border-secondary6 w-1/4">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.fieldsLabels.stock') }}
        </h1>
        <p v-if="issuance?.stockAlias" class="text-blackp text-sm text-left">
          <Tag
            :value="issuance.stockAlias.id"
            :text="issuance.stockAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
          />
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>

      <div class="p-3 border-r border-secondary6 w-1/4">
        <h1 class="text-left text-secondary7 text-xs font-light">
          {{ $t('events.fieldsLabels.delegation') }}
        </h1>
        <p v-if="issuance?.delegation" class="text-blackp text-sm text-left">
          {{ issuance.delegation.label }}
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
      <div class="p-3 border-secondary6 border-r w-1/4">
        <h1 class="text-left text-secondary7 text-xs font-light">
          {{ $t('events.fieldsLabels.profitSharingPlan') }}
        </h1>
        <p
          v-if="issuance?.profitSharingPlan"
          class="text-blackp text-sm text-left"
        >
          {{ issuance?.profitSharingPlan?.name }}
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
    </div>
    <div :class="`flex overflow-scroll grid grid-cols-6`">
      <div class="p-3 flex items-end col-span-2">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.contact') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.conservationPeriod') }}
        </h1>
      </div>

      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.startAt') }}
        </h1>
      </div>

      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.vestingName') }}
        </h1>
      </div>
    </div>
    <div class="event-bg">
      <div
        v-for="(part, index) in parts"
        :key="part.id"
        :class="`w-full flex-wrap flex ${
          contactsId.includes(part.contact?.id) ? 'bg-active font-bold' : ''
        } grid grid-cols-6 ${index > 0 ? 'border-t border-secondary6' : ''}`"
      >
        <div
          class="
            p-2
            border-r border-secondary6
            col-span-2
            flex
            justify-start
            items-center
          "
        >
          <p v-if="part.contact" class="text-blackp text-sm text-left">
            <router-link
              :to="{
                name: 'company.directory',
                params: {
                  id: $route.params.id,
                  contactId: part.contact.id
                }
              }"
            >
              <ContactBadge
                class="p-0 text-xs"
                :contact="part.contact"
                :details="false"
                type="light"
            /></router-link>
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(part.quantity) }}
          </p>
        </div>

        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ part.conservationPeriod }}
            {{
              $t(
                `events.elements.${part?.conservationPeriodUnit?.toLowerCase()}`,
                part.conservationPeriod
              )
            }}
          </p>
        </div>

        <div
          class="p-3 border-l border-secondary6 flex justify-end items-center"
        >
          <p
            v-if="part.vestingStartDate"
            class="text-blackp text-sm text-right"
          >
            {{ moment.utc(part.vestingStartDate).format('l') }}
          </p>
        </div>
        <div
          class="p-3 border-l border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ part?.vestingCalendar?.name || '-' }}
          </p>
        </div>
      </div>
      <div
        :class="`grid grid-cols-6 ${
          parts?.length > 0 ? 'border-t border-secondary6 ' : ''
        } h-10 flex`"
      >
        <div
          class="
            col-span-2
            border-r border-secondary6
            h-10
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalQuantity) }}
          </p>
        </div>

        <div
          class="h-10 flex items-center justify-end border-l border-secondary6"
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
        <div
          class="h-10 flex items-center justify-end border-l border-secondary6"
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
        <div
          class="h-10 flex items-center justify-end border-l border-secondary6"
        >
          <p class="text-right px-2 font-semibold">-</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRefs, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { isNumber } from 'lodash'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Event } from '@/services/api/models'
  import { EventType } from '@/types/event'

  export default defineComponent({
    name: 'IssuanceCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { nominalValue } = toRefs(props)
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const store = useStore()
      const isLoading = ref(false)
      const issuance = computed<any>(() => props.event?.freeShareIssuance)

      const isOptionIssuance = computed(
        () => props.event?.type?.toString() === EventType.optionIssuance
      )

      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed<any[]>(
        () => props.event?.freeShareIssuance?.freeShareIssuanceParts || []
      )

      const totalQuantity = computed(() => {
        return (parts.value || []).reduce(
          (total: number, p: any) => (total += p.quantity),
          0
        )
      })
      const globalReduction = computed(() => {
        return (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      })

      const globalReleased = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc += parseFloat(line.releasedAmount || 0)),
          0
        )
      )
      const globalEmit = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc +=
              (parseFloat(line.totalPrice) / parseInt(line.quantity || 0) -
                nominalValue.value) *
              parseInt(line.quantity || 0)),
          0
        )
      )

      const globalIssuancePerAction = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc +=
              parseInt(line.totalPrice) / parseInt(line.quantity || 0) -
              nominalValue.value),
          0
        )
      )
      const globalNominalIncrease = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )
      const globalTotal = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) => (acc += parseFloat(line.totalPrice || 0)),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value =
            parseFloat(globalTotal.value?.toString()) / totalQuantity.value
        }
        return value
      })

      const calculateUnitPrice = (row: any) => {
        if (!row.totalPrice || !row.quantity) return 0

        return row.totalPrice / row.quantity
      }

      const emitPerAction = (row: any) => {
        const unitPrice = calculateUnitPrice(row)

        if (!isNumber(unitPrice)) {
          return '-'
        }

        return Math.abs(unitPrice - nominalValue.value)
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseFloat(ePerAction.toString()) * row.quantity
      }

      const augNominal = (row: any) => {
        if (nominalValue.value < 0) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }
      console.log(props.event)

      return {
        currency,
        parts,
        issuance,
        moment,
        globalReduction,
        totalQuantity,
        globalReleased,
        globalUnitPrice,
        globalNominalIncrease,
        globalIssuancePerAction,
        globalEmit,
        globalTotal,
        isLoading,
        isOptionIssuance,
        contactsId,
        formatNumber,
        calculateUnitPrice,
        emitPerAction,
        emitTotal,
        augNominal
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;

    .w-1-7 {
      width: calc(100% / 7);
    }
  }
</style>
