
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field, useForm } from 'vee-validate'
  import {
    CompanyGroup,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { ContactGroupFormData } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_COMPANY_GROUP,
    EDIT_COMPANY_GROUP
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { groupSchema } from '@/utils/schema/group'
  import ColorPicker from '@/components/ColorPicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    name: 'UplContactGroup',
    components: {
      Form,
      ElPopconfirm,
      Field,
      Input,
      FormActions,
      Button,
      ColorPicker,
      Checkbox
    },
    emits: [],
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )
      const colorCode = ref('#409EFF')
      const newGroup = ref<ContactGroupFormData>({
        name: '',
        colorCode: '',
        description: null,
        contactsId: [],
        isEmployeesGroup: false
      })

      const resetForm = () => {
        newGroup.value = {
          name: '',
          colorCode: '',
          description: null,
          contactsId: [],
          isEmployeesGroup: false
        }
      }

      const modalForm = computed(() => store.getters['organizations/form'])

      const group = computed<CompanyGroup>(
        () => store.getters['organizations/group']
      )

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )

      const groupsContacts = computed<any[]>(() =>
        groups.value.flatMap((c) => c.contacts)
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = ref(groupSchema)
      const { errors, setValues, setFieldValue, values, meta } = useForm({
        initialValues: group.value,
        validationSchema: schema.value,
        validateOnMount: false
      })

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_CONTACT_GROUP', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const allContacts = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contacts'])

      const contacts = computed(() => [
        ...allContacts.value,
        ...groupsContacts.value.filter(
          (x) => !allContacts.value.map((c) => c.id).includes(x.id)
        )
      ])
      const handleForm = (values: ContactGroupFormData) => {
        let edition = false
        let action = CREATE_COMPANY_GROUP
        let formData = {
          companyId: currentCompany.value.id,
          data: { ...values, contactsId: newGroup.value.contactsId },

          groupId: ''
        }
        if (group.value && group.value.id) {
          edition = true
          formData['groupId'] = group.value.id
          action = EDIT_COMPANY_GROUP
        }
        console.log(formData)

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(`groupBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('groupBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('groupBook.form.title'),
              message: t(
                `groupBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          }
        })
      }

      const updateContacts = ($event: any) => {
        console.log($event)

        newGroup.value.contactsId = $event
      }

      const deleteGroup = () => {
        store.dispatch(`organizations/DELETE_COMPANY_GROUP`, {
          companyId: currentCompany.value.id,
          groupId: group.value.id,
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('groupBook.form.title'),
              message: t(`groupBook.apiResponse.deleted`)
            })
          },
          onError: (error = null) => {
            const message = error || t(`groupBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('groupBook.form.title'),
              message
            })
          }
        })
      }
      const handleReset = () => {
        initForm()
      }

      const getContacts = async (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }
      const toggleModal = (state: boolean) =>
        store.dispatch('ui/toggleModal', { display: state })

      watch(
        () => group.value,
        (newValue: any) => {
          if (newValue) {
            newGroup.value = {
              contactsId: newValue.contacts.map((x: any) => x.id),
              colorCode: newValue.colorCode || null,
              name: newValue.name,
              description: newValue.description,
              isEmployeesGroup: newValue.isEmployeesGroup
            }
          }

          console.log(groups.value.flatMap((c) => c.contacts))
        }
      )

      watch(modalForm, (newValues: any) => {
        if (newValues.initCurrentForm) resetForm()
      })

      onMounted(() => {
        getContacts()
      })

      return {
        newGroup,
        group,
        canDo,
        schema,
        colorCode,
        contacts,
        updateContacts,
        toggleModal,
        handleForm,
        deleteGroup,
        handleReset,
        setFieldValue
      }
    }
  })
