<template>
  <div id="target" class="h-full p-0 flex justify-between overflow-hidden">
    <DocumentTreeview
      ref="documentTreeview"
      :company-id="$route.params.companyId"
    />
    <div class="extraction__wrapper">
      <div
        v-if="isLoading"
        class="flex w-full min-h-full justify-center items-center"
      >
        <Loader />
      </div>
      <div v-else class="extraction__body">
        <div class="flex justify-between relative">
          <div class="flex flex-col justify-start items-start">
            <h2>{{ $t('events.title') }}</h2>
          </div>
          <div class="text-xl">
            <Button
              class="ml-4 text-sm"
              type="submit"
              variant="primary"
              :label="$t('events.create.title')"
              @click="goToCreate"
            />
          </div>
        </div>
        <div class="info-wrapper mt-2 m-0 pb-2">
          <div class="w-1/2">
            <UplInput
              v-model="searchQuery"
              type="search"
              name="searchInput"
              :disabled="false"
              class="upl-treeview__search__input"
              :placeholder="$t('events.search')"
            />
          </div>

          <div v-if="events.length > 0">
            <event-card
              v-for="event in events"
              :key="event.id"
              :event="event"
              @select-card="selectCard"
            />
          </div>

          <div
            v-else
            class="
              flex
              justify-center
              items-center
              w-full
              mt-4
              transition transition-all
            "
          >
            <EmptyState
              image="no-result.svg"
              class="d-block mx-auto"
              :title="$t('global.noResult')"
              :subtitle="$t('global.noCreatedEvent')"
            />
          </div>
        </div>
      </div>
      <p v-if="loading" class="text-primary">{{ $t('global.loading') }}</p>
      <p v-if="noMore" class="text-primary">{{ $t('global.noMore') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { ref, computed, onMounted, watch } from 'vue'
  import { useStore } from 'vuex'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { useRoute, useRouter } from 'vue-router'
  import { defineComponent } from 'vue'
  import EventCard from '@/components/Event/EventCard.vue'
  import { Event } from '@/services/api/models'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import { Pagination } from '@/store/modules/Organization/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  export default defineComponent({
    name: 'EventList',
    components: {
      DocumentTreeview,
      Button,
      UplInput: Input,
      EventCard,
      Loader,
      EmptyState
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const searchQuery = ref('')
      const loading = ref(false)

      const isLoading = computed(
        () => store.getters['organizations/loadingEvents']
      )

      const events = computed<Event[]>(() =>
        store.getters['organizations/events'].filter((item: Event) => item.type)
      )

      const pagination = computed<Pagination>(
        () => store.getters['organizations/eventsPagination']
      )
      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )

      const selectedCards = ref<string[]>([])

      const getEventCategories = async () => {
        await store.dispatch('toolbox/getEventCategories')
      }

      const load = async () => {
        loading.value = true
        store.commit(
          'organizations/SET_EVENTS_PAGINATION_PAGE',
          pagination.value.currentPage + 1
        )
        await store.dispatch('organizations/LOAD_COMPANY_EVENTS', {
          companyId: route.params.id,
          filter: {},
          offset: events.value.length
        })
        loading.value = false
      }

      const loadEvents = async (companyId: string) => {
        await store.dispatch('organizations/GET_EVENTS', {
          companyId: companyId,
          isDraft: false,
          startDate: '1970-01-01',
          endDate: moment().format('YYYY-MM-DD')
        })
      }

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      const goToCreate = async () => {
        await router.push({
          name: 'createEvent',
          params: { id: currentCompany.value.id }
        })
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        const { check, id } = data
        if (check) {
          selectedCards.value.push(id)
        } else {
          selectedCards.value.splice(
            selectedCards.value.findIndex((item: string) => item === id),
            1
          )
        }
      }

      onMounted(async () => {
        await getEventCategories()
        await loadEvents(route.params.id as string)
      })

      watch(currentCompany, (val) => {
        loadEvents(val.id)
      })

      return {
        searchQuery,
        events,
        loading,
        noMore,
        goToCreate,
        selectCard,
        isLoading,
        load
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/extraction.scss';
</style>
