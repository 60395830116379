
  import { defineComponent, computed, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { Button, Input } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'
  import { useStore } from 'vuex'
  import { Company, Notification, Alert } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { AlertType } from '@/types/config'
  import { Field, useForm } from 'vee-validate'
  import * as yup from 'yup'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { TYPE } from 'vue-toastification'
  import moment from 'moment'
  import DataTable from '@/components/DataTable/index.vue'
  import { Switch } from '@headlessui/vue'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      Button,
      Input,
      Modal,
      Field,
      EmptyState,
      DataTable,
      Switch,
      ElTooltip
    },
    setup() {
      const router = useRouter()
      const { t } = useI18n()
      const store = useStore()
      const newAlertSetting = ref(false)
      const isLoadingNotification = ref(false)
      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )

      const notification = ref<Notification | null>(null)

      const columns = computed(() => [
        {
          label: t(`datatable.column.date`),
          field: 'date',
          custom: true
        },
        {
          label: t(`datatable.column.alertType`),
          field: 'alertType',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          custom: true
        }
      ])

      const alerts = computed<Alert[]>(
        () => store.getters['organizations/alerts']
      )
      const pagination = computed(
        () => store.getters['organizations/notificationsPagination']
      )

      const notifications = computed(
        () => store.getters['organizations/notifications']
      )

      const toggler = ref<any>(
        Object.values(AlertType).reduce(
          (acc, x) =>
            (acc = {
              ...acc,
              [x]: alerts.value.find((a) => a.type === x) !== undefined
            }),
          {}
        )
      )

      const schema = Object.values(AlertType).reduce(
        (acc, type) =>
          (acc = acc.concat(
            yup
              .object()
              .shape({ [type]: yup.number().positive().min(0).max(101) })
          )),
        yup.object({})
      )

      const { resetForm, values, meta } = useForm({
        validationSchema: schema,
        validateOnMount: false
      })
      const setting = ref<any>(
        Object.values(AlertType).reduce(
          (acc, x) => (acc = { ...acc, [x]: 5 }),
          {}
        )
      )
      const gotoNewSettings = () => {
        setting.value = (alerts.value || []).reduce(
          (v: any, x: any) => (v = { ...v, [x.type]: x.daysBefore }),
          {}
        )

        toggler.value = Object.values(AlertType).reduce(
          (acc, x) =>
            (acc = {
              ...acc,
              [x]: alerts.value.find((a) => a.type === x) !== undefined
            }),
          {}
        )
        newAlertSetting.value = true
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_NOTIFICATIONS_PAGE', page)
        getNotifications()
      }

      const initForm = () => {
        Object.values(AlertType).reduce(
          (acc, x) => (acc = { ...acc, [x]: 5 }),
          {}
        )
        resetForm()
      }

      const closeNewAlertModal = () => {
        initForm()
        newAlertSetting.value = false
      }

      const handleAlertSubmit = () => {
        storeAlertSetting()
      }

      const gotoEvent = (row: Notification) => {
        if (!row.seen)
          store.dispatch('organizations/MARK_AS_SEEN', {
            companyId: currentCompany.value?.id,
            notificationId: row.id
          })
        router.push({
          name: 'company.eventDetails',
          params: { eventId: row?.event?.id, id: currentCompany.value?.id }
        })
      }

      const storeAlertSetting = () => {
        const data = Object.keys(values).reduce((acc: any[], key) => {
          if (toggler.value[key]) {
            acc.push({
              daysBefore: parseInt(values[key]),
              type: key
            })
          }
          return acc
        }, [])

        store.dispatch('organizations/CREATE_ALERTS', {
          companyId: currentCompany.value?.id,
          data,
          onSuccess: () => {
            closeNewAlertModal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.alerts.context'),
              message: t(
                `account.alerts.${
                  alerts.value.length > 0 ? 'edit' : 'create'
                }Success`
              )
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.alerts.context'),
              message: t(
                `account.alerts.${
                  alerts.value.length > 0 ? 'edit' : 'create'
                }Error`
              )
            })
          }
        })
      }

      const getNotification = (id: string) => {
        isLoadingNotification.value = true
        store.dispatch('organizations/GET_NOTIFICATION', {
          companyId: currentCompany.value?.id,
          notificationId: id,
          onSuccess: (data: Notification) => {
            notification.value = data
            isLoadingNotification.value = false
          },
          onError: () => {
            notification.value = null
            isLoadingNotification.value = false
          }
        })
      }

      const getAlerts = () => {
        if (!currentCompany.value?.id) return
        store.dispatch('organizations/GET_ALERTS', {
          companyId: currentCompany.value?.id
        })
      }

      const getNotifications = () => {
        store.dispatch('organizations/GET_NOTIFICATIONS', {
          companyId: currentCompany.value?.id
        })
      }

      onMounted(() => {
        getAlerts()
        getNotifications()
      })

      return {
        newAlertSetting,
        setting,
        toggler,
        AlertType,
        onPageChange,
        meta,
        notifications,
        pagination,
        alerts,
        notification,
        currentCompany,
        columns,
        moment,
        getNotification,
        gotoEvent,
        gotoNewSettings,
        closeNewAlertModal,
        handleAlertSubmit
      }
    }
  })
