
  import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue'
  import CheckboxGeneric from '@/components/Forms/Event/Generic/CheckboxGeneric.vue'
  import ContactGeneric from '@/components/Forms/Event/Generic/ContactGeneric.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import SectionGeneric from '@/components/Forms/Event/Generic/SectionGeneric.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownGeneric from '@/components/Forms/Event/Generic/DropdownGeneric.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  import DropdownFunctions from '@/components/Forms/DropDowns/DropdownFunctions.vue'
  import DropdownCommittee from '@/components/Forms/DropDowns/DropdownCommittee.vue'
  import EventFormFooter from '@/components/Forms/Event/EventFormFooter.vue'
  import DurationGeneric from '@/components/Forms/Event/Generic/DurationGeneric.vue'
  import DropdownCeilings from '@/components/Forms/DropDowns/DropdownCeilings.vue'
  import DropdownDelegations from '@/components/Forms/DropDowns/DropdownDelegations.vue'
  import StockQuantity from '@/components/Forms/Event/Generic/StockQuantity.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  import LabelGeneric from '@/components/Forms/Event/Generic/LabelGeneric.vue'
  import InputGeneric from '@/components/Forms/Event/Generic/InputGeneric.vue'
  import StockGeneric from '@/components/Forms/Event/Generic/StockGeneric.vue'
  import DropdownProfitSharingPlans from '@/components/Forms/DropDowns/DropdownProfitSharingPlans.vue'
  import DropdownFundRaisingRounds from '@/components/Forms/DropDowns/DropdownFundRaisingRounds.vue'
  import { EventFormPrimitive } from '@/types/event'
  export default defineComponent({
    name: 'NestedComponent',
    components: {
      CheckboxGeneric,
      DropdownActions,
      DropdownContacts,
      DropdownStocks,
      ContactGeneric,
      DropdownGeneric,
      EventFormFooter,
      DateGeneric,
      StockGeneric,
      SectionGeneric,
      DurationGeneric,
      DropdownVesting,
      DropdownCeilings,
      DropdownFunctions,
      DropdownCommittee,
      DropdownDelegations,
      StockQuantity,
      LabelGeneric,
      InputGeneric,
      DropdownProfitSharingPlans,
      DropdownFundRaisingRounds,
      DropdownOptions
    },
    props: {
      needs: {
        type: Object as () => any[]
      },
      primitives: {
        type: Object as () => EventFormPrimitive[]
      },
      value: {
        type: [String, Boolean, Object, Number],
        default: ''
      },
      data: {
        type: Object,
        default: null
      },
      error: {
        type: Object,
        default: null
      }
    },
    emits: ['updateFormData'],
    setup(props) {
      const formData = ref(props.data ?? {})
      const errors = ref(props.error ?? {})

      const getNeeds = (needed: string) => {
        const needs =
          (props.primitives || []).reduce(
            (acc: any, item: EventFormPrimitive) => {
              if (item.needs === needed) {
                acc.push(item)
              }
              return acc
            },
            []
          ) ?? []

        return needs
      }
      onBeforeUnmount(() => {
        formData.value = {}
      })

      return { formData, errors, getNeeds }
    }
  })
