<template>
  <div class="flex flex-col items-start">
    <div class="flex w-full my-3 justify-between">
      <div class="flex w-4/5 lg:w-3/5">
        <div class="w-2/5 md:w-2/5 h-10 mr-2">
          <Field v-slot="{ field }" v-model="dateRange" name="search">
            <UplDatePicker
              :placeholder="$t('global.datePicker.rangePlaceholder')"
              name="dateRange"
              range
              v-bind="field"
              @cleared="dateCleared"
            />
          </Field>
        </div>

        <div class="w-2/5">
          <Field v-slot="{ field }" v-model="selectedStocks" name="stocks">
            <StockDropdown
              :has-label="false"
              :can-add-new="false"
              :model-value="selectedStocks"
              open-direction="bottom"
              mode="multiple"
              :placeholder="$t('global.placeholder.stocks')"
              :elements="[]"
              v-bind="field"
              @onUpdated="updateStockFilter"
            />
          </Field>
        </div>
      </div>
      <div v-if="!isDownloading" class="text-xl">
        <Button
          variant="custom"
          class="
            bg-white
            py-2
            h-10
            rounded-forced
            border
            flex
            justify-center
            items-center
            border-secondary5
            text-center text-lg
          "
          @click="downloadPdf"
          :label="$t('global.actions.associateDownload')"
        >
          <template>
            <i class="bi bi-download" />
          </template>
        </Button>
      </div>
      <el-tooltip
        v-else-if="isDownloading"
        class="box-item"
        effect="dark"
        :content="$t('tooltips.downloading')"
        placement="top"
      >
        <div
          class="
            border
            cursor-not-allowed
            rounded-3
            bg-white
            border-secondary6
            px-3
            flex
            items-center
          "
        >
          <i class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"></i>
        </div>
      </el-tooltip>
    </div>

    <div
      v-if="isLoading"
      class="flex w-full h-full justify-center items-center relative"
    >
      <Loader />
    </div>
    <div v-else class="captable-table mt-2">
      <DataTable
        v-if="captable && captable.lines.length !== 0"
        :rows="captableStocks"
        :columns="columns"
        text-size="text-sm"
        :has-checkbox="false"
        container-class="w-full"
        cell-padding="p-0"
        :pagination="pagination"
        @onPageChange="onPageChange"
        @on-select="onSelected"
      >
        <template #stocks="{ row }">
          <div class="flex">
            <Tag
              v-if="row.id"
              :value="row.name"
              :text="row.name.toUpperCase()"
              :with-initials="false"
              :with-remove="false"
              :color="rgbToHex(row.colorCode)"
            />
            <p v-else class="text-left">-</p>
          </div>
        </template>
        <template #nfd="{ row }">
          <p
            :class="`text-left px-2  ${
              row.quantityNFD < 0 ? 'text-error' : ''
            }`"
          >
            {{ formatNumber(row.quantityNFD) || 0 }}
          </p>
        </template>
        <template #fd="{ row }">
          <p
            :class="`text-left px-2 ${row.quantityFD < 0 ? 'text-error' : ''}`"
          >
            {{ formatNumber(row.quantityFD) || 0 }}
          </p>
        </template>
        <template #funding="{ row }">
          <p
            :class="`text-left px-2 ${
              row.releasedAmount < 0 ? 'text-error' : ''
            }`"
          >
            {{ formatNumber(row.releasedAmount) || 0 }} €
          </p></template
        >
        <template #fd_p="{ row }">
          <p
            :class="`text-left px-2 ${
              row.quantityFD < 0 || captable.allFD < 0 ? 'text-error' : ''
            }`"
          >
            {{ formatNumber((row.quantityFD / captable.allFD) * 100) || 0 }} %
          </p></template
        >
        <template #nfd_p="{ row }"
          ><p
            :class="`text-left ${
              row.quantityNFD < 0 || captable.allNFD < 0 ? 'text-error' : ''
            } px-2`"
          >
            {{ formatNumber((row.quantityNFD / captable.allNFD) * 100) || 0 }} %
          </p>
        </template>
        <template #total>
          <tr>
            <td>
              <p class="text-right font-bold text-primary8 py-3 px-2">Total</p>
            </td>
            <td>
              <p
                :class="`text-left font-bold text-primary8 py-3 px-2 ${
                  captable.totalAmount < 0 ? 'text-error' : ''
                }`"
              >
                {{ formatNumber(captable.totalAmount) }} €
              </p>
            </td>

            <td>
              <p
                :class="`text-left font-bold text-primary8 py-3 px-2 ${
                  captable.totalNFD < 0 ? 'text-error' : ''
                }`"
              >
                {{ formatNumber(captable.totalNFD) }}
              </p>
            </td>
            <td v-if="hasColumn('nfd_p')">
              <p
                :class="`text-left font-bold text-primary8 py-3 px-2 ${
                  totalPercentNFD < 0 ? 'text-error' : ''
                }`"
              >
                {{ formatNumber(totalPercentNFD) }} %
              </p>
            </td>
            <td>
              <p
                :class="`text-left font-bold text-primary8 py-3 px-2 ${
                  captable.totalFD < 0 ? 'text-error' : ''
                }`"
              >
                {{ formatNumber(captable.totalFD) }}
              </p>
            </td>
            <td v-if="hasColumn('fd_p')">
              <p
                :class="`text-left font-bold text-primary8 py-3 px-2 ${
                  totalPercentNFD < 0 ? 'text-error' : ''
                }`"
              >
                {{ formatNumber(totalPercentFD) || 0 }} %
              </p>
            </td>
          </tr>
        </template>
      </DataTable>
      <div v-else class="flex justify-center items-center w-full mt-4">
        <EmptyState
          class="d-block mx-auto datatable"
          :title="$t('global.noResult')"
          :subtitle="$t('global.selectFilters')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import { formatNumericalValue, rgbToHex } from '@/utils/global'
  import { ContactGroup } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import StockDropdown from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Field } from 'vee-validate'
  import moment from 'moment'
  import { Captable, CaptableLine, STOCKTYPE } from '@/types'
  import { formatNumber } from '@/utils'
  import { Tag, Button } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  import Loader from '@/components/Loader/index.vue'
  import { ElTooltip } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  export default defineComponent({
    name: 'CompanyCaptable',
    components: {
      DataTable,
      Button,
      ElTooltip,
      StockDropdown,
      EmptyState,
      UplDatePicker,
      Field,
      Loader,
      Tag
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const search = ref('')
      const selectedContact = ref('')
      const selectedGroup = ref('')
      const selectedStock = ref('')
      const dateRange = ref([])
      const selectedStocks = ref([])
      const filter = ref<any>({})
      const contacts = computed(() => store.getters['organizations/contacts'])
      const contactId = ref(route.params.contactId)
      const optionAlias = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const isDownloading = ref(false)

      const isLoading = ref(false)
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const currentContact = computed(
        () => store.getters['organizations/contactDetails']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )
      const captable = ref<Captable>(store.getters['organizations/captable'])

      const totalPercentFD = computed(() =>
        captable.value?.lines.reduce((value, line: CaptableLine) => {
          value +=
            line.stocks.reduce((acc, stock) => (acc += stock.quantityFD), 0) /
            captable.value.allFD
          return value * 100
        }, 0)
      )

      const totalPercentNFD = computed(() =>
        captable.value?.lines.reduce((value, line: CaptableLine) => {
          value +=
            line.stocks.reduce((acc, stock) => (acc += stock.quantityNFD), 0) /
            captable.value.allNFD
          return value * 100
        }, 0)
      )

      const captableStocks = computed(() =>
        captable.value?.lines.reduce((stocks: any[], line: CaptableLine) => {
          stocks.push(...line.stocks)
          return stocks
        }, [])
      )

      const groups = computed<ContactGroup[]>(
        () => store.getters['toolbox/contactGroups']
      )

      const acl = useAcl()

      const pagination = computed(
        () => store.getters['organizations/captablePagination']
      )
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', page)
        getCaptable()
      }

      const columns = computed(() =>
        [
          {
            label: t('datatable.column.stocks'),
            field: 'stocks',
            custom: true
          },
          {
            label: t('datatable.column.funding'),
            field: 'funding',
            custom: true
          },
          { label: t('datatable.column.nfd'), field: 'nfd', custom: true },

          !acl.can.any(['employee'])
            ? {
                label: t('datatable.column.nfd_p'),
                field: 'nfd_p',
                custom: true
              }
            : null,
          { label: t('datatable.column.fd'), field: 'fd', custom: true },
          !acl.can.any(['employee'])
            ? { label: t('datatable.column.fd_p'), field: 'fd_p', custom: true }
            : null
        ].filter((x) => x != null)
      )

      const hasColumn = (columnName: string) => {
        return columns.value.map((x: any) => x.field).includes(columnName)
      }
      const dateCleared = () => {
        const dateFilter = {
          startDate: null,
          endDate: null
        }
        filter.value = { ...filter.value, ...dateFilter }
      }

      const downloadPdf = () => {
        isDownloading.value = true
        const companyId = route.params.id

        store.dispatch('organizations/EXPORT_ASSOCIATE_RECORD', {
          companyId,
          filter: { contactsId: [route.params.contactId] },
          name: `${t(`global.associateRecord`)}-${
            currentContact.value?.name
          }-${moment().format('L')}`,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const onSelected = (value: any) => {
        console.log(value)
      }

      const updateStockFilter = ({ value }: any) => {
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        value.forEach((v: string) => {
          const [al] = v.split('@_')
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === al)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(al)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })
        selectedStocks.value = value
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)

        getCaptable()
      }

      const updateStock = (id: string) => {
        if (id === '') delete filter.value.optionAliasesId
        else filter.value.optionAliasesId = id
        selectedStock.value = id
      }
      const updateGroup = (group: any) => {
        if (group.value === '') delete filter.value.groupId
        else filter.value.groupId = group.value
        selectedGroup.value = group.value
      }
      const updateContact = (contactId: string) => {
        if (contactId === '') delete filter.value.contactId
        else filter.value.contactId = contactId
        selectedContact.value = contactId
      }

      const getCaptable = async (contactId = route.params.contactId) => {
        // isLoading.value = true
        await store.dispatch('organizations/GET_CAPTABLE', {
          companyId: route.params.id,
          filter: { ...filter.value, contactId: [contactId] },
          onSuccess: (data: Captable) => {
            isLoading.value = false
            captable.value = data
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
      })

      onBeforeMount(async () => {
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)
        await store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: route.params.id
        })
        getCaptable()
      })

      watch(
        () => route.params.contactId,
        (newContactId) => {
          store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)

          if (!newContactId) {
            store.commit('organizations/SET_CURRENT_CONTACT', null)
          } else {
            contactId.value = newContactId
          }
        }
      )

      watch(dateRange, (newDateRange) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)

        const dateFilter = {
          startDate: newDateRange
            ? moment(newDateRange[0]).startOf('day').toISOString()
            : null,
          endDate: newDateRange
            ? moment(newDateRange[1]).endOf('day').toISOString()
            : null
        }

        filter.value = { ...filter.value, ...dateFilter }

        getCaptable()
      })

      onMounted(() => {
        isLoading.value = true
      })

      return {
        search,
        dateRange,
        captable,
        company,
        groups,
        isDownloading,
        selectedContact,
        contacts,
        selectedStocks,
        columns,
        pagination,
        isLoading,
        captableStocks,
        totalPercentFD,
        totalPercentNFD,
        onSelected,
        updateStock,
        updateContact,
        updateGroup,
        formatNumericalValue,
        optionAlias,
        hasColumn,
        dateCleared,
        downloadPdf,
        updateStockFilter,
        onPageChange,
        formatNumber,
        rgbToHex
      }
    }
  })
</script>

<style lang="scss">
  .rounded-forced {
    border-radius: 3px !important;
  }
  .captable-table {
    @apply flex w-full;
    .contact-name {
      color: var(--color-primary) !important;
    }
    .nfd {
      background-color: var(--color-primary-lower) !important;
    }
  }
</style>
