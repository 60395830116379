import { OrganizationState } from './models'
import { MutationTree } from 'vuex'
import {
  FreeSharesMutations,
  SET_FREE_SHARES_VESTINGS,
  SET_FREE_SHARES_ACQUISITION_CALENDARS
} from './models'

export const freeSharesMutations: MutationTree<OrganizationState> &
  FreeSharesMutations = {
  [SET_FREE_SHARES_VESTINGS](state, result) {
    state.freeSharesVestings = [...(result ?? [])]
  },
  [SET_FREE_SHARES_ACQUISITION_CALENDARS](state, result) {
    state.freeSharesAcquisitionCalendars = [...(result.data ?? [])]
    state.freeSharesAcquisitionCalendarsPagination.currentPage =
      result.metadata.currentPage
    state.freeSharesAcquisitionCalendarsPagination.lastPage =
      result.metadata.lastPage
    state.freeSharesAcquisitionCalendarsPagination.total = result.metadata.count
  }
}
