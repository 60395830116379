
  import { computed, defineComponent, inject, onMounted, ref } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field, Form } from 'vee-validate'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '@/components/Loader/index.vue'
  import { User } from '@/services/api/models'
  import * as yup from 'yup'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { GlobalConfig } from '@/types'
  import conf from '@/config'
  import { getInstance } from '@/plugins/auth'

  export default defineComponent({
    components: { Input, Field, Form, Button, Loader },
    setup() {
      const config = conf as GlobalConfig
      const Auth: any = inject('Auth')
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const form = ref({ password: '', confirmation: '' })
      const user = ref<User | null>(null)
      const token = computed(() => route.query?.token)
      const isLoading = ref(true)
      const hasError = ref(false)
      const email = computed(() =>
        Buffer.from(token.value?.toString() || '', 'base64').toString('binary')
      )

      const schema = yup.object({
        password: yup.string().min(8).required('passwordRequired'),
        confirmation: yup
          .string()
          .oneOf([yup.ref('password')], 'passwordMissmatch')
      })
      const gotoLogin = () => {
        Auth.logout({ returnTo: config.auth0Options.redirectUri })
        router.replace({ name: 'auth.connect' })
      }

      const handleForm = () => {
        isLoading.value = true
        store.dispatch('auth/DEFINE_PASSWORD', {
          userId: user.value?.id,
          data: { ...form.value, token: token.value },
          onSuccess: () => {
            isLoading.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              message: t(
                `account.accountInformations.apiResponse.successPasswordEdition`
              ),
              context: t('account.accountInformations.newPassword')
            })
            gotoLogin()
          },
          onError: (err: string) => {
            isLoading.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              message:
                err ||
                t(
                  `account.accountInformations.apiResponse.errorPasswordEdition`
                ),
              context: t('account.accountInformations.newPassword')
            })
            // hasError.value = true
          }
        })
      }

      onMounted(async () => {
        const client = getInstance()
        if (await client.isAuthenticated()) {
          Auth.logoutWithoutRedirect(
            `${config.auth0Options.redirectUri}${route.fullPath}`
          )
        }

        store.dispatch('auth/VERIFY_USER_BY_TOKEN', {
          token: token.value,
          onSuccess: (response: any) => {
            hasError.value = !response.data
            user.value = response.data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
            hasError.value = true
          }
        })
      })

      return {
        email,
        hasError,
        isLoading,
        schema,
        form,
        user,
        handleForm,
        gotoLogin
      }
    }
  })
