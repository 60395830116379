import Axios from '@/services/api/axios'
import { AxiosResponse } from 'axios'
import { toolbox } from './endpoints'
import { DocumentCategory, EventCategory } from './models'

import { Role } from './models/user'

const getDocCategories = async (): Promise<
  AxiosResponse<DocumentCategory[]>
> => {
  const response = await Axios.get<DocumentCategory[]>(
    toolbox.documentCategories(),
    { params: { offset: 0, limit: 20 } }
  )

  return response
}

const getStockCategories = async (): Promise<AxiosResponse> => {
  const response = await Axios.get(toolbox.stockCategories(), {
    params: { offset: 0, limit: 50 }
  })
  return response.data
}

const getOptionCategories = async (): Promise<AxiosResponse> => {
  const response = await Axios.get(toolbox.optionCategories(), {
    params: { offset: 0, limit: 20 }
  })
  return response.data
}

const getEventCategories = async (): Promise<EventCategory[]> => {
  // return EventFormExample
  return []
}

const getOrganCategories = async (): Promise<AxiosResponse> => {
  const response = await Axios.get(toolbox.organCategories(), {
    params: { offset: 0, limit: 20 }
  })
  return response.data
}

const getContactGroups = async (): Promise<AxiosResponse> => {
  const response = await Axios.get(toolbox.contactGroups(), {
    params: { offset: 0, limit: 20 }
  })
  return response.data
}

const getLegalStatus = async (): Promise<AxiosResponse> => {
  const response = await Axios.get(toolbox.legalStatus(), {
    params: { offset: 0, limit: 20 }
  })
  return response.data
}

const getRoles = async (): Promise<Role[]> => {
  const { data } = await Axios.get(toolbox.roles())
  return data
}

export {
  getDocCategories,
  getStockCategories,
  getOptionCategories,
  getEventCategories,
  getOrganCategories,
  getContactGroups,
  getLegalStatus,
  getRoles
}
