
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { Input } from '@up.law/uplaw-ui'
  import { SampleStockTypeFormData, STOCKTYPESALIAS } from '@/types'
  import { sampleStockSchema } from '@/utils'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import { StockAlias } from '@/services/api/models'
  import { useAcl } from 'vue-simple-acl/src'
  export default defineComponent({
    components: { Form, Field, Multiselect, Input, FormActions },
    props: {
      initForm: {
        type: Boolean,
        required: false,
        default: false
      },

      values: {
        type: Object as () => StockAlias,
        required: false,
        default: () => ({})
      }
    },
    emits: ['saveChanges', 'applyChanges'],
    setup(props, { emit }) {
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )
      const store = useStore()
      const initialDirty = ref({
        stockCategoryId: true,
        alias: true,
        inValuation: false,
        inNFD: false,
        inFD: false,
        inESOP: false
      })
      const newStock = ref<SampleStockTypeFormData>({
        stockCategoryId: props.values?.stockCategory?.id || '',
        alias: props.values?.alias || '',
        inValuation: props.values?.inValuation || false,
        inNFD: props.values?.inNFD || false,
        inFD: props.values?.inNFD || false,
        inESOP: props.values?.inESOP || false
      })

      const stockCategories = computed(
        () => store.getters['toolbox/stockCategories']
      )

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      watch(mustInitForm, (val) => {
        if (val) resetForm()
      })

      watch(props, (newProps) => {
        newStock.value.stockCategoryId = newProps.values?.stockCategory?.id
        newStock.value.alias = newProps.values?.alias
        newStock.value.inValuation = newProps.values?.inValuation || false
        newStock.value.inESOP = newProps.values?.inESOP || false
        newStock.value.inNFD = newProps.values?.inNFD || false
        newStock.value.inFD = newProps.values?.inFD || false
      })
      const schema = sampleStockSchema
      const handleForm = (formValues: SampleStockTypeFormData) => {
        emit(
          'saveChanges',
          formValues,
          STOCKTYPESALIAS.STOCK,
          props.values && props.values.id
        )
      }

      const categoryChanged = (id: string) => {
        newStock.value.stockCategoryId = id
        emit('applyChanges', newStock.value)
      }

      const resetForm = () => {
        newStock.value = {
          stockCategoryId: '',
          alias: '',
          inValuation: false,
          inNFD: false,
          inFD: false,
          inESOP: false
        }
      }

      onMounted(() => {
        resetForm()
      })

      watch(props, (newProps) => {
        if (newProps.initForm) {
          resetForm()
        } else {
          newStock.value.stockCategoryId =
            newProps.values?.stockCategory?.id || ''
          newStock.value.alias = newProps.values?.alias || ''
          newStock.value.inNFD = newProps.values?.inNFD || false
          newStock.value.inValuation = newProps.values?.inValuation || false
          newStock.value.inFD = newProps.values?.inFD || false
          newStock.value.inESOP = newProps.values?.inESOP || false
        }
      })
      return {
        schema,
        initialDirty,
        newStock,
        stockCategories,
        canDo,
        handleForm,
        categoryChanged
      }
    }
  })
