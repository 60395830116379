// CONTACT CONSTS
export const ContactType = Object.freeze({
  NATURAL: 'NATURAL',
  LEGAL: 'LEGAL',
  FUND: 'FUND'
})

export enum ORDER_BY {
  ASC = 0,
  DESC = 1
}

export const ContactGender = Object.freeze({
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER'
})

export const ContactSituation = Object.freeze({
  SINGLE: 'SINGLE',
  PACSED: 'PACSED',
  MARRIED: 'MARRIED',
  DIVORCED: 'DIVORCED',
  WIDOWER: 'WIDOWER',
  OTHER: 'OTHER'
})

// CALL API MODEL TYPE
export const HtmlMethodType = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
})

// CALL API MODEL TYPE
export const ElementType = Object.freeze({
  DYNAMIC: 'dynamic',
  DATE: 'date',
  MODEL: 'model',
  INTEGER: 'integer',
  TEXT: 'text',
  STRING: 'string',
  MONEY: 'money',
  DOUBLE: 'double',
  ADDRESS: 'address'
})

export const ModelType = Object.freeze({
  CONTACT: 'Contact',
  COMPANYSTOCKS: 'CompaniesStockAlias',
  COMPANYEVENTS: 'CompaniesEvents',
  COMPANYVESTINGS: 'CompaniesVesting',
  SELECTOR: 'Selector',
  ORGANFUNCTION: 'OrganFunction',
  COMPANYORGANS: 'CompaniesOrgan'
})

export enum AppointmentTerminationReason {
  mandateExpiry = 'MANDATE_EXPIRY',
  resignation = 'RESIGNATION',
  revocation = 'REVOCATION',
  nullity = 'NULLITY',
  other = 'OTHER'
}

// PDF VIEWER
export const PIXEL_RATIO = window.devicePixelRatio || 1
export const VIEWPORT_RATIO = 1

// REGEX
export const regexControlEmail =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
export const regexControlPassword =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[*.!@#$%^&(){}[\]:;<>,.?/~_+-]).{12,}$/
export const regexControlText =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.]{2,}$/u
export const regexControlTextAndNumber =
  /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,}$/u
export const regexControlNumber = /^[0-9]+$/
export const regexControlDate =
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
export const regexControlSMS = /^\d{5}$/
export const regexControlOTP = /^\d{6}$/
export const regexControlSiren = /^\d{9}$/
export const regexControlSiret = /^\d{14}$/
export const regexControlUUID =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

// OTP TIMING
export const timeout = 60000
export const refreshTimeOTP = 30
export const oneSecond = 1000

export const responsiveMenuTriggerSize = 995
