import { STOCKTYPE, MAINACTIONS } from '@/types'
import i18n from '@/services/lang'
import { EventType } from '@/types/event'

export const stockTypesData = [
  {
    name: 'Simple',
    id: STOCKTYPE.SAMPLE,
    value: STOCKTYPE.SAMPLE,
    children: []
  },
  {
    name: 'Option',
    id: STOCKTYPE.OPTION,
    value: STOCKTYPE.OPTION,
    children: []
  },
  {
    name: 'Composition',
    id: STOCKTYPE.COMPOSITION,
    value: STOCKTYPE.COMPOSITION,
    children: []
  }
]

export const languages = [
  {
    id: 'fr',
    name: i18n.global.t('account.accountInformations.languageList.french'),
    value: 'fr',
    image: '/imgs/lang/fr.png'
  },
  {
    id: 'en',
    name: i18n.global.t('account.accountInformations.languageList.english'),
    value: 'en',
    image: '/imgs/lang/en.png'
  }
]

export const mainActionsList = [
  {
    name: i18n.global.t('stockGroupBook.title'),
    icon: 'collection',
    type: 'list',
    key: MAINACTIONS.STOCKGROUP
  },
  {
    name: i18n.global.t('stockBook.title'),
    icon: 'pie-chart',
    type: 'list',
    key: MAINACTIONS.STOCK
  },
  {
    name: i18n.global.t('vestingBook.title'),
    icon: 'file-spreadsheet',
    type: 'list',
    key: MAINACTIONS.VESTING
  },
  {
    name: i18n.global.t('committeeBook.title'),
    icon: 'diagram-2-fill',
    type: 'list',
    key: MAINACTIONS.COMMITTEE
  },
  {
    name: i18n.global.t('groupBook.title'),
    icon: 'people-fill',
    type: 'list',
    key: MAINACTIONS.GROUP
  },
  {
    name: i18n.global.t('contactBook.title'),
    icon: 'person-lines-fill',
    type: 'list',
    key: MAINACTIONS.CONTACT
  }
]

export const vestingTableColumns = [
  {
    label: '#',
    field: 'order',
    sortable: true,
    custom: true,
    width: '1%'
  },
  {
    label: i18n.global.t('vestingBook.labels.vestingType'),
    field: 'vestingType',
    sortable: true,
    custom: true,
    width: '14%'
  },
  {
    label: i18n.global.t('vestingBook.labels.startOfPeriod'),
    field: 'startOfPeriod',
    sortable: true,
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t('vestingBook.labels.nbTimeUnits'),
    field: 'nbTimeUnits',
    custom: true,
    sortable: true,
    width: '8%'
  },
  {
    label: i18n.global.t('vestingBook.labels.timeUnit'),
    field: 'timeUnit',
    sortable: true,
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t('vestingBook.labels.endOfPeriod'),
    field: 'endOfPeriod',
    sortable: true,
    custom: true,
    width: '14%'
  },
  {
    label: i18n.global.t('vestingBook.labels.value'),
    field: 'value',
    sortable: true,
    custom: true,
    width: '7%'
  },
  {
    label: i18n.global.t('vestingBook.labels.roundingMethod'),
    field: 'roundingMethod',
    sortable: true,
    custom: true,
    width: '12%'
  },

  {
    label: '',
    field: 'actions',
    custom: true,
    width: '1%'
  }
]

export const auditTableColumns = [
  {
    label: '#',
    field: 'order',
    custom: true
  },
  {
    label: 'Motives',
    field: 'motives',
    custom: true
  },
  {
    label: 'Comments',
    field: 'motives',
    custom: true
  },
  {
    label: 'Processed',
    field: 'startOfPeriod',
    custom: true
  },
  {
    label: 'Date',
    field: 'createdAt',
    custom: true
  },
  {
    label: '',
    field: 'actions',
    custom: true
  }
]

export const stockRegisterColumns = [
  {
    label: '#',
    field: 'id',
    custom: true,
    width: '5%'
  },
  {
    label: 'Date',
    field: 'date',
    custom: true,
    width: '5%'
  },
  {
    label: i18n.global.t('datatable.column.sender'),
    field: 'from',
    custom: true
  },
  {
    label: i18n.global.t('datatable.column.receiver'),
    field: 'to',
    custom: true
  },
  {
    label: i18n.global.t('datatable.column.stock'),
    field: 'stock',
    custom: true
  },

  {
    label: i18n.global.t('datatable.column.quantity'),
    field: 'quantity',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t('datatable.column.event'),
    field: 'eventName',
    custom: true,
    width: '5%'
  }
  // {
  //   label: i18n.global.t('datatable.column.comment'),
  //   field: 'comment',
  //   custom: true,
  //   width: '5%'
  // }
]
export const userTransactionsColumns = [
  {
    label: 'Date',
    field: 'date',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t('datatable.column.event'),
    field: 'eventName',
    custom: true,
    width: '10%'
  },
  // {
  //   label: '#',
  //   field: 'id',
  //   custom: true,
  //   width: '5%'
  // },
  {
    label: i18n.global.t('datatable.column.transaction'),
    field: 'from',
    custom: true,
    width: '10%'
  },
  // {
  //   label: i18n.global.t('datatable.column.receiver'),
  //   field: 'to',
  //   custom: true,
  //   width: '10%'
  // },
  {
    label: i18n.global.t('datatable.column.stock'),
    field: 'stock',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t('datatable.column.quantity'),
    field: 'quantity',
    custom: true,
    width: '10%'
  }
  // {
  //   label: i18n.global.t('datatable.column.price'),
  //   field: 'price',
  //   custom: true,
  //   width: '10%'
  // },
]

export const delegationColumns = [
  {
    label: 'Date',
    field: 'createdAt',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.delegation'),
    field: 'label',
    custom: false
  },
  {
    label: i18n.global.t('events.elements.stockCategory'),
    field: 'stock',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.delegationQuantity'),
    field: 'delQuantity',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.commonCeil'),
    field: 'ceiling',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.endDate'),
    field: 'endDate',
    custom: true
  },

  {
    label: i18n.global.t('events.elements.delegationAssignedquantity'),
    field: 'quantity',
    custom: true
  },

  {
    label: i18n.global.t('events.elements.balance'),
    field: 'balance',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.status'),
    field: 'status',
    custom: true
  },
  {
    label: i18n.global.t('datatable.column.event'),
    field: 'eventName',
    custom: true,
    width: '3%'
  }
]

export const comnpaniesListColumns = [
  {
    label: i18n.global.t('account.accountCompanies.labels.name'),
    custom: false,
    field: 'name'
  },
  {
    label: i18n.global.t('account.accountCompanies.labels.representedBy'),
    custom: true,
    field: 'respresentantContact'
  },
  {
    label: i18n.global.t('account.accountCompanies.labels.siret'),
    custom: false,
    field: 'siret'
  },
  {
    label: i18n.global.t('account.accountCompanies.labels.active'),
    custom: true,
    field: 'status'
  },
  {
    label: i18n.global.t('account.accountCompanies.labels.createdAt'),
    custom: true,
    field: 'createdAt'
  },
  {
    label: i18n.global.t('account.accountCompanies.labels.constitutedAt'),
    custom: true,
    field: 'constituedAt'
  },
  { label: '', custom: true, field: 'actions' }
]

export const delegationHolderColumns = [
  {
    label: i18n.global.t('events.elements.date'),
    field: 'date',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.contact'),
    field: 'contact',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.delegation'),
    field: 'delegation',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.stock'),
    field: 'stock',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.quantity'),
    field: 'quantity',
    custom: true
  },
  {
    label: i18n.global.t('datatable.column.event'),
    field: 'eventName',
    custom: true,
    width: '3%'
  }
]

export const mandateColumns = [
  {
    label: i18n.global.t('events.elements.contact'),
    field: 'contact',
    custom: true,
    width: ''
  },
  {
    label: i18n.global.t('events.elements.function'),
    field: 'function',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.committee'),
    field: 'organ',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.startDate'),
    field: 'startDate',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.endDate'),
    field: 'endDate',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.status'),
    field: 'status',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.event'),
    field: 'event',
    custom: true
  }
]

export const meetingColumns = [
  {
    label: 'Date',
    field: 'date',
    custom: true
  },
  {
    label: i18n.global.t('documents.elements.decisionType'),
    field: 'type',
    custom: true
  },
  {
    label: 'Document',
    field: 'name',
    custom: true
  },
  {
    label: '',
    field: 'actions',
    custom: true,
    width: '5%'
  }
]

export const committeeColumns = [
  {
    label: i18n.global.t('events.elements.date'),
    field: 'date',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t('events.elements.committee'),
    field: 'committee',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.document'),
    field: 'document',
    custom: true
  },
  {
    label: i18n.global.t('events.elements.minMembersCount'),
    field: 'minMembers',
    custom: false
  },
  {
    label: i18n.global.t('events.elements.maxMembersCount'),
    field: 'maxMembers',
    custom: false
  },
  {
    label: i18n.global.t('events.elements.membersCount'),
    field: 'countMembers',
    custom: true
  },
  {
    label: '',
    field: 'download',
    custom: true,
    width: '5%'
  }
]

export const eventCategoriesList = [
  {
    id: '10',
    name: i18n.global.t(`events.categories.stockOperations`),
    value: 'cancel',
    group: [
      {
        id: EventType.capitalReduction,
        name: i18n.global.t(`events.categories.capital`),
        value: 'capital'
      },
      {
        id: EventType.optionExercise,
        name: i18n.global.t(`events.categories.exercise`),
        value: 'exercise'
      },
      {
        id: EventType.freeSharesFinalAcquisition,
        name: i18n.global.t(`events.categories.finalAcquisition`),
        value: 'finalAcquisition'
      },
      {
        id: EventType.conversion,
        name: i18n.global.t(`events.categories.conversion`),
        value: 'conversion'
      },
      {
        id: EventType.parityChange,
        name: i18n.global.t(`events.categories.parityChange`),
        value: 'parityChange'
      },
      {
        id: EventType.optionSunset,
        name: i18n.global.t(`events.categories.lapseOptions`),
        value: 'lapseOptions'
      },
      {
        id: EventType.freeSharesSunset,
        name: i18n.global.t(`events.categories.freeSharesSunsets`),
        value: 'freeSharesSunsets'
      }
    ]
  },
  {
    id: '31',
    name: i18n.global.t(`events.categories.emit`),
    value: 'emit',
    group: [
      {
        id: EventType.stockIssuance,
        name: i18n.global.t(`events.categories.primary`),
        value: 'primary'
      },
      {
        id: EventType.optionIssuance,
        name: i18n.global.t(`events.categories.option`),
        value: 'option'
      },
      {
        id: EventType.compositionIssuance,
        name: i18n.global.t(`events.categories.composition`),
        value: 'composition'
      },
      {
        id: EventType.freeShareIssuance,
        name: i18n.global.t(`events.categories.freeShareIssuance`),
        value: 'freeShareIssuance'
      }
    ]
  },
  {
    id: '40',
    name: i18n.global.t(`events.categories.transfer`),
    value: 'transfer',
    group: [
      {
        id: EventType.transfer,
        name: i18n.global.t(`events.categories.transfer`),
        value: 'transfer'
      }
    ]
  },
  {
    id: '50',
    name: i18n.global.t(`events.categories.nominal`),
    value: 'nominal',
    group: [
      {
        id: EventType.nominalValue,
        name: i18n.global.t(`events.categories.fix_nominal_value`),
        value: 'fix_nominal_value'
      }
    ]
  },
  {
    id: '60',
    name: i18n.global.t(`events.categories.delegation`),
    value: 'delegation',
    group: [
      {
        id: EventType.commongCeiling,
        name: i18n.global.t(`events.categories.commonCeiling`),
        value: 'commonCeiling'
      },
      {
        id: EventType.delegation,
        name: i18n.global.t(`events.categories.delegation`),
        value: 'delegation'
      }
    ]
  },
  {
    id: '70',
    name: i18n.global.t(`events.categories.vesting`),
    value: 'delegation',
    group: [
      {
        id: EventType.vestingSuspension,
        name: i18n.global.t(`events.categories.suspension`),
        value: 'suspension'
      },
      {
        id: EventType.vestingAcceleration,
        name: i18n.global.t(`events.categories.acceleration`),
        value: 'acceleration'
      },
      {
        id: EventType.exerciseDelayProrogation,
        name: i18n.global.t(`events.categories.prorogation`),
        value: 'prorogation'
      }
    ]
  },
  {
    id: '80',
    name: i18n.global.t(`events.categories.nomination`),
    value: 'nominal',
    group: [
      {
        id: EventType.appointment,
        name: i18n.global.t(`events.categories.nomination`),
        value: 'nomination'
      },
      {
        id: EventType.appointmentTermination,
        name: i18n.global.t(`events.categories.officeTermination`),
        value: 'officeTermination'
      }
    ]
  },

  {
    id: '100',
    name: i18n.global.t(`events.categories.others`),
    value: 'social',
    group: [
      {
        id: EventType.financialEnding,
        name: i18n.global.t(`events.categories.socialEnd`),
        value: 'socialEnd'
      },
      {
        id: EventType.accountApproval,
        name: i18n.global.t(`events.categories.approbation`),
        value: 'socialAppro'
      }
    ]
  }
]

export const CaptableColumns = [
  {
    label: i18n.global.t(`datatable.column.associates`),
    field: 'associates',
    custom: true,
    order: 1,
    selected: true,
    sortBy: 'contactName',
    type: 'text'
  },
  {
    label: i18n.global.t(`datatable.column.groups`),
    field: 'groups',
    custom: true,
    filterable: true,
    order: 2,
    selected: true,
    sortable: true,
    type: 'text'
  },
  {
    label: i18n.global.t(`datatable.column.stocks`),
    field: 'stocks',
    custom: true,
    selected: true,
    order: 3,
    sortable: true,
    type: 'text'
  },
  {
    label: i18n.global.t(`datatable.column.funding`),
    field: 'funding',
    custom: true,
    filterable: true,
    selected: true,
    order: 4,
    sortBy: 'amount',
    type: 'number'
  },
  {
    label: i18n.global.t(`datatable.column.nfd`),
    field: 'nfd',
    custom: true,
    order: 5,
    selected: true,
    sortBy: 'totalNFD',
    type: 'number'
  },
  {
    label: i18n.global.t(`datatable.column.nfd_p`),
    field: 'nfd_p',
    custom: true,
    order: 6,
    selected: false,
    sortable: true,
    type: 'number'
  },
  {
    label: i18n.global.t(`datatable.column.nfd_p_u`),
    field: 'nfd_p_u',
    custom: true,
    order: 7,
    selected: true,
    sortable: true,
    type: 'number'
  },
  {
    label: i18n.global.t(`datatable.column.fd`),
    field: 'fd',
    custom: true,
    selected: true,
    order: 8,
    sortBy: 'totalFD',
    type: 'number'
  },
  {
    label: i18n.global.t(`datatable.column.fd_p`),
    field: 'fd_p',
    order: 9,
    selected: false,
    custom: true,
    sortable: true,
    type: 'text'
  },
  {
    label: i18n.global.t(`datatable.column.fd_p_u`),
    field: 'fd_p_u',
    custom: true,
    order: 10,
    selected: true,
    sortable: true,
    type: 'text'
  }
]

export const exerciseTableColumns = [
  {
    label: '#',
    field: 'index',
    custom: true,
    width: '3%'
  },
  {
    label: i18n.global.t(`datatable.column.date`),
    field: 'date',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t(`datatable.column.pVested`),
    field: 'pVested',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t(`datatable.column.qVested`),
    field: 'qVested',
    custom: true,
    width: '10%'
  },
  {
    label: i18n.global.t(`datatable.column.cumulatedVestedQuantity`),
    field: 'cumulatedVestedQuantity',
    custom: true,
    width: '10%'
  }
]

// export const defaultProfileImage =
//   'https://www.nicepng.com/png/full/73-730154_open-default-profile-picture-png.png'
export const defaultProfileImage = '@/assets/images/profile/user.png'
export const companyDefaultImage = '@/assets/images/profile/company.png'

export const backendResponseChart = {
  root_company: {
    company_name: 'Demo Company A',
    company_id: '4386ab35-89f6-4f79-9e22-51eeb66e4ba5'
  },
  vertexes: [
    {
      company_name: 'Demo Company B',
      company_id: '3d80cab5-6cf6-4809-bf2d-c5120058b4d4'
    },
    {
      company_name: 'Demo Company A',
      company_id: '4386ab35-89f6-4f79-9e22-51eeb66e4ba5'
    },
    {
      company_name: 'UPLAW (Démo)',
      company_id: 'b03c5dcd-4b6f-473c-9471-3a6356259148'
    },
    {
      company_name: 'Demo Company C',
      company_id: '077af18d-3786-4747-a7d1-20d4db06a17c'
    }
  ],
  edges: [
    {
      source: 'Demo Company A',
      destination: 'Demo Company B',
      percentage: '100'
    },
    {
      source: 'Demo Company A',
      destination: 'UPLAW (Démo)',
      percentage: '0.017875113730411108'
    },
    {
      source: 'Demo Company B',
      destination: 'Demo Company C',
      percentage: '100'
    }
  ]
}

export const optionExpirationSvg = `<svg
width="48"
height="48"
viewBox="0 0 48 48"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<rect width="48" height="48" rx="8" fill="#FFEDD5" />
<rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
<rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
<rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
<rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
<rect x="29" y="16" width="2" height="20" fill="#FB923C" />
<rect x="33" y="12" width="2" height="24" fill="#FB923C" />
</svg>`

export const delegExpirationSvg = `<svg
width="48"
height="48"
viewBox="0 0 48 48"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<rect width="48" height="48" rx="8" fill="#FFEDD5" />
<path stroke="#FDBA74"
fill-rule="evenodd"
  clip-rule="evenodd"
  stroke-width="2" d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
</svg>`

export const appointmentExpirationSvg = `<svg
width="48"
height="48"
viewBox="0 0 48 48"
fill="none"
aria-hidden="true"
xmlns="http://www.w3.org/2000/svg"
>
<rect width="48" height="48" rx="8" fill="#FFEDD5" />
<path
  d="M24 11L35.2583 17.5V30.5L24 37L12.7417 30.5V17.5L24 11Z"
  stroke="#FB923C"
  stroke-width="2"
/>
<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M16.7417 19.8094V28.1906L24 32.3812L31.2584 28.1906V19.8094L24 15.6188L16.7417 19.8094Z"
  stroke="#FDBA74"
  stroke-width="2"
/>
<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M20.7417 22.1196V25.882L24 27.7632L27.2584 25.882V22.1196L24 20.2384L20.7417 22.1196Z"
  stroke="#FDBA74"
  stroke-width="2"
/>
</svg>`
