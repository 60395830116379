
  import { watch, defineComponent, computed, ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { LoadAction, VueEternalLoading } from '@ts-pro/vue-eternal-loading'
  import { Pagination } from '@/store/modules/Organization/models'
  import Loader from '../Loader/index.vue'
  import { rgbToHex } from '@/utils'
  export default defineComponent({
    components: {
      EmptyState,
      VueEternalLoading,
      Loader,
      Tag
    },
    props: {
      search: { type: String, default: '' }
    },
    emits: ['dataCount', 'close', 'loading'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const events = computed(
        () => store.getters['organizations/searchedEvents']
      )

      const eventsList = ref<any[]>([])

      const isLoading = computed(
        () => store.getters['organizations/searchingEvents']
      )

      const pagination = computed<Pagination>(
        () => store.getters['organizations/searchedEventsPagination']
      )
      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )
      const loading = ref(false)
      const navigate = (id: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId: id }
        })

        emit('close')
      }
      const getEvents = (search: string | null) => {
        loading.value = true
        emit('loading', 'events', true)

        store.dispatch('organizations/SEARCH_EVENTS', {
          companyId: route.params.id,
          offset: 0,
          limit: 10,
          filter: { q: search },
          onSuccess: (resp: any) => {
            eventsList.value = resp.data
            loading.value = false
            emit('loading', 'events', false)
          }
        })
      }

      const load = async ({ loaded }: LoadAction) => {
        if (eventsList.value.length >= pagination.value.total) loaded(0, 0)

        const response: any = await store.dispatch(
          'organizations/SEARCH_EVENTS',
          {
            companyId: route.params.id,
            filter: { q: props.search },
            offset: eventsList.value.length
          }
        )
        if (response && response.data) {
          eventsList.value.push(...response.data)
          loaded(eventsList.value.length, pagination.value.lastPage)
        }
        emit('dataCount', 'events', eventsList.value.length || 0)
        emit('loading', 'events', false)
        loading.value = false
      }

      watch(
        () => props.search,
        (val) => {
          getEvents(val)
        }
      )

      watch(
        () => eventsList.value,
        (val) => {
          emit('dataCount', 'events', val?.length || 0)
        }
      )

      onMounted(() => {
        emit('dataCount', 'events', eventsList.value.length || 0)
      })

      return {
        events,
        noMore,
        isLoading,
        eventsList,
        loading,
        load,
        rgbToHex,
        navigate,
        moment
      }
    }
  })
