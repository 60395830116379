
  import { ActionListItem, MAINACTIONS } from '@/types/config'
  import { groupItemBy } from '@/utils'
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
  import { computed, defineComponent, onMounted } from 'vue'
  export default defineComponent({
    components: {
      Menu,
      MenuButton,
      MenuItems,
      MenuItem
    },
    props: {
      direction: {
        type: String,
        default: 'rtl'
      },
      callerClass: {
        type: String,
        default:
          'bg-white bg-opacity-20 px-2 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
      },
      actions: {
        type: Object as () => Array<ActionListItem>,
        required: false,
        default: null
      }
    },
    emits: ['onClick'],
    setup(props, { emit }) {
      const groupedActions = computed(() =>
        groupItemBy(props.actions, (i) => i.section || 1)
      )
      const handleAction = (action: MAINACTIONS) => {
        emit('onClick', action)
      }

      return { groupedActions, handleAction }
    }
  })
