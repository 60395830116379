<template>
  <section :class="`event__wrapper my-10 ${isSelected ? 'selected' : ''}`">
    <div class="event__header flex justify-between mb-2">
      <div class="event__infos flex flex-col justify-left items-left">
        <div class="font-bold text-left">
          <i
            :class="`bi bi-chevron-${
              displayed ? 'down' : 'up'
            } mr-2 transition transition-all duration-75`"
            @click.stop="collapseCard()"
          ></i>
          {{ document.docSubCategory?.name || '-' }}

          <span
            v-if="document.isDraft"
            class="border border-error rounded my-2 ml-2 px-2 text-error"
          >
            {{ $t('documents.draftDocument') }}
          </span>
        </div>
        <div class="flex mt-2">
          <div class="text-primary text-left">
            <p v-if="document.signedAt" class="text-primary4 py-2 font-bold">
              {{ moment.utc(document.signedAt).format('L') }}
            </p>
            <p v-else>{{ $t('global.dateFormatPlaceHolder') }}</p>
          </div>
          <div
            :class="`tag my-2 ${
              document.signedAt === null ? 'tag-danger' : ''
            }`"
          >
            {{
              document.signedAt !== null
                ? $t('documents.signed')
                : $t('documents.notSigned')
            }}
          </div>
        </div>
      </div>
      <document-actions
        :is-portfolio="isPortfolio"
        :item="document"
        :side="currentSide"
        @toggle-side="toggleSide"
        @select-card="selectCard"
      />
    </div>
    <div v-if="currentSide === CardType.DOCUMENT" class="overflow-hidden">
      <p class="text-left text-secondary9">
        {{ document.label || document.name }}
      </p>
    </div>
    <div v-else>
      <data-table
        v-if="document.events.length"
        :rows="document.events"
        :columns="eventColumns"
      >
        <template #date="{ row }">
          <p v-if="row.date" class="text-left">
            {{ moment.utc(row.date).format('L') }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #createdAt="{ row }">
          {{ moment.utc(row.createdAt).format('L') }}
        </template>
        <template #name="{ row }">
          <p
            v-if="row.type"
            class="text-left cursor-pointer"
            @click.stop="goToEvent(row.id)"
          >
            {{ $t(`events.labels.${row.type}`) }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
      </data-table>
      <h5 v-else class="text-left">{{ $t('global.documentHasNoEvent') }}</h5>
    </div>
  </section>
</template>

<script lang="ts">
  import { defineComponent, ref, computed } from 'vue'
  import moment from 'moment'
  import { Document } from '@/services/api/models'
  import { CardType } from '@/types/config'
  import DocumentActions from './DocumentActions.vue'
  import { useI18n } from 'vue-i18n'
  import DataTable from '../DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'DocumentCard',
    components: {
      DocumentActions,
      DataTable
    },
    props: {
      document: {
        type: Object as () => Document,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select-card', 'collapse'],
    setup(props, { emit }) {
      const router = useRouter()
      const route = useRoute()
      const document = ref<Document>(props.document)
      const displayed = ref(true)
      const { t } = useI18n()
      const currentSide = ref<CardType>(CardType.DOCUMENT)
      const toggleAudit = ref(false)
      const isSelected = ref(false)
      const eventColumns = computed(() => [
        {
          label: t('events.elements.eventDate'),
          field: 'date',
          custom: true,
          width: ''
        },
        {
          label: t('events.elements.eventCreatedDate'),
          field: 'createdAt',
          custom: true,
          width: ''
        },
        {
          label: t('events.elements.eventName'),
          field: 'name',
          custom: true,
          width: ''
        }
      ])
      const toggleSide = (what: CardType) => {
        currentSide.value = what
      }
      const collapseCard = () => {
        emit('collapse', !displayed.value)
      }
      const selectCard = (data: { check: boolean; id: string }) => {
        isSelected.value = data.check
        emit('select-card', data)
      }

      const isSigned = computed(() => document.value.signedAt !== null)

      const addDocument = (row: any, index: number) => {
        console.log(row, index)
      }

      const goToEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      return {
        displayed,
        currentSide,
        toggleAudit,
        isSelected,
        CardType,
        eventColumns,
        toggleSide,
        selectCard,
        addDocument,
        goToEvent,
        collapseCard,
        moment,
        isSigned
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '../../scss/eventcard.scss';
  .coll-enter-active,
  .coll-leave-active {
    transition: max-height 0.5s ease-in-out;
    max-height: 200px;
    overflow: hidden;
  }
  .coll-enter-from,
  .coll-leave-to {
    max-height: 0;
  }

  .event-docView {
    border-radius: 4px;
    border: 1px solid rgb(208, 210, 214);
    background-color: rgb(245, 247, 250);
    .event-doc-wrapper {
      @apply p-4 pt-2 flex;
      .event-doc {
        @apply p-1 px-3 border-r-2 text-sm;
        text-align: left;
        border-radius: 10px;
        border: 1px solid rgb(208, 210, 214);
        background-color: #ffffff;
      }
    }
  }
  .tag {
    height: 20px;
    margin-left: 8px;
    border-radius: var(--radius-s);
    font-size: var(--fs-xs);
    text-transform: uppercase;
    color: var(--color-contrast-40);
    font-weight: 500;
    @include flex-center-center;
    padding: 0 10px;
    border: 1px solid var(--color-contrast-40);

    &.active {
      border-color: var(--color-success-high) !important;
      color: var(--color-success-high) !important;
    }
    &.tag-danger {
      @apply text-error border-error;
    }
  }
</style>
