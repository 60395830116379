
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { delegationColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import DropdownCeilings from '@/components/Forms/DropDowns/DropdownCeilings.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import { ElTooltip } from 'element-plus'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { DelegationStatus } from '@/types/event'
  export default defineComponent({
    components: {
      Field,
      Input,
      DataTable,
      DownloadButton,
      DropdownCeilings,
      DatePicker,
      Button,
      ElTooltip,
      EmptyState,
      Multiselect
    },
    setup() {
      const search = ref('')
      const status = ref('')
      const commonceiling = ref('')
      const store = useStore()
      const picked = ref('')
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const columns = computed(() => delegationColumns)
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )
      const isDownloading = ref(false)

      const statusChoices = computed(() =>
        Object.values(DelegationStatus).map((x) => ({
          id: x,
          name: t(`DelegationStatus.${x}`)
        }))
      )
      const ceilings = computed(() => store.getters['organizations/ceilings'])
      const pagination = computed(
        () => store.getters['organizations/delegationsPagination']
      )

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', page)
        getDelegations()
      }
      const getDelegations = (companyId = route.params.id) => {
        const filter: any = {}
        if (search.value) filter.search = search.value
        if (commonceiling.value) filter.commonCeilingId = commonceiling.value
        if (picked.value) filter.usableAtDate = picked.value
        if (status.value) filter.status = status.value

        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId,
          filter
        })
      }

      const getCeilings = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_CEILINGS', {
          companyId
        })
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const resetFilters = () => {
        picked.value = ''
        commonceiling.value = ''
        search.value = ''
        status.value = ''
      }

      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_DELEGATIONS', {
          name: `${t('exports.delegations')}`,
          companyId,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      onMounted(() => {
        getCeilings()

        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
        getDelegations()
      })

      watch(
        () => route.params.id,
        (val) => {
          store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
          getDelegations(val)
        }
      )

      watch(
        [
          () => commonceiling.value,
          () => search.value,
          () => picked.value,
          () => status.value
        ],
        () => {
          store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
          getDelegations()
        }
      )
      return {
        search,
        delegations,
        columns,
        moment,
        commonceiling,
        pagination,
        ceilings,
        picked,
        statusChoices,
        status,
        isDownloading,
        gotoEvent,
        resetFilters,
        download,
        formatNumber,
        onPageChange
      }
    }
  })
