<template>
  <div class="h-full">
    <Field v-slot="{ field }" v-model="search" type="text" name="search">
      <Input
        v-model="search"
        v-bind="field"
        :disabled="false"
        :placeholder="$t('global.search')"
      >
      </Input>
    </Field>
    <div class="alias-list h-4/5 overflow-scroll">
      <div
        v-for="committee in listedOrgans"
        :key="committee.id"
        :class="`p-1 my-1 rounded cursor-pointer ${
          currentCommittee && currentCommittee.id == committee.id
            ? 'bg-secondary5'
            : 'hover:bg-secondary5'
        }`"
        @click="handleSelect(committee.id)"
      >
        <h5 class="text-blackp text-md">{{ committee.denomination }}</h5>
      </div>
    </div>
    <div v-if="canDo" class="bottom-container bg-white text-xl">
      <Button
        :disabled="false"
        :label="$t('committeeBook.form.new')"
        size="medium"
        variant="secondary"
        class="w-full h-10 flex justify-center items-center"
        @click="initForm"
      >
        <i class="bi bi-plus text-md"></i>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { CompanyCommittee } from '@/services/api/models'
  import { CompanyModel } from '@/services/api/models1'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-committees'))
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentCommittee = computed<CompanyCommittee>(
        () => store.getters['organizations/currentCommittee']
      )

      const getOrgans = () => {
        store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: currentCompany.value.id
        })
      }
      const committees = computed<CompanyCommittee[]>(
        () => store.getters['organizations/committees']
      )
      const listedOrgans = ref(committees.value)

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (committeeId: string) => {
        store.dispatch('organizations/GET_COMPANY_COMMITTEE', {
          companyId: currentCompany.value.id,
          committeeId
        })
      }

      onBeforeMount(() => {
        getOrgans()
      })

      watch(search, (text: string) => {
        listedOrgans.value = committees.value.filter((x) =>
          x.denomination.toLowerCase().includes(text.toLowerCase())
        )
      })

      watch(committees, (list) => {
        listedOrgans.value = list
      })
      return {
        canDo,
        listedOrgans,
        search,
        currentCommittee,
        initForm,
        handleSelect
      }
    }
  })
</script>
<style lang="scss" scoped>
  .bottom-container {
    @apply absolute bottom-0 right-0 left-0 z-10 h-20 flex justify-center items-center px-4;
  }
</style>
