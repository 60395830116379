export interface ConfigState {
  lang: string
}
export interface Auth0Config {
  clientId: string
  domain: string
  audience: string
  redirectUri: string
}

export interface UrlConfig {
  apiHost: string
  appDomain: string
  sentryDsn?: string
  diagramExternalLink?: string
}

export interface GoogleMapApi {
  apiKey: string
}

export interface GlobalConfig {
  environement: string
  auth0Options: Auth0Config
  urls: UrlConfig
  googleMap: GoogleMapApi
}

export interface AddressData {
  postalCode: string
  address: string
  fullAddress: string
  city: string
  country: string
}

export interface ActionListItem {
  action?: string | (() => void)
  section?: number
  name: string
  icon: string
  type: string
  children?: ActionListItem[]
}

export interface SelectOptionsItem {
  name: string
  id: string | number
  value: string
  children?: Array<SelectOptionsItem>
}

export interface FileUploaded {
  file: File
  category: string | null
  progress: number
  success: boolean
  error: boolean
}
export enum ApprobationAllocationType {
  legalReserve = 'LEGAL_RESERVE',
  statutoryReserve = 'STATUTORY_RESERVES',
  otherReserve = 'OTHER_RESERVE'
}

export enum AlertType {
  delegationExpiration = 'DELEGATION_EXPIRATION',
  appointementExpiration = 'APPOINTMENT_EXPIRATION',
  optionExpiration = 'OPTION_EXPIRATION'
}

export enum STOCKTYPE {
  SAMPLE = 1,
  OPTION,
  COMPOSITION
}

export enum TimeUnit {
  DAYS = 'DAY',
  MONTHS = 'MONTH',
  YEARS = 'YEAR'
}

export enum MAINACTIONS {
  STOCK = 1,
  VESTING,
  COMMITTEE,
  CONTACT,
  GROUP,
  STOCKGROUP,
  PROFIT_SHARING_PLAN,
  FUNDRAISING_ROUND,
  EDIT_CONTACT,
  GLOBAL,
  CONTACT_RECORD_PDF,
  CONTACTS_RECORD_PDF,
  CONTACTS_RECORD_XLS,
  DELETE_CONTACT,
  OPERATION,
  DOCUMENTS_UPLAOD,
  NEW_EVENT,
  NEW_CONTACT
}

export enum CardType {
  DOCUMENT = 1,
  EVENT
}
