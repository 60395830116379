import { Auth0Config, UrlConfig } from '@/types/config'
import { envName } from '../index'

const auth0Options: Auth0Config = {
  redirectUri: 'http://localhost:8080/redirecting',
  audience: 'https://uplaw-staging.us.auth0.com/api/v2/',
  clientId: 'Cd1ORcfdV7WSjBYULOaWoUq4zAlBpWoL',
  domain: 'uplaw-staging.us.auth0.com'
}

const urls: UrlConfig = {
  apiHost: `http://localhost:4000`,
  // apiHost: 'http://demo2.eu-west-3.elasticbeanstalk.com',
  appDomain: 'https://up.law',
  sentryDsn:
    'https://d6ffd14534104bda8bd93cb86856cc40@o4504679368818688.ingest.sentry.io/4504723959447552',
  diagramExternalLink:
    'https://up-law.github.io/UPLAW_DIAGRAMS/src/main/webapp/'
}

const googleMap = {
  apiKey: 'AIzaSyDj3F7iUyFg45_rjD_V7_EDA3kIFi5c6wg'
}

const environement = `developpement`

export { environement, auth0Options, urls, googleMap }
