<template>
  <div class="upl-spinner" />
</template>

<script lang="ts">
  import { defineComponent } from '@vue/runtime-core'
  export default defineComponent({
    name: 'Spinner'
  })
</script>

<style lang="scss" scoped>
  .upl-spinner {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: var(--border-style-m) var(--color-primary);
    border-color: var(--color-primary) var(--color-primary) var(--color-primary)
      transparent;
    animation: animation-rotation-360 1s cubic-bezier(1, 0.3, 0.51, 0.9)
      infinite;

    &.xl {
      width: 100px;
      height: 100px;
      border-width: 4px;
    }

    &.lg {
      width: 75px;
      height: 75px;
      border-width: 4px;
    }

    &.md {
      width: 40px;
      height: 40px;
      border-width: 3px;
    }
  }
</style>
