<template>
  <div
    class="
      contacts
      container-fluid
      p-0
      w-100
      h-full
      relative
      left-pad
      relative
      max-h-screen
      upl-treeview
    "
  >
    <div
      :class="`
        relative flex flex-col
        contact-left-panel-container
        transition transition-all
        ease-in-out ${showTreeview ? '' : 'min-w-0'}
      `"
    >
      <div class="upl-treeview__toggle" @click="showTreeview = !showTreeview">
        <i v-show="showTreeview" class="bi-chevron-compact-left" />
        <i v-show="!showTreeview" class="bi-chevron-compact-right" />
      </div>
      <div
        v-show="showTreeview"
        :class="`flex flex-col max-h-full contacts-leftPanel-search transition transition-all ease-in-out `"
      >
        <ContactsTree />
      </div>
    </div>

    <div
      v-if="isLoadingPage"
      class="flex w-full h-full justify-center items-center relative"
    >
      <Loader />
    </div>

    <!-- EMPTY STATE -->
    <div
      v-else-if="!$route.params.contactId"
      class="flex h-full w-full justify-center items-center bg-main1"
    >
      <EmptyState
        image="choose-contact.svg"
        :title="$t('corporateDB.contacts.emptyStateTitle')"
        :subtitle="$t('corporateDB.contacts.emptyStateSubTitle')"
      />
    </div>
    <div
      v-else-if="currentContact"
      class="contacts-content w-full h-100 bg-main1"
    >
      <div class="p-5 sticky bg-white top-0 z-10">
        <ContactCard
          :contact="currentContact"
          @update-contact-list="getContacts"
        />
      </div>

      <div class="contacts-tables mt-10 p-10 h-full">
        <tab :tabs="tabList" defaultSelected="contactRegister"> </tab>
      </div>
    </div>

    <div
      v-else-if="errorCode"
      class="w-full h-auto flex justify-center items-center"
    >
      <p class="border rounded p-3 border-secondary5">
        {{ $t(`errors.contact-${errorCode}`) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { useStore } from 'vuex'
  import { useAcl } from 'vue-simple-acl/src'
  import {
    defineComponent,
    computed,
    onMounted,
    ref,
    watch,
    onBeforeMount
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import ContactCard from '@/components/ContactCard/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { sortObjectByKeys } from '@/utils'
  import {
    ContactGroup,
    ContactStatus,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models/company'
  import { CompanyModel } from '@/services/api/models1'
  import { CONTACTTYPE } from '@/types'
  import { useI18n } from 'vue-i18n'
  import Tab from '@/components/Tab/index.vue'
  import ContactCaptable from './stockTab.vue'
  import ContactSynthesis from './synthesisTab.vue'
  import ContactFreeSharesSynthesis from './freeSharesTab.vue'
  import ContactRegister from './registerTab.vue'
  import ContactVesting from './vestingTab.vue'
  import ContactDocuments from './documentTab.vue'
  import Simulate from './simulate.vue'
  import Loader from '@/components/Loader/index.vue'
  import ContactsTree from '@/components/ContactsTree/index.vue'
  import moment from 'moment'
  export default defineComponent({
    name: 'Contacts',
    components: {
      ContactCard,
      EmptyState,
      Tab,
      Loader,
      ContactsTree
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const router = useRouter()
      const route = useRoute()
      const contactStatusId = ref('')
      const contactType = ref('')
      const acl = useAcl()
      const stocksCount = ref(0)
      const documentsCount = ref(0)
      const filtered = computed(
        () =>
          aliasesId.value?.length !== 0 ||
          contactType.value !== '' ||
          contactStatusId.value != '' ||
          filter.value.name != ''
      )
      const isLoadingPage = ref(false)
      const isVisible = ref(false)
      const isLoadingData = ref(false)
      const hasOptions = ref(true)
      const hasFreeShares = ref(true)
      const showTreeview = ref(true)
      const filter = ref<any>({
        name: '',
        contactStatusId: '',
        type: '',
        contactGroupId: ''
      })
      const groupsDisplay = ref<any>({})
      const aliasesId = ref([])
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const errorCode = ref()
      const currentContact = ref<
        IndividualContact | ProfessionalContact | InvestorContact | null
      >(store.getters['organizations/contactDetails'])

      const allContactGroups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const companyGroups = computed<ContactGroup[]>(
        () => store.getters['organizations/groups']
      )

      const contactStatus = ref<ContactStatus[]>([])

      const types = ref(
        Object.values(CONTACTTYPE).map((x) => ({
          id: x,
          name: t(`contactType.${x}`)
        }))
      )

      const toggleGroup = (id: string) => {
        groupsDisplay.value[id] = !groupsDisplay.value[id]
      }

      const getCountContact = (id: string) => {
        return companyGroups.value.find((x) => x.name === id)?.countContacts
      }

      const calculateTabs = () => {
        const tabs: any[] = []
        if (
          acl.can.any([
            'employee-readTransactions',
            'is-manager',
            'admin',
            'read-transactions'
          ])
        ) {
          tabs.push({
            order: 2,
            key: 'contactRegister',
            langKey: 'contactRegister',
            component: ContactRegister
          })
        }

        if (
          acl.can.any([
            'employee-readStocks',
            'admin',
            'is-manager',
            'read-stocks'
          ]) &&
          currentContact.value &&
          hasOptions.value
        ) {
          tabs.push(
            ...[
              {
                order: 5,
                key: 'contactVesting',
                langKey: 'contactVesting',
                component: ContactVesting
              },
              {
                order: 3,
                key: 'contactSynthesis',
                langKey: 'contactSynthesis',
                component: ContactSynthesis
              }
            ]
          )
        }
        if (
          acl.can.any([
            'employee-readStocks',
            'admin',
            'is-manager',
            'read-stocks'
          ]) &&
          currentContact.value &&
          hasFreeShares.value
        ) {
          tabs.push(
            ...[
              {
                order: 3,
                key: 'contactFreeSharesSynthesis',
                langKey: 'contactFreeSharesSynthesis',
                component: ContactFreeSharesSynthesis
              }
            ]
          )
        }
        if (
          acl.can.any(['admin']) &&
          currentContact.value &&
          currentCompany.value?.contactCanSimulatePotentialGain
        ) {
          tabs.push({
            order: 5,
            key: 'simulate',
            langKey: 'simulation',
            component: Simulate
          })
        }
        if (
          acl.can.any([
            'employee-readDocuments',
            'admin',
            'is-manager',
            'read-documents'
          ]) &&
          currentContact.value &&
          documentsCount.value > 0
        ) {
          tabs.push({
            order: 6,
            key: 'contactDocuments',
            langKey: 'contactDocuments',
            component: ContactDocuments
          })
        }

        if (
          acl.can.any([
            'employee-readStocks',
            'is-manager',
            'admin',
            'read-stocks'
          ]) &&
          currentContact.value
        ) {
          tabs.push({
            order: 1,
            key: 'contactStock',
            langKey: 'contactCaptable',
            component: ContactCaptable
          })
        }
        return tabs.sort((a, b) => a.order - b.order)
      }

      const tabList = computed(() => calculateTabs())
      const getContactGroups = () => {
        store.dispatch('toolbox/GET_CONTACT_GROUPS')
      }

      const getContactSubInfo = () => {
        if (!route.params.contactId) return
        store.dispatch('organizations/LOAD_CONTACT_COMPUTED_DATA', {
          companyId: route.params.id,
          contactId: route.params.contactId,
          onSuccess: (data: any) => {
            documentsCount.value = data.documentsCount
            stocksCount.value = data.stocksCount
          }
        })
      }

      const getExerciseCalendar = async (contactId: string) => {
        if (!contactId) return
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            contactId,
            date: moment().endOf('day').toISOString(),
            offset: 0,
            limit: 100
          }
        })
      }
      const resetFilter = () => {
        aliasesId.value = []
        contactType.value = ''

        filter.value = {
          name: '',
          contactGroupId: '',
          contactStatusId: '',
          type: '',
          stockAliasIds: [],
          optionAliasIds: [],
          compositionAliasIds: []
        }
        getContacts()
        isVisible.value = false
      }
      const getContacts = () => {
        if (!currentCompany.value) return

        store.dispatch('organizations/FILTER_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: { ...filter.value, limit: 10, offset: 0 }
        })
      }
      const getVesting = async (contactId: string) => {
        if (!contactId) return
        store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            contactId,
            offset: 0,
            limit: 100,
            date: moment().endOf('day').toISOString()
          },
          onSuccess: (result: any) => {
            hasOptions.value = result.data.length > 0
            isLoadingData.value = false
          }
        })

        store.dispatch('organizations/GET_FREE_SHARES_ACQUISITION_CALENDARS', {
          companyId: route.params.id,
          page: 1,
          filter: {
            contactId,
            offset: 0,
            limit: 100,
            date: moment().endOf('day').toISOString()
          },
          onSuccess: (result: any) => {
            hasFreeShares.value = result?.data?.length > 0
          }
        })
      }

      const getContact = () => {
        if (
          !currentCompany.value ||
          !route.params?.contactId ||
          (route.params?.contactId &&
            route.params?.contactId === currentContact.value?.id)
        )
          return
        isLoadingPage.value = true

        store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: currentCompany.value.id,
          contactId: route.params?.contactId,
          onSuccess: (contact: any) => {
            isLoadingPage.value = false
            currentContact.value = { ...contact }
          },
          onError: (err: any) => {
            console.log(err)
            errorCode.value = err.statusCode
            currentContact.value = null
            isLoadingPage.value = false
          }
        })
        getVesting(route.params?.contactId?.toString())
        getExerciseCalendar(route.params?.contactId?.toString())
      }

      const allGroups = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contactsResult'])

      const groups = computed(() => {
        const noGroupContacts = allGroups.value.filter(
          (c) => c.contactGroups?.length == 0
        )
        const map = new Map()
        if (noGroupContacts.length > 0) map.set('zz-other', noGroupContacts)
        allGroups.value
          .filter((c) => c.contactGroups?.length)
          .forEach((elm, i) => {
            elm?.contactGroups?.forEach((group) => {
              const collection = map.get(group.name)
              if (!collection) {
                map.set(group.name, [elm])
              } else {
                collection.push(elm)
              }
            })
          })

        return sortObjectByKeys(map)
      })

      const handleSelection = ({ selected }: any) => {
        filter.value = { ...filter.value, ...selected }
      }

      const handleUserClick = async (
        contact: IndividualContact | ProfessionalContact | InvestorContact
      ) => {
        router.push({
          name: 'company.directory',
          params: { id: currentCompany.value.id, contactId: contact.id }
        })

        isLoadingData.value = true
      }

      const getGroups = () => {
        store.dispatch('organizations/GET_COMPANY_GROUPS', {
          companyId: route.params.id
        })
      }

      onMounted(() => {
        getContact()
        getContacts()
        getContactSubInfo()
        getContactGroups()
        getGroups()

        store.commit('organizations/SET_COMPANY_DOCUMENTS', {
          update: false,
          list: {
            data: [],
            metadata: { currentPage: 1, total: 0, lastPage: 1 }
          }
        })

        store.commit('organizations/SET_EXERCISE_CALENDARS', {
          data: []
        })
      })

      watch(currentCompany, (val) => {
        getContacts()
        getContact()
      })

      watch(allGroups, (val) => {
        if (val.length === 1 && !route.params.contactId) {
          const [ct] = val
          handleUserClick(ct)
        }
      })

      watch(contactType, (val) => {
        filter.value.type = val
        contactStatusId.value = ''
        contactStatus.value =
          allContactGroups.value.filter((x) => x.type === filter.value.type) ??
          []
      })

      watch(contactStatusId, (val) => {
        filter.value.contactStatusId = val
      })

      watch(
        () => route.params.contactId,
        (newContactId) => {
          if (!newContactId) {
            store.commit('organizations/SET_CURRENT_CONTACT', null)
          } else {
            getContact()
            getContactSubInfo()
          }
        }
      )

      watch(
        () => route.params.id,
        (id) => {
          router.push({ name: 'company.directory', params: { id } })
        }
      )

      watch(groups, (grps) => {
        Array.from(grps).forEach((x) => (groupsDisplay.value[x[0]] = true))
      })

      return {
        groups,
        showTreeview,
        currentContact,
        contactStatus,
        types,
        filter,
        aliasesId,
        isVisible,
        filtered,
        contactType,
        contactStatusId,
        tabList,
        errorCode,
        companyGroups,
        groupsDisplay,
        isLoadingPage,
        toggleGroup,
        getCountContact,
        getContacts,
        handleSelection,
        resetFilter,
        handleUserClick
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/contacts.scss';
</style>
