<template>
  <DataTable :rows="periods" :columns="columns" cell-padding="p-0">
    <template #order="{ row, index }">
      <h6 class="text-center">{{ row.order }}</h6>
      <Field
        v-slot="{ field }"
        v-model="periods[index].order"
        :name="`vestingPeriods[${index}].order`"
      >
        <input
          v-model="periods[index].order"
          type="hidden"
          v-bind="field"
          :name="`vestingPeriods[${index}].order`"
        />
      </Field>
    </template>
    <template #startOfPeriod="{ index, row }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].startOfPeriod"
          :name="`vestingPeriods[${index}].startOfPeriod`"
        >
          <Multiselect
            :id="`vestingPeriods[${index}].startOfPeriod`"
            v-model="periods[index].startOfPeriod"
            class="h-10"
            open-direction="top"
            v-bind="field"
            mode="single"
            :disabled="row.order !== 1"
            :value="periods[index].startOfPeriod"
            :name="`vestingPeriods[${index}].startOfPeriod`"
            :options="row.order == 1 ? fisrtStartOfPeriods : startOfPeriods"
            :placeholder="$t('vestingBook.form.startOfPeriodPlaceholder')"
            :groups="false"
          />
        </Field></div
    ></template>
    <template #nbTimeUnits="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].nbTimeUnits"
          type="number"
          :name="`vestingPeriods[${index}].nbTimeUnits`"
        >
          <UplInput
            id="name"
            v-model="periods[index].nbTimeUnits"
            class="h-full"
            type="number"
            v-bind="field"
            :value="periods[index].nbTimeUnits"
            :placeholder="$t('vestingBook.form.nbTimeUnitsPlaceholder')"
            :name="`vestingPeriods[${index}].nbTimeUnits`"
            no-error
          />
        </Field>
      </div>
    </template>
    <template #vestingType="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].vestingType"
          :name="`vestingPeriods[${index}].vestingType`"
        >
          <Multiselect
            id="vestingType"
            v-model="periods[index].vestingType"
            open-direction="top"
            v-bind="field"
            mode="single"
            :name="`vestingPeriods[${index}].vestingType`"
            :value="periods[index].vestingType"
            :options="vestingTypes"
            :placeholder="$t('vestingBook.form.vestingTypePlaceholder')"
            :groups="false"
            @change="periods[index].vestingType = $event"
          />
        </Field>
      </div>
    </template>
    <template #timeUnit="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].timeUnit"
          :name="`vestingPeriods[${index}].timeUnit`"
        >
          <Multiselect
            id="timeUnit"
            v-model="periods[index].timeUnit"
            open-direction="top"
            v-bind="field"
            mode="single"
            :value="periods[index].timeUnit"
            :name="`vestingPeriods[${index}].timeUnit`"
            :options="timeUnits"
            :placeholder="$t('vestingBook.form.timeUnitPlaceholder')"
            :groups="false"
            @change="periods[index].timeUnit = $event"
          />
        </Field>
      </div>
    </template>

    <template #endOfPeriod="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].endOfPeriod"
          :name="`vestingPeriods[${index}].endOfPeriod`"
        >
          <Multiselect
            :id="`vestingPeriods.${index}.endOfPeriod`"
            v-bind="field"
            v-model="periods[index].endOfPeriod"
            open-direction="top"
            mode="single"
            :value="periods[index].endOfPeriod"
            :name="`vestingPeriods[${index}].endOfPeriod`"
            :options="endOfPeriods"
            :placeholder="$t('vestingBook.form.endOfPeriodPlaceholder')"
            :groups="false"
            @change="periods[index].endOfPeriod = $event"
          />
        </Field>
      </div>
    </template>
    <template #value="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].value"
          type="number"
          :name="`vestingPeriods[${index}].value`"
        >
          <UplInput
            id="name"
            v-model="periods[index].value"
            v-bind="field"
            type="number"
            :value="periods[index].value"
            :name="`vestingPeriods[${index}].value`"
            class="h-full"
            :step="0.01"
            :placeholder="$t('vestingBook.form.valuePlaceholder')"
            no-error
          />
        </Field>
      </div>
    </template>
    <template #type>
      <h6 class="text-center">
        {{ $t(`global.vestingValueUnit.${vesting.vestingValueUnit}`) }}
      </h6>
    </template>
    <template #roundingMethod="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].roundingMethod"
          :name="`vestingPeriods[${index}].roundingMethod`"
        >
          <Multiselect
            id="vestingValueUnit"
            v-bind="field"
            v-model="periods[index].roundingMethod"
            open-direction="top"
            mode="single"
            :name="`vestingPeriods[${index}].roundingMethod`"
            :options="roundingMethods"
            :value="periods[index].roundingMethod"
            :placeholder="$t('vestingBook.form.vestingValueUnitPlaceholder')"
            :groups="false"
            @change="periods[index].roundingMethod = $event"
          />
        </Field>
      </div>
    </template>
    <template #periodBehavior="{ index }">
      <div class="h-10">
        <Field
          v-slot="{ field }"
          v-model="periods[index].periodBehavior"
          :name="`vestingPeriods[${index}].periodBehavior`"
        >
          <Multiselect
            id="periodBehavior"
            v-bind="field"
            v-model="periods[index].periodBehavior"
            open-direction="top"
            mode="single"
            :name="`vestingPeriods[${index}].periodBehavior`"
            :options="periodBehaviors"
            :value="periods[index].periodBehavior"
            :placeholder="$t('vestingBook.form.vestingValueUnitPlaceholder')"
            :groups="false"
            @change="periods[index].periodBehavior = $event"
          />
        </Field>
      </div>
    </template>
    <template #actions="{ row, index }">
      <div class="flex justify-center items-center">
        <el-popover
          placement="left"
          :title="$t('events.table.dupe')"
          :width="200"
        >
          <div class="flex items-center">
            <Field v-slot="{ field }" type="number" name="timesToDuplicate">
              <upl-input
                v-model="timesToDuplicate"
                v-bind="field"
                class="flex w-12"
                type="number"
                :min="0"
                :placeholder="$t('events.table.add')"
                name="rowsToAdd"
                @input="inputValueChanged"
              />
            </Field>
            <div class="mx-2">
              {{ $t('events.table.rows') }}
            </div>
            <Button
              class="ml-2 w-12 h-8 flex items-center justify-center"
              variant="secondary"
              :label="$t('events.table.ok')"
              :disabled="parseInt(timesToDuplicate.toString()) <= 0"
              @click="duplicate(index)"
            />
          </div>
          <template #reference>
            <Button
              variant="custom"
              label=""
              class="rounded flex justify-center items-center"
            >
              <i class="bi bi-front text-lg"></i>
            </Button>
          </template>
        </el-popover>

        <Button
          variant="custom"
          class="rounded text-error p-1"
          @click="removeRow(row)"
        >
          <i class="bi bi-trash text-lg"></i>
        </Button>
      </div>
    </template>
    <template #total>
      <tr v-if="periods.length > 0" class="data-table-cell">
        <td
          colspan="6"
          class="
            border border-secondary4
            h-10
            text-right
            font-semibold
            px-2
            text-primary8
          "
        >
          Total
        </td>

        <td class="border border-secondary4">
          <p
            :class="`text-left px-2 text-left font-semibold ${
              vesting.vestingValueUnit === VESTINGVALUEUNIT.PERCENT &&
              calculateValue() !== 100
                ? 'text-error'
                : 'text-primary8'
            }`"
          >
            {{ formatNumber(calculateValue()) }}
            {{
              vesting.vestingValueUnit === VESTINGVALUEUNIT.PERCENT ? '%' : ''
            }}
          </p>
        </td>
        <td class="border border-secondary4"></td>
        <td class="border border-secondary4"></td>
      </tr>
    </template>
    <template #footer>
      <div class="p-2 flex flex-column items-center pt-5">
        <div class="mr-2">
          {{ $t('events.table.add') }}
        </div>
        <Field
          v-slot="{ field }"
          v-model="rowsToAdd"
          type="number"
          name="rowsToAdd"
        >
          <UplInput
            v-model="rowsToAdd"
            :value="rowsToAdd"
            v-bind="field"
            class="flex w-12"
            type="number"
            :placeholder="$t('events.table.add')"
            name="rowsToAdd"
            @input="rowValueChanged"
          />
        </Field>
        <div class="mx-2">
          {{ $t('events.table.rows') }}
        </div>
        <Button
          class="btn-add ml-4 px-2 h-8 flex items-center justify-center"
          variant="secondary"
          :label="$t('events.table.add')"
          :disabled="parseInt(rowsToAdd?.toString()) <= 0"
          @click="addRows"
        >
          <i class="bi bi-plus text-lg"></i>
        </Button>
      </div>
    </template>
  </DataTable>
</template>
<script lang="ts">
  import { computed, defineComponent, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import {
    ENDOFPERIOD,
    PERIODBEHAVIOR,
    ROUNDINGMETHOD,
    STARTOFPERIOD,
    TIMEUNIT,
    VestingForm,
    VestingPlanning,
    VESTINGTYPE
  } from '@/types'
  import { vestingTableColumns } from '@/utils/data'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { VESTINGVALUEUNIT } from '@/types/forms'
  import { formatNumber } from '@/utils'
  import { ElPopover } from 'element-plus'

  export default defineComponent({
    components: {
      DataTable,
      Multiselect,
      Field,
      Button,
      UplInput: Input,
      ElPopover
    },
    props: {
      vesting: {
        type: Object as () => VestingForm,
        required: true
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['removeItem', 'addNewItem', 'duplicateLine', 'fieldChanged'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const periods = ref<VestingPlanning[]>(props.vesting.vestingPeriods)
      const rowsToAdd = ref(1)
      const timesToDuplicate = ref(1)
      const startOfPeriods = ref(
        Object.keys(STARTOFPERIOD).map((x) => ({
          name: t(`startOfPeriods.${x}`),
          value: x,
          id: x
        }))
      )

      const fisrtStartOfPeriods = computed(() =>
        startOfPeriods.value.filter(
          (x) => x.id !== STARTOFPERIOD.LAST_PERIOD_END_PLUS_ONE
        )
      )
      const timeUnits = ref(
        Object.keys(TIMEUNIT).map((x) => ({
          name: t(`timeUnits.${x}`),
          value: x,
          id: x
        }))
      )
      const endOfPeriods = ref(
        Object.keys(ENDOFPERIOD).map((x) => ({
          name: t(`endOfPeriods.${x}`),
          value: x,
          id: x
        }))
      )
      const vestingTypes = Object.values(VESTINGTYPE).map((x) => ({
        name: t(`vestingTypes.${x}`),
        value: x,
        id: x
      }))
      const roundingMethods = ref(
        Object.keys(ROUNDINGMETHOD).map((x) => ({
          name: t(`roundingMethods.${x}`),
          value: x,
          id: x
        }))
      )

      const periodBehaviors = ref(
        Object.keys(PERIODBEHAVIOR).map((x) => ({
          name: t(`periodBehaviors.${x}`),
          value: x,
          id: x
        }))
      )

      const columns = computed(() => vestingTableColumns)

      const inputValueChanged = (value: any) => {
        timesToDuplicate.value = value.target.value
      }

      const rowValueChanged = (value: any) => {
        rowsToAdd.value = value.target.value
      }
      const calculateValue = () =>
        parseFloat(
          periods.value
            .reduce(
              (acc: number, p: any) =>
                (acc += parseFloat(p?.value?.toString() || '0') || 0),
              0
            )
            .toFixed(4)
        )

      const calculateGlobalUnit = () =>
        periods.value.reduce(
          (acc, p) => (acc += parseInt(p?.nbTimeUnits?.toString() || '0') || 0),
          0
        )
      const addRows = () => {
        emit('addNewItem', rowsToAdd.value || 1)
        rowsToAdd.value = 1
      }

      const duplicate = (index: number) => {
        emit('duplicateLine', index, timesToDuplicate.value)
        timesToDuplicate.value = 1
      }

      const removeRow = (row: any) => {
        emit('removeItem', row)
      }

      watch(props, (newProps) => {
        periods.value = (newProps.vesting.vestingPeriods || [])?.sort(
          (a, b) => a.order - b.order
        )

        rowsToAdd.value = 1
      })
      return {
        periods,
        columns,
        periodBehaviors,
        roundingMethods,
        vestingTypes,
        endOfPeriods,
        timeUnits,
        startOfPeriods,
        rowsToAdd,
        fisrtStartOfPeriods,
        addRows,
        removeRow,
        calculateValue,
        inputValueChanged,
        rowValueChanged,
        calculateGlobalUnit,
        duplicate,
        formatNumber,
        timesToDuplicate,
        VESTINGVALUEUNIT
      }
    }
  })
</script>
