<template>
  <div>
    <div
      v-for="c in contactsList"
      :key="c.id"
      class="border rounded border-secondary6 px-3 py-2 mb-2 cursor-pointer"
      @click.prevent.stop="navigate(c.id)"
    >
      <ContactBadge
        :id="c.id"
        class="profile-xs light"
        :use-picture="true"
        :contact="c"
        type="light"
      />
    </div>
    <VueEternalLoading :load="load" :isInitial="false">
      <template #loading>
        <p class="text-primary">
          {{ $t('global.loading') }}
        </p>
      </template>

      <template #no-more>
        <p class="text-primary">
          {{ $t('global.noMore') }}
        </p>
      </template>

      <template #no-results>
        <p></p>
      </template>
    </VueEternalLoading>
    <EmptyState
      v-if="!contactsList.length"
      class="d-block mx-auto py-20"
      :subtitle="$t('global.noData')"
    />
  </div>
</template>

<script lang="ts">
  import { watch, defineComponent, computed, ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import EmptyState from '@/components/EmptyState/index.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { LoadAction, VueEternalLoading } from '@ts-pro/vue-eternal-loading'

  export default defineComponent({
    components: { EmptyState, ContactBadge, VueEternalLoading },
    props: {
      search: { type: String, default: '' }
    },
    emits: ['dataCount', 'close', 'loading'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const loading = ref(false)

      const noMore = ref(false)
      const contactsList = ref<any[]>([])
      const getContacts = (search: string | null) => {
        emit('loading', 'contacts', true)

        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            name: search,
            limit: 10,
            offset: 0
          },
          onSuccess: (resp: any[]) => {
            contactsList.value = resp
            emit('loading', 'contacts', false)
          }
        })
      }

      const navigate = (cId: string) => {
        router.push({
          name: 'company.directory',
          params: { id: route.params.id, contactId: cId }
        })

        emit('close')
      }

      const load = async ({ loaded }: LoadAction) => {
        if (contactsList.value.length < 10) loaded(0, 0)
        emit('loading', 'contacts', true)
        console.log('dedededed')

        loading.value = true
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            name: props.search,
            offset: contactsList.value.length,
            limit: 10
          },
          onSuccess: (cts: any[]) => {
            if (cts.length) {
              contactsList.value.push(...(cts || []))

              contactsList.value = contactsList.value.reduce((acc, c) => {
                if (!acc.map((x: any) => x.id).includes(c.id)) acc.push(c)

                return acc
              }, [])
              loaded(contactsList.value.length, contactsList.value.length / 10)

              emit('dataCount', 'contacts', contactsList.value.length || 0)
            }
            emit('loading', 'contacts', false)
            loading.value = false
            noMore.value = cts?.length < 10
          }
        })
      }

      watch(
        () => props.search,
        (val) => {
          getContacts(val)
        }
      )

      watch(
        () => contactsList.value,
        (val) => {
          emit('dataCount', 'contacts', val?.length || 0)
          emit('loading', 'contacts', false)
        }
      )

      onMounted(() => {
        emit('dataCount', 'contacts', contactsList.value?.length || 0)
        emit('loading', 'contacts', false)
      })
      return { contactsList, noMore, navigate, load }
    }
  })
</script>
