import { createI18n } from 'vue-i18n'
import store from '@/store'
import { getFormStorage } from '@/services/Storage'
import { storageKeys } from '../../utils/global'

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  let messages = {}
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const data = locales(key)
      messages = { ...messages, [locale]: data }
    }
  })

  return messages
}
const [browserLang] = navigator.language.split('-')

const lang = getFormStorage(storageKeys.userLang) || browserLang
const i18n = createI18n({
  warnHtmlInMessage: 'off',
  locale: store ? store.getters['config/lang'] : lang,
  fallbackLocale: store ? store.getters['config/lang'] : lang,
  enableLegacy: true,
  messages: loadLocaleMessages()
})

export default i18n
