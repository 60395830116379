
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import EventCard from '@/components/Event/EventCard.vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { Button } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { Event } from '@/services/api/models'
  import moment from 'moment'
  import { Pagination } from '@/store/modules/Organization/models'
  import Loader from '@/components/Loader/index.vue'
  import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
  import Modal from '@/components/Modal/Modal.vue'

  export default defineComponent({
    name: 'CorporateEventTab',
    components: {
      EmptyState,
      EventCard,
      Loader,
      Button,
      VueEternalLoading,
      Modal
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object as () => any
      }
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const canDelete = ref(false)
      const loading = ref(false)
      const { filters } = toRefs<any>(props)
      const defaultFilters = computed(() => props.filters)
      const selectedCards = ref<string[]>([])
      const collapseAll = ref(false)
      const isDraft = ref(false)

      const handleToggle = (value: boolean) => {
        isDraft.value = value
        filters.value.isDraft = value

        getEvents()
      }

      const isLoading = ref(false)

      const events = computed(() =>
        store.getters['portfolio/events'].filter((item: Event) => item.type)
      )

      const eventsList = ref(
        store.getters['portfolio/events'].filter((item: Event) => item.type)
      )
      const pagination = computed<Pagination>(
        () => store.getters['portfolio/eventsPagination']
      )

      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )

      const load = async ({ loaded }: LoadAction) => {
        const data = { ...filters.value }
        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft
        const response: any = await store.dispatch('portfolio/LOAD_EVENTS', {
          filter: {
            startDate: '1970-01-01',
            endDate: moment().format('YYYY-MM-DD'),
            ...data
          },
          offset: eventsList.value.length
        })
        if (response && response.data) {
          eventsList.value.push(...response.data)
          loaded(response.data.length, pagination.value.currentPage)
        }
      }

      const submitDeletion = () => {
        Promise.all(selectedCards.value.map((id) => removeEvent(id))).then(
          (delEvents) => {
            getEvents()

            canDelete.value = false
            selectedCards.value = []
          }
        )
      }

      const removeEvent = (eventId: string) => {
        store.dispatch('portfolio/DELETE_EVENT', {
          companyId: route.params.id,
          eventId,
          onSuccess: () => {
            console.log()
          }
        })
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        const { check, id } = data
        if (check) {
          selectedCards.value.push(id)
        } else {
          selectedCards.value.splice(
            selectedCards.value.findIndex((item: string) => item === id),
            1
          )
        }
      }

      const listUpdated = () => {
        isLoading.value = true
        filters.value = {}

        getEvents()
      }

      const getEvents = async () => {
        await store.dispatch('portfolio/GET_EVENTS', {
          offset: 0,
          filter: {
            isDraft: isDraft.value,
            startDate: '1970-01-01',
            endDate: moment().format('YYYY-MM-DD'),
            ...filters.value
          },
          onSuccess: () => {
            isLoading.value = false
          }
        })
      }
      watch(filters, () => {
        isLoading.value = true
        getEvents()
      })

      watch(events, (val) => {
        eventsList.value = val
        isLoading.value = false
      })

      onMounted(() => {
        isLoading.value = true
        filters.value = {}
        // call api with filter here
        getEvents()
      })

      return {
        loading,
        eventsList,
        isLoading,
        noMore,
        events,
        selectedCards,
        collapseAll,
        canDelete,
        isDraft,
        listUpdated,
        defaultFilters,
        load,
        selectCard,
        handleToggle,
        submitDeletion
      }
    }
  })
