import * as yup from 'yup'
import {
  ENDOFPERIOD,
  STARTOFPERIOD,
  ROUNDINGMETHOD,
  TIMEUNIT,
  VESTINGTYPE
} from '@/types/forms'

const vestingPlanning = yup.object({
  startOfPeriod: yup
    .string()
    .oneOf(Object.values(STARTOFPERIOD), 'startOfPeriodRequired')
    .required('startOfPeriodRequired'),
  endOfPeriod: yup
    .string()
    .oneOf(Object.values(ENDOFPERIOD), 'endOfPeriodRequired')
    .required('endOfPeriodRequired'),
  order: yup.number().typeError('numberTypeError').positive('positive'),
  value: yup
    .number()
    .typeError('numberTypeError')
    .min(0)
    .required('vestingValueRequired'),
  nbTimeUnits: yup
    .number()
    .typeError('numberTypeError')
    .min(0)
    .required('nbTimeUnitsRequired'),
  roundingMethod: yup
    .string()
    .oneOf(Object.values(ROUNDINGMETHOD), 'roundingMethodRequired'),
  vestingType: yup
    .string()
    .oneOf(Object.values(VESTINGTYPE), 'vestingTypeRequired'),
  timeUnit: yup.string().oneOf(Object.values(TIMEUNIT), 'timeUnitRequired')
})

const reasonSchema = yup.object().shape({
  nvPercent: yup.number().typeError('numberTypeError'),
  vPercent: yup
    .number()
    .typeError('numberTypeError')
    .transform((value) => Number(value)),
  nvQuantity: yup
    .number()
    .typeError('numberTypeError')
    .transform((value) => Number(value)),
  vQuantity: yup
    .number()
    .typeError('numberTypeError')
    .transform((value) => Number(value)),
  vDaysCount: yup
    .number()
    .typeError('numberTypeError')
    .transform((value) => Number(value)),
  nvDaysCount: yup
    .number()
    .typeError('numberTypeError')
    .transform((value) => Number(value))
})
export const vestingSchema = yup.object({
  id: yup.string().nullable().default(null),
  name: yup.string().required('nameRequired'),
  vestingValueUnit: yup.string().required('vestingValueUnitRequired'),
  fractionReport: yup.boolean(),
  hasObjective: yup.boolean(),
  vestingPeriods: yup
    .array()
    .of(vestingPlanning)
    .min(1, 'vestingPeriodsRequired')
    .required('vestingPeriodsRequired'),
  endOfContractBehaviors: yup
    .object()
    .shape({
      resignation: reasonSchema,
      dismissal: reasonSchema,
      conventionalRupture: reasonSchema,
      inability: reasonSchema,
      death: reasonSchema,
      revocation: reasonSchema
    })
    .required('endOfContractBehaviorsRequired')
})
