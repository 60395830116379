<template>
  <div v-if="event">
    <div class="text-left">
      {{ $t('events.endsOn') }}:
      <span v-if="event && event.endDate" class="text-primary">{{
        moment.utc(event.endDate).format('L')
      }}</span>
      <span v-else class="text-primary">-</span>
    </div>
    <div
      v-if="event && event.contacts && event.contacts.length"
      class="flex flex-wrap"
    >
      <div
        v-for="contact in event.contacts"
        :key="contact.id"
        class="contact mr-2"
      >
        <router-link
          :target="`${company?.id ? '_blank' : ''}`"
          :to="{
            name: 'company.directory',
            params: {
              id: company?.id || $route.params.id,
              contactId: contact.id
            }
          }"
        >
          <ContactBadge
            class="p-1 my-3 m-0 border border-secondary5 rounded"
            :contact="contact"
            :details="false"
            type="light"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    VestingSuspension,
    VestingDelayProrogation,
    Company
  } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  export default defineComponent({
    name: 'VestingCard',
    components: { ContactBadge },
    props: {
      event: {
        type: Object as () =>
          | VestingSuspension
          | VestingDelayProrogation
          | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      }
    },
    setup() {
      return { moment }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
