import { company, auth } from '../endpoints'
import axios from '../axios'

import {
  InvestorContact,
  IndividualContact,
  ProfessionalContact
} from '../models/company'
import { CancelTokenSource } from 'axios'
let exericseCalendarCancelToken: CancelTokenSource | null = null
let exericseCalendarTotalCancelToken: CancelTokenSource | null = null
let contactsCancelToken: CancelTokenSource | null = null
let companyContactsCancelToken: CancelTokenSource | null = null

const getContacts = async (
  companyId: string,
  filter = { limit: 50, offset: 0 }
) => {
  if (typeof contactsCancelToken != undefined) {
    contactsCancelToken?.cancel('Operation canceled due to new request.')
  }
  contactsCancelToken = axios.CancelToken.source()
  const params = { ...filter }
  const { data } = await axios.get(company.contacts(companyId), {
    // cancelToken: contactsCancelToken.token,
    params
  })

  return data
}

const getManagedContacts = async (filter = { limit: 20, offset: 0 }) => {
  if (typeof companyContactsCancelToken != undefined) {
    companyContactsCancelToken?.cancel('Operation canceled due to new request.')
  }
  companyContactsCancelToken = axios.CancelToken.source()
  const params = { ...filter }
  const { data } = await axios.get(auth.accountContacts(), {
    cancelToken: companyContactsCancelToken.token,
    params
  })
  return data
}

const getContact = async (companyId: string, contactId: string) => {
  const { data } = await axios.get(company.singleContact(companyId, contactId))
  return data
}

const getContactImage = async (companyId: string, contactId: string) => {
  return await axios.get(company.singleContactPicture(companyId, contactId))
}

const getContactComputedData = async (companyId: string, contactId: string) => {
  return await axios.get(
    company.singleContactComputedData(companyId, contactId)
  )
}

const changeImage = async (
  companyId: string,
  form: FormData,
  contactId: string,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const resp = await axios.post(
    company.singleContactPicture(companyId, contactId),
    form,
    {
      onUploadProgress
    }
  )
  return resp
}

const getContactVesting = async (companyId: string, filter = { offset: 0 }) => {
  if (typeof exericseCalendarCancelToken != undefined) {
    exericseCalendarCancelToken?.cancel(
      'Operation canceled due to new request.'
    )
  }

  exericseCalendarCancelToken = axios.CancelToken.source()
  const response = await axios.get(company.exerciseCalendars(companyId), {
    // cancelToken: exericseCalendarCancelToken.token,
    params: { ...filter, offset: filter?.offset || 0 }
  })
  return response
}

const getExerciseCalendarsTotal = async (companyId: string, filter = {}) => {
  if (typeof exericseCalendarTotalCancelToken != undefined) {
    exericseCalendarTotalCancelToken?.cancel(
      'Operation canceled due to new request.'
    )
  }
  exericseCalendarTotalCancelToken = axios.CancelToken.source()
  const response = await axios.get(company.exerciseCalendarsTotal(companyId), {
    cancelToken: exericseCalendarTotalCancelToken?.token,
    params: { ...filter }
  })
  return response
}

const getExerciseCalendar = async (
  companyId: string,
  exerciseCalendarId: string
) => {
  const response = await axios.get(
    company.exerciseCalendar(companyId, exerciseCalendarId)
  )
  return response
}
const createContact = async (
  companyId: string,
  values: InvestorContact | IndividualContact | ProfessionalContact
): Promise<InvestorContact | IndividualContact | ProfessionalContact> => {
  const { data } = await axios.post(company.contacts(companyId), values)
  return data
}

const updateContact = async (
  companyId: string,
  contactId: string,
  values: InvestorContact | IndividualContact | ProfessionalContact
) => {
  return await axios.put(company.singleContact(companyId, contactId), values)
}

const deleteContact = async (companyId: string, contactId: string) => {
  return await axios.delete(company.singleContact(companyId, contactId))
}

export {
  deleteContact,
  updateContact,
  createContact,
  getContact,
  getContacts,
  getContactVesting,
  changeImage,
  getContactImage,
  getContactComputedData,
  getManagedContacts,
  getExerciseCalendarsTotal,
  getExerciseCalendar
}
