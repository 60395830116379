import conf from '@/config'
import { GlobalConfig } from '@/types'
import { AuditType, STOCKTYPESALIAS, AuditGetType } from '@/types/forms'

const config = conf as GlobalConfig
const apiHost = `${config.urls.apiHost}/`

export const auth = {
  profile(id: string): string {
    return `${config.urls.apiHost}/auth/user/${id}`
  },
  roles(id: string): string {
    return `${config.urls.apiHost}/auth/roles/${id}`
  },
  accountContacts(): string {
    return `${config.urls.apiHost}/auth/companies-contacts`
  },
  accountUsers(): string {
    return `${config.urls.apiHost}/auth/account-users`
  },
  picture(id: string, userId: string): string {
    return `${apiHost}companies/${id}/users/${userId}/picture`
  },
  password(id: string): string {
    return `${apiHost}auth/${id}/update-password`
  },
  definePassord(id: string): string {
    return `${apiHost}auth/user/${id}/define-password`
  },
  createNewPassord(id: string): string {
    return `${apiHost}auth/user/${id}/reset-password`
  },
  resetPassword(): string {
    return `${apiHost}auth/reset-password`
  },
  getUserByToken(): string {
    return `${apiHost}auth/verify-token`
  }
}

export const exportFiles = {
  transactionsExportPdf(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/transaction-pdf`
  },
  exerciseCalendars(id: string): string {
    return `${apiHost}companies/${id}/exercise-calendars/export`
  },
  delegations(id: string): string {
    return `${apiHost}companies/${id}/delegations/export`
  },
  appointments(id: string): string {
    return `${apiHost}companies/${id}/appointments/export`
  },
  transactions(id: string): string {
    return `${apiHost}companies/${id}/transactions/export`
  },
  captable(id: string): string {
    return `${apiHost}companies/${id}/transactions/captable`
  },
  associateRecord(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/contact-record`
  },
  delegationByHolder(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/delegations-holder`
  },
  exerciseCalendarsByOption(id: string): string {
    return `${apiHost}companies/${id}/exercise-calendars/export/synthetic-view`
  },
  captableExport(id: string): string {
    return `${apiHost}companies/${id}/cap-table/export`
  },
  captablePDFExport(id: string): string {
    return `${apiHost}companies/${id}/cap-table/export-pdf`
  },
  contactRecordExport(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/contact-register`
  }
}

export const company = {
  verifyStock(cmpId: string, id: string) {
    return `${apiHost}companies/${cmpId}/stock-alias/${id}`
  },
  capital(id: string): string {
    return `${apiHost}companies/${id}/share-capital`
  },
  capitalByGroup(id: string): string {
    return `${apiHost}companies/${id}/share-capital/contact-group`
  },
  capitalByStockCategory(id: string): string {
    return `${apiHost}companies/${id}/share-capital/stock-category`
  },
  capitalByEsopCategory(id: string): string {
    return `${apiHost}companies/${id}/share-capital/option-category`
  },
  getProfiltSharingEvolution(id: string): string {
    return `${apiHost}companies/${id}/share-capital/profit-sharing`
  },
  esopData(id: string): string {
    return `${apiHost}companies/${id}/share-capital/esop-data`
  },
  fundingData(id: string): string {
    return `${apiHost}companies/${id}/share-capital/funding`
  },
  profile(): string {
    return `${config.urls.apiHost}`
  },
  onboarding(): string {
    return `${apiHost}me/onboarding`
  },
  list(): string {
    return `${apiHost}companies`
  },
  create(): string {
    return `${apiHost}companies`
  },
  documents(id: string): string {
    return `${apiHost}companies/${id}/documents`
  },
  picture(id: string): string {
    return `${apiHost}companies/${id}/picture`
  },
  documentContacts(id: string, documentId: string): string {
    return `${apiHost}companies/${id}/documents/${documentId}/contacts`
  },
  getDocument(companyId: string, documentId: string): string {
    return `${apiHost}companies/${companyId}/documents/${documentId}`
  },
  get(id: string): string {
    return `${apiHost}companies/${id}`
  },
  bodac(id: string): string {
    return `${apiHost}companies/${id}/bodacc`
  },
  bodacUrl(id: string): string {
    return `${apiHost}companies/${id}/bodacc/pdf`
  },
  getTransactions(id: string): string {
    return `${apiHost}companies/${id}/transactions`
  },
  transactionsExport(id: string): string {
    return `${apiHost}companies/${id}/transactions/export`
  },
  transactionsExportPdf(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/transaction-pdf`
  },
  captableExport(id: string): string {
    return `${apiHost}companies/${id}/cap-table/export`
  },
  contactRecordExport(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/contact-register`
  },
  getDelegatedTransactions(id: string): string {
    return `${apiHost}companies/${id}/transactions/delegation`
  },
  nominalValue(id: string): string {
    return `${apiHost}companies/${id}/nominal-values`
  },
  singleNominalValue(id: string, valueId: string): string {
    return `${apiHost}companies/${id}/nominal-values/${valueId}`
  },
  uploadFiles(companyId: string): string {
    return `${apiHost}companies/${companyId}/documents`
  },
  searchInDocuments(companyId: string) {
    return `${apiHost}companies/${companyId}/full-text-search`
  },
  stockCreation(companyId: string, type: STOCKTYPESALIAS) {
    return `${apiHost}companies/${companyId}/${type}`
  },
  singleStockAlias(companyId: string, type: STOCKTYPESALIAS, stockId: string) {
    return `${apiHost}companies/${companyId}/${type}/${stockId}`
  },
  getStockAlias(companyId: string, type: STOCKTYPESALIAS) {
    return `${apiHost}companies/${companyId}/${type}`
  },
  singleCommittee(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/committees/${id}`
  },
  singleVesting(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/vesting-calendars/${id}`
  },
  createVesting(companyId: string) {
    return `${apiHost}companies/${companyId}/vesting-calendars`
  },
  freeSharesVestings(companyId: string) {
    return `${apiHost}companies/${companyId}/free-share-vesting-calendars`
  },
  freeSharesIssuance(companyId: string) {
    return `${apiHost}companies/${companyId}/free-shares-issuances`
  },
  freeSharesAcquisitionCalendars(companyId: string) {
    return `${apiHost}companies/${companyId}/free-shares-acquisition-calendars`
  },
  getFreeSharesIssuance(companyId: string, issuanceId: string) {
    return `${apiHost}companies/${companyId}/free-shares-issuances/${issuanceId}`
  },
  freeSharesVesting(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/free-share-vesting-calendars/${id}`
  },

  finalAcquisition(companyId: string) {
    return `${apiHost}companies/${companyId}/free-shares-final-acquisitions`
  },
  freeSharesSunsets(companyId: string) {
    return `${apiHost}companies/${companyId}/free-shares-sunsets`
  },
  createOptionSunset(companyId: string) {
    return `${apiHost}companies/${companyId}/option-sunsets`
  },
  singleOptionSunset(companyId: string, eventId: string) {
    return `${apiHost}companies/${companyId}/option-sunsets/${eventId}`
  },
  singleVestingSynthesis(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/vesting-syntheses/${id}`
  },
  getVestingSyntesis(companyId: string) {
    return `${apiHost}companies/${companyId}/vesting-syntheses`
  },
  contacts(companyId: string) {
    return `${apiHost}companies/${companyId}/contacts`
  },
  singleContact(companyId: string, contactId: string) {
    return `${apiHost}companies/${companyId}/contacts/${contactId}`
  },
  singleContactPicture(companyId: string, contactId: string) {
    return `${apiHost}companies/${companyId}/contacts/${contactId}/picture`
  },
  singleContactComputedData(companyId: string, contactId: string) {
    return `${apiHost}companies/${companyId}/contacts/${contactId}/computed-data`
  },
  audits(companyId: string, type: AuditType, typeId: string) {
    return `${apiHost}companies/${companyId}/${type}/${typeId}/audits`
  },
  audit(companyId: string, type: AuditType, typeId: string, auditId: string) {
    return `${apiHost}companies/${companyId}/${type}/${typeId}/audits/${auditId}`
  },
  bulkAudit(companyId: string, type: AuditGetType) {
    return `${apiHost}companies/${companyId}/${type}`
  },
  getAuditByType(companyId: string, type: AuditGetType) {
    return `${apiHost}companies/${companyId}/${type}`
  },
  getCeilings(companyId: string) {
    return `${apiHost}companies/${companyId}/common-ceilings`
  },
  ceiling(companyId: string, ceilingId: string) {
    return `${apiHost}companies/${companyId}/common-ceilings/${ceilingId}`
  },
  getDelegations(companyId: string) {
    return `${apiHost}companies/${companyId}/delegations`
  },
  delegation(companyId: string, delegationId: string) {
    return `${apiHost}companies/${companyId}/delegations/${delegationId}`
  },
  getPrimaryStocks(companyId: string) {
    return `${apiHost}companies/${companyId}/stock-issuances`
  },
  getStockIssuanceParts(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/stock-issuances/${id}/stock-issuance-parts`
  },
  getStockIssuancePart(companyId: string, id: string, partId: string) {
    return `${apiHost}companies/${companyId}/stock-issuances/${id}/stock-issuance-parts/${partId}`
  },
  getOptionIssuancePart(companyId: string, id: string, partId: string) {
    return `${apiHost}companies/${companyId}/option-issuances/${id}/option-issuance-parts/${partId}`
  },
  singlePrimaryStock(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/stock-issuances/${id}`
  },
  getPrimaryOptions(companyId: string) {
    return `${apiHost}companies/${companyId}/option-issuances`
  },
  singlePrimaryOption(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/option-issuances/${id}`
  },
  getPrimaryComposition(companyId: string) {
    return `${apiHost}companies/${companyId}/composition-issuances`
  },
  singlePrimaryComposition(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/composition-issuances/${id}`
  },
  getCommittees(companyId: string) {
    return `${apiHost}companies/${companyId}/committees`
  },
  getCaptable(companyId: string) {
    return `${apiHost}companies/${companyId}/cap-table`
  },
  getNominations(companyId: string) {
    return `${apiHost}companies/${companyId}/appointments`
  },
  nommination(companyId: string, appointmentId: string) {
    return `${apiHost}companies/${companyId}/appointments/${appointmentId}`
  },
  terminateAppointment(companyId: string) {
    return `${apiHost}companies/${companyId}/appointment-terminations`
  },
  singleTerminateAppointment(companyId: string, appointmentTermId: string) {
    return `${apiHost}companies/${companyId}/appointment-terminations/${appointmentTermId}`
  },
  exerciseCalendars(companyId: string): string {
    return `${apiHost}companies/${companyId}/exercise-calendars`
  },
  exerciseCalendarsTotal(companyId: string): string {
    return `${apiHost}companies/${companyId}/exercise-calendars/total`
  },
  exerciseCalendar(companyId: string, calendarId: string): string {
    return `${apiHost}companies/${companyId}/exercise-calendars/${calendarId}/get`
  },
  getTransfers(companyId: string) {
    return `${apiHost}companies/${companyId}/transfers`
  },
  transfer(companyId: string, transferId: string) {
    return `${apiHost}companies/${companyId}/transfers/${transferId}`
  },
  getVestingSuspension(companyId: string) {
    return `${apiHost}companies/${companyId}/vesting-suspensions`
  },
  singleVestingSuspension(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/vesting-suspensions/${id}`
  },
  getVestingAcceleration(companyId: string) {
    return `${apiHost}companies/${companyId}/vesting-accelerations`
  },
  singleVestingAcceleration(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/vesting-accelerations/${id}`
  },
  getVestingProrogation(companyId: string) {
    return `${apiHost}companies/${companyId}/exercise-delay-prorogrations`
  },
  singleVestingProrogation(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/exercise-delay-prorogrations/${id}`
  },
  getFunctions() {
    return `${apiHost}functions`
  },
  getGroups(companyId: string) {
    return `${apiHost}companies/${companyId}/contact-groups`
  },
  singleGroup(companyId: string, id: string): string {
    return `${apiHost}companies/${companyId}/contact-groups/${id}`
  },
  getStocksGroups(companyId: string) {
    return `${apiHost}companies/${companyId}/stock-groups`
  },
  singleStockGroup(companyId: string, id: string): string {
    return `${apiHost}companies/${companyId}/stock-groups/${id}`
  },
  users(id: string): string {
    return `${apiHost}companies/${id}/users`
  },
  exportHotjar(id: string): string {
    return `${apiHost}companies/${id}/option-sunsets/exports/get`
  },
  exportEvents(id: string): string {
    return `${apiHost}companies/${id}/option-sunsets/exports`
  },
  mail(id: string, userId: string): string {
    return `${apiHost}companies/${id}/users/${userId}/mail`
  },
  getUser(id: string, userId: string): string {
    return `${apiHost}companies/${id}/users/${userId}`
  },
  getUserCompanyAccount(id: string, userId: string, accountId: string): string {
    return `${apiHost}companies/${id}/users/${userId}/account/${accountId}`
  },
  getEvents(companyId: string) {
    return `${apiHost}companies/${companyId}/events`
  },
  searchEvents(companyId: string) {
    return `${apiHost}companies/${companyId}/events/text-search/get`
  },
  eventDocument(companyId: string, eventId: string, documentId: string) {
    return `${apiHost}companies/${companyId}/events/${eventId}/documents/${documentId}`
  },

  eventPartDocuments(
    type: string,
    partString: string,
    companyId: string,
    stockIssuanceId: string,
    stockIssuancePartId: string
  ) {
    return `${apiHost}companies/${companyId}/${type}/${stockIssuanceId}/${partString}/${stockIssuancePartId}/documents`
  },
  eventPartDocument(
    type: string,
    partString: string,
    companyId: string,
    stockIssuanceId: string,
    stockIssuancePartId: string,
    documentId: string
  ) {
    return `${apiHost}companies/${companyId}/${type}/${stockIssuanceId}/${partString}/${stockIssuancePartId}/documents/${documentId}`
  },

  eventDocuments(companyId: string, eventId: string) {
    return `${apiHost}companies/${companyId}/events/${eventId}/documents`
  },
  getEvent(companyId: string, eventId: string) {
    return `${apiHost}companies/${companyId}/events/${eventId}`
  },
  getFinancialEnding(companyId: string) {
    return `${apiHost}companies/${companyId}/financial-endings`
  },
  singleFinancialEnding(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/financial-endings/${id}`
  },
  getAccountApproval(companyId: string) {
    return `${apiHost}companies/${companyId}/account-approvals`
  },
  singleAccountApproval(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/account-approvals/${id}`
  },
  parityChanges(companyId: string) {
    return `${apiHost}companies/${companyId}/parity-changes`
  },
  singleParityChanges(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/parity-changes/${id}`
  },
  compositionDetachment(companyId: string) {
    return `${apiHost}companies/${companyId}/composition-detachment`
  },
  getConstitution(companyId: string) {
    return `${apiHost}companies/${companyId}/establishments`
  },
  capitalReductions(companyId: string) {
    return `${apiHost}companies/${companyId}/capital-reductions`
  },
  capitalReduction(companyId: string, reductionId: string) {
    return `${apiHost}companies/${companyId}/capital-reductions/${reductionId}`
  },
  conversions(companyId: string) {
    return `${apiHost}companies/${companyId}/conversions`
  },
  conversion(companyId: string, conversionId: string) {
    return `${apiHost}companies/${companyId}/conversions/${conversionId}`
  },
  optionsExercise(companyId: string) {
    return `${apiHost}companies/${companyId}/option-exercises`
  },
  singleOptionsExercise(companyId: string, eventId: string) {
    return `${apiHost}companies/${companyId}/option-exercises/${eventId}`
  },
  profitSharingPlan(companyId: string) {
    return `${apiHost}companies/${companyId}/profit-sharing-plan`
  },
  singleProfitSharingPlan(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/profit-sharing-plan/${id}`
  },
  fundraisingRound(companyId: string) {
    return `${apiHost}companies/${companyId}/financing-round`
  },
  singleFundraisingRound(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/financing-round/${id}`
  },
  operation(companyId: string) {
    return `${apiHost}companies/${companyId}/operations`
  },
  singleOperation(companyId: string, id: string) {
    return `${apiHost}companies/${companyId}/operations/${id}`
  },
  allAssociatesRecord(id: string): string {
    return `${apiHost}companies/${id}/transactions/export/all-contacts`
  },
  alerts(companyId: string) {
    return `${apiHost}companies/${companyId}/alerts`
  },
  alert(companyId: string, alertId: string) {
    return `${apiHost}companies/${companyId}/alerts/${alertId}`
  },
  alertSeen(companyId: string, alertId: string) {
    return `${apiHost}companies/${companyId}/alerts/${alertId}`
  },
  alertsConf(companyId: string) {
    return `${apiHost}companies/${companyId}/alert-settings`
  },
  alertConf(companyId: string, alertId: string) {
    return `${apiHost}companies/${companyId}/alert-settings/${alertId}`
  },

  getOrganigrams(companyId: string) {
    return `${apiHost}organigrams`
  }
}

export const toolbox = {
  searchCompanies(query: string | null): string {
    return `${apiHost}toolbox/companies/search/${query}`
  },
  companyInformations(siret: string): string {
    return `${apiHost}toolbox/companies/${siret}`
  },
  documentCategories(): string {
    return `${apiHost}doc-categories`
  },
  stockCategories(): string {
    return `${apiHost}stock-categories`
  },
  optionCategories(): string {
    return `${apiHost}option-category`
  },
  organCategories(): string {
    return `${apiHost}organ-categories`
  },
  contactGroups(): string {
    return `${apiHost}contact-statuses`
  },
  legalStatus(): string {
    return `${apiHost}legal-status`
  },

  roles(): string {
    return `${apiHost}roles`
  }
}

export const portfolio = {
  events(): string {
    return `${apiHost}portfolio/events`
  },
  eventDocuments(eventId: string): string {
    return `${apiHost}portfolio/events/${eventId}/documents`
  },
  contacts(): string {
    return `${apiHost}portfolio/contacts`
  },
  companies(): string {
    return `${apiHost}portfolio/companies`
  },
  event(id: string): string {
    return `${apiHost}portfolio/events/${id}`
  },
  document(id: string): string {
    return `${apiHost}portfolio/documents/${id}`
  },
  documents(): string {
    return `${apiHost}portfolio/documents`
  },
  charts(): string {
    return `${apiHost}portfolio/charts`
  },
  searchInDocuments() {
    return `${apiHost}portfolio/documents/full-text-search`
  }
}
