const getFormStorage = (key: string): string | null => {
  return sessionStorage.getItem(key)
}

const addToStorage = (key: string, value: string): void => {
  return sessionStorage.setItem(key, value)
}

const removeFromStorage = (key: string): void => {
  return sessionStorage.removeItem(key)
}

const clearStorage = (): void => {
  localStorage.clear()
  return sessionStorage.clear()
}

export { getFormStorage, clearStorage, removeFromStorage, addToStorage }
