
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { delegationHolderColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { formatNumber } from '@/utils/global'
  import moment from 'moment'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import {
    CompositionStockAlias,
    DelegationTransaction,
    OptionStockAlias,
    StockAlias
  } from '@/services/api/models'
  import { STOCKTYPE } from '@/types'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { useI18n } from 'vue-i18n'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      Field,
      Multiselect,
      DataTable,
      ContactBadge,
      EmptyState,
      Tag,
      ElTooltip,
      DownloadButton,
      DropdownStocks
    },
    setup() {
      const search = ref('')
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const selectedStocks = ref<string[]>([])
      const { t } = useI18n()
      const filter = ref<any>({
        contactId: '',
        delegationId: '',
        optionAliasesId: [],
        stockAliasesId: [],
        compositionAliasesId: []
      })
      const columns = computed(() => delegationHolderColumns)
      const delegatedTransactions = computed<DelegationTransaction[]>(
        () => store.getters['organizations/delegatedTransactions']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )
      const isDownloading = ref(false)

      const contacts = computed(() =>
        delegatedTransactions.value.map((x) => x?.associateAccount?.contact)
      )

      const stocks = computed(() => {
        const value = []
        const smp: any = {
          id: '1',
          name: t('companyStockType.SIMPLE'),
          label: t('companyStockType.SIMPLE'),
          children: []
        }

        const cmp: any = {
          id: '3',
          name: t('companyStockType.COMPOSED'),
          label: t('companyStockType.COMPOSED'),
          children: []
        }
        const cmb: any = {
          id: '2',
          name: t('companyStockType.COMBINED'),
          label: t('companyStockType.COMBINED'),
          children: []
        }

        delegatedTransactions.value.forEach((x) => {
          if (
            x.optionAlias &&
            !cmb.children
              .map((c: OptionStockAlias) => c.id)
              .includes(x.optionAlias?.id)
          )
            cmb.children.push({ ...x.optionAlias, name: x.optionAlias.alias })

          if (
            x.stockAlias &&
            !smp.children
              .map((c: StockAlias) => c.id)
              .includes(x.stockAlias?.id)
          )
            smp.children.push({ ...x.stockAlias, name: x.stockAlias.alias })

          if (
            x.compositionAlias &&
            !cmp.children
              .map((c: CompositionStockAlias) => c.id)
              .includes(x.compositionAlias?.id)
          )
            cmp.children.push({
              ...x.compositionAlias,
              name: x.compositionAlias.alias
            })
        })

        value.push(...[smp, cmp, cmb])

        return value
      })

      const pagination = computed(
        () => store.getters['organizations/delegTransactionsPagination']
      )

      const onPageChange = (page: number) => {
        store.commit(
          'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
          page
        )
        getTransactions()
      }
      const getEventName = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (exercise?.event) return exercise.event.type
        else return row.eventType
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const getEventId = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (exercise?.event) return exercise.event.id
        else return null
      }

      const updateStockFilter = ({ value }: any) => {
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }

      const getTransactions = () => {
        const data = filter.value
        if (!filter.value.contactId) delete data.contactId
        store.dispatch('organizations/GET_DELEGATIONS_TRANSACTIONS', {
          companyId: route.params.id,
          filter: filter.value
        })
      }

      const download = () => {
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_DELEGATIONS_BY_HOLDER', {
          name: `${t('exports.delegations')}`,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const getDelegations = () => {
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: {}
        })
      }
      onMounted(() => {
        store.commit(
          'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
          1
        )
        getTransactions()
      })

      watch(
        [
          () => filter.value.stockAliasesId,
          () => filter.value.optionAliasesId,
          () => filter.value.compositionAliasesId,
          () => filter.value.contactId,
          () => filter.value.delegationId
        ],
        () => {
          store.commit(
            'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
            1
          )
          getTransactions()
        }
      )

      watch(
        () => route.params.id,
        () => {
          store.commit(
            'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
            1
          )
          getDelegations()
          getTransactions()
        }
      )

      return {
        search,
        isDownloading,
        stocks,
        delegatedTransactions,
        columns,
        delegations,
        pagination,
        contacts,
        filter,
        download,
        selectedStocks,
        updateStockFilter,
        formatNumber,
        moment,
        getEventName,
        getEventId,
        onPageChange,
        gotoEvent
      }
    }
  })
