<template>
  <div class="emptystate__wrapper">
    <img
      v-if="image"
      :src="require(`@/assets/images/illustrations/${image}`)"
    />
    <Spinner v-if="loading" class="xl" />
    <h2 v-if="title">
      {{ title }}
    </h2>
    <h5 v-if="subtitle">
      {{ subtitle }}
    </h5>
  </div>
</template>

<script lang="ts">
  import Spinner from '@/components/Spinner/index.vue'

  export default {
    name: 'EmptyState',
    components: { Spinner },
    props: {
      image: {
        type: String,
        required: false,
        default: ''
      },
      loading: Boolean,
      title: {
        type: String,
        required: false,
        default: ''
      },
      subtitle: {
        type: String,
        required: false,
        default: ''
      },
      hasLabel: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .emptystate__wrapper {
    max-width: 350px;

    img {
      width: 210px;
      display: block;
      margin: 0 auto;
    }

    .upl-spinner {
      display: block;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
      padding-top: 28px;
      color: var(--color-primary);
    }

    h5 {
      text-align: center;
      padding-bottom: 28px;
      color: var(--color-contrast-50);
    }
  }
  .emptystate__wrapper.datatable {
    width: 100%;
    background-color: var(--color-contrast-10);
    margin: 0 !important;
    max-width: none;
    border-radius: var(--radius-m);
  }
</style>
