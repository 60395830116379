
  import { defineComponent, toRefs, computed } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { isNumber } from 'lodash'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import {
    CapitalReduction,
    Company,
    NominalValue
  } from '@/services/api/models'

  export default defineComponent({
    name: 'ReductionCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => CapitalReduction | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { event } = toRefs(props)
      const store = useStore()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.issuanceDate).unix() >
            moment.utc(x.date).unix()
        )
        return nv?.nominalValue
      })

      const parts = computed(() => props.event?.capitalReductionParts || [])

      const totalQuantity = computed(() => {
        return parts.value.reduce(
          (total: number, p: any) => (total += p.quantity),
          0
        )
      })
      const globalReduction = computed(() => {
        return parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      })

      const globalReleased = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseFloat(line.releasedAmount || 0)),
          0
        )
      )
      const globalEmit = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              (parseFloat(line.totalPrice) / parseInt(line.quantity || 0) -
                nominalValue.value) *
              parseInt(line.quantity || 0)),
          0
        )
      )

      const globalIssuancePerAction = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              parseInt(line.totalPrice) / parseInt(line.quantity || 0) -
              nominalValue.value),
          0
        )
      )
      const globalNominalIncrease = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )
      const globalTotal = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) => (acc += parseFloat(line.totalPrice || 0)),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value = parseFloat(globalTotal.value.toString()) / totalQuantity.value
        }
        return value
      })

      const calculateUnitPrice = (row: any) => {
        if (!row.totalPrice || !row.quantity) return 0
        return row.totalPrice / row.quantity
      }

      const emitPerAction = (row: any) => {
        const unitPrice = calculateUnitPrice(row)

        if (!nominalValue.value || !isNumber(unitPrice)) {
          return '-'
        }

        return Math.abs(unitPrice - nominalValue.value)
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseFloat(ePerAction.toString()) * row.quantity
      }

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      return {
        currency,
        nominalValue,
        parts,
        moment,
        globalReduction,
        totalQuantity,
        globalReleased,
        globalUnitPrice,
        globalNominalIncrease,
        globalIssuancePerAction,
        globalEmit,
        contactsId,
        globalTotal,
        formatNumber,
        calculateUnitPrice,
        emitPerAction,
        emitTotal,
        augNominal
      }
    }
  })
