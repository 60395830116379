
  import { defineComponent, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import FileInput from '../FileInput/index.vue'
  import { Field } from 'vee-validate'
  import { Input, Button, UplTheme } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { ElProgress } from 'element-plus'

  export default defineComponent({
    name: 'DocumentUpdate',
    components: {
      FileInput,
      Field,
      Input,
      ElProgress,
      Button
    },
    props: {
      documentId: { type: String, default: null }
    },
    emits: ['updated'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const replaceLabel = ref(t('fileLoader.loadFile'))
      const fileLabel = ref('')
      const selectedFile = ref<File | null>(null)

      const uploadResponse = ref({ error: false, success: false, progress: 0 })

      const handleFilesSelection = (files: File[]) => {
        const [file] = files
        replaceLabel.value = file.name
        fileLabel.value = file.name.replace('.pdf', '')
        selectedFile.value = file
      }

      const init = () => {
        uploadResponse.value = { error: false, success: false, progress: 0 }
        selectedFile.value = null
        replaceLabel.value = t('fileLoader.loadFile')
        fileLabel.value = ''
        emit('updated')
      }

      const replaceDocument = () => {
        if (!selectedFile.value) return

        const form = new FormData()

        form.append('file', selectedFile.value)
        form.append('fileName', selectedFile.value.name)
        form.append('label', fileLabel.value)

        store.dispatch(`organizations/REPLACE_COMPANY_DOCUMENT`, {
          form,
          companyId: route.params.id,
          documentId: props.documentId,
          onError: (err: string) => {
            console.log(err)
            uploadResponse.value.error = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('fileLoader.title'),
              message: err || t('fileLoader.replaceErrorMessage')
            })
          },
          onSuccess: (res: string) => {
            console.log(res)
            uploadResponse.value.success = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('fileLoader.title'),
              message: t('fileLoader.replaceSuccessMessage')
            })
          },
          progress: (pEvent: ProgressEvent) => {
            const percentCompleted =
              Math.round(pEvent.loaded * 100) / pEvent.total
            uploadResponse.value.progress = percentCompleted
          }
        })
      }

      return {
        selectedFile,
        replaceLabel,
        fileLabel,
        uploadResponse,
        UplTheme,
        handleFilesSelection,
        replaceDocument,
        init
      }
    }
  })
