<template>
  <div class="px-4 py-5 md:w-full">
    <div class="flex justify-between lg:w-3/4">
      <h1 class="text-left text-secondary9">
        {{ $t('account.accountInformations.chart') }}
      </h1>
    </div>
    <div class="flex text-xl">
      <Button class="mr-2" @click="download">Télécharger l'organigramme</Button>
      <Button @click="edit">Editer l'organigramme</Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { Button } from '@up.law/uplaw-ui'
  import { defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    name: 'AccountInformations',

    components: {
      Button
    },
    setup() {
      const { t } = useI18n()

      const download = () => {
        const url = `https://publicdata345.s3.eu-west-3.amazonaws.com/Capture%20d%E2%80%99e%CC%81cran%202022-12-14%20a%CC%80%2013.44.39.png?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEK3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMyJHMEUCICYKpBFTIHlyOeBiWNsI1rEWXabUtY2BL6JAbrJSzViVAiEAt4dUEPHOZTeu8Ocqiir5uzn%2FYkCL%2FJ4QShFmKLBD6wgq7QII1v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAEGgwzMDgxNzIyNTEzMTkiDAAIYEtubB%2Fmc0OfJirBAjUUhTX8dAGf3VnuUzo%2FGUlVqkmO6F4AZxUqa%2BjqPAz5pQYUkJsPuoWiU9pPifYUd7QjqYHA0aYTnigwKgfsdS3vp0MrDdtxFHG76sew0YOCON2htj60lgV1MDzc%2B3EWaTBXMUns1wneY9Zlgoni8xX5KnBPHYIvzL046BebubQY28ziLiJg71K4%2F4BZE5ja1sVlawcI%2FkUbL0D3AABsdGJjnCJVbNCbWvDZMBi8PZY5BXQ5PbIZJxCDmq8tQfRcc1pyLhnIUI14Bq9IL6%2FenitkY747H%2BRz67JRNPYwSlhB7Xsby9a8YsHs2K3G11H0A3ecAq5S1EHFeox8cOYrb8MdPsNh3WXIJia0Dk0OqW1z5CcmumaLADq1O39nObH6J5u%2F7DHiOU9rZy9WKEeF4kvSf2HkQJNNMU8pYi3B91dhjDDokuecBjqzAgQOmlNjDQCoUV9Fcn8DS92D4pq5FBgDgoCAgPVoZoJDwUeDuwLNPSL%2Fy84V%2FIAyuwDQMU3RWZfRodDt6Oo7garqexplv6HZbXEuakFeEQ7nFWsgX1ia3ob9II79KqUcakD1nUP4Qvh8X1TdSRd8XHOdnhltrowDwJvFb0ggTBCiEoqcDze7KL1SKzfG%2FTRxolMp3WvPO%2FLpZ1b1BFkgYsXh6%2BwZAjVkm29I8m1%2F561KKq5Gf2JJ3Gmmudo7yNlFlBmDiwlIAySlLeLFnECS%2BXeKcYu%2BQnRJtAddpCwtQtNzKtr0sECnmb%2BgjyLzC6AKm%2F3bhnQSfvoiH3gSTvpmJNdZ1ejC3xeHEfhBCfduSUNoyk9FptNKR0Nq4MWNmwyOW4pT8SycUuQI3rCqUYo0V%2B8Ghps%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221214T130630Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAUPQD7MC3WJCGPYFT%2F20221214%2Feu-west-3%2Fs3%2Faws4_request&X-Amz-Signature=2088494f2395d2172d614563ff0fe101b7b6c200be68ad2a2e8a59ac8fca52b4`
        const a: any = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

      const edit = () => {
        const url = `https://cloud.smartdraw.com/share.aspx/?pubDocShare=321BF674F705089CBDA6CC20C21F074A69C`
        const a: any = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

      return {
        download,
        edit
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/informations.scss';
</style>
