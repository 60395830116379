<template>
  <div :class="[{ 'contactCard--extended': noPadding }, ' contactCard']">
    <div class="contactCard__visual">
      <div
        v-if="isLoadingImage"
        class="flex justify-center items-center w-full h-full"
      >
        <svg
          id="L4"
          style="
            -webkit-transform: translateX(25%);
            -ms-transform: translateX(25%);
            transform: translateX(25%);
          "
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          xml:space="preserve"
        >
          <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
      <img class="object-cover w-full h-full" v-else :src="imageUrl" />
    </div>

    <div class="contactCard__content">
      <div class="contactCard__content__information_lvl-1">
        <div class="flex flex-col items-start">
          <h2 class="contactCard__content__information_lvl-1__name capitalize">
            {{ contact.name }}
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('tooltips.contactInfo')"
              placement="top"
            >
              <span class="ml-2">
                <i
                  :class="`bi bi-${
                    display ? 'info-circle' : 'info-circle-fill'
                  } text-xl text-primary4`"
                  @click="display = !display"
                ></i>
              </span>
            </el-tooltip>
          </h2>
          <div
            v-if="contact.group"
            class="contactCard__content__information_lvl-1__role"
          >
            {{ contact.group.name }}
          </div>
        </div>
        <ActionList
          v-can.any="['admin', 'write-contacts', 'employee-writeContact']"
          class="right h-1/4 p-2"
          :actions="actionsListItems"
          @on-click="handleAction"
          iconClass="bi-three-dots text-md text-primary3"
        />
      </div>

      <transition name="collapse">
        <div v-show="display" class="contactCard__content__information_lvl-2">
          <div
            class="
              row
              contactCard__content__information_lvl-2__infos
              flex
              justify-between
              w-5/6
            "
          >
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.nationality') }}</label>
              <p v-if="contact.nationality">
                {{ contact.nationality }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.birthDate') }}</label>
              <p v-if="contact.birthDate" class="text">
                {{ moment.utc(contact.birthDate).format('LL') }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.birthPlace') }}</label>
              <p v-if="contact.birthPlace">
                {{ contact.birthPlace }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.address') }}</label>
              <p v-if="contact.address">
                {{ contact.address }} - {{ contact.postcode }}
                {{ contact.city }}
              </p>
              <i v-else class="bi-dash" />
            </div>
          </div>

          <div
            class="
              row
              contactCard__content__information_lvl-2__infos
              contactCard__content__information_lvl-2__infos
              flex
              justify-between
              w-1/2
            "
          >
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.maritalStatus') }}</label>
              <p v-if="contact.familySituation">
                {{ $t(`contactCard.${contact.familySituation}`) }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.phoneNumber') }}</label>
              <p v-if="contact.phoneNumber">
                {{ contact.phoneNumber }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.email') }}</label>
              <p v-if="contact.email">
                <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
              </p>
              <i v-else class="bi-dash" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
  import { useStore } from 'vuex'
  import ActionList from '@/components/ActionList/index.vue'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import { CompanyModel } from '@/services/api/models'
  import { useRoute, useRouter } from 'vue-router'
  import { ActionListItem, MAINACTIONS } from '@/types'
  import { useAcl } from 'vue-simple-acl/src'
  import { ElTooltip } from 'element-plus'
  export default defineComponent({
    name: 'ContactCard',
    components: {
      ElTooltip,
      ActionList
    },
    directives: {},
    props: {
      contact: {
        type: Object,
        required: true
      },
      noPadding: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['delete-contact'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const isLoadingImage = ref(false)
      const url = ref()
      const imageUrl = computed(
        () => `${url.value || require('@/assets/images/profile/user.png')}`
      )
      const display = ref(false)
      const acl = useAcl()
      const canDelete = computed(
        () =>
          acl.role('admin') ||
          acl.permission('is-manager') ||
          acl.permission('write-contacts')
      )
      const actionsListItems = ref<ActionListItem[]>([
        {
          name: t('global.actions.edit'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.EDIT_CONTACT,
          type: ''
        }
      ])

      if (canDelete.value) {
        actionsListItems.value.push({
          name: t('global.actions.delete'),
          action: 'delete',
          children: [],
          icon: '',
          key: MAINACTIONS.DELETE_CONTACT,
          type: ''
        })
      }

      const openContactModal = () => {
        router.push({
          name: 'company.contact',
          params: {
            id: currentCompany.value.id,
            contactId: route.params.contactId
          }
        })
      }
      const handleAction = (key: MAINACTIONS) => {
        if (key === MAINACTIONS.EDIT_CONTACT) {
          openContactModal()
        } else if (key === MAINACTIONS.DELETE_CONTACT) {
          deleteContact()
        }
      }
      const loadImage = async () => {
        url.value = ''
        isLoadingImage.value = true
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_PICTURE',
          {
            companyId: currentCompany.value.id,
            contactId: props.contact.id
          }
        )
        if (response) url.value = response
        else url.value = ''
        isLoadingImage.value = false
      }

      const deleteContact = () => {
        emit('delete-contact', props.contact.id)
      }
      watch(
        () => props.contact,
        async () => {
          await loadImage()
        }
      )
      onBeforeMount(async () => {
        await loadImage()
      })
      return {
        moment,
        url,
        imageUrl,
        isLoadingImage,
        actionsListItems,
        display,
        handleAction
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'contactCard.scss';
</style>
