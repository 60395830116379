
  import { defineComponent } from 'vue'
  import { truncText } from '@/utils'
  import { ElPopover } from 'element-plus'

  export default defineComponent({
    name: 'UplLongText',
    components: { ElPopover },
    props: {
      maxLenght: {
        type: Number,
        required: false,
        default: 25
      },
      text: {
        type: String,
        required: true,
        default: ''
      },
      tag: {
        type: String,
        required: false,
        default: 'h5'
      },
      classes: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup() {
      return { truncText }
    }
  })
