<template>
  <section class="settings__section mt-4">
    <h3 class="mt-5 mb-2">{{ $t('global.globalParameters') }}</h3>
    <div class="grid gap-2 grid-cols-2">
      <div class="flex justify-start items-center">
        <Field name="inNFD">
          <Checkbox
            v-model="fields.inNFD"
            :checked="fields.inNFD"
            name="inNFD"
            :disabled="disabled.inNFD"
            :label="`${$t('global.includeInNFD')} ?`"
            @onChange="handleToggle"
        /></Field>
      </div>
      <div class="flex justify-start items-center">
        <Field name="inFD">
          <Checkbox
            v-model="fields.inFD"
            :checked="fields.inFD"
            name="inFD"
            :disabled="disabled.inFD"
            :label="`${$t('global.includeInFD')} ?`"
            @onChange="handleToggle"
          />
        </Field>
      </div>
      <div class="flex justify-start items-center">
        <Field name="inESOP">
          <Checkbox
            v-model="fields.inESOP"
            :checked="fields.inESOP"
            name="inESOP"
            :disabled="disabled.inESOP"
            :label="`${$t('global.includeInEsop')} ?`"
            @onChange="handleToggle"
          />
        </Field>
      </div>
      <div class="flex justify-start items-center">
        <Field name="inValuation">
          <Checkbox
            v-model="fields.inValuation"
            :checked="fields.inValuation"
            name="inValuation"
            :disabled="disabled.inValuation"
            :label="`${$t('global.includeInValuation')} ?`"
            @onChange="handleToggle"
          />
        </Field>
      </div>

      <div
        v-show="stockType == STOCKTYPE.OPTION"
        class="flex justify-start items-center"
      >
        <Field name="notInTransactionsRegister">
          <Checkbox
            v-model="fields.notInTransactionsRegister"
            :checked="fields.notInTransactionsRegister"
            name="notInTransactionsRegister"
            :disabled="false"
            :label="`${$t('global.notInTransactionsRegister')}`"
            @onChange="handleToggle"
          />
        </Field>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { StockToggleFields, STOCKTYPE } from '@/types'
  import { useStore } from 'vuex'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    components: { Field, Checkbox },
    props: {
      stockType: {
        type: Object as () => STOCKTYPE,
        required: true
      },
      values: {
        type: Object as () => StockToggleFields,
        required: true
      },
      disabled: {
        type: Object as () => StockToggleFields,
        required: true
      }
    },
    emits: ['onChange'],
    setup(props, { emit }) {
      const store = useStore()
      const disabledFields = computed(() => props.disabled)
      const resetFields = () => ({
        inESOP: false,
        inFD: true,
        inNFD: true,
        inValuation: true,
        notInTransactionsRegister: false
      })
      const fields = ref<StockToggleFields>(props.values)

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      watch(mustInitForm, (val) => {
        if (val) fields.value = resetFields()
      })

      watch(props, (newProps) => {
        fields.value = { ...newProps.values }
      })

      onMounted(() => {
        emit('onChange', props.values)
      })

      const handleToggle = (value: boolean, field: string) => {
        ;(fields.value as any)[field] = value
        emit('onChange', fields.value)
      }

      return { fields, disabledFields, STOCKTYPE, handleToggle }
    }
  })
</script>
