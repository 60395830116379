<template>
  <div class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.option') }}
      </h1>
      <p v-if="event?.optionAlias" class="text-blackp text-sm text-left">
        <Tag
          :text="event?.optionAlias.alias.toUpperCase()"
          :value="event?.optionAlias.id"
          :with-initials="false"
          :with-remove="false"
        />
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.parity') }}
      </h1>
      <p v-if="event?.parity" class="text-blackp text-sm text-left">
        {{ event?.parity }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.parityType') }}
      </h1>
      <p v-if="event?.parityType" class="text-blackp text-sm text-left">
        {{ $t(`global.${event?.parityType.toLowerCase()}`) }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { ParityChange } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import { Tag } from '@up.law/uplaw-ui'

  export default defineComponent({
    name: 'ParityChange',
    components: { Tag },
    props: {
      event: {
        type: Object as () => ParityChange | undefined,
        required: true
      }
    },
    setup() {
      return {}
    }
  })
</script>
<style lang="scss">
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
