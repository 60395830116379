<template>
  <transition name="fade">
    <div
      v-if="isModalOpen"
      id="modal_container"
      class="w-full h-full fixed top-0 left-0 flex"
    >
      <div
        class="
          modal_overlay
          transition-shadow
          w-full
          h-full
          z-0
          absolute
          top-0
          left-0
          right-0
          z-1
        "
        @click="toggleModal(false)"
      ></div>
      <div
        :class="`${fitPageSize ? 'full-' : ''}modal-container transition-all`"
      >
        <div class="flex modal-toggle">
          <div class="modal-toggle-item close_modal fit" @click="fitPage()">
            <i
              :class="`bi bi-arrows-angle-${
                fitPageSize ? 'contract' : 'expand'
              }`"
            />
          </div>
          <div
            class="modal-toggle-item close_modal close"
            @click="toggleModal(false)"
          >
            <i class="bi-x xxl" />
          </div>
        </div>

        <div class="relative z-0 flex h-full w-full bg-white">
          <div
            :class="`modal_sidebar p-4 w-1/4 relative text-md transition-all duration-100 ease-in-out ease-linear ${
              hideSidebar ? 'hidden' : 'flex'
            }`"
          >
            <div class="sidebar_header h-full w-full">
              <slot name="menu" />
            </div>
          </div>
          <div
            class="
              bg-main1
              modal_content
              w-full
              pt-5
              relative
              transition-all
              duration-100
            "
          >
            <div
              class="
                content_header
                px-4
                relative
                z-10
                h-auto
                flex
                items-center
                justify-start
              "
            >
              <div
                class="
                  bg-secondary5
                  px-3
                  py-2
                  rounded
                  mr-3
                  hover:cursor-pointer
                "
                @click="toggleSidebar"
              >
                <i class="bi bi-chevron-bar-left text-lg"></i>
              </div>
              <h3 class="text-xl"><slot name="title" /></h3>
            </div>
            <div class="px-4 pt-3 overflow-scroll h-full scroller pb-28">
              <slot name="content" />
            </div>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
  import { mediumScreenBreakpointWidth } from '@/utils'
  import { defineComponent, computed, ref } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'UplModal',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      },
      onModalClose: {
        type: Function,
        required: false
      }
    },
    setup(props) {
      const store = useStore()
      const hideSidebar = ref(false)
      const fitPageSize = ref<boolean>(
        window.innerWidth <= mediumScreenBreakpointWidth
      )
      const toggleSidebar = () => (hideSidebar.value = !hideSidebar.value)
      const isModalOpen = computed(() => props.isOpen || false)

      const toggleModal = (display: boolean) => {
        hideSidebar.value = false
        fitPageSize.value = window.innerWidth <= mediumScreenBreakpointWidth
        store.commit('organizations/SET_CURRENT_CONTACT', null)
        store.commit('organizations/SET_CURRENT_VESTING', null)
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
        store.commit('organizations/SET_PROFIT_SHARING_PLAN', null)
        store.commit('organizations/SET_FUND_RAISING_ROUND', null)
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('ui/toggleModal', { display })
      }

      const fitPage = () => {
        fitPageSize.value = !fitPageSize.value
      }

      return {
        isModalOpen,
        hideSidebar,
        fitPageSize,
        toggleSidebar,
        toggleModal,
        fitPage
      }
    }
  })
</script>

<style lang="scss">
  @import 'modal.scss';
  #modal_container {
    z-index: 999;
  }
  .scroller::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scroller {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
