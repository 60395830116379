<template>
  <div class="flex flex-col w-full audits">
    <div class="my-3">
      <Filter
        :selected-rows="selectedRows.length > 0"
        @filter-change="selectedStatus = $event"
        @update-status="updateStatus"
      />
    </div>
    <div v-if="data.length" class="mt-2 text-sm">
      <DataTable
        :has-checkbox="true"
        :columns="columns"
        :rows="data"
        :checked-rows="selectedRows"
        :pagination="pagination"
        @on-select="auditSelected"
        @on-page-change="onPageChange"
      >
        <template #createdAt="{ row }">
          <p v-if="row.createdAt" class="text-left">
            {{ moment.utc(row.createdAt).format('L') }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #motive="{ row }">
          <div v-if="row.motive" class="flex flex-wrap">
            <span
              v-for="m in row.motive.split(',')"
              :key="m"
              class="border border-warning text-warning rounded px-1 m-1"
            >
              {{ $t(`audits.labels.${m}`) }}
            </span>
          </div>
          <span v-else>-</span>
        </template>
        <template #comment="{ row }">
          <LongText :text="row.comment" />
        </template>

        <template #processed="{ row }">
          <span
            :class="`border border-${classes[row.status]} text-${
              classes[row.status]
            } rounded px-2 m-1`"
          >
            {{ $t(`auditStatus.${row.status}`) }}
          </span>
        </template>

        <template #actions="slotProps">
          <div class="flex">
            <Button
              variant="secondary"
              size="medium"
              class="p-1 rounded-sm cursor-pointer audit-btn"
              label=""
              @click="openContactModal(slotProps.row)"
            >
              <i class="bi bi-files text-xl"></i>
            </Button>
          </div>
        </template>
      </DataTable>
    </div>
    <div
      v-else
      class="
        flex
        justify-center
        items-center
        w-full
        mt-4
        transition transition-all
      "
    >
      <EmptyState
        image="no-result.svg"
        class="d-block mx-auto"
        :title="$t('global.noResult')"
        :subtitle="$t('global.noData')"
      />
    </div>
  </div>
</template>
<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import LongText from '@/components/LongText/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import { CompanyModel, ContactAudit } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import {
    AuditGetType,
    AuditStatusType,
    AuditType,
    MAINACTIONS
  } from '@/types'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import Filter from '@/views/Company/audit/filter.vue'
  import { TYPE } from 'vue-toastification'
  import EmptyState from '@/components/EmptyState/index.vue'

  export default defineComponent({
    components: { DataTable, LongText, Button, Filter, EmptyState },
    props: {},
    setup() {
      const isProcessed = ref(false)
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const selectedRows = ref<string[]>([])
      const selectedStatus = ref('')
      const data = computed(() => store.getters['organizations/contactAudits'])
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const classes = ref<any>({
        PENDING: 'warning',
        IN_PROGRESS: 'primary',
        DONE: 'success',
        CANCELLED: 'error'
      })
      const pagination = ref(store.getters['organizations/auditPagination'])
      const columns = [
        {
          label: t(`datatable.column.date`),
          custom: true,
          field: 'createdAt'
        },
        { label: t(`datatable.column.motive`), custom: true, field: 'motive' },
        {
          label: t(`datatable.column.comment`),
          custom: true,
          field: 'comment'
        },
        {
          label: t(`datatable.column.status`),
          custom: true,
          field: 'processed'
        },
        { label: '', custom: true, field: 'actions', width: '3%' }
      ]

      const auditSelected = (row: string[]) => {
        selectedRows.value = row
      }
      const updateStatus = (status: AuditStatusType) => {
        updateAuditStatus(selectedRows.value, status, AuditGetType.CONTACT)
      }

      const updateAuditStatus = (
        ids: string[],
        status: AuditStatusType,
        type: AuditGetType
      ) => {
        store.dispatch('organizations/UPDATE_AUDIT_STATUS', {
          companyId: currentCompany.value.id,
          type,
          data: { auditsId: ids, status },
          onSuccess: () => {
            selectedRows.value = []
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.context'),
              message: t(`audits.editSuccess`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.context'),
              message: t(`audits.editError`)
            })
          }
        })
      }

      const openContactModal = (data: ContactAudit) => {
        store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: currentCompany.value.id,
          contactId: data.contact.id
        })
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.CONTACT
        })
      }

      const markAsProcessed = (data: ContactAudit) => {
        const formData = {
          processed: true,
          comment: data.comment,
          motive: data.motive
        }
        store.dispatch('organizations/UPDATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.CONTACT,
          typeDataId: data.contact.id,
          auditId: data.id,
          formData,
          onSuccess: (audit: ContactAudit) => {
            console.log()
          },
          onError: () => {
            console.log('ERROR CREATE_AUDIT')
          }
        })
      }

      const onPageChange = (page: number) => {
        getAudits(page)
      }

      const getAudits = (page = 1) => {
        const filter: any = {}
        if (isProcessed.value) filter['isProcessed'] = isProcessed.value
        else delete filter['isProcessed']
        store.commit('organizations/SET_AUDIT_PAGINATION', page)
        store.dispatch('organizations/GET_CONTACT_AUDITS', {
          companyId: route.params.id,
          filter: {},
          onSuccess: (list: any) => {
            pagination.value = {
              total: list.metadata.count,
              currentPage: list.metadata.currentPage,
              lastPage: list.metadata.lastPage,
              limit: 10
            }
          }
        })
      }

      onMounted(() => {
        getAudits()
      })

      watch(isProcessed, () => {
        getAudits()
      })

      return {
        columns,
        moment,
        data,
        pagination,
        selectedStatus,
        classes,
        updateStatus,
        auditSelected,
        selectedRows,
        openContactModal,
        onPageChange,
        markAsProcessed
      }
    }
  })
</script>
<style lang="scss">
  .audits {
    .audit-btn {
      border-radius: 5px !important;
    }
  }
</style>
