<template>
  <div v-if="event" class="flex">
    <div class="w-2/12 p-3 flex items-end">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.transferor') }}
      </h1>
    </div>
    <div class="w-2/12 p-3 flex items-end">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.subscriber') }}
      </h1>
    </div>
    <div class="w-2/12 p-3 flex items-end">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.stock') }}
      </h1>
    </div>
    <div class="w-2/12 p-3 flex items-end">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.quantity') }}
      </h1>
    </div>

    <div class="w-2/12 p-3 flex items-end">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.elements.unitPrice') }}
      </h1>
    </div>

    <div class="w-2/12 p-3 flex items-end">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.salePrice') }}
      </h1>
    </div>
  </div>
  <div v-if="event" class="event-bg">
    <div
      v-for="(part, index) in event.transferParts"
      :key="part.id"
      :class="`w-full flex-wrap flex  ${
        contactsId.includes(part.seller?.id) ||
        contactsId.includes(part.transferee?.id)
          ? 'bg-active font-bold'
          : ''
      }  ${index > 0 ? 'border-t border-secondary6' : ''}`"
    >
      <div
        class="
          p-3
          border-r border-secondary6
          w-2/12
          flex
          items-center
          overflow-hidden
        "
      >
        <p v-if="part.seller" class="text-blackp text-sm text-left">
          <router-link
            :target="`${isPortfolio ? '_blank' : ''}`"
            :to="{
              name: 'company.directory',
              params: {
                id: company?.id || $route.params.id,
                contactId: part.seller.id
              }
            }"
          >
            <ContactBadge
              class="p-0 text-xs"
              :contact="part.seller"
              :details="false"
              type="light"
            />
          </router-link>
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
      <div
        class="
          p-3
          border-r border-secondary6
          w-2/12
          flex
          items-center
          overflow-hidden
        "
      >
        <p v-if="part.transferee" class="text-blackp text-sm text-left">
          <router-link
            :target="`${isPortfolio ? '_blank' : ''}`"
            :to="{
              name: 'company.directory',
              params: {
                id: company?.id || $route.params.id,
                contactId: part.transferee.id
              }
            }"
          >
            <ContactBadge
              class="p-0 text-xs"
              :contact="part.transferee"
              :details="false"
              type="light"
            />
          </router-link>
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>

      <div class="p-3 border-r border-secondary6 w-2/12">
        <p
          v-if="part.stockAlias || part.optionAlias || part.compositionAlias"
          class="text-blackp text-sm text-left"
        >
          <Tag
            v-if="part.stockAlias"
            :value="part.stockAlias.id"
            :text="part.stockAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="part.optionAlias"
            :text="part.optionAlias.alias.toUpperCase()"
            :value="part.optionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="part.compositionAlias"
            :value="part.compositionAlias.id"
            :text="part.compositionAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
          />
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
      <div
        class="
          p-3
          border-r border-secondary6
          w-2/12
          justify-end
          flex
          items-center
        "
      >
        <p class="text-blackp text-sm text-right">
          {{ formatNumber(part.quantity) }}
        </p>
      </div>

      <div class="p-3 border-secondary6 w-2/12 flex justify-end items-center">
        <p class="text-blackp text-sm text-right">
          {{ formatNumber(part.totalAmount / part.quantity) }} €
        </p>
      </div>

      <div
        class="
          p-3
          border-secondary6 border-l border-secondary6
          w-2/12
          flex
          justify-end
          items-center
        "
      >
        <p class="text-blackp text-sm text-right">
          {{ formatNumber(part.totalAmount) }} €
        </p>
      </div>
    </div>

    <div class="border-t border-secondary6 h-10 flex">
      <div
        class="
          w-6/12
          border-r border-secondary6
          h-10
          flex
          items-center
          justify-end
        "
      >
        <p class="text-right px-2 font-semibold">Total</p>
      </div>

      <div
        class="
          w-2/12
          border-r border-secondary6
          h-10
          flex
          items-center
          justify-end
        "
      >
        <p class="text-right px-2 font-semibold">
          {{ formatNumber(totalQuantity) }}
        </p>
      </div>
      <div class="w-2/12 h-10 flex items-center justify-end">
        <p class="text-right px-2 font-semibold">
          {{ formatNumber(globalUnitPrice) }} {{ currency }}
        </p>
      </div>
      <div
        class="
          w-2/12
          h-10
          border-l border-secondary6
          flex
          items-center
          justify-end
        "
      >
        <p class="text-right px-2 font-semibold">
          {{ formatNumber(globalSalePrice) }} {{ currency }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Company, Transfer } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { Tag } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'

  export default defineComponent({
    name: 'TransferCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => Transfer | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const store = useStore()
      const currency = computed(() => store.getters['organizations/currency'])
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const globalUnitPrice = computed(() => {
        return (props.event?.transferParts || []).reduce(
          (sale, p) => (sale += p.totalAmount / p.quantity),
          0
        )
      })
      const globalSalePrice = computed(() => {
        return (props.event?.transferParts || []).reduce(
          (sale, p) => (sale += p.totalAmount),
          0
        )
      })
      const totalQuantity = computed(() => {
        return (props.event?.transferParts || []).reduce(
          (sale, p) => (sale += p.quantity),
          0
        )
      })

      return {
        totalQuantity,
        globalUnitPrice,
        globalSalePrice,
        currency,
        contactsId,
        moment,
        formatNumber
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
