const environment: string = process.env.NODE_ENV ?? 'developement'

const importAll = (route: __WebpackModuleApi.RequireContext) =>
  route.keys().map((key: string) => ({
    key: key.replace('./', '').replace('.ts', ''),
    value: require(`${key.replace('./', './env/')}`)
  }))

const all = importAll(require.context('./env', false, /\.ts$/))

const config = all.find((x) => x.key === environment)?.value

export default config

export const envName = environment
