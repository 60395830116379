<template>
  <div
    class="
      content_footer
      w-full
      px-4
      py-5
      absolute
      right-0
      bottom-0
      z-10
      h-20
      bg-white
      flex
      items-center
      justify-between
      text-xl
    "
  >
    <div class="border-none border-r-0">
      <li
        v-for="(error, index) in errorsKeys"
        v-show="errors"
        :key="index"
        class="text-xs text-error"
      >
        {{ $t(`errors.${errors[error]}`) }}
      </li>
    </div>
    <div
      class="flex items-center justify-end"
      v-if="(isSubmitting && !submittingText) || !isSubmitting"
    >
      <Button
        variant="secondary"
        type="reset"
        :disabled="isSubmitting"
        :label="$t('global.actions.cancel')"
        @click="initStockForm"
      />
      <Button
        class="ml-4"
        type="submit"
        variant="primary"
        :disabled="!meta.valid || isSubmitting"
        :label="$t('global.actions.save')"
      />
    </div>
    <div
      v-else-if="isSubmitting && submittingText"
      class="flex items-center justify-end"
    >
      <h5>{{ submittingText }}</h5>
      <svg
        id="L9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        height="50"
        width="50"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
        xml:space="preserve"
        class="stroke-1"
      >
        <path
          fill="#bcbec1"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  export default defineComponent({
    components: { Button },
    props: {
      errors: {
        type: Object as () => Record<string, string>,
        required: true
      },
      meta: {
        type: Object as () => any,
        required: true
      },
      isSubmitting: { type: Boolean, default: false },
      submittingText: { type: String, default: null }
    },
    emits: ['resetForm'],
    setup(props, { emit }) {
      const store = useStore()
      const errorsKeys = computed(() => Object.keys(props.errors).slice(0, 3))

      const initStockForm = () => {
        emit('resetForm')
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: false,
          initCurrentForm: true
        })
      }
      return { initStockForm, errorsKeys }
    }
  })
</script>
