<template>
  <div
    :class="['actionlist', { 'c-actionList--btn': actionButton }]"
    @mouseenter="setCanDisplay(true)"
  >
    <div v-if="!actionButton" :class="`actionlist__toggler py-1 ${hoverClass}`">
      <i v-if="actionTable" class="bi bi-three-dots-vertical" />
      <i v-else :class="`bi ${iconClass}`" />
    </div>
    <div v-else class="actionlist__toggler--button">
      <slot name="toggle" />
    </div>
    <div v-if="!actions" class="actionlist__list">
      <!-- Liste d'action (Default) -->
      <slot name="list" />
      <div class="actionlist__list__delete">
        <!-- Specifique pour l'action de suppression -->
        <div class="py-3" @click="confirmDelete()">
          <slot name="delete" />
        </div>
        <div v-show="confirm" class="actionlist__list__delete__confirm">
          <hr class="m-0 mb-2" />
          <h6 class="text-uppercase py-2">{{ $t('global.confirmDelete') }}</h6>
          <div class="actionlist__list__delete__confirm__buttons py-3">
            <Button
              variant="custom"
              class="secondary sm bg-error text-white"
              icon="bi-x"
              :label="$t('confirm.no')"
              @click="init()"
            />
            <Button
              variant="primary"
              class="danger sm"
              icon="bi-trash xs"
              :label="$t('confirm.yes')"
              @click="deleteEvent('')"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="actionlist__list">
      <div
        v-for="action in actions"
        v-show="canDisplay"
        :key="action.name"
        class="actionlist__list__item"
      >
        <div
          class="actionlist__list__item__inner"
          @click.prevent.stop="click(action.key)"
        >
          <div class="actionlist__list__item__icon w-full">
            <i v-if="action.icon" :class="`bi-${action.icon}`" />
            <h5>{{ action.name }}</h5>
          </div>
          <i v-if="action.children?.length" class="bi-chevron-right" />
        </div>
        <div
          v-if="action.children?.length"
          class="actionlist__list__item__level"
        >
          <div
            v-for="child in action.children"
            :key="child.name"
            class="actionlist__list__item__level__item"
          >
            <div
              class="actionlist__list__item__level__item__inner"
              @click.prevent.stop="click(child.key)"
            >
              <p>{{ child.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { ActionListItem, MAINACTIONS } from '@/types'
  import { Button } from '@up.law/uplaw-ui'
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
    name: 'ActionList',
    components: { Button },
    props: {
      actionButton: {
        type: Boolean,
        required: false,
        default: false
      },
      iconClass: {
        type: String,
        default: 'bi-three-dots'
      },
      hoverClass: {
        type: String,
        default:
          'hover:bg-primary hover:bg-opacity-20 rounded-full text-secondary4'
      },
      actions: {
        type: Object as () => Array<ActionListItem>,
        required: false,
        default: null
      },
      actionTable: {
        type: Boolean,
        required: false
      }
    },
    emits: ['onClick', 'onDelete'],
    setup(props, { emit }) {
      const canDisplay = ref<boolean>(true)
      const confirm = ref<boolean>(false)
      const setCanDisplay = (value: boolean) => {
        canDisplay.value = value
      }
      const click = (eventKey: MAINACTIONS) => {
        emit('onClick', eventKey)
        canDisplay.value = false
      }

      const deleteEvent = (eventKey: string) => {
        emit('onDelete', eventKey)
        canDisplay.value = false
      }

      const confirmDelete = () => {
        confirm.value = true
      }

      const init = () => {
        confirm.value = false
      }

      return {
        click,
        canDisplay,
        confirm,
        setCanDisplay,
        init,
        confirmDelete,
        deleteEvent
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'actionlist.scss';
</style>
