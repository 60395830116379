<template>
  <DashboardView v-if="isAuthenticated && !isLoginRoute">
    <router-view></router-view>
  </DashboardView>
  <WelcomeView v-else>
    <router-view></router-view>
  </WelcomeView>
</template>

<script lang="ts">
  import { computed, defineComponent, inject } from 'vue'
  import DashboardView from '@/views/dashboard/index.vue'
  import WelcomeView from '@/views/dashboard/welcome.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'App',
    components: { DashboardView, WelcomeView },
    inject: ['Auth'],

    setup() {
      const Auth = inject('Auth')
      const route = useRoute()
      const store = useStore()
      const isLoginRoute = computed(() => route.name === 'auth.connect')
      const { isAuthenticated, loading }: any = Auth
      return { isAuthenticated, isLoginRoute }
    }
  })
</script>

<style>
  #app {
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
