<template>
  <div class="w-full h-full ownership flex flex-col">
    <div class="flex my-5 flex justify-between">
      <div class="md:w-4/5 lg:w-3/4 xl:w-1/2 flex justify-start">
        <DatePicker
          v-model="filter.date"
          class="max-w-xs rounded text-xs h-10 mr-2"
          placeholder="--/--/----"
        />
        <div class="w-1/5 lg:w-1/3 xl:w-1/3 h-10 mr-2">
          <multiselect
            v-model="filter.contactGroupId"
            :options="companyGroups"
            :groups="false"
            :has-label="false"
            :value="filter.contactGroupId"
            :placeholder="$t('contactSearch.group')"
            open-direction="bottom"
            classes="h-10"
            @change="handleOptionChange($event, 'contactGroupId')"
          />
        </div>
        <div class="w-1/5 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <Multiselect
            v-model="filter.contactId"
            :can-add-new="false"
            open-direction="bottom"
            :has-label="false"
            :value="filter.contactId"
            mode="tags"
            :filter-results="false"
            :options="contacts"
            :placeholder="$t('global.placeholder.contact')"
            name="contactId"
            :groups="false"
            @search-change="handleContactSearch"
            @change="handleOptionChange($event, 'contactId')"
          />
        </div>
        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <Multiselect
            v-model="filter.delegationId"
            :value="filter.delegationId"
            :options="delegations"
            mode="tags"
            :placeholder="$t('global.placeholder.delegation')"
            name="delegationId"
            @change="handleOptionChange($event, 'delegationId')"
          />
        </div>

        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <DropdownActions
            v-model="filter.stockAliasId"
            :can-add-new="false"
            :value="filter.stockAliasId"
            open-direction="bottom"
            :has-label="false"
            mode="single"
            :placeholder="$t('global.placeholder.option')"
            name="stockAliasId"
            :groups="false"
            :option-per-price="false"
            :filter-results="false"
            @on-updated="handleOptionChange($event, 'stockAliasId')"
          />
        </div>
        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <DropdownVesting
            v-model="filter.vestingId"
            :can-add-new="false"
            :value="filter.vestingId"
            open-direction="bottom"
            :has-label="false"
            mode="single"
            :placeholder="$t('global.placeholder.vesting')"
            name="vestingId"
            :groups="false"
            :filter-results="false"
            @on-updated="handleOptionChange($event?.value, 'vestingId')"
          />
        </div>
      </div>
      <div class="filters-date flex justify-between">
        <div class="h-10 flex">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('tooltips.columns')"
            placement="top"
            ><Filter
              class="relative mx-2"
              :options="availableColumns"
              :value="selectedColumns"
              @on-change="handleFilterSelected"
            >
              <template #option="{ option, checked }" @click.prevent.stop="">
                <Checkbox
                  size="w-5 h-5 mr-2"
                  :checked="checked"
                  :name="option.id"
                  @click.prevent.stop
                />
              </template>
              <i class="bi bi-gear"></i> </Filter
          ></el-tooltip>

          <DownloadButton class="mr-2" :on-click="download"></DownloadButton>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('tooltips.resetFilter')"
            placement="top"
            ><Button
              variant="tertiary"
              class="button-filter ml-2"
              @click="resetFilters"
            >
              <i class="bi bi-filter text-2xl"></i> </Button
          ></el-tooltip>
        </div>
      </div>
    </div>

    <div class="mb-5 mt-2 text-sm flex flex-col h-full w-full">
      <div
        v-if="isLoading || loading"
        class="flex h-full justify-center items-center"
      >
        <Loader />
      </div>
      <DataTable
        v-else-if="exerciceCalendars.length > 0"
        thead-class="sticky bg-white top-0"
        :has-checkbox="false"
        :columns="columns"
        :rows="exerciceCalendars"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #stock="{ row }">
          <Tag
            :value="row.freeSharesIssuancePart.freeShareIssuance.stockAlias.id"
            :text="
              row.freeSharesIssuancePart.freeShareIssuance.stockAlias?.alias?.toUpperCase()
            "
            :with-initials="false"
            :with-remove="false"
          />
        </template>
        <template #user="{ row }">
          <div class="flex justify-start items-center">
            <router-link
              :to="{
                name: 'company.directory',
                params: {
                  id: $route.params.id,
                  contactId: row.freeSharesIssuancePart.contact.id
                }
              }"
            >
              <ContactBadge
                :id="row.freeSharesIssuancePart.contact.id"
                class="p-0 text-xs"
                :contact="row.freeSharesIssuancePart.contact"
                :details="false"
                type="light"
              />
            </router-link>
          </div>
        </template>
        <template #vestingStartDate="{ row }">
          <p v-if="row.acquisitionPeriods[0]?.startDate">
            {{
              moment
                .utc(row.acquisitionPeriods[0]?.startDate)
                .format($t('dateFormat'))
            }}
          </p>
          <p v-else>-</p>
        </template>
        <template #allocatedQuantity="{ row }">
          <Number
            :value="row.freeSharesIssuancePart.quantity"
            :mode="NumberType.quantity"
          />
        </template>
        <template #vestingEndDate="{ row }">
          <p
            v-if="
              row.acquisitionPeriods[row.acquisitionPeriods.length - 1]?.endDate
            "
          >
            {{
              moment
                .utc(
                  row.acquisitionPeriods[row.acquisitionPeriods.length - 1]
                    .endDate
                )
                .format($t('dateFormat'))
            }}
          </p>
          <p v-else>-</p>
        </template>
        <template #exercisableQuantity="{ row }">
          <Number :value="row.vestedQuantity" :mode="NumberType.quantity" />
        </template>
        <template #ceil="{ row }">
          <Number
            :value="
              row.vestedQuantity - row.acquiredQuantity - row.numberOfSunseted >
              0
                ? row.vestedQuantity -
                  row.acquiredQuantity -
                  row.numberOfSunseted
                : 0
            "
            :mode="NumberType.quantity"
          />
        </template>
        <template #lapsedQuantity="{ row }">
          <Number
            zero-presents="-"
            :value="row.numberOfSunseted"
            :mode="NumberType.quantity"
          />
        </template>
        <template #exercisedQuantity="{ row }">
          <Number
            zero-presents="-"
            :value="row.acquiredQuantity"
            :mode="NumberType.quantity"
          />
        </template>
        <template #heldBalance="{ row }">
          <Number
            :value="row.freeSharesIssuancePart.quantity - row.vestedQuantity"
            :mode="NumberType.quantity"
          />
        </template>

        <template #vesting="{ row }">
          <div>
            {{ row.freeSharesIssuancePart.vestingCalendar?.name || '-' }}
          </div>
        </template>
        <template #delegation="{ row }">
          <p>
            {{ row.delegation?.label || '-' }}
          </p>
        </template>
        <template #eventName="{ row }">
          <div
            v-if="row.freeSharesIssuancePart?.freeShareIssuance?.event?.id"
            class="flex justify-end"
          >
            <div
              class="
                text-left
                cursor-pointer
                bg-primary1
                rounded
                w-8
                h-8
                flex
                justify-center
                items-center
                hover:bg-primary2
              "
              @click="
                gotoEvent(
                  row.freeSharesIssuancePart?.freeShareIssuance?.event?.id
                )
              "
            >
              <i class="bi bi-lightning text-xl text-primary4"></i>
            </div>
          </div>
          <p v-else>-</p>
        </template>
        <template #total>
          <tr>
            <td :colspan="`${getOrder('delegation') > 0 ? '5' : '4'}`">
              <p class="text-right font-bold py-3 px-2 text-primary8">Total</p>
            </td>
            <td v-if="getOrder('allocatedQuantity') > 0">
              <Number
                :classes="`text-left font-bold py-3 px-2 text-${
                  total >= 0 ? 'primary8' : 'error'
                }`"
                :value="total"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="getOrder('exercisedQuantity') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="exercisedQuantity"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="getOrder('lapsedQuantity') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="obsoleteQuantity"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="selectedColumns.includes('exercisableQuantity')">
              <Number
                :classes="`text-left font-bold py-3 px-2 text-${
                  exercisableQuantity >= 0 ? 'primary8' : 'error'
                }`"
                :value="exercisableQuantity"
                :mode="NumberType.quantity"
              />
            </td>

            <td v-if="getOrder('ceil') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="ceil > 0 ? ceil : 0"
                :mode="NumberType.quantity"
              />
            </td>

            <td v-if="getOrder('heldBalance') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="heldBalance"
                :mode="NumberType.quantity"
              />
            </td>

            <td v-if="getOrder('totalExercisePrice') > 0">
              <p class="text-left font-bold py-3 px-2 text-primary8">
                <!-- {{ formatNumber(totalExercisePrice) }}
                  {{ currency }} -->
              </p>
            </td>

            <td v-if="getOrder('pv') > 0">-</td>
            <td v-if="getOrder('pv_vested') > 0">-</td>
          </tr>
          <!-- <tr>
            <td :colspan="`${getOrder('delegation') > 0 ? '5' : '4'}`">
              <p class="text-right font-bold py-3 px-2 text-primary8">Total</p>
            </td>
            <td></td>
            <td v-if="getOrder('allocatedQuantity') > 0">
              <Number
                :classes="`text-left font-bold py-3 px-2 text-${
                  total >= 0 ? 'primary8' : 'error'
                }`"
                :value="exerciceCalendarsTotal?.assignedAmount || 0"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="getOrder('exercisedQuantity') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="exerciceCalendarsTotal?.exercisedQuantity || 0"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="getOrder('lapsedQuantity') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="exerciceCalendarsTotal?.numberOfSunsetedOptions || 0"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="selectedColumns.includes('exercisableQuantity')">
              <Number
                :classes="`text-left font-bold py-3 px-2 text-${
                  exerciceCalendarsTotal?.vestedQuantity >= 0
                    ? 'primary8'
                    : 'error'
                }`"
                :value="exerciceCalendarsTotal?.vestedQuantity || 0"
                :mode="NumberType.quantity"
              />
            </td>

            <td v-if="getOrder('ceil') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="exerciceCalendarsTotal?.exercisableQuantity || 0"
                :mode="NumberType.quantity"
              />
            </td>

            <td v-if="getOrder('heldBalance') > 0">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="exerciceCalendarsTotal?.nonVestedQuantity || 0"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="getOrder('lastActionPrice') > 0">-</td>

            <td v-if="getOrder('totalExercisePrice') > 0">
              <p class="text-left font-bold py-3 px-2 text-primary8"></p>
            </td>

            <td v-if="getOrder('pv') > 0">-</td>
            <td v-if="getOrder('pv_vested') > 0">-</td>
          </tr> -->
        </template>
      </DataTable>
      <div
        v-else
        class="
          flex
          justify-center
          items-center
          w-full
          mt-4
          transition transition-all
        "
      >
        <EmptyState
          image="no-result.svg"
          class="d-block mx-auto"
          :title="$t('global.noResult')"
          :subtitle="$t('global.noData')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DataTable, { Column } from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Contact,
    FreeSharesAcquisitionCalendar,
    OptionStockAlias,
    VestingSynthesis
  } from '@/services/api/models'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { formatNumber, ucFirst } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { ElTooltip } from 'element-plus'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownFreeSharesVesting.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  export default defineComponent({
    components: {
      DataTable,
      Tag,
      DropdownActions,
      ContactBadge,
      EmptyState,
      Multiselect,
      DatePicker,
      Checkbox,
      Filter,
      Button,
      ElTooltip,
      Loader,
      DownloadButton,
      Number,
      DropdownVesting
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const selectedColumns = ref<string[]>([])

      const filter = ref<any>({
        vestingId: '',
        contactId: [],
        delegationId: [],
        stockAliasId: '',
        contactGroupId: '',
        date: new Date().toISOString()
      })
      const exerciceCalendars = computed<FreeSharesAcquisitionCalendar[]>(
        () => store.getters['organizations/freeSharesAcquisitionCalendars']
      )
      const pagination = computed(
        () =>
          store.getters[
            'organizations/freeSharesAcquisitionCalendarsPagination'
          ]
      )
      const exerciceCalendarsTotal = computed<VestingSynthesis>(
        () => store.getters['organizations/exerciseCalendarsTotal']
      )
      const currency = computed(() => store.getters['organizations/currency'])
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const loading = ref(true)

      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData'] && loading
      )

      const optionAliases = ref<OptionStockAlias[]>([])

      const contacts = computed<Contact[]>(() =>
        store.getters['organizations/contacts'].map((x: any) => ({
          ...x,
          name: ucFirst(x.name)
        }))
      )

      const total = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.freeSharesIssuancePart.quantity),
          0
        )
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c?.vestedQuantity || 0),
          0
        )
      )

      const ceil = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              (c?.vestedQuantity || 0) -
              (c?.acquiredQuantity || 0) -
              (c?.numberOfSunseted || 0)),
          0
        )
      )

      const obsoleteQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.numberOfSunseted || 0),
          0
        )
      )
      const exercisedQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.acquiredQuantity || 0),
          0
        )
      )

      const heldBalance = computed(
        () => total.value - exercisableQuantity.value
      )

      const exercisePrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )

      const lastStockPrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )
      const portentialGainAtDate = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )

      const totalExercisePrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )
      const potentialGain100Percent = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += 0), 0)
      )
      const allColumns = computed(() => [
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          custom: true,
          order: 2
        },
        {
          label: t('events.fieldsLabels.contact'),
          field: 'user',
          custom: true,
          order: 1,
          sortBy: 'contact',
          sortFunction: (a: any, b: any) => {
            return a?.contact.name.localeCompare(b.contact.name)
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingStartDate'),
          field: 'vestingStartDate',
          custom: true,
          order: 3,
          sortBy: 'vestingStartDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].startDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].startDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingEndDate'),
          field: 'vestingEndDate',
          custom: true,
          filterable: true,
          selected: true,
          order: 4,
          sortBy: 'vestingEndDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].endDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].endDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.delegation'),
          field: 'delegation',
          custom: true,
          order: 4.5,
          filterable: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          order: 6,
          sortBy: 'allocatedQuantity',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity - b?.optionIssuancePart.quantity
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          filterable: true,
          order: 9,
          selected: true,
          sortBy: 'exercisableQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisableQuantity - b?.exercisableQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.free_shares_balance'),
          field: 'ceil',
          custom: true,
          filterable: true,
          order: 10,
          selected: true,
          sortBy: 'ceil',
          sortFunction: (a: any, b: any) => {
            return a?.exercisableBalance - b?.exercisableBalance
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          filterable: true,
          order: 8,
          selected: true,
          sortBy: 'lapsedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.numberOfSunsetedOptions - b?.numberOfSunsetedOptions
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.freeSharesExercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          filterable: true,
          order: 7,
          selected: true,
          sortBy: 'exercisedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisedQuantity - b?.exercisedQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.heldBalance'),
          field: 'heldBalance',
          custom: true,
          filterable: true,
          order: 10,
          selected: true,
          sortBy: 'heldBalance',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity -
              a?.vestedQuantity -
              (b?.optionIssuancePart.quantity - b?.vestedQuantity)
            )
          },
          type: 'function'
        },

        {
          label: t('events.fieldsLabels.totalExercisePrice'),
          field: 'totalExercisePrice',
          custom: true,
          filterable: true,
          order: 12.5,
          selected: false
        },

        {
          label: t('events.fieldsLabels.vestingPlan'),
          field: 'vesting',
          custom: true,
          filterable: true,
          order: 15
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          custom: true,

          order: 15,
          filterable: true,
          selected: true
        }
      ])
      const existingColumns = ref(allColumns.value.filter((x) => !x.filterable))
      const columns = ref(
        allColumns.value
          .filter((x) => !x.filterable || x.selected)
          .sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable || x.selected)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      selectedColumns.value = allColumns.value
        .filter((x) => x.selected)
        .map((x) => x.field)
      const handleFilterSelected = (value: string[]) => {
        columns.value = [
          ...existingColumns.value,
          ...allColumns.value.filter((x) => value.includes(x.field))
        ].sort((a: any, b: any) => a.order - b.order)

        selectedColumns.value = value
      }
      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }
      const getVesting = async (page = 1) => {
        await store.dispatch(
          'organizations/GET_FREE_SHARES_ACQUISITION_CALENDARS',
          {
            companyId: route.params.id,
            page,
            filter: {
              ...filter.value
            }
          }
        )

        loading.value = false
      }

      const getTotal = () => {
        store.dispatch('organizations/GET_EXERCISE_CALENDARS_TOTAL', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            ...filter.value
          }
        })
      }

      const onPageChange = (page: number) => {
        getVesting(page)
      }

      const resetFilters = () => {
        filter.value = {
          vestingId: '',
          contactId: [],
          delegationId: [],
          stockAliasId: '',
          contactGroupId: '',
          date: new Date().toISOString()
        }
      }
      const download = () => {
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS', {
          name: `${t('exports.shareholding')} `,
          companyId,
          filter: filter.value
        })
      }
      const getOrder = (field: string) =>
        columns.value.find((x) => x.field === field)?.order || 0

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: route.params.id,
          filter,
          onSuccess: (data: OptionStockAlias[]) => {
            optionAliases.value = [...data].map((x) => ({
              ...x,
              name: x.alias
            }))
          }
        })
      }

      const handleOptionChange = (data: any, field: string) => {
        console.log('=> ', field, data)
        if (field === 'stockAliasId') filter.value[field] = data.value
        else filter.value[field] = data
        // getTotal()
        onPageChange(1)
      }

      watch(
        () => route.params.id,
        () => {
          // getTotal()
          onPageChange(1)
        }
      )

      watch(
        () => filter.value.date,
        (val) => {
          onPageChange(1)

          filter.value.date = val || new Date().toISOString()
          // getTotal()
        }
      )

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          }
        })
      }

      onMounted(async () => {
        await store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id
        })
        // getTotal()
        onPageChange(1)

        getStocks()

        getContacts()
      })

      onBeforeMount(() => (loading.value = true))

      return {
        currency,
        contacts,
        columns,
        availableColumns,
        exerciceCalendars,
        exercisableQuantity,
        obsoleteQuantity,
        ceil,
        pagination,
        selectedColumns,
        delegations,
        heldBalance,
        exercisedQuantity,
        lastStockPrice,
        exercisePrice,
        portentialGainAtDate,
        potentialGain100Percent,
        total,
        optionAliases,
        filter,
        isLoading,
        loading,
        moment,
        companyGroups,
        totalExercisePrice,
        NumberType,
        exerciceCalendarsTotal,
        resetFilters,
        getStocks,
        formatNumber,
        download,
        onPageChange,
        handleFilterSelected,
        getOrder,
        gotoEvent,
        handleOptionChange,
        handleContactSearch
      }
    }
  })
</script>

<style lang="scss">
  .ownership {
    .filters-date {
      .button-filter {
        @apply h-full flex px-2 justify-center items-center border border-inputBorder rounded-sm hover:bg-primary5 hover:text-white;
        &:hover img {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }
    .allocatedQuantity {
      width: 12%;
    }
    .stock {
      padding: 5px !important;
    }
    .user {
      padding: 5px !important;
    }
  }
</style>
