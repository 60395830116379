<template>
  <div :id="`checkbox-${name}`" class="py-2 upl-checkbox relative h-10">
    <div class="flex items-center">
      <input
        :class="`opacity-0 absolute ${size}`"
        type="checkbox"
        :disabled="disabled"
        :checked="checked"
        :name="name"
        @input.stop="handleChange"
      />
      <div
        :class="`checkbox-svg
          ${disabled ? 'disabled' : 'not-disabled'}
          ${value ? 'is-checked' : 'not-checked'}
          ${size}`"
      >
        <i :class="`bi ${icon}`"></i>
      </div>
      <span
        v-if="label"
        :for="name"
        :class="`ml-2 select-none mb-0 text-${
          disabled ? 'secondary8' : 'blackp'
        }`"
        >{{ `${trueLabel && value ? trueLabel : label}` }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'

  export default defineComponent({
    name: 'UplCheckbox',
    props: {
      checked: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: false,
        default: null
      },
      trueLabel: {
        type: String,
        required: false,
        default: null
      },
      size: {
        type: String,
        default: 'w-6 h-6'
      },
      icon: {
        type: String,
        default: 'bi-check text-xl'
      }
    },
    emits: ['onChange'],
    setup(props, { emit }) {
      const value = ref(props.checked)

      const handleChange = ($event: Event) => {
        value.value = ($event.target as HTMLInputElement).checked
        emit('onChange', value.value, props.name)
      }

      watch(props, (newProps) => {
        value.value = newProps.checked
      })

      return { value, handleChange }
    }
  })
</script>

<style lang="scss" scoped>
  .upl-checkbox {
    input:checked + div {
      @apply border-primary4;
    }

    .is-checked i {
      @apply block text-primary4;
    }
    .not-checked i {
      @apply hidden;
    }
    .checkbox-svg {
      @apply bg-white border-2 rounded-md flex flex-shrink-0 justify-center items-center transition-all;
    }

    .checkbox-svg.disabled {
      @apply border-secondary6 focus-within:border-secondary6;
    }

    .checkbox-svg.disabled i {
      @apply fill-current text-secondary6;
    }
    .checkbox-svg.not-disabled {
      @apply border-primary4 focus-within:border-primary4 border-secondary6 transition-all;
    }
    .checkbox-svg.not-disabled.is-checked {
      @apply border-primary4;
    }
  }
</style>
