
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import FileInput from '@/components/FileInput/index.vue'
  import { CompanyModel } from '@/services/api/models'
  import { useStore } from 'vuex'
  export default defineComponent({
    components: { FileInput },
    props: {
      type: { type: String, default: '' },
      contactId: { type: String, default: null },
      profileImage: {
        type: String,
        required: false,
        default: null
      },
      withLabel: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['fileChanged'],
    setup(props, { emit }) {
      const store = useStore()
      const url = ref<string | null>(props.profileImage)
      const fileInputRef = ref<HTMLInputElement>()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const handleChange = (files: File[]) => {
        url.value = URL.createObjectURL(files![0])
        emit('fileChanged', files![0])
      }
      const handleSelect = (e: Event) => {
        document.getElementById('profileImage')?.click()
      }
      const resetUrl = () => {
        url.value = null
      }

      const loadImage = async () => {
        if (!props.contactId) return
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_PICTURE',
          {
            companyId: currentCompany.value?.id,
            contactId: props.contactId
          }
        )
        if (response) url.value = response
      }

      watch(
        () => props.profileImage,
        (val) => {
          url.value = val
        }
      )

      onMounted(loadImage)

      return {
        handleSelect,
        handleChange,
        resetUrl,
        fileInputRef,
        url
      }
    }
  })
