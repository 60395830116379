import * as yup from 'yup'
import { CONTACTTYPE } from '@/types'
export const individualSchema = yup.object({
  firstName: yup.string().required('firstNameRequired'),
  lastName: yup.string().required('lastNameRequired'),
  familySituation: yup.string().optional().nullable().default(null),
  gender: yup.string().optional().nullable().default(null),
  type: yup.string().oneOf(Object.values(CONTACTTYPE), 'contactType'),
  contactGroupsId: yup
    .array()
    .nullable()
    .of(yup.string())
    .optional()
    .default(null),
  contactStatusId: yup.string().optional().nullable().default(''),
  birthDate: yup.string().optional().nullable().default(''),
  birthPlace: yup.string().optional().nullable().default(''),
  email: yup.string().optional().nullable().default(''),
  phoneNumber: yup.string().optional().nullable().default(''),
  website: yup.string().optional().nullable().default(''),
  address: yup.string().optional().nullable().default(''),
  country: yup.string().optional().nullable().default(''),
  city: yup.string().optional().nullable().default(''),
  nationality: yup.string().optional().nullable().default('')
})

export const investorSchema = yup.object({
  contactGroupsId: yup.array().of(yup.string()).optional().default([]),
  contactStatusId: yup.string().optional().nullable().default(''),
  name: yup.string().required('nameRequired'),
  legalStatusId: yup.string().optional().nullable().default(''),
  representativeContactId: yup.string().optional().nullable().default(''),
  email: yup.string().optional().nullable().default(''),
  phoneNumber: yup.string().optional().nullable().default(''),
  website: yup.string().optional().nullable().default('')
})

export const professionalSchema = yup.object({
  email: yup.string().optional().nullable().default(''),
  siren: yup.string().optional().nullable().default(''),
  tva: yup.string().optional().nullable().default(''),
  siret: yup.string().optional().nullable().default(''),
  phoneNumber: yup.string().optional().nullable().default(''),
  website: yup.string().optional().nullable().default(''),
  address: yup.string().optional().nullable().default(''),
  country: yup.string().optional().nullable().default(''),
  city: yup.string().optional().nullable().default(''),
  nationality: yup.string().optional().nullable().default(''),
  type: yup.string().oneOf(Object.values(CONTACTTYPE), 'contactType'),
  contactGroupsId: yup.array().of(yup.string()).optional().default([]),
  contactStatusId: yup.string().optional().nullable().default(''),
  birthDate: yup.string().optional().nullable().default(''),
  name: yup.string().required('nameRequired'),
  tradeName: yup.string().optional().nullable().default(''),
  legalStatusId: yup.string().optional().nullable().default(''),
  representativeContactId: yup.string().optional().nullable().default('')
})

export const comapnySchema = yup.object({
  email: yup.string().optional().nullable().default(''),
  constitutedAt: yup.string().optional().nullable().default(''),
  phoneNumber: yup.string().optional().nullable().default(''),
  website: yup.string().optional().nullable().default(''),
  address: yup.string().optional().nullable().default(''),
  country: yup.string().optional().nullable().default(''),
  city: yup.string().nullable().default(''),
  postalCode: yup.string().nullable().default(''),
  name: yup.string().required('requiredDenomination'),
  siren: yup.string().nullable().default(null),
  tva: yup.string().nullable().default(null),
  siret: yup.string().nullable().default(''),
  commercialName: yup.string().optional(),
  legalStatusId: yup.string().nullable().optional().default('')
})
