<template>
  <div class="eclipse-loading relative">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="
        background: rgba(255, 255, 255, 0);
        display: block;
        shape-rendering: auto;
      "
      x="0px"
      y="0px"
      width="150"
      height="150"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M10 50A40 40 0 0 0 90 50A40 43.6 0 0 1 10 50"
        fill="#8580f6"
        stroke="none"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur="1.5873015873015872s"
          repeatCount="indefinite"
          keyTimes="0;1"
          values="0 50 51.8;360 50 51.8"
        ></animateTransform>
      </path>
    </svg>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    setup() {
      const { t } = useI18n()
      const text = computed(() => Array.from(t('global.loading')))
      const style = computed(() => '')
      return { text }
    }
  })
</script>

<style lang="scss" scoped>
  @mixin position-center($text-align: center) {
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: $text-align;
  }

  .eclipse-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(133, 128, 246, 0);
  }

  @keyframes blur-text {
    0% {
      filter: blur(0px);
    }

    100% {
      filter: blur(4px);
    }
  }
</style>
