<template>
  <div class="edit-upload">
    <div class="flex justify-between items-center my-3">
      <p class="text-blackp text-md font-semibold">
        {{ $t('fileLoader.replaceDocument') }}
      </p>
      <el-progress
        v-if="uploadResponse.progress > 0"
        class="upload-progress"
        :stroke-width="10"
        :percentage="uploadResponse.progress"
        :show-text="true"
        :color="`${
          uploadResponse.progress > 0 && uploadResponse.progress < 100
            ? UplTheme.colors.warning
            : uploadResponse.error
            ? UplTheme.colors.error
            : UplTheme.colors.success
        }`"
      />
    </div>

    <FileInput accept="application/pdf" @filesChanged="handleFilesSelection">
      <div
        class="
          flex
          justify-start
          items-center
          rounded
          border border-secondary6
          p-2
          hover:bg-secondary2
          bg-secondary2
          cursor-pointer
        "
      >
        <i class="bi bi-link text-xl mr-2 text-secondary6 rotate-90"></i>
        <p class="text-secondary7">{{ replaceLabel }}</p>
      </div>
    </FileInput>
    <div v-if="selectedFile" class="mt-2">
      <div>
        <label>{{ $t('fileLoader.renameDocumentLabel') }}</label>
        <Field v-slot="{ field }" v-model="fileLabel" name="fileName">
          <Input
            v-model="fileLabel"
            :placeholder="$t('fileLoader.renameDocumentPlaceholder')"
            name="fileName"
            v-bind="field"
          />
        </Field>
      </div>

      <div class="text-xl flex justify-end">
        <Button
          :label="
            $t(
              `global.actions.${
                uploadResponse.progress === 100 ? 'close' : 'cancel'
              }`
            )
          "
          variant="tertiarty"
          class="text-xl mr-2"
          @click="init()"
        />
        <Button
          :label="$t('global.actions.save')"
          :disabled="uploadResponse.progress === 100"
          variant="secondary"
          class="text-xl"
          @click="replaceDocument"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import FileInput from '../FileInput/index.vue'
  import { Field } from 'vee-validate'
  import { Input, Button, UplTheme } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { ElProgress } from 'element-plus'

  export default defineComponent({
    name: 'DocumentUpdate',
    components: {
      FileInput,
      Field,
      Input,
      ElProgress,
      Button
    },
    props: {
      documentId: { type: String, default: null }
    },
    emits: ['updated'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const replaceLabel = ref(t('fileLoader.loadFile'))
      const fileLabel = ref('')
      const selectedFile = ref<File | null>(null)

      const uploadResponse = ref({ error: false, success: false, progress: 0 })

      const handleFilesSelection = (files: File[]) => {
        const [file] = files
        replaceLabel.value = file.name
        fileLabel.value = file.name.replace('.pdf', '')
        selectedFile.value = file
      }

      const init = () => {
        uploadResponse.value = { error: false, success: false, progress: 0 }
        selectedFile.value = null
        replaceLabel.value = t('fileLoader.loadFile')
        fileLabel.value = ''
        emit('updated')
      }

      const replaceDocument = () => {
        if (!selectedFile.value) return

        const form = new FormData()

        form.append('file', selectedFile.value)
        form.append('fileName', selectedFile.value.name)
        form.append('label', fileLabel.value)

        store.dispatch(`organizations/REPLACE_COMPANY_DOCUMENT`, {
          form,
          companyId: route.params.id,
          documentId: props.documentId,
          onError: (err: string) => {
            console.log(err)
            uploadResponse.value.error = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('fileLoader.title'),
              message: err || t('fileLoader.replaceErrorMessage')
            })
          },
          onSuccess: (res: string) => {
            console.log(res)
            uploadResponse.value.success = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('fileLoader.title'),
              message: t('fileLoader.replaceSuccessMessage')
            })
          },
          progress: (pEvent: ProgressEvent) => {
            const percentCompleted =
              Math.round(pEvent.loaded * 100) / pEvent.total
            uploadResponse.value.progress = percentCompleted
          }
        })
      }

      return {
        selectedFile,
        replaceLabel,
        fileLabel,
        uploadResponse,
        UplTheme,
        handleFilesSelection,
        replaceDocument,
        init
      }
    }
  })
</script>
<style lang="scss">
  .edit-upload {
    .upload-progress.el-progress {
      width: 40%;
      .el-progress__text {
        min-width: auto !important;
      }
    }
  }
</style>
