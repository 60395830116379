<template>
  <div v-if="event" class="flex">
    <router-link
      :target="`${company?.id ? '_blank' : ''}`"
      :to="{
        name: 'company.directory',
        params: {
          id: company?.id || $route.params.id,
          contactId: event.contact?.id
        }
      }"
    >
      <ContactBadge
        class="p-1 my-3 m-0 border border-secondary5 rounded"
        :contact="event.contact"
        :details="false"
        type="light"
      />
    </router-link>
  </div>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.function') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ event?.function?.name }}
      </p>
    </div>
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.committee') }}
      </h1>
      <p v-if="event?.committee" class="text-blackp text-sm text-left">
        {{ event.committee.denomination }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">-</h1>
      <p class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-secondary6 border-r w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.startDate') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ moment.utc(event.startDate).format('L') }}
      </p>
    </div>

    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.expirationDate') }}
      </h1>
      <p
        v-if="event.appointmentTermination"
        class="text-left text-sm capitalize"
      >
        {{ moment.utc(event?.appointmentTermination.endDate).format('L') }}
        <span class="mx-2">
          <i
            class="
              bi bi-arrow-up-right-square
              text-lg
              cursor-pointer
              hover:text-primary4
            "
            @click="gotoEvent"
          ></i>
        </span>
      </p>
      <p
        v-else-if="!event.isUnlimited && event?.endDate"
        class="text-blackp text-sm text-left"
      >
        {{ moment.utc(event?.endDate).format('L') }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.duration') }}
      </h1>

      <p
        v-if="event.appointmentTermination"
        class="text-left text-sm capitalize"
      >
        {{
          calculateTimeDuration(
            event?.startDate || '',
            event?.appointmentTermination.endDate || '',
            lang
          )
        }}
      </p>
      <div v-else>
        <p v-if="event.isUnlimited" class="text-blackp text-sm text-left">
          {{ $t('events.elements.timeUnlimited') }}
        </p>
        <p
          v-else-if="event.accountingExerciseYear"
          class="text-left capitalize text-sm"
        >
          {{ $t('events.elements.accountingExerciseYear') }}
          {{ event.accountingExerciseYear }}
          {{
            event?.endDate ? ` (${moment.utc(event?.endDate).format('L')})` : ''
          }}
        </p>

        <p v-else class="text-blackp text-sm text-left">
          {{
            calculateTimeDuration(
              event?.startDate || '',
              event?.endDate || '',
              lang
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Appointment, Company } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { calculateTimeDuration } from '@/utils/global'
  import { useRoute, useRouter } from 'vue-router'
  export default defineComponent({
    name: 'AppointmentCard',
    components: { ContactBadge },
    props: {
      event: {
        type: Object as () => Appointment | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      }
    },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const lang = computed(() => store.getters['config/lang'])

      const gotoEvent = () => {
        if (!props.event?.appointmentTermination?.event) return
        router.push({
          name: 'company.eventDetails',
          params: {
            id: route.params.id,
            eventId: props.event?.appointmentTermination?.event.id
          }
        })
      }
      return { lang, moment, calculateTimeDuration, gotoEvent }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
