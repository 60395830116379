<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" :class="size">
        <div class="modal-header flex justify-between items-center">
          <slot name="header"> </slot>
          <div
            class="
              rounded
              hover:bg-primary4 hover:text-white
              text-primary8
              transition
            "
          >
            <i class="bi bi-x text-xl px-2 m-0" @click="$emit('close')"></i>
          </div>
        </div>

        <div class="modal-body">
          <slot name="body"> </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <Button
              class="modal-default-button text-xl"
              label="Close"
              variant="secondary"
              @click="$emit('close')"
            >
            </Button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  export default defineComponent({
    name: 'Modal',
    components: { Button },
    props: {
      size: { type: String, default: 'w-3/5' }
    },
    emits: ['close'],
    setup(props, { emit }) {
      const close = () => {
        emit('close')
      }
      return { close }
    }
  })
</script>
<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 0.55em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    @apply text-primary9;
  }

  .modal-body {
    margin: 20px 0 0;
    overflow: scroll;
  }

  .modal-default-button {
    float: right;
  }

  /*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }

  .modal-enter-active .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
