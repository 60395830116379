
  import { ParityChange } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import { Tag } from '@up.law/uplaw-ui'

  export default defineComponent({
    name: 'ParityChange',
    components: { Tag },
    props: {
      event: {
        type: Object as () => ParityChange | undefined,
        required: true
      }
    },
    setup() {
      return {}
    }
  })
