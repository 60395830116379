<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >
    <Field v-slot="{ field }" v-model="formValue" :name="data.name">
      <Multiselect
        :value="formValue"
        :name="data.name"
        :show-labels="true"
        mode="single"
        :options="committees"
        :placeholder="$t('events.elements.selectCommittee')"
        :groups="false"
        class="w-full"
        v-bind="field"
        group-label="denomination"
        :open-direction="openDirection"
        @search-change="searchText = $event"
        @change="updateValue"
      >
        <template #[addActionSlot]>
          <div v-if="canAddNew" class="">
            <div
              class="
                flex
                w-full
                h-12
                justify-start
                items-center
                text-md
                px-2
                text-secondary9
                hover:bg-primary1
              "
            >
              <i class="bi bi-plus text-xl text-secondary9"></i>
              <h4
                class="
                  w-full
                  h-full
                  text-secondary9
                  flex
                  justify-start
                  items-center
                  text-md
                "
                @click="openModal"
              >
                {{ $t('committeeBook.form.add') }}
              </h4>
            </div>
          </div>
        </template>

        <template #noOptions>
          <span v-if="false"> {{ $t('events.elements.noCommittee') }} </span>
          <span v-else>{{ $t('events.elements.search') }}</span>
        </template>

        <template #noResults>
          <span>{{ $t('global.placeholder.noResult') }}</span>
        </template>
      </Multiselect>
    </Field>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, toRefs, onBeforeMount } from 'vue'
  import { useRoute } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { Committee } from '@/services/api/models'
  import { Field } from 'vee-validate'
  import { MAINACTIONS } from '@/types'
  export default defineComponent({
    name: 'DropdownCommittee',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      allData: {
        default: () => ({}),
        type: Object
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      mode: {
        default: 'single',
        type: String
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'top'
      },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      canAddNew: { type: Boolean, default: true }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { data } = toRefs(props)
      const route = useRoute()

      // allData will be useful for filter if delegation is selected for example
      const formValue = ref('')
      const searchText = ref('')
      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'afterlist' : 'beforelist'
      )

      const committees = computed<Committee[]>(
        () => store.getters['organizations/committees']
      )

      const updateValue = (value: any) => {
        formValue.value = value
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      const openModal = () => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.COMMITTEE
        })
      }

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_FUNCTIONS')
        await store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: route.params.id
        })
      })

      return {
        formValue,
        searchText,
        fieldName,
        committees,
        addActionSlot,
        updateValue,
        openModal
      }
    }
  })
</script>
