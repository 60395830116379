<template>
  <div id="target" class="h-full p-0 flex justify-between">
    <DocumentTreeview
      ref="documentTreeview"
      :company-id="$route.params.companyId"
    />
    <div class="flex w-full min-h-full">
      <div
        v-if="isLoading"
        class="
          flex
          w-full
          min-h-full
          justify-center
          items-center
          transition transition-all
        "
      >
        <Loader />
      </div>
      <div
        v-show="!isLoading"
        class="extraction__wrapper relative transition transition-all"
      >
        <div class="extraction__body">
          <event-form :event="currentEvent" @loaded="handleLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import EventForm from '@/components/Event/EventEditForm.vue'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import { defineComponent } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    name: 'CreateEvent',
    components: {
      DocumentTreeview,
      EventForm,
      Loader
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const getEventCategories = () => {
        store.dispatch('toolbox/getEventCategories')
      }
      const isLoading = ref<boolean>(true)
      const currentEvent = ref(store.getters['organizations/event'])

      const getEvent = async (companyId = route.params.id) => {
        // isLoading.value = true
        await store.dispatch('organizations/GET_EVENT', {
          companyId,
          eventId: route.params.eventId,
          onSuccess: async (data: Event | null) => {
            currentEvent.value = data
            // isLoading.value = false
          },
          onError: () => {
            router.push({
              name: 'company.corporateEvents',
              params: { id: route.params.id },
              query: { type: 'event' }
            })
            isLoading.value = false
          }
        })
      }

      const handleLoading = (display: boolean) => {
        isLoading.value = !display
      }

      onMounted(async () => {
        getEventCategories()
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
        getEvent()
      })

      onBeforeUnmount(() => {
        store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', [])
      })

      watch([route.params.id, route.params.eventId], () => {
        getEvent()
      })

      return {
        isLoading,
        handleLoading,
        currentEvent
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/extraction.scss';
  .extraction__body {
    @apply flex justify-center items-center overflow-hidden;
  }
  .ext-con {
    padding: 40px 20px !important;
  }
</style>
