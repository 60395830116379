<template>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.elements.exercise') }}
      </h1>
      <p v-if="event.financialEndingDate" class="text-blackp text-sm text-left">
        {{ moment.utc(event.financialEndingDate).format('L') }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.elements.turnover') }}
      </h1>
      <p v-if="event.turnover" class="text-blackp text-sm text-left">
        {{ formatNumber(event.turnover) }} {{ currency }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-l border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.elements.result') }}
      </h1>
      <p v-if="event.result" class="text-blackp text-sm text-left">
        {{ formatNumber(event.result) }} {{ currency }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.elements.earningsAllocation') }}
      </h1>
      <p v-if="event.earningsAllocation" class="text-blackp text-sm text-left">
        {{ $t(`earningsAllocation.${event.earningsAllocation}`) }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.elements.allocationType') }}
      </h1>
      <p v-if="event.allocationType" class="text-blackp text-sm text-left">
        {{ $t(`earningsAllocation.${event.allocationType}`) }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.elements.amount') }}
      </h1>
      <p v-if="event.allocationType" class="text-blackp text-sm text-left">
        {{ formatNumber(event.amount) }} {{ currency }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { AccountApproval } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  export default defineComponent({
    name: 'AccountApprovalCard',
    props: {
      event: {
        type: Object as () => AccountApproval | undefined,
        default: null
      }
    },
    setup() {
      const store = useStore()
      const currency = computed(() => store.getters['organizations/currency'])

      return { moment, currency, formatNumber }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
