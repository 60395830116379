
  import { computed, defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    setup() {
      const { t } = useI18n()
      const text = computed(() => Array.from(t('global.loading')))
      const style = computed(() => '')
      return { text }
    }
  })
