
  import { SET_MENU_COMPRESSED } from '@/store/modules/Config'
  import { computed, defineComponent, ref } from 'vue'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { Company, User } from '@/services/api/models'
  import { useAcl } from 'vue-simple-acl/src'
  import { AlertType, MAINACTIONS } from '@/types/config'
  import moment from 'moment'
  import conf from '@/config'
  import { GlobalConfig } from '@/types'
  import MenuDropdown from '@/components/MenuDropdown/index.vue'
  import AlertDisplay from '@/components/AlertDisplay/index.vue'
  import SearchModal from '@/components/Modal/SearchModal.vue'
  import MainActions from '@/components/MainActionList/index.vue'
  import FileLoader from '@/components/FileLoader/index.vue'

  import {
    appointmentExpirationSvg,
    delegExpirationSvg,
    optionExpirationSvg
  } from '@/utils/data'
  import Modal from '@/components/Modal/SampleModal.vue'

  export default defineComponent({
    components: {
      MainActions,
      ContactBadge,
      MenuDropdown,
      AlertDisplay,
      SearchModal,
      Modal,
      FileLoader
    },
    props: {
      environment: {
        type: String,
        required: false,
        default: null
      },
      user: {
        type: Object as () => User,
        required: true
      }
    },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const acl = useAcl()
      const loaderRef = ref()
      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )

      const isSearchVisible = ref(false)
      const uploadDocuments = ref(false)

      const openSearchModal = () => {
        isSearchVisible.value = true
      }

      const actions = computed(() => {
        const value = []

        if (acl.can.any(['read-stocks', 'write-stocks'])) {
          value.push({
            name: t('stockGroupBook.title'),
            icon: 'collection',
            type: 'list',
            section: 1,
            key: MAINACTIONS.STOCKGROUP
          })

          value.push({
            name: t('stockBook.title'),
            icon: 'pie-chart',
            type: 'list',
            section: 1,
            key: MAINACTIONS.STOCK
          })
        }

        if (acl.can.any(['read-vestings', 'write-vestings'])) {
          value.push({
            name: t('vestingBook.title'),
            icon: 'file-spreadsheet',
            type: 'list',
            section: 2,
            key: MAINACTIONS.VESTING
          })
        }

        if (acl.can.any(['read-committees', 'write-committees'])) {
          value.push({
            name: t('committeeBook.title'),
            icon: 'diagram-2-fill',
            type: 'list',
            section: 2,
            key: MAINACTIONS.COMMITTEE
          })
        }

        if (acl.can.any(['write-contacts'])) {
          value.push({
            name: t('groupBook.title'),
            icon: 'people-fill',
            type: 'list',
            section: 2,
            key: MAINACTIONS.GROUP
          })
        }

        if (
          acl.can.any(['write-profitSharingPlan', 'read-profitSharingPlan'])
        ) {
          value.push({
            name: t('profitSharingPlanBook.title'),
            icon: 'cash-stack',
            type: 'list',
            section: 3,
            key: MAINACTIONS.PROFIT_SHARING_PLAN
          })
        }

        if (acl.can.any(['write-fundRaisingRound', 'read-fundRaisingRound'])) {
          value.push({
            name: t('fundRaisingRoundBook.title'),
            icon: 'wallet2',
            type: 'list',
            section: 3,
            key: MAINACTIONS.FUNDRAISING_ROUND
          })
        }

        if (acl.can.any(['write-operations', 'read-operations'])) {
          value.push({
            name: t('operationBook.title'),
            icon: 'calendar-range',
            type: 'list',
            section: 4,
            key: MAINACTIONS.OPERATION
          })
        }

        return value
      })

      const productionActions = computed(() => {
        const value = []

        if (acl.can.any(['admin', 'is-manager', 'write-documents'])) {
          value.push({
            name: t('documents.title'),
            icon: 'folder',
            type: 'list',
            section: 1,
            key: MAINACTIONS.DOCUMENTS_UPLAOD
          })
        }
        if (acl.can.any(['admin', 'is-manager', 'write-events'])) {
          value.push({
            name: t('events.title'),
            icon: 'lightning',
            type: 'list',
            section: 1,
            key: MAINACTIONS.NEW_EVENT
          })
        }

        if (acl.can.any(['admin', 'is-manager', 'write-contacts'])) {
          value.push({
            name: t('contactBook.title'),
            icon: 'person-lines-fill',
            type: 'list',
            section: 1,
            key: MAINACTIONS.NEW_CONTACT
          })
        }

        return value
      })

      const icons = {
        [AlertType.appointementExpiration]: appointmentExpirationSvg,
        [AlertType.delegationExpiration]: delegExpirationSvg,
        [AlertType.optionExpiration]: optionExpirationSvg
      }
      const form = ref<any>(null)

      const config = conf as GlobalConfig

      const redirectTo = (action: MAINACTIONS) => {
        if (action == MAINACTIONS.NEW_CONTACT) {
          router.push({
            name: 'company.newContact',
            params: { id: currentCompany.value?.id }
          })
        } else if (action == MAINACTIONS.NEW_EVENT) {
          router.push({
            name: 'createEvent',
            params: { id: currentCompany.value?.id }
          })
        } else if (action === MAINACTIONS.DOCUMENTS_UPLAOD) {
          uploadDocuments.value = true
        }
      }

      const accountActions = computed(() => {
        const d = [
          {
            name: t('baseMenu.account'),
            icon: 'person-circle',
            type: 'list',
            section: 1,
            key: 'seeProfile'
          },

          {
            name: t('baseMenu.logout'),
            icon: 'box-arrow-right',
            type: 'list',
            section: 4,
            key: 'logout'
          }
        ]

        if (acl.can.any(['admin', 'is-manager', 'write-documents'])) {
          d.push(
            ...[
              {
                name: t('baseMenu.docAutomation'),
                icon: 'file-earmark-medical',
                type: 'list',
                section: 2,
                key: 'docAutomation'
              },
              {
                name: t('baseMenu.diagram'),
                icon: 'diagram-3',
                type: 'list',
                section: 3,
                key: 'diagram'
              }
            ]
          )
        }

        return d
      })
      const submitForm = (e: any) => {
        e.preventDefault()
      }

      const cancelFilesUpload = () => {
        loaderRef.value?.closeDropFile()
        uploadDocuments.value = false
      }

      const submitFiles = () => {
        loaderRef.value?.uploadFiles()
      }
      const isMenuCompressed = computed(
        () => store.getters['config/isMenuCompressed']
      )

      const openModal = (value: MAINACTIONS) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: value
        })
      }

      const cancelSearch = () => {
        isSearchVisible.value = false
      }

      const toggleMenu = () => {
        store.commit(`config/${SET_MENU_COMPRESSED}`, !isMenuCompressed.value)
      }

      const navigateToFileList = () => {
        uploadDocuments.value = false
        router.push({
          name: 'companyUpload',
          params: { id: route.params.id }
        })
      }

      const onOptionClicked = (eventKey: string) => {
        if (eventKey == 'seeProfile') {
          router.push({
            name: 'accountInformations',
            params: { id: route.params.id }
          }) //can send route name as key
        }

        if (eventKey == 'logout') {
          router.push({ name: 'auth.logout', params: { id: route.params.id } })
        }

        if (eventKey == 'docAutomation') {
          form.value?.submit()
        }

        if (eventKey === 'diagram') {
          window.open(config.urls.diagramExternalLink, '_blank', 'noreferrer')
        }
      }

      return {
        store,
        form,
        submitFiles,
        isSearchVisible,
        openSearchModal,
        isMenuCompressed,
        moment,
        productionActions,
        toggleMenu,
        icons,
        cancelFilesUpload,
        loaderRef,
        navigateToFileList,
        uploadDocuments,
        currentCompany,
        accountActions,
        actions,
        openModal,
        onOptionClicked,
        submitForm,
        redirectTo,
        cancelSearch
      }
    }
  })
