<template>
  <div class="new-company">
    <h5 class="text-left my-5 text-blackp text-lg">
      {{ $t('contactBook.form.personalInformation') }}
    </h5>
    <Form
      v-slot="{ errors, isSubmitting, setFieldValue }"
      autocomplete="off"
      :validation-schema="schema"
      :initial-values="newContact"
      @submit="handleForm"
    >
      <div class="flex justify-between">
        <div class="w-4/5 flex">
          <profile-avatar-form
            :profile-image="companyLogoUrl"
            type="company"
            @file-changed="fileChanged"
          />
        </div>
      </div>
      <div class="w-full flex justify-between mt-3">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="legalStatus"
            >{{ $t('contactBook.fields.formType') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.legalStatusId"
            name="legalStatusId"
          >
            <Multiselect
              id="stockType"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              :value="newContact.legalStatusId"
              name="legalStatusId"
              :options="professionalLegalStatus"
              :placeholder="$t('contactBook.placeholder.formType')"
              :groups="false"
              @change="newContact.legalStatusId = $event"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-left text-secondary8 font-semibold mt-0"
              for="name"
              >{{ $t('contactBook.fields.denomination') }}</label
            >
            <h5 class="ml-1 text-error h-2 font-bold">*</h5>
          </div>

          <Field v-slot="{ field }" v-model="newContact.name" name="name">
            <Input
              v-model="newContact.name"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="name"
              :placeholder="$t('contactBook.placeholder.denomination')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="commercialName"
            >{{ $t('contactBook.fields.commercialName') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.commercialName"
            name="commercialName"
          >
            <Input
              v-model="newContact.commercialName"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="commercialName"
              :placeholder="$t('contactBook.placeholder.commercialName')"
            />
          </Field>
        </div>
      </div>
      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-left text-secondary8 font-semibold mt-0"
              for="name"
              >{{ $t('contactBook.fields.siren') }}</label
            >
          </div>
          <Field v-slot="{ field }" v-model="newContact.siren" name="siren">
            <Input
              v-model="newContact.siren"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="siren"
              :placeholder="$t('contactBook.placeholder.siren')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="siret"
            >{{ $t('contactBook.fields.siret') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.siret" name="siret">
            <Input
              v-model="newContact.siret"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="siret"
              :placeholder="$t('contactBook.placeholder.siret')"
            />
          </Field>
        </div>
        <div class="w-1/3">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="constitutedAt"
            >{{ $t('contactBook.fields.constitutedAt') }}</label
          >
          <Field
            v-model="newContact.constitutedAt"
            class="h-10"
            name="constitutedAt"
          >
            <UplDatePicker
              v-model="newContact.constitutedAt"
              class="hover:border-primary4 h-10"
              :enable-time-picker="false"
              :placeholder="$t('contactBook.placeholder.constitutedAt')"
              close-on-scroll
            />
          </Field>
        </div>
      </div>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-left text-secondary8 font-semibold mt-0"
              for="tva"
              >{{ $t('contactBook.fields.tva') }}</label
            >
          </div>
          <Field v-slot="{ field }" v-model="newContact.tva" name="tva">
            <Input
              v-model="newContact.tva"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="tva"
              :placeholder="$t('contactBook.placeholder.tva')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-left text-secondary8 font-semibold mt-0"
              for="rcsCity"
              >{{ $t('contactBook.fields.rcsCity') }}</label
            >
          </div>
          <Field v-slot="{ field }" v-model="newContact.rcsCity" name="rcsCity">
            <Input
              v-model="newContact.rcsCity"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="rcsCity"
              :placeholder="$t('contactBook.placeholder.rcsCity')"
            />
          </Field>
        </div>
        <div class="w-1/3">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="address"
            >{{ $t('contactBook.fields.address') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.address" name="address">
            <AddressInput
              v-model="newContact.address"
              v-bind="field"
              class="h-10"
              name="address"
              :placeholder="$t('contactBook.placeholder.address')"
              @onChange="onAddressInput($event)"
            />
          </Field>
        </div>
      </div>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="postalCode"
            >{{ $t('contactBook.fields.postalCode') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.postalCode"
            name="postalCode"
          >
            <Input
              v-model="newContact.postalCode"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="postalCode"
              :placeholder="$t('contactBook.placeholder.postalCode')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="city"
            >{{ $t('contactBook.fields.city') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.city" name="city">
            <Input
              v-model="newContact.city"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="city"
              :placeholder="$t('contactBook.placeholder.city')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="country"
            >{{ $t('contactBook.fields.country') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.country" name="country">
            <Input
              v-model="newContact.country"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="country"
              :placeholder="$t('contactBook.placeholder.country')"
            />
          </Field>
        </div>
      </div>

      <h5 class="text-left my-5 text-blackp text-lg">
        {{ $t('contactBook.form.moreInformations') }}
      </h5>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="email"
            >{{ $t('contactBook.fields.email') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.email" name="email">
            <Input
              v-model="newContact.email"
              v-bind="field"
              :disabled="false"
              type="text"
              name="email"
              :placeholder="$t('contactBook.placeholder.email')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="phoneNumber"
            >{{ $t('contactBook.fields.phoneNumber') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.phoneNumber"
            name="phoneNumber"
          >
            <Input
              v-model="newContact.phoneNumber"
              v-bind="field"
              :disabled="false"
              type="text"
              name="phoneNumber"
              :placeholder="$t('contactBook.placeholder.phoneNumber')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="website"
            >{{ $t('contactBook.fields.website') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.website" name="website">
            <Input
              v-model="newContact.website"
              v-bind="field"
              class=""
              :disabled="false"
              type="text"
              name="website"
              :placeholder="$t('contactBook.placeholder.website')"
            />
          </Field>
        </div>
      </div>

      <h5 class="text-left my-5 text-blackp text-lg">
        {{ $t('contactBook.form.accountInfo') }}
      </h5>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-left text-secondary8 font-semibold mt-0"
            for="email"
            >{{ $t('contactBook.fields.lastPricePerAction') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.lastPricePerAction"
            name="lastPricePerAction"
          >
            <Input
              v-model="newContact.lastPricePerAction"
              v-bind="field"
              :disabled="false"
              type="text"
              name="lastPricePerAction"
              :placeholder="$t('contactBook.placeholder.lastPricePerAction')"
            />
          </Field>
        </div>
      </div>

      <h5 class="text-left my-5 text-blackp text-lg">
        <i class="la la-gear"></i> {{ $t('contactBook.form.companyDetails') }}
      </h5>

      <div class="w-full flex flex-col justify-between mt-2">
        <div
          class="
            flex
            justify-start
            items-center
            w-full
            border-2 border-secondary6
            rounded
            px-3
            py-2
          "
        >
          <div class="flex justify-start items-start">
            <Field
              v-model="newContact.contactCanSimulatePotentialGain"
              type="checkbox"
              :value="true"
              name="contactCanSimulatePotentialGain"
            >
              <Checkbox
                name="contactCanSimulatePotentialGain"
                :checked="newContact.contactCanSimulatePotentialGain"
                @onChange="
                  setFieldValue('contactCanSimulatePotentialGain', $event),
                    (newContact.contactCanSimulatePotentialGain = $event)
                "
              />
            </Field>
          </div>
          <label
            class="text-md text-start break-words text-secondary9 ml-2 mb-0"
            >{{ $t('contactBook.form.canSimulate') }}</label
          >
        </div>
        <div
          class="
            flex
            justify-start
            items-center
            w-full
            border-2 border-secondary6
            rounded
            px-3
            py-2
            my-2
          "
        >
          <div class="flex justify-start items-start">
            <Field
              v-model="newContact.useMFA"
              type="checkbox"
              :value="true"
              name="useMFA"
            >
              <Checkbox
                name="useMFA"
                :checked="newContact.useMFA"
                @onChange="
                  setFieldValue('useMFA', $event), (newContact.useMFA = $event)
                "
              />
            </Field>
          </div>
          <label
            class="text-md text-start break-words text-secondary9 ml-2 mb-0"
            >{{ $t('contactBook.form.useMFA') }}</label
          >
        </div>
      </div>

      <div class="relative w-full flex justify-between items-center">
        <div class="border-none border-r-0 flex flex-col items-start">
          <li
            v-for="(error, index) in errors"
            v-show="errors"
            :key="index"
            class="text-xs text-error"
          >
            {{ $t(`errors.${error}`) }}
          </li>
        </div>
        <div class="flex h-10 mt-8">
          <Button
            v-if="mode === 'create'"
            type="reset"
            variant="tertiary"
            :disabled="isSubmitting"
            size="medium"
            :label="$t('newCompany.back')"
            class="
              form-btn
              flex
              justify-center
              items-center
              py-5
              px-5
              text-xl text-center text-error
              mr-2
            "
            @click.prevent="$emit('previousStep')"
          />
          <Button
            type="submit"
            variant="secondary"
            size="medium"
            :disabled="isSubmitting"
            :label="$t(`newCompany.${mode}-1`)"
            class="
              form-btn
              flex
              justify-center
              items-center
              py-5
              px-5
              text-xl text-center text-white
            "
          />
        </div>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, watch, onMounted } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Form, Field, useForm } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { Company, ContactStatus, LegalStatus } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CompanyModelForm, CONTACTTYPE } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { CompanyModel } from '@/services/api/models1'
  import ProfileAvatarForm from '@/components/Forms/Configurable/Contact/ProfileAvatarForm.vue'
  import { comapnySchema } from '@/utils/schema/contact'
  import { TYPE, useToast } from 'vue-toastification'
  import AddressInput from '@/components/Address/index.vue'
  import { AddressData } from '@/types/config'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    components: {
      Form,
      Checkbox,
      Field,
      Multiselect,
      Button,
      Input,
      UplDatePicker,
      ProfileAvatarForm,
      AddressInput
    },
    props: {
      canSubmit: {
        type: Boolean,
        required: true
      },
      mode: {
        type: String,
        required: false,
        default: 'create'
      },
      company: {
        type: Object as () => CompanyModelForm,
        required: false,
        default: () => ({})
      }
    },
    emits: ['submitForm', 'saved', 'previousStep', 'toggleModal'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const toast = useToast()
      const fileToUpload = ref<File | null>(null)
      const getLegalStatus = () => {
        store.dispatch('toolbox/GET_LEGAL_STATUS')
      }
      const companiesLogos = computed(
        () => store.getters['organizations/companiesLogo']
      )
      const { validate } = useForm()

      const canSubmitForm = ref(props.canSubmit)

      const newContact = ref<CompanyModelForm>(
        props.company || {
          email: '',
          constitutedAt: '',
          phoneNumber: '',
          website: '',
          address: null,
          additionalAddress: '',
          country: '',
          lastPricePerAction: '',
          city: '',
          rcsCity: '',
          contactCanSimulatePotentialGain: false,
          useMFA: false,
          postalCode: '',
          name: '',
          siren: '',
          tva: null,
          siret: '',
          commercialName: '',
          legalStatusId: null
        }
      )
      const companyLogoUrl = computed(
        () => companiesLogos.value[newContact.value?.id || '']
      )

      const contacts = computed<CompanyModel[]>(
        () => store.getters['organizations/contacts']
      )

      const legalStatus = computed<LegalStatus[]>(
        () => store.getters['toolbox/legalStatus']
      )
      const professionalLegalStatus = computed<LegalStatus[]>(() =>
        legalStatus.value.filter(
          (x) => x.contactType === CONTACTTYPE.PROFESSIONAL
        )
      )

      const contactGroups = computed<ContactStatus[]>(
        () => store.getters['toolbox/statusGroups']
      )
      const status = computed(() =>
        contactGroups.value.filter((x) => x.type === CONTACTTYPE.PROFESSIONAL)
      )
      const schema = comapnySchema

      const handleForm = async (formData: CompanyModelForm) => {
        let action = 'CREATE_NEW_COMPANY'

        let formProps = {
          data: {
            ...newContact.value,
            phoneNumber: formData.phoneNumber || '',
            legalStatusId: formData.legalStatusId || null,
            lastPricePerAction:
              parseFloat(formData?.lastPricePerAction ?? '') || null
          },
          companyId: ''
        }

        if (props.mode === 'edit') {
          action = 'UPDATE_COMPANY'
          formProps.companyId = props.company.id ?? ''
        } else {
          displayNotification()
        }
        updateProfileImage()
        await store.dispatch(`organizations/${action}`, {
          ...formProps,
          onError: (error = null) => {
            toast.clear()
            const message =
              error ||
              t(
                `newCompany.apiResponse.${
                  props.mode === 'edit' ? 'edit' : 'create'
                }Error`
              )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('newCompany.title'),
              message
            })
          },
          onSuccess: (data: Company) => {
            emit('toggleModal', false)
            toast.clear()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('newCompany.title'),
              message: t(
                `newCompany.apiResponse.${
                  props.mode === 'edit' ? 'edited' : 'created'
                }`
              ),
              loader: true,
              loadingIcon: false
            })
            handleReset()
            setTimeout(() => {
              toast.clear()
            }, 5000)
            emit('saved', data)
          }
        })
      }

      const updateProfileImage = () => {
        if (!fileToUpload.value) return
        const form = new FormData()
        form.append('fileName', fileToUpload.value?.name)
        form.append('file', fileToUpload.value)
        store.dispatch('organizations/UPDATE_COMPANY_LOGO', {
          companyId: props.company.id,
          form,
          onProgress: () => {
            console.log()
          },
          onSuccess: () => {
            fileToUpload.value = null
            store.commit('organizations/SET_COMPANY_LOGO', {
              companyId: props.company.id,
              url: null
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message: t(`contactBook.form.notSaved`)
            })
          }
        })
      }

      const displayNotification = () => {
        store.dispatch('toolbox/displayToast', {
          type: TYPE.INFO,
          context: t('newCompany.title'),
          message: t('newCompany.loadingAdditionalData'),
          loader: true,
          loadingIcon: true
        })
      }

      const onAddressInput = ($event: AddressData) => {
        newContact.value.postalCode = $event.postalCode
        newContact.value.city = $event.city
        newContact.value.country = $event.country
        newContact.value.address = $event.address
      }

      const handleReset = () => {
        // newContact.value = newValues()
      }

      const fileChanged = (file: File) => {
        fileToUpload.value = file
      }

      watch(
        () => props.canSubmit,
        (canSubmit) => {
          if (canSubmitForm.value) validate()
          canSubmitForm.value = false
        }
      )

      watch(props, (newProps) => {
        if (newProps.company) {
          newContact.value = { ...newContact.value, ...newProps.company }
          newContact.value.commercialName = newProps.company.name
          ;(newContact.value.constitutedAt = newProps.company.constitutedAt
            ? newProps.company.constitutedAt?.split('T')[0]
            : ''),
            (newContact.value.legalStatusId = newProps.company.legalStatusId)
        }
      })

      onMounted(() => {
        getLegalStatus()
      })

      return {
        newContact,
        status,
        contacts,
        schema,
        companyLogoUrl,
        professionalLegalStatus,
        handleForm,
        handleReset,
        onAddressInput,
        fileChanged
      }
    }
  })
</script>

<style lang="scss" scoped>
  .new-company {
    .upl--main-block {
      @apply h-10;
    }
  }
  @import 'companyCreator.scss';
</style>
