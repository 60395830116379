<template>
  <div
    :class="`mt-3
      flex
      w-full
      justify-start
      items-center
      border 
      p-3
      cursor-pointer ${isSelected ? 'border-primary4' : 'border-secondary3'}`"
  >
    <el-radio
      v-model="selectedCompany"
      :label="company.siret"
      size="default"
      class="w-full"
    >
      <div
        class="flex justify-center flex-col items-start m-0 py-2"
        :for="company.siret"
      >
        <div class="flex">
          <h5 class="mr-2">{{ company.denomination }}</h5>
          <h5 class="m-0 text-primary4">{{ company.siren }}</h5>
        </div>
        <address>{{ company?.address }}</address>
      </div>
    </el-radio>
  </div>
</template>

<script lang="ts">
  import { ElRadio } from 'element-plus'

  import { defineComponent, ref } from 'vue'
  interface CompanyResult {
    constitutedAt: string
    siren: string
    siret: string
    denomination: string
    address?: string
  }
  export default defineComponent({
    components: { ElRadio },
    props: {
      company: {
        type: Object as () => CompanyResult,
        required: true
      },
      isSelected: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      const selectedCompany = ref<string>('')
      return { selectedCompany }
    },
    data() {
      return {
        selectedCompany1: null
      }
    }
  })
</script>
<style lang="scss" scoped>
  .el-radio {
    margin: 0;
  }
</style>
