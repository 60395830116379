<template>
  <div class="w-full mb-5 flex flex-col">
    <div class="flex justify-between w-full my-5">
      <div class="flex w-4/5 lg:w-3/5 xl:w-3/5">
        <div class="w-1/3 lg:w-1/5 xl:w-1/5 h-10 mr-2">
          <Field
            v-slot="{ field }"
            v-model="filter.delegationId"
            name="delegationId"
          >
            <Multiselect
              v-model="filter.delegationId"
              class="h-10"
              :options="delegations"
              :placeholder="$t('global.placeholder.delegation')"
              name="delegationId"
              v-bind="field"
            />
          </Field>
        </div>

        <div class="w-1/3 lg:w-1/5 xl:w-1/5 flex mr-2 h-10">
          <Field v-slot="{ field }" v-model="filter.contactId" name="contactId">
            <Multiselect
              v-model="filter.contactId"
              :can-add-new="false"
              open-direction="bottom"
              :has-label="false"
              :value="filter.contactId"
              mode="single"
              :options="contacts"
              :placeholder="$t('global.placeholder.contact')"
              name="contactId"
              :groups="false"
              v-bind="field"
            />
          </Field>
        </div>

        <div class="w-1/3 lg:w-1/5 xl:w-1/5">
          <Field
            v-slot="{ field }"
            v-model="selectedStocks"
            name="stocks"
            as=""
            class="w-full"
          >
            <DropdownStocks
              :has-label="false"
              :value="selectedStocks"
              open-direction="bottom"
              :tag-with-initials="false"
              mode="tags"
              :filter-results="false"
              :can-add-new="false"
              :placeholder="$t('global.placeholder.stocks')"
              name="search"
              :options="stocks"
              :groups="true"
              group-options-field="children"
              v-bind="field"
              :option-per-price="false"
              @on-updated="updateStockFilter"
            />
          </Field>
        </div>
      </div>

      <div>
        <DownloadButton
          v-if="!isDownloading"
          class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
          :on-click="download"
        ></DownloadButton>

        <el-tooltip
          v-else-if="isDownloading"
          class="box-item"
          effect="dark"
          :content="$t('tooltips.downloading')"
          placement="top"
        >
          <div
            class="
              border
              rounded-3
              cursor-not-allowed
              bg-white
              border-secondary6
              px-3
              flex
              items-center
            "
          >
            <i
              class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"
            ></i>
          </div>
        </el-tooltip>
      </div>
    </div>

    <div class="text-sm mt-2">
      <DataTable
        v-if="delegatedTransactions.length"
        :has-checkbox="false"
        :columns="columns"
        :rows="delegatedTransactions"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #stock="{ row }">
          <Tag
            v-if="row.stockAlias"
            :with-initials="false"
            :text="row.stockAlias.alias.toUpperCase()"
            :value="row.stockAlias.id"
            :with-remove="false"
          />
          <Tag
            v-else-if="row.optionAlias"
            :text="row.optionAlias.alias.toUpperCase()"
            :value="row.optionAlias.id"
            :with-remove="false"
            :with-initials="false"
          />
          <Tag
            v-else-if="row.compositionAlias"
            :text="row.compositionAlias.alias.toUpperCase()"
            :value="row.compositionAlias.id"
            :with-remove="false"
            :with-initials="false"
          />
          <p v-else>-</p>
        </template>
        <template #quantity="{ row }">
          <p v-if="row.stockIssuancePart" class="text-left">
            {{ formatNumber(row.stockIssuancePart.quantity) }}
          </p>
          <p v-else-if="row.optionIssuancePart" class="text-left">
            {{ formatNumber(row.optionIssuancePart.quantity) }}
          </p>
          <p v-else-if="row.compositionIssuancePart" class="text-left">
            {{ formatNumber(row.compositionIssuancePart.quantity) }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #delegation="{ row }">
          <p v-if="row.stockIssuancePart" class="text-left">
            {{ row.stockIssuancePart.stockIssuance.delegation.label }}
          </p>
          <p v-else-if="row.optionIssuancePart" class="text-left">
            {{ row.optionIssuancePart.optionIssuance.delegation.label }}
          </p>
          <p v-else-if="row.compositionIssuancePart" class="text-left">
            {{
              row.compositionIssuancePart.compositionIssuance.delegation.label
            }}
          </p>
          <p v-else class="text-left">-</p>
        </template>

        <template #contact="{ row }">
          <router-link
            class="cursor-pointer"
            :to="{
              name: 'company.directory',
              params: {
                id: $route.params.id,
                contactId: row.associateAccount.contact.id
              }
            }"
            ><ContactBadge
              :contact="row.associateAccount.contact"
              class="flat light"
              type="light"
          /></router-link>
        </template>
        <template #date="{ row }">
          <p class="text-left">{{ moment.utc(row.createdAt).format('L') }}</p>
        </template>
        <template #eventName="{ row }">
          <p
            v-if="getEventName(row)"
            class="text-left cursor-pointer"
            @click="gotoEvent(getEventId(row))"
          >
            {{ $t(`events.labels.${getEventName(row)}`) }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
      </DataTable>
      <div
        v-else
        class="
          flex
          justify-center
          items-center
          w-full
          mt-4
          transition transition-all
        "
      >
        <EmptyState
          image="no-result.svg"
          class="d-block mx-auto"
          :title="$t('global.noResult')"
          :subtitle="$t('global.noData')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { delegationHolderColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { formatNumber } from '@/utils/global'
  import moment from 'moment'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import {
    CompositionStockAlias,
    DelegationTransaction,
    OptionStockAlias,
    StockAlias
  } from '@/services/api/models'
  import { STOCKTYPE } from '@/types'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { useI18n } from 'vue-i18n'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      Field,
      Multiselect,
      DataTable,
      ContactBadge,
      EmptyState,
      Tag,
      ElTooltip,
      DownloadButton,
      DropdownStocks
    },
    setup() {
      const search = ref('')
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const selectedStocks = ref<string[]>([])
      const { t } = useI18n()
      const filter = ref<any>({
        contactId: '',
        delegationId: '',
        optionAliasesId: [],
        stockAliasesId: [],
        compositionAliasesId: []
      })
      const columns = computed(() => delegationHolderColumns)
      const delegatedTransactions = computed<DelegationTransaction[]>(
        () => store.getters['organizations/delegatedTransactions']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )
      const isDownloading = ref(false)

      const contacts = computed(() =>
        delegatedTransactions.value.map((x) => x?.associateAccount?.contact)
      )

      const stocks = computed(() => {
        const value = []
        const smp: any = {
          id: '1',
          name: t('companyStockType.SIMPLE'),
          label: t('companyStockType.SIMPLE'),
          children: []
        }

        const cmp: any = {
          id: '3',
          name: t('companyStockType.COMPOSED'),
          label: t('companyStockType.COMPOSED'),
          children: []
        }
        const cmb: any = {
          id: '2',
          name: t('companyStockType.COMBINED'),
          label: t('companyStockType.COMBINED'),
          children: []
        }

        delegatedTransactions.value.forEach((x) => {
          if (
            x.optionAlias &&
            !cmb.children
              .map((c: OptionStockAlias) => c.id)
              .includes(x.optionAlias?.id)
          )
            cmb.children.push({ ...x.optionAlias, name: x.optionAlias.alias })

          if (
            x.stockAlias &&
            !smp.children
              .map((c: StockAlias) => c.id)
              .includes(x.stockAlias?.id)
          )
            smp.children.push({ ...x.stockAlias, name: x.stockAlias.alias })

          if (
            x.compositionAlias &&
            !cmp.children
              .map((c: CompositionStockAlias) => c.id)
              .includes(x.compositionAlias?.id)
          )
            cmp.children.push({
              ...x.compositionAlias,
              name: x.compositionAlias.alias
            })
        })

        value.push(...[smp, cmp, cmb])

        return value
      })

      const pagination = computed(
        () => store.getters['organizations/delegTransactionsPagination']
      )

      const onPageChange = (page: number) => {
        store.commit(
          'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
          page
        )
        getTransactions()
      }
      const getEventName = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (exercise?.event) return exercise.event.type
        else return row.eventType
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const getEventId = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (exercise?.event) return exercise.event.id
        else return null
      }

      const updateStockFilter = ({ value }: any) => {
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }

      const getTransactions = () => {
        const data = filter.value
        if (!filter.value.contactId) delete data.contactId
        store.dispatch('organizations/GET_DELEGATIONS_TRANSACTIONS', {
          companyId: route.params.id,
          filter: filter.value
        })
      }

      const download = () => {
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_DELEGATIONS_BY_HOLDER', {
          name: `${t('exports.delegations')}`,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const getDelegations = () => {
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: {}
        })
      }
      onMounted(() => {
        store.commit(
          'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
          1
        )
        getTransactions()
      })

      watch(
        [
          () => filter.value.stockAliasesId,
          () => filter.value.optionAliasesId,
          () => filter.value.compositionAliasesId,
          () => filter.value.contactId,
          () => filter.value.delegationId
        ],
        () => {
          store.commit(
            'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
            1
          )
          getTransactions()
        }
      )

      watch(
        () => route.params.id,
        () => {
          store.commit(
            'organizations/SET_DELEGATED_TRANSACTION_PAGINATION_PAGE',
            1
          )
          getDelegations()
          getTransactions()
        }
      )

      return {
        search,
        isDownloading,
        stocks,
        delegatedTransactions,
        columns,
        delegations,
        pagination,
        contacts,
        filter,
        download,
        selectedStocks,
        updateStockFilter,
        formatNumber,
        moment,
        getEventName,
        getEventId,
        onPageChange,
        gotoEvent
      }
    }
  })
</script>
