<template>
  <div>
    <header
      :class="[
        { 'layout_header--minified': isMenuCompressed },
        'layout_header flex justify-between relative px-4'
      ]"
    >
      <div class="flex justify-start items-center w-1/3">
        <div
          v-if="!isMenuCompressed"
          class="
            flex-initial
            layout_header-logo
            flex
            justify-start
            items-center
          "
        >
          <img
            src="@/assets/images/logo/logo_white.png"
            class="h-12"
            alt="logo uplaw"
          />
          <div class="layout_header-headerMinifier" @click="toggleMenu()">
            <i class="bi-arrow-up-square text-white" />
          </div>
        </div>
        <div
          v-if="environment && !isMenuCompressed"
          class="
            layout_registerLoader--active
            border border-warning
            rounded
            py-1
            px-1
            mx-2
          "
        >
          <div class="layout_registerLoader-inner">
            <p class="mx-0 text-warning">You are on : {{ environment }}</p>
          </div>
        </div>
      </div>
      <div
        v-if="isMenuCompressed"
        class="layout_header-signet w-1/3"
        @click="toggleMenu()"
      >
        <img
          src="~@/assets/images/logo/logo_uplaw_signet.png"
          alt="expand menu"
          width="16"
        />
      </div>
      <div
        v-else
        class="flex justify-end items-center h-full content-center w-1/3"
      >
        <div
          v-if="user"
          class="layout_header-user flex justify-center items-center"
        >
          <div
            v-can.any="[
              'admin',
              'is-manager',
              'write-contacts',
              'write-events',
              'write-documents'
            ]"
            @click.prevent.stop="openSearchModal"
            class="px-4 cursor-pointer text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
          <div
            class="mx-4"
            v-can.any="[
              'admin',
              'is-manager',
              'write-contacts',
              'write-events',
              'write-documents'
            ]"
          >
            <MenuDropdown :actions="productionActions" @on-click="redirectTo">
              <div class="inline flex items-center">
                <h3 class="font-thin text-white px-1 text-sm">
                  {{ $t('global.actions.add') }}
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </div>
            </MenuDropdown>
          </div>
          <div
            class="mx-4"
            v-can.any="[
              'admin',
              'is-manager',
              'write-contacts',
              'write-events',
              'write-documents'
            ]"
          >
            <MenuDropdown
              :actions="actions"
              caller-class="hover:bg-white hover:bg-opacity-20 py-2 px-4 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              @on-click="openModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                />
              </svg>
            </MenuDropdown>
          </div>
          <div class="mr-2" v-can.any="['admin', 'is-manager']">
            <AlertDisplay />
          </div>
          <slot name="list"></slot>
          <div class="layout_header-userProfile cursor-default"></div>

          <MainActions />
          <SearchModal :isVisible="isSearchVisible" @cancel="cancelSearch" />
          <div class="layout_header-userMore">
            <div class="ml-2">
              <MenuDropdown
                :actions="accountActions"
                caller-class="py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                @on-click="onOptionClicked"
              >
                <ContactBadge
                  :contact="{
                    name: user.name,
                    lastName: user.lastName,
                    id: user.id,
                    firstName: user.firstName,
                    documentsCount: 0,
                    stocksCount: 0
                  }"
                  :initialsOnly="true"
                  :details="false"
                  class="light"
                  mode="user"
                />
              </MenuDropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Modal
      :title="$t('fileLoader.title')"
      :isVisible="uploadDocuments"
      @cancel="uploadDocuments = false"
      @submit="submitFiles"
      ><FileLoader ref="loaderRef" />
      <template #left>
        <a
          class="m-0 open-historic cursor-pointer text-primary4"
          @click="navigateToFileList"
        >
          {{ $t('fileLoader.historic') }}
        </a>
      </template>
    </Modal>
    <form
      v-show="false"
      ref="form"
      action="https://dashboard.docupilot.app/login"
      method="GET"
      target="_blank"
      @submit="submitForm"
    ></form>
  </div>
</template>

<script lang="ts">
  import { SET_MENU_COMPRESSED } from '@/store/modules/Config'
  import { computed, defineComponent, ref } from 'vue'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { Company, User } from '@/services/api/models'
  import { useAcl } from 'vue-simple-acl/src'
  import { AlertType, MAINACTIONS } from '@/types/config'
  import moment from 'moment'
  import conf from '@/config'
  import { GlobalConfig } from '@/types'
  import MenuDropdown from '@/components/MenuDropdown/index.vue'
  import AlertDisplay from '@/components/AlertDisplay/index.vue'
  import SearchModal from '@/components/Modal/SearchModal.vue'
  import MainActions from '@/components/MainActionList/index.vue'
  import FileLoader from '@/components/FileLoader/index.vue'

  import {
    appointmentExpirationSvg,
    delegExpirationSvg,
    optionExpirationSvg
  } from '@/utils/data'
  import Modal from '@/components/Modal/SampleModal.vue'

  export default defineComponent({
    components: {
      MainActions,
      ContactBadge,
      MenuDropdown,
      AlertDisplay,
      SearchModal,
      Modal,
      FileLoader
    },
    props: {
      environment: {
        type: String,
        required: false,
        default: null
      },
      user: {
        type: Object as () => User,
        required: true
      }
    },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const acl = useAcl()
      const loaderRef = ref()
      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )

      const isSearchVisible = ref(false)
      const uploadDocuments = ref(false)

      const openSearchModal = () => {
        isSearchVisible.value = true
      }

      const actions = computed(() => {
        const value = []

        if (acl.can.any(['read-stocks', 'write-stocks'])) {
          value.push({
            name: t('stockGroupBook.title'),
            icon: 'collection',
            type: 'list',
            section: 1,
            key: MAINACTIONS.STOCKGROUP
          })

          value.push({
            name: t('stockBook.title'),
            icon: 'pie-chart',
            type: 'list',
            section: 1,
            key: MAINACTIONS.STOCK
          })
        }

        if (acl.can.any(['read-vestings', 'write-vestings'])) {
          value.push({
            name: t('vestingBook.title'),
            icon: 'file-spreadsheet',
            type: 'list',
            section: 2,
            key: MAINACTIONS.VESTING
          })
        }

        if (acl.can.any(['read-committees', 'write-committees'])) {
          value.push({
            name: t('committeeBook.title'),
            icon: 'diagram-2-fill',
            type: 'list',
            section: 2,
            key: MAINACTIONS.COMMITTEE
          })
        }

        if (acl.can.any(['write-contacts'])) {
          value.push({
            name: t('groupBook.title'),
            icon: 'people-fill',
            type: 'list',
            section: 2,
            key: MAINACTIONS.GROUP
          })
        }

        if (
          acl.can.any(['write-profitSharingPlan', 'read-profitSharingPlan'])
        ) {
          value.push({
            name: t('profitSharingPlanBook.title'),
            icon: 'cash-stack',
            type: 'list',
            section: 3,
            key: MAINACTIONS.PROFIT_SHARING_PLAN
          })
        }

        if (acl.can.any(['write-fundRaisingRound', 'read-fundRaisingRound'])) {
          value.push({
            name: t('fundRaisingRoundBook.title'),
            icon: 'wallet2',
            type: 'list',
            section: 3,
            key: MAINACTIONS.FUNDRAISING_ROUND
          })
        }

        if (acl.can.any(['write-operations', 'read-operations'])) {
          value.push({
            name: t('operationBook.title'),
            icon: 'calendar-range',
            type: 'list',
            section: 4,
            key: MAINACTIONS.OPERATION
          })
        }

        return value
      })

      const productionActions = computed(() => {
        const value = []

        if (acl.can.any(['admin', 'is-manager', 'write-documents'])) {
          value.push({
            name: t('documents.title'),
            icon: 'folder',
            type: 'list',
            section: 1,
            key: MAINACTIONS.DOCUMENTS_UPLAOD
          })
        }
        if (acl.can.any(['admin', 'is-manager', 'write-events'])) {
          value.push({
            name: t('events.title'),
            icon: 'lightning',
            type: 'list',
            section: 1,
            key: MAINACTIONS.NEW_EVENT
          })
        }

        if (acl.can.any(['admin', 'is-manager', 'write-contacts'])) {
          value.push({
            name: t('contactBook.title'),
            icon: 'person-lines-fill',
            type: 'list',
            section: 1,
            key: MAINACTIONS.NEW_CONTACT
          })
        }

        return value
      })

      const icons = {
        [AlertType.appointementExpiration]: appointmentExpirationSvg,
        [AlertType.delegationExpiration]: delegExpirationSvg,
        [AlertType.optionExpiration]: optionExpirationSvg
      }
      const form = ref<any>(null)

      const config = conf as GlobalConfig

      const redirectTo = (action: MAINACTIONS) => {
        if (action == MAINACTIONS.NEW_CONTACT) {
          router.push({
            name: 'company.newContact',
            params: { id: currentCompany.value?.id }
          })
        } else if (action == MAINACTIONS.NEW_EVENT) {
          router.push({
            name: 'createEvent',
            params: { id: currentCompany.value?.id }
          })
        } else if (action === MAINACTIONS.DOCUMENTS_UPLAOD) {
          uploadDocuments.value = true
        }
      }

      const accountActions = computed(() => {
        const d = [
          {
            name: t('baseMenu.account'),
            icon: 'person-circle',
            type: 'list',
            section: 1,
            key: 'seeProfile'
          },

          {
            name: t('baseMenu.logout'),
            icon: 'box-arrow-right',
            type: 'list',
            section: 4,
            key: 'logout'
          }
        ]

        if (acl.can.any(['admin', 'is-manager', 'write-documents'])) {
          d.push(
            ...[
              {
                name: t('baseMenu.docAutomation'),
                icon: 'file-earmark-medical',
                type: 'list',
                section: 2,
                key: 'docAutomation'
              },
              {
                name: t('baseMenu.diagram'),
                icon: 'diagram-3',
                type: 'list',
                section: 3,
                key: 'diagram'
              }
            ]
          )
        }

        return d
      })
      const submitForm = (e: any) => {
        e.preventDefault()
      }

      const cancelFilesUpload = () => {
        loaderRef.value?.closeDropFile()
        uploadDocuments.value = false
      }

      const submitFiles = () => {
        loaderRef.value?.uploadFiles()
      }
      const isMenuCompressed = computed(
        () => store.getters['config/isMenuCompressed']
      )

      const openModal = (value: MAINACTIONS) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: value
        })
      }

      const cancelSearch = () => {
        isSearchVisible.value = false
      }

      const toggleMenu = () => {
        store.commit(`config/${SET_MENU_COMPRESSED}`, !isMenuCompressed.value)
      }

      const navigateToFileList = () => {
        uploadDocuments.value = false
        router.push({
          name: 'companyUpload',
          params: { id: route.params.id }
        })
      }

      const onOptionClicked = (eventKey: string) => {
        if (eventKey == 'seeProfile') {
          router.push({
            name: 'accountInformations',
            params: { id: route.params.id }
          }) //can send route name as key
        }

        if (eventKey == 'logout') {
          router.push({ name: 'auth.logout', params: { id: route.params.id } })
        }

        if (eventKey == 'docAutomation') {
          form.value?.submit()
        }

        if (eventKey === 'diagram') {
          window.open(config.urls.diagramExternalLink, '_blank', 'noreferrer')
        }
      }

      return {
        store,
        form,
        submitFiles,
        isSearchVisible,
        openSearchModal,
        isMenuCompressed,
        moment,
        productionActions,
        toggleMenu,
        icons,
        cancelFilesUpload,
        loaderRef,
        navigateToFileList,
        uploadDocuments,
        currentCompany,
        accountActions,
        actions,
        openModal,
        onOptionClicked,
        submitForm,
        redirectTo,
        cancelSearch
      }
    }
  })
</script>

<style lang="scss" scoped>
  .layout_header {
    flex: 0 1 auto;
    position: relative;
    width: 100%;
    height: 64px;
    background: var(--color-header);
    transition: all 0.2s cubic-bezier(0.75, -0.22, 0.24, 1.31);

    &.layout_header--minified {
      min-height: 8px;
      height: 8px;
    }

    .contactBadge .contactBadge__name {
      color: var(--color-header-contrast) !important;
    }

    .layout_header-signet {
      position: absolute;
      top: 0;
      @apply z-50;
      right: 284px;
      width: 32px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-header) no-repeat top center;
      -webkit-mask-image: url('~@/assets/svg/signet.svg');
      mask-image: url('~@/assets/svg/signet.svg');
      cursor: pointer;
    }

    .layout_header-signet > svg > path {
      fill: var(--color-header-contrast);
    }

    .layout_header-logo {
      .layout_header-headerMinifier {
        margin-left: 10px;
        font-size: 16px;
        color: var(--color-contrast-0);
        cursor: pointer;
      }

      .bi {
        color: var(--color-header-contrast);
        font-size: 14px;
      }
    }

    .layout_header-user {
      height: 100%;
      //@include flex-between-center;

      .bi {
        // color: var(--color-header-contrast);
        font-size: 16px;
      }

      .layout_header-userNotifications {
        width: 72px;
        height: 64px;
        position: relative;
        cursor: pointer;
        border: 0;
        // //@include flex-center-center;

        & .icon {
          font-size: 20px;
          color: #fff;
        }

        &.layout_header-userNotifications--active {
          &:before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: var(--color-header-contrast);
            transform: translate(12px, -12px);
          }
        }
      }

      .layout_header-userProfile {
        color: var(--color-header-contrast);
      }

      .layout_header-userMore {
        color: var(--color-header-contrast);
        cursor: pointer;
      }
    }
  }
</style>
