
  import { defineComponent, ref, computed } from 'vue'
  import moment from 'moment'
  import { Document } from '@/services/api/models'
  import { CardType } from '@/types/config'
  import DocumentActions from './DocumentActions.vue'
  import { useI18n } from 'vue-i18n'
  import DataTable from '../DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'DocumentCard',
    components: {
      DocumentActions,
      DataTable
    },
    props: {
      document: {
        type: Object as () => Document,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select-card', 'collapse'],
    setup(props, { emit }) {
      const router = useRouter()
      const route = useRoute()
      const document = ref<Document>(props.document)
      const displayed = ref(true)
      const { t } = useI18n()
      const currentSide = ref<CardType>(CardType.DOCUMENT)
      const toggleAudit = ref(false)
      const isSelected = ref(false)
      const eventColumns = computed(() => [
        {
          label: t('events.elements.eventDate'),
          field: 'date',
          custom: true,
          width: ''
        },
        {
          label: t('events.elements.eventCreatedDate'),
          field: 'createdAt',
          custom: true,
          width: ''
        },
        {
          label: t('events.elements.eventName'),
          field: 'name',
          custom: true,
          width: ''
        }
      ])
      const toggleSide = (what: CardType) => {
        currentSide.value = what
      }
      const collapseCard = () => {
        emit('collapse', !displayed.value)
      }
      const selectCard = (data: { check: boolean; id: string }) => {
        isSelected.value = data.check
        emit('select-card', data)
      }

      const isSigned = computed(() => document.value.signedAt !== null)

      const addDocument = (row: any, index: number) => {
        console.log(row, index)
      }

      const goToEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      return {
        displayed,
        currentSide,
        toggleAudit,
        isSelected,
        CardType,
        eventColumns,
        toggleSide,
        selectCard,
        addDocument,
        goToEvent,
        collapseCard,
        moment,
        isSigned
      }
    }
  })
