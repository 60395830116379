
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'UplToast',
    props: {
      message: {
        type: String,
        required: true
      },
      context: {
        type: String,
        required: true
      }
    },
    setup() {
      return {}
    }
  })
