<template>
  <div
    :class="`flex flex-col justify-center items-center ${
      isLoadingStock ? 'min-h-full' : ''
    }`"
  >
    <div
      v-if="isLoadingStock"
      class="flex w-full h-full justify-center items-center relative"
    >
      <Loader />
    </div>
    <div v-show="!isLoadingStock" class="pb-10 w-full">
      <h3 class="text-md font-bold">
        {{ $t('stockBook.form.mainInformation') }}
      </h3>

      <Form v-slot="{ meta }" as="div">
        <div class="w-3/5 mt-3">
          <label
            class="text-xs uppercase text-secondary7 font-semibold"
            for="stockType"
            >{{ $t('stockBook.form.type') }}</label
          >
          <Field v-slot="{ field }" v-model="newStockType" name="type">
            <Multiselect
              v-bind="field"
              id="stockType"
              :value="newStockType"
              mode="single"
              name="type"
              :disabled="currentStock !== null"
              :options="stockTypes"
              :placeholder="$t('stockBook.form.typePlaceholder')"
              :groups="false"
              @change="newStockType = $event"
            />
          </Field>
        </div>

        <div v-if="!newStockType">
          <p class="text-left py-5 text-warning">
            {{ $t('stockBook.noType') }}
          </p>
        </div>

        <div v-if="newStockType === STOCKTYPE.SAMPLE" class="w-full mt-3">
          <SampleStockAliasForm
            :values="stock"
            @saveChanges="persistStockAlias"
            @applyChanges="applyStockChanges"
          />
        </div>
        <div v-if="newStockType === STOCKTYPE.OPTION" class="w-full mt-3">
          <OptionStockAliasForm
            :values="stock"
            @saveChanges="persistStockAlias"
            @applyChanges="applyChanges"
          />
        </div>
        <div v-if="newStockType === STOCKTYPE.COMPOSITION" class="w-full mt-3">
          <CompositeStockAliasForm
            :values="stock"
            @saveChanges="persistStockAlias"
          />
        </div>

        <div v-if="newStockType" class="w-3/5 mt-3">
          <h3 class="text-md font-bold" for="colorCode">
            {{ $t('stockGroupBook.form.color') }}
          </h3>
          <Field v-slot="{ field }" v-model="colorCode" name="colorCode">
            <div class="group-color-block">
              <ColorPicker
                v-bind="field"
                :value="colorCode"
                name="colorCode"
                @change="colorCode = $event"
              />
            </div>
          </Field>
        </div>

        <!-- SECTION GROUP -->
        <section v-if="newStockType" class="w-full mt-3">
          <h3 class="text-md font-bold">{{ $t('stockBook.form.groups') }}</h3>
          <div
            class="stock__aliasPrincipal form-group flex justify-start w-full"
          >
            <div class="flex flex-col w-3/5">
              <label class="text-sm">{{
                $t('stockBook.form.groupsSubtitle')
              }}</label>
              <Field
                v-slot="{ field }"
                v-model="selectedGroup"
                type="text"
                name="stockGroup"
              >
                <Multiselect
                  v-bind="field"
                  id="group"
                  :value="selectedGroup"
                  mode="single"
                  name="stockGroup"
                  :options="stockGroups"
                  :placeholder="$t('stockBook.form.groupPlaceholder')"
                  :groups="false"
                  @change="selectedGroup = $event"
                />
              </Field>
            </div>
          </div>
        </section>

        <!-- SECTION COLOR -->
        <!-- SECTION GLOBAL PARAMS -->
        <ToggleFields
          v-if="newStockType"
          :stockType="newStockType"
          :values="toggleFields"
          :disabled="disabledToggleFields"
          @onChange="updateFields"
        />

        <hr v-if="currentStock" />

        <div v-if="currentStock && canDo" class="mt-5">
          <h3 class="text-md font-bold">{{ $t('global.settings') }}</h3>
          <div
            class="
              book__settings
              flex
              justify-between
              items-center
              border border-error
              rounded
              p-3
              mt-3
              text-xl
            "
          >
            <div>
              <h5 class="text-blackp">
                {{ $t('stockBook.form.deletionTitle') }}
              </h5>
              <h6>{{ $t('stockBook.form.deletionMessage') }}</h6>
            </div>
            <div class="ml-auto">
              <el-popconfirm
                :title="$t('stockBook.form.promptDeletion')"
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                @confirm="deleteCompanyStock"
              >
                <template #reference>
                  <Btn
                    variant="custom"
                    class="bg-error text-white hover:bg-error hover:border-0"
                    :disabled="!meta.valid"
                    :label="$t('stockBook.form.deletionPersist')"
                  >
                  </Btn>
                </template>
              </el-popconfirm>
            </div>

            <i
              v-if="false"
              v-tooltip.bottom="
                `Ce titre est utilisé dans un ou plusieurs évènements.`
              "
              class="bi-lock-fill text-warning xxl"
            />
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import {
    OptionStockTypeFormData,
    SampleStockTypeFormData,
    StockAliasFormProps,
    StockToggleFields
  } from '@/types'
  import { stockTypesData } from '@/utils'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { CompanyModel } from '@/services/api/models1'
  import SampleStockAliasForm from './SampleStockForm.vue'
  import OptionStockAliasForm from './OptionStockForm.vue'
  import CompositeStockAliasForm from './CompositeStockForm.vue'
  import ToggleFields from '@/components/Forms/Configurable/Stock/ToggleFields.vue'
  import {
    CompositionStockAlias,
    OptionStockAlias,
    OptionStockCategory,
    StockAlias,
    StockCategory
  } from '@/services/api/models'
  import { STOCKTYPE } from '@/types/config'
  import { PARITYTYPE, STOCKTYPESALIAS } from '@/types/forms'
  import {
    CREATE_STOCK,
    EDIT_STOCK_ALIAS
  } from '@/store/modules/Organization/models'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  import Loader from '@/components/Loader/index.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import ColorPicker from '@/components/ColorPicker/index.vue'

  export default defineComponent({
    components: {
      Field,
      Form,
      Multiselect,
      ElPopconfirm,
      Btn: Button,
      Loader,
      SampleStockAliasForm,
      OptionStockAliasForm,
      CompositeStockAliasForm,
      ToggleFields,
      ColorPicker
    },

    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const newStockType = ref<STOCKTYPE | null>()
      const stock = ref<any>()
      const selectedGroup = ref<string>()
      const colorCode = ref<string | null>()
      const optionCategories = computed<OptionStockCategory[]>(
        () => store.getters['toolbox/optionCategories']
      )
      const isLoadingStock = computed(
        () => store.getters['organizations/isLoadingStock']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )
      const stockCategories = computed<OptionStockCategory[]>(
        () => store.getters['toolbox/stockCategories']
      )
      const currentStock = computed<
        StockAlias | CompositionStockAlias | OptionStockAlias
      >(() => store.getters['organizations/currentStockAlias'])

      const category = ref<OptionStockCategory | StockCategory>()
      const defaultToggleFields = () => ({
        inESOP: currentStock.value?.inESOP ?? category.value?.inESOP ?? true,
        inFD: currentStock.value?.inFD ?? category.value?.inFD ?? true,
        notInTransactionsRegister:
          currentStock.value?.notInTransactionsRegister ||
          STOCKTYPE.OPTION !== newStockType.value,
        inNFD:
          currentStock.value?.inNFD ??
          category.value?.inNFD ??
          newStockType.value === STOCKTYPE.OPTION,
        inValuation:
          currentStock.value?.inValuation ?? category.value?.inValuation ?? true
      })

      const defaultDisabledToggleFields = () => ({
        inESOP: category.value?.inESOPDisabled ?? false,
        inFD: category.value?.inFDDisabled ?? false,
        notInTransactionsRegister: false,
        inNFD:
          category.value?.inNFDDisabled ??
          newStockType.value === STOCKTYPE.OPTION,
        inValuation: category.value?.inValuationDisabled ?? false
      })

      const toggleFields = ref<StockToggleFields>(defaultToggleFields())

      const disabledToggleFields = ref<StockToggleFields>(
        defaultDisabledToggleFields()
      )

      const stockGroups = computed(
        () => store.getters['organizations/stockGroups']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const persistStockAlias = (
        data: SampleStockTypeFormData | OptionStockTypeFormData,
        type: STOCKTYPESALIAS,
        edition = false
      ) => {
        let action = CREATE_STOCK

        const formData: StockAliasFormProps = {
          companyId: currentCompany.value.id,
          form: {
            ...data,
            ...toggleFields.value,
            groupId: selectedGroup.value,
            colorCode: colorCode.value || '#3b69da'
          },
          type
        }

        if (!formData.form.groupId) {
          delete formData.form.groupId
        }
        if (!formData.form.colorCode) {
          delete formData.form.colorCode
        }
        if (edition) {
          action = EDIT_STOCK_ALIAS
          formData['stockId'] = currentStock.value.id
        }

        if (newStockType.value !== STOCKTYPE.OPTION) {
          delete formData.form.notInTransactionsRegister
        }
        store.dispatch(`organizations/${action}`, {
          ...formData,
          onSuccess: () => {
            resetList()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('stockBook.form.title'),
              message: t(
                `stockBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          },
          onError: (error = null) => {
            const message =
              error ||
              t(`stockBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('stockBook.form.title'),
              message
            })
          }
        })
      }
      const stockTypes = ref(stockTypesData)

      const getGroups = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_GROUPS', {
          companyId: currentCompany.value.id,
          filter: { search }
        })
      }

      const getStockCategories = () => {
        store.dispatch('toolbox/GET_STOCK_CATEGORIES', {
          companyId: currentCompany.value.id,
          filter: {}
        })
      }

      const getOptionsCategories = () => {
        store.dispatch('toolbox/GET_OPTION_CATEGORIES', {
          companyId: currentCompany.value.id,
          filter: {}
        })
      }

      const resetList = () => {
        newStockType.value = null
        selectedGroup.value = ''
        colorCode.value = null
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: currentCompany.value.id
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: false,
          initCurrentForm: true
        })
        toggleFields.value = defaultToggleFields()
      }
      const stockForm = computed(() => store.getters['organizations/form'])

      const updateFields = (fields: StockToggleFields) => {
        toggleFields.value = fields
      }

      const deleteCompanyStock = () => {
        store.dispatch(`organizations/REMOVE_STOCK_ALIAS`, {
          companyId: currentCompany.value.id,
          stockId: currentStock.value.id,
          type:
            newStockType.value === STOCKTYPE.SAMPLE
              ? STOCKTYPESALIAS.STOCK
              : newStockType.value === STOCKTYPE.OPTION
              ? STOCKTYPESALIAS.OPTION
              : STOCKTYPESALIAS.COMPOSITION,
          onSuccess: () => {
            resetList()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('stockBook.form.title'),
              message: t('stockBook.apiResponse.deleted')
            })
          },
          onError: (error = null) => {
            const message = error || t('stockBook.apiResponse.deleteError')
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('stockBook.form.title'),
              message
            })
          }
        })
      }

      const applyChanges = (form: OptionStockTypeFormData) => {
        const canDisable =
          form.parityType === PARITYTYPE.VARIABLE && form.parity <= 0
        toggleFields.value.inFD = !canDisable
        disabledToggleFields.value.inFD = canDisable
        category.value = optionCategories.value.find(
          (x) => x.id === form.optionCategoryId
        )
      }

      const applyStockChanges = (form: SampleStockTypeFormData) => {
        category.value = stockCategories.value.find(
          (x) => x.id === form.stockCategoryId
        )
      }

      watch(stockForm, (newStockForm) => {
        if (newStockForm.initCurrentForm && !currentStock.value)
          newStockType.value = null
        else newStockType.value = newStockForm.stockType
      })

      watch([newStockType, category], () => {
        toggleFields.value = defaultToggleFields()
        disabledToggleFields.value = defaultDisabledToggleFields()
      })

      watch(
        () => currentStock.value,
        (newValue) => {
          stock.value = newValue
          newStockType.value = newValue?.type ?? null
          selectedGroup.value = newValue?.group?.id ?? ''
          colorCode.value = newValue?.colorCode ?? null
          console.log('Tetstst', stock.value, newValue)

          toggleFields.value = defaultToggleFields()
        }
      )

      onMounted(() => {
        getOptionsCategories()
        getStockCategories()
        getGroups()
      })

      return {
        stock,
        canDo,
        isLoadingStock,
        selectedGroup,
        colorCode,
        stockGroups,
        stockTypes,
        newStockType,
        STOCKTYPE,
        toggleFields,
        currentStock,
        disabledToggleFields,
        persistStockAlias,
        updateFields,
        deleteCompanyStock,
        applyChanges,
        applyStockChanges
      }
    }
  })
</script>
