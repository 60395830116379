<template>
  <div class="w-full h-full px-12 flex flex-col overflow-y-scroll">
    <div class="flex">
      <h3 class="text text-blackp text-2xl pt-8">
        {{ $t('company.mandates_title') }}
      </h3>
    </div>

    <div class="flex justify-between my-5 w-full">
      <div class="flex w-3/5 xl:w-3/5">
        <div class="w-1/3 min-h-10 mr-2">
          <ContactDropdown
            v-model="selectedContacts"
            :can-add-new="false"
            open-direction="bottom"
            mode="multiple"
            :placeholder="$t('global.placeholder.contacts')"
            name="contacts"
            :elements="contacts"
            :filter-results="false"
            @update:modelValue="updateField($event, 'contacts')"
          />
        </div>

        <div class="w-1/3 min-h-10 mr-2">
          <Multiselect
            v-model="selectedFunctions"
            :tag-with-initials="false"
            :options="functions"
            mode="multiple"
            elementName="global.functions"
            selectionText="global.actions.selected2"
            :placeholder="$t('global.placeholder.function')"
            @change="updateField($event, 'functions')"
          />
        </div>

        <div class="w-1/3 h-10">
          <Multiselect
            v-model="selectedCommittees"
            :options="organs"
            :tag-with-initials="false"
            elementName="global.committees"
            selectionText="global.actions.selected1"
            mode="multiple"
            :placeholder="$t('global.placeholder.committee')"
            @change="updateField($event, 'committees')"
          />
        </div>
      </div>

      <div>
        <DownloadButton
          v-if="!isDownloading"
          class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
          :on-click="download"
        ></DownloadButton>

        <el-tooltip
          v-else-if="isDownloading"
          class="box-item"
          effect="dark"
          :content="$t('tooltips.downloading')"
          placement="top"
        >
          <div
            class="
              border
              h-full
              cursor-not-allowed
              rounded-3
              bg-white
              border-secondary6
              px-3
              flex
              items-center
            "
          >
            <i
              class="
                bi bi-arrow-clockwise
                text-xl text-secondary8
                rotating
                cursor-no-drop
              "
            ></i>
          </div>
        </el-tooltip>
      </div>
    </div>

    <div
      v-if="mandates.length > 0"
      class="mt-2 text-sm transition transition-all"
    >
      <data-table
        :has-checkbox="false"
        :columns="columns"
        :rows="mandates"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #contact="{ row }">
          <p class="text-left capitalize font-semibold">
            <router-link
              :to="{
                name: 'company.directory',
                params: { id: $route.params.id, contactId: row.contact.id }
              }"
            >
              <ContactBadge
                type="light"
                :contact="row.contact"
                :details="false"
              />
            </router-link>
          </p>
        </template>
        <template #function="{ row }">
          <p class="text-left capitalize">{{ row.function.name }}</p>
        </template>
        <template #organ="{ row }">
          <p v-if="row.committee" class="text-left capitalize">
            {{ row.committee.denomination }}
          </p>
          <p v-else class="text-left capitalize">-</p>
        </template>
        <template #startDate="{ row }">
          <p class="text-left capitalize">
            {{ moment.utc(row.startDate).format('L') }}
          </p>
        </template>
        <template #endDate="{ row }">
          <p v-if="row.appointmentTermination" class="text-left capitalize">
            {{ moment.utc(row?.appointmentTermination.endDate).format('L') }}
          </p>
          <div v-else>
            <p v-if="row.isUnlimited" class="text-left capitalize">
              {{ $t('events.elements.timeUnlimited') }}
            </p>
            <p
              v-else-if="row.accountingExerciseYear"
              class="text-left capitalize"
            >
              {{ $t('events.elements.accountingExerciseYear') }}
              {{ row.accountingExerciseYear
              }}{{
                row.endDate ? ` (${moment.utc(row?.endDate).format('L')})` : ''
              }}
            </p>
            <p v-else class="text-left capitalize">
              {{ moment.utc(row.endDate).format('L') }}
            </p>
          </div>
        </template>

        <template #status="{ row }">
          <p
            v-if="
              row?.appointmentTermination?.endDate ||
              moment.utc().endOf('day').isAfter(moment.utc(row.endDate))
            "
            class="text-left text-error"
          >
            {{ $t('events.elements.expired') }}
          </p>
          <p
            v-else-if="
              moment.utc().endOf('day').isBefore(moment.utc(row.endDate)) ||
              row.isUnlimited
            "
            class="text-left text-success"
          >
            {{ $t('events.elements.pending') }}
          </p>
        </template>
        <template #event="{ row }">
          <div class="flex justify-end items-center">
            <span
              class="
                text-left
                cursor-pointer
                border border-primary3
                bg-white
                rounded
                p-2
                flex
                justify-center
                items
                center
                hover:bg-primary3
              "
              @click="gotoEvent(row.event.id)"
            >
              <i class="bi bi-eye text-primary4 text-lg"></i>
            </span>
          </div>
        </template>
      </data-table>
    </div>
    <div
      v-else
      class="
        flex
        justify-center
        items-center
        w-full
        mt-4
        transition transition-all
      "
    >
      <EmptyState
        class="d-block mx-auto datatable"
        :title="$t('global.noResult')"
        :subtitle="$t('global.selectFilters')"
      />
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import ContactDropdown from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { mandateColumns, stockRegisterColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import moment from 'moment'
  import humanizeDuration from 'humanize-duration'
  import { calculateTimeDuration } from '@/utils/global'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { useI18n } from 'vue-i18n'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'UplRegisterMandate',
    components: {
      ContactDropdown,
      DataTable,
      ElTooltip,
      Multiselect,
      ContactBadge,
      EmptyState,
      DownloadButton
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const search = ref('')
      const { t } = useI18n()
      const selectedContacts = ref<string[]>([])
      const selectedFunctions = ref<string[]>([])
      const selectedCommittees = ref<string[]>([])
      const lang = computed(() => store.getters['config/lang'])

      const contacts = computed(() => store.getters['organizations/contacts'])
      const organs = computed(() => store.getters['organizations/committees'])
      const mandates = computed(
        () => store.getters['organizations/nominations']
      )
      const functions = computed(() => store.getters['organizations/functions'])
      const pagination = computed(
        () => store.getters['organizations/nominationsPagination']
      )
      const isDownloading = ref(false)

      const getContacts = () => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
      }

      const getFunctions = () => {
        store.dispatch('organizations/GET_FUNCTIONS', {
          companyId: route.params.id
        })
      }

      const getOrgans = () => {
        store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: route.params.id
        })
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', page)
        getMandates()
      }

      const columns = ref(mandateColumns)

      const handleFilterSelected = (value: string[]) => {
        columns.value = stockRegisterColumns.filter((x) =>
          value.includes(x.field)
        )
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const updateField = (value: any, field = '') => {
        if (field === 'contacts') selectedContacts.value = value
        if (field === 'functions') selectedFunctions.value = value
        if (field === 'committees') selectedCommittees.value = value
      }

      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_APPOINTMENTS', {
          name: `${t('exports.mandates')}`,
          companyId,
          filter: {
            committeesId: selectedCommittees.value,
            functionsId: selectedFunctions.value,
            contactsId: selectedContacts.value
          },
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const getMandates = () => {
        store.dispatch('organizations/GET_NOMINATIONS', {
          companyId: route.params.id,
          filter: {
            committeesId: selectedCommittees.value,
            functionsId: selectedFunctions.value,
            contactsId: selectedContacts.value
          }
        })
      }

      watch(
        [
          selectedContacts,
          selectedCommittees,
          selectedFunctions,
          () => route.params.id
        ],
        () => {
          store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', 1)
          getMandates()
        }
      )

      watch(
        () => route.params.id,
        () => {
          selectedCommittees.value = []
          selectedContacts.value = []
          selectedFunctions.value = []
          store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', 1)
          getMandates()
        }
      )

      onMounted(() => {
        store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', 1)
        getContacts()
        getOrgans()
        getMandates()
        getFunctions()
      })

      return {
        search,
        contacts,
        organs,
        selectedContacts,
        selectedCommittees,
        selectedFunctions,
        columns,
        mandates,
        moment,
        humanizeDuration,
        functions,
        pagination,
        lang,
        isDownloading,
        gotoEvent,
        download,
        handleFilterSelected,
        calculateTimeDuration,
        onPageChange,
        updateField
      }
    }
  })
</script>
