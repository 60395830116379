<template>
  <div class="flex justify-start w-full">
    <div
      v-if="isLoading"
      class="flex w-full justify-center items-center relative"
    >
      <Loader />
    </div>
    <div v-else class="flex flex-col w-3/5 xl:w-3/5 lg:w-3/5">
      <div class="flex flex-col w-1/2 min-h-14 my-4">
        <label class="text-left">{{
          $t('events.fieldsLabels.includedOptions')
        }}</label>
        <Field
          v-slot="{ field }"
          v-model="optionId"
          name="optionId"
          as="div"
          class="w-full pr-1"
        >
          <Multiselect
            id="optionId"
            v-bind="field"
            v-model="optionId"
            :value="optionId"
            open-direction="bottom"
            mode="multiple"
            name="optionId"
            elementName="stockBook.options"
            selectionText="global.actions.selected2"
            :options="options"
            :placeholder="$t('vestingBook.form.options')"
            :groups="false"
            @change="optionId = $event"
          />
        </Field>
      </div>

      <div class="rounded shadow-md border border-primary3 w-full py-10 px-14">
        <div class="flex w-full items-center">
          <div class="w-1/2 flex flex-col justify-between h-3/4">
            <div class="flex flex-col items-start w-full pr-1">
              <label class="">{{ $t('events.fieldsLabels.stockPrice') }}</label>
              <div class="flex justify-center items-center">
                <Field
                  v-slot="{ field }"
                  v-model="pricePerAction"
                  name="pricePerAction"
                  as="div"
                  class="w-full"
                >
                  <Input
                    class="w-40"
                    :no-error="true"
                    v-bind="field"
                    v-model="pricePerAction"
                    :placeholder="$t('events.fieldsLabels.stockPrice')"
                    @focus="handleFocus('pricePerAction')"
                  ></Input>
                </Field>
                <span class="px-1 text-2xl text-secondary7">{{
                  currency
                }}</span>
              </div>
            </div>

            <div class="flex flex-col items-start w-full pr-1 mt-8">
              <label class="">{{
                $t('events.fieldsLabels.valorisationAmount')
              }}</label>
              <div class="flex justify-center items-center">
                <Field
                  v-slot="{ field }"
                  v-model="companyValorisationAmount"
                  name="companyValorisationAmount"
                  as="div"
                  class="w-full"
                >
                  <Input
                    v-bind="field"
                    no-error
                    v-model="companyValorisationAmount"
                    :placeholder="$t('events.fieldsLabels.valorisationAmount')"
                    class="w-40"
                    type="text"
                    @focus="handleFocus('companyValorisationAmount')"
                  ></Input>
                </Field>

                <span class="px-1 text-2xl text-secondary7">{{
                  currency
                }}</span>
              </div>
            </div>
          </div>

          <div class="mt-2 w-1/2">
            <div
              class="mt-2 text-success flex flex-col items-end justify-between"
            >
              <strong v-if="optionId"
                ><Number
                  classes="text-right font-bold   text-primary4 text-6xl"
                  :value="
                    potentialFullAddedValue > 0 ? potentialFullAddedValue : 0
                  "
                  :mode="NumberType.price"
              /></strong>

              <strong v-else>- </strong>
              <span class="text-primary4 text-xs font-light text-right">
                {{ $t('global.potentialVestedAddedValue') }}
              </span>
            </div>
            <div
              class="mt-8 text-warning flex flex-col items-end justify-between"
            >
              <strong v-if="optionId"
                ><Number
                  classes="text-right font-bold text-secondary8 text-6xl"
                  :value="
                    potentialVestedAddedValue > 0
                      ? potentialVestedAddedValue
                      : 0
                  "
                  :mode="NumberType.price"
              /></strong>

              <strong v-else> - </strong>
              <span class="text-secondary8 text-xs font-light text-right">
                {{ $t('global.potentialAddedValue') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-start mt-5">
        <p class="text-secondary7 text-right text-xs">
          <span class="text-error">*</span>
          {{ $t('global.simulationNotice') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import { ExerciseCalendar } from '@/services/api/models/company'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { Captable, NumberType } from '@/types'
  import Number from '@/components/Number/index.vue'
  import { useRoute } from 'vue-router'
  import { formatNumber } from '@/utils'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    name: 'SimulationTab',
    components: { Multiselect, Field, Input, Number, Loader },
    setup() {
      const route = useRoute()
      const optionId = ref([])
      const pricePerAction = ref()
      const companyValorisationAmount = ref<any>()
      const store = useStore()
      const isLoadingCaptable = ref(false)
      const isLoadingCalendar = ref(false)
      const isLoading = computed(
        () => isLoadingCaptable.value || isLoadingCalendar.value
      )
      const exerciceCalendars = computed(
        () => store.getters['organizations/exerciseCalendars']
      )
      const captable = computed<Captable>(
        () => store.getters['organizations/captable']
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const focusedField = ref('')
      const selectedCalendars = computed<ExerciseCalendar[]>(() =>
        optionId.value.map((op: string) => {
          const [exerciseId] = op?.split('@_')
          return exerciseId
            ? exerciceCalendars.value.find(
                (x: ExerciseCalendar) => x.id === exerciseId
              )
            : null
        })
      )

      const savedLastStockPrice = computed<number>(() =>
        selectedCalendars.value.length
          ? selectedCalendars.value[0].lastStockPrice
          : 0
      )

      const potentialFullAddedValue = computed(() =>
        parseInt(
          selectedCalendars.value
            .reduce(
              (acc, exerciceCalendar) =>
                (acc +=
                  (parseFloat(pricePerAction.value) -
                    (exerciceCalendar?.optionIssuancePart.optionIssuance
                      .exercisePrice || 0)) *
                  (exerciceCalendar?.optionIssuancePart?.quantity || 0)),
              0
            )
            .toString()
        )
      )

      const potentialVestedAddedValue = computed(() =>
        parseInt(
          selectedCalendars.value
            .reduce(
              (acc, exerciceCalendar) =>
                (acc +=
                  (parseFloat(pricePerAction.value) -
                    (exerciceCalendar?.optionIssuancePart.optionIssuance
                      .exercisePrice || 0)) *
                  (exerciceCalendar?.vestedQuantity || 0)),
              0
            )
            .toString()
        )
      )

      const options = computed(() =>
        exerciceCalendars.value.map((x: ExerciseCalendar) => ({
          ...x.optionAlias,
          name: `${x.optionAlias.alias} ${
            x.optionIssuancePart?.vestingCalendar
              ? `(${x.optionIssuancePart?.vestingCalendar?.name})`
              : ''
          }`,
          id: `${x.id}@_${x.optionAlias.id}`,
          exerciseId: x.id
        }))
      )
      const calculate = () => {
        const [computedValorisationValue] = formatNumber(
          (savedLastStockPrice.value || 0) * (captable.value?.allNFD || 0)
        )
          ?.toString()
          .replaceAll('.', ' ')
          ?.split(',')
        pricePerAction.value = (savedLastStockPrice.value || 0).toFixed(2)
        companyValorisationAmount.value = computedValorisationValue
      }

      const handleFocus = (field: string) => {
        focusedField.value = field
      }

      const getCaptable = () => {
        store.dispatch('organizations/GET_CAPTABLE', {
          companyId: route.params.id,
          filter: {
            date: moment().toISOString(),
            contactId: [route.params.contactId]
          },
          onSuccess: () => {
            isLoadingCaptable.value = false
          },
          onError() {
            isLoadingCaptable.value = false
          }
        })
      }

      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0,
            date: moment().toISOString(),
            contactId: route.params.contactId
          },
          onSuccess: () => {
            isLoadingCalendar.value = false
          }
        })
      }

      watch(
        () => optionId.value,
        () => {
          focusedField.value = ''
          calculate()
        }
      )

      watch(
        () => pricePerAction.value,
        (value) => {
          const entered =
            !value || isNaN(value?.toString()?.replace(',', '.'))
              ? 0
              : value?.toString()?.replace(',', '.')
          if (focusedField.value == 'pricePerAction') {
            const calcul =
              (parseFloat(entered?.toString()?.replace(',', '.')) <= 0
                ? 0
                : parseFloat(entered?.toString()?.replace(',', '.'))) *
              (captable.value?.allNFD || 1)

            const [computedValue] = formatNumber(calcul)
              ?.toString()
              .replaceAll('.', ' ')
              ?.split(',')

            companyValorisationAmount.value = computedValue
          }

          pricePerAction.value = value
        }
      )

      watch(
        () => companyValorisationAmount.value,
        (value) => {
          const entered =
            !value ||
            isNaN(value?.toString()?.replaceAll(' ', '')?.replaceAll('.', ''))
              ? 0
              : value?.toString()?.replaceAll(' ', '')?.replaceAll('.', '')
          const parsed = parseFloat(entered?.toString()?.replace(',', '.'))
          if (focusedField.value == 'companyValorisationAmount') {
            const calcul = parseFloat(
              `${(parsed <= 0 ? 0 : parsed) / (captable.value?.allNFD || 1)}`
            )?.toFixed(2)

            pricePerAction.value = calcul
          }
          const [computedValue] = formatNumber(parsed)
            ?.toString()
            .replaceAll('.', ' ')
            ?.split(',')

          companyValorisationAmount.value = computedValue
        }
      )

      watch(
        () => route.params.contactId,
        () => {
          getCaptable()
        }
      )

      watch(
        () => captable.value?.allNFD,
        (totalNFD) => {
          companyValorisationAmount.value = formatNumber(
            (savedLastStockPrice.value || 0) * (totalNFD || 0)
          )
        }
      )

      onMounted(() => {
        isLoadingCaptable.value = true
        isLoadingCalendar.value = true
        getCaptable()
        getVesting()
        if (savedLastStockPrice.value) {
          calculate()
        }
        const v = selectedCalendars.value.reduce(
          (acc, exerciceCalendar) =>
            (acc +=
              (parseFloat(pricePerAction.value) -
                (exerciceCalendar?.optionIssuancePart.optionIssuance
                  .exercisePrice || 0)) *
              (exerciceCalendar?.vestedQuantity || 0)),
          0
        )

        optionId.value = options.value.map((x: any) => x.id)
      })
      return {
        optionId,
        currency,
        options,
        isLoading,
        pricePerAction,
        NumberType,
        companyValorisationAmount,
        potentialVestedAddedValue,
        potentialFullAddedValue,
        handleFocus
      }
    }
  })
</script>
