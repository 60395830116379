import { Auth0Config, UrlConfig } from '@/types/config'

const auth0Options: Auth0Config = {
  redirectUri: 'https://demo-v2.uplaw.fr',
  audience: 'https://uplaw-staging.us.auth0.com/api/v2/',
  clientId: 'Cd1ORcfdV7WSjBYULOaWoUq4zAlBpWoL',
  domain: 'uplaw-staging.us.auth0.com'
}

const urls: UrlConfig = {
  apiHost: 'https://demo.uplaw-api.com',
  appDomain: 'https://up.law',
  sentryDsn:
    'https://2946c8efaaab4272b2b3e77c1895a620@o4504679368818688.ingest.sentry.io/4504723973079040',
  diagramExternalLink:
    'https://up-law.github.io/UPLAW_DIAGRAMS/src/main/webapp/'
}

const googleMap = {
  apiKey: 'AIzaSyDj3F7iUyFg45_rjD_V7_EDA3kIFi5c6wg'
}
const environement = `production`

export { environement, auth0Options, urls, googleMap }
