import {
  PortfolioState,
  PortfolioMutations,
  PortfolioGetters,
  SET_EVENTS,
  GET_DOCUMENTS,
  IS_DOCUMENT_SEARCHING,
  SET_DOC_SEARCH_RESULT,
  SET_COMPANIES,
  SET_CONTACTS,
  SET_DOCUMENTS_PAGINATION_PAGE,
  SET_DOCUMENTS_GETTING,
  SET_EVENT,
  LOAD_EVENTS,
  GET_COMPANY_SINGLE_DOCUMENT,
  SET_COMPANY_SINGLE_DOCUMENT,
  SET_LOADER_COMPANIES,
  GET_LOADER_COMPANIES
} from './models'
import {
  MutationTree,
  GetterTree,
  Module,
  ActionTree,
  ActionContext
} from 'vuex'
import { RootState } from '../../index'
import { Event } from '@/services/api/models'
import { GET_CONTACTS } from './models'
import {
  LOAD_DOCUMENTS,
  SET_LOADING,
  SET_COMPANIES_LOGOS,
  GET_EVENT_DOCUMENTS
} from './models'
import {
  Company,
  IndividualContact,
  ProfessionalContact,
  InvestorContact
} from '@/services/api/models/company'
import {
  GET_EVENTS,
  SET_DOCUMENTS,
  GET_EVENT,
  SEARCH_IN_DOCUMENTS
} from './models'
import {
  getContacts,
  getDocuments,
  getEvent,
  getEventDocuments,
  getEvents,
  searchInDocuments
} from '@/services/api/portfolio'
import {
  getCompanies,
  getManagedContacts,
  getSingleCompanyDocuments
} from '@/services/api'
import { TYPE } from 'vue-toastification'
import i18n from '@/services/lang'
import { DocumentResult } from '../Organization/models'

const context = 'PORTFOLIO'

const state: PortfolioState = {
  corporateEvents: [],
  documents: [],
  documentList: [],
  searching: false,
  documentSearchResult: [],
  companies: [],
  loaderCompanies: [],

  contacts: [],
  event: null,
  isLoading: false,
  documentsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  eventsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  companyLogos: {},
  chart: null,
  gettingDocuments: false
}

const mutations: MutationTree<PortfolioState> & PortfolioMutations = {
  [SET_EVENTS](state, result) {
    state.eventsPagination = {
      ...result.metadata,
      total: result.metadata.count,
      limit: state.eventsPagination.limit
    }
    state.corporateEvents = [...result.data]
  },
  [SET_COMPANIES_LOGOS](state, { companyId, value }) {
    if (companyId) state.companyLogos[companyId] = value
  },

  [SET_DOCUMENTS](state: PortfolioState, { list, update = false }) {
    state.documentsPagination = {
      ...list.metadata,
      total: list.metadata.count,
      limit: state.documentsPagination.limit
    }
    if (update) {
      state.documents = [...state.documents, ...list.data]
    } else {
      state.documents = [...list.data]
    }
    state.documentList = [...state.documentList, ...list.data]
  },
  [SET_LOADER_COMPANIES](state: PortfolioState, list: Company[]) {
    state.loaderCompanies = [...list]
  },
  [SET_COMPANIES](state, documents: Company[]) {
    state.companies = [...documents]
  },
  [SET_DOCUMENTS_PAGINATION_PAGE](state: PortfolioState, page: number) {
    state.documentsPagination.currentPage = page
  },
  [SET_DOCUMENTS_GETTING](state: PortfolioState, isLoading: boolean) {
    state.gettingDocuments = isLoading
  },
  [SET_CONTACTS](
    state,
    documents: (IndividualContact | ProfessionalContact | InvestorContact)[]
  ) {
    state.contacts = [...documents]
  },
  [SET_EVENT](state: PortfolioState, event: Event) {
    state.event = event
  },
  [SET_LOADING](state, loader: boolean) {
    state.isLoading = loader
  },
  [SET_DOC_SEARCH_RESULT](state: PortfolioState, data: DocumentResult[]) {
    state.documentSearchResult = data
  },
  [IS_DOCUMENT_SEARCHING](state: PortfolioState, searching: boolean) {
    state.searching = searching
  }
}

const getters: GetterTree<PortfolioState, RootState> & PortfolioGetters = {
  events: (state) => state.corporateEvents,
  documents: (state) => state.documents,
  contacts: (state) => state.contacts,
  companies: (state) => state.companies,
  loaderCompanies: (state) => state.loaderCompanies,
  eventsPagination: (state) => state.eventsPagination,
  documentsPagination: (state) => state.documentsPagination,
  companiesLogos: (state) => state.companyLogos,
  gettingDocuments: (state) => state.gettingDocuments,
  searchResults: (state) => state.documentSearchResult,
  searchingText: (state) => state.searching
}

const actions: ActionTree<PortfolioState, RootState> = {
  async [GET_EVENT](
    { commit }: ActionContext<PortfolioState, RootState>,
    { eventId, type, onSuccess, onError }
  ) {
    try {
      const event = await getEvent(eventId, type)
      if (onSuccess) onSuccess(event)
      else {
        commit(SET_EVENT, event)
        return event
      }
    } catch (error: any) {
      console.log(error)
      if (onError) onError(error.response)
      else throw error
    } finally {
      // commit(SET_EVENTS_LOADING, false)
    }
  },
  async [GET_EVENT_DOCUMENTS](
    { rootState }: ActionContext<PortfolioState, RootState>,
    { eventId, page, limit, onSuccess, onError }
  ) {
    try {
      const lm = limit || rootState.config.eventDocumentsLimit

      const offset = ((page || 1) - 1) * lm
      const response = await getEventDocuments(eventId, offset, lm)
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [GET_COMPANY_SINGLE_DOCUMENT](
    { commit, dispatch }: ActionContext<PortfolioState, RootState>,
    { companyId, documentId }
  ) {
    try {
      const document = await getSingleCompanyDocuments(companyId, documentId)

      commit(SET_COMPANY_SINGLE_DOCUMENT, document)
    } catch (error) {
      await dispatch(
        'toolbox/displayToast',
        {
          type: TYPE.ERROR,
          context: i18n.global.t('documents.context'),
          message: i18n.global.t('documents.error500')
        },
        { root: true }
      )
      console.log(context, error)
    }
  },
  async [GET_EVENTS](
    { commit }: ActionContext<PortfolioState, RootState>,
    { filter }
  ) {
    try {
      const response = await getEvents(filter)
      commit(SET_EVENTS, response)
    } catch (error) {
      console.log(context, error)
    }
  },
  async [GET_CONTACTS]({ commit }, { filter }) {
    try {
      const contacts = await getContacts(filter)
      commit(SET_CONTACTS, contacts)
    } catch (error) {
      console.log(error)
    }
  },
  async [LOAD_EVENTS](
    { state, commit }: ActionContext<PortfolioState, RootState>,
    { filter, offset = 0, limit = 30 }
  ) {
    if (state.eventsPagination.currentPage >= state.eventsPagination.lastPage)
      return
    commit(SET_LOADING, true)
    try {
      const events = await getEvents(
        filter,
        offset,
        (limit = state.eventsPagination.limit)
      )
      // commit(SET_EVENTS, { list: events, update: true })
      return events
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async [SEARCH_IN_DOCUMENTS](
    { commit }: ActionContext<PortfolioState, RootState>,
    { query, companyIds, onSuccess, onError }
  ) {
    commit('organizations/IS_DOCUMENT_SEARCHING', true, { root: true })
    try {
      const response = await searchInDocuments(query, companyIds)
      commit('organizations/SET_DOC_SEARCH_RESULT', response, { root: true })

      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    } finally {
      commit('organizations/IS_DOCUMENT_SEARCHING', false, { root: true })
    }
  },
  async [GET_LOADER_COMPANIES](
    { commit }: ActionContext<PortfolioState, RootState>,
    { filter, onSuccess }
  ) {
    try {
      commit(SET_LOADING, true)
      const { data } = await getCompanies(filter)
      commit(SET_LOADER_COMPANIES, data)
      if (!onSuccess) commit(SET_LOADING, false)
      else onSuccess(data)
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async [GET_DOCUMENTS]({ commit }, { filter }) {
    try {
      const response = await getDocuments(filter)
      commit(SET_DOCUMENTS, { list: response })
    } catch (error) {
      console.log(context, error)
    }
  },
  async [LOAD_DOCUMENTS](
    { commit, state }: ActionContext<PortfolioState, RootState>,
    { filter, offset, onSuccess, onError }
  ) {
    try {
      if (
        state.documentsPagination.total > 0 &&
        state.documentsPagination.currentPage >=
          state.documentsPagination.lastPage
      ) {
        commit(
          SET_DOCUMENTS_PAGINATION_PAGE,
          state.documentsPagination.currentPage - 1
        )
        return
      }
      commit(SET_DOCUMENTS_GETTING, true)

      const documents = await getDocuments(
        filter,
        offset,
        state.documentsPagination.limit
      )
      commit(SET_DOCUMENTS, { list: documents, update: true })
      if (onSuccess) onSuccess()

      return documents
    } catch (error) {
      if (onError) onError(error)
    } finally {
      commit(SET_DOCUMENTS_GETTING, false)
    }
  }
}

const module: Module<PortfolioState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default module
