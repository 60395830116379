
  import { defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import EventsTab from './eventsTab.vue'
  import filtersCorporate from '@/views/Company/corporate/filtersCorporate.vue'

  export default defineComponent({
    components: {
      filtersCorporate,
      EventsTab
    },
    emits: ['on-selected'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()

      const selectedTab = ref(0)
      const filters = ref<any>({ search: '', limit: 10, page: 1 })
      const resetFilters = (tab: number) => {
        filters.value = { limit: 10, page: 1 }
      }
      const tabClicked = (key: string, index: number) => {
        selectedTab.value = index
        emit('on-selected', key)
      }
      const updateFilters = async (e: any, type = 'document', field = '') => {
        if (field === 'search') {
          store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
            companyId: route.params.id,
            query: e.search
          })

          return
        }
        filters.value = { ...e }
        const data = { ...filters.value }
        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft

        await store.dispatch('organizations/GET_EVENTS', {
          companyId: route.params.id,
          filter: { ...data }
        })
      }

      watch(
        () => route.query?.type,
        (val) => {
          if (val == 'event') selectedTab.value = 0
        }
      )

      onMounted(() => {
        if (route.query?.type == 'event') selectedTab.value = 0

        store.commit('ui/DISPLAY_PREVIEW', false)
      })

      return {
        selectedTab,
        filters,
        tabClicked,
        updateFilters,
        resetFilters
      }
    }
  })
