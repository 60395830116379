
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import Loader from '@/components/Loader/index.vue'
  import Issuance from '@/components/Event/types/issuance.vue'
  import Delegation from '@/components/Event/types/delegation.vue'
  import Exercise from '@/components/Event/types/exercise.vue'
  import Ceiling from '@/components/Event/types/ceiling.vue'
  import Transfer from '@/components/Event/types/transfer.vue'
  import Conversion from '@/components/Event/types/conversion.vue'
  import Committee from '@/components/Event/types/committee.vue'
  import Nomination from '@/components/Event/types/nomination.vue'
  import Termination from '@/components/Event/types/appointmentTermination.vue'
  import NominalValue from '@/components/Event/types/nominalValue.vue'
  import Reduction from '@/components/Event/types/reduction.vue'
  import Vesting from '@/components/Event/types/vesting.vue'
  import Approval from '@/components/Event/types/approval.vue'
  import FinalAcquisition from '@/components/Event/types/finalAcquisition.vue'
  import FreeShareSunset from '@/components/Event/types/freeShareSunset.vue'
  import Sunset from '@/components/Event/types/sunset.vue'
  import FinancialEnding from '@/components/Event/types/finacialEnding.vue'
  import ParityChange from '@/components/Event/types/parityChange.vue'
  import { EventType } from '@/types/event'
  import { Event } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import FreeSharesIssuance from '@/components/Event/types/freeSharesIssuance.vue'

  export default defineComponent({
    name: 'EventOperation',
    components: {
      Loader,
      FinalAcquisition,
      FreeShareSunset,
      Sunset,
      Issuance,
      Delegation,
      Termination,
      Ceiling,
      Transfer,
      Nomination,
      NominalValue,
      Reduction,
      Exercise,
      Vesting,
      FinancialEnding,
      Committee,
      Conversion,
      ParityChange,
      Approval,
      FreeSharesIssuance
    },
    emits: ['on-updated'],
    setup() {
      const store = useStore()
      const isLoading = ref(false)
      const route = useRoute()
      const nominalValues = computed(
        () => store.getters['organizations/nominalValues']
      )
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const nominalValue = ref(0)
      const event = ref<Event | null>(null)

      const calculateNominalValue = async (date: string) => {
        const value = await store.dispatch(
          'organizations/COMPUTE_NOMINAL_VALUE',
          { date, setAsGlobal: false }
        )

        if (value?.nominalValue) nominalValue.value = value?.nominalValue
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      watch([() => event.value, nominalValues], ([val, vals]) => {
        if (val?.date) calculateNominalValue(val?.date || '')
      })

      onMounted(getEvent)
      return { EventType, event, nominalValue, isLoading }
    }
  })
