
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      accept: {
        type: String,
        default: ''
      }
    },
    emits: ['filesChanged'],
    setup(props, { emit }) {
      const handleChange = (e: Event) => {
        const target = e.target as HTMLInputElement
        emit('filesChanged', target.files)
        target.value = ''
      }
      const handleSelect = (e: Event) => {
        document.getElementById('fileLoader')?.click()
      }
      return { handleChange, handleSelect }
    }
  })
