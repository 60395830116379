<template>
  <el-dialog
    v-model="isModalVisible"
    :before-close="beforeModalClose"
    :title="$t(`newCompany.createTitle`)"
    :lock-scroll="false"
    top="h-auto"
    width="80%"
    custom-class="w-4/5 h-auto overflow-y-scroll max-h-screen"
  >
    <div class="modal-content">
      <Form
        class="w-full mt-5 pb-5"
        as="div"
        :validation-schema="currentSchema"
        :initial-values="newCompany"
        @submit="nextStep"
      >
        <template v-if="currentStep === 0">
          <div class="modal-body p-1">
            <div class="create-container">
              <p class="m-0">
                {{ $t('onboarding.step2.baseline') }}
              </p>
              <div class="mt-2 row justify-content-md-center">
                <div class="col-md-12">
                  <Box :type="'primary'">
                    <div v-html="$t('onboarding.step2.tips')" />
                  </Box>
                </div>
              </div>

              <!-- INPUT + SEARCH BUTTON -->
              <div class="create_form">
                <div class="w-full flex justify-center items-center form-group">
                  <div class="w-3/5 flex justify-center">
                    <UplInput
                      v-model="search"
                      class="w-3/5 search-input"
                      type="search"
                      :no-error="true"
                      name="search"
                      :disabled="false"
                      :placeholder="$t('onboarding.step2.form.searchbox')"
                      @input="userEnterText"
                    />
                  </div>
                </div>

                <div class="text-muted mt-2">
                  {{ $t('onboarding.step2.notFound.label') }}
                  <u>
                    <a href="" @click.prevent="nextStep">{{
                      $t('onboarding.step2.notFound.button')
                    }}</a></u
                  >
                </div>
                <!-- RESULT LIST -->
                <div class="mt-1 results max-h-64">
                  <div class="flex justify-center mb-4">
                    <div class="w-4/5">
                      <el-radio-group v-model="selectedCompany">
                        <CompanyResult
                          v-for="(company, index) in companyResult"
                          :key="index"
                          :company="company"
                          :is-selected="company.siret === selectedCompany"
                      /></el-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="currentStep === 1">
          <h3 class="m-0 text-left">
            {{ $t('onboarding.step3.baseline') }}
          </h3>
          <div class="mt-5 row justify-center">
            <div class="col-md-12">
              <Box :type="'primary'">
                <div v-html="$t('onboarding.step3.tips')" />
              </Box>
            </div>
          </div>
          <NewCompanyFrom
            :can-submit="canSubmit"
            :company="userSelection"
            @saved="savedCompany"
            @previousStep="previousStep"
            @toggleModal="handleModalDisplay"
          />
        </template>
        <div v-if="currentStep != 1" class="flex h-10 mt-8 justify-end">
          <Button
            v-if="currentStep !== 0"
            type="reset"
            variant="tertiary"
            size="medium"
            :label="$t('newCompany.back')"
            class="
              form-btn
              flex
              justify-center
              items-center
              py-5
              px-5
              text-xl text-center text-error
              mr-2
            "
            @click="previousStep"
          />
          <Button
            type="submit"
            variant="secondary"
            size="medium"
            :disabled="!selectedCompany"
            :label="$t(`newCompany.create-${currentStep}`)"
            class="
              form-btn
              flex
              justify-center
              items-center
              py-5
              px-5
              text-xl text-center text-white
            "
            @click="nextStep"
          />
        </div>
      </Form>
    </div>
  </el-dialog>
</template>

<script lang="ts">
  import { Input, Button } from '@up.law/uplaw-ui'
  import Box from '@/components/Box/index.vue'
  import { defineComponent, watch, ref, computed } from 'vue'
  import { useStore } from '@/store'
  import CompanyResult from '@/components/CompanySearchResultCard/index.vue'
  import NewCompanyFrom from './form.vue'
  import { useI18n } from 'vue-i18n'
  import { ElMessageBox } from 'element-plus'
  import { Form } from 'vee-validate'
  import { comapnySchema } from '@/utils/schema/contact'
  import { CompanyModelForm } from '@/types'
  import * as yup from 'yup'
  import { CompanyModel } from '@/services/api/models'
  import { useRouter } from 'vue-router'
  import { ElDialog, ElRadioGroup } from 'element-plus'

  export default defineComponent({
    name: 'CompanyAdd',
    components: {
      NewCompanyFrom,
      ElDialog,
      ElRadioGroup,
      Button,
      Form,
      Box,
      UplInput: Input,
      CompanyResult
    },
    props: {
      isVisible: {
        type: Boolean,
        required: true
      }
    },
    emits: ['onClose'],
    setup(props, { emit }) {
      const search = ref('')
      const router = useRouter()
      const canSubmit = ref(false)
      const { t } = useI18n()
      const nominalValue = ref(1)
      const newValues = (): CompanyModelForm => ({
        additionalAddress: null,
        commercialName: '',
        name: '',
        lastPricePerAction: '',
        country: '',
        contactCanSimulatePotentialGain: false,
        email: null,
        phoneNumber: '',
        address: '',
        postalCode: '',
        city: '',
        legalStatusId: '',
        siret: '',
        siren: '',
        tva: '',
        constitutedAt: '',
        capital: 0
      })
      const newContact = ref<CompanyModelForm>(newValues())
      const newCompany = ref<CompanyModelForm>(newValues())

      const store = useStore()
      const currentStep = ref<number>(0)
      let isModalVisible = ref(props.isVisible)
      const companyResult = computed(() => store.getters['auth/companies'])
      const userSelection = computed(() => store.getters['auth/company'])
      const selectedCompany = ref<string>('')

      const schemas = [
        yup.object({
          search: yup.string().optional()
        }),
        comapnySchema
      ]

      const currentSchema = computed(() => {
        return schemas[currentStep.value]
      })
      const beforeModalClose = () => {
        ElMessageBox.confirm(t('newCompany.leaveCompanyConfirmation'), '', {
          confirmButtonText: t('confirm.yes'),
          cancelButtonText: t('confirm.no')
        })
          .then(() => {
            store.commit('auth/SET_COMPANIES', [])
            currentStep.value = 0
            emit('onClose')
          })
          .catch((err) => {
            console.log('Error', err)
          })
      }

      const handleModalDisplay = (value: boolean) => {
        isModalVisible.value = value
      }

      const userEnterText = (event: any) => {
        if (event.target?.value && event.target?.value.length < 3) return
        store.dispatch('auth/searchCompanies', {
          query: event.target?.value,
          onError: () => {
            console.log('Error')
          },
          onSuccess: () => {
            console.log('Success')
          }
        })
      }
      const previousStep = () => {
        currentStep.value--
      }

      const loadCompanyInformations = async (companyId: string) => {
        store.dispatch(`organizations/GET_COMPANIES`, {}).then(() => {
          store
            .dispatch(`organizations/GET_LOADER_COMPANIES`, {
              filter: { shortVersion: true }
            })
            .then(() => {
              store.dispatch(`organizations/INIT_ORGANIZATION`, {
                companyId
              })
            })
        })
      }

      const setCurrentCompany = async (company: CompanyModel) => {
        store.commit(`organizations/SET_COMPANY`, company)
        router.push({ name: 'createEvent', params: { id: company.id } })
        await loadCompanyInformations(company.id)
      }
      const savedCompany = (data: CompanyModel) => {
        emit('onClose')
        currentStep.value = 0
        store.commit('auth/SET_COMPANIES', [])
        if (data.id) setCurrentCompany(data)
      }

      const nextStep = () => {
        if (currentStep.value === 0 && selectedCompany.value) {
          store.dispatch('auth/getCompanyInformations', {
            siret: selectedCompany.value
          })
        }

        if (currentStep.value >= 0 && currentStep.value <= 1)
          currentStep.value++
      }

      watch(search, (val) => {
        store.dispatch('auth/searchCompanies', {
          query: val,
          onError: () => {
            console.log('Error')
          },
          onSuccess: () => {
            console.log('Success')
          }
        })
      })

      watch(userSelection, (val) => {
        if (val) {
          newContact.value = { ...val }
          newContact.value.commercialName = val.name
        }
      })

      watch(
        () => props.isVisible,
        (val) => {
          isModalVisible.value = val
        }
      )

      return {
        search,
        savedCompany,
        newCompany,
        companyResult,
        isModalVisible,
        currentStep,
        canSubmit,
        userSelection,
        nextStep,
        previousStep,
        selectedCompany,
        beforeModalClose,
        userEnterText,
        currentSchema,
        newContact,
        nominalValue,
        handleModalDisplay
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'companyCreator.scss';
</style>
