<template>
  <div class="h-full">
    <Field v-slot="{ field }" v-model="search" type="text" name="search">
      <Input
        v-bind="field"
        :disabled="false"
        :placeholder="$t('global.search')"
      >
      </Input>
    </Field>
    <div class="alias-list h-4/5 overflow-scroll mt-4">
      <div
        v-for="([key, stocks], index) in groups"
        :key="index"
        class="alias-group-container transition transition-all duration-1000"
      >
        <div class="flex justify-between items-center">
          <div class="flex justify-start items-center h-10">
            <div
              :style="`background-color:${
                key === 'zz-other' ? '#09065F' : getGroup(key)?.colorCode
              }`"
              :class="`color w-2 h-2 rounded-full mr-3`"
            ></div>
            <p class="font-bold text-md">
              {{
                key === 'zz-other'
                  ? $t('global.zz-other')
                  : `${getGroup(key)?.name.charAt(0).toUpperCase()}${getGroup(
                      key
                    )?.name.slice(1)}`
              }}<span
                class="px-2 bg-secondary3 text-secondary8 rounded-lg mx-3"
                >{{ stocks.length }}</span
              >
            </p>
          </div>
          <div
            :class="`
              rounded border-2 ${
                groupsDisplay[key]
                  ? ' border-secondary7 text-secondary7'
                  : 'border-primary7 text-primary7'
              } font-bold
              flex
              justify-center
              items-center
              w-6
              h-6
            `"
            @click="toggleGroup(key)"
          >
            <i
              :class="`bi bi-${groupsDisplay[key] ? 'dash' : 'plus'} font-bold`"
            ></i>
          </div>
        </div>

        <div
          v-if="groupsDisplay[key]"
          :id="getGroup(key)?.id"
          class="ml-4 border-l border-secondary4 px-4 transition transition-all duration-1000"
        >
          <Alias
            v-for="alias in stocks"
            :key="alias.id"
            :stock="alias"
            :color="getGroup(key)?.colorCode"
            :is-current="currentStock && currentStock.id === alias.id"
            @onSelect="setCurrentStockALias"
          />
        </div>
      </div>
    </div>
    <div v-if="canDo" class="bottom-container bg-white text-xl">
      <Button
        :disabled="false"
        :label="$t('stockBook.form.new')"
        size="medium"
        variant="secondary"
        class="w-full h-10 flex justify-center items-center text-xl"
        @click="initStockForm"
      >
        <i class="bi bi-plus text-md"></i>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import { STOCKTYPE, STOCKTYPESALIAS } from '@/types'
  import {
    CompositionStockAlias,
    OptionStockAlias,
    StockAlias,
    StockGroup
  } from '@/services/api/models'
  import Alias from '@/components/StockAlias/index.vue'
  import moment from 'moment'
  import { groupItemBy, sortObjectByKeys } from '@/utils'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Alias, Button },
    setup() {
      const search = ref('')
      const acl = useAcl()
      const store = useStore()
      const groupsDisplay = ref<any>({})
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )

      const currentStock = computed(
        () => store.getters['organizations/currentStockAlias']
      )

      const stockGroups = computed<StockGroup[]>(
        () => store.getters['organizations/stockGroups']
      )

      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true,
          stockType: STOCKTYPE.SAMPLE
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
      }

      const getAliases = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: currentCompany.value.id,
          filter: { ...filter }
        })
      }

      const setCurrentStockALias = (stock: StockAlias) => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          stockType: stock.type
        })
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: currentCompany.value.id,
          stockId: stock.id,
          type:
            stock.type === STOCKTYPE.SAMPLE
              ? STOCKTYPESALIAS.STOCK
              : stock.type === STOCKTYPE.OPTION
              ? STOCKTYPESALIAS.OPTION
              : STOCKTYPESALIAS.COMPOSITION
        })
      }

      const stocks = computed<StockAlias[]>(
        () => store.getters['organizations/stockAliases']
      )

      const optionStockAliases = computed<OptionStockAlias[]>(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed<CompositionStockAlias[]>(
        () => store.getters['organizations/compositionStockAlias']
      )

      const groups = computed(() => {
        return sortObjectByKeys(
          groupItemBy(
            listedStockAliases.value,
            (item) => item.group?.id || 'zz-other'
          )
        )
      })
      const toggleGroup = (id: string) => {
        groupsDisplay.value[id] = !groupsDisplay.value[id]
      }
      const getGroup = (id: string) => {
        return stockGroups.value.find((x) => x.id === id)
      }

      const allStocks = computed(() =>
        [
          ...optionStockAliases.value,
          ...stocks.value,
          ...compositionStockAlias.value
        ].sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      )

      const listedStockAliases = ref(allStocks.value)

      watch(search, (text: string) => {
        getAliases(text)
      })

      watch(allStocks, (stocks) => {
        listedStockAliases.value = stocks
      })

      watch(stockGroups, (grps) => {
        grps.forEach((g) => (groupsDisplay.value[g.id] = true))
      })

      onBeforeMount(() => {
        getAliases()
      })
      return {
        canDo,
        search,
        groupsDisplay,
        currentStock,
        groups,
        initStockForm,
        toggleGroup,
        getGroup,
        setCurrentStockALias
      }
    }
  })
</script>
<style lang="scss" scoped>
.alias-list::-webkit-scrollbar {
  display: none;
}
.alias-list {
  @apply pb-5;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.alias-list::-webkit-scrollbar {
  display: none;
}

.bottom-container {
  @apply absolute bottom-0 right-0 left-0 z-10 h-20 flex justify-center items-center px-4;
}
</style>
