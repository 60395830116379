<template>
  <div id="target" class="h-full p-0 flex justify-between">
    <!-- TREEVIEW -->
    <DocumentTreeview ref="documentTreeview" />
    <!-- RIGHT SIDE -->
    <div class="extraction__wrapper">
      <div class="extraction__body relative">
        <!-- EXTRACTION DE 1ER NIVEAU -->
        <FirstLevel />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import FirstLevel from '@/components/Event/ExtractForm.vue'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import { defineComponent, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'Extraction',
    components: {
      DocumentTreeview,
      FirstLevel
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()

      watch(
        () => route.params.id,
        (newCmp) => {
          store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
          store.commit('ui/COLLAPSE_DOC_PREVIEW', false)

          router.push({ name: 'companyDocuments', params: { id: newCmp } })
        }
      )
      return {}
    },
    data() {
      return {
        document: null,
        uuid: null,
        hightLightDocument: false,
        currentPage: 0,
        showTreeview: true
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/extraction.scss';
</style>
