
  import { computed, defineComponent, ref, toRefs, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { Tag } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { OptionStockCategory, StockCategory } from '@/services/api/models'

  export default defineComponent({
    name: 'DropdownStockCategories',
    components: { Multiselect, Field, Tag },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: String
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'top'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      optionPerPrice: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      stocksData: {
        type: Object,
        default: () => ({
          value: [],
          contactId: '',
          index: -1
        })
      }
    },
    emits: ['onUpdated'],

    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const { data } = toRefs(props)
      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name

      const stockCategories = computed<StockCategory[]>(
        () => store.getters['toolbox/stockCategories']
      )
      const optionCategories = computed<OptionStockCategory[]>(
        () => store.getters['toolbox/optionCategories']
      )
      const formValue = ref('')
      const dataType = computed(() => {
        if (category.value === 'composition') return 'composition'
        else if (
          stockCategories.value.map((x) => x.id).includes(category.value)
        )
          return 'stock'
        else if (
          optionCategories.value.map((x) => x.id).includes(category.value)
        )
          return 'option'
        return null
      })
      const category = ref(props.value)
      const categories = computed(() => [
        {
          id: '1',
          name: t('companyStockType.SIMPLE'),
          label: t('companyStockType.SIMPLE'),
          value: 'stocks',
          children: stockCategories.value
        },
        {
          id: '2',
          name: t('companyStockType.COMPOSED'),
          label: t('companyStockType.COMPOSED'),
          value: 'optionStock',
          children: optionCategories.value
        },
        {
          id: '3',
          name: t('companyStockType.COMBINED'),
          label: t('companyStockType.COMBINED'),
          value: 'compositionStocks',
          children: [{ id: 'composition', name: 'Composition' }]
        }
      ])

      const updateValue = (selected: string) => {
        // const ids = [
        //   ...categories.value.map((x) => x.children.map((c: any) => c.id))
        // ]
        // category.value = ids.flat().includes(selected) ? selected : ''

        emit('onUpdated', {
          ...data.value,
          dataType: dataType.value,
          value: selected
        })
      }

      return { categories, category, fieldName, updateValue }
    }
  })
