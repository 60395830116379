<template>
  <div class="flex flex-col h-100">
    <div class="flex flex-col justify-center items-center w-100 h-100">
      <router-link :to="{ name: 'auth.connect' }">
        <img
          class="flex w-40 h-40 object-fit"
          src="https://images.prismic.io/uplaw%2F88f08cec-5696-4c1e-a527-9a0ed9b6571d_logo.svg?auto=compress,format"
        />
      </router-link>
      <div class="login-container">
        <p
          class="
            mt-2
            mb-5
            border border-1
            p-3
            rounded
            border-primary4
            text-primary4
            font-500
            text-xl
          "
        >
          {{ $t('login.welcomeText') }}
        </p>
        <!-- ENVOYER -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, onMounted } from 'vue'
  export default defineComponent({
    name: 'AuthConnect',
    components: {},
    setup() {
      const Auth: any = inject('Auth')
      onMounted(() => {
        Auth.loginWithRedirect()
      })

      return {}
    }
  })
</script>

<style lang="scss" scoped></style>
