
  import { defineComponent, toRefs, computed } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { isNumber } from 'lodash'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { OptionExercise } from '@/types'
  import { Company, NominalValue } from '@/services/api/models'
  export default defineComponent({
    name: 'IssuanceCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => OptionExercise | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { event } = toRefs(props)
      const store = useStore()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed(() => props.event?.optionExerciseParts || [])

      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.issuanceDate).unix() >
            moment.utc(x.date).unix()
        )

        return nv?.nominalValue || 0
      })
      const totalQuantity = computed(() => {
        return (props.event?.optionExerciseParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })

      const totalIssuedQuantity = computed(() => {
        return (props.event?.optionExerciseParts || []).reduce(
          (sale: number, p: any) => (sale += p.issuedQuantity),
          0
        )
      })

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      const emitTotal = (row: any) => {
        return (row.unitPrice - nominalValue.value) * row.quantity
      }

      const emitPerAction = (row: any) => {
        if (!nominalValue.value || !isNumber(row.unitPrice)) {
          return '-'
        }

        return row.unitPrice - nominalValue.value
      }

      const totalPrice = computed((row) => {
        return row.quantity * row.unitPrice
      })

      const globalTotal = computed(() => {
        return (props.event?.optionExerciseParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity * p.unitPrice),
          0
        )
      })

      const getLineTotalPrice = (line: any) => {
        return line.issuedQuantity * line.unitPrice
      }

      const globalIssuancePerAction = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              (line.quantity * line.unitPrice) / parseInt(line.quantity || 1) -
              nominalValue.value),
          0
        )
      )

      const globalEmit = computed(() => {
        return parts.value.reduce(
          (acc: number, line: any) =>
            (acc += (line.unitPrice - nominalValue.value) * line.quantity),
          0
        )
      })

      const globalNominalIncrease = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value = globalTotal.value / totalQuantity.value
        }
        return value
      })

      const globalTotalPrice = computed(() => {
        return parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.issuedQuantity || 0) * line.unitPrice),
          0
        )
      })

      return {
        parts,
        totalQuantity,
        totalIssuedQuantity,
        totalPrice,
        globalUnitPrice,
        globalTotalPrice,
        globalIssuancePerAction,
        globalNominalIncrease,
        globalEmit,
        currency,
        contactsId,
        emitPerAction,
        getLineTotalPrice,
        moment,
        formatNumber,
        emitTotal,
        augNominal
      }
    }
  })
