<template>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.contact') }}
      </h1>
      <p v-if="part?.contact" class="text-blackp text-sm text-left">
        <router-link
          :to="{
            name: 'company.directory',
            params: {
              id: $route.params.id,
              contactId: part?.contact.id
            }
          }"
        >
          <ContactBadge
            class="p-0 text-xs"
            :contact="part?.contact"
            :details="false"
            type="light"
        /></router-link>
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.stockAlias') }}
      </h1>
      <p
        class="text-blackp text-sm text-left"
        v-if="part?.freeShareIssuance?.stockAlias"
      >
        <Tag
          :value="part?.freeShareIssuance?.stockAlias.id"
          :text="part?.freeShareIssuance.stockAlias.alias.toUpperCase()"
          :with-initials="false"
          :with-remove="false"
        />
      </p>

      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.quantity') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ formatNumber(event.quantity) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { FreeSharesFinalAcquisition } from '@/services/api/models'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { defineComponent, computed } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  export default defineComponent({
    name: 'FinalAcquisition',
    components: { ContactBadge, Tag },
    props: {
      event: {
        type: Object as () => FreeSharesFinalAcquisition | undefined,
        default: null
      }
    },
    setup(props) {
      const part = computed(
        () => props.event?.acquisitionCalendar.freeSharesIssuancePart
      )

      return { moment, part, formatNumber }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
