<template>
  <div class="h-full">
    <Field v-slot="{ field }" v-model="search" type="text" name="search">
      <Input
        v-model="search"
        v-bind="field"
        :disabled="false"
        :placeholder="$t('global.search')"
      >
      </Input>
    </Field>
    <div class="alias-list h-4/5 overflow-scroll">
      <div
        v-for="g in operations"
        :key="g.id"
        :class="`p-1 my-1 rounded cursor-pointer flex items-center ${
          operation?.id == g.id ? 'bg-secondary5' : 'hover:bg-secondary5'
        }`"
        @click="handleSelect(g.id)"
      >
        <div
          class="w-2 h-2 rounded-full mr-2"
          :style="`background-color: ${g.colorCode}`"
        ></div>
        <h5 class="text-blackp text-md">{{ g.name }}</h5>
      </div>
    </div>
    <div v-if="canDo" class="bottom-container bg-white text-xl">
      <Button
        :disabled="false"
        :label="$t('operationBook.form.new')"
        size="medium"
        variant="secondary"
        class="w-full h-10 flex justify-center items-center"
        @click="initForm"
      >
        <i class="bi bi-plus text-md"></i>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    CompanyModel,
    FundRaisingRound,
    Operation
  } from '@/services/api/models'
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()

      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-operations'))
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const operation = computed<Operation | null>(
        () => store.getters['organizations/operation']
      )

      const getOperations = (search = '') => {
        store.dispatch('organizations/GET_OPERATIONS', {
          companyId: currentCompany.value.id,
          filter: { search }
        })
      }

      const operations = computed<Operation[]>(
        () => store.getters['organizations/operations']
      )

      const initForm = () => {
        store.commit('organizations/SET_OPERATION', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (operationId: string) => {
        store.dispatch('organizations/GET_OPERATION', {
          companyId: currentCompany.value.id,
          id: operationId
        })
      }

      onMounted(() => {
        getOperations()
      })

      watch(search, (text: string) => {
        getOperations(text)
      })

      return { canDo, operation, search, operations, initForm, handleSelect }
    }
  })
</script>
<style lang="scss" scoped>
  .bottom-container {
    @apply absolute bottom-0 right-0 left-0 z-10 h-20 flex justify-center items-center px-4;
  }
</style>
