<template>
  <div
    v-for="block in elements"
    :key="block.key"
    class="
      flex flex-col
      border-secondary5 border
      first:border-l
      py-6
      px-1
      whitespace-nowrap
      rounded-lg
      bg-white
    "
    :style="`width:${100 / (elements.length + 0.5)}%`"
  >
    <i :class="['text-primary4 text-2xl', block.icon]" />
    <div class="mt-3 text-sm text-secondary8">
      {{ $t(`overview.${type}.${block.key}`) }}
    </div>
    <div
      v-if="!data[block.key]['isLoading']"
      :class="`mt-2 font-bold  text-${
        data[block.key]['value'] < 0 ? 'error' : 'black'
      }`"
    >
      {{
        formatNumericalValue(
          data[block.key]['value'],
          data[block.key]['type'],
          {
            currency: data[block.key]['currency'],
            style: data[block.key]['style']
          }
        )
      }}
    </div>
    <div
      v-else
      class="loader flex w-full justify-center text-sm items-center relative"
    >
      <Loader />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { formatNumericalValue } from '@/utils/global'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    name: 'DataBoxes',
    components: { Loader },
    props: {
      data: {
        type: Object,
        required: true
      },
      elements: {
        type: Array,
        default: () => []
      },
      type: {
        type: String,
        default: 'capital'
      }
    },
    setup() {
      return {
        formatNumericalValue
      }
    }
  })
</script>

<style lang="scss">
  .data-box {
    // @apply flex flex-col border-secondary5 border first: border-l py-6 px-1 whitespace-nowrap;
  }

  .loader {
    svg {
      height: 25px;
    }
  }
</style>
