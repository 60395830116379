import { RootState } from '@/store'
import { storageKeys } from '@/utils'
import { debug } from 'loglevel'
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from 'vuex'
import { ErrorDisplay, ErrorType, MenuRoleBasedAccessProps } from './models'
import { addToStorage } from '@/services/Storage'
import i18n from '@/services/lang'
import moment from 'moment'

const SET_APP_LANG = 'SET_APP_LANG'
export const SET_APP_ERROR = 'SET_APP_ERROR'
export const SET_MENU_COMPRESSED = 'SET_MENU_COMPRESSED'
export const GET_MENU_COMPRESSED = 'GET_MENU_COMPRESSED'

interface AppError {
  display: boolean
  message: string | null
  type: ErrorType
  displayMode?: ErrorDisplay
}
export type State = {
  lang: string
  eventPartsLimit: number
  eventDocumentsLimit: number
  error: AppError
  isMenuCompressed: boolean
  controlsAccess: MenuRoleBasedAccessProps
}

interface ConfigMutations {
  [SET_APP_LANG](state: State, locale: string): void
}

const [browserLang] = navigator.language.split('-')
addToStorage(storageKeys.userLang, browserLang || 'fr')

interface LangProps {
  lang: string
}
const state: State = {
  lang: browserLang || 'fr',
  eventPartsLimit: 40,
  eventDocumentsLimit: 40,
  isMenuCompressed: false,
  controlsAccess: {
    dashbord: {
      roles: ['admin'],
      routeName: 'dashbord',
      key: 'dashborad'
    }
  },
  error: {
    type: ErrorType.DEFAULT,
    display: false,
    message: null,
    displayMode: ErrorDisplay.GLOBAL
  }
}

export interface Mutations<State> {
  [SET_APP_LANG](state: State, payload: LangProps): void
  [SET_APP_ERROR](state: State, payload: AppError): void
  [SET_MENU_COMPRESSED](state: State, value: boolean): void
}
export interface Getters {
  lang(state: State): string
  appError(state: State): AppError
  isMenuCompressed(state: State): boolean
}

const mutations: MutationTree<State> & ConfigMutations = {
  [SET_APP_LANG](state, lang: string) {
    state.lang = lang || browserLang
    i18n.global.locale = lang || browserLang
    moment.locale(lang || browserLang)
    addToStorage(storageKeys.userLang, lang || browserLang)
    console.log('store.state.config.lang', lang || browserLang)
  },
  [SET_APP_ERROR](state, error: AppError) {
    state.error = error
  },
  [SET_MENU_COMPRESSED](state, value: boolean) {
    state.isMenuCompressed = value
    localStorage.setItem(storageKeys.isMenuCompressed, value.toString())
  }
}

const actions: ActionTree<State, RootState> = {
  updateUserLang({ commit }: ActionContext<State, RootState>, { lang }) {
    try {
      commit({ type: SET_APP_LANG, lang })
    } catch (error) {
      console.log(`[LANG UPDATE]`, error)
    }
  },
  init({ commit }) {
    try {
      const isMenuCompressed =
        localStorage.getItem(storageKeys.isMenuCompressed) === 'true'
      commit(SET_MENU_COMPRESSED, isMenuCompressed)
    } catch (error) {
      debug('[Config Store]', error)
    }
  }
}

const getters: GetterTree<State, RootState> & Getters = {
  lang: (state) => state.lang,
  eventPartsLimit: (state) => state.eventPartsLimit,
  eventDocumentsLimit: (state) => state.eventDocumentsLimit,
  appError: (state) => state.error,
  isMenuCompressed: (state) => state.isMenuCompressed
}

const config: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default config
