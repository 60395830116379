<template>
  <div class="w-full h-full overflow-y-scroll p-5">
    <div class="flex justify-between relative mb-2">
      <div class="flex flex-col justify-start items-start">
        <h3 class="text-secondary9 font-bold">
          {{ $t('events.edit.title') }}
          {{
            event?.type
              ? `(${$t(`events.labels.${event?.type?.toString()}`)})`
              : ''
          }}
        </h3>
        <span class="text-secondary9 font-normal text-sm">
          {{ $t('events.labels.createdAt') }}:
          {{ moment.utc(event?.createdAt).format('L') }}
        </span>
      </div>
    </div>
    <form-wrapper
      type="event"
      :selected-cat="selectedCat"
      :is-draft="isDraft"
      :event="event"
      @loaded="$emit('loaded', $event)"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { EventType } from '@/types/event'
  import { Event } from '@/services/api/models'
  import FormWrapper from '@/components/Forms/Event/Generic/FormWrapper.vue'
  import moment from 'moment'

  export default defineComponent({
    components: { FormWrapper },
    props: { event: { type: Object as () => Event, required: true } },
    emits: ['loaded'],
    setup() {
      const isDraft = ref(false)
      const selectedCat = ref()
      return { EventType, isDraft, selectedCat, moment }
    }
  })
</script>
