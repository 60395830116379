<template>
  <div class="tabs-cmp flex flex-col items-start w-full h-full">
    <TabGroup :selected-index="selectedTab" as="div" class="w-full">
      <TabList class="tabList">
        <Tab v-for="tab in tabs" :key="tab.key" v-slot="{ selected }" as="div">
          <button
            :class="['tabBtn flex', selected ? 'selected' : 'not-selected']"
            @click="() => selectTab(tab.key)"
          >
            <span>{{ $t(`tabs.${tab.langKey}`) }}</span>
            <div
              v-if="tab.count"
              class="
                ml-2
                bg-primary1
                min-w-5 min-h-5
                px-2
                text-primary4
                rounded-full
                flex
                justify-center
                items-center
              "
            >
              {{ tab.count }}
            </div>
          </button>
        </Tab>
      </TabList>
      <TabPanels class="mt-2 w-full h-full">
        <TabPanel v-for="tab in tabs" :key="tab.key" as="template">
          <div class="w-full h-full">
            <component :is="tab.component" @on-updated="handleParentUpdate" />
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script lang="ts">
  import { ref, defineComponent, watch, computed, onMounted } from 'vue'
  import { TabPanel, TabPanels, TabGroup, TabList, Tab } from '@headlessui/vue'
  import { UplTab } from '@/types'
  export default defineComponent({
    name: 'UplTab',
    components: { TabPanel, TabPanels, TabGroup, TabList, Tab },

    props: {
      tabs: {
        type: Object as () => UplTab[],
        required: true
      },
      defaultSelected: {
        type: String,
        required: false,
        default: null
      }
    },
    emits: ['on-selected', 'on-updated'],
    setup(props, { emit }) {
      const list = computed(() => props.tabs)
      const selectedTab = ref(0)

      const handleParentUpdate = () => {
        emit('on-updated')
      }

      const selectTab = (key: string) => {
        selectedTab.value = props.tabs.map((x) => x.key).indexOf(key)
        emit('on-selected', key)
      }
      watch(props, (val) => {
        if (selectedTab.value >= val.tabs.length) selectedTab.value = 0
      })

      onMounted(() => {
        if (props.defaultSelected) {
          selectTab(props.defaultSelected)
        }
      })

      return { selectedTab, list, handleParentUpdate, selectTab }
    }
  })
</script>

<style lang="scss">
  .tabList {
    @apply w-full flex justify-start border-solid border-b border-secondary4 space-x-4;
    .tabBtn {
      padding: 0 24px;
      @apply pb-2;
      margin-right: 1em !important;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
      }
      &.selected {
        @apply border-solid border-b-2 border-primary5 text-primary5;
        margin-bottom: -1px;
      }
      &.not-selected {
        @apply text-secondary9 hover:text-primary4;
      }
    }
  }

  .tabs-cmp {
    .tab-element {
      padding: 3px 20px !important;
      margin-right: 2em;
      @apply cursor-pointer;
      &.active {
        @apply border-primary5;
        .tab {
          @apply text-primary5;
        }
      }
      .tab {
        padding: 3px 0px !important;
        @apply text-secondary9 hover:text-primary4;
      }
    }
  }
</style>
