<template>
  <div :class="`${classes}`">
    <h4 class="text-secondary9 font-bold text-left">{{ title }}</h4>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SectionGeneric',
    props: {
      title: { type: String },
      classes: {
        type: String,
        default: ''
      }
    },
    setup() {
      return {}
    }
  })
</script>
