<template>
  <div class="h-full w-full">
    <slot name="filters" />
    <div
      v-if="isLoading"
      class="flex w-full min-h-full h-full justify-center items-center mt-10"
    >
      <Loader />
    </div>
    <div v-else class="mt-10">
      <div v-if="events.length === 0" class="flex justify-center items-center">
        <EmptyState
          image="no-result.svg"
          :title="$t('global.noResult')"
          :subtitle="$t('global.selectFilters')"
        />
      </div>
      <div v-else>
        <div class="my-4">
          <event-card
            v-for="event in eventsList"
            :key="event.id"
            :filters="filters"
            :collapsed="collapseAll"
            :event="event"
            @select-card="selectCard"
            @collapse="collapseAll = !collapseAll"
            @updated="listUpdated"
          />
          <VueEternalLoading :load="load">
            <template #loading>
              <p v-if="!noMore" class="text-primary">
                {{ $t('global.loading') }}
              </p>
              <p v-else></p>
            </template>

            <template #no-more>
              <p class="text-primary">
                {{ $t('global.noMore') }}
              </p>
            </template>

            <template #no-results>
              <EmptyState
                image="no-result.svg"
                :title="$t('global.noResult')"
                :subtitle="$t('global.selectFilters')"
              />
            </template>
          </VueEternalLoading>
        </div>
      </div>
    </div>
  </div>
  <transition name="modal">
    <Modal v-if="canDelete" size="w-2/5" @close="canDelete = false">
      <template #header>
        <h3 class="text-md font-semibold text-error">
          {{ $t('events.labels.groupDeleteModalTextHeader') }}
        </h3>
      </template>

      <template #body>
        <p
          class="select-none"
          v-html="
            $tc('events.labels.groupDeleteModalText', {
              size: selectedCards.length
            })
          "
        ></p>
      </template>
      <template #footer>
        <div class="flex text-xl justify-end">
          <Button
            class="flex text-white bg-primary rounded mr-2"
            variant="custom"
            :label="$t('account.accountCompanies.deletionModalCancel')"
            @click="canDelete = false"
          />
          <Button
            class="flex text-white bg-error rounded"
            variant="custom"
            :label="$t('account.accountCompanies.deletionModalText')"
            @click.prevent="submitDeletion"
          />
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import EventCard from '@/components/Event/EventCard.vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { Button } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { Event } from '@/services/api/models'
  import moment from 'moment'
  import { Pagination } from '@/store/modules/Organization/models'
  import Loader from '@/components/Loader/index.vue'
  import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
  import Modal from '@/components/Modal/Modal.vue'

  export default defineComponent({
    name: 'CorporateEventTab',
    components: {
      EmptyState,
      EventCard,
      Loader,
      Button,
      VueEternalLoading,
      Modal
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object as () => any
      }
    },
    emits: [''],
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const canDelete = ref(false)
      const loading = ref(false)
      const { filters } = toRefs<any>(props)
      const defaultFilters = computed(() => props.filters)
      const selectedCards = ref<string[]>([])
      const collapseAll = ref(false)
      const isDraft = ref(false)

      const handleToggle = (value: boolean) => {
        isDraft.value = value
        filters.value.isDraft = value

        getEvents()
      }

      const isLoading = ref(false)
      // const isLoading = computed(
      //   () => store.getters['organizations/loadingEvents']
      // )

      const events = computed(() =>
        store.getters['organizations/events'].filter((item: Event) => item.type)
      )

      const eventsList = ref(
        store.getters['organizations/events'].filter((item: Event) => item.type)
      )
      const pagination = computed<Pagination>(
        () => store.getters['organizations/eventsPagination']
      )

      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )

      const load = async ({ loaded }: LoadAction) => {
        const data = { ...filters.value }

        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft
        const response: any = await store.dispatch(
          'organizations/LOAD_COMPANY_EVENTS',
          {
            companyId: route.params.id,
            filter: data,
            offset: eventsList.value.length
          }
        )
        if (response && response.data) {
          eventsList.value.push(...response.data)
          loaded(response.data.length, pagination.value.currentPage)
        }
      }
      const listUpdated = () => {
        isLoading.value = true
        filters.value = {}

        getEvents()
      }
      const submitDeletion = () => {
        Promise.all(selectedCards.value.map((id) => removeEvent(id))).then(
          (delEvents) => {
            getEvents()

            canDelete.value = false
            selectedCards.value = []
          }
        )
      }

      const removeEvent = (eventId: string) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: route.params.id,
          eventId,
          onSuccess: () => {
            console.log()
          }
        })
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        const { check, id } = data
        if (check) {
          selectedCards.value.push(id)
        } else {
          selectedCards.value.splice(
            selectedCards.value.findIndex((item: string) => item === id),
            1
          )
        }
      }

      const getEvents = async () => {
        const data = { ...filters.value }

        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft
        await store.dispatch('organizations/GET_EVENTS', {
          companyId: route.params.id,
          offset: 0,
          filter: {
            startDate: '1970-01-01',
            endDate: moment().format('YYYY-MM-DD'),
            ...data
          },
          onSuccess: () => {
            isLoading.value = false
          }
        })
      }
      watch(filters, () => {
        getEvents()
      })

      watch(events, (val) => {
        eventsList.value = val
      })

      onMounted(() => {
        isLoading.value = true
        filters.value = {}
        // call api with filter here
        getEvents()
      })

      watch(
        () => route.params.id,
        async (val) => {
          store.commit('organizations/SET_EVENTS', { list: [], update: false })
          getEvents()
        }
      )

      return {
        loading,
        eventsList,
        isLoading,
        noMore,
        events,
        listUpdated,
        selectedCards,
        collapseAll,
        canDelete,
        isDraft,
        defaultFilters,
        load,
        selectCard,
        handleToggle,
        submitDeletion
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import './style.scss';
</style>
