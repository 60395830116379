
  import { mapActions, useStore } from 'vuex'
  import LongText from '@/components/LongText/index.vue'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { companyDefaultImage } from '@/utils/data'
  import moment from 'moment'
  import { truncText } from '@/utils'
  import { Company } from '@/services/api/models'

  export default defineComponent({
    name: 'ContactCard',
    components: {
      LongText
    },
    directives: {},
    props: {
      company: { type: Object as () => Company }
    },
    setup(props) {
      const store = useStore()
      const showCardSecondary = ref(true)

      const contextCompany = computed<Company>(
        () => store.getters['organizations/company']
      )

      const companiesLogos = computed(
        () => store.getters['organizations/companiesLogo']
      )
      const companyUrl = ref<string | null>('')
      const companies = computed<Company[]>(
        () => store.getters['organizations/loaderCompanies']
      )
      const currentCompany = computed<Company | undefined>(() => props.company)
      const companyId = ref(contextCompany.value?.id)

      const actionsListItems = computed(() => [
        {
          name: 'Modifier',
          action: 'edit',
          key: 'edit'
        },
        {
          name: 'Fiche individuelle',
          type: 'list',
          key: 'edit',
          childrens: [
            {
              name: 'Pdf',
              type: 'list',
              action: 'exportPDF',
              key: 'edit'
            }
          ]
        }
      ])
      const getCompanies = async (search = '') => {
        store.dispatch(`organizations/GET_LOADER_COMPANIES`, {
          filter: { search, limit: 30 }
        })
      }

      const handleChange = ($event: string) => {
        companyId.value = $event
      }

      const loadImage = async () => {
        if (
          currentCompany.value?.id &&
          companiesLogos.value[currentCompany.value.id] !== undefined
        ) {
          companyUrl.value = companiesLogos.value[currentCompany.value.id]

          return
        }
        const response = await store.dispatch(
          `organizations/LOAD_COMPANY_LOGO`,
          {
            companyId: currentCompany.value?.id
          }
        )
        if (response) companyUrl.value = response
        else companyUrl.value = null
      }

      watch(
        () => currentCompany.value,
        () => {
          loadImage()
        }
      )

      onMounted(() => {
        getCompanies()
        loadImage()
      })
      return {
        showCardSecondary,
        companyUrl,
        companies,
        companyId,
        companyDefaultImage,
        moment,
        currentCompany,
        actionsListItems,
        truncText,
        handleChange,
        getCompanies
      }
    },

    methods: {
      ...mapActions('ui', ['openBook']),
      exportPDF() {
        window.open('/files/Fiche_individuelle_MANOURY.pdf', '_blank')
      }
    }
  })
