
  import { computed, defineComponent, ref, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  interface ToggleOption {
    label: string
    value: any
  }
  export default defineComponent({
    props: {
      options: { type: Array as () => ToggleOption[], default: () => [] },
      selected: {}
    },
    emits: ['changed'],
    setup(props, { emit }) {
      const { t } = useI18n()

      const displayedOptions = computed(() =>
        props.options?.length >= 2
          ? props.options
          : [
              { label: t('confirm.yes'), value: true },
              { label: t('confirm.no'), value: false }
            ]
      )
      const value = computed(() => props.options[0].value)
      const selectedItem = ref(props.selected || value.value || true)
      const selectedElmClass = ref('bg-selected text-primary font-semibold')

      const toggleSelection = (value: any) => {
        selectedItem.value = value
        emit('changed', value)
      }

      watch(
        () => props.selected,
        (val) => {
          selectedItem.value = val
        }
      )

      return {
        selectedElmClass,
        displayedOptions,
        selectedItem,
        toggleSelection
      }
    }
  })
