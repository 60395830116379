
  import { ElRadio } from 'element-plus'

  import { defineComponent, ref } from 'vue'
  interface CompanyResult {
    constitutedAt: string
    siren: string
    siret: string
    denomination: string
    address?: string
  }
  export default defineComponent({
    components: { ElRadio },
    props: {
      company: {
        type: Object as () => CompanyResult,
        required: true
      },
      isSelected: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      const selectedCompany = ref<string>('')
      return { selectedCompany }
    },
    data() {
      return {
        selectedCompany1: null
      }
    }
  })
