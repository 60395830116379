<template>
  <div class="">
    <h1 class="text-sm uppercase text-white">{{ context }}</h1>
    <span>{{ message }}</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'UplToast',
    props: {
      message: {
        type: String,
        required: true
      },
      context: {
        type: String,
        required: true
      }
    },
    setup() {
      return {}
    }
  })
</script>
<style lang="scss" scoped>
  .Vue-Toastification__toast {
    @apply py-2;
  }
  .Vue-Toastification__container.top-right {
    @apply py-2;
  }
</style>
