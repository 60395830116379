import router from '@/router'
// Import store if you are using reactive Store/Vuex as User data source
import { useStore } from '../store/index'
import { createAcl, defineAclRules } from 'vue-simple-acl/src'
import { computed } from 'vue'
import { User } from '../services/api/models/user'
import { CompanyModel } from '../services/api/models/company'

const store = useStore()
const user = computed<User>(() => store.getters['auth/profile'])
const currentCompany = computed<CompanyModel>(
  () => store.getters['organizations/contextCompany']
)
const permissions = computed<string[]>(() => [
  ...(user.value?.companiesAccounts || [])
    .filter((x) => x.companyId === currentCompany.value?.id)
    ?.flatMap((cmp) => cmp.role.permissions.map((r) => r.slug)),
  ...(user.value?.roles || []).flatMap((r) => r.permissions.map((p) => p.slug))
])

const getPermissions = (user: any) => {
  return (
    user?.companiesAccounts.find(
      (ca: any) => ca.companyId == currentCompany.value?.id
    )?.role?.permissions || []
  ).map((x: any) => x.slug)
}

const rules = async () => {
  defineAclRules(async (setRule) => {
    permissions.value.forEach((perm) => {
      const [verb, func] = perm.split(':')
      setRule(`${verb}-${func}`, (user) => {
        const ps = [
          ...(user?.companiesAccounts || [])
            .filter((x: any) => x.companyId === currentCompany.value?.id)
            ?.flatMap((cmp: any) =>
              cmp.role.permissions.map((r: any) => r.slug)
            ),
          ...(user?.roles || []).flatMap((r: any) =>
            r.permissions.map((p: any) => p.slug)
          )
        ]

        return ps.includes(perm)
      })
    })

    setRule('is-manager', (user) =>
      user?.companiesAccounts
        .find((x: any) => x.companyId === currentCompany.value?.id)
        ?.role?.slug.includes('account-manager')
    )

    setRule(
      'portfolio',
      (user) =>
        user?.companiesAccounts
          .find((x: any) => x.companyId === currentCompany.value?.id)
          ?.role?.slug.includes('account-manager') &&
        permissions.value.includes('read:documents') &&
        permissions.value.includes('read:events')
    )

    setRule(
      'admin',
      (user) =>
        user?.roles.map((x: any) => x.slug).includes('super-administrator') ||
        user?.roles.map((x: any) => x.slug).includes('administrator')
    )

    setRule('read-captable', (user) =>
      permissions.value.includes('read:captable')
    )

    setRule('read-stocks', (user) => {
      return permissions.value.includes('read:stocks')
    })

    setRule('write-stocks', (user) => {
      return permissions.value.includes('write:stocks')
    })

    setRule('read-committees', (user) =>
      permissions.value.includes('read:committees')
    )
    setRule('read-delegations', (user) =>
      permissions.value.includes('read:delegations')
    )
    setRule('write-delegations', (user) =>
      permissions.value.includes('read:delegations')
    )
    setRule('read-corporate', (user) =>
      permissions.value.includes('read:corporate')
    )

    setRule('read-dashboard', (user) =>
      permissions.value.includes('read:dashboard')
    )
    setRule('write-committees', (user) =>
      permissions.value.includes('write:committees')
    )

    setRule('read-operations', (user) =>
      permissions.value.includes('read:operations')
    )

    setRule('write-operations', (user) =>
      permissions.value.includes('write:operations')
    )

    setRule('read-fundRaisingRound', (user) =>
      permissions.value.includes('read:fundRaisingRound')
    )
    setRule('write-fundRaisingRound', (user) =>
      permissions.value.includes('write:fundRaisingRound')
    )

    setRule('read-profitSharingPlan', (user) =>
      permissions.value.includes('read:profitSharingPlan')
    )
    setRule('write-profitSharingPlan', (user) =>
      permissions.value.includes('write:profitSharingPlan')
    )

    setRule('read-vestings', (user) =>
      permissions.value.includes('read:vestings')
    )
    setRule('read-companies', (user) =>
      permissions.value.includes('read:companies')
    )
    setRule('write-companies', (user) =>
      permissions.value.includes('write:companies')
    )
    setRule('read-users', (user) => {
      const ps = getPermissions(user) || permissions.value
      return ps.includes('write:users')
    })

    setRule('write-users', (user) => permissions.value.includes('write:users'))

    setRule('write-vestings', (user) =>
      permissions.value.includes('write:vestings')
    )
    setRule('read-documents', (user) =>
      permissions.value.includes('read:documents')
    )
    setRule('write-documents', (user) =>
      permissions.value.includes('write:documents')
    )
    setRule('read-events', (user) => permissions.value.includes('read:events'))
    setRule('write-alerts', (user) =>
      permissions.value.includes('write:alerts')
    )
    setRule('read-alerts', (user) => permissions.value.includes('read:alerts'))
    setRule('write-events', (user) =>
      permissions.value.includes('write:events')
    )
    setRule('read-contacts', (user) =>
      permissions.value.includes('read:contacts')
    )
    setRule('write-contacts', (user) =>
      permissions.value.includes('write:contacts')
    )
    setRule('read-audits', (user) => permissions.value.includes('read:audits'))
    setRule('write-audits', (user) =>
      permissions.value.includes('write:audits')
    )

    setRule('read-ownership', (user) =>
      permissions.value.includes('read:ownership')
    )

    setRule('download-documents', (user) =>
      permissions.value.includes('download:documents')
    )

    setRule('employee-writeContact', (user) =>
      permissions.value.includes('employee:writeContact')
    )
    setRule('employee-readStocks', (user) =>
      permissions.value.includes('employee:readStocks')
    )
    setRule('employee-readDocuments', (user) =>
      permissions.value.includes('employee:readDocuments')
    )
    setRule('employee-readTransactions', (user) =>
      permissions.value.includes('employee:readTransactions')
    )

    setRule('self-contact', (user) =>
      permissions.value.includes('self:contact')
    )

    setRule('low-access', () => true)

    setRule(
      'employee',
      (user) =>
        user?.companiesAccounts.find(
          (x: any) => x.companyId === currentCompany?.value?.id
        )?.role.slug === 'employee'
    )
  })
}

const simpleAcl = createAcl({
  user,
  rules,
  router,
  onDeniedRoute: '/unauthorized'
})

export default simpleAcl
