
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import LongText from '@/components/LongText/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import { CompanyModel, DocumentAudit } from '@/services/api/models'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { AuditGetType, AuditStatusType, AuditType } from '@/types'
  import { useI18n } from 'vue-i18n'
  import Filter from '@/views/Company/audit/filter.vue'
  import { TYPE } from 'vue-toastification'
  import EmptyState from '@/components/EmptyState/index.vue'

  export default defineComponent({
    components: { DataTable, LongText, Button, Filter, EmptyState },
    emits: ['updateAuditStatus'],
    setup(props, { emit }) {
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const store = useStore()
      const selectedRows = ref<string[]>([])
      const isProcessed = ref(false)
      const selectedStatus = ref()

      const data = computed(() => store.getters['organizations/documentAudits'])
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const pagination = ref(store.getters['organizations/auditPagination'])
      const classes = ref<any>({
        PENDING: 'warning',
        IN_PROGRESS: 'primary',
        DONE: 'success',
        CANCELLED: 'error'
      })
      const columns = [
        {
          label: t(`datatable.column.date`),
          custom: true,
          field: 'createdAt'
        },
        { label: t(`datatable.column.motive`), custom: true, field: 'motive' },
        {
          label: t(`datatable.column.comment`),
          custom: true,
          field: 'comment'
        },
        {
          label: t(`datatable.column.status`),
          custom: true,
          field: 'processed'
        },
        { label: '', custom: true, field: 'actions', width: '3%' }
      ]
      const updateStatus = (status: AuditStatusType) => {
        updateAuditStatus(selectedRows.value, status, AuditGetType.DOCUMENT)
      }
      const getDocument = (data: DocumentAudit) => {
        router.push({
          name: 'companyDocuments',
          params: { id: route.params.id, documentId: data.document.id }
        })
      }

      const updateAuditStatus = (
        ids: string[],
        status: AuditStatusType,
        type: AuditGetType
      ) => {
        store.dispatch('organizations/UPDATE_AUDIT_STATUS', {
          companyId: currentCompany.value.id,
          type,
          data: { auditsId: ids, status },
          onSuccess: () => {
            console.log('onSuccess')

            selectedRows.value = []
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.context'),
              message: t(`audits.editSuccess`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.context'),
              message: t(`audits.editError`)
            })
          }
        })
      }

      const markAsProcessed = (data: DocumentAudit) => {
        const formData = {
          processed: true,
          comment: data.comment,
          motive: data.motive
        }

        store.dispatch('organizations/UPDATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.DOCUMENT,
          typeDataId: data.document.id,
          auditId: data.id,
          formData,
          onSuccess: (audit: DocumentAudit) => {
            console.log()
          },
          onError: () => {
            console.log('ERROR CREATE_AUDIT')
          }
        })
      }

      const onPageChange = (page: number) => {
        getAudits(page)
      }

      const auditSelected = (row: string[]) => {
        selectedRows.value = row
      }

      const getAudits = (page = 1) => {
        const filter: any = {}
        if (isProcessed.value) filter['isProcessed'] = isProcessed.value
        else delete filter['isProcessed']
        store.commit('organizations/SET_AUDIT_PAGINATION', page)
        store.dispatch('organizations/GET_DOCUMENT_AUDITS', {
          companyId: route.params.id,
          filter: {},
          onSuccess: (list: any) => {
            pagination.value = {
              total: list.metadata.count,
              currentPage: list.metadata.currentPage,
              lastPage: list.metadata.lastPage,
              limit: 10
            }
          }
        })
      }

      onMounted(() => {
        getAudits()
      })

      watch(isProcessed, () => {
        getAudits()
      })

      return {
        columns,
        moment,
        data,
        pagination,
        selectedRows,
        selectedStatus,
        classes,
        getDocument,
        updateStatus,
        auditSelected,
        markAsProcessed,
        onPageChange
      }
    }
  })
