
  import { defineComponent, ref, toRefs, onMounted, computed } from 'vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useI18n } from 'vue-i18n'
  import { Input } from '@up.law/uplaw-ui'
  import { TimeUnit } from '@/types/config'
  interface DataInputDuration {
    name: string
    type: string
    label: string
    fieldId: string
    hint?: string
  }

  export interface ValueProp {
    duration: number
    unit: TimeUnit
  }
  export default defineComponent({
    name: 'Duration',
    components: {
      Multiselect,
      UplInput: Input
    },
    inheritAttrs: false,
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputDuration,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      value: {
        type: Object as () => ValueProp,
        default: () => ({ duration: 0, unit: TimeUnit.YEARS })
      },
      choice: {
        type: Number,
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      },
      group: {
        default: false,
        type: Boolean
      },
      label: {
        default: true,
        type: Boolean
      },
      groupBy: {
        type: String,
        required: false,
        default: ''
      },
      needs: {
        type: String,
        default: 'startDate'
      },
      byEvent: {
        type: Boolean,
        default: false
      },
      hasUnlimited: {
        type: Boolean,
        default: false
      },
      onlyDuration: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: -1
      },
      tableId: {
        type: String,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, needs } = toRefs(props)
      const { t } = useI18n()
      const choiceValue = ref(props.choice || 0)

      const formValue = ref(props.value || { duration: 0, unit: TimeUnit.YEARS })
      const unit = ref(formValue.value.unit || 0)
      const quantity = ref(formValue.value.duration)

      const choiceUnit = computed(() =>
        Object.values(TimeUnit).map((x) => ({
          id: x,
          name: t(`events.elements.${x.toLowerCase()}s`)
        }))
      )

      const updateUnit = (value: any) => {
        unit.value = value
        formValue.value = {
          unit: value,
          duration: formValue.value?.duration || 0
        }
        emit('onUpdated', {
          ...data.value,
          index: props.index,
          needs: needs.value,
          value: formValue.value
        })
      }

      const updateQuantity = (value: any) => {
        quantity.value = parseInt(value.target.value)
        formValue.value = {
          unit: formValue.value.unit,
          duration: quantity.value
        }
        emit('onUpdated', {
          ...data.value,
          index: props.index,
          needs: needs.value,
          value: formValue.value
        })
      }

      onMounted(() => {
        emit('onUpdated', {
          ...data.value,
          index: props.index,
          needs: needs.value,
          value: formValue.value
        })
      })
      return {
        formValue,
        choiceValue,
        choiceUnit,
        unit,
        quantity,
        updateQuantity,
        updateUnit
      }
    }
  })
