
  import { computed, defineComponent, ref, onMounted, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import {
    DocumentAuditAnomalies,
    AuditStatus,
    AuditForm,
    DocumentEditForm,
    AuditType
  } from '@/types/forms'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import {
    Contact,
    Document,
    DocumentAudit,
    Operation
  } from '@/services/api/models'
  import DropdownContacts from '../DropDowns/DropdownContacts.vue'
  import { documentSchema } from '@/utils'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { CompanyModel } from '@/services/api/models1'
  import moment from 'moment'
  import { useRoute } from 'vue-router'
  import { Input, UplTheme } from '@up.law/uplaw-ui'
  import {
    ElRadioGroup,
    ElRadioButton,
    ElCollapse,
    ElCollapseItem
  } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      UplDatePicker,
      FormActions,
      UplAuditForm,
      DropdownContacts,
      Input,
      ElRadioButton,
      ElRadioGroup,
      ElCollapse,
      ElCollapseItem
    },
    props: {
      document: { type: Object as () => Document, default: () => null }
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-documents'))
      )
      const newDocument = ref<DocumentEditForm>({
        docSubCategoryId: props.document?.docSubCategory?.id,
        operationsId: props.document?.operations.map((o) => o.id) || [],
        isDraft: props.document?.isDraft,
        signedAt:
          moment
            .utc(props.document?.signedAt)
            .set({ hour: 12, minute: 0 })
            .toISOString() || '',
        contactsId: props.document?.contacts.map((c) => c.id) || [],
        label:
          props.document?.label ||
          props.document?.name.replace('.pdf', '') ||
          ''
      })
      const documentAudit = ref<AuditForm>()
      const hasAnomaly = ref((props.document?.docAudits || []).length > 0)
      const reasons = computed(() => Object.values(DocumentAuditAnomalies))
      const currentDocument = computed<Document>(() => props.document)
      const isPreviewDisplayed = computed(() => {
        return store.getters['ui/displayDocumentPreview']
      })

      const documentPreviewCollapsed = computed(() => {
        return store.getters['ui/documentPreviewCollapsed']
      })
      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const categories = computed(
        () => store.getters[`toolbox/documentCategories`]
      )

      const operations = computed<Operation[]>(
        () => store.getters['organizations/operations']
      )

      const schema = documentSchema
      const onOptionSelected = () => {
        console.log('')
      }

      const updateSelected = (values: string[]) => {
        // newDocument.value.contactsId = values
      }

      const createAudit = (documentAudit: AuditForm) => {
        const formData = {
          motive: documentAudit.motives.join(','),
          processed: false,
          comment: documentAudit.comment
        }
        store.dispatch('organizations/CREATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.DOCUMENT,
          typeDataId: currentDocument.value.id,
          formData,
          onSuccess: (audit: DocumentAudit) => {
            store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
              companyId: currentCompany.value.id,
              documentId: currentDocument.value.id
            })
          },
          onError: () => {
            console.log('ERROR CREATE_AUDIT')
          }
        })
      }

      const handleAuditForm = (values: AuditForm) => {
        documentAudit.value = values
      }

      const displayMessage = () => {
        store.dispatch('toolbox/displayToast', {
          type: TYPE.ERROR,
          context: t('uploads.editDocument'),
          message: t(`uploads.editDocumentError`)
        })
      }

      const handleFormSubmit = (values: DocumentEditForm) => {
        const form = values
        form.contactsId = newDocument.value.contactsId
        form.operationsId = newDocument.value.operationsId
        form.signedAt = moment(form.signedAt)
          .set({ hour: 12, minute: 0 })
          .toISOString()
        form.isDraft = hasAnomaly.value
        store.dispatch('organizations/EDIT_COMPANY_DOCUMENT', {
          companyId: currentCompany.value?.id,
          documentId: currentDocument.value?.id,
          form,
          onSuccess: async () => {
            store.dispatch('organizations/ATTACH_CONTACTS_TO_DOCUMENT', {
              companyId: currentCompany.value.id,
              documentId: currentDocument.value.id,
              form: { contacts: newDocument.value.contactsId },
              onSuccess: () => {
                if (documentAudit.value) {
                  createAudit(documentAudit.value)
                } else {
                  store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
                    companyId: currentCompany.value.id,
                    documentId: currentDocument.value.id
                  })
                }
                store.dispatch('toolbox/displayToast', {
                  type: TYPE.SUCCESS,
                  context: t('uploads.editDocument'),
                  message: t(`uploads.editDocumentSuccess`)
                })
              },
              onError: () => {
                console.log('Error on ATTACH_CONTACTS_TO_DOCUMENT')
                displayMessage()
              }
            })
            if (
              hasAnomaly.value &&
              documentAudit.value &&
              documentAudit.value.comment
            ) {
              await handleAuditForm(documentAudit.value)
            }
          },
          onError: () => {
            displayMessage()
          }
        })
      }

      const handleFormReset = () => {
        store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
      }

      onMounted(() => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id || route.params.id,
          filter: { limit: 10, offset: 0 }
        })
        store.dispatch('organizations/GET_OPERATIONS', {
          companyId: currentCompany.value?.id || route.params.id
        })
      })

      watch(currentDocument, (doc) => {
        newDocument.value = {
          docSubCategoryId: doc?.docSubCategory?.id,
          isDraft: doc.isDraft,
          signedAt:
            moment
              .utc(doc.signedAt)
              .set({ hour: 12, minute: 0 })
              .toISOString() || '',
          label: doc?.label || doc?.name.replace('.pdf', '') || '',
          contactsId: doc.contacts?.map((c) => c.id),
          operationsId: doc.operations?.map((o) => o.id)
        }
      })

      watch(
        () => route.params?.documentId,
        () => {
          if (route.name === 'companyDocuments') {
            store.commit('ui/DISPLAY_PREVIEW', false)
            store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
          }
        }
      )

      return {
        newDocument,
        categories,
        currentDocument,
        currentCompany,
        AuditStatus,
        documentAudit,
        reasons,
        hasAnomaly,
        contacts,
        schema,
        moment,
        DocumentAuditAnomalies,
        AuditType,
        UplTheme,
        documentPreviewCollapsed,
        isPreviewDisplayed,
        updateSelected,
        canDo,
        onOptionSelected,
        handleAuditForm,
        handleFormSubmit,
        handleFormReset,
        operations
      }
    }
  })
