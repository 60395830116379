
  import { Company } from '@/services/api/models'
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useRoute } from 'vue-router'
  import * as yup from 'yup'
  import { Field } from 'vee-validate'
  export default defineComponent({
    name: 'DropdownCompany',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      value: {
        default: '',
        type: [String, Array]
      },
      mode: {
        default: 'single',
        type: String
      },
      placeholder: {
        default: '',
        type: String
      },
      name: {
        default: 'single',
        type: String
      },
      index: {
        type: Number,
        default: null
      },
      hasDefaut: {
        type: Boolean,
        default: false
      }
    },
    emits: ['onUpdated', 'search-change'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const searchText = ref('')
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const schema = yup.object().shape({
        uuid: yup.string().uuid()
      })

      const isValid = computed(() => schema.isValidSync({ uuid: option.value }))
      const companies = ref<Company[]>([])

      const cCompanies = ref<Company[]>([])
      const selectedContainer = ref<Company[]>([])

      const option = ref<any>(props.value)
      const options = ref<any[]>([])

      const updateValue = (selected: any) => {
        const comp = cCompanies.value.find((x) => x.id === selected)
        if (comp && !companies.value.map((x) => x.id).includes(comp.id)) {
          selectedContainer.value.push(comp)
        }
        option.value = selected
        if (Array.isArray(selected) || isValid.value) {
          emit('onUpdated', selected, props.index)
        }
      }

      const searchCompanies = (selected: any) => {
        getCompanies(selected)
      }

      const getCompany = (cmpId: string) => {
        store.dispatch('organizations/GET_SINGLE_COMPANY', {
          companyId: cmpId,
          onSuccess: (cmp: any) => {
            selectedContainer.value.push(cmp)
            companies.value = [...companies.value, cmp]
            option.value = cmpId
          }
        })
      }

      const getCompanies = (text: string) => {
        store.dispatch(`organizations/GET_ACCOUNT_COMPANIES`, {
          filter: {
            search: text,
            shortVersion: isPortfolioRoute.value,
            limit: 20
          },
          onError: (err: any) => {
            console.log('companyError', err)
          },
          onSuccess: (data: Company[]) => {
            cCompanies.value = data ? [...data] : []

            companies.value = [
              ...(data ? data : []),
              ...(text ? [] : companies.value),
              ...selectedContainer.value
            ].reduce((acc: any[], c: any) => {
              if (!acc.map((x: any) => x.id).includes(c.id)) acc.push(c)
              return acc
            }, [])
          }
        })
      }

      watch(
        () => props.value,
        (val) => {
          option.value = val
        }
      )

      onBeforeMount(() => {
        getCompanies('')
      })

      onMounted(() => {
        getCompany(props.value.toString())
      })

      return {
        options,
        option,
        companies,
        searchText,
        updateValue,
        searchCompanies
      }
    }
  })
