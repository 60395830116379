<template>
  <div class="flex justify-center items-center">
    <div class="pb-10 w-full">
      <h3>{{ $t('groupBook.form.add') }}</h3>
      <Form
        v-slot="{ errors, meta, resetForm }"
        :validation-schema="schema"
        @submit="handleForm"
        @reset="handleReset"
      >
        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="name"
            >{{ $t('groupBook.form.name') }}</label
          >
          <Field v-slot="{ field }" v-model="newGroup.name" name="name">
            <Input
              v-bind="field"
              id="name"
              v-model="newGroup.name"
              name="name"
              :disabled="false"
              :placeholder="$t('groupBook.form.namePlaceholder')"
            />
          </Field>
        </div>
        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="colorCode"
            >{{ $t('groupBook.form.color') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newGroup.colorCode"
            name="colorCode"
          >
            <div class="group-color-block">
              <color-picker
                name="colorCode"
                :value="newGroup.colorCode"
                v-bind="field"
                @change="newGroup.colorCode = $event"
              />
            </div>
          </Field>
        </div>

        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="description"
            >{{ $t('groupBook.form.description') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newGroup.description"
            name="description"
          >
            <div class="group-color-block">
              <textarea
                v-bind="field"
                id=""
                name="description"
                class="text-area"
              ></textarea>
            </div>
          </Field>
        </div>

        <div class="flex justify-start items-center w-1/2">
          <div class="flex justify-start items-start">
            <Field
              v-model="newGroup.isEmployeesGroup"
              type="checkbox"
              :value="true"
              name="isEmployeesGroup"
            >
              <Checkbox
                name="isEmployeesGroup"
                :checked="newGroup.isEmployeesGroup"
                @onChange="
                  setFieldValue('isEmployeesGroup', $event),
                    (newGroup.isEmployeesGroup = $event)
                "
              />
            </Field>
          </div>
          <label class="text-md text-secondary9 ml-2 mb-0">{{
            $t('groupBook.form.isEmployeesGroup')
          }}</label>
        </div>

        <div class="w-4/5 mt-5">
          <h3 class="text-md font-semibold text-secondary9" for="contactsId">
            {{
              $tc('groupBook.form.contactsCount', {
                count: newGroup.contactsId.length
              })
            }}
          </h3>
        </div>

        <div v-if="group && group.id && canDo" class="mt-5">
          <h3 class="text-md font-bold">{{ $t('global.settings') }}</h3>
          <div
            class="
              book__settings
              flex
              justify-between
              items-center
              border border-error
              rounded
              p-3
              mt-3
              text-xl
            "
          >
            <div>
              <h5 class="text-blackp">
                {{ $t('groupBook.form.deletionTitle') }}
              </h5>
              <h6>{{ $t('groupBook.form.deletionMessage') }}</h6>
            </div>
            <div class="ml-auto">
              <el-popconfirm
                :title="$t('groupBook.form.promptDeletion')"
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                @confirm="deleteGroup"
              >
                <template #reference>
                  <Button
                    variant="custom"
                    class="bg-error text-white hover:bg-error hover:border-0"
                    :disabled="false"
                    :label="$t('groupBook.form.deletionPersist')"
                  >
                  </Button>
                </template>
              </el-popconfirm>
            </div>
            <i
              v-if="false"
              v-tooltip.bottom="
                `Ce titre est utilisé dans un ou plusieurs évènements.`
              "
              class="bi-lock-fill text-warning xxl"
            />
          </div>
        </div>
        <FormActions
          v-if="canDo"
          :errors="errors"
          :meta="meta"
          @reset-form="resetForm"
        />
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field, useForm } from 'vee-validate'
  import {
    CompanyGroup,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { ContactGroupFormData } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_COMPANY_GROUP,
    EDIT_COMPANY_GROUP
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { groupSchema } from '@/utils/schema/group'
  import ColorPicker from '@/components/ColorPicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    name: 'UplContactGroup',
    components: {
      Form,
      ElPopconfirm,
      Field,
      Input,
      FormActions,
      Button,
      ColorPicker,
      Checkbox
    },
    emits: [],
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )
      const colorCode = ref('#409EFF')
      const newGroup = ref<ContactGroupFormData>({
        name: '',
        colorCode: '',
        description: null,
        contactsId: [],
        isEmployeesGroup: false
      })

      const resetForm = () => {
        newGroup.value = {
          name: '',
          colorCode: '',
          description: null,
          contactsId: [],
          isEmployeesGroup: false
        }
      }

      const modalForm = computed(() => store.getters['organizations/form'])

      const group = computed<CompanyGroup>(
        () => store.getters['organizations/group']
      )

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )

      const groupsContacts = computed<any[]>(() =>
        groups.value.flatMap((c) => c.contacts)
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = ref(groupSchema)
      const { errors, setValues, setFieldValue, values, meta } = useForm({
        initialValues: group.value,
        validationSchema: schema.value,
        validateOnMount: false
      })

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_CONTACT_GROUP', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const allContacts = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contacts'])

      const contacts = computed(() => [
        ...allContacts.value,
        ...groupsContacts.value.filter(
          (x) => !allContacts.value.map((c) => c.id).includes(x.id)
        )
      ])
      const handleForm = (values: ContactGroupFormData) => {
        let edition = false
        let action = CREATE_COMPANY_GROUP
        let formData = {
          companyId: currentCompany.value.id,
          data: { ...values, contactsId: newGroup.value.contactsId },

          groupId: ''
        }
        if (group.value && group.value.id) {
          edition = true
          formData['groupId'] = group.value.id
          action = EDIT_COMPANY_GROUP
        }
        console.log(formData)

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(`groupBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('groupBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('groupBook.form.title'),
              message: t(
                `groupBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          }
        })
      }

      const updateContacts = ($event: any) => {
        console.log($event)

        newGroup.value.contactsId = $event
      }

      const deleteGroup = () => {
        store.dispatch(`organizations/DELETE_COMPANY_GROUP`, {
          companyId: currentCompany.value.id,
          groupId: group.value.id,
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('groupBook.form.title'),
              message: t(`groupBook.apiResponse.deleted`)
            })
          },
          onError: (error = null) => {
            const message = error || t(`groupBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('groupBook.form.title'),
              message
            })
          }
        })
      }
      const handleReset = () => {
        initForm()
      }

      const getContacts = async (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }
      const toggleModal = (state: boolean) =>
        store.dispatch('ui/toggleModal', { display: state })

      watch(
        () => group.value,
        (newValue: any) => {
          if (newValue) {
            newGroup.value = {
              contactsId: newValue.contacts.map((x: any) => x.id),
              colorCode: newValue.colorCode || null,
              name: newValue.name,
              description: newValue.description,
              isEmployeesGroup: newValue.isEmployeesGroup
            }
          }

          console.log(groups.value.flatMap((c) => c.contacts))
        }
      )

      watch(modalForm, (newValues: any) => {
        if (newValues.initCurrentForm) resetForm()
      })

      onMounted(() => {
        getContacts()
      })

      return {
        newGroup,
        group,
        canDo,
        schema,
        colorCode,
        contacts,
        updateContacts,
        toggleModal,
        handleForm,
        deleteGroup,
        handleReset,
        setFieldValue
      }
    }
  })
</script>
<style lang="scss" scoped>
  .text-area {
    @apply resize-none w-full p-2 rounded border border-secondary4 focus:border-primary4 text-secondary9;
    font-size: 0.9em;
  }
</style>
