
  import { defineComponent, inject, onMounted } from 'vue'
  export default defineComponent({
    name: 'AuthConnect',
    components: {},
    setup() {
      const Auth: any = inject('Auth')
      onMounted(() => {
        Auth.loginWithRedirect()
      })

      return {}
    }
  })
