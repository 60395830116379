<template>
  <div>
    <div class="flex overflow-scroll grid grid-cols-8">
      <div class="w-3/12 p-3 flex items-end col-span-2">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.subscriber') }}
        </h1>
      </div>
      <div class="w-1/12 p-3 flex items-end col-span-2">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.stock') }}
        </h1>
      </div>
      <div class="w-1/12 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.totalPrice') }}
        </h1>
      </div>
      <div class="w-1/12 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.unitPrice') }}
        </h1>
      </div>

      <div class="w-1/12 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.nominalIncrease') }}
        </h1>
      </div>
    </div>
    <div class="event-bg">
      <div
        v-for="(part, index) in parts"
        :key="part.id"
        :class="`w-full flex-wrap flex grid grid-cols-8 ${
          contactsId.includes(part.contact?.id) ? 'bg-active font-bold' : ''
        }  ${index > 0 ? 'border-t border-secondary6' : ''}`"
      >
        <div class="p-2 border-r border-secondary6 col-span-2">
          <p v-if="part.contact" class="text-blackp text-sm text-left">
            <router-link
              :target="`${company?.id ? '_blank' : ''}`"
              :to="{
                name: 'company.directory',
                params: {
                  id: company?.id || $route.params.id,
                  contactId: part.contact.id
                }
              }"
            >
              <ContactBadge
                class="p-0 text-xs"
                :contact="part.contact"
                :details="false"
                type="light"
              />
            </router-link>
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>

        <div class="p-3 border-r border-secondary6 col-span-2">
          <p class="text-blackp text-sm text-left">
            <Tag
              v-if="part.stockAlias"
              :value="part.stockAlias.id"
              :text="part.stockAlias.alias.toUpperCase()"
              :with-initials="false"
              :with-remove="false"
            />

            <Tag
              v-else-if="part.optionAlias"
              :text="part.optionAlias.alias.toUpperCase()"
              :value="part.optionAlias.id"
              :with-initials="false"
              :with-remove="false"
            />
            <Tag
              v-else-if="part.compositionAlias"
              :value="part.compositionAlias.id"
              :text="part.compositionAlias.alias.toUpperCase()"
              :with-initials="false"
              :with-remove="false"
            />
          </p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(part.quantity) }}
          </p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(part.totalPrice) }} {{ currency }}
          </p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex justify-end items-center"
        >
          <p class="text-blackp text-sm text-right">
            {{ formatNumber(calculateUnitPrice(part)) }} {{ currency }}
          </p>
        </div>

        <div class="p-3 border-secondary6 flex justify-end items-center">
          <p v-if="part.quantity" class="text-blackp text-sm text-right">
            {{ formatNumber(augNominal(part)) }} {{ currency }}
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
      </div>
      <div
        :class="`${
          parts.length > 0 ? 'border-t border-secondary6 grid grid-cols-8' : ''
        } h-10 flex`"
      >
        <div
          class="
            col-span-4
            border-r border-secondary6
            h-10
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalQuantity) }}
          </p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalTotal) }} {{ currency }}
          </p>
        </div>
        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalUnitPrice) }} {{ currency }}
          </p>
        </div>

        <div class="h-10 flex items-center justify-end">
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(globalNominalIncrease) }} {{ currency }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRefs, computed } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { isNumber } from 'lodash'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import {
    CapitalReduction,
    Company,
    NominalValue
  } from '@/services/api/models'

  export default defineComponent({
    name: 'ReductionCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => CapitalReduction | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { event } = toRefs(props)
      const store = useStore()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.issuanceDate).unix() >
            moment.utc(x.date).unix()
        )
        return nv?.nominalValue
      })

      const parts = computed(() => props.event?.capitalReductionParts || [])

      const totalQuantity = computed(() => {
        return parts.value.reduce(
          (total: number, p: any) => (total += p.quantity),
          0
        )
      })
      const globalReduction = computed(() => {
        return parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      })

      const globalReleased = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseFloat(line.releasedAmount || 0)),
          0
        )
      )
      const globalEmit = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              (parseFloat(line.totalPrice) / parseInt(line.quantity || 0) -
                nominalValue.value) *
              parseInt(line.quantity || 0)),
          0
        )
      )

      const globalIssuancePerAction = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              parseInt(line.totalPrice) / parseInt(line.quantity || 0) -
              nominalValue.value),
          0
        )
      )
      const globalNominalIncrease = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )
      const globalTotal = computed(() =>
        parts.value.reduce(
          (acc: number, line: any) => (acc += parseFloat(line.totalPrice || 0)),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value = parseFloat(globalTotal.value.toString()) / totalQuantity.value
        }
        return value
      })

      const calculateUnitPrice = (row: any) => {
        if (!row.totalPrice || !row.quantity) return 0
        return row.totalPrice / row.quantity
      }

      const emitPerAction = (row: any) => {
        const unitPrice = calculateUnitPrice(row)

        if (!nominalValue.value || !isNumber(unitPrice)) {
          return '-'
        }

        return Math.abs(unitPrice - nominalValue.value)
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseFloat(ePerAction.toString()) * row.quantity
      }

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      return {
        currency,
        nominalValue,
        parts,
        moment,
        globalReduction,
        totalQuantity,
        globalReleased,
        globalUnitPrice,
        globalNominalIncrease,
        globalIssuancePerAction,
        globalEmit,
        contactsId,
        globalTotal,
        formatNumber,
        calculateUnitPrice,
        emitPerAction,
        emitTotal,
        augNominal
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;

    .w-1-7 {
      width: calc(100% / 7);
    }
  }
</style>
