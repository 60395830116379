
  import { Form, Field } from 'vee-validate'
  import {
    defineComponent,
    ref,
    watch,
    computed,
    onMounted,
    onBeforeMount
  } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import * as yup from 'yup'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Multiselect from '../../Select/multiselect.vue'
  import { CompanyModel, Contact, Role } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'

  interface NewInvitationFormProps {
    email: string
    lastName: string
    firstName: string
    contactId?: string
    companyId: string
    roles: string
  }
  export default defineComponent({
    components: {
      UplInput: Input,
      Form,
      Field,
      Multiselect,
      DropdownCompany
    },
    props: {
      companyId: {
        type: String,
        required: false,
        default: null
      },
      index: {
        type: Number,
        required: true
      }
    },
    emits: ['handleChange', 'handleRemove'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t, te } = useI18n()

      const invitee = ref<NewInvitationFormProps>({
        email: '',
        lastName: '',
        firstName: '',
        roles: '',
        contactId: '',
        companyId: props.companyId
      })

      const selectedRolePriority = computed(
        () =>
          allRoles.value.find((x) => x.id === invitee.value.roles)?.priority ||
          0
      )

      const companies = ref<CompanyModel[]>([])

      const highestPriority = computed(() =>
        Math.max(
          ...(store.getters['auth/roles'] || []).map((x: Role) => x.priority)
        )
      )

      const allRoles = computed<Role[]>(() => store.getters['toolbox/roles'])

      const roles = computed(() =>
        [...allRoles.value]
          .filter(
            (x) =>
              x.priority >=
              (![0, 2].includes(highestPriority.value)
                ? highestPriority.value + 1
                : highestPriority.value)
          )
          .sort((a, b) => a.priority - b.priority)
          .map((r) => ({
            ...r,
            name: te(`roles.${r.slug}`) ? t(`roles.${r.slug}`) : r.name
          }))
      )
      const allContacts = computed<Contact[]>(() =>
        store.getters['auth/contacts'].filter((x: Contact) => x.company)
      )
      const contacts = computed(() =>
        allContacts.value.filter(
          (x) => invitee.value.companyId === x.company.id
        )
      )

      const selectectedContacts = []
      const handleSearchCompanies = (text?: string) => {
        getCompanies(text)
      }

      const handleCompany = (data: any, index: number) => {
        // invitee.value.companyId = data
        // invitee.value.contactId = ''
      }

      const schema = yup.object({
        email: yup.string().email().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        roles: yup.string().required(),
        contactId: yup.string().required(),
        companyId: yup.string().required('companyRequired')
      })

      const onChange = (errors: Record<string, string>) => {
        emit('handleChange', props.index, invitee.value)
      }

      const roleChanged = (e?: string) => {
        const role = allRoles.value.find((r) => r.id === e)
        if ((role?.priority || 2) <= 1) {
          invitee.value.companyId = ''
          invitee.value.contactId = ''
        }
        invitee.value.roles = e || ''
        onChange({})
      }

      const onCompanyUpdated = (id: string) => {
        invitee.value.companyId = id
        store.dispatch('organizations/GET_CONTACTS', {
          companyId: id,
          filter: {
            limit: 20,
            offset: 0
          }
        })
      }

      const contactSearch = (text: string) => {
        store.dispatch('organizations/GET_CONTACTS', {
          companyId: invitee.value.companyId,
          filter: {
            limit: 20,
            offset: 0,
            name: text
          }
        })
      }

      const getCompanies = (search = '') => {
        store.dispatch('organizations/GET_ACCOUNT_COMPANIES', {
          companyId: route.params.id,
          filter: {
            limit: 20,
            offset: 0,
            search
          },
          onSuccess: (data: CompanyModel[]) => {
            companies.value = [...data]
          }
        })
      }

      const handleRemove = () => {
        emit('handleRemove', props.index)
      }
      const getRoles = () => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id
        })
      }

      const getContacts = (search = '') => {
        if (!invitee.value.companyId) return
        store.dispatch('organizations/GET_CONTACTS', {
          companyId: invitee.value.companyId,
          filter: {
            limit: 20,
            offset: 0,
            name: search
          }
        })
      }

      const updateSelected = (data: Record<string, string>) => {
        onChange(data)
      }

      const getContact = (id: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: invitee.value?.companyId,
          contactId: id,
          onSuccess: (contact: any) => {
            //
          },
          onError: (err: any) => {
            //
          }
        })
      }

      watch(
        () => invitee.value,
        (val) => {
          emit('handleChange', props.index, val)
        }
      )

      watch(
        () => props.companyId,
        (val) => {
          invitee.value.companyId = val
          invitee.value.contactId = ''
          emit('handleChange', props.index, invitee.value)
        }
      )

      watch(selectedRolePriority, (val) => {
        if (val && val > 1) invitee.value.companyId = ''
      })

      onBeforeMount(() => {
        getCompanies()
      })

      onMounted(() => {
        getContacts()
        // getContact()
        getRoles()
      })

      return {
        schema,
        invitee,
        roles,
        contacts,
        selectedRolePriority,
        companies,
        contactSearch,
        onCompanyUpdated,
        handleCompany,
        handleSearchCompanies,
        onChange,
        updateSelected,
        handleRemove,
        roleChanged
      }
    }
  })
