<template>
  <div :class="`DurationGeneric ${classes}`">
    <label class="capitalize text-xs font-semibold text-secondary8 text-left">{{
      data.label
    }}</label>
    <Multiselect
      v-if="!onlyDuration"
      v-model="choiceValue"
      :value="choiceValue"
      :name="data.name"
      :show-labels="true"
      mode="single"
      :options="choices"
      :group-options-field="groupBy"
      :placeholder="$t(`events.elements.${data.name}`)"
      :groups="group"
      class="w-full"
      @change="updateChoice"
    ></Multiselect>
    <div v-if="choiceValue === 1" class="mt-4">
      <UplDatePicker
        v-model="formValue"
        :data="data"
        class="hover:border-primary4"
        :enable-time-picker="false"
        placeholder="Select date"
        close-on-scroll
        @onUpdated="dateChanged"
      />
    </div>
    <div v-else-if="choiceValue === 2" class="mt-4 flex items-center">
      <UplInput
        v-model="quantity"
        class="flex"
        type="number"
        name="durationQuantity"
        :disabled="false"
        placeholder="durationQuantity"
        @input="updateQuantity"
      />
      <Multiselect
        :value="unit"
        :show-labels="true"
        mode="single"
        :options="choiceUnit"
        :group-options-field="groupBy"
        :placeholder="$t(`events.elements.${data.name}`)"
        :groups="group"
        class="ml-4"
        @change="updateUnit"
      />
      <UplDatePicker
        v-show="false"
        v-model="formValue"
        :data="data"
        class="hover:border-primary4"
        :enable-time-picker="false"
        placeholder="Select date"
        close-on-scroll
      />
    </div>
    <div v-else-if="choiceValue === 3">
      <UplInput
        v-model="year"
        class="mt-4"
        :value="year"
        name="year"
        :placeholder="$t('events.fieldsLabels.accountingExerciseYear')"
        :show-labels="true"
        @input="updateYears"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, toRefs, watch, onMounted, computed } from 'vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  // import UplDatePicker from '@/components/DatePicker/index.vue'
  import UplDatePicker from './DateGeneric.vue'
  import { useI18n } from 'vue-i18n'
  import { Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import moment from 'moment'
  interface DataInputDuration {
    name: string
    type: string
    label: string
    fieldId: string
    hint?: string
  }

  export default defineComponent({
    name: 'DurationGeneric',
    components: {
      Multiselect,
      UplDatePicker,
      UplInput: Input
    },
    inheritAttrs: false,
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputDuration,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      value: {
        type: [String, Number],
        default: null
      },
      choice: {
        type: Number,
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      },
      group: {
        default: false,
        type: Boolean
      },
      groupBy: {
        type: String,
        required: false,
        default: ''
      },
      needs: {
        type: String,
        default: 'startDate'
      },
      byEvent: {
        type: Boolean,
        default: false
      },
      hasUnlimited: {
        type: Boolean,
        default: false
      },
      onlyDuration: {
        type: Boolean,
        default: false
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value, needs, byEvent, hasUnlimited } = toRefs(props)
      const { t } = useI18n()
      const startDate = ref(value.value)
      const choiceValue = ref(props.choice || 0)
      const unit = ref('days')
      const quantity = ref(0)
      const year = ref()

      const formValue = ref(props.value)

      const choices = computed(() => {
        const data = [
          {
            id: 1,
            name: t('events.elements.endDate'),
            value: 'date'
          },
          {
            id: 2,
            name: t('events.elements.Duration'),
            value: 'duration'
          }
        ]
        if (byEvent.value) {
          data.push({
            id: 3,
            name: t('events.elements.byEvent'),
            value: 'event'
          })
        }
        if (hasUnlimited.value) {
          data.push({
            id: 4,
            name: t('events.elements.timeUnlimited'),
            value: 'unlimited'
          })
        }

        return data
      })

      const choiceUnit = [
        {
          id: 'days',
          name: t('events.elements.days'),
          value: 'days'
        },
        {
          id: 'months',
          name: t('events.elements.months'),
          value: 'months'
        },
        {
          id: 'years',
          name: t('events.elements.years'),
          value: 'years'
        }
      ]

      const updateChoice = (value: any) => {
        if (value === 2) {
          unit.value = 'days'
          quantity.value = 0
        } else if (value === 3) {
          year.value = new Date().getFullYear().toString()
        }
        formValue.value = new Date(props.value).toString()
        choiceValue.value = value
      }

      const updateUnit = (value: any) => {
        unit.value = value
        formValue.value = moment
          .utc(startDate.value)
          .add(quantity.value, value)
          .format('yyyy-MM-DD')
        emit('onUpdated', {
          ...data.value,
          needs: needs.value,
          value: formValue.value,
          options: {
            choiceValue: choices.value[choiceValue.value - 1],
            unit: unit.value,
            quantity: quantity.value,
            year: year.value
          }
        })
      }

      const dateChanged = (value: any) => {
        emit('onUpdated', {
          ...value
        })
      }
      const updateQuantity = (value: any) => {
        quantity.value = parseInt(value.target.value)
        formValue.value = moment
          .utc(startDate.value)
          .add(value.target.value, unit.value)
          .format('yyyy-MM-DD')
        emit('onUpdated', {
          ...data.value,
          needs: needs.value,
          value: formValue.value,
          options: {
            choiceValue: choices.value[choiceValue.value - 1],
            unit: unit.value,
            quantity: quantity.value,
            year: year.value
          }
        })
      }

      const updateYears = (event: { target: HTMLInputElement }) => {
        year.value = event.target.value
      }

      watch([formValue, year], ([, newYerr]) => {
        let value = ''
        if (choiceValue.value === 3) {
          value = newYerr || moment(formValue.value).year().toString()
          year.value = newYerr || moment(formValue.value).year().toString()
        } else if (choiceValue.value === 4) {
          value = 'unlimited'
        } else {
          value = moment(formValue.value).format('yyyy-MM-DD')
        }

        emit('onUpdated', {
          ...data.value,
          needs: needs.value,
          value,
          options: {
            choiceValue: choices.value[choiceValue.value - 1],
            unit: unit.value,
            quantity: quantity.value,
            year: year.value
          }
        })
      })

      onMounted(() => {
        emit('onUpdated', {
          ...data.value,
          needs: needs.value,
          value: moment(formValue.value).format('yyyy-MM-DD')
        })

        formValue.value = value.value ?? new Date()

        year.value = (value.value ? moment.utc(value.value || '') : moment())
          .year()
          .toString()
      })
      return {
        formValue,
        choiceValue,
        choices,
        choiceUnit,
        year,
        unit,
        quantity,
        updateChoice,
        dateChanged,
        updateQuantity,
        updateUnit,
        updateYears
      }
    }
  })
</script>
