
  import { computed, defineComponent, onMounted } from 'vue'
  import { Document as DocumentModel } from '@/services/api/models'
  import moment from 'moment'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'Document',

    props: {
      document: {
        type: Object,
        required: true
      },
      byDate: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['selectDocument', 'selectedDocument'],

    setup(props, { emit }) {
      const store = useStore()
      const currentDocument = computed<DocumentModel | null>(
        () => store.getters['organizations/currentDocument']
      )
      const isDocumentSelected = computed(
        () => props.document.id === currentDocument.value?.id
      )

      onMounted(() => {
        if (isDocumentSelected.value) {
          emit('selectedDocument')
        }
      })

      return { currentDocument, isDocumentSelected, moment, emit }
    }
  })
