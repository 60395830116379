
  import Multiselect from '@vueform/multiselect'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { UplTheme } from '@up.law/uplaw-ui'
  import InvitationForm from '@/components/Forms/Account/invitationForm.vue'
  import { computed, defineComponent, ref } from 'vue'
  import { UserInvitationFields } from '@/types/forms'
  import {
    ElTable,
    ElTableColumn,
    ElPagination,
    ElInputNumber
  } from 'element-plus'

  export default defineComponent({
    name: 'AccountTeam',
    components: {
      Btn: Button,
      Multiselect,
      UplInput: Input,
      InvitationForm,
      ElTable,
      ElTableColumn,
      ElPagination,
      ElInputNumber
    },
    setup() {
      const countInvitation = ref(0)
      console.log(UplTheme)

      const invitees = ref<UserInvitationFields[]>([])

      const canSendInvitations = computed(
        (): boolean =>
          invitees.value.length > 0 &&
          !invitees.value
            .map((x) => !Object.values(x).includes(''))
            .includes(false)
      )

      return { countInvitation, invitees, canSendInvitations }
    },
    data() {
      return {
        UplTheme,
        invitationCount: 0,
        tableData: [
          {
            date: '2016-05-03',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-02',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-04',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-01',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-08',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-06',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-07',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          }
        ],
        multipleSelection: [],
        teamImmatriculation: null,
        teamName: null,
        teamCommercialName: null,
        teamAddress: null,
        teamPostalCode: null,
        teamCity: null,
        invites: [],
        inviteRowsNumber: 1,
        teamProfiles: null,
        companyProfiles: null,
        teamUsers: null
      }
    },
    computed: {},
    mounted() {
      // this.teamImmatriculation = this.me.team.immatriculation
      // this.teamName = this.me.team.name
      // this.teamCommercialName = this.me.team.commercialName
      // this.teamAddress = this.me.team.address
      // this.teamPostalCode = this.me.team.postalCode
      // this.teamCity = this.me.team.city
      // this.getProfilesAlias()
      // this.getTeamUsers(this.me.team.uuid)
    },
    methods: {
      confirmList() {
        this.invitationCount = this.countInvitation

        this.invitees = []
        for (let index = 0; index < this.countInvitation; index++) {
          this.invitees.push({
            lastName: '',
            firstName: '',
            email: '',
            profileAlias: '',
            inheritProfileAlias: ''
          })
        }
      },
      updateInviteeList(i: number, value: UserInvitationFields) {
        this.invitees[i] = value
      },
      removeInvitation(index: number) {
        this.invitees = this.invitees.filter((x, i) => i !== index)
        this.countInvitation = this.invitees.length
      },

      sendInvitations() {
        // const newInvites = Array.from(
        //   { length: this.inviteRowsNumber },
        //   () => ({
        //     email: null,
        //     firstname: null,
        //     lastname: null,
        //     profileAlias: null,
        //     inheritProfileAlias: null,
        //     isActive: true,
        //     success: false,
        //   })
        // )
        // this.invites = [].concat(this.invites, newInvites)
      },
      handleRemoveInviteClick(i: number) {
        // this.$delete(this.invites, i)
      },
      async handleSendInvitesClick() {
        // await Promise.all(
        //   this.invites.map(async (invite, i) => {
        //     if (invite.success) {
        //       return
        //     }
        //     try {
        //       await this.inviteUserToTeam(invite)
        //       this.$set(this.invites, i, {
        //         ...invite,
        //         success: true,
        //         error: false,
        //       })
        //     } catch (e) {
        //       this.$set(this.invites, i, {
        //         ...invite,
        //         success: false,
        //         error: true,
        //       })
        //     }
        //   })
        // )
        // this.getTeamUsers(this.me.team.uuid)
      },
      // inviteUserToTeam(inv) {
      //   const invite = inv
      //   invite.inheritMode = !!invite.inheritProfileAlias

      //   const data = {
      //     teamUuid: this.me.team.uuid,
      //     invitation: invite,
      //   }

      //   if (invite.profileAlias) {
      //     data.invitation.profileAliasId = invite.profileAlias.uuid
      //     delete data.invitation.profileAlias
      //   }

      //   if (invite.inheritProfileAlias) {
      //     data.invitation.inheritProfileAliasId =
      //       invite.inheritProfileAlias.uuid
      //     delete data.invitation.inheritProfileAlias
      //   }
      //   // return apiMe.inviteUserToTeam(data)
      // },
      getTeamUsers(teamUuid: string) {
        // apiMe.getTeamUsers(teamUuid, (response) => {
        //   this.teamUsers = response.data.rows
        // })
      },
      getProfilesAlias() {
        // apiMe.getProfileAlias((response) => {
        //   const { data } = response
        //   this.teamProfiles = data.filter(
        //     (profile) => profile.category === 'TEAM'
        //   )
        //   this.companyProfiles = data.filter(
        //     (profile) => profile.category === 'COMPANY'
        //   )
        // })
      },
      removeTeamUser(uuid: string) {
        const data = {
          // teamUuid: this.me.team.uuid,
          userUuid: uuid
        }
        // apiMe.removeTeamUser(data, (reponse) => {
        //   this.getTeamUsers(this.me.team.uuid)
        // })
      },
      updateTeamInfos() {
        // const data = {
        //   teamUuid: this.me.team.uuid,
        //   infos: {
        //     ...this.me.team,
        //     immatriculation: this.teamImmatriculation,
        //     name: this.teamName,
        //     commercialName: this.teamCommercialName,
        //     address: this.teamAddress,
        //     postalCode: this.teamPostalCode,
        //     city: this.teamCity,
        //   },
        // }
        // apiMe.updateTeamInfos(data, (response) => {
        //   this.$store.dispatch('me/updateMe')
        // })
      }
    }
  })
