<template>
  <div
    class="w-full h-full pb-10 px-12 upl-transactions flex flex-col relative"
  >
    <div class="flex">
      <h3 class="text text-blackp text-2xl pt-8">
        {{ $t('company.stock_movements_title') }}
      </h3>
    </div>

    <div class="flex my-5 justify-between">
      <div class="flex md:w-4/5 lg:w-2/3 xl:w-1/2">
        <div class="w-2/5 md:w-2/5 h-10 mr-2">
          <Field v-slot="{ field }" v-model="dateRange" name="dateRange">
            <UplDatePicker
              v-model="dateRange"
              :placeholder="$t('global.datePicker.rangePlaceholder')"
              name="dateRange"
              range
              v-bind="field"
              @cleared="dateCleared"
            />
          </Field>
        </div>

        <div class="w-2/5 min-h-10 mr-2">
          <Field v-slot="{ field }" v-model="selectedContacts" name="contacts">
            <multiselect
              v-model="selectedContacts"
              direction="bottom"
              mode="single"
              class="h-10"
              :placeholder="$t('global.placeholder.contacts')"
              name="contacts"
              :options="contacts"
              v-bind="field"
              :groups="false"
              @change="updateContact"
              @search-change="getContacts"
            />
          </Field>
        </div>

        <div class="w-2/5 min-h-10">
          <Field v-slot="{ field }" v-model="selectedStocks" name="stocks">
            <StockDropdown
              :has-label="false"
              :model-value="selectedStocks"
              open-direction="bottom"
              mode="multiple"
              :can-add-new="false"
              :filterResults="false"
              :placeholder="$t('global.placeholder.stocks')"
              :elements="[]"
              v-bind="field"
              :option-per-price="false"
              @on-updated="updateStockFilter"
            />
          </Field>
        </div>
      </div>

      <div class="h-10 flex">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.columns')"
          placement="top"
          ><Filter
            class="relative mx-2 bg-white"
            :options="availableColumns"
            :value="selectedColumns"
            @on-change="handleFilterSelected"
          >
            <template #option="{ option, checked }" @click.prevent.stop="">
              <Checkbox
                size="w-5 h-5 mr-2"
                :checked="checked"
                :name="option.id"
                @click.prevent.stop
              />
            </template>
            <i class="bi bi-gear"></i> </Filter
        ></el-tooltip>

        <DownloadButton
          v-if="!isDownloading"
          :has-children="true"
          class="mr-2"
          :on-click="download"
        ></DownloadButton>
        <el-tooltip
          v-else-if="isDownloading"
          class="box-item"
          effect="dark"
          :content="$t('tooltips.downloading')"
          placement="top"
        >
          <div
            class="
              border
              rounded-3
              cursor-not-allowed
              bg-white
              border-secondary6
              px-3
              flex
              items-center
            "
          >
            <i
              class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"
            ></i>
          </div>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.associates')"
          placement="top"
        >
          <Button
            variant="custom"
            class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
            @click="downloadAllAssociatesPdf"
          >
            <i class="bi bi-person-lines-fill text-primary4 text-xl"></i>
          </Button>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.resetFilter')"
          placement="top"
          ><Button
            variant="custom"
            class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
            @click="resetFilters"
          >
            <i class="bi bi-filter text-2xl"></i> </Button
        ></el-tooltip>
      </div>

      <transition name="modal">
        <Modal v-if="canDownload" size="w-2/5" @close="canDownload = false">
          <template #header>
            <h3 class="text-md font-semibold text-error">
              {{ $t('contactBook.downloadAllAssociatesModalTextHeader') }}
            </h3>
          </template>

          <template #body>
            <p
              class="select-none"
              v-html="$t('contactBook.downloadAllAssociatesModalText')"
            ></p>
          </template>
          <template #footer>
            <div class="flex text-xl justify-end mt-2">
              <Button
                class="flex text-white bg-primary rounded mr-2"
                variant="custom"
                :label="$t('contactBook.button.exit')"
                @click="canDownload = false"
              />
            </div>
          </template>
        </Modal>
      </transition>
    </div>
    <div v-if="isLoading" class="flex h-full justify-center items-center">
      <Loader />
    </div>
    <div v-else-if="transactions.length > 0" class="mt-2 text-sm">
      <data-table
        thead-class="sticky top-0"
        :is-filtering="isFiltering"
        :columns="columns"
        :rows="transactions"
        :pagination="pagination"
        @on-page-change="onPageChange"
      >
        <template #id="{ row }">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="row.id"
            placement="right"
          >
            <span class="text-left">{{
              row.id.substr(row.id.length - 8)
            }}</span>
          </el-tooltip>
        </template>
        <template #date="{ row }">
          <p class="text-left break-initial">
            {{ moment.utc(row.date).format('L') }}
          </p>
        </template>
        <template #from="{ row }">
          <p v-if="row.transactionType === 'DEBIT'" class="text-left">
            <router-link
              class="cursor-pointer"
              :to="{
                name: 'company.directory',
                params: {
                  id: $route.params.id,
                  contactId: row.associateAccount.contact?.id
                }
              }"
              ><ContactBadge
                :contact="row.associateAccount?.contact"
                :use-picture="true"
                class="flat light"
                type="light"
            /></router-link>
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #to="{ row }">
          <p
            v-if="
              row.associateAccount &&
              row.associateAccount.contact &&
              row.transactionType === 'CREDIT'
            "
            class="text-left"
          >
            <router-link
              class="cursor-pointer"
              :to="{
                name: 'company.directory',
                params: {
                  id: $route.params.id,
                  contactId: row.associateAccount.contact?.id
                }
              }"
            >
              <ContactBadge
                :contact="row.associateAccount.contact"
                class="flat light"
                type="light"
              />
            </router-link>
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #stock="{ row }">
          <Tag
            v-if="row.stockAlias"
            :with-initials="false"
            :text="row.stockAlias.alias.toUpperCase()"
            :value="row.stockAlias.id"
            :with-remove="false"
            :color="rgbToHex(row.stockAlias?.colorCode)"
          />
          <Tag
            v-else-if="row.optionAlias"
            :text="row.optionAlias.alias.toUpperCase()"
            :value="row.optionAlias.id"
            :with-remove="false"
            :with-initials="false"
            :color="rgbToHex(row.optionAlias?.colorCode ?? '')"
          />
          <Tag
            v-else
            :text="row.compositionAlias.alias.toUpperCase()"
            :value="row.compositionAlias.id"
            :with-remove="false"
            :with-initials="false"
            :color="rgbToHex(row.compositionAlias?.colorCode ?? '')"
          />
        </template>
        <template #quantity="{ row }">
          <p class="text-left">{{ formatNumber(row.quantity) }}</p>
        </template>
        <template #comment="{ row }">
          <p class="text-left">Veuillez entrer un commentaure</p>

          <el-popover
            placement="bottom"
            title="Title"
            :width="200"
            trigger="click"
            content="this is content, this is content, this is content"
          >
            <template #reference>
              <Button><i class="bi bi-pen"></i></Button>
            </template>

            <template #default>
              <div class="w-2/5 mt-3">
                <label
                  class="text-xs uppercase font-semibold text-secondary9"
                  for="description"
                  >{{ $t('groupBook.form.comment') }}</label
                >
                <Field v-slot="{ field }" v-model="row.comment" name="comment">
                  <div class="group-color-block">
                    <textarea
                      v-bind="field"
                      :id="`${row.id}-comment`"
                      name="comment"
                      class="text-area"
                    ></textarea>
                  </div>
                </Field>
              </div>
              <div class="flex justify-end">
                <Button><i class="bi bi-pen"></i></Button>
              </div>
            </template>
          </el-popover>
        </template>

        <template #eventName="{ row }">
          <u
            v-if="getEventId(row)"
            class="text-left cursor-pointer hover:text-primary4"
            @click="gotoEvent(getEventId(row))"
          >
            {{ $t(`events.labels.${getEventName(row)}`) }}
          </u>
          <u v-else-if="getEventName(row)" class="text-left">
            {{ $t(`events.labels.${getEventName(row)}`) }}
          </u>
          <u v-else class="text-left">-</u>
        </template>
      </data-table>
    </div>
    <div
      v-else
      class="
        flex
        justify-center
        items-center
        w-full
        mt-4
        transition transition-all
      "
    >
      <EmptyState
        image="no-result.svg"
        class="d-block mx-auto"
        :title="$t('global.noResult')"
        :subtitle="$t('global.noData')"
      />
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Filter, Tag, Button } from '@up.law/uplaw-ui'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import StockDropdown from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import Checkbox from '@/components/Checkbox/index.vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { Contact } from '@/services/api/models'
  import { MAINACTIONS, STOCKTYPE } from '@/types'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { formatNumber, rgbToHex } from '@/utils'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip, ElPopover } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  import Modal from '@/components/Modal/Modal.vue'
  export default defineComponent({
    name: 'UplStockMovements',
    components: {
      Field,
      ContactBadge,
      UplDatePicker,
      StockDropdown,
      Checkbox,
      DataTable,
      Multiselect,
      EmptyState,
      Loader,
      Filter,
      Tag,
      Button,
      DownloadButton,
      ElTooltip,
      ElPopover,
      Modal
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const search = ref('')
      const selectedContacts = ref<string[]>([])
      const selectedColumns = ref<string[]>([])
      const selectedStocks = ref<string[]>([])
      const dateRange = ref([])
      const canDownload = ref(false)
      const { t } = useI18n()
      const isDownloading = ref(false)

      const filter = ref<{
        contactsId?: string[]
        stockAliasesId?: string[]
        optionAliasesId?: string[]
        compositionAliasesId?: string[]
      }>({
        contactsId: [],
        stockAliasesId: [],
        optionAliasesId: [],
        compositionAliasesId: []
      })

      const stockRegisterColumns = computed(() => [
        {
          label: '#',
          field: 'id',
          custom: true,
          width: '5%'
        },
        {
          label: 'Date',
          field: 'date',
          custom: true,
          width: '5%'
        },
        {
          label: t('datatable.column.sender'),
          field: 'from',
          custom: true
        },
        {
          label: t('datatable.column.receiver'),
          field: 'to',
          custom: true
        },
        {
          label: t('datatable.column.stock'),
          field: 'stock',
          custom: true
        },

        {
          label: t('datatable.column.quantity'),
          field: 'quantity',
          custom: true,
          width: '10%'
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          custom: true,
          width: '5%'
        }
        // {
        //   label: t('datatable.column.comment'),
        //   field: 'comment',
        //   custom: true,
        //   width: '5%'
        // }
      ])

      const transactions = computed(
        () => store.getters['organizations/transactions']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )

      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const pagination = computed(
        () => store.getters['organizations/transactionsPagination']
      )

      const getContacts = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            name: search,
            limit: 10,
            offset: 0
          }
        })
      }
      const isLoading = ref(false)
      const isFiltering = ref(false)

      const resetFilters = () => {
        dateRange.value = []

        dateCleared()
        selectedContacts.value = []
        selectedStocks.value = []
      }

      const getTransactions = (companyId = route.params.id) => {
        if (filter.value?.contactsId?.length === 0)
          delete filter.value?.contactsId
        if (filter.value?.stockAliasesId?.length === 0)
          delete filter.value?.stockAliasesId
        if (filter.value?.optionAliasesId?.length === 0)
          delete filter.value?.optionAliasesId
        if (filter.value?.compositionAliasesId?.length === 0)
          delete filter.value?.compositionAliasesId
        store.dispatch('organizations/GET_COMPANY_TRANSACTIONS', {
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isLoading.value = false
            isFiltering.value = false
          }
        })
      }

      const availableColumns = ref(
        stockRegisterColumns.value
          .filter((x) => x.field !== 'actions')
          .map((x) => ({ id: x.field, value: x.label }))
      )

      const getEventId = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }
        if (row.compositionIssuancePart) {
          exercise = row.compositionIssuancePart.compositionIssuance
        }

        if (row.transferPart) {
          exercise = row.transferPart.transfer
        }

        if (row.conversionPart) {
          exercise = row.conversionPart.conversion
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (row?.optionSunsetPart?.optionSunset) {
          exercise = row?.optionSunsetPart?.optionSunset
        }

        if (row.nominalValue) {
          exercise = row.nominalValue
        }

        if (exercise?.event) return exercise.event.id
        else return null
      }

      const getEventName = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.compositionIssuancePart) {
          exercise = row.compositionIssuancePart.compositionIssuance
        }

        if (row.transferPart) {
          exercise = row.transferPart.transfer
        }

        if (row.conversionPart) {
          exercise = row.conversionPart.conversion
        }
        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (row.nominalValue) {
          exercise = row.nominalValue
        }

        if (exercise?.event) return exercise.event.type
        else return row.eventType
      }

      const columns = ref(stockRegisterColumns.value)

      selectedColumns.value = availableColumns.value.map((x) => x.id)

      const handleFilterSelected = (value: string[]) => {
        columns.value = stockRegisterColumns.value.filter((x) =>
          value.includes(x.field)
        )
      }

      const updateContact = (contactId: string) => {
        if (contactId !== '') {
          filter.value.contactsId = [contactId]
          selectedContacts.value = [contactId]
        } else {
          filter.value.contactsId = []
          selectedContacts.value = []
        }
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', page)
        getTransactions()
      }

      const updateStockFilter = ({ value }: any) => {
        if (typeof value === 'string') return
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }

      const dateCleared = () => {
        const dateFilter = {
          startDate: null,
          endDate: null
        }
        filter.value = { ...filter.value, ...dateFilter }
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const download = (key: MAINACTIONS) => {
        if (key === MAINACTIONS.CONTACTS_RECORD_XLS) {
          isDownloading.value = true
          const companyId = route.params.id
          store.dispatch('organizations/EXPORT_COMPANY_TRANSACTIONS', {
            companyId,
            filter: filter.value,
            onSuccess: () => {
              isDownloading.value = false
            },
            onError: () => {
              isDownloading.value = false
            }
          })
        } else downloadPdf()
      }

      const downloadPdf = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_COMPANY_TRANSACTIONS_PDF', {
          name: `Transactions-${moment().format('L')}`,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const downloadAllAssociatesPdf = () => {
        canDownload.value = true
        const companyId = route.params.id

        store.dispatch('organizations/EXPORT_ALL_ASSOCIATES_RECORD', {
          companyId,
          name: `${t(`global.allAssociatesRecord`)}-${moment().format('L')}`
        })
      }

      watch(dateRange, (newDateRange) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)

        const dateFilter = {
          startDate:
            newDateRange && newDateRange[0]
              ? moment.utc(newDateRange[0]).startOf('day').toISOString()
              : null,
          endDate:
            newDateRange && newDateRange[1]
              ? moment.utc(newDateRange[1]).endOf('day').toISOString()
              : null
        }

        filter.value = { ...filter.value, ...dateFilter }

        getTransactions()
      })

      onMounted(() => {
        isLoading.value = true

        getContacts()
        getTransactions()
      })

      watch([selectedContacts, selectedStocks], ([contacts, stocks]) => {
        isFiltering.value = true
        if (typeof stocks === 'string') return
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)
        filter.value.contactsId = contacts
        updateStockFilter({ value: stocks })
        getTransactions()
      })

      watch(
        () => route.params.id,
        (val) => {
          getTransactions(val)
        }
      )
      return {
        search,
        dateRange,
        transactions,
        selectedContacts,
        selectedStocks,
        columns,
        availableColumns,
        selectedColumns,
        moment,
        isDownloading,
        isFiltering,
        pagination,
        contacts,
        isLoading,
        handleFilterSelected,
        gotoEvent,
        getContacts,
        getEventId,
        getEventName,
        updateStockFilter,
        updateContact,
        dateCleared,
        onPageChange,
        formatNumber,
        resetFilters,
        download,
        downloadAllAssociatesPdf,
        canDownload,
        rgbToHex
      }
    }
  })
</script>

<style lang="scss">
  .upl-transactions {
    @apply overflow-y-scroll;
    .button-filter {
      @apply h-full flex justify-center items-center border border-inputBorder rounded-sm hover:bg-primary5 hover:text-white;
      &:hover img {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
      }
    }
    .stock {
      padding: 5px !important;
    }
    .from {
      padding: 5px !important;
    }
    .to {
      padding: 5px !important;
    }
    .date {
      padding: 5px !important;
    }
    .id {
      padding: 5px !important;
    }
    .quantity {
      padding: 5px !important;
    }
    .actions {
      padding: 5px !important;
    }
  }
</style>
