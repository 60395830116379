import routes from './routes'
import { createWebHistory, createRouter } from 'vue-router'
import i18n from '@/services/lang'
import store from '@/store'
import { storageKeys } from '../utils/global'
import { addToStorage, removeFromStorage } from '@/services/Storage'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next('/connect')
  }
  store.commit('ui/DISPLAY_PREVIEW', to.name === 'companyDocuments')
  if (to.meta.titleKey) {
    document.title = `${i18n.global.t(`${to.meta.titleKey}`)} | Uplaw`
  }

  if (to.name?.toString().includes('portfolioView'))
    addToStorage(storageKeys.lastRoute, to.name?.toString() || '')
  if (from.name?.toString().includes('portfolioView'))
    removeFromStorage(storageKeys.lastRoute)
  next()
})

// return router

export default router
