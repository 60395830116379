<template>
  <el-dialog
    v-model="visible"
    :before-close="beforeModalClose"
    :title="$t(`newCompany.editTitle`)"
    :lock-scroll="false"
    top="h-auto"
    custom-class="w-3/5 h-auto overflow-y-scroll max-h-screen"
  >
    <div class="modal-content">
      <div class="flex justify-end">
        <Toggler
          class="w-min"
          :options="toggleOptions"
          :selected="!detailDisplayed"
          @changed="toggled"
        ></Toggler>
      </div>

      <CompanyDetails v-if="detailDisplayed" :company="company" />

      <CompanyForm
        v-else
        :can-submit="true"
        :company="currentCompany"
        mode="edit"
        @saved="saved"
      />
    </div>
  </el-dialog>
</template>

<script lang="ts">
  import { Company } from '@/services/api/models'
  import { CompanyModelForm } from '@/types'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import CompanyForm from './form.vue'
  import CompanyDetails from '@/views/Account/tabs/company.vue'
  import Toggler from '@/components/Toggler/index.vue'
  import { ElDialog } from 'element-plus'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: { CompanyForm, ElDialog, Toggler, CompanyDetails },
    props: {
      isVisible: {
        type: Boolean,
        required: true
      },
      company: {
        type: Object as () => Company,
        required: true
      }
    },
    emits: ['hide'],
    setup(props, { emit }) {
      const visible = ref(props.isVisible)
      const store = useStore()

      const detailDisplayed = ref(false)
      const { t } = useI18n()
      const toggleOptions = computed(() => [
        { label: t('global.actions.updateCompany'), value: true },
        { label: t('global.actions.viewCompany'), value: false }
      ])

      const currentCompany = ref<any>({
        ...props.company,
        commercialName: props.company?.name,
        legalStatusId: props.company?.legalStatus?.id || ''
      })
      const toggled = (value: boolean) => {
        detailDisplayed.value = !value
      }
      const beforeModalClose = () => {
        visible.value = false
        emit('hide', false)
      }

      const saved = () => {
        visible.value = false
        emit('hide', false)
      }

      watch(
        () => props.isVisible,
        (val) => {
          toggled(true)

          visible.value = val
        }
      )

      const loadImage = async () => {
        await store.dispatch(`organizations/LOAD_COMPANY_LOGO`, {
          companyId: currentCompany.value.id
        })
      }

      onMounted(() => {
        console.log('detailDisplayed', detailDisplayed.value)
      })

      watch(
        () => props.company,
        (val) => {
          if (val) {
            currentCompany.value = {
              ...val,
              commercialName: val?.name,
              legalStatusId: val?.legalStatus?.id || ''
            }
            loadImage()
          }
        }
      )
      return {
        currentCompany,
        toggleOptions,
        detailDisplayed,
        visible,
        beforeModalClose,
        toggled,
        saved
      }
    }
  })
</script>
