import { createStore, createLogger, Store } from 'vuex'
import config, { State } from '@/store/modules/Config'
import toolbox from '@/store/modules/Toolbox'
import ui from '@/store/modules/UI'
import organizations from '@/store/modules/Organization'
import portfolio from '@/store/modules/Portfolio'
import auth from '@/store/modules/User'
import { ToolboxState } from './modules/Toolbox/models'
import { UIState } from './modules/UI/models'
import createPersistedState from 'vuex-persistedstate'
import { OrganizationState } from './modules/Organization/models'
import { UserState } from './modules/User/models'
import { PortfolioState } from './modules/Portfolio/models'

export interface RootState {
  toolbox: ToolboxState
  ui: UIState
  config: State
  organizations: OrganizationState
  auth: UserState
  portfolio: PortfolioState
}
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

plugins.push(createPersistedState({ storage: window.sessionStorage }))

const store = createStore<RootState>({
  modules: { toolbox, ui, config, organizations, auth, portfolio },
  plugins: process.env.NODE_ENV === 'development' ? [createLogger()] : []
})

export default store

export function useStore(): Store<RootState> {
  return store as Store<RootState>
}
