
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    onBeforeMount,
    watch
  } from 'vue'
  import { Field } from 'vee-validate'
  import { useRoute } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { FundRaisingRound } from '@/services/api/models'
  export default defineComponent({
    name: 'DropdownProfitSharingPlan',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      allData: {
        default: () => ({}),
        type: Object
      },

      classItem: {
        type: String,
        default: 'w-full'
      },
      type: {
        type: String,
        default: null
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: String
      },
      endDate: {
        default: '',
        type: String
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'top'
      },
      grid: {
        default: true,
        type: Boolean
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { data } = toRefs(props)
      const route = useRoute()

      // allData will be useful for filter if delegation is selected for example
      const formValue = ref<any>(props.value)
      const searchText = ref('')

      const fundRounds = computed<FundRaisingRound[]>(
        () => store.getters['organizations/fundRaisingRounds']
      )

      const updateValue = (value: any) => {
        formValue.value = value
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      watch(fundRounds, (opts) => {
        if (!opts.map((x) => x.id).includes(props.value)) {
          formValue.value = ''
        }
      })

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_FUND_RAISING_ROUNDS', {
          companyId: route.params.id
        })
      })

      return {
        formValue,
        searchText,
        fundRounds,
        updateValue
      }
    }
  })
