import {
  Document,
  CommonCeiling,
  UpFunction,
  Appointment,
  Transfer,
  Delegation,
  VestingSuspension,
  VestingAcceleration,
  VestingDelayProrogation,
  FinancialEnding,
  AccountApproval,
  StockIssuance,
  OptionIssuance,
  CompanyCommittee
} from '@/services/api/models'
import {
  StockAlias,
  OptionStockAlias,
  ProfitSharingPlan,
  CompanyCapitalByGroup,
  CompanyCapitalByStockCategory
} from '@/services/api/models/company'
import {
  CompositionStockAlias,
  CompanyVesting
} from '@/services/api/models/company'
import { STOCKTYPE } from '@/types/config'
import { DocumentAudit } from '@/services/api/models/document'
import { EventAudit } from '@/services/api/models/events'
import { ContactAudit } from '@/services/api/models/company'
import { UserModel, CompanyModel, Transaction } from '@/services/api/models'
import {
  IndividualContact,
  ProfessionalContact,
  InvestorContact
} from '@/services/api/models/company'

import { Captable } from '@/types'
import { Event } from '@/services/api/models/events'
import { CompanyGroup } from '@/services/api/models/company'
import {
  ExerciseCalendar,
  ContactAvailableData
} from '@/services/api/models/company'
import { StockGroup, CapitalReduction } from '@/services/api/models/company'
import { Conversion } from '@/services/api/models/events'
import {
  CompanyGlobalStockData,
  FundRaisingRound
} from '@/services/api/models/company'
import { User } from '@/services/api/models/user'
import { VestingSynthesis, NominalValue } from '@/services/api/models/company'
import { Operation } from '@/services/api/models/company'
import {
  FundingData,
  CompanyFreeSharesVesting
} from '../../../services/api/models/company'
import { Alert } from '../../../services/api/models/user'
import {
  FreeShareIssuancePart,
  FreeSharesAcquisitionCalendar
} from '../../../services/api/models/events'
import {
  CompanyProfitSharingItem,
  EsopData
} from '../../../services/api/models/company'

export interface CompanyStock {
  uid: string
}
export interface CompanyContact {
  uid: string
}
export const STOCK_PRIMARY = 'primary5'
export const STOCK_SECONDARY = 'warning'
export const STOCK_TERTIARY = 'success'
//Actions
export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_LOADER_COMPANIES = 'GET_LOADER_COMPANIES'
export const GET_COMPANY = 'GET_COMPANY'
export const ONBOARD_COMPANY = 'ONBOARD_COMPANY'
export const GET_COMPANY_COMMITTEES = 'GET_COMPANY_COMMITTEES'
export const GET_COMPANY_UID = 'SET_COMPANY_UID'
export const UPLOAD_COMPANY_FILES = 'UPLOAD_COMPANY_FILES'
export const REPLACE_COMPANY_DOCUMENT = 'REPLACE_COMPANY_DOCUMENT'
export const GET_COMPANY_DOCUMENTS = 'GET_COMPANY_DOCUMENTS'
export const BUILD_DEFAULT_COMPANY = 'BUILD_DEFAULT_COMPANY'
export const CREATE_NEW_COMPANY = 'CREATE_NEW_COMPANY'
export const REMOVE_COMPANY = 'REMOVE_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const LOAD_COMPANY_DOCUMENTS = 'LOAD_COMPANY_DOCUMENTS'
export const LOAD_COMPANY_EVENTS = 'LOAD_COMPANY_EVENTS'
export const EDIT_COMPANY_DOCUMENT = 'EDIT_COMPANY_DOCUMENT'
export const DELETE_COMPANY_DOCUMENT = 'DELETE_COMPANY_DOCUMENT'
export const REMOVE_EVENT_DOCUMENT = 'REMOVE_EVENT_DOCUMENT'
export const UPDATE_EVENT_DOCUMENTS = 'UPDATE_EVENT_DOCUMENTS'
export const ATTACH_CONTACTS_TO_DOCUMENT = 'ATTACH_CONTACTS_TO_DOCUMENT'
export const ATTACH_DOCUMENTS_TO_EVENT_PART = 'ATTACH_DOCUMENTS_TO_EVENT_PART'
export const DETACH_DOCUMENTS_TO_EVENT_PART = 'DETACH_DOCUMENTS_TO_EVENT_PART'
export const GET_COMPANY_SINGLE_DOCUMENT = 'GET_COMPANY_SINGLE_DOCUMENT'
export const SEARCH_IN_DOCUMENTS = 'SEARCH_IN_DOCUMENTS'
export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT'
export const SEND_INVITATION_MAIL = 'SEND_INVITATION_MAIL'
export const EDIT_USER_ACCOUNT = 'EDIT_USER_ACCOUNT'
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT'
export const DELETE_USER_COMPANY_PROFILE = 'DELETE_USER_COMPANY_PROFILE'
export const SET_DOCUMENTS_PAGINATION_PAGE = 'SET_DOCUMENTS_PAGINATION_PAGE'
export const SET_EVENTS_PAGINATION_PAGE = 'SET_EVENTS_PAGINATION_PAGE'
export const SET_COMPANIES_PAGINATION_PAGE = 'SET_COMPANIES_PAGINATION_PAGE'
export const CREATE_STOCK = 'CREATE_STOCK'
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const CREATE_VESTING = 'CREATE_VESTING'
export const UPDATE_VESTING = 'UPDATE_VESTING'
export const CREATE_AUDIT = 'CREATE_AUDIT'
export const GET_STOCK_ALIAS = 'GET_STOCK_ALIAS'
export const GET_OPTION_STOCK_ALIAS = 'GET_OPTION_STOCK_ALIAS'
export const GET_COMPOSITION_STOCK_ALIAS = 'GET_COMPOSITION_STOCK_ALIAS'
export const GET_ALL_STOCK = 'GET_ALL_STOCK'
export const VERIFY_STOCK = 'VERIFY_STOCK'
export const GET_COMPANY_VESTINGS = 'GET_COMPANY_VESTINGS'
export const GET_COMPANY_VESTING = 'GET_COMPANY_VESTING'
export const GET_SINGLE_STOCK_ALIAS = 'GET_SINGLE_STOCK_ALIAS'
export const GET_SINGLE_OPTION_ALIAS = 'GET_SINGLE_OPTION_ALIAS'
export const ADD_OPTION_ALIAS = 'ADD_OPTION_ALIAS'
export const GET_SINGLE_VESTING = 'GET_SINGLE_VESTING'
export const GET_COMPANY_CONTACTS = 'GET_COMPANY_CONTACTS'
export const LOAD_COMPANY_CONTACTS = 'LOAD_COMPANY_CONTACTS'
export const FILTER_COMPANY_CONTACTS = 'FILTER_COMPANY_CONTACTS'
export const GET_COMPANY_CONTACT = 'GET_COMPANY_CONTACT'
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS'
export const GET_ACCOUNT_USERS = 'GET_ACCOUNT_USERS'
export const SET_ACCOUNT_USERS = 'SET_ACCOUNT_USERS'

export const SET_ACCOUNTS_PAGINATION_PAGE = 'SET_ACCOUNTS_PAGINATION_PAGE'

export const GET_DOCUMENT_AUDITS = 'GET_DOCUMENT_AUDITS'
export const UPDATE_DOCUMENT_AUDIT = 'UPDATE_DOCUMENT_AUDIT'
export const GET_CONTACT_AUDITS = 'GET_CONTACT_AUDITS'
export const UPDATE_AUDIT_STATUS = 'UPDATE_AUDIT_STATUS'
export const UPDATE_CONTACT_AUDIT = 'UPDATE_CONTACT_AUDIT'
export const GET_EVENT_AUDITS = 'GET_EVENT_AUDITS'
export const UPDATE_EVENT_AUDIT = 'UPDATE_EVENT_AUDIT'
export const GET_COMPANY_TRANSACTIONS = 'GET_COMPANY_TRANSACTIONS'
export const CREATE_NOMINAL_VALUE = 'CREATE_NOMINAL_VALUE'
export const EDIT_NOMINAL_VALUE = 'EDIT_NOMINAL_VALUE'
export const GET_NOMINAL_VALUE = 'GET_NOMINAL_VALUE'
export const SET_NOMINAL_VALUE = 'SET_NOMINAL_VALUE'
export const SET_NOMINAL_VALUES = 'SET_NOMINAL_VALUES'
export const GET_CAPTABLE = 'GET_CAPTABLE'
export const GET_COMPANY_GROUPS = 'GET_COMPANY_GROUPS'
export const GET_COMPANY_GROUP = 'GET_COMPANY_GROUP'
export const DELETE_COMPANY_GROUP = 'DELETE_COMPANY_GROUP'
export const GET_COMPANY_STOCKS_GROUPS = 'GET_COMPANY_STOCKS_GROUPS'
export const GET_COMPANY_STOCKS_GROUP = 'GET_COMPANY_STOCKS_GROUP'
export const DELETE_COMPANY_STOCKS_GROUP = 'DELETE_COMPANY_STOCKS_GROUP'
//Mutations
export const SET_COMPANIES = 'SET_COMPANIES'
export const GET_ACCOUNT_COMPANIES = 'GET_ACCOUNT_COMPANIES'
export const SET_LOADER_COMPANIES = 'SET_LOADER_COMPANIES'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_LOADING = 'SET_LOADING'
export const SET_COMPANY_STOCKS = 'SET_COMPANY_STOCKS'
export const SET_COMPANY_CONTACTS = 'SET_COMPANY_CONTACTS'
export const PUSH_COMPANY_CONTACTS = 'PUSH_COMPANY_CONTACTS'

export const SET_COMPANY_COMMITTEES = 'SET_COMPANY_COMMITTEES'
export const SET_COMPANY_ORGAN = 'SET_COMPANY_ORGAN'
export const GET_COMPANY_COMMITTEE = 'GET_COMPANY_COMMITTEE'
export const CREATE_COMPANY_COMMITTEE = 'CREATE_COMPANY_COMMITTEE'
export const REMOVE_COMPANY_COMMITTEE = 'REMOVE_COMPANY_COMMITTEE'
export const EDIT_COMPANY_COMMITTEE = 'EDIT_COMPANY_COMMITTEE'

export const SET_COMPANY_VESTINGS = 'SET_COMPANY_VESTINGS'
export const SET_COMPANY_UUID = 'SET_COMPANY_UID'
export const INIT_ORGANIZATION = 'INIT_ORGANIZATION'
export const SET_COMPANY_DOCUMENTS = 'SET_COMPANY_DOCUMENTS'
export const ATTACH_FILES_TO_EVENT = 'ATTACH_FILES_TO_EVENT'
export const SET_COMPANY_SINGLE_DOCUMENT = 'SET_COMPANY_SINGLE_DOCUMENT'
export const SET_DOC_SEARCH_RESULT = 'SET_DOC_SEARCH_RESULT'
export const SET_STOCK_ALIAS = 'SET_STOCK_ALIAS'
export const SET_OPTIONS_STOCK_ALIAS = 'SET_OPTIONS_STOCK_ALIAS'
export const UPDATE_OPTIONS_STOCK_ALIAS = 'UPDATE_OPTIONS_STOCK_ALIAS'
export const INIT_MODAL_FORM = 'INIT_MODAL_FORM'
export const SET_MODAL_FORM = 'SET_MODAL_FORM'
export const SET_COMPOSITION_STOCK_ALIAS = 'SET_COMPOSITION_STOCK_ALIAS'
export const SET_CURRENT_STOCK = 'SET_CURRENT_STOCK'
export const IS_LOADING_SINGLE_STOCK = 'IS_LOADING_SINGLE_STOCK'
export const SET_CURRENT_VESTING = 'SET_CURRENT_VESTING'
export const IS_LOADING_VESTING = 'IS_LOADING_VESTING'
export const EDIT_STOCK_ALIAS = 'EDIT_STOCK_ALIAS'
export const REMOVE_STOCK_ALIAS = 'REMOVE_STOCK_ALIAS'
export const CREATE_NEW_ORGAN = 'CREATE_NEW_ORGAN'
export const SET_CURRENT_COMMITTEE = 'SET_CURRENT_COMMITTEE'
export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT'
export const SET_CURRENT_ORGANIGRAM = 'SET_CURRENT_ORGANIGRAM'
export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS'
export const REMOVE_COMPANY_VESTING = 'REMOVE_COMPANY_VESTING'
export const SET_DOCUMENT_AUDITS = 'SET_DOCUMENT_AUDITS'
export const GET_CONTACT_GROUP = 'GET_CONTACT_GROUP'
export const SET_CONTACT_AUDITS = 'SET_CONTACT_AUDITS'
export const SET_EVENT_AUDITS = 'SET_EVENT_AUDITS'
export const SET_CAPTABLE = 'SET_CAPTABLE'
export const SET_CAPTABLE_PAGINATION_PAGE = 'SET_CAPTABLE_PAGINATION_PAGE'
export const UPDATE_AUDIT = 'UPDATE_AUDIT'
export const SET_COMPANY_USERS = 'SET_COMPANY_USERS'
export const SET_COMPANY_GROUPS = 'SET_COMPANY_GROUPS'
export const GET_SINGLE_COMPANY = 'GET_SINGLE_COMPANY'
export const LOAD_COMPANY = 'LOAD_COMPANY'
export const SET_SUMBITTING = 'SET_SUMBITTING'
export const SET_AUDIT_PAGINATION = 'SET_AUDIT_PAGINATION'

export const GET_PROFIT_SHARING_PLANS = 'GET_PROFIT_SHARING_PLANS'
export const CREATE_PROFIT_SHARING_PLAN = 'CREATE_PROFIT_SHARING_PLAN'
export const EDIT_PROFIT_SHARING_PLAN = 'EDIT_PROFIT_SHARING_PLAN'
export const REMOVE_PROFIT_SHARING_PLAN = 'REMOVE_PROFIT_SHARING_PLAN'
export const GET_PROFIT_SHARING_PLAN = 'GET_PROFIT_SHARING_PLAN'
export const SET_PROFIT_SHARING_PLANS = 'SET_PROFIT_SHARING_PLANS'
export const SET_PROFIT_SHARING_PLAN = 'SET_PROFIT_SHARING_PLAN'

export const GET_FUND_RAISING_ROUND = 'GET_FUND_RAISING_ROUND'
export const CREATE_FUND_RAISING_ROUND = 'CREATE_FUND_RAISING_ROUND'
export const EDIT_FUND_RAISING_ROUND = 'EDIT_FUND_RAISING_ROUND'
export const REMOVE_FUND_RAISING_ROUND = 'REMOVE_FUND_RAISING_ROUND'
export const GET_FUND_RAISING_ROUNDS = 'GET_FUND_RAISING_ROUNDS'
export const SET_FUND_RAISING_ROUND = 'SET_FUND_RAISING_ROUND'
export const SET_FUND_RAISING_ROUNDS = 'SET_FUND_RAISING_ROUNDS'

export const GET_OPERATION = 'GET_OPERATION'
export const CREATE_OPERATION = 'CREATE_OPERATION'
export const EDIT_OPERATION = 'EDIT_OPERATION'
export const REMOVE_OPERATION = 'REMOVE_OPERATION'
export const GET_OPERATIONS = 'GET_OPERATIONS'
export const SET_OPERATION = 'SET_OPERATION'
export const SET_OPERATIONS = 'SET_OPERATIONS'

export const CREATE_COMMITTEE = 'CREATE_COMMITTEE'
export const GET_COMMITTEES = 'GET_COMMITTEES'
export const SET_COMMITTEES = 'SET_COMMITTEES'

export const GET_CEILINGS = 'GET_CEILINGS'
export const SET_CEILINGS = 'SET_CEILINGS'
export const GET_FUNCTIONS = 'GET_FUNCTIONS'
export const SET_FUNCTIONS = 'SET_FUNCTIONS'
export const CREATE_CEILING = 'CREATE_CEILING'
export const EDIT_CEILING = 'EDIT_CEILING'
export const GET_CAPITAL_REDUCTION = 'GET_CAPITAL_REDUCTION'
export const SET_CAPITAL_REDUCTION = 'SET_CAPITAL_REDUCTION'

export const GET_DELEGATIONS = 'GET_DELEGATIONS'
export const GET_DELEGATION = 'GET_DELEGATION'
export const APPEND_DELEGATIONS = 'APPEND_DELEGATIONS'
export const GET_DELEGATIONS_TRANSACTIONS = 'GET_DELEGATIONS_TRANSACTIONS'
export const SET_DELEGATIONS = 'SET_DELEGATIONS'
export const CREATE_DELEGATIONS = 'CREATE_DELEGATIONS'
export const EDIT_DELEGATIONS = 'EDIT_DELEGATIONS'

export const CREATE_PRIMARY_STOCK = 'CREATE_PRIMARY_STOCK'
export const EDIT_PRIMARY_STOCK = 'EDIT_PRIMARY_STOCK'
export const CREATE_PRIMARY_OPTIONS = 'CREATE_PRIMARY_OPTIONS'
export const EDIT_PRIMARY_OPTIONS = 'EDIT_PRIMARY_OPTIONS'
export const CREATE_PRIMARY_COMPOSITION = 'CREATE_PRIMARY_COMPOSITION'
export const EDIT_PRIMARY_COMPOSITION = 'EDIT_PRIMARY_COMPOSITION'
export const GET_PRIMARY_STOCK = 'GET_PRIMARY_STOCK'
export const SET_PRIMARY_STOCK = 'SET_PRIMARY_STOCK'
export const GET_PRIMARY_OPTIONS = 'GET_PRIMARY_OPTIONS'
export const SET_PRIMARY_OPTIONS = 'SET_PRIMARY_OPTIONS'
export const GET_PRIMARY_COMPOSITION = 'GET_PRIMARY_COMPOSITION'
export const SET_PRIMARY_COMPOSITION = 'SET_PRIMARY_COMPOSITION'
export const GET_STOCK_ISSUANCE_PARTS = 'GET_STOCK_ISSUANCE_PARTS'
export const REMOVE_STOCK_ISSUANCE_PART = 'REMOVE_STOCK_ISSUANCE_PART'
export const REMOVE_OPTION_ISSUANCE_PART = 'REMOVE_OPTION_ISSUANCE_PART'
export const GET_OPTION_ISSUANCE_PARTS = 'GET_OPTION_ISSUANCE_PARTS'
export const GET_COMPOSITION_ISSUANCE_PARTS = 'GET_COMPOSITION_ISSUANCE_PARTS'
export const GET_EVENT_DOCUMENTS = 'GET_EVENT_DOCUMENTS'
export const CREATE_NOMINATION = 'CREATE_NOMINATION'
export const EDIT_NOMINATION = 'EDIT_NOMINATION'
export const GET_NOMINATIONS = 'GET_NOMINATIONS'
export const GET_NOMINATION = 'GET_NOMINATION'
export const SET_NOMINATIONS = 'SET_NOMINATIONS'
export const SET_NOMINATIONS_PAGINATION_PAGE = 'SET_NOMINATIONS_PAGINATION_PAGE'
export const SET_DELEGATED_TRANSACTIONS = 'SET_DELEGATED_TRANSACTIONS'
export const CREATE_APPOINTMENT_TERMINATION = 'CREATE_APPOINTMENT_TERMINATION'
export const EDIT_APPOINTMENT_TERMINATION = 'EDIT_APPOINTMENT_TERMINATION'
export const CREATE_CONVERSION = 'CREATE_CONVERSION'
export const EDIT_CONVERSION = 'EDIT_CONVERSION'
export const GET_CONVERSIONS = 'GET_CONVERSIONS'
export const SET_CONVERSIONS = 'SET_CONVERSIONS'

export const GET_EXERCISE_CALENDARS = 'GET_EXERCISE_CALENDARS'
export const GET_EXERCISE_CALENDAR = 'GET_EXERCISE_CALENDAR'
export const GET_USERS_WITH_OPTIONS = 'GET_USERS_WITH_OPTIONS'
export const GET_CONTACT_EXERCISE_CALENDARS = 'GET_CONTACT_EXERCISE_CALENDARS'
export const CREATE_OPTIONS_EXERCISE = 'CREATE_OPTIONS_EXERCISE'
export const EDIT_OPTIONS_EXERCISE = 'EDIT_OPTIONS_EXERCISE'
export const GET_OPTIONS_EXERCISE = 'GET_OPTIONS_EXERCISE'
export const DELETE_OPTIONS_EXERCISE = 'DELETE_OPTIONS_EXERCISE'

export const GET_VESTING_SYNTHESIS = 'GET_VESTING_SYNTHESIS'
export const SET_VESTING_SYNTHESES = 'SET_VESTING_SYNTHESES'

export const CREATE_TRANSFER = 'CREATE_TRANSFER'
export const EDIT_TRANSFER = 'EDIT_TRANSFER'
export const GET_TRANSFER = 'GET_TRANSFER'
export const SET_TRANSFER = 'SET_TRANSFER'

export const CREATE_OPTION_SUNSETS = 'CREATE_OPTION_SUNSETS'
export const EDIT_OPTION_SUNSETS = 'EDIT_OPTION_SUNSETS'
export const GET_OPTION_SUNSETS = 'GET_OPTION_SUNSETS'
export const SET_OPTION_SUNSETS = 'SET_OPTION_SUNSETS'

export const CREATE_PARITY_CHANGES = 'CREATE_PARITY_CHANGES'
export const EDIT_PARITY_CHANGES = 'EDIT_PARITY_CHANGES'
export const GET_PARITY_CHANGES = 'GET_PARITY_CHANGES'
export const SET_PARITY_CHANGES = 'SET_PARITY_CHANGES'

export const GET_EVENT = 'GET_EVENT'
export const SET_EVENT = 'SET_EVENT'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'

export const CREATE_FINANCIAL_ENDING = 'CREATE_FINANCIAL_ENDING'
export const EDIT_FINANCIAL_ENDING = 'EDIT_FINANCIAL_ENDING'
export const GET_FINANCIAL_ENDING = 'GET_FINANCIAL_ENDING'
export const SET_FINANCIAL_ENDING = 'SET_FINANCIAL_ENDING'

export const CREATE_VESTING_SUSPENSION = 'CREATE_VESTING_SUSPENSION'
export const EDIT_VESTING_SUSPENSION = 'EDIT_VESTING_SUSPENSION'
export const GET_VESTING_SUSPENSION = 'GET_VESTING_SUSPENSION'
export const SET_VESTING_SUSPENSION = 'SET_VESTING_SUSPENSION'
export const CREATE_VESTING_ACCELERATION = 'CREATE_VESTING_ACCELERATION'
export const EDIT_VESTING_ACCELERATION = 'EDIT_VESTING_ACCELERATION'
export const GET_VESTING_ACCELERATION = 'GET_VESTING_ACCELERATION'
export const SET_VESTING_ACCELERATION = 'SET_VESTING_ACCELERATION'
export const CREATE_VESTING_PROROGATION = 'CREATE_VESTING_PROROGATION'
export const EDIT_VESTING_PROROGATION = 'EDIT_VESTING_PROROGATION'
export const GET_VESTING_PROROGATION = 'GET_VESTING_PROROGATION'
export const SET_VESTING_PROROGATION = 'SET_VESTING_PROROGATION'
export const CREATE_APPROVAL = 'CREATE_APPROVAL'
export const EDIT_APPROVAL = 'EDIT_APPROVAL'
export const CREATE_CAPITAL_REDUCTION = 'CREATE_CAPITAL_REDUCTION'
export const EDIT_CAPITAL_REDUCTION = 'EDIT_CAPITAL_REDUCTION'
export const GET_APPROVAL = 'GET_APPROVAL'
export const SET_APPROVAL = 'SET_APPROVAL'
export const CREATE_CONSTITUTION = 'CREATE_CONSTITUTION'
export const SET_COMPANY_TRANSACTIONS = 'SET_COMPANY_TRANSACTIONS'
export const SET_TRANSACTIONS_PAGE = 'SET_TRANSACTIONS_PAGE'
export const SET_CURRENT_CONTACT_GROUP = 'SET_CURRENT_CONTACT_GROUP'
export const SET_FILTERED_CONTACTS = 'SET_FILTERED_CONTACTS'
export const SET_CONTEXT_COMPANY = 'SET_CONTEXT_COMPANY'
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP'
export const SET_CURRENT_STOCK_GROUP = 'SET_CURRENT_STOCK_GROUP'
export const SET_COMPANY_STOCKS_GROUPS = 'SET_COMPANY_STOCKS_GROUPS'
export const GET_EVENTS = 'GET_EVENTS'
export const SEARCH_EVENTS = 'SEARCH_EVENTS'
export const SET_SEARCHED_EVENTS = 'SET_SEARCHED_EVENTS'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING'
export const SET_EVENTS_SEARCHING = 'SET_EVENTS_SEARCHING'
export const SET_DOCUMENTS_LOADING = 'SET_DOCUMENTS_LOADING'
export const SET_DOCUMENTS_GETTING = 'SET_DOCUMENTS_GETTING'
export const SET_EXERCISE_CALENDARS = 'SET_EXERCISE_CALENDARS'
export const SET_CONTACT_EXERCISE_CALENDARS = 'SET_CONTACT_EXERCISE_CALENDARS'
export const UPDATE_CONTACT_EXERCISE_CALENDARS =
  'UPDATE_CONTACT_EXERCISE_CALENDARS'
export const SET_CONTACT_CAPTABLE = 'SET_CONTACT_CAPTABLE'
export const SET_COMMITTEES_PAGINATION_PAGE = 'SET_COMMITTEES_PAGINATION_PAGE'
export const SET_DELEGATED_TRANSACTION_PAGINATION_PAGE =
  'SET_DELEGATED_TRANSACTION_PAGINATION_PAGE'
export const SET_DELEGATION_PAGINATION_PAGE = 'SET_DELEGATION_PAGINATION_PAGE'
export const UPDATE_CONTACT_PROFILE_IMAGE = 'UPDATE_CONTACT_PROFILE_IMAGE'
export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO'
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO'
export const INIT_COMPANIES_LOGOS = 'INIT_COMPANIES_LOGOS'
export const LOAD_COMPANY_LOGO = 'LOAD_COMPANY_LOGO'
export const UPDATE_USER_PROFILE_IMAGE = 'UPDATE_USER_PROFILE_IMAGE'
export const LOAD_USER_PROFILE_IMAGE = 'LOAD_USER_PROFILE_IMAGE'
export const SET_USER_PROFILE_IMAGE = 'SET_USER_PROFILE_IMAGE'
export const INIT_USERS_PROFILE_IMAGES = 'INIT_USERS_PROFILE_IMAGES'

export const CREATE_COMPANY_GROUP = 'CREATE_COMPANY_GROUP'
export const EDIT_COMPANY_GROUP = 'EDIT_COMPANY_GROUP'

export const LOADING_COMPANY_DATA = 'LOADING_COMPANY_DATA'
export const LOADING_USER_DATA = 'LOADING_USER_DATA'
export const CREATE_COMPANY_STOCK_GROUP = 'CREATE_COMPANY_STOCK_GROUP'
export const EDIT_COMPANY_STOCK_GROUP = 'EDIT_COMPANY_STOCK_GROUP'
export const IS_DOCUMENT_SEARCHING = 'IS_DOCUMENT_SEARCHING'
export const SET_TEXT_SEARCHED = 'SET_TEXT_SEARCHED'
export const DELETE_EVENT = 'DELETE_EVENT'

export const LOAD_CONTACT_PICTURE = 'LOAD_CONTACT_PICTURE'
export const LOAD_CONTACT_COMPUTED_DATA = 'LOAD_CONTACT_COMPUTED_DATA'

export const COMPUTE_NOMINAL_VALUE = 'COMPUTE_NOMINAL_VALUE'

//Dasbord
export const GET_BODAC = 'GET_BODAC'
export const GET_BODAC_PDF_URL = 'GET_BODAC_PDF_URL'
export const SET_BODAC = 'SET_BODAC'

//export
export const EXPORT_COMPANY_TRANSACTIONS = 'EXPORT_COMPANY_TRANSACTIONS'
export const EXPORT_COMPANY_TRANSACTIONS_PDF = 'EXPORT_COMPANY_TRANSACTIONS_PDF'
export const EXPORT_COMPANY_CAPTABLE = 'EXPORT_COMPANY_CAPTABLE'
export const EXPORT_COMPANY_CAPTABLE_PDF = 'EXPORT_COMPANY_CAPTABLE_PDF'
export const EXPORT_ASSOCIATE_RECORD = 'EXPORT_ASSOCIATE_RECORD'
export const EXPORT_ALL_ASSOCIATES_RECORD = 'EXPORT_ALL_ASSOCIATES_RECORD'
export const IS_EXPORTING = 'IS_EXPORTING'
export const EXPORT_EXERCISE_CALENDARS = 'EXPORT_EXERCISE_CALENDARS'
export const EXPORT_EXERCISE_CALENDARS_BY_OPTION =
  'EXPORT_EXERCISE_CALENDARS_BY_OPTION'
export const EXPORT_DELEGATIONS = 'EXPORT_DELEGATIONS'
export const EXPORT_DELEGATIONS_BY_HOLDER = 'EXPORT_DELEGATIONS_BY_HOLDER'
export const EXPORT_APPOINTMENTS = 'EXPORT_APPOINTMENTS'
export const EXPORT_TRANSACTIONS = 'EXPORT_TRANSACTIONS'
export const EXPORT_CAPTABLE = 'EXPORT_CAPTABLE'

export const SET_CONTACT_PICTURE = 'SET_CONTACT_PICTURE'
export const INIT_CONTACTS_PICTURE = 'INIT_CONTACTS_PICTURE'

//
export const GET_COMPANY_STOCKS_DATA = 'GET_COMPANY_STOCKS_DATA'
export const GET_COMPANY_CAPITAL_BY_GROUP = 'GET_COMPANY_CAPITAL_BY_GROUP'
export const GET_COMPANY_CAPITAL_BY_STOCK_CATEGORY =
  'GET_COMPANY_CAPITAL_BY_STOCK_CATEGORY'
export const GET_COMPANY_CAPITAL_BY_ESOP_CATEGORY =
  'GET_COMPANY_CAPITAL_BY_ESOP_CATEGORY'
export const GET_ESOP_DATA = 'GET_ESOP_DATA'
export const SET_ESOP_DATA = 'SET_ESOP_DATA'
export const GET_FUNDING_DATA = 'GET_FUNDING_DATA'
export const SET_FUNDING_DATA = 'SET_FUNDING_DATA'
export const GET_PROFIT_SHARING_EVOLUTION = 'GET_PROFIT_SHARING_EVOLUTION'
export const SET_PROFIT_SHARING_EVOLUTION = 'SET_PROFIT_SHARING_EVOLUTION'
export const SET_COMPANY_STOCKS_DATA = 'SET_COMPANY_STOCKS_DATA'
export const SET_COMPANY_CAPITAL_BY_GROUP = 'SET_COMPANY_CAPITAL_BY_GROUP'
export const SET_COMPANY_CAPITAL_BY_STOCK_CATEGORY =
  'SET_COMPANY_CAPITAL_BY_STOCK_CATEGORY'
export const SET_COMPANY_CAPITAL_BY_ESOP_CATEGORY =
  'SET_COMPANY_CAPITAL_BY_ESOP_CATEGORY'

export const INIT_CURRENT_CONTACT_PART_DOCUMENTS =
  'INIT_CURRENT_CONTACT_PART_DOCUMENTS'

export const INIT_PART_DOCUMENTS = 'INIT_PART_DOCUMENTS'
export const SET_CURRENT_PART_CONTACT_ID = 'SET_CURRENT_PART_CONTACT_ID'

export const GET_EXERCISE_CALENDARS_TOTAL = 'GET_EXERCISE_CALENDARS_TOTAL'
export const SET_EXERCISE_CALENDARS_TOTAL = 'SET_EXERCISE_CALENDARS_TOTAL'
export const SET_EXERCISE_CALENDARS_PAGE = 'SET_EXERCISE_CALENDARS_PAGE'

export const GET_ALERTS = 'GET_ALERTS'
export const CREATE_ALERTS = 'CREATE_ALERTS'
export const MARK_AS_SEEN = 'MARK_AS_SEEN'
export const GET_ALERT = 'GET_ALERT'
export const SET_ALERTS = 'SET_ALERTS'
export const SET_ALERT = 'SET_ALERT'
export const DELETE_ALERT = 'DELETE_ALERT'
export const EDIT_ALERT = 'EDIT_ALERT'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const SET_NOTIFICATIONS_PAGE = 'SET_NOTIFICATIONS_PAGE'

export const CREATE_COMPANY_ORGANIGRAM = 'CREATE_COMPANY_ORGANIGRAM'
export const GET_COMPANY_ORGANIGRAM = 'GET_COMPANY_ORGANIGRAM'
export const SET_COMPANY_ORGANIGRAM = 'SET_COMPANY_ORGANIGRAM'
export const GET_COMPANY_ORGANIGRAMS = 'GET_COMPANY_ORGANIGRAMS'
export const GET_FREE_SHARES_VESTINGS = 'GET_FREE_SHARES_VESTINGS'
export const GET_FREE_SHARES_VESTING = 'GET_FREE_SHARES_VESTING'
export const SET_FREE_SHARES_VESTINGS = 'SET_FREE_SHARES_VESTINGS'
export const SET_FREE_SHARES_VESTING = 'SET_FREE_SHARES_VESTING'
export const CREATE_FREE_SHARES_VESTING = 'CREATE_FREE_SHARES_VESTING'
export const EDIT_FREE_SHARES_VESTING = 'EDIT_FREE_SHARES_VESTING'
export const DELETE_FREE_SHARES_VESTING = 'DELETE_FREE_SHARES_VESTING'
export const GET_CONTACT_FINAL_ACQUISITION_CALENDARS =
  'GET_CONTACT_FINAL_ACQUISITION_CALENDARS'

export const CREATE_FREE_SHARE_FINAL_ACQUISITION =
  'CREATE_FREE_SHARE_FINAL_ACQUISITION'
export const CREATE_FREE_SHARE_SUNSET = 'CREATE_FREE_SHARE_SUNSET'

export const CREATE_FREE_SHARES_ISSUANCE = 'CREATE_FREE_SHARES_ISSUANCE'
export const EDIT_FREE_SHARES_ISSUANCE = 'EDIT_FREE_SHARES_ISSUANCE'

export const GET_FREE_SHARES_ACQUISITION_CALENDARS =
  'GET_FREE_SHARES_ACQUISITION_CALENDARS'
export const SET_FREE_SHARES_ACQUISITION_CALENDARS_PAGE =
  'SET_FREE_SHARES_ACQUISITION_CALENDARS_PAGE'
export const SET_FREE_SHARES_ACQUISITION_CALENDARS =
  'SET_FREE_SHARES_ACQUISITION_CALENDARS'
export const GET_FREE_SHARES_ACQUISITION_CALENDAR =
  'GET_FREE_SHARES_ACQUISITION_CALENDAR'
export const SET_FREE_SHARES_ACQUISITION_CALENDAR =
  'SET_FREE_SHARES_ACQUISITION_CALENDAR'

export interface Pagination {
  currentPage: number
  total: number
  lastPage: number
  limit: number
}

export interface DocumentData {
  data: Document[]
  metadata: {
    currentPage: number
    count: number
    lastPage: number
  }
}

export interface PaginatedData {
  data: any[]
  metadata: {
    currentPage: number
    count: number
    lastPage: number
  }
}

export interface DocumentResult {
  text: string
  documentId: string
  companyId?: string
  documentName: string
  highlights: string[]
}
interface EntityImageUrl {
  [key: string]: string | null
}
export interface EventPartDocuments {
  [key: string]: Document[]
}

export interface OrganizationState {
  isLoadingVesting: boolean
  contactsPicture: EntityImageUrl
  fundingData: FundingData | null
  profitSharingEvolution: CompanyProfitSharingItem[]
  esopData: EsopData | null
  companyCapitalByGroup: CompanyCapitalByGroup[]
  companyCapitalByStockCategory: CompanyCapitalByStockCategory[]
  companyCapitalByEsopCategory: CompanyCapitalByStockCategory[]
  freeSharesAcquisitionCalendars: FreeSharesAcquisitionCalendar[]
  freeSharesAcquisitionCalendarsPagination: Pagination
  companiesLogo: EntityImageUrl
  usersProfilesImages: EntityImageUrl
  eventPartLinkedDocuments: EventPartDocuments
  currentPartContactId: string | null
  companies: CompanyModel[]
  accountUsers: User[]
  fundRaisingRounds: FundRaisingRound[]
  currentFundRaisingRound?: FundRaisingRound | null
  profitSharingPlans: ProfitSharingPlan[]
  currentProfitSharingPlan?: ProfitSharingPlan | null
  operations: Operation[]
  currentOperation?: Operation | null
  userAccountsPagination: Pagination
  companyGlobalData: CompanyGlobalStockData | null
  downloading: boolean
  companiesPagination: Pagination
  loaderCompanies: CompanyModel[]
  currency: string
  documentSearchResult: DocumentResult[]
  company?: CompanyModel
  contextCompany?: CompanyModel
  documents: Document[]
  allDocuments: Document[]
  ceilings: CommonCeiling[]
  functions: UpFunction[]
  delegations: Delegation[]
  searchedText: string | null
  nominations: Appointment[]
  delegatedTransactions: Transaction[]
  capitalReductions: CapitalReduction[]
  transactions: Transaction[]
  transactionsPagination: Pagination
  committeesPagination: Pagination
  delegTransactionsPagination: Pagination
  delegationsPagination: Pagination
  nominationsPagination: Pagination
  eventsPagination: Pagination
  exerciseCalendarPagination: Pagination
  exerciseCalendarSynthesisPagination: Pagination
  loadingEvents: boolean
  loadingDocuments: boolean //Used in GET documents
  gettingDocuments: boolean //used in load documents
  loadingUserData: boolean
  loadingCompanyData: boolean
  searching: boolean
  transfers: Transfer[]
  conversions: Conversion[]
  vestingSuspension: VestingSuspension[]
  vestingAcceleration: VestingAcceleration[]
  vestingDelayProrogation: VestingDelayProrogation[]
  financialEndings: FinancialEnding[]
  approvals: AccountApproval[]
  primaryStockEmission: StockIssuance[]
  primaryOptionsEmission: OptionIssuance[]
  primaryCompositionEmission: any[]
  stockAliases: StockAlias[]
  optionStockAliases: OptionStockAlias[]
  compositionStockAliases: CompositionStockAlias[]
  currentStock: StockAlias | OptionStockAlias | CompositionStockAlias | null
  exerciseCalendars: ExerciseCalendar[]
  exerciseCalendarsTotal: VestingSynthesis | null
  vestingSyntheses: VestingSynthesis[]
  contactExtraData: ContactAvailableData
  currentContact:
    | IndividualContact
    | ProfessionalContact
    | InvestorContact
    | null
  currentOrganigram: any | null
  contactDetails:
    | IndividualContact
    | ProfessionalContact
    | InvestorContact
    | null
  documentList: Document[]
  documentsPagination: Pagination
  auditPagination: Pagination
  accountsPagination: Pagination
  currentDocument: Document | null
  companyStocks: CompanyStock[]
  companyContacts: CompanyContact[]
  committees: CompanyCommittee[]
  groups: CompanyGroup[]
  group: CompanyGroup | null
  stockGroups: StockGroup[]
  stockGroup: StockGroup | null
  currentCommittee: CompanyCommittee | null
  companyVestings?: CompanyVesting[]
  companyCommittees?: CompanyCommittee[]
  vestings: CompanyVesting[]
  freeSharesVestings: CompanyFreeSharesVesting[]
  contacts: (IndividualContact | ProfessionalContact | InvestorContact)[]
  contactsResult: (IndividualContact | ProfessionalContact | InvestorContact)[]
  currentVesting: CompanyVesting | null
  currentCompanyUuid: string | null
  isLoading: boolean
  documentAudits: DocumentAudit[]
  eventAudits: EventAudit[]
  contactAudits: ContactAudit[]
  attachedFiles: Document[]
  accounts: UserModel[]
  nominalValue: number
  nominalValues: NominalValue[]
  events: Event[]
  event: Event | null
  allEvents: Event[]
  searchingEvents: boolean
  captable: Captable | null
  captablePagination: Pagination
  corporateDocuments: Document[]
  corporateEvents: Event[]
  isLoadingStock: boolean
  searchedEvents: Event[]
  searchedEventsPagination: Pagination
  forms: {
    modalInit: boolean
    initCurrentForm: boolean
    stockType: STOCKTYPE
  }
  bodac: any[]
  alerts: Alert[]
  notifications: Notification[]
  alertsPagination: Pagination
  notificationsPagination: Pagination
}

export interface OrganizationMutations {
  [SET_COMPANIES](state: OrganizationState, list: CompanyModel[]): void
  [SET_COMPANY](state: OrganizationState, org: CompanyModel): void
  [SET_LOADING](state: OrganizationState, loading: boolean): void
}

export interface OrganizationGetters {
  company(state: OrganizationState): CompanyModel | undefined
  companies(state: OrganizationState): CompanyModel[]
  isLoading(state: OrganizationState): boolean
  documents(state: OrganizationState): Document[]
}

export interface AlertMutations {
  [SET_ALERTS](state: OrganizationState, list: any[]): void
}

export interface FreeSharesMutations {
  [SET_FREE_SHARES_VESTINGS](state: OrganizationState, list: any[]): void
}
