
  import { useDropzone } from 'vue3-dropzone'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Button } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { useStore } from '@/store'
  import { ref, defineComponent, computed, watch, defineExpose } from 'vue'
  import { humanFileSize, truncText } from '@/utils'
  import { FileUploaded } from '@/types'
  import { useRoute, useRouter } from 'vue-router'
  import { ElMessageBox } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  import { TYPE } from 'vue-toastification'

  export default defineComponent({
    name: 'FileLoader',
    components: { Btn: Button, EmptyState, Multiselect },
    setup() {
      const isDropfileOpen = ref(false)
      const alreadyPrompt = ref(false)
      const canClose = ref(false)
      const defaultDocumentType = ref<string>('')
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      let loadedFiles = ref<FileUploaded[]>([])
      const totalProgress = computed(() =>
        loadedFiles.value.reduce(
          (acc, l) => (acc += l.progress / loadedFiles.value.length),
          0
        )
      )

      watch(totalProgress, (val, old) => {
        if (val === 100 && val !== old) {
          store.dispatch('toolbox/displayToast', {
            type: TYPE.SUCCESS,
            context: t('fileLoader.title'),
            message: t('fileLoader.successMessage')
          })
          store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
            companyId: route.params.id,
            filter: {}
          })
        }
      })

      const categories = computed(
        () => store.getters['toolbox/documentCategories']
      )

      const allCategoriesDefined = computed(
        () =>
          loadedFiles.value.length > 0 &&
          !loadedFiles.value.map((x) => x.category).includes('')
      )

      const truncateText = (value: string, max: number) => {
        return truncText(value, max)
      }

      const fileCategoryChanged = (value: string, i: number) => {
        loadedFiles.value[i].category = value
      }

      const mainCategoryChanged = (value: string) => {
        if (value) {
          defaultDocumentType.value = value
          loadedFiles.value = loadedFiles.value.map((e) => ({
            ...e,
            category: value
          }))
        }
      }

      const cancelUpload = () => {
        alreadyPrompt.value = true
        ElMessageBox.confirm(t('fileLoader.cancelPrompt'), '', {
          confirmButtonText: t('confirm.yes'),
          cancelButtonText: t('confirm.no')
        })
          .then(() => {
            defaultDocumentType.value = ''
            loadedFiles.value = []
            isDropfileOpen.value = false
            canClose.value = false
            alreadyPrompt.value = false
          })
          .catch(() => {
            canClose.value = true
            isDropfileOpen.value = true
          })
          .finally(() => {
            setTimeout(() => {
              canClose.value = false
              alreadyPrompt.value = false
            }, 1000)
          })
      }

      const onFileRemove = (index: number) => {
        loadedFiles.value.splice(index, 1)
      }

      const toggleDropFile = () =>
        (isDropfileOpen.value = !isDropfileOpen.value)

      const closeDropFile = () => {
        loadedFiles.value = []
        if (totalProgress.value === 100) {
          defaultDocumentType.value = ''
          canClose.value = false
          alreadyPrompt.value = false
          isDropfileOpen.value = false
          return
        }
      }

      const clearAllFiles = () => {
        loadedFiles.value = []
        defaultDocumentType.value = ''
      }

      const navigateToFileList = () => {
        if (company.value) {
          closeDropFile()
          router.push({
            name: 'companyUpload',
            params: { id: company.value.id }
          })
        } else router.push({ name: 'welcome' })
      }

      const onDrop = (acceptFiles: any) => {
        acceptFiles.forEach((file: File) => {
          loadedFiles.value.push({
            file,
            progress: 0,
            success: false,
            error: false,
            category: defaultDocumentType.value
          })
        })
        canClose.value = false
      }

      const uploadFiles = () => {
        loadedFiles.value.forEach((entry: FileUploaded) => {
          const form = new FormData()

          form.append('docSubCategoryId', entry.category || '')
          form.append('file', entry.file)
          form.append('fileName', entry.file.name)

          store.dispatch(`organizations/UPLOAD_COMPANY_FILES`, {
            form,
            companyId: route.params.id,
            onError: (err: string) => {
              console.log(err)
              entry.error = true
            },
            onSuccess: (res: string) => {
              console.log(res)
              entry.success = true
            },
            progress: (pEvent: ProgressEvent) => {
              const percentCompleted = Math.round(
                (pEvent.loaded * 100) / pEvent.total
              )
              entry.progress = percentCompleted
            }
          })
        })
      }

      const { getRootProps, getInputProps, ...rest } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        multiple: true
      })

      defineExpose({ uploadFiles, closeDropFile })

      return {
        ...rest,
        loadedFiles,
        isDropfileOpen,
        categories,
        defaultDocumentType,
        allCategoriesDefined,
        totalProgress,
        humanFileSize,
        mainCategoryChanged,
        onFileRemove,
        fileCategoryChanged,
        truncateText,
        navigateToFileList,
        uploadFiles,
        getRootProps,
        getInputProps,
        closeDropFile,
        toggleDropFile,
        clearAllFiles
      }
    }
  })
