
  import { defineComponent, toRefs, computed } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { isNumber } from 'lodash'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Company, Conversion, NominalValue } from '@/services/api/models'

  export default defineComponent({
    name: 'ConversionCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => Conversion | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { event } = toRefs(props)
      const store = useStore()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed(() => props.event?.conversionParts || [])
      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.conversionDate).unix() >
            moment.utc(x.date).unix()
        )
        return nv?.nominalValue
      })
      const totalQuantity = computed(() => {
        return (props.event?.conversionParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseInt(ePerAction.toString()) * row.quantity
      }

      const emitPerAction = (row: any) => {
        if (!nominalValue.value || !isNumber(row.unitPrice)) {
          return '-'
        }

        return row.unitPrice - nominalValue.value
      }

      const globalTotal = computed(() => {
        return (props.event?.conversionParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })

      return {
        parts,
        totalQuantity,
        currency,
        contactsId,
        emitPerAction,
        moment,
        formatNumber,
        emitTotal,
        augNominal
      }
    }
  })
