
  import { defineComponent } from 'vue'
  import { formatNumericalValue } from '@/utils/global'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    name: 'DataBoxes',
    components: { Loader },
    props: {
      data: {
        type: Object,
        required: true
      },
      elements: {
        type: Array,
        default: () => []
      },
      type: {
        type: String,
        default: 'capital'
      }
    },
    setup() {
      return {
        formatNumericalValue
      }
    }
  })
