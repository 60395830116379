
  import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue'
  import DataBoxes from '@/components/CompanyOverview/DataBoxes.vue'
  import UplChart from '@/components/Graphs/UplChart.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { FundingData, FundRaisingRound } from '@/services/api/models'
  import moment from 'moment'
  import { Captable } from '@/types'

  export default defineComponent({
    name: 'FinancialsTab',
    components: { DataBoxes, UplChart },

    setup() {
      const store = useStore()
      const route = useRoute()

      const loadingData = ref(false)
      const loadingFunding = ref(false)
      const captable = computed<Captable>(
        () => store.getters['organizations/captable']
      )
      const data = computed(() => ({
        postMoneyValue: {
          value:
            (fundingData.value?.lastStockPrice || 0) *
            (captable.value?.totalFD || 0),
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingFunding.value || loadingData.value
        },
        lastPricePerStock: {
          value: fundingData.value?.lastStockPrice || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingFunding.value
        },
        totalAmountFunding: {
          value: fundingData.value?.raisedAmount || '-',
          type: fundingData.value?.raisedAmount ? 'currency' : 'string',
          currency: 'EUR',
          isLoading: loadingFunding.value
        },
        fundingRounds: {
          value: fundingData.value?.financingRoundAmount || '-',
          type: fundingData.value?.financingRoundAmount ? 'currency' : 'string',
          currency: 'EUR',
          isLoading: loadingFunding.value
        }
      }))

      const funds = computed<FundRaisingRound[]>(
        () => store.getters['organizations/fundRaisingRounds']
      )

      const fundingData = computed<FundingData | null>(
        () => store.getters['organizations/fundingData']
      )

      const formattedFunds = computed(() =>
        [...funds.value].sort(
          (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
        )
      )

      const labels = computed(() => formattedFunds.value.map((x) => x.name))
      const dataset = computed(() => [
        {
          data: formattedFunds.value.map((x) => x.totalAmount),
          backgroundColor: [...formattedFunds.value.map((x) => x.colorCode)]
        }
      ])

      const dataBoxes = [
        {
          icon: 'bi-cash-stack',
          key: 'postMoneyValue'
        },
        {
          icon: 'bi-cash-stack',
          key: 'lastPricePerStock'
        },
        {
          icon: 'bi-cash-stack',
          key: 'totalAmountFunding'
        },
        {
          icon: 'bi-cash-stack',
          key: 'fundingRounds'
        }
      ]

      const getFunds = () => {
        store.dispatch('organizations/GET_FUND_RAISING_ROUNDS', {
          companyId: route.params.id,
          filter: {}
        })
      }

      const getFundingData = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_FUNDING_DATA', {
          companyId,
          onSuccess: () => {
            loadingFunding.value = false
          }
        })
      }

      const getCaptable = async (companyId = route.params.id) => {
        const data: any = {
          includeDelegationsAvailableBalance: true,
          date: moment().toISOString()
        }

        await store.dispatch('organizations/GET_CAPTABLE', {
          companyId,
          filter: data,
          onSuccess: () => {
            loadingData.value = false
          }
        })
      }
      onBeforeMount(() => {
        loadingData.value = true
        loadingFunding.value = true

        getCaptable()
        getFunds()
        getFundingData()
      })

      return {
        dataBoxes,
        data,
        loadingData,
        labels,
        dataset
      }
    }
  })
