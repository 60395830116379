<template>
  <div class="w-full overflow-y-scroll text-search">
    <div v-if="searching" class="treeview__hint-loading text-xs relative">
      <Loader />
    </div>

    <div v-else class="treeview__hint-results flex flex-col bg-white z-0">
      <div v-for="(searchResult, index) in searchResults" :key="index" class="">
        <h5 class="text-sm text-left">{{ searchResult.documentName }}</h5>
        <div
          v-for="(text, i) in searchResult.highlights"
          :key="i"
          class="
            my-2
            treeview__hint-resultsItem treeview__hint-resultsItemInner
            flex
            justify-start
            p-2
            border
            rounded
            border-secondary6
          "
        >
          <p class="treeview__hint-file flex items-center cursor-pointer">
            <i class="bi-file-earmark-text mr-2 md text-xl" />
          </p>

          <p
            class="ml-1 cursor-pointer text-left"
            @click="
              displayDocument(
                searchResult.documentId,
                getCorrectText(text),
                searchResult?.companyId
              )
            "
            v-html="`...${text}...`"
          ></p>
        </div>
      </div>
    </div>
    <EmptyState
      v-if="!searchResults.length && !searching"
      class="block mx-auto py-20"
      :subtitle="$t('global.noData')"
    />
  </div>
</template>

<script lang="ts">
  import { CompanyModel } from '@/services/api/models'
  import { computed, defineComponent, onMounted, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '../Loader/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'

  export default defineComponent({
    components: { EmptyState, Loader },
    props: {
      search: { type: String, default: '' }
    },
    emits: ['dataCount', 'close', 'loading'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const searching = computed(
        () => store.getters['organizations/searchingText']
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )

      const getCorrectText = (text: string) => {
        var result = text
          .match(/<mark>(.*?)<\/mark>/g)
          ?.map(function (val: string) {
            return val.replace(/<\/?mark>/g, '')
          })
        return result && result?.length > 0 ? result[0] : props.search
      }
      const displayDocument = async (
        documentId: string,
        text: string,
        companyId: string | undefined
      ) => {
        store.commit('organizations/SET_TEXT_SEARCHED', text)

        store.commit('ui/DISPLAY_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: currentCompany.value.id,
          documentId
        })

        emit('close')

        router.push({
          name: 'companyDocuments',
          params: { id: currentCompany.value.id, documentId }
        })
      }

      const getDocuments = (search: string | null) => {
        emit('loading', 'documents', true)

        store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
          companyId: route.params.id,
          query: search
        })
      }

      watch(
        () => props.search,
        (val) => {
          getDocuments(val)
        }
      )

      watch(
        () => searchResults.value,
        (val) => {
          emit('dataCount', 'documents', val?.length || 0)
          emit('loading', 'documents', false)
        }
      )

      onMounted(() => {
        emit('dataCount', 'documents', searchResults.value?.length || 0)
      })

      return {
        searchResults,
        searching,
        displayDocument,
        getCorrectText
      }
    }
  })
</script>
<style lang="scss">
  .text-search {
    min-height: 100px;
    .treeview__hint-loading {
      @apply flex justify-center items-center;
      min-height: 100px;
    }
  }
</style>
