
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { compositionStockSchema } from '@/utils/schema'
  import {
    CompositeStockTypeFormData,
    STOCKTYPE,
    STOCKTYPESALIAS
  } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    CompositionStockAlias,
    CompositionUnderlayingOption,
    OptionStockAlias,
    StockAlias,
    StockCategory
  } from '@/services/api/models'
  import { groupItemBy } from '@/utils/global'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import {
    STOCK_PRIMARY,
    STOCK_SECONDARY
  } from '@/store/modules/Organization/models'

  export default defineComponent({
    components: { Form, Input, Field, Button, Multiselect, FormActions },
    props: {
      values: {
        type: Object as () => CompositionStockAlias,
        required: false,
        default: () => ({})
      }
    },
    emits: ['saveChanges'],
    setup(props, { emit }) {
      const store = useStore()
      const newStock = ref<CompositeStockTypeFormData>({
        underlayingOptions:
          props.values?.underlayingOptions?.map(
            (x: CompositionUnderlayingOption) => ({
              underlayingOptionId: x.underlayingOption?.id,
              parity: parseFloat(x.parity.toString())
            })
          ) || [],
        alias: props.values?.alias || '',
        mainStockAliasId: props.values?.mainStockAlias?.id || '',
        inValuation: props.values?.inValuation || false,
        inNFD: props.values?.inNFD || false,
        inFD: props.values?.inNFD || false,
        inESOP: props.values?.inESOP || false
      })

      const resetForm = () => {
        newStock.value = {
          underlayingOptions: [],
          alias: null,
          mainStockAliasId: '',
          inValuation: false,
          inNFD: false,
          inFD: false,
          inESOP: false
        }
      }

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      watch(mustInitForm, (val) => {
        if (val) resetForm()
      })

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const stockAliases = ref<StockAlias[]>([])

      const optionAliases = ref<OptionStockAlias[]>([])
      const stockCategories = computed<StockCategory[]>(
        () => store.getters['toolbox/stockCategories']
      )

      const filteredListedStock = computed<any[]>(() =>
        Array.from(
          groupItemBy(
            stockAliases.value,
            (stock: StockAlias) => stock.stockCategory?.id
          )
        ).map(([key, value]) => {
          const category = stockCategories.value.find((x) => x.id === key)
          return {
            ...category,
            children: [...value]
          }
        })
      )

      const handleSearch = (value: string) => {
        getStockAlias(value)
      }

      const handleForm = (formValues: CompositeStockTypeFormData) => {
        emit(
          'saveChanges',
          formValues,
          STOCKTYPESALIAS.COMPOSITION,
          props.values && props.values.id
        )
      }

      const schema = compositionStockSchema

      const addCompositionStock = () => {
        newStock.value.underlayingOptions?.push({
          underlayingOptionId: '',
          parity: 0
        })
      }

      const deleteComposedStock = (index: number) => {
        newStock.value.underlayingOptions =
          newStock.value.underlayingOptions?.filter((x, i) => index !== i)
      }
      const handleOptionSearch = (search: string) => {
        getOptions(search)
      }

      const getStockAlias = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_STOCK_ALIAS', {
          companyId: currentCompany.value.id,
          filter,
          onSuccess: (data: StockAlias[]) => {
            stockAliases.value = [...data].map((x) => ({
              ...x,
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.SAMPLE,
              class: STOCK_PRIMARY
            }))
          }
        })
      }

      const getOptions = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: currentCompany.value?.id,
          filter,
          onSuccess: (data: OptionStockAlias[]) => {
            optionAliases.value = [...data].map((x) => ({
              ...x,
              alias: x.alias.toUpperCase(),
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.OPTION,
              class: STOCK_SECONDARY
            }))
          }
        })
      }

      watch(
        () => props.values,
        (newProps) => {
          if (newProps) {
            if (
              !stockAliases.value
                .map((x) => x.id)
                .includes(newProps.mainStockAlias.id)
            )
              stockAliases.value.push({
                ...newProps.mainStockAlias,
                name: newProps.mainStockAlias.alias
              })
            newStock.value.alias = newProps.alias
            newStock.value.mainStockAliasId = newProps.mainStockAlias.id
            newStock.value.underlayingOptions = newProps.underlayingOptions.map(
              (x: CompositionUnderlayingOption) => {
                if (
                  !optionAliases.value
                    .map((x) => x.id)
                    .includes(x.underlayingOption.id)
                )
                  optionAliases.value.push({
                    ...x.underlayingOption,
                    name: x.underlayingOption.alias
                  })
                return {
                  underlayingOptionId: x.underlayingOption.id,
                  parity: parseFloat(x.parity.toString())
                }
              }
            )
            newStock.value.inValuation = newProps?.inValuation || false
            newStock.value.inNFD = newProps?.inNFD || false
            newStock.value.inFD = newProps?.inFD || false
            newStock.value.inESOP = newProps?.inESOP || false
          }
        }
      )
      onMounted(() => {
        getStockAlias()
        getOptions()
      })

      return {
        schema,
        newStock,
        stockAliases,
        optionAliases,
        filteredListedStock,
        addCompositionStock,
        deleteComposedStock,
        handleForm,
        handleSearch,
        handleOptionSearch
      }
    }
  })
