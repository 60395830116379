import { company } from '../endpoints'
import axios from '../axios'
import { ContactGroupFormData } from '@/types/forms'

const getGroups = async (companyId: string, page = 1, search = null) => {
  const params: any = {}
  if (search) params.search = search
  const response = await axios.get(company.getGroups(companyId), {
    params
  })
  return response
}

const getGroup = async (companyId: string, groupId = '') => {
  const response = await axios.get(company.singleGroup(companyId, groupId))
  return response
}

const deleteGroup = async (companyId: string, groupId = '') => {
  const response = await axios.delete(company.singleGroup(companyId, groupId))
  return response
}

const createGroup = async (companyId: string, form: ContactGroupFormData) => {
  const { data } = await axios.post(company.getGroups(companyId), form)
  return data
}

const editGroup = async (
  companyId: string,
  id: string,
  form: ContactGroupFormData
) => {
  const { data } = await axios.put(company.singleGroup(companyId, id), form)
  return data
}
export { getGroups, createGroup, editGroup, getGroup, deleteGroup }
