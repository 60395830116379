<template>
  <div class="event__actions">
    <div class="event__actions flex justify-between items-center mt-1">
      <div
        v-if="isPortfolio"
        class="h-16 flex items-center text text-blackp text-xl py-8"
      >
        <div
          class="w-10 h-10 rounded-full bg-cover bg-primary1"
          :style="`background-image: url(${
            companyImage ?? require('@/assets/images/profile/company.png')
          })`"
        ></div>
        <p class="pl-1 pr-2 text-sm">{{ item?.company?.name }}</p>
      </div>

      <div
        v-if="!isPortfolio"
        class="action__toggle flex justify-center items-center"
      >
        <div
          class="switch event__actions-btn border-r"
          role="button"
          @click.stop="navigateToDocumentForm(item.id, item.company.id)"
        >
          <i class="bi-pencil" />
        </div>

        <el-popover class="shadow-md z-30" width="60%" placement="top-end">
          <template #reference>
            <div class="switch event__actions-btn border-r" role="button">
              <i
                :class="[
                  'align-self-center mx-auto bi-exclamation-triangle md',
                  { warning: false }
                ]"
              />
            </div>
          </template>
          <template #default>
            <div class="text-xl">
              <div v-if="document" class="flex justify-between">
                <p class="text-sm my-4">
                  <span
                    class="border border-secondary8 p-1 text-secondary8 rounded"
                    >{{ document?.name }}</span
                  >
                  ({{
                    document.docSubCategory
                      ? document.docSubCategory?.name
                      : '-'
                  }}/{{
                    document.docSubCategory &&
                    document.docSubCategory.docCategory
                      ? document.docSubCategory?.docCategory?.name
                      : '-'
                  }}
                  )
                </p>
              </div>
              <el-tabs
                :model-value="currentTab"
                type="border-card"
                class="shadow-none h-72 overflow-scroll text-sm"
              >
                <el-tab-pane
                  v-if="document"
                  name="audits"
                  :label="`${$t(
                    'extraction.firstlevel.state.labels.audits'
                  )} (${document?.docAudits?.length || 0})`"
                >
                  <el-table
                    v-if="document?.docAudits?.length > 0"
                    :data="document?.docAudits"
                    fit
                    class="w-full"
                  >
                    <el-table-column
                      property="createdAt"
                      label="Date"
                      width="100"
                    >
                      <template #default="scope">
                        <div>
                          <span>{{
                            moment.utc(scope.row.date).format('L')
                          }}</span>
                        </div>
                      </template></el-table-column
                    >
                    <el-table-column width="300" property="name" label="Motive">
                      <template #default="scope">
                        <div v-if="scope.row.motive" class="flex flex-wrap">
                          <span
                            v-for="m in scope.row.motive.split(',')"
                            :key="m"
                            class="
                              border border-warning
                              text-warning
                              rounded
                              px-1
                              m-1
                            "
                          >
                            {{ $t(`audits.labels.${m}`) }}
                          </span>
                        </div>

                        <span v-else>-</span></template
                      >
                    </el-table-column>
                    <el-table-column
                      property="comment"
                      label="Comment"
                    ></el-table-column>
                    <el-table-column label="Resolved ?">
                      <template #default="scope">
                        <span
                          :class="`border border-${
                            scope.row.processed ? 'success' : 'error'
                          } text-${
                            scope.row.processed ? 'success' : 'error'
                          } rounded px-2`"
                          >{{
                            $t(`confirm.${scope.row.processed ? 'yes' : 'no'}`)
                          }}</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane
                  name="newAudit"
                  :label="$t('extraction.firstlevel.state.labels.newAudit')"
                >
                  <Form @submit="handleAuditForm">
                    <h3 class="text-md text-secondary9">
                      {{ $t('extraction.firstlevel.state.labels.newAudit') }}
                    </h3>
                    <UplAuditForm
                      :reasons="Object.values(DocumentAuditAnomalies)"
                      :type="AuditType.DOCUMENT"
                      @change="handleAuditFormChange"
                    />
                    <div class="flex justify-end text-xl mt-2">
                      <Button
                        type="submit"
                        variant="secondary"
                        class="text-xl"
                        :label="$t('global.actions.submit')"
                      ></Button>
                    </div>
                  </Form>
                </el-tab-pane>
              </el-tabs>
            </div>
          </template>
        </el-popover>
        <el-popconfirm
          :confirm-button-text="$t('confirm.yes')"
          :cancel-button-text="$t('confirm.no')"
          :title="$t('global.confirmDelete')"
          @confirm="removeDocument"
        >
          <template #reference>
            <div class="switch event__actions-btn" role="button" @click.stop>
              <i class="bi-trash" />
            </div>
          </template>
        </el-popconfirm>
      </div>
      <div class="event__separator" />
      <!-- TOGGLES FOR SIDES -->
      <div class="action__toggle flex justify-center items-center">
        <div
          class="switch event__actions-btn border-r"
          role="button"
          :class="{ active: currentSide === CardType.EVENT }"
          @click.stop="toggleSide(CardType.EVENT)"
        >
          <i
            :class="`bi-lightning ${
              document.events.length > 0 ? 'text-primary4' : ''
            }`"
          />
          <span
            v-if="document.events.length > 0"
            class="ml-1 p-1 py-0 bg-primary1 text-primary4 rounded-full text-sm"
            >{{ document.events.length }}</span
          >
        </div>
        <div
          class="switch event__actions-btn"
          role="button"
          :class="{ active: currentSide === CardType.DOCUMENT }"
          @click.stop="toggleSide(CardType.DOCUMENT)"
        >
          <i class="bi-file-earmark mr-1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { CompanyModel, Document } from '@/services/api/models'
  import { AuditForm, AuditType, DocumentAuditAnomalies } from '@/types/forms'
  import moment from 'moment'
  import { Form } from 'vee-validate'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { CardType } from '@/types/config'
  import {
    ElTabs,
    ElTabPane,
    ElTableColumn,
    ElTable,
    ElPopover,
    ElPopconfirm
  } from 'element-plus'

  export default defineComponent({
    name: 'DocumentActions',
    components: {
      Form,
      UplAuditForm,
      Button,
      ElTabs,
      ElTabPane,
      ElTableColumn,
      ElPopconfirm,
      ElPopover,
      ElTable
    },
    props: {
      item: {
        type: Object as () => Document,
        required: true
      },
      side: {
        type: Number,
        default: CardType.EVENT
      },
      audit: {
        type: Boolean,
        default: false
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['toggle-side', 'select-card'],
    setup(props, { emit }) {
      const { side, audit, item } = toRefs(props)
      const router = useRouter()
      const route = useRoute()
      const itemId = computed(() => item.value.id)
      const document = computed(() => item.value)
      const currentSide = ref(side.value)
      const toggleAudit = ref(audit.value)
      const documentAudit = ref<AuditForm>()
      const store = useStore()
      const { t } = useI18n()
      const currentTab = computed<string>(() =>
        (item.value?.docAudits || [])?.length === 0 ? 'newAudit' : 'audits'
      )

      const companyImage = ref<null | string>()

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const toggleSide = (what: CardType) => {
        currentSide.value = what
        emit('toggle-side', currentSide.value)
      }

      const selectCard = (e: boolean) => {
        emit('select-card', {
          check: e,
          id: itemId.value
        })
      }

      const removeDocument = (e: any) => {
        store.dispatch('organizations/DELETE_COMPANY_DOCUMENT', {
          companyId: currentCompany.value.id,
          documentId: document.value.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      const handleAuditFormChange = (values: AuditForm) => {
        documentAudit.value = values
      }

      const navigateToDocumentForm = (id: string, companyId?: string) => {
        router.push({
          name: 'companyDocuments',
          params: { id: route.params.id || companyId, documentId: id }
        })
      }

      const handleAuditForm = () => {
        const formData = {
          motive: documentAudit.value?.motives?.join(','),
          processed: false,
          comment: documentAudit.value?.comment
        }
        store.dispatch('organizations/CREATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.DOCUMENT,
          typeDataId: document.value.id,
          formData,
          onSuccess: () => {
            store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
              companyId: currentCompany.value.id,
              filter: {}
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.documents'),
              message: t(`audits.editSuccess`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.documents'),
              message: t(`audits.editError`)
            })
          }
        })
      }

      const loadImage = async () => {
        if (!props.item?.company?.id) return
        if (
          store.getters['portfolio/companiesLogos'][props.item?.company?.id]
        ) {
          companyImage.value =
            store.getters['portfolio/companiesLogos'][props.item?.company?.id]
        } else {
          const response = await store.dispatch(
            `organizations/LOAD_COMPANY_LOGO`,
            {
              companyId: props.item.company?.id
            }
          )
          if (response) {
            companyImage.value = response
            store.commit('portfolio/SET_COMPANIES_LOGOS', {
              companyId: props.item.company?.id,
              value: response
            })
          }
        }
      }

      onMounted(() => {
        if (props.isPortfolio) loadImage()
      })

      return {
        itemId,
        currentSide,
        toggleAudit,
        document,
        DocumentAuditAnomalies,
        currentTab,
        CardType,
        companyImage,
        AuditType,
        toggleSide,
        selectCard,
        removeDocument,
        handleAuditFormChange,
        handleAuditForm,
        navigateToDocumentForm,
        moment
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/eventcard.scss';
  .cb-wrapper {
    height: 28px;
  }
</style>
