<template>
  <div v-if="isLoading" class="flex justify-center items-center">
    <p>{{ $t('global.loadingPage') }}</p>
  </div>
  <div v-else>
    <component :is="Unauthorized" v-if="!canAccess"></component>
    <router-view v-else></router-view>
  </div>
</template>
<script lang="ts">
  import userCanSee from '@/middlewares/canSee'
  import { computed, defineComponent, ref, onMounted } from 'vue'
  import Unauthorized from '@/views/auth/unauthorized.vue'
  import { useRoute } from 'vue-router'
  import { addToStorage } from '@/services/Storage'
  import { storageKeys } from '@/utils'

  export default defineComponent({
    setup() {
      const route = useRoute()
      const isLoading = ref(true)
      const canAccess = computed(() => userCanSee(route))
      onMounted(() => {
        setTimeout(() => {
          addToStorage(storageKeys.currentCompany, route.params.id.toString())
          // addToStorage(storageKeys.currentCompany, route.params.id.toString())
          isLoading.value = false
        }, 1000)
      })
      return { Unauthorized, canAccess, isLoading }
    }
  })
</script>
