<template>
  <el-tooltip
    class="box-item"
    effect="dark"
    :content="$t('tooltips.download')"
    placement="top"
  >
    <Button
      v-if="!hasChildren"
      variant="custom"
      class="
        flex
        justify-center
        items-center
        rounded-sm-i
        w-11
        right
        h-full
        bg-white
        border border-secondary6
        text-secondary9
        rounded
        hover:border-primary4 hover:border-primary4 hover:text-primary4
      "
      @click="onDownload"
    >
      <i class="bi bi-download text-lg"></i>
      <span v-if="label" class="ml-1">{{ label }}</span>
    </Button>

    <ActionList
      v-else
      class="
        w-11
        right
        h-full
        bg-white
        border border-secondary6
        text-secondary9
        rounded
        hover:border-primary3 hover:border-primary3 hover:text-primary3
      "
      icon-class="bi-download text-dwnld"
      :actions="actionsListItems"
      hover-class="hover:border-primary4 hover:border-primary4 "
      @on-click="handleAction"
    />
  </el-tooltip>

  <transition name="modal">
    <Modal v-if="displayModal" size="w-2/5" @close="displayModal = false">
      <template #header>
        <h3 class="text-md font-semibold text-error">
          {{ $t('global.downloadAction.modal_title') }}
        </h3>
      </template>

      <template #body>
        <p
          class="select-none"
          v-html="$t('global.downloadAction.modal_text')"
        ></p>
      </template>
      <template #footer>
        <div class="flex text-xl justify-end">
          <Button
            class="flex text-white bg-primary4 rounded mr-2"
            variant="custom"
            :label="$t('global.downloadAction.modal_ok')"
            @click="displayModal = false"
          />
        </div>
      </template>
    </Modal>
  </transition>
</template>
<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { ElTooltip } from 'element-plus'
  import Modal from '@/components/Modal/Modal.vue'
  import { useStore } from 'vuex'
  import { MAINACTIONS } from '@/types'
  import { useI18n } from 'vue-i18n'
  import { ActionListItem } from '@/types'
  import ActionList from '@/components/ActionList/index.vue'

  export default defineComponent({
    name: 'DownloadButton',
    components: { Button, Modal, ElTooltip, ActionList },
    props: {
      onClick: { type: Function },
      hasChildren: { type: Boolean, default: false },
      label: { type: String, default: null }
    },
    setup(props) {
      const store = useStore()
      const { t } = useI18n()
      const displayModal = ref(false)
      const isExporting = computed(
        () => store.getters['organizations/isExporting']
      )

      const actionsListItems = ref<ActionListItem[]>([
        {
          name: t('global.actions.pdf'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.CONTACTS_RECORD_PDF,
          type: ''
        },
        {
          name: t('global.actions.xls'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.CONTACTS_RECORD_XLS,
          type: ''
        }
      ])

      const handleAction = (key: MAINACTIONS) => {
        onDownload(key)
      }

      const onDownload = (key: MAINACTIONS) => {
        if (props.onClick) props.onClick(key)
        else displayModal.value = true
      }

      return {
        displayModal,
        isExporting,
        actionsListItems,
        handleAction,
        onDownload
      }
    }
  })
</script>
<style lang="scss">
  .rounded-sm-i {
    border-radius: 3px !important;
  }
  .text-dwnld {
    font-size: 0.6em;
  }
  .dwnld-btn {
    border-radius: 3px !important;
    border: 1px solid #d0d2d6 !important;
    @apply hover:bg-white  border border-primary4 flex justify-center items-center transition transition-all;

    span {
      @apply text-secondary9;
    }
    &:hover > i {
      @apply text-primary4;
    }
    &:hover > span {
      @apply text-primary4;
    }
    i {
      @apply text-secondary9;
    }
  }
</style>
