
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import SearchResult from '@/components/DocumentSearchResult/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { DocumentSearchProps } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { Contact } from '@/services/api/models'

  export default defineComponent({
    name: 'DocumentFilter',
    components: {
      Button,
      Input,
      SearchResult,
      Multiselect,
      Field,
      UplDatePicker
    },
    props: { visible: { type: Boolean } },
    emits: ['on-change'],
    setup(props, { emit }) {
      const searchFilter = ref<DocumentSearchProps>({
        documentSubCategoriesId: [],
        contactsId: [],
        startDate: '',
        endDate: ''
      })

      const store = useStore()
      const route = useRoute()
      const isVisible = ref(props.visible)

      const searchQuery = ref('')
      let selectedContacts: Contact[] = []
      const dateRange = ref([])
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const filtered = computed(
        () =>
          searchFilter.value.documentSubCategoriesId?.length !== 0 ||
          searchFilter.value.contactsId?.length !== 0 ||
          searchQuery.value != '' ||
          (dateRange.value && dateRange.value.length !== 0)
      )

      const categories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])

      const filteredContacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const handleSearch = () => {
        emit('on-change', searchFilter.value)
      }

      const resetFilter = () => {
        searchFilter.value.documentSubCategoriesId = []
        searchFilter.value.contactsId = []
        searchFilter.value.startDate = ''
        searchFilter.value.endDate = ''
        selectedContacts = []
        searchQuery.value = ''
        dateRange.value = []
        emit('on-change', searchFilter.value)
        isVisible.value = false
      }

      const uniqByKeepFirst = (a: any, key: any) => {
        let seen = new Set()
        return a.filter((item: any) => {
          let k = key(item)
          return seen.has(k) ? false : seen.add(k)
        })
      }

      const contacts = computed(() =>
        uniqByKeepFirst(
          [
            ...filteredContacts.value,
            ...selectedContacts.filter((c) =>
              searchFilter.value.contactsId?.includes(c.id)
            )
          ],
          (value: any) => value.id
        )
      )
      const handleCategoryChange = ($event: string[]) => {
        searchFilter.value.documentSubCategoriesId = $event
      }
      const handleContactChange = ($event: string[]) => {
        selectedContacts.push(
          ...filteredContacts.value.filter((c) => $event.includes(c.id))
        )
        searchFilter.value.contactsId = $event
      }

      const dateCleared = () => {
        const dateFilter = {
          startDate: '',
          endDate: ''
        }
        searchFilter.value = { ...searchFilter.value, ...dateFilter }
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          }
        })
      }
      const initSearch = () => {
        searchQuery.value = ''
      }
      const hideSearchResults = () => {
        console.log()
      }
      const handleContactSearch = ($event: string) => {
        getContacts($event)
      }

      const filterDocumentList = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: {
            search,
            limit: 300
          }
        })
      }

      watch(
        () => props.visible,
        (value: boolean) => {
          isVisible.value = value
        }
      )

      watch(dateRange, (newDateRange) => {
        const dateFilter = {
          startDate: newDateRange
            ? moment(newDateRange[0]).startOf('day').toISOString()
            : '',
          endDate: newDateRange
            ? moment(newDateRange[1]).endOf('day').toISOString()
            : ''
        }
        searchFilter.value = { ...searchFilter.value, ...dateFilter }
      })

      watch(searchQuery, (val) => {
        filterDocumentList(val)
        store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
          companyId: route.params.id,
          query: val
        })
      })

      watch(
        () => route.params.id,
        async () => {
          searchFilter.value = {
            documentSubCategoriesId: [],
            contactsId: [],
            startDate: '',
            endDate: ''
          }
        }
      )

      onMounted(() => {
        getContacts()
      })
      return {
        searchFilter,
        searchQuery,
        dateRange,
        categories,
        contacts,
        isVisible,
        searchResults,
        dateCleared,
        filtered,
        handleSearch,
        initSearch,
        handleContactChange,
        handleCategoryChange,
        handleContactSearch,
        resetFilter,
        hideSearchResults
      }
    }
  })
