
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import { FundRaisingRound } from '@/services/api/models'
  import { FundRaisingRoundFormData } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_FUND_RAISING_ROUND,
    EDIT_FUND_RAISING_ROUND
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { groupSchema } from '@/utils/schema/group'
  import ColorPicker from '@/components/ColorPicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'UplContactGroup',
    components: {
      Form,
      ElPopconfirm,
      Field,
      Input,
      FormActions,
      Button,
      ColorPicker
    },
    emits: [],
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') &&
            acl.permission('write-fundRaisingRound'))
      )

      const colorCode = ref('#409EFF')
      const newFund = ref<FundRaisingRoundFormData>({
        name: '',
        colorCode: '',
        description: null
      })

      const resetForm = () => {
        newFund.value = {
          name: '',
          colorCode: '',
          description: null
        }
      }

      const modalForm = computed(() => store.getters['organizations/form'])

      const fund = computed<FundRaisingRound | null>(
        () => store.getters['organizations/fundRaisingRound']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = ref(groupSchema)

      const initForm = () => {
        store.commit('organizations/SET_FUND_RAISING_ROUND', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleForm = (values: FundRaisingRoundFormData) => {
        let edition = false
        let action = CREATE_FUND_RAISING_ROUND
        let formData = {
          companyId: currentCompany.value.id,
          data: { ...values },
          fundId: ''
        }
        edition = !!fund.value?.id
        formData['fundId'] = fund.value?.id || ''
        action = fund.value?.id
          ? EDIT_FUND_RAISING_ROUND
          : CREATE_FUND_RAISING_ROUND

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(
                `fundRaisingRoundBook.apiResponse.${
                  edition ? 'edit' : 'create'
                }Error`
              )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('fundRaisingRoundBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('fundRaisingRoundBook.form.title'),
              message: t(
                `fundRaisingRoundBook.apiResponse.${
                  edition ? 'edit' : 'create'
                }Success`
              )
            })
          }
        })
      }

      const deleteGroup = () => {
        store.dispatch(`organizations/REMOVE_FUND_RAISING_ROUND`, {
          companyId: currentCompany.value.id,
          id: fund.value?.id || '',
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('fundRaisingRoundBook.form.title'),
              message: t(`fundRaisingRoundBook.apiResponse.deleteSuccess`)
            })
          },
          onError: (error = null) => {
            const message =
              error || t(`fundRaisingRoundBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('fundRaisingRoundBook.form.title'),
              message
            })
          }
        })
      }
      const handleReset = () => {
        initForm()
      }

      const getContacts = async (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }
      const toggleModal = (state: boolean) =>
        store.dispatch('ui/toggleModal', { display: state })

      watch(fund, (newValue) => {
        if (newValue) {
          newFund.value = {
            colorCode: newValue.colorCode || null,
            name: newValue.name,
            description: newValue.description || null
          }
        }
      })

      watch(modalForm, (newValues) => {
        if (newValues.initCurrentForm) resetForm()
      })

      onMounted(() => {
        getContacts()
      })

      return {
        newFund,
        fund,
        canDo,
        schema,
        colorCode,
        toggleModal,
        handleForm,
        deleteGroup,
        handleReset
      }
    }
  })
