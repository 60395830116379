import {
  GET_ALERTS,
  OrganizationState,
  SET_ALERTS,
  CREATE_ALERTS
} from './models'
import { ActionContext } from 'vuex'
import { RootState } from '../../index'
import { DELETE_NOTIFICATION, MARK_AS_SEEN } from './models'
import {
  createAlert,
  getAlerts,
  getNotification,
  getNotifications,
  notificationSeen
} from '@/services/api'
import {
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  GET_NOTIFICATION,
  SET_NOTIFICATION
} from './models'

const context = 'ALERT_NOTIFICATIONS'

export const alertActions = {
  async [GET_ALERTS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const alerts = await getAlerts(companyId, {})
      if (onSuccess) onSuccess(alerts)
      else commit(SET_ALERTS, alerts)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [GET_NOTIFICATIONS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const data = {
        ...filter,
        offset:
          (state.notificationsPagination.currentPage - 1) *
          state.notificationsPagination.limit,
        limit: state.notificationsPagination.limit
      }

      const alerts = await getNotifications(companyId, data)

      if (onSuccess) onSuccess(alerts)
      else commit(SET_NOTIFICATIONS, alerts)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [GET_NOTIFICATION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { notificationId, companyId, onSuccess, onError }: any
  ) {
    try {
      const alert = await getNotification(companyId, notificationId)

      if (onSuccess) onSuccess(alert)
      else commit(SET_NOTIFICATION, alert)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [DELETE_NOTIFICATION](
    { commit, dispatch }: ActionContext<OrganizationState, RootState>,
    { notificationId, companyId, onSuccess, onError }: any
  ) {
    try {
      await getNotification(companyId, notificationId)
      if (onSuccess) onSuccess(alert)
      else commit(SET_NOTIFICATION, alert)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },
  async [CREATE_ALERTS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId, onSuccess, onError }: any
  ) {
    try {
      await createAlert(companyId, data)
      dispatch(GET_ALERTS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },
  async [MARK_AS_SEEN](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { notificationId, companyId }: any
  ) {
    try {
      await notificationSeen(companyId, notificationId)
      dispatch(GET_NOTIFICATIONS, { companyId })
    } catch (error) {
      console.log(context, error)
    }
  }
}
