<template>
  <div class="w-full h-screen flex px-20">
    <div class="w-2/5 flex justify-start items-center">
      <div class="flex flex-col items-start">
        <h1 class="text-left big-text">
          {{ $t('errors.404') }}
        </h1>

        <span
          class="
            my-3
            w-40
            py-3
            border border-secondary8
            text-secondary9
            cursor-pointer
            rounded-full
          "
          @click="$router.push({ name: 'accountInformations' })"
        >
          <i class="bi bi-arrow-left text-xl mr-1"></i>
          {{ $t('errors.backLabel') }}</span
        >
      </div>
    </div>

    <div
      class="bg-no-repeat min-h-screen w-3/5 bg-center bg-right img-bg-404"
    ></div>
  </div>
</template>

<script lang="ts">
  import { User } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    setup() {
      const store = useStore()
      const user = computed<User>(() => store.getters['auth/profile'])
      const email = computed<string>(() => user.value?.email)
      return { email }
    }
  })
</script>

<style lang="scss">
  .big-text {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .img-bg-404 {
    background-image: url('~@/assets/images/illustrations/404.svg');
  }
</style>
