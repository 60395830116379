
  //TODO: Display with flex? column or row? Both?
  import { Field } from 'vee-validate'
  import { Switch } from '@headlessui/vue'
  interface DataInputBool {
    name: string
    type: string
    label: string
    id: ''
  }

  import { defineComponent, ref, watch, toRefs } from 'vue'
  export default defineComponent({
    name: 'BoolGeneric',
    components: { Field, Switch },
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputBool,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      value: {
        type: Boolean,
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref(value.value ?? false)
      watch(formValue, () => {
        // console.log('values', value)
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      })
      return { formValue }
    }
  })
