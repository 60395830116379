import { STOCKTYPE, TimeUnit } from './config'
import {
  GenderEnum,
  FamilySituationEnum,
  OptionStockAlias,
  OrganigramDilutionEnum
} from '../services/api/models/company'
import {
  Contact,
  StockAlias,
  CompositionStockAlias
} from '../services/api/models/company'
import { ExerciseCalendar } from '../services/api/models/company'
export interface UserInvitationFields {
  email: string
  lastName: string
  firstName: string
  profileAlias: string
  inheritProfileAlias: string
}

export interface InvitationForm {
  id?: string
  contactId?: string
  roles: string
  lastName: string
  firstName: string
  email: string
  useMFA?: boolean
  companyId?: string
  accounts: any[]
}

export interface NewCompanyFields {
  commercialName: string
  denomination: string
  formType: string
  registration: string
  registeredAt: string
  address: string
  additionnalAddressInfo: string
  postalCode: number
  city: string
  country: string
}

export enum PARITYTYPE {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE'
}

export interface NewStockItem {
  type: STOCKTYPE
  alias: string | null
  optionCategoryId: string
  underlayingOption: string
  stockCategoryId: string
  underlayingStockId: string
  parityType: PARITYTYPE
  parity: number
  nfd?: boolean
  fd?: boolean
  esop?: boolean
  valuation: boolean
}

export interface SampleStockTypeFormData {
  stockCategoryId: string | null
  alias: string | null
  inNFD: boolean
  inFD: boolean
  inESOP: boolean
  notInTransactionsRegister?: boolean
  inValuation: boolean
  groupId?: string
  colorCode?: string | null
}

export interface OptionStockTypeFormData {
  id?: string
  optionCategoryId: string
  underlayingStockId: string | null
  underlayingCompositionId: string | null
  alias: string
  parityType: string
  parity: number
  inNFD: boolean
  inFD: boolean
  inESOP: boolean
  inValuation: boolean
  groupId?: string
  notInTransactionsRegister?: boolean
  exercisePrices: number[]
  expirationTimeUnit: TimeUnit
  expirationNumberOfTimeUnits: number
  colorCode?: string | null
}
export interface CompositionItem {
  underlayingOptionId: string | null
  parity: number
  color?: string
}
export interface CompositeStockTypeFormData {
  mainStockAliasId: string
  underlayingOptions: CompositionItem[]
  alias: string | null
  inNFD: boolean
  inFD: boolean
  notInTransactionsRegister?: boolean
  inESOP: boolean
  inValuation: boolean
  simulationOnly?: false
  groupId?: string
  colorCode?: string | null
}

export interface StockAliasFormProps {
  form:
    | CompositeStockTypeFormData
    | SampleStockTypeFormData
    | OptionStockTypeFormData
  stockId?: string
  type: STOCKTYPESALIAS
  companyId: string
}

export interface StockToggleFields {
  inNFD: boolean
  inFD: boolean
  inESOP: boolean
  inValuation: boolean
  notInTransactionsRegister?: boolean
}

export interface CompanyCommitteeFormData {
  denomination: string
  creationDate: string
  minMembers: number
  maxMembers: number
  documentsId: string[]
  isDraft: boolean
}

export interface OrganigramFormData {
  date: string
  companiesId: string[]
  topCompanyId: string
  dilution: OrganigramDilutionEnum
}

export interface ContactGroupFormData {
  name: string
  colorCode: string | null
  description: string | null
  contactsId: string[]
  isEmployeesGroup: boolean
}

export interface ProfitSharingPlanFormData {
  name: string
  colorCode: string | null
  description: string | null
}

export interface FundRaisingRoundFormData {
  name: string
  colorCode: string | null
  description: string | null
}

export interface OperationFormData {
  name: string
  colorCode: string | null
  description: string | null
}

export interface StockGroupFormData {
  name: string
  colorCode: string | null
  description: string | null
  stocksId?: string[]
}
export interface CommonCeilingFormData {
  date: string
  quantity: string
}

export interface DelegationFormData {
  date: string
  endDate: string
  stockAliasId?: string
  optionAliasId?: string
  commonCeilingId?: string
  documentsId?: string[]
}

export interface AppointmentFormData {
  startDate: string
  endDate?: string
  durationInDay?: number
  contactId: string
  accountingExerciseYear?: string
  functionId?: string
  committeeId?: string
}

export interface CommiteeFormData {
  creationDate: string
  denomination: string
  maxMembers: number
  minMembers: number
}
export interface OptionExercisePart {
  id: string
  quantity: number
  issuedQuantity: number
  entryDate: string
  optionAlias: OptionStockAlias
  contact: Contact
  unitPrice: number
  totalIssuancePremium?: number
  nominalIncrease?: number
  issuancePremiumPerAction?: number
  exerciseCalendar: ExerciseCalendar
}

export interface OptionExercisePartForm {
  contactId: string
  quantity: number
  entryDate: string
  optionAliasId: string
  unitPrice: number
  nominalIncrease?: number
  issuancePremiumPerAction: number
}
export interface OptionExerciseFormData {
  isDraft: boolean
  issuanceDate: string
  documentsId?: string[]
  optionExerciseParts: OptionExercisePartForm[]
}
export interface OptionExercise {
  id?: string
  isDraft: boolean
  issuanceDate: string
  documentsId?: string[]
  optionExerciseParts: OptionExercisePart[]
}

export interface ApprovalFormData {
  date: string
  earningsAllocation?: string
  result?: number
  turnover?: number
  financialEndingDate?: string
  documentsId?: string[]
  isDraft?: boolean
}

export interface VestingSuspensionFormData {
  startDate: string
  contactsId?: string[]
  optionAliasId?: string[]
  endDate?: string
  timeUnit?: string
  timeQuantity?: number
}

export interface VestingAccelerationFormData {
  startDate: string
  contactsId?: string[]
  optionAliasId?: string[]
  percent: number
}

export interface VestingProrogationFormData {
  startDate: string
  contactsId?: string[]
  optionAliasId?: string[]
  endDate?: string
  timeUnit?: string
  timeQuantity?: number
}

export interface TransferFormData {
  transferDate: string
  transferParts: TransferParts[]
}

export interface TransferParts {
  id: string
  sellerId: string
  transfereeId: string
  stockAliasId: string
  seller: Contact
  transferee: Contact
  stockAlias?: StockAlias
  compositionAlias?: CompositionStockAlias
  optionAlias?: OptionStockAlias
  quantity: number
  unitPrice: number
  documents?: Document[]
  totalAmount: number
}

export interface OptionIssuanceFormData {
  issuanceDate: string
  entryDate: string
  isDraft: boolean
  delegationId?: string
  optionAliasId: string
  optionIssuanceParts: OptionIssuanceParts[]
}

export interface OptionIssuanceParts {
  contactId: string
  quantity: number
  totalPrice: number
  releasedAmount: number
  vestingId: string
  vestingStartDate: string
  unitPrice?: number
  issuancePremiumPerAction?: number
  totalIssuancePremium?: number
  nominalIncrease?: number
}

export interface StockIssuanceFormData {
  issuanceDate: string
  entryDate: string
  isDraft: boolean
  delegationId?: string
  stockAliasId: string
  stockIssuanceParts: StockIssuanceParts[]
}

export interface StockIssuanceParts {
  contactId: string
  quantity: number
  totalPrice: number
  releasedAmount: number
  unitPrice?: number
  issuancePremiumPerAction?: number
  totalIssuancePremium?: number
  nominalIncrease?: number
}

export interface CompositionIssuanceFormData {
  issuanceDate: string
  entryDate: string
  isDraft: boolean
  delegationId?: string
  compositionAliasId: string
  compositionIssuanceParts: CompositionIssuancePart[]
}

export interface CompositionIssuancePart {
  contactId: string
  quantity: number
  totalPrice: number
  releasedAmount: number
  unitPrice?: number
  issuancePremiumPerAction?: number
  totalIssuancePremium?: number
  nominalIncrease?: number
}

export interface FinancialEndingFormData {
  date: string
}

export interface ConstitutionFormData {
  durationInYears: string
  companyName: string
  nominalValue: number
  stockAliasId: string
  stockIssuanceParts: StockIssuanceParts[]
  companyAddress: string
  firstExerciseClosure: string
  firstClosure: string
  nomination: AppointmentFormData[]
}

export enum MULTISELECTMODE {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  TAGS = 'tags'
}

export enum CONTACTTYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  PROFESSIONAL = 'PROFESSIONAL',
  INVESTOR = 'INVESTOR'
}
export enum CapitalReductionReasonEnum {
  losses = 'LOSSES',
  not_losses = 'NOT_LOSSES'
}

export enum STOCKTYPESALIAS {
  STOCK = 'stock-alias',
  OPTION = 'option-alias',
  COMPOSITION = 'composition-alias'
}
export enum ROUNDINGMETHOD {
  INF = 'INF',
  SUP = 'SUP',
  NEAR = 'NEAR'
}

export enum STARTOFPERIOD {
  DEFAULT_START_DATE = 'DEFAULT_START_DATE',
  GRANT_DATE = 'GRANT_DATE', //
  LAST_PERIOD_END_PLUS_ONE = 'LAST_PERIOD_END_PLUS_ONE',
  EMPLOYMENT_DATE = 'EMPLOYMENT_DATE'
}

export enum ENDOFPERIOD {
  ANNIVERSARY_DAY = 'ANNIVERSARY_DAY',
  ANNIVERSARY_DAY_PLUS_ONE = 'ANNIVERSARY_DAY_PLUS_ONE',
  ANNIVERSARY_DAY_MINUS_ONE = 'ANNIVERSARY_DAY_MINUS_ONE',
  START_OF_MONTH = 'START_OF_MONTH',
  END_OF_MONTH = 'END_OF_MONTH',
  NEXT_MONTH_FIRST_DAY = 'NEXT_MONTH_FIRST_DAY'
}
export enum VESTINGTYPE {
  START_OF_PERIOD = 'START_OF_PERIOD',
  LINEAR = 'LINEAR',
  END_OF_PERIOD = 'END_OF_PERIOD'
}
export enum TIMEUNIT {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY'
}
export enum PERIODBEHAVIOR {
  WAIT = 'WAIT',
  VEST = 'VEST'
}

export enum VESTINGVALUEUNIT {
  PERCENT = 'PERCENT',
  QUANTITY = 'QUANTITY',
  FRACTION = 'FRACTION'
}

export enum OptionSunsetTypeEnum {
  byContact = 'BY_CONTACT',
  byType = 'BY_OPTION'
}

export enum VestingAdjustment {
  bottom = 'BOTTOM_ADJUSTMENT',
  top = 'TOP_ADJUSTMENT',
  uniform = 'UNIFORM_FIT'
}

export interface VestingPlanning {
  value: number
  order: number
  vestingType: VESTINGTYPE
  startOfPeriod: STARTOFPERIOD
  roundingMethod: ROUNDINGMETHOD
  nbTimeUnits: number
  endOfPeriod: ENDOFPERIOD
  timeUnit: TIMEUNIT
  periodBehavior: PERIODBEHAVIOR
}

export enum EndOfContractReasons {
  resignation = 'RESIGNATION',
  dismissal = 'DISMISSAL',
  conventionalRupture = 'CONVENTIONAL_RUPTURE',
  inability = 'INABILITY',
  death = 'DEATH',
  expiration = 'EXPIRATION',
  revocation = 'REVOCATION',
  unknown = 'UNKNOWN'
}
export enum OptionSunsetMode {
  all = 'ALL',
  notVested = 'NOT_VESTED',
  vested = 'VESTED'
}

interface VestingEOCPeriod {
  timeUnit: string
  value: number
}
export interface BehaviourValues {
  notVestedPercent: number
  vestedPercent: number
  notVestedPeriod: VestingEOCPeriod
  vestedPeriod: VestingEOCPeriod
}
export interface PostContractBehavior {
  resignation: BehaviourValues
  dismissal: BehaviourValues
  conventionalRupture: BehaviourValues
  inability: BehaviourValues
  death: BehaviourValues
  revocation: BehaviourValues
  expiration: BehaviourValues
}

export interface VestingForm {
  id?: string
  name: string
  vestingValueUnit: VESTINGVALUEUNIT
  fractionReport: boolean
  hasObjective: boolean
  vestingPeriods: VestingPlanning[]
  endOfContractBehaviors: PostContractBehavior
}
export interface IndividualContactForm {
  id?: null | string
  type: CONTACTTYPE
  contactGroupsId: string[]
  contactStatusId: string | null
  firstName: string
  lastName: string
  birthDate: string | null
  birthPlace: string | null
  email?: string | null
  phoneNumber?: string | null
  website?: string | null
  address: string | null
  postalcode: string | null
  city: string | null
  country: string | null
  gender: GenderEnum | null
  familySituation: FamilySituationEnum
  nationality: string | null
  internalId1?: string | null
  internalId2?: string | null
  mappingId?: string | null
}

export interface InvestorContactForm {
  id?: null | string
  name: string
  contactGroupsId: string[]
  type: CONTACTTYPE
  representativeContactId: string | null
  contactStatusId: string | null
  legalStatusId: string | null
  email?: string | null
  phoneNumber?: string
  website?: string | null
  internalId1?: string | null
  internalId2?: string | null
  mappingId?: string | null
}

export interface ProfessionalContactForm {
  id?: null | string
  name: string
  tradeName: string
  representativeContactId: string | null
  legalStatusId: string
  birthDate: string | null
  siret: string | null
  siren: string | null
  ape: string | null
  tva: string
  email?: string | null
  phoneNumber?: string | null
  website?: string | null
  type: CONTACTTYPE
  contactGroupsId: string[]
  contactStatusId: string | null
  address: string | null
  postalcode: string | null
  city: string | null
  country: string | null
  nationality: string | null
  internalId1?: string | null
  internalId2?: string | null
  mappingId?: string | null
}

export interface AuditForm {
  id?: string
  motives: string[]
  processed: boolean
  comment: string
}

export interface DocumentEditForm {
  name?: string
  isDraft: boolean
  docSubCategoryId: string
  signedAt: string
  label: string
  contactsId?: string[]
  operationsId?: string[]
}

export interface DocumentContactsForm {
  contacts: string[]
}

export enum AuditStatus {
  COMPLETED = 'standarded',
  ANOMALY = 'anomaly'
}

export enum AuditStatusType {
  pending = 'PENDING',
  inProgress = 'IN_PROGRESS',
  done = 'DONE',
  cancelled = 'CANCELLED'
}

export enum AuditGetType {
  DOCUMENT = 'doc-audits',
  EVENT = 'event-audits',
  CONTACT = 'contact-audits'
}

export enum AuditType {
  DOCUMENT = 'documents',
  EVENT = 'events',
  CONTACT = 'contacts'
}

export enum DocumentAuditAnomalies {
  UNREADABLE = 'unreadable',
  MISSINGPAGE = 'missing-page',
  MISSINGINFO = 'missing-info',
  MISSINGSIGNATURE = 'missing-signature',
  OTHER = 'other'
}

export enum EventAuditAnomalies {
  INCONSISTENCY = 'inconsistency',
  MISSING_DOCUMNENT = 'missing_document',
  OTHER = 'other'
}

export enum ContactAuditAnomalies {
  UNREADABLE_CONTACT = 'unreadable',
  MISSINGINFO_CONTACT = 'missing-info',
  OTHER = 'other'
}
