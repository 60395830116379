
  import { defineComponent, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { EndOfContractReasons, TIMEUNIT, VestingForm } from '@/types'
  import { useI18n } from 'vue-i18n'
  import { Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'

  export default defineComponent({
    components: { DataTable, Input, Field, Multiselect },
    props: {
      vesting: {
        type: Object as () => VestingForm,
        required: true
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const { t } = useI18n()
      const endOfContractBehaviors = ref<any>(
        props.vesting.endOfContractBehaviors
      )

      const timeUnits = ref(
        Object.keys(TIMEUNIT).map((x) => ({
          name: t(`timeUnits.${x}`),
          value: x,
          id: x
        }))
      )

      const data = ref(
        Object.keys(EndOfContractReasons).map((x) => ({
          name: t(`endOfContractReasons.${x}`),
          value: x,
          id: x,
          class: `tag-reason tag-reason-${x.toLowerCase()}`
        }))
      )

      const columns = ref([
        {
          label: t(`vestingBook.endOfContracts.reason`),
          field: 'reason',
          width: '20%',
          custom: true
        },
        {
          label: `${t(`vestingBook.endOfContracts.v_lapse`)} (V)`,
          field: 'v_lapse',
          width: '7%',
          custom: true
        },
        {
          label: `${t(`vestingBook.endOfContracts.v_lapse_date`)} (V)`,
          field: 'v_lapse_date',
          width: '',
          custom: true
        },
        {
          label: `${t(`vestingBook.endOfContracts.nv_lapse`)} (NV)`,
          field: 'nv_lapse',
          custom: true,
          width: '7%',
          info: 'Info'
        },
        {
          label: `${t(`vestingBook.endOfContracts.nv_lapse_date`)} (NV)`,
          field: 'nv_lapse_date',
          custom: true
        }
      ])

      watch(
        () => props.vesting,
        (newProps: any) => {
          if (props.vesting?.endOfContractBehaviors) {
            Object.keys(EndOfContractReasons).forEach((x) => {
              endOfContractBehaviors.value[x] = {
                notVestedPercent:
                  newProps?.endOfContractBehaviors[x]?.notVestedPercent || 0,
                vestedPercent:
                  newProps?.endOfContractBehaviors[x]?.vestedPercent || 0,
                notVestedPeriod: {
                  timeUnit:
                    newProps?.endOfContractBehaviors[x]?.notVestedPeriod
                      ?.timeUnit || TIMEUNIT.MONTH,
                  value:
                    newProps?.endOfContractBehaviors[x]?.notVestedPeriod
                      ?.value || 90
                },
                vestedPeriod: {
                  timeUnit:
                    newProps?.endOfContractBehaviors[x]?.vestedPeriod
                      ?.timeUnit || TIMEUNIT.MONTH,
                  value:
                    newProps?.endOfContractBehaviors[x]?.vestedPeriod?.value ||
                    90
                }
              }
            })
          }
        }
      )

      return {
        columns,
        data,
        timeUnits,
        endOfContractBehaviors
      }
    }
  })
