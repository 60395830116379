<template>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.label') }}
      </h1>
      <p v-if="event.label" class="text-blackp text-sm text-left">
        {{ event.label }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.stockCategory') }}
      </h1>
      <p v-if="event.stockCategory" class="text-blackp text-sm text-left">
        {{ event.stockCategory.name }}
      </p>
      <p v-else-if="event.optionCategory" class="text-blackp text-sm text-left">
        {{ event.optionCategory.name }}
      </p>
      <p
        v-else-if="event.isForCompositionAlias"
        class="text-blackp text-sm text-left"
      >
        {{ $t('events.fieldsLabels.compositions') }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-l border-secondary6 border-b w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.startDate') }}
      </h1>
      <p v-if="event.date" class="text-blackp text-sm text-left">
        {{ moment.utc(event.date).format('L') }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.delegationQuantity') }}
      </h1>
      <p v-if="event.quantity" class="text-blackp text-sm text-left">
        {{ formatNumber(event.quantity) }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.commonCeiling') }}
      </h1>
      <p v-if="event.commonCeiling" class="text-blackp text-sm text-left">
        {{ event.commonCeiling.label }}
        <strong>({{ formatNumber(event.commonCeiling.quantity) }})</strong>
      </p>
      <p v-else class="text-blackp text-sm text-left"></p>
    </div>
    <div class="p-3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.endDate') }}
      </h1>
      <p v-if="event.endDate" class="text-blackp text-sm text-left">
        {{ moment.utc(event.endDate).format('L') }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { Delegation } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  export default defineComponent({
    name: 'DelegationCard',
    props: {
      event: {
        type: Object as () => Delegation | undefined,
        default: null
      }
    },
    setup() {
      return { moment, formatNumber }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
