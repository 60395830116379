<template>
  <div class="h-full w-full bg-primary12 flex justify-center items-center">
    <Loader v-if="isLoading" />
    <div
      v-else-if="success"
      class="flex justify-between w-2/5 xl:w-1/4 rounded p-2 error-container"
    >
      <div class="w-1/5 flex justify-center items-center">
        <img class="my-1 w-16" src="@/assets/images/logo/uplaw_icon.png" />
      </div>
      <div class="p-2">
        <p class="text-lg text-primary5 font-bold">
          {{ $t('account.accountInformations.password_renew') }}
        </p>
        <span class="text-success">{{
          $t('account.accountInformations.email_sent')
        }}</span>
      </div>
      <div class="1/6 flex justify-center items-center">
        <span
          class="
            border
            px-2
            py-1
            rounded
            border-secondary6
            icon
            cursor-pointer
            mr-1
          "
          @click="gotoLogin()"
        >
          <i class="bi bi-box-arrow-in-up-right text-secondary6"></i>
        </span>
      </div>
    </div>

    <div
      v-else
      class="w-2/5 xl:w-1/4 h-3/4 flex flex-col items-center justify-center"
    >
      <img
        class="w-48 my-8"
        src="https://uploads-ssl.webflow.com/61f7c66c94334412498f2920/625f0340b2348b9acb3e5091_uplaw%20final%20(1).png"
      />

      <div class="w-full h-3/4 bg-white border-t-4 border-primary4 px-0 pt-5">
        <p class="text-center font-bold text-2xl text-black text-primary5">
          {{ $t('account.accountInformations.password_renew') }}
        </p>

        <Form
          v-slot="{ errors }"
          ref="myForm"
          :validation-schema="schema"
          class="mt-5"
          @submit="handleForm"
        >
          <div>
            <label class="text-left" for="">{{
              $t('account.accountInformations.form.email.label')
            }}</label>
            <Field v-slot="{ field }" v-model="email" name="email">
              <Input
                v-model="email"
                v-bind="field"
                :error="errors['email'] ? $t(`errors.${errors['email']}`) : ''"
                type="email"
                name="email"
                :placeholder="
                  $t('account.accountInformations.form.email.placeholder')
                "
              />
            </Field>
          </div>

          <div class="text-2xl mt-5 flex justify-end items-end">
            <Button
              type="submit"
              variant="custom"
              :disabled="Object.keys(errors).length > 0"
              class="bg-primary4 text-white rounded hover:bg-primary5"
              :label="$t('global.actions.edit')"
            ></Button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field, Form } from 'vee-validate'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '@/components/Loader/index.vue'
  import { User } from '@/services/api/models'
  import * as yup from 'yup'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: { Input, Field, Form, Button, Loader },
    setup() {
      const store = useStore()
      const router = useRouter()
      const { t } = useI18n()
      const email = ref('')
      const user = ref<User | null>(null)
      const isLoading = ref(false)
      const success = ref(false)

      const schema = yup.object({
        email: yup.string().required('emailRequired')
      })
      const gotoLogin = () => {
        router.replace({ name: 'auth.connect' })
      }

      const handleForm = () => {
        isLoading.value = true
        store.dispatch('auth/RESET_PASSWORD', {
          email: email.value,
          onSuccess: () => {
            isLoading.value = false
            success.value = true
          },
          onError: (err: string) => {
            success.value = false
            isLoading.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              message:
                err ||
                t(
                  `account.accountInformations.apiResponse.errorPasswordEdition`
                ),
              context: t('account.context')
            })
            success.value = true
          }
        })
      }

      return {
        email,
        success,
        isLoading,
        schema,
        user,
        handleForm,
        gotoLogin
      }
    }
  })
</script>
<style lang="scss" scoped>
  .error-container {
    -webkit-box-shadow: 1px 2px 21px 7px rgba(133, 128, 246, 0.24);
    box-shadow: 1px 2px 21px 7px rgba(133, 128, 246, 0.24);
    .icon {
      &:hover {
        @apply border-primary4;
      }
      &:hover i {
        @apply text-primary4;
      }
    }
  }
</style>
