<template>
  <div class="footer-form content_footer">
    <div class="border-none border-r-0">
      <li
        v-for="(error, index) in displayedErrors"
        :key="index"
        class="text-xs text-error text-left"
      >
        {{ buildErrorField(index.toString()) }}: {{ error }}
      </li>
    </div>
    <div v-if="submitting" class="flex items-center justify-end">
      <h5>{{ $t('events.labels.saving') }}...</h5>
      <svg
        id="L9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        height="50"
        width="50"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
        xml:space="preserve"
        class="stroke-1"
      >
        <path
          fill="#bcbec1"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    <div v-else class="flex items-center justify-end">
      <Button
        class="mr-3"
        variant="tertiary"
        type="reset"
        :disabled="false"
        :label="$t('global.actions.cancel')"
        @click="goBack"
      />
      <Button
        v-if="!onlySave"
        variant="secondary"
        type="button"
        :disabled="!meta.valid"
        :label="$t('global.actions.saveAndContinue')"
        @click="$emit('submit', false)"
      />
      <Button
        class="ml-4"
        type="submit"
        variant="primary"
        :disabled="!meta.valid"
        :label="
          ['company.eventUpdate'].includes($route?.name?.toString() || '')
            ? $t('events.editSave')
            : $t('global.actions.save')
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { Button } from '@up.law/uplaw-ui'
  import { computed, defineComponent, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'EventFormFooter',
    components: { Button },
    props: {
      onlySave: { type: Boolean, default: false },
      submitting: { type: Boolean, default: false },
      errors: {
        type: Object as () => Record<string, string>,
        required: true
      },
      meta: {
        type: Object as () => Record<string, boolean>,
        required: true
      }
    },
    emits: ['submit'],
    setup(props) {
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()

      const displayedErrors = computed(() =>
        Object.keys(props.errors)
          .filter((x, i) => i <= 3)
          .reduce((res: any, err) => ({ ...res, [err]: props.errors[err] }), {})
      )
      const buildErrorField = (key: string) => {
        let message = ''
        let line = 1
        const field = key.split('.')[key.split('.').length - 1]
        let data: string[] = key.split('[')
        if (data.length > 1) {
          line += parseInt(data[key.split('[').length - 1].charAt(0))
          message += `${t('global.line', { number: line })} - `
        }

        message += `${t(`errors.fields.${field}`)}`
        return message
      }

      const goBack = () => {
        router.push({
          name: 'company.corporateEvents',
          params: { id: route.params.id },
          query: { type: 'event' }
        })
      }

      return {
        displayedErrors,
        goBack,
        buildErrorField
      }
    }
  })
</script>

<style lang="scss" scoped>
  .footer-form {
    @apply w-full px-4 py-5 right-0 bottom-0  h-20 flex items-center justify-between text-xl absolute bg-white;
  }
</style>
