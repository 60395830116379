<template>
  <div class="w-full flex justify-between">
    <div class="w-1/5">
      <Field v-slot="{ field }" v-model="selectedStatus" name="selectedStatus">
        <Multiselect
          v-model="selectedStatus"
          v-bind="field"
          mode="single"
          class="min-h-full"
          open-direction="bottom"
          :options="status"
          :groups="false"
          :placeholder="$t('global.placeholder.auditStatusPlaceholder')"
          @change="updateFilters"
        />
      </Field>
    </div>

    <div v-if="selectedRows" class="w-1/2 flex justify-end">
      <div class="w-1/3 mr-2">
        <Field v-slot="{ field }" v-model="updateStatus" name="updateStatus">
          <Multiselect
            v-model="updateStatus"
            v-bind="field"
            mode="single"
            class="min-h-full"
            open-direction="bottom"
            :options="status"
            :groups="false"
            :placeholder="$t('global.placeholder.auditStatusPlaceholder')"
            @change="updateStatus = $event"
          />
        </Field>
      </div>
      <Button
        class="flex items-center px-2"
        variant="secondary"
        :label="$t('global.actions.save')"
        :disabled="updateStatus == ''"
        @click="update"
      >
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { AuditStatusType } from '@/types'
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Button } from '@up.law/uplaw-ui'

  export default defineComponent({
    components: { Field, Multiselect, Button },
    props: { selectedRows: { type: Boolean, default: false } },
    emits: ['filterChange', 'updateStatus'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const selectedStatus = ref('')
      const updateStatus = ref('')
      const status = computed(() =>
        Object.values(AuditStatusType).map((key) => ({
          id: key,
          name: t(`auditStatus.${key}`)
        }))
      )

      const updateFilters = (value: string) => {
        selectedStatus.value = value
        emit('filterChange', selectedStatus.value)
      }

      const update = () => {
        emit('updateStatus', updateStatus.value)
      }

      return { status, selectedStatus, updateStatus, updateFilters, update }
    }
  })
</script>
