
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Tag } from '@up.law/uplaw-ui'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { VestingSynthesis } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { formatNumber, rgbToHex } from '@/utils'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { ElTooltip } from 'element-plus'
  export default defineComponent({
    components: {
      Field,
      DataTable,
      Tag,
      EmptyState,
      Multiselect,
      Loader,
      ElTooltip,
      DownloadButton,
      DatePicker,
      Number
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const currency = computed(() => store.getters['organizations/currency'])

      const pagination = computed(
        () => store.getters['organizations/exerciseCalendarSynthesisPagination']
      )

      const filter = ref({
        optionAliasId: '',
        date: new Date().toISOString()
      })
      const columns = computed(() => [
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          tooltipText: t('events.tooltips.stock'),
          tooltipWidth: '12%',
          hasTooltip: true,
          custom: true
        },
        {
          label: t('events.fieldsLabels.exercisePrice'),
          field: 'exercisePrice',
          custom: true,
          tooltipText: t('events.tooltips.exercisePrice'),
          tooltipWidth: '12%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          width: '2%',
          tooltipText: t('events.tooltips.assignedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.exercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          tooltipText: t('events.tooltips.exercisedQuantity'),
          tooltipWidth: '12%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          tooltipText: t('events.tooltips.lapsedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          tooltipText: t('events.tooltips.vestedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.balance'),
          field: 'ceil',
          custom: true,
          tooltipText: t('events.tooltips.balance'),
          tooltipWidth: '20%',
          hasTooltip: true
        }
      ])

      const data = computed<VestingSynthesis[]>(
        () => store.getters['organizations/vestingSyntheses']
      )

      const optionAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const isDownloading = ref(false)

      const total = computed(() =>
        data.value.reduce((acc, c) => (acc += c.assignedAmount), 0)
      )

      const vestedQuantity = computed(() =>
        data.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const ceil = computed(() =>
        data.value.reduce((acc, row) => (acc += row.exercisableQuantity), 0)
      )

      const obsoleteQuantity = computed(() =>
        data.value.reduce((acc, c) => (acc += c.numberOfSunsetedOptions), 0)
      )
      const exercisedQuantity = computed(() =>
        data.value.reduce((acc, c) => (acc += c.exercisedQuantity), 0)
      )

      const heldBalance = computed(() => total.value - vestedQuantity.value)

      const exercisePrice = computed(() =>
        data.value.reduce((acc, c) => (acc += c.exercisePrice), 0)
      )

      const potentialGain100Percent = computed(() =>
        data.value.reduce(
          (acc, row) =>
            (acc +=
              row.potentialGain100Percent < 0
                ? 0
                : row.potentialGain100Percent),
          0
        )
      )

      const lastStockPrice = computed(() =>
        data.value.reduce((acc, c) => (acc += c.lastStockPrice), 0)
      )
      const isLoading = ref(false)
      const isFiltering = ref(false)
      const handleFiltering = (value: boolean) => {
        isFiltering.value = value
      }
      const onPageChange = (page: number) => {
        getVesting(page)
      }

      const getVesting = async (page = 0) => {
        await store.dispatch('organizations/GET_VESTING_SYNTHESIS', {
          companyId: route.params.id,
          filter: {
            ...filter.value,
            page
          },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }
      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS_BY_OPTION', {
          name: `${t('exports.shareholding')} `,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          }
        })
      }

      watch(
        () => route.params.id,
        () => {
          getVesting()
        }
      )

      watch(
        () => filter.value.optionAliasId,
        (val) => {
          isFiltering.value = true
          filter.value.optionAliasId = val
          getVesting()
        }
      )

      watch(
        () => filter.value.date,
        (val) => {
          isFiltering.value = true

          filter.value.date = val
          getVesting()
        }
      )

      onMounted(() => {
        isLoading.value = true
        getVesting()
      })

      return {
        filter,
        data,
        pagination,
        optionAliases,
        currency,
        exercisePrice,
        lastStockPrice,
        heldBalance,
        handleFiltering,
        isFiltering,
        onPageChange,
        exercisedQuantity,
        total,
        ceil,
        isDownloading,
        potentialGain100Percent,
        NumberType,
        obsoleteQuantity,
        columns,
        vestedQuantity,
        isLoading,
        formatNumber,
        download,
        rgbToHex
      }
    }
  })
