
  import { defineComponent, ref } from 'vue'
  import { EventType } from '@/types/event'
  import { Event } from '@/services/api/models'
  import FormWrapper from '@/components/Forms/Event/Generic/FormWrapper.vue'
  import moment from 'moment'

  export default defineComponent({
    components: { FormWrapper },
    props: { event: { type: Object as () => Event, required: true } },
    emits: ['loaded'],
    setup() {
      const isDraft = ref(false)
      const selectedCat = ref()
      return { EventType, isDraft, selectedCat, moment }
    }
  })
