
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { meetingColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import {
    DocumentCategory,
    Document as DocumentModel
  } from '@/services/api/models'
  import { DocumentMarkAs } from '@/types'
  import moment from 'moment'
  import LongText from '@/components/LongText/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Loader from '@/components/Loader/index.vue'
  export default defineComponent({
    name: 'UplStockMovements',
    components: {
      Field,
      Input,
      Button,
      DataTable,
      LongText,
      EmptyState,
      Loader
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const search = ref('')
      const loading = ref(true)
      const router = useRouter()
      const documents = ref<DocumentModel[]>([])
      const categories = computed<DocumentCategory[]>(
        () => store.getters['toolbox/documentCategories']
      )

      const committeesCategories = computed(() =>
        categories.value
          .map((x) => {
            return x.docSubCategories.filter(
              (y) => y.markAs === DocumentMarkAs.committees
            )
          })
          .flat()
          .map((x) => x.id)
      )

      const pagination = computed(
        () => store.getters['organizations/documentsPagination']
      )
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', page)
        getDocuments()
      }
      const editFile = (document: DocumentModel) => {
        router.push({
          name: 'companyDocuments',
          params: {
            id: route.params.id,
            documentId: document.id
          }
        })
      }
      const selectDocument = async (doc: DocumentModel) => {
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.commit('organizations/COLLAPSE_DOC_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }
      const getDocuments = async (search = '') => {
        if (committeesCategories.value.length == 0) {
          loading.value = false
          return
        }
        loading.value = true
        await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: {
            search,
            documentSubCategoriesId: committeesCategories.value
          },
          onSuccess: (result: any) => {
            documents.value = result.data
            loading.value = false
          }
        })

        loading.value = false
      }

      const columns = ref(meetingColumns)

      const downloadableDocument = (row: DocumentModel) => {
        return row.documentVersions?.find((x) => x.isMaster)
      }

      watch(committeesCategories, () => {
        getDocuments()
      })

      watch(search, (val) => {
        getDocuments(val)
      })

      onMounted(() => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', 1)
        getDocuments()
      })

      return {
        search,
        loading,
        documents,
        columns,
        moment,
        pagination,
        committeesCategories,
        downloadableDocument,
        onPageChange,
        editFile,
        selectDocument
      }
    }
  })
