
  import { defineComponent } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  export default defineComponent({
    name: 'Modal',
    components: { Button },
    props: {
      size: { type: String, default: 'w-3/5' }
    },
    emits: ['close'],
    setup(props, { emit }) {
      const close = () => {
        emit('close')
      }
      return { close }
    }
  })
