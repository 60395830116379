
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    toRefs,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Datepicker from '@/components/DatePicker/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { eventCategoriesList } from '@/utils/data'
  import moment from 'moment'
  import { Field } from 'vee-validate'
  import SearchResult from '@/components/DocumentSearchResult/index.vue'
  import { OptionStockAlias } from '@/services/api/models'
  import { ElTooltip } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'FiltersCorporate',
    components: {
      Datepicker,
      SearchResult,
      DropdownStocks,
      DropdownContacts,
      Multiselect,
      Input,
      ElTooltip,
      Button,
      Field
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object
      },
      type: {
        type: String,
        required: true
      }
    },
    emits: ['update-filters'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const search = ref('')
      const resultPopover = ref(null)
      const { t } = useI18n()
      const { filters } = toRefs(props)
      const filtersData = ref<any>(filters.value)
      const contactsId = ref([])
      const categories = ref([])
      const draft = ref([])
      const stockIds = ref<string[]>([])
      const aliasesId = ref<string[]>([])
      const optionIds = ref<string[]>([])
      const compositionIds = ref<string[]>([])
      const eventTypes = ref([])
      const operationsId = ref([])
      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])
      const picked = ref([])
      const contacts = computed(() => store.getters['organizations/contacts'])
      const organs = computed(() => store.getters['organizations/committees'])
      const eventCategories = ref(eventCategoriesList)
      const documents = computed(
        () => store.getters['organizations/corporateDocuments']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )

      const status = computed(() => [
        { id: true, name: t('events.draftStatus.isDraft') },
        { id: false, name: t('events.draftStatus.validated') }
      ])
      const optionStockAliases = computed<OptionStockAlias[]>(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const operations = computed(
        () => store.getters['organizations/operations']
      )
      const resetFilters = () => {
        picked.value = []
        draft.value = []
        filtersData.value = {
          endDate: moment().toISOString(),
          startDate: '1970-01-01'
        }
        aliasesId.value = []
        stockIds.value = []
        contactsId.value = []
        categories.value = []
        eventTypes.value = []
        operationsId.value = []

        emit('update-filters', filtersData.value, props.type)
      }
      const isOption = (id: string) => {
        return (
          optionStockAliases.value.find((item: any) => item.id === id) !==
          undefined
        )
      }

      const isComposition = (id: string) => {
        return (
          compositionStockAlias.value.find((item: any) => item.id === id) !==
          undefined
        )
      }

      const updateFilters = (e: any, filter: string, field = '') => {
        if (filter === 'stocksId' && e.value) {
          optionIds.value = []
          compositionIds.value = []
          stockIds.value = []
          e.value.forEach((element: string) => {
            if (isOption(element)) {
              optionIds.value.push(element)
            } else if (isComposition(element)) {
              compositionIds.value.push(element)
            } else {
              stockIds.value.push(element)
            }
          })
          filtersData.value['optionAliasesId'] = optionIds.value
          filtersData.value['compositionAliasesId'] = compositionIds.value
          filtersData.value['stockAliasesId'] = stockIds.value
          aliasesId.value = e.value
        } else {
          filtersData.value[filter] = e
        }

        emit('update-filters', filtersData.value, props.type, field)
      }

      watch(picked, (newDateRange) => {
        let dateFilter: any = {
          endDate: moment().toISOString(),
          startDate: '1970-01-01'
        }
        if (newDateRange && newDateRange.length > 0) {
          dateFilter = {
            startDate:
              newDateRange && newDateRange[0]
                ? moment.utc(newDateRange[0]).startOf('day').toISOString()
                : null,
            endDate:
              newDateRange && newDateRange[1]
                ? moment.utc(newDateRange[1]).endOf('day').toISOString()
                : null
          }
        }
        filtersData.value = { ...filtersData.value, ...dateFilter }
        emit('update-filters', filtersData.value, props.type)
      })

      const initSearch = () => {
        filtersData.value.search = null
      }
      const hideSearchResults = () => {
        filtersData.value.search = null
      }

      watch(
        () => filtersData.value.search,
        () => {
          // resultPopover.value?.click()
        }
      )

      onBeforeMount(() => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
        store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: route.params.id
        })
        store.dispatch('organizations/GET_OPERATIONS', {
          companyId: route.params.id
        })

        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id
        })
      })
      onMounted(() => {
        //
      })

      return {
        search,
        eventTypes,
        documentCategories,
        eventCategories,
        categories,
        contacts,
        contactsId,
        organs,
        documents,
        stockIds,
        aliasesId,
        picked,
        draft,
        filtersData,
        status,
        updateFilters,
        resetFilters,
        hideSearchResults,
        searchResults,
        resultPopover,
        initSearch,
        operationsId,
        operations
      }
    }
  })
