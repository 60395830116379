<template>
  <div class="w-full overflow-y-scroll text-search">
    <div v-if="searching" class="treeview__hint-loading text-xs relative">
      <Loader />
    </div>

    <div v-else class="treeview__hint-results flex flex-col bg-white z-0">
      <div class="w-full flex justify-between items-center">
        <p class="m-0 mb-2">
          {{ searchResults.length }}
          {{ $tc('extraction.treeview.searchResult', searchResults.length) }}
        </p>
        <div class="icon__button" @click="hideSearchResults()">
          <i class="bi-x xl" />
        </div>
      </div>

      <div v-for="(searchResult, index) in searchResults" :key="index" class="">
        <h5 class="text-sm text-left">{{ searchResult.documentName }}</h5>
        <div
          v-for="(text, i) in searchResult.highlights"
          :key="i"
          class="
            my-2
            treeview__hint-resultsItem treeview__hint-resultsItemInner
            flex
            justify-start
            p-2
            border
            rounded
            border-secondary6
          "
        >
          <p class="treeview__hint-file flex items-center cursor-pointer">
            <i class="bi-file-earmark-text mr-2 md text-xl" />
          </p>

          <p
            class="ml-1 cursor-pointer text-left"
            @click="
              displayDocument(
                searchResult.documentId,
                getCorrectText(text),
                searchResult?.companyId
              )
            "
            v-html="`...${text}...`"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { CompanyModel } from '@/services/api/models'
  import { DocumentResult } from '@/store/modules/Organization/models'
  import { computed, defineComponent } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '../Loader/index.vue'
  export default defineComponent({
    components: { Loader },
    props: {
      filter: { type: String, default: '' },
      data: { type: Object as () => DocumentResult[], required: true }
    },
    emits: ['close'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const searchResults = computed(() => props.data)
      const searching = computed(
        () => store.getters['organizations/searchingText']
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const hideSearchResults = () => {
        emit('close')
      }

      const getCorrectText = (text: string) => {
        var result = text
          .match(/<mark>(.*?)<\/mark>/g)
          ?.map(function (val: string) {
            return val.replace(/<\/?mark>/g, '')
          })
        return result && result?.length > 0 ? result[0] : props.filter
      }
      const displayDocument = async (
        documentId: string,
        text: string,
        companyId: string | undefined
      ) => {
        console.log(documentId, companyId)

        store.commit('organizations/SET_TEXT_SEARCHED', text)

        if (route.name === 'companyDocuments') {
          router.push({
            name: 'companyDocuments',
            params: { documentId }
          })
        }
        store.commit('ui/DISPLAY_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: isPortfolioRoute.value
            ? companyId
            : currentCompany.value.id,
          documentId
        })

        emit('close')
      }

      return {
        searchResults,
        searching,
        hideSearchResults,
        displayDocument,
        getCorrectText
      }
    }
  })
</script>
<style lang="scss">
  .text-search {
    min-height: 100px;
    .treeview__hint-loading {
      @apply flex justify-center items-center;
      min-height: 100px;
    }
  }
</style>
