import axios from '../axios'
import {
  AppointmentFormData,
  ApprovalFormData,
  CommiteeFormData,
  CommonCeilingFormData,
  DelegationFormData,
  TransferFormData,
  VestingAccelerationFormData,
  VestingProrogationFormData,
  VestingSuspensionFormData,
  ConstitutionFormData
} from '@/types/forms'
import {
  Appointment,
  Committee,
  Delegation,
  FinancialEnding,
  Transfer,
  UpFunction,
  VestingAcceleration,
  VestingDelayProrogation,
  VestingSuspension
} from '../models/company'
import { company } from '../endpoints'
import { AccountApproval, CommonCeiling } from '../models/events'
import { FinancialEndingFormData } from '@/types'
import { OptionExerciseFormData } from '@/types/forms'
import { EventType } from '@/types/event'
import { CancelTokenSource } from 'axios'
let eventsSearchCancelToken: CancelTokenSource | null = null
let eventsCancelToken: CancelTokenSource | null = null
let delegationsCancelToken: CancelTokenSource | null = null
const createCommittee = async (
  companyId: string,
  data: CommiteeFormData
): Promise<Committee> => {
  return await axios.post(company.getCommittees(companyId), data)
}

const getCompanyCommittes = async (
  companyId: string,
  filter = {},
  page = 1
): Promise<any> => {
  const offset = (page - 1) * 10
  return await axios.get(company.getCommittees(companyId), {
    params: { ...filter, offset, limit: 10 }
  })
}

const getEvents = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 30
): Promise<any[]> => {
  if (typeof eventsCancelToken != undefined) {
    eventsCancelToken?.cancel(
      'Operation (GET_EVENTS) canceled due to new request.'
    )
  }
  eventsCancelToken = axios.CancelToken.source()
  return await axios.get(company.getEvents(companyId), {
    cancelToken: eventsCancelToken.token,
    params: {
      offset,
      limit,
      ...filter
    }
  })
}

const searchEvents = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 20
): Promise<any[]> => {
  if (typeof eventsSearchCancelToken != undefined) {
    eventsSearchCancelToken?.cancel(
      'Operation (GET_EVENTS) canceled due to new request.'
    )
  }
  eventsSearchCancelToken = axios.CancelToken.source()
  return await axios.get(company.searchEvents(companyId), {
    cancelToken: eventsSearchCancelToken.token,
    params: {
      offset,
      limit,
      ...filter
    }
  })
}

const getEvent = async (
  companyId: string,
  eventId: string,
  type: string
): Promise<any> => {
  return await axios.get(company.getEvent(companyId, eventId), {
    params: { type }
  })
}

const deleteEvent = async (
  companyId: string,
  eventId: string
): Promise<any> => {
  return await axios.delete(company.getEvent(companyId, eventId), {})
}

const createVestingSuspension = async (
  companyId: string,
  data: VestingSuspensionFormData
): Promise<VestingSuspension> => {
  return await axios.post(company.getVestingSuspension(companyId), data)
}

const editVestingSuspension = async (
  companyId: string,
  eventId: string,
  data: VestingSuspensionFormData
): Promise<VestingSuspension> => {
  return await axios.put(
    company.singleVestingSuspension(companyId, eventId),
    data
  )
}

const getVestingSuspension = async (
  companyId: string
): Promise<VestingSuspension[]> => {
  return await axios.get(company.getVestingSuspension(companyId))
}

const createVestingAcceleration = async (
  companyId: string,
  data: VestingAccelerationFormData
): Promise<VestingAcceleration> => {
  return await axios.post(company.getVestingAcceleration(companyId), data)
}

const editVestingAcceleration = async (
  companyId: string,
  eventId: string,
  data: VestingAccelerationFormData
): Promise<VestingAcceleration> => {
  return await axios.put(
    company.singleVestingAcceleration(companyId, eventId),
    data
  )
}

const getVestingAcceleration = async (
  companyId: string
): Promise<VestingAcceleration[]> => {
  return await axios.get(company.getVestingAcceleration(companyId))
}

const createVestingProrogation = async (
  companyId: string,
  data: VestingProrogationFormData
): Promise<VestingDelayProrogation> => {
  return await axios.post(company.getVestingProrogation(companyId), data)
}
const editVestingProrogation = async (
  companyId: string,
  eventId: string,
  data: VestingProrogationFormData
): Promise<VestingDelayProrogation> => {
  return await axios.post(
    company.singleVestingProrogation(companyId, eventId),
    data
  )
}
const attachDocumentsToParts = async (
  eventType: EventType,
  companyId: string,
  id: string,
  partId: string,
  data: any
) => {
  EventType.optionIssuance
  const types: any = {
    [EventType.optionIssuance]: {
      event: 'option-issuances',
      partString: 'option-issuance-parts'
    },
    [EventType.stockIssuance]: {
      event: 'stock-issuances',
      partString: 'stock-issuance-parts'
    },
    [EventType.compositionIssuance]: {
      event: 'composition-issuances',
      partString: 'composition-issuance-parts'
    },
    [EventType.optionExercise]: {
      event: 'option-exercise',
      partString: 'option-exercise-parts'
    },
    [EventType.capitalReduction]: {
      event: 'capital-reduction',
      partString: 'capital-reduction-parts'
    },
    [EventType.conversion]: {
      event: 'conversions',
      partString: 'conversion-parts'
    },
    [EventType.transfer]: {
      event: 'transfers',
      partString: 'transfer-parts'
    }
  }
  return await axios.put(
    company.eventPartDocuments(
      types[eventType].event,
      types[eventType.toString()].partString,
      companyId,
      id,
      partId
    ),
    data
  )
}
const detachDocumentsFromPart = async (
  eventType: EventType,
  companyId: string,
  id: string,
  partId: string,
  documentId: string
) => {
  EventType.optionIssuance
  const types: any = {
    [EventType.optionIssuance]: {
      event: 'option-issuances',
      partString: 'option-issuance-parts'
    },
    [EventType.stockIssuance]: {
      event: 'stock-issuances',
      partString: 'stock-issuance-parts'
    },
    [EventType.compositionIssuance]: {
      event: 'composition-issuances',
      partString: 'composition-issuance-parts'
    },
    [EventType.optionExercise]: {
      event: 'option-exercise',
      partString: 'option-exercise-parts'
    }
  }
  return await axios.delete(
    company.eventPartDocument(
      types[eventType].event,
      types[eventType.toString()].partString,
      companyId,
      id,
      partId,
      documentId
    )
  )
}

const getVestingProrogation = async (
  companyId: string
): Promise<VestingDelayProrogation[]> => {
  return await axios.get(company.getVestingProrogation(companyId))
}

const getCompanyCeilings = async (
  companyId: string
): Promise<CommonCeiling[]> => {
  return await axios.get(company.getCeilings(companyId))
}

const getCompanyFunctions = async (): Promise<UpFunction[]> => {
  return await axios.get(company.getFunctions())
}

const getCompanyAppointments = async (
  companyId: string,
  filter = {},
  offset = 0
) => {
  return await axios.get(company.getNominations(companyId), {
    params: { ...filter, offset, limit: 10 }
  })
}

const getCompanyAppointment = async (companyId: string, id: string) => {
  return await axios.get(company.nommination(companyId, id))
}

const createAppointment = async (
  companyId: string,
  data: AppointmentFormData
): Promise<Appointment> => {
  return await axios.post(company.getNominations(companyId), data)
}

const editAppointment = async (
  companyId: string,
  appointmentId: string,
  data: AppointmentFormData
): Promise<Appointment> => {
  return await axios.put(company.nommination(companyId, appointmentId), data)
}

const terminateAppointment = async (
  companyId: string,
  data: AppointmentFormData
): Promise<Appointment> => {
  return await axios.post(company.terminateAppointment(companyId), data)
}
const editAppointmentTermination = async (
  companyId: string,
  terminationId: string,
  data: AppointmentFormData
): Promise<Appointment> => {
  return await axios.put(
    company.singleTerminateAppointment(companyId, terminationId),
    data
  )
}
const getCompanyTransfers = async (companyId: string): Promise<Transfer[]> => {
  return await axios.get(company.getTransfers(companyId))
}

const createTransfer = async (
  companyId: string,
  data: TransferFormData
): Promise<Transfer> => {
  return await axios.post(company.getTransfers(companyId), data)
}

const editTransfer = async (
  companyId: string,
  transferId: string,
  data: TransferFormData
): Promise<Transfer> => {
  return await axios.put(company.transfer(companyId, transferId), data)
}
const createCeiling = async (
  companyId: string,
  data: CommonCeilingFormData
): Promise<CommonCeiling> => {
  return await axios.post(company.getCeilings(companyId), data)
}
const editCeiling = async (
  companyId: string,
  ceilingId: string,
  data: CommonCeilingFormData
): Promise<CommonCeiling> => {
  return await axios.put(company.ceiling(companyId, ceilingId), data)
}

const getDelegations = async (
  companyId: string,
  filter = { offset: 0, limit: 20 }
): Promise<Delegation[]> => {
  const params = {
    ...filter,
    offset: filter?.offset || 0,
    limit: filter?.limit || 20
  }

  if (typeof delegationsCancelToken != undefined) {
    delegationsCancelToken?.cancel(
      'Operation (GET_DELEGATIONS) canceled due to new request.'
    )
  }
  delegationsCancelToken = axios.CancelToken.source()
  return await axios.get(company.getDelegations(companyId), {
    cancelToken: delegationsCancelToken.token,
    params
  })
}

const getDelegation = async (
  companyId: string,
  delegationId: string
): Promise<Delegation[]> => {
  return await axios.get(company.delegation(companyId, delegationId), {})
}

const getDelegatedTransactions = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 20
): Promise<Delegation[]> => {
  return await axios.get(company.getDelegatedTransactions(companyId), {
    params: { ...filter, offset, limit }
  })
}

const createDelegation = async (
  companyId: string,
  data: DelegationFormData
): Promise<Delegation> => {
  return await axios.post(company.getDelegations(companyId), data)
}

const editDelegation = async (
  companyId: string,
  delegationId: string,
  data: DelegationFormData
): Promise<Delegation> => {
  return await axios.put(company.delegation(companyId, delegationId), data)
}

const getApprovals = async (companyId: string): Promise<AccountApproval[]> => {
  return await axios.get(company.getAccountApproval(companyId))
}

const createApproval = async (
  companyId: string,
  data: ApprovalFormData
): Promise<AccountApproval> => {
  return await axios.post(company.getAccountApproval(companyId), data)
}

const editApproval = async (
  companyId: string,
  eventId: string,
  data: ApprovalFormData
): Promise<AccountApproval> => {
  return await axios.put(
    company.singleAccountApproval(companyId, eventId),
    data
  )
}

const createOptionExercise = async (
  companyId: string,
  data: OptionExerciseFormData
): Promise<any> => {
  return await axios.post(company.optionsExercise(companyId), data)
}

const editOptionExercise = async (
  companyId: string,
  exerciseId: string,
  data: OptionExerciseFormData
): Promise<any> => {
  return await axios.put(
    company.singleOptionsExercise(companyId, exerciseId),
    data
  )
}
const createConstitution = async (
  companyId: string,
  data: ConstitutionFormData
): Promise<any> => {
  return await axios.post(company.getConstitution(companyId), data)
}

const createCapitalReduction = async (
  companyId: string,
  data: any
): Promise<any> => {
  return await axios.post(company.capitalReductions(companyId), data)
}

const createOptionSunset = async (
  companyId: string,
  data: any
): Promise<any> => {
  return await axios.post(company.createOptionSunset(companyId), data)
}

const editOptionSunset = async (
  companyId: string,
  eventId: string,
  data: any
): Promise<any> => {
  return await axios.put(company.singleOptionSunset(companyId, eventId), data)
}
const getOptionSunsets = async (
  companyId: string,
  filter: any
): Promise<any> => {
  return await axios.get(company.createOptionSunset(companyId), {
    params: filter
  })
}
const editCapitalReduction = async (
  companyId: string,
  reductionId: string,
  data: any
): Promise<any> => {
  return await axios.put(company.capitalReduction(companyId, reductionId), data)
}

const createConversion = async (companyId: string, data: any): Promise<any> => {
  return await axios.post(company.conversions(companyId), data)
}

const editConversion = async (
  companyId: string,
  conversionId: string,
  data: any
): Promise<any> => {
  return await axios.put(company.conversion(companyId, conversionId), data)
}
const getConversions = async (companyId: string): Promise<any> => {
  return await axios.get(company.conversions(companyId))
}

const getCapitalReductions = async (companyId: string): Promise<any> => {
  return await axios.get(company.capitalReductions(companyId))
}
const addNominalValue = async (id: string, data: any) => {
  return await axios.post(company.nominalValue(id), data)
}

const editNominalValue = async (id: string, valueId: string, data: any) => {
  return await axios.put(company.singleNominalValue(id, valueId), data)
}
const getNominalValue = async (id: string): Promise<any> => {
  return await await axios.get(company.nominalValue(id))
}

const getTransactions = async (
  companyId: string,
  filter: any,
  page = 1,
  limit = 100
) => {
  const params: any = {
    limit,
    offset: page <= 1 ? 0 : (page - 1) * 100,
    ...filter
  }
  console.log(params)

  return await axios.get(company.getTransactions(companyId), {
    params
  })
}

const exportTransactions = async (
  companyId: string,
  filter: any,
  page = 1,
  limit = 100
) => {
  const params: any = {
    limit,
    offset: page <= 1 ? 0 : (page - 1) * 100,
    ...filter
  }

  return await axios.get(company.transactionsExport(companyId), {
    responseType: 'arraybuffer',
    params
  })
}
const exportTransactionsPdf = async (
  companyId: string,
  filter: any,
  page = 1,
  limit = 100
) => {
  const params: any = {
    limit,
    offset: page <= 1 ? 0 : (page - 1) * 100,
    ...filter
  }

  return await axios.get(company.transactionsExportPdf(companyId), {
    params
  })
}
const exportCaptable = async (
  companyId: string,
  filter: any,
  page = 1,
  limit = 100
) => {
  const params: any = {
    limit,
    offset: page <= 1 ? 0 : (page - 1) * 100,
    ...filter
  }

  return await axios.get(company.captableExport(companyId), {
    params
  })
}

const exportContactRecord = async (
  companyId: string,
  filter: any,
  page = 1,
  limit = 100
) => {
  const params: any = {
    limit,
    offset: page <= 1 ? 0 : (page - 1) * 100,
    ...filter
  }

  return await axios.get(company.contactRecordExport(companyId), {
    params
  })
}

const createFinancialEnding = async (
  companyId: string,
  data: FinancialEndingFormData
): Promise<FinancialEnding> => {
  return await axios.post(company.getFinancialEnding(companyId), data)
}

const editFinancialEnding = async (
  companyId: string,
  eventId: string,
  data: FinancialEndingFormData
): Promise<FinancialEnding> => {
  return await axios.put(
    company.singleFinancialEnding(companyId, eventId),
    data
  )
}

const createParityChange = async (
  companyId: string,
  data: any
): Promise<any> => {
  return await axios.post(company.parityChanges(companyId), data)
}

const editParityChange = async (
  companyId: string,
  eventId: string,
  data: any
): Promise<any> => {
  return await axios.put(company.singleParityChanges(companyId, eventId), data)
}
export {
  getCompanyCeilings,
  createCommittee,
  getCompanyCommittes,
  createCeiling,
  getDelegations,
  createDelegation,
  getCompanyFunctions,
  getCompanyAppointments,
  createAppointment,
  terminateAppointment,
  getCompanyTransfers,
  createTransfer,
  createVestingSuspension,
  getVestingSuspension,
  createVestingAcceleration,
  getVestingAcceleration,
  createVestingProrogation,
  getVestingProrogation,
  addNominalValue,
  getNominalValue,
  getTransactions,
  getEvents,
  createFinancialEnding,
  getApprovals,
  createApproval,
  createConstitution,
  createCapitalReduction,
  getCapitalReductions,
  createOptionExercise,
  getEvent,
  getDelegatedTransactions,
  deleteEvent,
  exportTransactions,
  exportContactRecord,
  exportCaptable,
  createConversion,
  getConversions,
  attachDocumentsToParts,
  detachDocumentsFromPart,
  createOptionSunset,
  getOptionSunsets,
  createParityChange,
  editAppointment,
  editDelegation,
  editAppointmentTermination,
  editCeiling,
  editTransfer,
  editOptionExercise,
  editCapitalReduction,
  editConversion,
  editParityChange,
  editNominalValue,
  editVestingAcceleration,
  editVestingProrogation,
  editVestingSuspension,
  editFinancialEnding,
  editOptionSunset,
  editApproval,
  searchEvents,
  getCompanyAppointment,
  exportTransactionsPdf,
  getDelegation
}
