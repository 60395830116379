
  import { defineComponent, ref, watch } from 'vue'

  export default defineComponent({
    name: 'UplCheckbox',
    props: {
      checked: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: false,
        default: null
      },
      trueLabel: {
        type: String,
        required: false,
        default: null
      },
      size: {
        type: String,
        default: 'w-6 h-6'
      },
      icon: {
        type: String,
        default: 'bi-check text-xl'
      }
    },
    emits: ['onChange'],
    setup(props, { emit }) {
      const value = ref(props.checked)

      const handleChange = ($event: Event) => {
        value.value = ($event.target as HTMLInputElement).checked
        emit('onChange', value.value, props.name)
      }

      watch(props, (newProps) => {
        value.value = newProps.checked
      })

      return { value, handleChange }
    }
  })
