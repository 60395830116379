
  import { computed, onMounted, defineComponent, ref } from 'vue'
  import { getInstance } from '@/plugins/auth'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'

  export default defineComponent({
    setup(props) {
      const displayAll = ref(false)
      const store = useStore()
      const { t } = useI18n()
      const accessToken = ref<string | null>(null)
      const truncToken = computed(() => `${accessToken.value?.slice(0, 50)}...`)

      const copyText = () => {
        navigator.clipboard.writeText(accessToken.value || '')

        store.dispatch('toolbox/displayToast', {
          type: TYPE.DEFAULT,
          context: t('account.integrationContext'),
          message: t(`account.accountInformations.tokenCopied`)
        })
      }
      onMounted(async () => {
        accessToken.value = await getInstance().getTokenSilently()
      })
      return { displayAll, accessToken, truncToken, copyText }
    }
  })
