
  import { AccountApproval } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  export default defineComponent({
    name: 'AccountApprovalCard',
    props: {
      event: {
        type: Object as () => AccountApproval | undefined,
        default: null
      }
    },
    setup() {
      const store = useStore()
      const currency = computed(() => store.getters['organizations/currency'])

      return { moment, currency, formatNumber }
    }
  })
