<template>
  <div class="h-full">
    <Field v-slot="{ field }" v-model="search" type="text" name="search">
      <Input
        v-bind="field"
        :disabled="false"
        :placeholder="$t('global.search')"
      >
      </Input>
    </Field>
    <div class="alias-list h-4/5 overflow-scroll">
      <div class="flex justify-start items-center">
        <div
          class="
            bg-secondary5
            px-2
            py-1
            rounded-full
            flex
            justify-center
            items-center
            text-xs
            font-bold
          "
        >
          {{ usedVestings.length }}
        </div>
        <h2 class="text-sm text-secondary9 ml-2">
          {{ $t('vestingBook.labels.assigned') }}
        </h2>
      </div>
      <div class="border-l border-secondary4 my-2 vesting-ml mx-3">
        <h5
          v-for="vesting in usedVestings"
          :key="vesting.id"
          :class="`
            w-full
            p-2
            flex
            justify-start
            items-center
            cursor-pointer
            text-secondary8
            hover:text-secondary9
            ${
              currentVesting && currentVesting?.id === vesting?.id
                ? 'text-secondary9'
                : ''
            }
          `"
          @click="getVesting(vesting.id)"
        >
          <span class="h-3 w-3 rounded-full border-2 border-success"></span>
          <span class="text-sm ml-2">
            {{ vesting.name }}
          </span>
        </h5>
      </div>
      <div class="flex justify-start items-center">
        <div
          class="
            bg-secondary5
            px-2
            py-1
            rounded-full
            flex
            justify-center
            items-center
            text-xs
            font-bold
          "
        >
          {{ notUsedVestings.length }}
        </div>
        <h2 class="text-sm text-secondary9 ml-2">
          {{ $t('vestingBook.labels.unassigned') }}
        </h2>
      </div>
      <div class="border-l border-secondary4 my-2 mx-3 vesting-ml">
        <h5
          v-for="vesting in notUsedVestings"
          :key="vesting.id"
          :class="`p-2 flex justify-start items-center cursor-pointer ${
            currentVesting && currentVesting?.id === vesting?.id
              ? 'text-secondary9'
              : 'text-secondary8'
          }`"
          @click="getVesting(vesting.id)"
        >
          <span class="h-3 w-3 rounded-full border-2 border-success"></span>
          <span class="text-sm ml-2">{{ vesting.name }}</span>
        </h5>
      </div>
    </div>
    <div v-if="canDo" class="bottom-container bg-white text-xl">
      <Button
        :disabled="false"
        :label="$t('vestingBook.form.new')"
        size="medium"
        variant="secondary"
        class="w-full h-10 flex justify-center items-center"
        @click="initStockForm"
      >
        <i class="bi bi-plus text-md"></i>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import { CompanyFreeSharesVesting, CompanyVesting } from '@/services/api/models'
  import { useAcl } from 'vue-simple-acl/src'
  import moment from 'moment'

  export default defineComponent({
    components: { Button, Input, Field },
    setup() {
      const search = ref('')

      const store = useStore()
      const acl = useAcl()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-vestings'))
      )

      const optionsVestings = computed<CompanyVesting[]>(
        () => store.getters['organizations/vestings']
      )
      const freeSharesVestings = computed<CompanyFreeSharesVesting[]>(
        () => store.getters['organizations/freeSharesVestings']
      )

      const vestings = computed(() =>
        [...optionsVestings.value, ...freeSharesVestings.value].sort(
          (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
        )
      )

      const usedVestings = computed(() =>
        vestings.value.filter(
          (x: any) =>
            (x?.optionIssuanceParts || x?.freeShareIssuanceParts || []).length > 0
        )
      )
      const currentVesting = computed<CompanyVesting>(
        () => store.getters['organizations/vesting']
      )
      const listedVestings = ref(vestings.value)

      const notUsedVestings = computed(() =>
        vestings.value.filter(
          (x: any) =>
            (x?.optionIssuanceParts || x?.freeShareIssuanceParts || []).length ===
            0
        )
      )
      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })
        store.commit('organizations/SET_CURRENT_VESTING', null)
      }

      const getVestings = (name?: string) => {
        const filter: any = {}
        if (name) filter.name = name
        store.dispatch('organizations/GET_COMPANY_VESTINGS', {
          companyId: currentCompany.value.id,
          filter
        })

        store.dispatch('organizations/GET_FREE_SHARES_VESTINGS', {
          companyId: currentCompany.value.id,
          filter
        })
      }

      const getVesting = (vestingId: string) => {
        if (freeSharesVestings.value.map((x) => x.id).includes(vestingId)) {
          store.dispatch('organizations/GET_FREE_SHARES_VESTING', {
            companyId: currentCompany.value.id,
            vestingId
          })
        } else
          store.dispatch('organizations/GET_COMPANY_VESTING', {
            companyId: currentCompany.value.id,
            vestingId
          })
      }

      watch(search, (text: string) => {
        getVestings(text)
      })

      watch(vestings, (stocks) => {
        listedVestings.value = stocks
      })

      onBeforeMount(() => {
        getVestings()
      })

      return {
        currentCompany,
        currentVesting,
        search,
        canDo,
        notUsedVestings,
        usedVestings,
        initStockForm,
        getVesting
      }
    }
  })
</script>
<style lang="scss" scoped>
.vesting-ml {
  margin-left: 0.6em !important;
}
.bottom-container {
  @apply absolute bottom-0 right-0 left-0 z-10 h-20 flex justify-center items-center px-4;
}
</style>
