<template>
  <div
    :class="`flex my-2 items-center cursor-pointer transition-all ${
      currentStock ? 'bg-secondary5 px-2 text-white rounded' : ''
    }`"
    @click="onClick"
  >
    <div
      :class="`w-2 h-2 rounded-full border-2 border-${
        currentStock ? 'white' : ''
      }`"
      :style="`border-color: ${color}`"
    ></div>
    <div class="ml-2 flex items-center">
      <h4
        :class="`text-md text-blackp ${
          currentStock ? 'font-bold' : 'font-medium'
        }`"
      >
        {{ stock.alias }}
      </h4>
    </div>
  </div>
</template>

<script lang="ts">
  import { StockAlias } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    props: {
      stock: {
        type: Object as () => StockAlias,
        required: true
      },
      isCurrent: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: '#09065F'
      }
    },
    emits: ['onSelect'],
    setup(props, { emit }) {
      const currentStock = computed(() => props.isCurrent)
      const onClick = () => {
        emit('onSelect', props.stock)
      }
      return { currentStock, onClick }
    }
  })
</script>
