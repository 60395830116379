import axios from '../axios'
import { Pagination } from '@/store/modules/Organization/models'
import { AuditGetType, AuditType, AuditForm } from '@/types/forms'
import { company } from '../endpoints'

const createAudit = async (
  companyId: string,
  type: AuditType,
  typeDataId: string,
  values: AuditForm
) => {
  return await axios.post(company.audits(companyId, type, typeDataId), values)
}

const editAudit = async (
  companyId: string,
  type: AuditType,
  typeDataId: string,
  auditId: string,
  values: AuditForm
) => {
  return await axios.put(
    company.audit(companyId, type, typeDataId, auditId),
    values
  )
}

const editAuditStatus = async (
  companyId: string,
  type: AuditGetType,
  values: any
) => {
  return await axios.put(company.bulkAudit(companyId, type), values)
}
const getAudits = async (
  companyId: string,
  type: AuditGetType,
  pagination: Pagination,
  filter = {}
) => {
  const response = await axios.get(company.getAuditByType(companyId, type), {
    params: {
      ...filter,
      limit: pagination.limit,
      offset: pagination.limit * (pagination.currentPage - 1)
    }
  })
  return response
}

export { createAudit, getAudits, editAudit, editAuditStatus }
