<template>
  <div class="w-full rounded">
    <Form v-slot="{ values }" class="flex flex-col items-start w-full">
      <h4 class="text-blackp text-md capitalize text-left">{{ label }}</h4>
      <Field
        v-if="auditReasons.length > 0"
        v-model="newAudit.motives"
        name="motives"
      >
        <div class="w-full">
          <h4
            class="
              text-secondary9
              uppercase
              py-2
              text-xs text-left
              font-semibold
            "
          >
            {{ $t('extraction.firstlevel.state.labels.motive') }}
          </h4>
          <div
            v-for="reason in auditReasons"
            :key="reason"
            :class="`border border-inputBorder
            w-full
            rounded
            flex
            justify-start
            items-center
            p-2
            mb-2 bg-white
            cursor-pointer transition
            ${
              newAudit.motives.includes(reason)
                ? 'border border-warning text-warning'
                : ''
            }
            `"
            @click="toggleMotif(reason)"
          >
            <i
              :class="`${
                newAudit.motives.includes(reason)
                  ? 'text-white bi bi-x '
                  : 'text-secondary7 '
              }`"
            ></i>
            <label
              :class="`${
                newAudit.motives.includes(reason)
                  ? 'text-warning'
                  : 'text-secondary7 '
              } text-center mb-0`"
              :for="reason"
              >{{ $t(`extraction.firstlevel.state.labels.${reason}`) }}</label
            >
          </div>
        </div>
      </Field>
      <div class="w-full">
        <Field v-slot="{ field }" v-model="newAudit.comment" name="comment">
          <div class="flex justify-between items-center">
            <label
              class="text-secondary9 uppercase py-2 text-xs font-semibold"
              >{{ $t('audits.reasonOtherPlaceholder') }}</label
            >
            <Checkbox
              v-if="newAudit.id"
              v-model="newAudit.processed"
              name="processed"
              :label="$t('audits.processed')"
              @onChange="newAudit.processed = $event"
            />
          </div>
          <textarea
            v-model="newAudit.comment"
            v-bind="field"
            :placeholder="$t('audits.reasonOtherPlaceholderInput')"
            rows="4"
            name="comment"
            class="
              resize-none
              w-full
              border border-inputBorder
              rounded
              p-2
              focus:border-primary4
            "
            @blur="handleBlur(values)"
          ></textarea>
        </Field>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { DocumentAuditAnomalies, AuditForm, AuditType } from '@/types'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: { Form, Field, Checkbox },
    props: {
      reasons: { type: Array, required: false, default: () => [] },
      type: { type: String as () => AuditType, required: true },
      label: { type: String, required: false, default: null }
    },
    emits: ['change'],
    setup(props, { emit }) {
      const store = useStore()
      const newAudit = ref<AuditForm>({
        motives: [],
        comment: '',
        processed: false
      })
      const modalForm = computed(() => store.getters['organizations/form'])

      const auditReasons = computed<any[]>(() => props.reasons)
      const toggleMotif = (reason: DocumentAuditAnomalies) => {
        if (newAudit.value.motives.includes(reason)) {
          newAudit.value.motives = newAudit.value.motives.filter(
            (x) => x !== reason
          )
          emit('change', newAudit.value)
          return
        }
        newAudit.value.motives.push(reason)
        emit('change', newAudit.value)
      }
      const handleBlur = (values: AuditForm) => {
        emit('change', values)
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newAudit.value = {
            motives: [],
            comment: '',
            processed: false
          }
          return
        }
      })
      return { newAudit, auditReasons, toggleMotif, handleBlur }
    }
  })
</script>
