
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import Datepicker from '@/components/DatePicker/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Contact, ContactStatus, StockAlias } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import moment from 'moment'
  import { formatNumber, rgbToHex } from '@/utils/global'
  import { useI18n } from 'vue-i18n'
  import {
    STOCKTYPE,
    NumberType,
    MAINACTIONS,
    Captable,
    STOCKTYPESALIAS
  } from '@/types'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Checkbox from '@/components/Checkbox/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip } from 'element-plus'
  import Number from '@/components/Number/index.vue'
  import { CompanyModel } from '@/services/api/models'
  export default defineComponent({
    name: 'CompanyCaptable',
    components: {
      DataTable,
      Datepicker,
      Multiselect,
      Tag,
      DownloadButton,
      Button,
      Checkbox,
      Filter,
      EmptyState,
      Number,
      ContactBadge,
      DropdownStocks,
      Loader,
      ElTooltip
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const selectedContact = ref<string[]>([])
      const selectedGroup = ref('')
      const selectedStocks = ref<string[]>([])
      const picked = ref<any>(new Date())
      const selectedColumns = ref<string[]>([])
      const isDownloading = ref(false)

      const captableColumns = computed(() => [
        {
          label: t(`datatable.column.associates`),
          field: 'associates',
          custom: true,
          order: 1,
          selected: true,
          sortBy: 'contactName',
          type: 'text'
        },
        {
          label: t(`datatable.column.groups`),
          field: 'groups',
          custom: true,
          filterable: true,
          order: 2,
          selected: true,
          sortable: true,
          type: 'text'
        },
        {
          label: t(`datatable.column.stocks`),
          field: 'stocks',
          custom: true,
          selected: true,
          order: 3,
          sortable: true,
          type: 'text'
        },
        {
          label: t(`datatable.column.funding`),
          field: 'funding',
          custom: true,
          filterable: true,
          selected: true,
          order: 4,
          sortBy: 'amount',
          type: 'number'
        },
        {
          label: t(`datatable.column.nfd`),
          field: 'nfd',
          custom: true,
          order: 5,
          selected: true,
          sortBy: 'totalNFD',
          type: 'number'
        },
        {
          label: t(`datatable.column.nfd_p`),
          field: 'nfd_p',
          custom: true,
          order: 6,
          selected: false,
          sortable: true,
          type: 'number'
        },
        {
          label: t(`datatable.column.nfd_p_u`),
          field: 'nfd_p_u',
          custom: true,
          order: 7,
          selected: true,
          sortable: true,
          type: 'number'
        },
        {
          label: t(`datatable.column.fd`),
          field: 'fd',
          custom: true,
          selected: true,
          order: 8,
          sortBy: 'totalFD',
          type: 'number'
        },
        {
          label: t(`datatable.column.fd_p`),
          field: 'fd_p',
          order: 9,
          selected: false,
          custom: true,
          sortable: true,
          type: 'text'
        },
        {
          label: t(`datatable.column.fd_p_u`),
          field: 'fd_p_u',
          custom: true,
          order: 10,
          selected: true,
          sortable: true,
          type: 'text'
        }
      ])
      const filter = ref<any>({
        compositionAliasesId: [],
        optionAliasesId: [],
        stockAliasesId: []
      })

      const includeDelegationsAvailableBalance = ref(false)

      const isFiltered = computed(
        () =>
          selectedStocks.value.length > 0 ||
          selectedContact.value ||
          selectedGroup.value
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const isLoading = ref(false)
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const pagination = computed(
        () => store.getters['organizations/captablePagination']
      )
      const onPageChange = (page: number) => {
        // store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', page)
        getCaptable(page)
      }
      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const captable = computed<Captable>(
        () => store.getters['organizations/captable']
      )
      const groups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const allColumns = computed(() =>
        isFiltered.value
          ? captableColumns.value
          : captableColumns.value.filter((c) => c.selected)
      )
      const existingColumns = computed(() =>
        allColumns.value.filter((x) => !x.filterable)
      )

      selectedColumns.value = []
      const columns = computed(() =>
        [
          ...allColumns.value.filter((x) =>
            selectedColumns.value.includes(x.field)
          ),
          ...existingColumns.value
        ].sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      const handleFilterSelected = (value: string[]) => {
        selectedColumns.value = value
      }

      const resetFilters = () => {
        filter.value = {
          compositionAliasesId: [],
          optionAliasesId: [],
          stockAliasesId: [],
          contactId: []
        }
        picked.value = new Date().toISOString()
        selectedStocks.value = []
        selectedContact.value = []
        selectedGroup.value = ''
      }

      const updateStock = ({ value }: any) => {
        if (!value) {
          delete filter.value.optionAliasesId
          delete filter.value.stockAliasesId
          delete filter.value.compositionAliasesId
          getCaptable()
          return
        }
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }
      const updateGroup = (group: any) => {
        if (group === '') delete filter.value.contactGroupId
        else filter.value.contactGroupId = group

        selectedGroup.value = group
      }
      const updateContact = (contactId: string[]) => {
        selectedContact.value = contactId
        filter.value.contactId = contactId
      }

      const download = (key: MAINACTIONS) => {
        isDownloading.value = true
        const companyId = route.params.id
        const data: any = { ...filter.value, date: picked.value }
        if (includeDelegationsAvailableBalance.value)
          data.includeDelegationsAvailableBalance = true
        if (key === MAINACTIONS.CONTACTS_RECORD_XLS) {
          store.dispatch('organizations/EXPORT_COMPANY_CAPTABLE', {
            companyId,
            filter: data,
            name: 'Captable',
            onSuccess: () => {
              isDownloading.value = false
            },
            onError: () => {
              isDownloading.value = false
            }
          })
        } else {
          store.dispatch('organizations/EXPORT_COMPANY_CAPTABLE_PDF', {
            companyId,
            filter: data,
            name: 'Captable',
            onSuccess: () => {
              isDownloading.value = false
            },
            onError: () => {
              isDownloading.value = false
            }
          })
        }
      }

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id,
          filter
        })
      }

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }

      // const openStockDetails = (stock: StockAlias) => {
      //   store.dispatch('ui/toggleModal', {
      //     display: true,
      //     contentType: MAINACTIONS.STOCK
      //   })
      //   store.dispatch('organizations/INIT_MODAL_FORM', {
      //     ...store.getters['organizations/form'],
      //     stockType: stock.type,
      //     modalInit: true,
      //     initCurrentForm: false
      //   })

      //   console.log(
      //     stock.type,
      //     STOCKTYPESALIAS.STOCK,
      //     STOCKTYPE.SAMPLE,
      //     stock.type === STOCKTYPE.SAMPLE
      //       ? STOCKTYPESALIAS.STOCK
      //       : stock.type === STOCKTYPE.OPTION
      //       ? STOCKTYPESALIAS.OPTION
      //       : STOCKTYPESALIAS.COMPOSITION
      //   )

      //   store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
      //     companyId: currentCompany.value.id,
      //     stockId: stock.id,
      //     type: STOCKTYPESALIAS.OPTION
      //   })
      // }

      const getCaptable = async (page = 1, companyId = route.params.id) => {
        const data: any = { ...filter.value, date: picked.value }
        if (includeDelegationsAvailableBalance.value)
          data.includeDelegationsAvailableBalance = true
        else delete data.includeDelegationsAvailableBalance
        await store.dispatch('organizations/GET_CAPTABLE', {
          companyId,
          filter: data,
          page,
          onSuccess: () => {
            isLoading.value = false
          }
        })
      }

      onBeforeMount(async () => {
        getStocks()
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0
          }
        })
      })

      onMounted(async () => {
        isLoading.value = true
        getCaptable()
      })

      onBeforeUnmount(() =>
        store.commit('organizations/SET_CAPTABLE', {
          metadata: { count: 0, currentPage: 1, lastPage: 1 },
          data: {
            lines: [],
            allFD: 0,
            allNFD: 0,
            percentageFD: 0,
            totalAmount: 0,
            totalFD: 0,
            totalNFD: 0
          }
        })
      )

      watch(
        [
          selectedStocks,
          selectedGroup,
          () => selectedContact.value,
          () => includeDelegationsAvailableBalance.value
        ],
        () => {
          store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)
          getCaptable()
        }
      )

      watch(picked, (val: any) => {
        if (val) filter.value.date = moment(val).toISOString()
        else delete filter.value.date
        getCaptable()
      })

      watch(
        () => route.params.id,
        (val) => {
          filter.value = {}
          selectedGroup.value = ''
          selectedContact.value = []
          selectedStocks.value = []
          picked.value = new Date()
          getCaptable(1, val)
        }
      )

      return {
        picked,
        captable,
        groups,
        isDownloading,
        selectedStocks,
        selectedContact,
        selectedGroup,
        contacts,
        columns,
        isLoading,
        pagination,
        isFiltered,
        companyGroups,
        handleContactSearch,
        updateStock,
        updateContact,
        updateGroup,
        formatNumber,
        onPageChange,
        download,
        rgbToHex,
        includeDelegationsAvailableBalance,
        moment,
        // openStockDetails,
        availableColumns,
        handleFilterSelected,
        selectedColumns,
        resetFilters,
        currentCompany,
        NumberType
      }
    }
  })
