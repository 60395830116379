import axios, { AxiosError } from 'axios'
import { getInstance } from '@/plugins/auth'
import store from '@/store'
import * as Sentry from '@sentry/vue'

axios.interceptors.request.use(
  async (config) => {
    const client = getInstance()
    if (await client.isAuthenticated()) {
      const accessToken = await getInstance().getTokenSilently()

      config.headers.Authorization = `Bearer ${accessToken}`
    }
    config.headers['Accept-Language'] = store.getters['config/lang']
    if (config.data instanceof FormData) {
      config.headers = {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
    }
    config.timeout = 600000
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    const result = { ...response }
    return Promise.resolve(response.data)
  },
  (error: AxiosError) => {
    // throw error.response?.status;
    Sentry.captureException(error)
    return Promise.reject(error.response)
  }
)

export default axios
