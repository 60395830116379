
  import { GlobalConfig } from '@/types'
  import conf from '@/config'

  import { defineComponent, inject, onMounted } from 'vue'
  export default defineComponent({
    name: 'AuthLogout',

    setup() {
      const config = conf as GlobalConfig
      const Auth: any = inject('Auth')

      onMounted(() => {
        Auth.logout({ returnTo: config.auth0Options.redirectUri })
      })

      return {}
    }
  })
