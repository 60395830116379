<template>
  <div :class="`${grid ? 'grid grid-cols-6 gap-4' : ''} ${classes}`">
    <div :class="`${inline ? 'flex' : 'col-span-3'} `">
      <div
        :class="`${
          inline ? (isRelatedToCommittee ? 'w-1/2' : 'w-full') : 'col-span-1'
        }`"
      >
        <label
          v-if="hasLabel"
          class="capitalize text-xs font-semibold text-secondary8 text-left"
          >{{ data.label }}</label
        >
        <Field v-slot="{ field }" v-model="formValue" :name="data.name">
          <Multiselect
            v-bind="field"
            :value="formValue"
            :show-labels="true"
            mode="single"
            :options="functions"
            :placeholder="$t('events.elements.selectQuality')"
            :groups="false"
            class="w-full h-10"
            group-label="name"
            :name="data.name"
            :open-direction="openDirection"
            @search-change="searchText = $event"
            @change="updateValue"
          >
            <template #noOptions>
              <span v-if="false"> {{ $t('events.elements.noVesting') }} </span>
              <span v-else>{{ $t('events.elements.search') }}</span>
            </template>

            <template #noResults>
              <span>{{ $t('global.placeholder.noResult') }}</span>
            </template>
          </Multiselect>
        </Field>
      </div>
      <div
        :class="`${
          inline
            ? formValue !== '' && isRelatedToCommittee
              ? 'w-1/2 ml-5'
              : 'w-0'
            : `col-span-1 mt-${
                data.isNeeded && formValue && isRelatedToCommittee ? 4 : 0
              }`
        }`"
      >
        <slot :name="data.name" :value="isRelatedToCommittee" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, toRefs, onBeforeMount } from 'vue'
  import { Field } from 'vee-validate'
  import { useRoute } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { UpFunction } from '@/services/api/models'
  export default defineComponent({
    name: 'DropdownFunctions',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: '',
          isNeeded: false
        })
      },
      inline: { default: true, type: Boolean },
      allData: {
        default: () => ({}),
        type: Object
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'top'
      },
      grid: {
        default: true,
        type: Boolean
      },
      classes: {
        default: '',
        type: String
      },
      value: {
        default: '',
        type: String
      },
      callback: {
        type: Function,
        default: () => ({})
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { data } = toRefs(props)
      const route = useRoute()

      // allData will be useful for filter if delegation is selected for example
      const formValue = ref(props.value)
      const searchText = ref('')

      const functions = computed<UpFunction[]>(() =>
        store.getters['organizations/functions'].filter(props.callback)
      )

      const updateValue = (value: any) => {
        formValue.value = value
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      const isRelatedToCommittee = computed(
        () =>
          functions.value.find(
            (item: UpFunction) => item.id === formValue.value
          )?.isRelatedToCommittee || false
      )

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_FUNCTIONS')
        await store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: route.params.id
        })
      })

      return {
        formValue,
        searchText,
        functions,
        updateValue,
        isRelatedToCommittee
      }
    }
  })
</script>
