
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import { CompanyCommittee } from '@/services/api/models'
  import { CompanyCommitteeFormData } from '@/types'
  import { committeeSchema } from '@/utils'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_COMPANY_COMMITTEE,
    EDIT_COMPANY_COMMITTEE
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'UplCommittee',
    components: {
      Form,
      Field,
      Input,
      FormActions,
      Button,
      UplDatePicker,
      ElPopconfirm
    },

    setup() {
      const store = useStore()
      const { t } = useI18n()
      const newCommittee = ref<CompanyCommitteeFormData>({
        denomination: '',
        creationDate: new Date().toISOString(),
        minMembers: 1,
        maxMembers: 1,
        documentsId: [],
        isDraft: true
      })

      const resetForm = () => {
        newCommittee.value = {
          isDraft: false,
          denomination: '',
          creationDate: new Date().toISOString(),
          minMembers: 1,
          maxMembers: 1,
          documentsId: []
        }
      }
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-committees'))
      )
      const modalForm = computed(() => store.getters['organizations/form'])

      const currentCommittee = computed<CompanyCommittee>(
        () => store.getters['organizations/currentCommittee']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = committeeSchema

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleForm = (values: CompanyCommitteeFormData) => {
        let edition = false
        let action = CREATE_COMPANY_COMMITTEE
        let formData = {
          companyId: currentCompany.value.id,
          data: { ...values, name: values.denomination, isDraft: false },
          committeeId: ''
        }

        if (currentCommittee.value && currentCommittee.value.id) {
          edition = true
          formData['committeeId'] = currentCommittee.value.id
          action = EDIT_COMPANY_COMMITTEE
        }

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(`committeeBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('committeeBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('committeeBook.form.title'),
              message: t(
                `committeeBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          }
        })
      }

      const deleteCompanyCommittee = () => {
        store.dispatch(`organizations/REMOVE_COMPANY_COMMITTEE`, {
          companyId: currentCompany.value.id,
          committeeId: currentCommittee.value.id,
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('committeeBook.form.title'),
              message: t(`committeeBook.apiResponse.deleted`)
            })
          },
          onError: (error = null) => {
            const message = error || t(`committeeBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('committeeBook.form.title'),
              message
            })
          }
        })
      }
      const handleReset = () => {
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
      }

      watch(currentCommittee, (newValue) => {
        if (newValue) {
          newCommittee.value = {
            denomination: newValue.denomination,
            creationDate: newValue.creationDate,
            minMembers: newValue.minMembers,
            maxMembers: newValue.maxMembers,
            documentsId: newValue.documentsId,
            isDraft: false
          }
        }
      })

      watch(modalForm, (newValues) => {
        if (newValues.initCurrentForm) resetForm()
      })

      return {
        newCommittee,
        canDo,
        schema,
        currentCommittee,
        handleForm,
        deleteCompanyCommittee,
        handleReset
      }
    }
  })
