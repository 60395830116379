import { RootState } from '@/store'
import { COLLAPSE_DOC_PREVIEW } from './models'

import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from 'vuex'

import {
  UIState,
  UIMutations,
  UIGetters,
  SET_MODAL_SETTINGS,
  DISPLAY_PREVIEW
} from './models'

// const context = `[UI:]`

const state: UIState = {
  displayDocumentPreview: true,
  documentPreviewCollapsed: true,
  modalSettings: {
    display: false,
    contentType: 0
  }
}

const mutations: MutationTree<UIState> & UIMutations = {
  [SET_MODAL_SETTINGS](state, settings) {
    state.modalSettings = settings
  },
  [DISPLAY_PREVIEW](state, display: boolean) {
    // state.displayDocumentPreview = display
  },
  [COLLAPSE_DOC_PREVIEW](state, collpase: boolean) {
    state.documentPreviewCollapsed = collpase
  }
}

const getters: GetterTree<UIState, RootState> & UIGetters = {
  isModalOpen: (state) => state.modalSettings.display,
  modalContentType: (state) => state.modalSettings.contentType,
  displayDocumentPreview: (state) => state.displayDocumentPreview,
  documentPreviewCollapsed: (state) => state.documentPreviewCollapsed
}

const actions: ActionTree<UIState, RootState> = {
  toggleModal(
    { commit }: ActionContext<UIState, RootState>,
    { display, contentType }
  ) {
    commit(SET_MODAL_SETTINGS, { display, contentType })
  }
}

const module: Module<UIState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default module
