
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SectionGeneric',
    props: {
      title: { type: String },
      classes: {
        type: String,
        default: ''
      }
    },
    setup() {
      return {}
    }
  })
