import { DocumentCategory, EventCategory } from '@/services/api/models'
import {
  StockCategory,
  OptionStockCategory,
  OrganCategory
} from '@/services/api/models/company'
import { LegalStatus, ContactGroup } from '@/services/api/models/company'
import { TYPE } from 'vue-toastification'
import { Role } from '@/services/api/models/user'
//Mutations
export const SET_GROUPS = 'SET_GROUPS'
export const SET_FORM_TYPES = 'SET_FORM_TYPES'
export const SET_DOCUMENT_GROUP_TYPES = 'SET_DOCUMENT_GROUP_TYPES'
export const SET_COLORS = 'SET_COLORS'
export const SET_COMPANY_COMMITTEES = 'SET_COMPANY_COMMITTEES'
export const SET_STOCKS = 'SET_STOCKS'
export const SET_EVENT_GROUPS = 'SET_EVENT_GROUPS'
export const SET_PROFILE_ALIAS = 'SET_PROFILE_ALIAS'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_COMPANY = 'SET_COMPANY'
export const SET_DOCUMENT_CATEGORIES = 'SET_DOCUMENT_CATEGORIES'
export const SET_EVENTS_CATEGORIES = 'SET_EVENTS_CATEGORIES'
export const SET_STOCK_CATEGORIES = 'SET_STOCK_CATEGORIES'
export const SET_OPTION_CATEGORIES = 'SET_OPTION_CATEGORIES'
export const SET_ORGAN_CATEGORIES = 'SET_ORGAN_CATEGORIES'
export const SET_LEGAL_STATUS = 'SET_LEGAL_STATUS'
export const SET_CONTACT_GROUPS = 'SET_CONTACT_GROUPS'
export const SET_PDF_META = 'SET_PDF_META'
export const SET_ROLES = 'SET_ROLES'

export const SET_EVENT_TABLE_COLUMNS = 'SET_EVENT_TABLE_COLUMNS'

//Actions
export const GET_GROUPS = 'GET_GROUPS'
export const GET_FORM_TYPES = 'GET_FORM_TYPES'
export const GET_DOCUMENT_GROUP_TYPES = 'GET_DOCUMENT_GROUP_TYPES'
export const GET_COLORS = 'GET_COLORS'
export const GET_COMPANY_COMMITTEES = 'GET_COMPANY_COMMITTEES'
export const GET_STOCKS = 'GET_STOCKS'
export const GET_EVENT_GROUPS = 'GET_EVENT_GROUPS'
export const GET_PROFILE_ALIAS = 'GET_PROFILE_ALIAS'
export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_DOCUMENT_CATEGORIES = 'GET_DOCUMENT_CATEGORIES'
export const GET_STOCK_CATEGORIES = 'GET_STOCK_CATEGORIES'
export const GET_OPTION_CATEGORIES = 'GET_OPTION_CATEGORIES'
export const GET_ORGAN_CATEGORIES = 'GET_ORGAN_CATEGORIES'
export const GET_LEGAL_STATUS = 'GET_LEGAL_STATUS'
export const GET_CONTACT_GROUPS = 'GET_CONTACT_GROUPS'
export const GET_ROLES = 'GET_ROLES'

export type Group = {
  uid: number
  name: string
}
export interface ToastParam {
  type: TYPE
  message: string
  context: string
  loader: boolean
  loadingIcon: boolean
}
export type FormType = {
  uid: number
  name: string
}

export type Color = {
  uid: number
  name: string
}

export type DocumentGroupType = {
  uid: number
  name: string
}
export type EventGroup = {
  uid: number
  name: string
}

export type Committee = {
  uid: number
  name: string
}

export type Stock = {
  uid: number
  name: string
}

export type Company = {
  uid: number
  name: string
}

export type ProfileAlias = {
  uid: number
  name: string
}
export enum PDF_FIT {
  AUTO = 'auto',
  WIDTH = 'width'
}
export interface PDFMeta {
  page: number
  scale: number
  fit: PDF_FIT
}

export interface ToolboxState {
  eventCategories: Array<EventCategory>
  documentCategories: DocumentCategory[]
  stockCategories: StockCategory[]
  optionCategories: OptionStockCategory[]
  organCategories: OrganCategory[]
  roles: Role[]
  groups: Group[]
  currentDocument: Document | null
  formTypes: FormType[]
  documentGroupTypes: DocumentGroupType[]
  colors: Color[]
  eventGroups: EventGroup[]
  stocks: Stock[]
  companies: Company[]
  company: Company | null
  profileAlias: ProfileAlias[]
  legalsStatus: LegalStatus[]
  contactGroups: ContactGroup[]
  pdfMeta: PDFMeta
  eventSelectedColumns: string[]
}

export interface ToolboxMutations {
  [SET_COLORS](state: ToolboxState, colors: Color[]): void
  [SET_DOCUMENT_CATEGORIES](
    state: ToolboxState,
    categories: DocumentCategory[]
  ): void
  [SET_GROUPS](state: ToolboxState, groups: Group[]): void
  [SET_FORM_TYPES](state: ToolboxState, types: FormType[]): void
  [SET_DOCUMENT_GROUP_TYPES](
    state: ToolboxState,
    types: DocumentGroupType[]
  ): void
  [SET_STOCKS](state: ToolboxState, stocks: Stock[]): void
  [SET_EVENT_GROUPS](state: ToolboxState, groups: EventGroup[]): void
  [SET_ROLES](state: ToolboxState, alaises: Role[]): void
}

export interface ToolboxGetters {
  groups(state: ToolboxState): Group[]
  companies(state: ToolboxState): Company[]
  documentCategories(state: ToolboxState): DocumentCategory[]
}
