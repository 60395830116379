import * as yup from 'yup'
export const committeeSchema = yup.object().shape({
  denomination: yup.string().required('denominationRequired'),
  creationDate: yup
    .string()
    .required('creationDateRequired')
    .typeError('dateInvalid'),
  minMembers: yup.number().required('minMembersRequired'),
  maxMembers: yup.number().required('maxMembersRequired')
})
