<template>
  <Form
    v-slot="{ errors, meta, resetForm, values: formValues }"
    :validation-schema="schema"
    @submit="handleForm"
  >
    <div class="ml-3 pl-4 mt-4 border-l border-secondary5">
      <div class="w-3/5 mt-3">
        <label class="text-xs uppercase font-semibold" for="stockType">{{
          $t('stockBook.form.primaryStock')
        }}</label>
        <Field
          v-slot="{ field }"
          v-model="newStock.mainStockAliasId"
          name="mainStockAliasId"
        >
          <Multiselect
            id="mainStockAliasId"
            v-bind="field"
            v-model="newStock.mainStockAliasId"
            mode="single"
            name="mainStockAliasId"
            :filterResults="false"
            group-options-field="children"
            :options="filteredListedStock"
            :placeholder="$t('stockBook.form.primaryStockPlaceholder')"
            :groups="true"
            @change="newStock.mainStockAliasId = $event"
            @search-change="handleSearch"
          />
        </Field>
      </div>
      <div
        class="
          stock__aliasSecondary
          form-group
          flex flex-col
          justify-center
          items-start
        "
      >
        <div
          v-for="(data, index) in newStock.underlayingOptions"
          :key="index"
          class="w-full"
        >
          <div class="stock__aliasSecondary__row">
            <div class="stock__aliasSecondary__rowSubStock form-group ml-5" />
          </div>
          <div
            class="
              w-full
              px-2
              my-2
              stock__aliasSecondary__row
              flex
              justify-start
              items-center
            "
          >
            <i class="mt-3 bi-arrow-right flex items-center justify-center" />
            <div class="form-group ml-3 h-16">
              <label class="text-xs uppercase font-semibold">{{
                $t('stockBook.form.quantity')
              }}</label>
              <Field
                v-slot="{ field }"
                v-model="newStock.underlayingOptions[index].parity"
                :name="`underlayingOptions[${index}].parity`"
              >
                <Input
                  v-model="newStock.underlayingOptions[index].parity"
                  v-bind="field"
                  :disabled="false"
                  type="number"
                  :no-error="true"
                  :name="`underlayingOptions[${index}].parity`"
                  :placeholder="$t('stockBook.form.quantity')"
              /></Field>
            </div>
            <div class="form-group ml-3 h-16 ml-3 w-1/3">
              <label class="text-xs uppercase font-semibold">{{
                $t('stockBook.form.underlayingOption')
              }}</label>
              <Field
                v-slot="{ field }"
                v-model="newStock.underlayingOptions[index].underlayingOptionId"
                :name="`underlayingOptions[${index}].underlayingOptionId`"
              >
                <Multiselect
                  v-bind="field"
                  id="stockType"
                  v-model="
                    newStock.underlayingOptions[index].underlayingOptionId
                  "
                  mode="single"
                  :options="optionAliases"
                  :placeholder="$t('stockBook.form.categoryPlaceholder')"
                  :groups="false"
                  class="h-10"
                  @search-change="handleOptionSearch"
                />
              </Field>
            </div>

            <div class="ml-1 mt-5 mb-2">
              <Button
                :disabled="false"
                class="
                  flex
                  justify-center
                  items-center
                  rounded
                  h-10
                  hover:bg-secondary5
                "
                variant="tertiary"
                size="medium"
                @click="deleteComposedStock(index)"
              >
                <i class="bi bi-trash text-xl text-error"></i>
              </Button>
            </div>
          </div>
        </div>
        <Button
          class="ml-3 mt-3 px-2"
          variant="secondary"
          size="medium"
          :label="$t('stockBook.form.addComposite')"
          @click="addCompositionStock"
        >
          <i class="bi bi-plus text-md"></i>
        </Button>
      </div>
    </div>
    <!-- SECTION ALIAS -->
    <section class="stock__section w-full mt-4">
      <h3>Alias</h3>
      <div class="stock__aliasPrincipal form-group flex justify-start w-full">
        <div class="flex flex-col w-3/5">
          <label class="text-sm">{{ $t('global.principalAlias') }}</label>
          <Field
            v-slot="{ field }"
            v-model="newStock.alias"
            type="text"
            name="alias"
          >
            <Input
              v-model="newStock.alias"
              placeholder="(ex: BSABSA-2021)"
              v-bind="field"
              name="alias"
              class="h-10"
              type="text"
              :disabled="false"
            />
          </Field>
        </div>
      </div>
    </section>
    <FormActions
      v-if="
        $acl.role('admin') ||
        ($acl.permission('is-manager') && $acl.permission('write-stocks'))
      "
      :errors="errors"
      :meta="meta"
      @reset-form="resetForm"
      @onSubmit="handleForm(formValues)"
    />
  </Form>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { compositionStockSchema } from '@/utils/schema'
  import {
    CompositeStockTypeFormData,
    STOCKTYPE,
    STOCKTYPESALIAS
  } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    CompositionStockAlias,
    CompositionUnderlayingOption,
    OptionStockAlias,
    StockAlias,
    StockCategory
  } from '@/services/api/models'
  import { groupItemBy } from '@/utils/global'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import {
    STOCK_PRIMARY,
    STOCK_SECONDARY
  } from '@/store/modules/Organization/models'

  export default defineComponent({
    components: { Form, Input, Field, Button, Multiselect, FormActions },
    props: {
      values: {
        type: Object as () => CompositionStockAlias,
        required: false,
        default: () => ({})
      }
    },
    emits: ['saveChanges'],
    setup(props, { emit }) {
      const store = useStore()
      const newStock = ref<CompositeStockTypeFormData>({
        underlayingOptions:
          props.values?.underlayingOptions?.map(
            (x: CompositionUnderlayingOption) => ({
              underlayingOptionId: x.underlayingOption?.id,
              parity: parseFloat(x.parity.toString())
            })
          ) || [],
        alias: props.values?.alias || '',
        mainStockAliasId: props.values?.mainStockAlias?.id || '',
        inValuation: props.values?.inValuation || false,
        inNFD: props.values?.inNFD || false,
        inFD: props.values?.inNFD || false,
        inESOP: props.values?.inESOP || false
      })

      const resetForm = () => {
        newStock.value = {
          underlayingOptions: [],
          alias: null,
          mainStockAliasId: '',
          inValuation: false,
          inNFD: false,
          inFD: false,
          inESOP: false
        }
      }

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      watch(mustInitForm, (val) => {
        if (val) resetForm()
      })

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const stockAliases = ref<StockAlias[]>([])

      const optionAliases = ref<OptionStockAlias[]>([])
      const stockCategories = computed<StockCategory[]>(
        () => store.getters['toolbox/stockCategories']
      )

      const filteredListedStock = computed<any[]>(() =>
        Array.from(
          groupItemBy(
            stockAliases.value,
            (stock: StockAlias) => stock.stockCategory?.id
          )
        ).map(([key, value]) => {
          const category = stockCategories.value.find((x) => x.id === key)
          return {
            ...category,
            children: [...value]
          }
        })
      )

      const handleSearch = (value: string) => {
        getStockAlias(value)
      }

      const handleForm = (formValues: CompositeStockTypeFormData) => {
        emit(
          'saveChanges',
          formValues,
          STOCKTYPESALIAS.COMPOSITION,
          props.values && props.values.id
        )
      }

      const schema = compositionStockSchema

      const addCompositionStock = () => {
        newStock.value.underlayingOptions?.push({
          underlayingOptionId: '',
          parity: 0
        })
      }

      const deleteComposedStock = (index: number) => {
        newStock.value.underlayingOptions =
          newStock.value.underlayingOptions?.filter((x, i) => index !== i)
      }
      const handleOptionSearch = (search: string) => {
        getOptions(search)
      }

      const getStockAlias = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_STOCK_ALIAS', {
          companyId: currentCompany.value.id,
          filter,
          onSuccess: (data: StockAlias[]) => {
            stockAliases.value = [...data].map((x) => ({
              ...x,
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.SAMPLE,
              class: STOCK_PRIMARY
            }))
          }
        })
      }

      const getOptions = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: currentCompany.value?.id,
          filter,
          onSuccess: (data: OptionStockAlias[]) => {
            optionAliases.value = [...data].map((x) => ({
              ...x,
              alias: x.alias.toUpperCase(),
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.OPTION,
              class: STOCK_SECONDARY
            }))
          }
        })
      }

      watch(
        () => props.values,
        (newProps) => {
          if (newProps) {
            if (
              !stockAliases.value
                .map((x) => x.id)
                .includes(newProps.mainStockAlias.id)
            )
              stockAliases.value.push({
                ...newProps.mainStockAlias,
                name: newProps.mainStockAlias.alias
              })
            newStock.value.alias = newProps.alias
            newStock.value.mainStockAliasId = newProps.mainStockAlias.id
            newStock.value.underlayingOptions = newProps.underlayingOptions.map(
              (x: CompositionUnderlayingOption) => {
                if (
                  !optionAliases.value
                    .map((x) => x.id)
                    .includes(x.underlayingOption.id)
                )
                  optionAliases.value.push({
                    ...x.underlayingOption,
                    name: x.underlayingOption.alias
                  })
                return {
                  underlayingOptionId: x.underlayingOption.id,
                  parity: parseFloat(x.parity.toString())
                }
              }
            )
            newStock.value.inValuation = newProps?.inValuation || false
            newStock.value.inNFD = newProps?.inNFD || false
            newStock.value.inFD = newProps?.inFD || false
            newStock.value.inESOP = newProps?.inESOP || false
          }
        }
      )
      onMounted(() => {
        getStockAlias()
        getOptions()
      })

      return {
        schema,
        newStock,
        stockAliases,
        optionAliases,
        filteredListedStock,
        addCompositionStock,
        deleteComposedStock,
        handleForm,
        handleSearch,
        handleOptionSearch
      }
    }
  })
</script>
