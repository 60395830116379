interface RouteAccess {
  routeName: string
  key: string
  roles: Array<string>
}

export interface MenuRoleBasedAccessProps {
  [key: string]: RouteAccess
}

export enum ErrorType {
  SUCCESS,
  WARNING,
  ERROR,
  DEFAULT
}

export enum ErrorDisplay {
  GLOBAL,
  CONTEXTUAL
}
