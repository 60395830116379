<template>
  <div>
    <div v-if="loading" class="treeview__hint-loading text-xs relative">
      <Loader />
    </div>

    <div v-if="!loading">
      <div
        v-for="e in eventsList"
        :key="e.id"
        class="border cursor-pointer rounded border-secondary6 px-3 py-2 mb-2"
        @click.prevent.stop="navigate(e.id)"
      >
        <div class="flex justify-between">
          <div class="event__infos flex flex-col justify-left items-left">
            <div class="font-bold text-left">
              {{ $t(`events.labels.${e.type}`) }}
              <span
                v-if="e.isDraft"
                class="border border-error rounded my-2 py-1 px-2 text-error"
              >
                {{ $t('events.draftEvent') }}
              </span>
            </div>
            <div class="text-primary4 text-left">
              <p v-if="e.date" class="py-2 font-bold">
                {{ moment.utc(e.date).format('L') }}
              </p>
              <p v-else>-</p>
            </div>
          </div>
          <div class="flex justify-end text-sm">
            <Tag
              v-if="e.stockIssuance?.stockAlias"
              :id="e.stockIssuance?.stockAlias?.id"
              :value="e.stockIssuance?.stockAlias?.alias"
              :text="e.stockIssuance?.stockAlias?.alias.toUpperCase()"
              :with-initials="false"
              :with-remove="false"
              :color="rgbToHex(e.stockIssuance?.stockAlias.colorCode)"
            />
            <Tag
              v-if="e.optionIssuance?.optionAlias"
              :id="e.optionIssuance?.optionAlias?.id"
              :value="e.optionIssuance?.optionAlias?.alias"
              :text="e.optionIssuance?.optionAlias?.alias.toUpperCase()"
              :with-initials="false"
              :with-remove="false"
              :color="rgbToHex(e.optionIssuance?.optionAlias.colorCode)"
            />

            <Tag
              v-if="e.compositionIssuance?.compositionAlias"
              :id="e.compositionIssuance?.compositionAlias?.id"
              :value="e.compositionIssuance?.compositionAlias?.alias"
              :text="
                e.compositionIssuance?.compositionAlias?.alias.toUpperCase()
              "
              :with-initials="false"
              :with-remove="false"
              :color="
                rgbToHex(e.compositionIssuance?.compositionAlias.colorCode)
              "
            />
            <div v-if="e.appointment" class="flex flex-col items-end px-2">
              <h4 v-if="e.appointment?.function" class="text-md text-blackp">
                {{ e.appointment?.function.name }}
              </h4>
              <p v-if="e.appointment?.committee" class="text-secondary7">
                {{ e.appointment?.committee.denomination }}
              </p>
              <p v-else class="text-secondary7">-</p>
            </div>

            <div
              v-if="e.appointmentTermination"
              class="flex flex-col items-end px-2"
            >
              <h4
                v-if="e.appointmentTermination?.appointment?.function"
                class="text-md text-blackp"
              >
                {{ e.appointmentTermination?.appointment?.function.name }}
              </h4>
              <p
                v-if="e.appointmentTermination?.appointment?.committee"
                class="text-secondary7"
              >
                {{
                  e.appointmentTermination?.appointment?.committee.denomination
                }}
              </p>
              <p v-else class="text-secondary7">-</p>
            </div>
          </div>
        </div>
      </div>
      <VueEternalLoading :load="load" :isInitial="false">
        <template #loading>
          <p class="text-primary">
            {{ $t('global.loading') }}
          </p>
        </template>

        <template #no-more>
          <p class="text-primary">
            {{ $t('global.noMore') }}
          </p>
        </template>

        <template #no-results>
          <p></p>
        </template>
      </VueEternalLoading>
    </div>
    <EmptyState
      v-if="!eventsList.length && !loading"
      class="d-block mx-auto py-20"
      :subtitle="$t('global.noData')"
    />
  </div>
</template>

<script lang="ts">
  import { watch, defineComponent, computed, ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { LoadAction, VueEternalLoading } from '@ts-pro/vue-eternal-loading'
  import { Pagination } from '@/store/modules/Organization/models'
  import Loader from '../Loader/index.vue'
  import { rgbToHex } from '@/utils'
  export default defineComponent({
    components: {
      EmptyState,
      VueEternalLoading,
      Loader,
      Tag
    },
    props: {
      search: { type: String, default: '' }
    },
    emits: ['dataCount', 'close', 'loading'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const events = computed(
        () => store.getters['organizations/searchedEvents']
      )

      const eventsList = ref<any[]>([])

      const isLoading = computed(
        () => store.getters['organizations/searchingEvents']
      )

      const pagination = computed<Pagination>(
        () => store.getters['organizations/searchedEventsPagination']
      )
      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )
      const loading = ref(false)
      const navigate = (id: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId: id }
        })

        emit('close')
      }
      const getEvents = (search: string | null) => {
        loading.value = true
        emit('loading', 'events', true)

        store.dispatch('organizations/SEARCH_EVENTS', {
          companyId: route.params.id,
          offset: 0,
          limit: 10,
          filter: { q: search },
          onSuccess: (resp: any) => {
            eventsList.value = resp.data
            loading.value = false
            emit('loading', 'events', false)
          }
        })
      }

      const load = async ({ loaded }: LoadAction) => {
        if (eventsList.value.length >= pagination.value.total) loaded(0, 0)

        const response: any = await store.dispatch(
          'organizations/SEARCH_EVENTS',
          {
            companyId: route.params.id,
            filter: { q: props.search },
            offset: eventsList.value.length
          }
        )
        if (response && response.data) {
          eventsList.value.push(...response.data)
          loaded(eventsList.value.length, pagination.value.lastPage)
        }
        emit('dataCount', 'events', eventsList.value.length || 0)
        emit('loading', 'events', false)
        loading.value = false
      }

      watch(
        () => props.search,
        (val) => {
          getEvents(val)
        }
      )

      watch(
        () => eventsList.value,
        (val) => {
          emit('dataCount', 'events', val?.length || 0)
        }
      )

      onMounted(() => {
        emit('dataCount', 'events', eventsList.value.length || 0)
      })

      return {
        events,
        noMore,
        isLoading,
        eventsList,
        loading,
        load,
        rgbToHex,
        navigate,
        moment
      }
    }
  })
</script>
