<template>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.label') }}
      </h1>
      <p v-if="event.label" class="text-blackp text-sm text-left">
        {{ event.label }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.quantity') }}
      </h1>

      <p class="text-blackp text-sm text-left">
        {{ event.quantity }}
      </p>
    </div>
    <div class="p-3 border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">-</h1>

      <p class="text-blackp text-sm text-left">-</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { CommonCeiling } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import moment from 'moment'
  export default defineComponent({
    name: 'CommonCeilingCard',
    props: {
      event: {
        type: Object as () => CommonCeiling | undefined,
        required: true
      }
    },
    setup() {
      return { moment }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
