// Account
import About from '@/views/About.vue'
import Home from '@/views/index.vue'
import Connect from '@/views/auth/connect.vue'
import AuthPassword from '@/views/auth/password.vue'
import AuthPasswordEmail from '@/views/auth/renew.vue'
import AuthPasswordReset from '@/views/auth/reset-password.vue'
import Logout from '@/views/auth/logout.vue'
import Unauthorized from '@/views/auth/unauthorized.vue'
import Redirecting from '@/views/redirect.vue'
// Company routes
// Account
import Account from '@/views/Account/index.vue'
import AccountInformations from '@/views/Account/Information.vue'
import AccountCompanyDetails from '@/views/Account/tabs/company.vue'
import AccountCompanies from '@/views/Account/companies.vue'
import AccountUsers from '@/views/Account/users.vue'
import AccountAlerts from '@/views/Account/alerts.vue'
import AccountPassword from '@/views/Account/password.vue'
import AccountTeam from '@/views/Account/team.vue'
import TechnicalIntegration from '@/views/Account/tech.vue'
import AccountOrganizationalChart from '@/views/Account/chart.vue'
//Company
import CompanyOverview from '@/views/Company/home.vue'
import CompanyCaptable from '@/views/Company/captable.vue'
import Company from '@/views/Company/index.vue'
import CompanyUpload from '@/views/Company/upload.vue'
import CompanyDocuments from '@/views/Company/documents.vue'
import CreateEvent from '@/views/Company/events/createEvent.vue'
import events from '@/views/Company/events/events.vue'
import EventDetail from '@/views/Company/events/details.vue'
import EventUpdate from '@/views/Company/events/edit.vue'
import CompanyContact from '@/views/Company/contacts/index.vue'
import CompanyNewContact from '@/views/Company/contacts/create.vue'
import CompanyDirectory from '@/views/Company/directory/index.vue'
import CompanyAudit from '@/views/Company/audits.vue'
import ShareHolding from '@/views/Company/shareholding/index.vue'
import Corporate from '@/views/Company/corporate/index.vue'
import CorporateDocuments from '@/views/Company/corporate/documents.vue'
import CorporateEvents from '@/views/Company/corporate/events.vue'
//Company register
import Transactions from '@/views/Company/register/transactions.vue'
import DelegateRegister from '@/views/Company/register/delegate.vue'
import MandateRegister from '@/views/Company/register/mandate.vue'
import MeetingRegister from '@/views/Company/register/meeting.vue'
import CommitteeRegister from '@/views/Company/register/committee.vue'
import DecisionsRegister from '@/views/Company/register/decisions.vue'
import { Auth0 } from '@/plugins/auth'
import checkCompanyExist from '@/middlewares/companyExist'
import PageNotFound from '@/views/errors/404.vue'

//Portfolio
import Portfolio from '@/views/Portfolio/index.vue'
import PortfolioEvents from '@/views/Portfolio/Corporate/index.vue'
import PortfolioChart from '@/views/Portfolio/chart.vue'
export default [
  {
    name: 'index',
    component: Home,
    meta: { auth: true },
    path: '/',
    redirect: `/redirecting`
  },
  {
    name: 'auth.unauthorized',
    component: Unauthorized,
    meta: { auth: false, titleKey: 'company.forbidden' },
    path: '/unauthorized'
  },
  {
    name: 'auth.connect',
    component: Connect,
    meta: { auth: false, titleKey: 'login.title' },
    path: '/connect'
  },
  {
    name: 'auth.password',
    component: AuthPassword,
    meta: { auth: false, titleKey: 'login.title' },
    path: '/activate-account'
  },
  {
    name: 'auth.redirecting',
    component: Redirecting,
    meta: { auth: true, titleKey: 'login.title' },
    beforeEnter: [Auth0.routeGuard],
    path: '/redirecting'
  },
  {
    name: 'auth.password-renew',
    component: AuthPasswordEmail,
    meta: { auth: false, titleKey: 'login.title' },
    path: '/change-password'
  },
  {
    name: 'auth.password-reset',
    component: AuthPasswordReset,
    meta: { auth: false, titleKey: 'login.title' },
    path: '/reset-password'
  },
  {
    name: 'auth.logout',
    component: Logout,
    meta: { auth: false, titleKey: 'login.title' },
    path: '/logout'
  },

  {
    name: 'about',
    component: About,
    meta: { auth: false, titleKey: 'about.title' },
    path: '/about'
  },
  {
    name: 'account',
    component: Account,
    meta: {},
    path: '/account',
    redirect: 'informations',
    beforeEnter: [Auth0.routeGuard],
    children: [
      {
        path: 'informations',
        meta: { auth: true, titleKey: 'auth.informations_title' },
        name: 'accountInformations',
        component: AccountInformations
      },
      {
        path: 'password',
        name: 'accountPassword',
        meta: { auth: true, titleKey: 'auth.password_title' },
        component: AccountPassword
      },
      {
        path: 'team',
        name: 'accountTeam',
        meta: { auth: true, titleKey: 'auth.team_title' },
        component: AccountTeam
      },
      {
        path: 'organizational-chart',
        name: 'accountOrganizationalChart',
        meta: { auth: true, titleKey: 'auth.chart_title' },
        component: AccountOrganizationalChart
      },
      {
        path: 'companies',
        name: 'accountCompanies',
        meta: {
          auth: true,
          titleKey: 'auth.companies_title',
          anyCan: ['is-manager', 'admin', 'write-companies', 'read-companies'],
          onDeniedRoute: { name: 'auth.unauthorized', replace: true }
        },
        component: AccountCompanies
      },
      {
        path: 'users',
        name: 'accountUsers',
        meta: {
          auth: true,
          titleKey: 'auth.users_title',
          anyCan: ['is-manager', 'admin'],
          onDeniedRoute: { name: 'auth.unauthorized', replace: true }
        },
        component: AccountUsers
      },
      {
        path: 'technical-integration',
        name: 'technicalIntegration',
        meta: {
          auth: true,
          titleKey: 'auth.integration'
          // anyCan: ['is-manager', 'admin']
          // onDeniedRoute: { name: 'auth.unauthorized', replace: true }
        },
        component: TechnicalIntegration
      },
      {
        path: 'alerts',
        name: 'accountAlertSetting',
        meta: {
          auth: true,
          titleKey: 'auth.alerts_title',
          anyCan: ['is-manager', 'admin', 'write-alerts'],
          onDeniedRoute: { name: 'auth.unauthorized', replace: true }
        },
        component: AccountAlerts
      },

      {
        path: 'companies/:id',
        name: 'accountCompanies.details',
        meta: {
          auth: true,
          titleKey: 'auth.company_title',
          anyCan: ['is-manager'],
          onDeniedRoute: { name: 'auth.unauthorized', replace: true }
        },
        component: AccountCompanyDetails
      },
      {
        path: 'logs',
        name: 'accountLogs',
        meta: { auth: true, titleKey: 'auth.logs_title' },
        component: AccountInformations
      }
    ]
  },
  {
    path: '/app/portfolio-view',
    name: 'portfolioView',
    meta: { auth: true, titleKey: 'company.portfolio' },
    component: Portfolio,
    beforeEnter: [Auth0.routeGuard],
    redirect: { name: 'portfolioView.corporate' },
    children: [
      {
        path: 'corporate',
        alias: '',
        meta: {
          auth: true,
          titleKey: 'portfolioView.corporate',
          anyCan: ['portfolio', 'admin'],
          notCan: 'employee',
          docPreview: 'integred',
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'portfolioView.corporate',
        component: PortfolioEvents
      },
      {
        path: 'events/:eventId',
        alias: '',
        meta: {
          auth: true,
          titleKey: 'portfolioView.event',
          anyCan: ['admin', 'portfolio'],
          notCan: 'employee',
          docPreview: 'integred',
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'portfolioView.event',
        component: EventDetail
      },
      {
        path: 'charts',
        alias: '',
        meta: {
          auth: true,
          titleKey: 'portfolioView.charts',
          anyCan: ['admin', 'portfolio'],
          notCan: 'employee',
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'portfolioView.charts',
        component: PortfolioChart
      }
    ]
  },
  {
    path: '/company/:id',
    name: 'company',
    meta: { auth: true, titleKey: 'company.welcome' },
    component: Company,
    beforeEnter: [Auth0.routeGuard, checkCompanyExist],
    // redirect: { name: 'companyOverview' },
    children: [
      {
        path: 'dashboard',
        alias: '',
        meta: {
          auth: true,
          titleKey: 'company.overview',
          anyCan: ['admin', 'is-manager', 'read-dashboard'],
          notCan: 'employee',
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'companyOverview',
        component: CompanyOverview
      },
      {
        path: 'captable',
        meta: {
          auth: true,
          notCan: 'employee',
          titleKey: 'menu.captable',
          anyCan: ['admin', 'is-manager', 'read-captable'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'companyCaptable',
        component: CompanyCaptable
      },

      {
        path: 'upload',
        meta: {
          auth: true,
          notCan: 'employee',
          titleKey: 'company.upload_title',
          anyCan: ['admin', 'is-manager', 'write-documents', 'read-documents'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'companyUpload',
        component: CompanyUpload
      },
      {
        path: 'documents/:documentId?',
        meta: {
          auth: true,
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'write-documents', 'read-documents'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.documents_title',
          docPreview: 'integred'
        },
        name: 'companyDocuments',
        component: CompanyDocuments
      },
      {
        path: 'events',
        name: 'events',
        meta: {
          auth: true,
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'read-events'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.events',
          docPreview: 'integred'
        },
        component: events
      },
      {
        path: 'events-details/:eventId',
        name: 'company.eventDetails',
        meta: {
          notCan: 'employee',
          auth: true,
          anyCan: ['admin', 'is-manager', 'read-events'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.eventDetails',
          docPreview: 'integred'
        },
        component: EventDetail
      },
      {
        path: 'events-update/:eventId',
        name: 'company.eventUpdate',
        meta: {
          notCan: 'employee',
          auth: true,
          anyCan: ['admin', 'is-manager', 'write-events'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.eventDetails',
          docPreview: 'integred'
        },
        component: EventUpdate
      },
      // {
      //   path: 'corporate',
      //   name: 'company.corporate',
      //   meta: {
      //     notCan: 'employee',
      //     anyCan: ['admin', 'is-manager', 'read-corporate'],
      //     onDeniedRoute: { name: 'company.unauthorized', replace: true },
      //     titleKey: 'company.corporate',
      //     docPreview: 'integred'
      //   },
      //   component: Corporate
      // },
      {
        path: 'corporate/events',
        name: 'company.corporateEvents',
        meta: {
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'read-corporate', 'read-events'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.corporateEvents',
          docPreview: 'integred'
        },
        component: CorporateEvents
      },
      {
        path: 'corporate/documents',
        name: 'company.corporateDocuments',
        meta: {
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'read-corporate', 'read-documents'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.corporateDocuments',
          docPreview: 'integred'
        },
        component: CorporateDocuments
      },
      {
        path: 'events/new',
        name: 'createEvent',
        meta: {
          notCan: 'employee',
          auth: true,
          anyCan: ['admin', 'write-events'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.create_event',
          docPreview: 'integred'
        },
        component: CreateEvent
      },
      {
        name: 'company.unauthorized',
        component: Unauthorized,
        meta: { auth: false, titleKey: 'company.forbidden' },
        path: 'unauthorized'
      },
      {
        path: 'directory/:contactId?',
        meta: {
          auth: true,
          anyCan: [
            'low-access',
            'admin',
            'is-manager',
            'read-directory',
            'self-contact'
          ],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.contacts_title',
          docPreview: 'integred'
        },
        name: 'company.directory',
        component: CompanyDirectory
      },

      {
        path: 'contacts/:contactId?',
        meta: {
          auth: true,
          // notCan: 'employee',
          anyCan: [
            'admin',
            'is-manager',
            'read-contacts',
            'employee-readDocuments'
          ],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.contacts_title',
          docPreview: 'integred'
        },
        name: 'company.contact',
        component: CompanyContact
      },
      {
        path: 'new-contacts',
        meta: {
          auth: true,
          // notCan: 'employee',
          anyCan: [
            'admin',
            'is-manager',
            'read-contacts',
            'employee-readDocuments'
          ],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.contacts_title',
          docPreview: 'integred'
        },
        name: 'company.newContact',
        component: CompanyNewContact
      },
      {
        path: 'audits',
        meta: {
          auth: true,
          notCan: 'employee',
          titleKey: 'company.audits_title',
          anyCan: ['admin', 'is-manager', 'read-audits'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'company.audits',
        component: CompanyAudit
      },
      {
        path: 'register/stock-movements',
        meta: {
          auth: true,
          titleKey: 'company.stock_movements_title',
          anyCan: ['admin', 'is-manager', 'read-transactions'],
          notCan: 'employee',
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'register.stockMovement',
        component: Transactions
      },
      {
        path: 'register/decisions',
        meta: {
          auth: true,
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'read-decisions'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.decisions_title',
          docPreview: 'integred'
        },
        name: 'register.presidentDecisions',
        component: DecisionsRegister
      },
      {
        path: 'register/delegates',
        meta: {
          auth: true,
          notCan: 'employee',
          titleKey: 'company.delegates_title',
          anyCan: ['admin', 'is-manager', 'read-delegations'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'register.delegates',
        component: DelegateRegister
      },
      {
        path: 'register/mandates',
        meta: {
          auth: true,
          notCan: 'employee',
          titleKey: 'company.mandates_title',
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          anyCan: ['admin', 'is-manager', 'read-mandates']
        },
        name: 'register.mandates',
        component: MandateRegister
      },
      {
        path: 'register/meetings',
        meta: {
          auth: true,
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'read-meetings'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.meetings_title',
          docPreview: 'integred'
        },
        name: 'register.meetings',
        component: MeetingRegister
      },
      {
        path: 'register/committees',
        meta: {
          auth: true,
          notCan: 'employee',
          anyCan: ['admin', 'is-manager', 'read-committees'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true },
          titleKey: 'company.committees_title',
          docPreview: 'integred'
        },
        name: 'register.committees',
        component: CommitteeRegister
      },
      {
        path: 'shareholding',
        meta: {
          auth: true,
          notCan: 'employee',
          titleKey: 'company.shareholding_title',
          anyCan: ['admin', 'is-manager', 'read-ownership'],
          onDeniedRoute: { name: 'company.unauthorized', replace: true }
        },
        name: 'company.shareholding',
        component: ShareHolding
      }
    ]
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound }
]
