<template>
  <div>
    <div class="w-full flex">
      <div
        class="w-20 h-20 rounded-full overflow-hidden border-secondary5 border"
      >
        <img
          :src="`${
            url ||
            require(type === 'company'
              ? '@/assets/images/profile/company.png'
              : '@/assets/images/profile/user.png')
          }`"
          alt="Uplaw Avatar"
          class="h-full w-full bg-secondary5 object-cover transition"
        />
      </div>

      <div class="ml-4 flex flex-col justify-center">
        <h5 v-if="withLabel" class="my-2 text-left">
          {{ $t('contactBook.picture') }}
        </h5>
        <div class="flex">
          <div
            v-if="!profileImage"
            class="
              p-1
              rounded
              bg-white
              flex
              justify-center
              items-center
              cursor-pointer
              border border-secondary5
            "
            @click="resetUrl"
          >
            <i class="bi bi-x text-secondary7"></i>
          </div>

          <div
            class="
              rounded
              ml-2
              bg-white
              p-1
              px-2
              cursor-pointer
              border border-secondary5
            "
          >
            <FileInput
              accept="image/gif,image/jpeg,image/png"
              @files-changed="handleChange"
            >
              <h5 class="text-xs text-secondary8">
                {{
                  $t(
                    `contactBook.${
                      profileImage ? 'changePicture' : 'choosePicture'
                    }`
                  )
                }}
              </h5>
            </FileInput>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import FileInput from '@/components/FileInput/index.vue'
  import { CompanyModel } from '@/services/api/models'
  import { useStore } from 'vuex'
  export default defineComponent({
    components: { FileInput },
    props: {
      type: { type: String, default: '' },
      contactId: { type: String, default: null },
      profileImage: {
        type: String,
        required: false,
        default: null
      },
      withLabel: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['fileChanged'],
    setup(props, { emit }) {
      const store = useStore()
      const url = ref<string | null>(props.profileImage)
      const fileInputRef = ref<HTMLInputElement>()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const handleChange = (files: File[]) => {
        url.value = URL.createObjectURL(files![0])
        emit('fileChanged', files![0])
      }
      const handleSelect = (e: Event) => {
        document.getElementById('profileImage')?.click()
      }
      const resetUrl = () => {
        url.value = null
      }

      const loadImage = async () => {
        if (!props.contactId) return
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_PICTURE',
          {
            companyId: currentCompany.value?.id,
            contactId: props.contactId
          }
        )
        if (response) url.value = response
      }

      watch(
        () => props.profileImage,
        (val) => {
          url.value = val
        }
      )

      onMounted(loadImage)

      return {
        handleSelect,
        handleChange,
        resetUrl,
        fileInputRef,
        url
      }
    }
  })
</script>
