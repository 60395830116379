
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle
  } from '@headlessui/vue'
  import { Input } from '@up.law/uplaw-ui'
  import DocumentResult from './ModalDocumentSearch.vue'
  import EventsResult from './ModalEventResult.vue'
  import ContactsResult from './ModalContactResult.vue'
  import { useI18n } from 'vue-i18n'
  import { Field } from 'vee-validate'

  export default defineComponent({
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogPanel,
      Input,
      EventsResult,
      ContactsResult,
      Field,
      DocumentResult,
      DialogTitle
    },
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      disableCancel: {
        type: Boolean,
        default: false
      },
      disableValidate: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: 'w-3/5'
      },
      title: {
        type: String,
        default: 'Modal'
      }
    },
    emits: ['cancel', 'submit'],
    setup(props, { emit }) {
      const isOpen = ref(false)
      const { t } = useI18n()
      const closeModal = (value = true) => {
        isOpen.value = value
        displayResults.value = true
        if (!value) emit('cancel')
      }
      const search = ref('')
      const count = ref({ documents: 0, events: 0, contacts: 0 })
      const loading = ref({ documents: false, events: false, contacts: false })
      const countDocuments = ref(0)
      const countEvents = ref(0)
      const countContacts = ref(0)
      const tabs = computed(() => [
        {
          id: 0,
          key: 'documents',
          name: t('documents.context'),
          icon: 'files'
        },
        {
          id: 1,
          key: 'events',
          icon: 'lightning',
          name: t('events.context')
        },
        {
          id: 2,
          key: 'contacts',
          icon: 'people',
          name: t('contactBook.title')
        }
      ])

      const selectTab = (index: number) => {
        selectedTab.value = index
      }
      const selectedTab = ref(0)
      const displayResults = ref(false)

      const openModal = () => {
        isOpen.value = true
      }

      const handleCancel = () => {
        closeModal(false)
        emit('cancel')
      }

      const clearSearch = () => {
        search.value = ''
        displayResults.value = false
      }

      const handleValidate = () => {
        emit('submit')
      }

      watch(
        () => props.isVisible,
        (value) => {
          isOpen.value = value
        }
      )

      watch(
        () => search.value,
        (value) => {
          displayResults.value =
            value.length > 0 ||
            count.value.documents > 0 ||
            count.value.contacts > 0 ||
            count.value.events > 0
        }
      )

      onBeforeUnmount(() => {
        displayResults.value = false
      })

      onMounted(() => {
        isOpen.value = props.isVisible
        displayResults.value = false
      })

      return {
        isOpen,
        search,
        tabs,
        clearSearch,
        count,
        loading,
        countContacts,
        countDocuments,
        countEvents,
        selectedTab,
        displayResults,
        openModal,
        selectTab,
        closeModal,
        handleCancel,
        handleValidate
      }
    }
  })
