
  import { defineComponent } from 'vue'
  import moment from 'moment'
  import { FinancialEnding } from '@/services/api/models'

  export default defineComponent({
    name: 'FinancialEnding',
    components: {},
    props: {
      event: {
        type: Object as () => FinancialEnding | undefined,
        required: true
      }
    },
    setup() {
      return { moment }
    }
  })
