import axios from '../axios'

const getFile = (
  url: string,
  name: string,
  filter = {},
  onSuccess = (link: HTMLAnchorElement) => {
    //
  },
  onError = () => {
    //
  }
) => {
  axios
    .get(url, {
      responseType: 'blob',
      params: filter
    })
    .catch(() => {
      onError()
    })
    .then((response: any) => {
      const url = URL.createObjectURL(
        new Blob([response], { type: 'application/vnd.ms-excel' })
      )

      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${name.replaceAll(' ', '-')}-${new Date().toLocaleDateString()}.xlsx`
      )
      document.body.appendChild(link)
      link.click()

      onSuccess(link)
    })
}

const getPdfFile = async (
  url: string,
  name: string,
  filter = {},
  onSuccess = (link: HTMLAnchorElement) => {
    //
  },
  onError = () => {
    //
  }
) => {
  axios
    .get(url, {
      responseType: 'blob',
      params: filter
    })
    .catch(() => {
      onError()
    })
    .then((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = name
      link.click()
      URL.revokeObjectURL(link.href)

      onSuccess(link)
    })
}

export { getFile, getPdfFile }
