<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="
        group
        inline-flex
        items-center
        rounded-md
        bg-orange-700
        px-2
        py-2
        text-base
        font-medium
        text-white
        hover:text-opacity-100
        focus:outline-none
        focus-visible:ring-2
        focus-visible:ring-white
        focus-visible:ring-opacity-75
      "
    >
      <div
        :class="`
                  layout_header-userNotifications
                  flex
                  justify-center
                  items-center
                  ${
                    hasWaitingAlert
                      ? 'layout_header-userNotifications--active'
                      : ''
                  }
                  mr-3`"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
          />
        </svg>
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <div class="bg-white">
        <PopoverPanel
          class="
            absolute
            left-0
            mt-2
            -ml-20
            z-50
            w-max
            max-w-sm
            -translate-x-2/3
            transform
            px-4
            sm:px-0
            lg:max-w-3xl
          "
        >
          <div
            class="
              overflow-hidden
              bg-white
              rounded-lg
              shadow-lg
              ring-1 ring-black ring-opacity-5
            "
          >
            <div
              class="
                flex
                w-full
                justify-start
                items-center
                py-5
                border-b border-secondary7
              "
            >
              <i class="bi bi-bell-fill mx-2 text-secondary7"></i>
              <h5>Notifications ({{ notSeenotifications.length }})</h5>
            </div>
            <div
              v-if="lastNotifications.length > 0"
              class="relative grid gap-8 bg-white p-7 xl:grid-cols-2"
            >
              <a
                v-for="item in lastNotifications"
                :key="item.id"
                @click.prevent="handleNotificationClicked(item)"
                class="
                  -m-3
                  flex
                  items-center
                  rounded-lg
                  p-2
                  transition
                  duration-150
                  ease-in-out
                  hover:bg-gray-50
                  focus:outline-none
                  focus-visible:ring
                  focus-visible:ring-orange-500
                  focus-visible:ring-opacity-50
                "
              >
                <div
                  class="flex h-10 w-10 shrink-0 items-center justify-center"
                >
                  <i class="bi bi-bell text-lg"></i>
                </div>
                <div class="ml-4 cursor-pointer">
                  <p
                    :class="`text-md font-${
                      item.seen ? 'medium' : 'bold'
                    } text-gray-900 text-left`"
                  >
                    {{ $t(`AlertTypes.${item.alertType}`) }}
                  </p>
                  <p class="text-sm text-left text-gray-500">
                    {{ moment.utc(item.createdAt).format('L') }}
                  </p>
                </div>
              </a>
            </div>
            <div v-else class="flex items-center justify-center h-28 px-5 py-6">
              <h3 class="text-secondary8 font-medium">
                {{ $t('global.noAlerts') }}
              </h3>
              <p></p>
            </div>
            <div class="bg-gray-50 p-4">
              <div
                v-can.any="['admin', 'is-manager', 'write-alerts']"
                class="
                  flow-root
                  rounded-md
                  px-2
                  py-2
                  cursor-pointer
                  transition
                  duration-150
                  ease-in-out
                  hover:bg-gray-100
                  focus:outline-none
                  focus-visible:ring
                  focus-visible:ring-orange-500
                  focus-visible:ring-opacity-50
                "
              >
                <a
                  class="block text-sm text-gray-500 cursor-pointer"
                  @click.prevent="
                    $router.push({
                      name: 'accountAlertSetting',
                      params: { id: currentCompany.id }
                    })
                  "
                >
                  {{ $t('account.alerts.seeMore') }}
                </a>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </transition>
  </Popover>
</template>

<script lang="ts">
  import { Company, Notification } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { useAcl } from 'vue-simple-acl/src'

  import { GlobalConfig } from '@/types'
  import conf from '@/config'
  import { PopoverButton, Popover, PopoverPanel } from '@headlessui/vue'

  export default defineComponent({
    components: { PopoverButton, PopoverPanel, Popover },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const acl = useAcl()

      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )
      const notifications = computed<Notification[]>(
        () => store.getters['organizations/notifications']
      )
      const config = conf as GlobalConfig

      const notSeenotifications = computed(() =>
        notifications.value.filter((x) => !x.seen)
      )
      const lastNotifications = computed<Notification[]>(() =>
        notifications.value.slice(0, 5)
      )

      const hasWaitingAlert = computed(
        () => notifications.value.filter((x) => !x.seen).length > 0
      )

      const handleNotificationClicked = (row: Notification) => {
        if (!row.seen)
          store.dispatch('organizations/MARK_AS_SEEN', {
            companyId: currentCompany.value.id,
            notificationId: row.id
          })
        router.push({
          name: 'company.eventDetails',
          params: { eventId: row?.event?.id, id: currentCompany.value.id }
        })
      }
      return {
        currentCompany,
        lastNotifications,
        hasWaitingAlert,
        notSeenotifications,
        moment,
        handleNotificationClicked
      }
    }
  })
</script>
