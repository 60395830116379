
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import ContactDropdown from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { mandateColumns, stockRegisterColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import moment from 'moment'
  import humanizeDuration from 'humanize-duration'
  import { calculateTimeDuration } from '@/utils/global'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { useI18n } from 'vue-i18n'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'UplRegisterMandate',
    components: {
      ContactDropdown,
      DataTable,
      ElTooltip,
      Multiselect,
      ContactBadge,
      EmptyState,
      DownloadButton
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const search = ref('')
      const { t } = useI18n()
      const selectedContacts = ref<string[]>([])
      const selectedFunctions = ref<string[]>([])
      const selectedCommittees = ref<string[]>([])
      const lang = computed(() => store.getters['config/lang'])

      const contacts = computed(() => store.getters['organizations/contacts'])
      const organs = computed(() => store.getters['organizations/committees'])
      const mandates = computed(
        () => store.getters['organizations/nominations']
      )
      const functions = computed(() => store.getters['organizations/functions'])
      const pagination = computed(
        () => store.getters['organizations/nominationsPagination']
      )
      const isDownloading = ref(false)

      const getContacts = () => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
      }

      const getFunctions = () => {
        store.dispatch('organizations/GET_FUNCTIONS', {
          companyId: route.params.id
        })
      }

      const getOrgans = () => {
        store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: route.params.id
        })
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', page)
        getMandates()
      }

      const columns = ref(mandateColumns)

      const handleFilterSelected = (value: string[]) => {
        columns.value = stockRegisterColumns.filter((x) =>
          value.includes(x.field)
        )
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const updateField = (value: any, field = '') => {
        if (field === 'contacts') selectedContacts.value = value
        if (field === 'functions') selectedFunctions.value = value
        if (field === 'committees') selectedCommittees.value = value
      }

      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_APPOINTMENTS', {
          name: `${t('exports.mandates')}`,
          companyId,
          filter: {
            committeesId: selectedCommittees.value,
            functionsId: selectedFunctions.value,
            contactsId: selectedContacts.value
          },
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const getMandates = () => {
        store.dispatch('organizations/GET_NOMINATIONS', {
          companyId: route.params.id,
          filter: {
            committeesId: selectedCommittees.value,
            functionsId: selectedFunctions.value,
            contactsId: selectedContacts.value
          }
        })
      }

      watch(
        [
          selectedContacts,
          selectedCommittees,
          selectedFunctions,
          () => route.params.id
        ],
        () => {
          store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', 1)
          getMandates()
        }
      )

      watch(
        () => route.params.id,
        () => {
          selectedCommittees.value = []
          selectedContacts.value = []
          selectedFunctions.value = []
          store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', 1)
          getMandates()
        }
      )

      onMounted(() => {
        store.commit('organizations/SET_NOMINATIONS_PAGINATION_PAGE', 1)
        getContacts()
        getOrgans()
        getMandates()
        getFunctions()
      })

      return {
        search,
        contacts,
        organs,
        selectedContacts,
        selectedCommittees,
        selectedFunctions,
        columns,
        mandates,
        moment,
        humanizeDuration,
        functions,
        pagination,
        lang,
        isDownloading,
        gotoEvent,
        download,
        handleFilterSelected,
        calculateTimeDuration,
        onPageChange,
        updateField
      }
    }
  })
