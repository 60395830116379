
  import { defineComponent, ref, watch } from 'vue'
  import Multiselect from '../Select/multiselect.vue'
  import { languages } from '@/utils'

  export default defineComponent({
    name: 'UplLangSelect',
    components: { Multiselect },
    props: {
      value: { type: String, default: '' }
    },
    emits: ['onSelected'],
    setup(props, { emit }) {
      const lang = ref(props.value)

      const selectionChanged = ($event: string) => {
        emit('onSelected', $event)
      }
      const options = ref(languages)

      watch(props, (newProps) => {
        lang.value = newProps.value
      })

      return { lang, options, selectionChanged }
    }
  })
