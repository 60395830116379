import {
  OrganizationState,
  SET_ALERTS,
  AlertMutations,
  SET_NOTIFICATIONS
} from './models'
import { MutationTree } from 'vuex'
import { SET_NOTIFICATIONS_PAGE } from './models'

export const alertMutations: MutationTree<OrganizationState> & AlertMutations =
  {
    [SET_ALERTS](state, result) {
      state.alerts = [...result]
    },
    [SET_NOTIFICATIONS](state, result) {
      state.notifications = [...result.data]
      state.notificationsPagination.currentPage = result.metadata.currentPage
      state.notificationsPagination.lastPage = result.metadata.lastPage
      state.notificationsPagination.total = result.metadata.count
    },

    [SET_NOTIFICATIONS_PAGE](state, page: number) {
      state.notificationsPagination.currentPage = page
    }
  }
