
  import { computed, defineComponent, ref } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { ElTooltip } from 'element-plus'
  import Modal from '@/components/Modal/Modal.vue'
  import { useStore } from 'vuex'
  import { MAINACTIONS } from '@/types'
  import { useI18n } from 'vue-i18n'
  import { ActionListItem } from '@/types'
  import ActionList from '@/components/ActionList/index.vue'

  export default defineComponent({
    name: 'DownloadButton',
    components: { Button, Modal, ElTooltip, ActionList },
    props: {
      onClick: { type: Function },
      hasChildren: { type: Boolean, default: false },
      label: { type: String, default: null }
    },
    setup(props) {
      const store = useStore()
      const { t } = useI18n()
      const displayModal = ref(false)
      const isExporting = computed(
        () => store.getters['organizations/isExporting']
      )

      const actionsListItems = ref<ActionListItem[]>([
        {
          name: t('global.actions.pdf'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.CONTACTS_RECORD_PDF,
          type: ''
        },
        {
          name: t('global.actions.xls'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.CONTACTS_RECORD_XLS,
          type: ''
        }
      ])

      const handleAction = (key: MAINACTIONS) => {
        onDownload(key)
      }

      const onDownload = (key: MAINACTIONS) => {
        if (props.onClick) props.onClick(key)
        else displayModal.value = true
      }

      return {
        displayModal,
        isExporting,
        actionsListItems,
        handleAction,
        onDownload
      }
    }
  })
