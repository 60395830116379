
  import {
    VestingSuspension,
    VestingDelayProrogation,
    Company
  } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  export default defineComponent({
    name: 'VestingCard',
    components: { ContactBadge },
    props: {
      event: {
        type: Object as () =>
          | VestingSuspension
          | VestingDelayProrogation
          | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      }
    },
    setup() {
      return { moment }
    }
  })
