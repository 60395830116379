<template>
  <div class="flex flex-col items-start">
    <div class="w-1/5 my-3">
      <Field v-slot="{ field }" v-model="search" name="search">
        <Input
          v-model="search"
          v-bind="field"
          no-error
          name="search"
          :placeholder="$t('documents.elements.search')"
        />
      </Field>
    </div>
    <div
      v-if="isLoading"
      class="flex w-full h-full justify-center items-center relative"
    >
      <Loader />
    </div>
    <div v-else class="mt-2 w-full">
      <data-table
        v-if="documents.length"
        :has-checkbox="false"
        :columns="columns"
        :rows="documents"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #date="{ row }">
          <p v-if="row.signedAt">{{ moment.utc(row.signedAt).format('L') }}</p>
          <p v-else>--/--/----</p>
        </template>
        <template #name="{ row }">
          <p class="text-left">{{ row.label || row.name }}</p>
        </template>
        <template #actions="slotProps">
          <div class="flex">
            <Button
              variant="secondary"
              size="medium"
              class="p-1 rounded-sm"
              label=""
              @click="handleClick(slotProps.row)"
            >
              <i class="bi bi-eye text-lg"></i>
            </Button>
          </div>
        </template>
      </data-table>
      <div v-else class="flex justify-center items-center w-full mt-4">
        <EmptyState
          class="d-block mx-auto datatable"
          :title="$t('global.noData')"
          :subtitle="$t('documents.noDataForUser')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import moment from 'moment'
  import { Button, Input } from '@up.law/uplaw-ui'
  import {
    CompanyModel,
    Document,
    Document as DocumentModel
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { Field } from 'vee-validate'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    components: { DataTable, Button, Field, Input, EmptyState, Loader },
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const search = ref('')
      const isLoading = ref(false)
      const selectedDocumentId = ref('')
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const currentDocument = computed<DocumentModel | null>(
        () => store.getters['organizations/currentDocument']
      )
      const columns = ref([
        {
          label: 'Date',
          field: 'date',
          custom: true,
          width: '8%'
        },
        {
          label: t('documents.elements.name'),
          field: 'name',
          custom: true
        },

        {
          label: '',
          field: 'actions',
          custom: true,
          width: '5%'
        }
      ])
      const documents = ref<DocumentModel[]>([])

      const pagination = computed(
        () => store.getters['organizations/documentsPagination']
      )

      const getDocuments = (search = '') => {
        isLoading.value = true
        store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: { contactsId: [route.params.contactId], name: search },
          onSuccess: (docs: any) => {
            documents.value = docs.data.map((x: any) => ({
              ...x,
              active: x.id == selectedDocumentId.value
            }))
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const handleClick = async (data: Document) => {
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.commit('ui/COLLAPSE_DOC_PREVIEW', true)
        selectedDocumentId.value = data.id
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: currentCompany.value.id,
          documentId: data.id
        })

        documents.value = documents.value.map((x: any) => ({
          ...x,
          active: x.id == selectedDocumentId.value
        }))
      }

      const hasAuditNotProcessed = (row: Document | null) => {
        return row?.docAudits.every((x) => x.processed == true)
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', page)
        getDocuments()
      }

      watch(
        () => route.params.contactId,
        async () => {
          getDocuments()
        }
      )

      watch(search, (val) => {
        getDocuments(val)
      })

      onMounted(() => {
        getDocuments()
      })

      onBeforeUnmount(() => {
        store.commit('ui/DISPLAY_PREVIEW', false)
        store.commit('ui/COLLAPSE_DOC_PREVIEW', false)
        selectedDocumentId.value = ''
        store.commit('organizations/SET_COMPANY_SINGLE_DOCUMENT', null)
      })

      return {
        columns,
        documents,
        pagination,
        moment,
        search,
        isLoading,
        handleClick,
        onPageChange,
        hasAuditNotProcessed
      }
    }
  })
</script>
