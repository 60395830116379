<template>
  <div class="flex flex-col container-tree-display max-h-full">
    <div class="flex w-full flex-col justify-between items-center pl-3 pr-3">
      <div
        v-if="canSeeFilter"
        class="flex justify-between w-full items-center py-4"
      >
        <p>{{ $t('contactSearch.filter') }}</p>
        <Button
          variant="custom"
          size="medium"
          :class="`rounded-sm flex justify-center items-center ${
            filtered ? 'bg-primary4' : 'bg-secondary4'
          }`"
          @click="isVisible = !isVisible"
        >
          <i
            :class="`bi bi-filter text-xl  ${
              filtered ? 'text-white' : 'text-primary5'
            }`"
          ></i>
        </Button>
      </div>

      <transition name="collapse">
        <div v-show="isVisible" v-if="canSeeFilter" class="w-full">
          <Field
            v-slot="{ field }"
            v-model="filter.name"
            type="text"
            name="name"
          >
            <Input
              v-model="filter.name"
              v-bind="field"
              name="name"
              :value="filter.name"
              type="search"
              :disabled="false"
              no-error
              :placeholder="$t('contactSearch.name')"
              class="w-full mb-2"
              @keyup.enter="onEnterClick"
            />
          </Field>

          <div class="w-full">
            <Field
              v-slot="{ field }"
              v-model="filter.contactGroupId"
              name="type"
            >
              <Multiselect
                :value="filter.contactGroupId"
                v-bind="field"
                :options="companyGroups"
                :placeholder="$t('contactSearch.group')"
                name="type"
              />
            </Field>
          </div>

          <div>
            <DropdownStockByGroup
              v-model="aliasesId"
              :value="aliasesId"
              open-direction="bottom"
              :can-add-new="false"
              mode="tags"
              @on-updated="handleSelection"
            ></DropdownStockByGroup>
          </div>

          <div class="flex justify-end items-center text-xl mt-2">
            <Button
              variant="custom"
              size="medium"
              :label="$t('documents.resetFilter')"
              class="
                rounded-sm
                bg-white
                flex
                text-secondary9
                justify-center
                items-center
                text-lg
                mr-2
              "
              @click="resetFilter"
            >
            </Button>
            <Button
              variant="custom"
              size="medium"
              :label="$t('documents.filterLabel')"
              class="
                rounded-sm
                bg-secondary4
                flex
                text-primary4
                justify-center
                items-center
                text-lg
                hover:bg-primary4 hover:text-white
              "
              @click="getContacts"
            >
            </Button>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="
        contacts-leftPanel
        overflow-y-scroll overflow-x-hidden
        max-h-full
        bg-white
        transition transition-all
      "
    >
      <div v-if="groups.size > 0" class="mt-3 pl-3 pr-3">
        <div
          v-for="([key, contacts], index) of groups"
          :key="key"
          class="contacts-leftPanel-item transition transition-all"
        >
          <div
            :id="`wrapper-${index}`"
            class="
              contacts-leftPanel-toggle
              mb-3
              flex
              justify-between
              items-center
              py-3
              border-b border-secondary5
            "
            @click="toggleGroup(key)"
          >
            <div class="flex items-center">
              {{ key === 'zz-other' ? $t('global.zz-other') : key }}
              <span
                class="
                  ml-2
                  text-sm
                  dark
                  py-1
                  px-2
                  bg-primary1
                  text-primary4
                  rounded-xl
                "
                >{{
                  key === 'zz-other' || filtered
                    ? contacts.length
                    : getCountContact(key)
                }}</span
              >
            </div>
            <i
              :class="`transition transition-all bi bi-chevron-${
                groupsDisplay[key] ? 'down' : 'up'
              }`"
            />
          </div>

          <transition name="collapse">
            <div v-show="groupsDisplay[key]" class="contacts-leftPanel-infos">
              <div
                v-for="contact in contacts"
                :key="contact.id"
                class="relative"
                @click="handleUserClick(contact)"
              >
                <div
                  v-if="$route.params.contactId === contact.id"
                  class="
                    absolute
                    rounded
                    active-contact
                    transition transition-all
                    selected-contact
                  "
                ></div>
                <ContactBadge
                  class="p-0 m-0 my-4 cursor-pointer"
                  :contact="contact"
                  :details="true"
                  type="light"
                />
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div v-else-if="filtered" class="mt-5">
        <EmptyState title="" :subtitle="$t('contactSearch.noResult')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'
  import {
    CompanyModel,
    ContactGroup,
    ContactStatus,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { useStore } from 'vuex'
  import Multiselect from '../Select/multiselect.vue'
  import DropdownStockByGroup from '../Forms/DropDowns/DropdownStockByGroup.vue'
  import { sortObjectByKeys } from '@/utils'
  import ContactBadge from '../ContactBadge/index.vue'
  import EmptyState from '../EmptyState/index.vue'
  export default defineComponent({
    components: {
      Field,
      Button,
      Input,
      Multiselect,
      DropdownStockByGroup,
      ContactBadge,
      EmptyState
    },
    props: {
      handleNavigation: { type: Boolean, default: true }
    },
    emits: ['onClick'],
    setup(props, { emit }) {
      const router = useRouter()
      const route = useRoute()
      const store = useStore()
      const filter = ref<any>({
        name: '',
        contactStatusId: '',
        type: '',
        contactGroupId: ''
      })
      const isLoadingData = ref(false)

      const groupsDisplay = ref<any>({})
      const aliasesId = ref([])
      const contactStatusId = ref('')
      const contactType = ref('')
      const acl = useAcl()
      const canSeeFilter = computed(() =>
        acl.anyPermission(['write-contacts', 'read-contacts'])
      )
      const filtered = computed(
        () =>
          aliasesId.value?.length !== 0 ||
          contactType.value !== '' ||
          contactStatusId.value != '' ||
          filter.value.name != ''
      )
      const isVisible = ref(true)

      const companyGroups = computed<ContactGroup[]>(
        () => store.getters['organizations/groups']
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const allGroups = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contactsResult'])

      const groups = computed(() => {
        const noGroupContacts = allGroups.value.filter(
          (c) => c.contactGroups?.length == 0
        )
        const map = new Map()
        if (noGroupContacts.length > 0) {
          map.set('zz-other', noGroupContacts)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          groupsDisplay.value['zz-other'] = false
        }
        allGroups.value
          .filter((c) => c.contactGroups?.length)
          .forEach((elm, i) => {
            elm?.contactGroups?.forEach((group) => {
              const collection = map.get(group.name)
              if (!collection) {
                map.set(group.name, [elm])
                groupsDisplay.value[group.name] = false
              } else {
                collection.push(elm)
              }
            })
          })
        const sortedMap = sortObjectByKeys(map)

        return sortedMap
      })

      const onEnterClick = () => {
        getContacts()
      }

      const toggleGroup = (id: string, value = false) => {
        groupsDisplay.value[id] = !groupsDisplay.value[id] || value
      }

      const getCountContact = (id: string) => {
        return companyGroups.value.find((x) => x.name === id)?.countContacts
      }

      const resetFilter = () => {
        aliasesId.value = []
        contactType.value = ''

        filter.value = {
          name: '',
          contactGroupId: '',
          contactStatusId: '',
          type: '',
          stockAliasIds: [],
          optionAliasIds: [],
          compositionAliasIds: []
        }
        getContacts()
        isVisible.value = false
      }
      const getContacts = () => {
        if (!currentCompany.value) return

        store.dispatch('organizations/FILTER_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: { ...filter.value, limit: 10, offset: 0 }
        })
      }

      const handleSelection = ({ selected }: any) => {
        filter.value = { ...filter.value, ...selected }
      }

      const handleUserClick = async (
        contact: IndividualContact | ProfessionalContact | InvestorContact
      ) => {
        if (props.handleNavigation)
          router.push({
            name: 'company.directory',
            params: { id: currentCompany.value.id, contactId: contact.id }
          })
        else emit('onClick', contact)
        isLoadingData.value = true
      }

      const getGroups = () => {
        store.dispatch('organizations/GET_COMPANY_GROUPS', {
          companyId: route.params.id
        })
      }

      watch(
        () => groups.value,
        () => {
          const iterator = groups.value.keys()
          let key = 'zz-other'
          if (!groups.value.has(key)) {
            key = iterator.next().value
          }
          toggleGroup(key, true)
        }
      )

      onMounted(() => {
        getGroups()
      })
      return {
        filtered,
        isVisible,
        filter,
        companyGroups,
        aliasesId,
        groups,
        canSeeFilter,
        isLoadingData,
        getContacts,
        toggleGroup,
        resetFilter,
        handleSelection,
        getCountContact,
        handleUserClick,
        groupsDisplay,
        onEnterClick
      }
    }
  })
</script>
<style lang="scss">
  .container-tree-display {
    width: var(--document-treeview-width);

    .selected-contact {
      margin: auto 0;
      @apply border-l border-current;
      right: 0;
      top: 0;
      bottom: 0;
      width: 0;

      height: 0;
      border-top: 0.6em solid transparent;
      border-bottom: 0.6em solid transparent;

      border-left-width: 0.6em;
    }
  }
</style>
