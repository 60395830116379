import moment from 'moment'

const humanFileSize = (bytes: number, si = true, dp = 1): string => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${bytes.toFixed(dp)} ${units[u]}`
}

export const csvExport = (
  arrData: any,
  name = `Export-${moment.now()}`,
  onSuccess = (link: HTMLAnchorElement) => {
    //
  },
  onError = () => {
    //
  }
) => {
  try {
    let csvContent = 'data:text/xlsx;charset=utf-8,'
    const xlsxCont =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    csvContent += arrData
    const data = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', data)
    link.setAttribute('download', `${name}-${moment().format('LLL')}`)
    link.click()
    onSuccess(link)
  } catch (error) {
    onError()
  }
}

export const excelExport = (
  arrData: any,
  name = `Export-${moment.now()}`,
  onSuccess = (link: HTMLAnchorElement) => {
    //
  },
  onError = () => {
    //
  }
) => {
  try {
    const url = URL.createObjectURL(
      new Blob([arrData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    )
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute(
      'download',
      `${name}-${new Date().toLocaleDateString()}.xlsx`
    )
    link.click()

    onSuccess(link)
  } catch (error) {
    onError()
  }
}

export { humanFileSize }
