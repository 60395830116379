
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import { STOCKTYPE, STOCKTYPESALIAS } from '@/types'
  import {
    CompositionStockAlias,
    OptionStockAlias,
    StockAlias,
    StockGroup
  } from '@/services/api/models'
  import Alias from '@/components/StockAlias/index.vue'
  import moment from 'moment'
  import { groupItemBy, sortObjectByKeys } from '@/utils'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Alias, Button },
    setup() {
      const search = ref('')
      const acl = useAcl()
      const store = useStore()
      const groupsDisplay = ref<any>({})
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )

      const currentStock = computed(
        () => store.getters['organizations/currentStockAlias']
      )

      const stockGroups = computed<StockGroup[]>(
        () => store.getters['organizations/stockGroups']
      )

      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true,
          stockType: STOCKTYPE.SAMPLE
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
      }

      const getAliases = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: currentCompany.value.id,
          filter: { ...filter }
        })
      }

      const setCurrentStockALias = (stock: StockAlias) => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          stockType: stock.type
        })
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: currentCompany.value.id,
          stockId: stock.id,
          type:
            stock.type === STOCKTYPE.SAMPLE
              ? STOCKTYPESALIAS.STOCK
              : stock.type === STOCKTYPE.OPTION
              ? STOCKTYPESALIAS.OPTION
              : STOCKTYPESALIAS.COMPOSITION
        })
      }

      const stocks = computed<StockAlias[]>(
        () => store.getters['organizations/stockAliases']
      )

      const optionStockAliases = computed<OptionStockAlias[]>(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed<CompositionStockAlias[]>(
        () => store.getters['organizations/compositionStockAlias']
      )

      const groups = computed(() => {
        return sortObjectByKeys(
          groupItemBy(
            listedStockAliases.value,
            (item) => item.group?.id || 'zz-other'
          )
        )
      })
      const toggleGroup = (id: string) => {
        groupsDisplay.value[id] = !groupsDisplay.value[id]
      }
      const getGroup = (id: string) => {
        return stockGroups.value.find((x) => x.id === id)
      }

      const allStocks = computed(() =>
        [
          ...optionStockAliases.value,
          ...stocks.value,
          ...compositionStockAlias.value
        ].sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
      )

      const listedStockAliases = ref(allStocks.value)

      watch(search, (text: string) => {
        getAliases(text)
      })

      watch(allStocks, (stocks) => {
        listedStockAliases.value = stocks
      })

      watch(stockGroups, (grps) => {
        grps.forEach((g) => (groupsDisplay.value[g.id] = true))
      })

      onBeforeMount(() => {
        getAliases()
      })
      return {
        canDo,
        search,
        groupsDisplay,
        currentStock,
        groups,
        initStockForm,
        toggleGroup,
        getGroup,
        setCurrentStockALias
      }
    }
  })
