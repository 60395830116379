import { company } from '../endpoints'
import axios from '../axios'
import { FundRaisingRoundFormData } from '@/types/forms'

const getFundRaisingRounds = async (companyId: string, filter = {}) => {
  const response = await axios.get(company.fundraisingRound(companyId), {
    params: filter
  })
  return response
}

const getFundRaisingRound = async (companyId: string, planId = '') => {
  const response = await axios.get(
    company.singleFundraisingRound(companyId, planId)
  )
  return response
}

const deleteFundRaisingRound = async (companyId: string, planId = '') => {
  const response = await axios.delete(
    company.singleFundraisingRound(companyId, planId)
  )
  return response
}

const createFundRaisingRound = async (
  companyId: string,
  form: FundRaisingRoundFormData
) => {
  const { data } = await axios.post(company.fundraisingRound(companyId), form)
  return data
}

const editFundRaisingRound = async (
  companyId: string,
  id: string,
  form: FundRaisingRoundFormData
) => {
  const { data } = await axios.put(
    company.singleFundraisingRound(companyId, id),
    form
  )
  return data
}

export {
  editFundRaisingRound,
  createFundRaisingRound,
  getFundRaisingRound,
  getFundRaisingRounds,
  deleteFundRaisingRound
}
