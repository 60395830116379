export enum RolePermission {
  isManager = 'is-manager',
  writeVesting = 'write-vestings',
  readVesting = 'read-vestings',
  writeStocks = 'write-stocks',
  readStock = 'read-stocks',
  writeOperations = 'write-operations',
  readOperations = 'read-operations',
  writeCommittees = 'write-committees',
  readCommittees = 'read-committees',
  writeProfitSharingPlan = 'write-profitSharingPlan',
  readProfitSharingPlan = 'read-profitSharingPlan',
  writeFundRaisingRound = 'write-fundRaisingRound',
  readFundRaisingRound = 'read-fundRaisingRound',
  writeUsers = 'write-users',
  readUsers = 'read-users',
  writeCompanies = 'write-companies',
  readCompanies = 'read-companies',
  downloadDocuments = 'download-documents',
  readCaptable = 'read-captable',
  writeDocuments = 'write-documents',
  readDocuments = 'read-documents',
  writeEvents = 'write-events',
  readDirectory = 'read-directory',
  selfContact = 'self-contact',
  readCorporate = 'read-corporate',
  readDashboard = 'read-dashboard',
  readEvents = 'read-events',
  writeContacts = 'write-contacts',
  readAudits = 'read-audits',
  writeAudits = 'write-audits',
  readContacts = 'read-contacts',
  readOwnership = 'read-ownership',
  readTransactions = 'read-transactions',
  readDelegations = 'read-delegations',
  writeDelegations = 'write-delegations',
  readDecisions = 'read-decisions',
  readMandates = 'read-mandates',
  writeAlerts = 'write-alerts',
  readlerts = 'read-alerts',
  readMeetings = 'read-meetings',
  readExport = 'read-export',
  writeAutomation = 'write-automation',
  writeConfig = 'write-config',
  selfReadDocuments = 'employee-readDocuments'
}
