<template>
  <div class="w-full">
    <transition name="fade">
      <div>
        <div :class="[' contactCard w-full pt-5']">
          <div v-if="currentCompany" class="contactCard__visual">
            <img
              :src="
                companyUrl ||
                `${require('@/assets/images/profile/company.png')}`
              "
            />
          </div>

          <div v-if="currentCompany" class="contactCard__content">
            <div class="contactCard__content__information_lvl-1">
              <div class="flex flex-col items-start">
                <h2
                  class="
                    contactCard__content__information_lvl-1__name
                    capitalize
                  "
                >
                  {{ currentCompany.name }}
                </h2>
              </div>
            </div>

            <div class="contactCard__content__information_lvl-2">
              <div
                class="
                  row
                  contactCard__content__information_lvl-2__infos
                  flex
                  justify-between
                  w-full
                "
              >
                <div class="col-md-2 form-group flex flex-col items-start">
                  <label>{{ $t('contact.legalStatus') }}</label>
                  <div v-if="currentCompany.legalStatus" class="text-left">
                    <LongText
                      tag="p"
                      :text="currentCompany.legalStatus.legalStatus"
                    />
                  </div>
                  <i v-else class="bi-dash" />
                </div>
                <div class="col-md-2 form-group flex flex-col items-start">
                  <label>{{ $t('contact.siret') }}</label>
                  <p v-if="currentCompany.siret">
                    {{ currentCompany.siret }}
                  </p>
                  <i v-else class="bi-dash" />
                </div>
                <div class="col-md-2 form-group flex flex-col items-start">
                  <label>{{ $t('contact.constitutedDate') }}</label>
                  <p v-if="currentCompany.constitutedAt">
                    {{ moment.utc(currentCompany.constitutedAt).format('LL') }}
                  </p>
                  <p v-else>-</p>
                </div>
                <div class="col-md-2 form-group flex flex-col items-start">
                  <label>{{ $t('contact.commercialName') }}</label>
                  <p v-if="currentCompany.commercialName">
                    {{ currentCompany.commercialName }}
                  </p>
                  <i v-else class="bi-dash" />
                </div>
              </div>

              <div class="flex mt-4 justify-between w-5/6">
                <div class="col form-group flex flex-col items-start">
                  <label>{{ $t('contact.address') }}</label>
                  <p v-if="currentCompany.address">
                    {{ currentCompany?.address }} -
                    {{ currentCompany?.postalCode }}
                    {{ currentCompany?.city }}
                  </p>
                  <p v-if="currentCompany.additionalAddress">
                    {{ currentCompany.additionalAddress }}
                  </p>
                  <i v-else class="bi-dash" />
                </div>
                <div class="col-md-2 form-group flex flex-col items-start">
                  <label>{{ $t('contact.phoneNumber') }}</label>
                  <p v-if="currentCompany?.phoneNumber">
                    {{ currentCompany?.phoneNumber }}
                  </p>
                  <i v-else class="bi-dash" />
                </div>
                <div class="col-md-2 form-group flex flex-col items-start">
                  <label>{{ $t('contact.email') }}</label>
                  <p v-if="currentCompany?.email">
                    <a :href="`mailto:${currentCompany?.email}`">{{
                      currentCompany?.email
                    }}</a>
                  </p>
                  <i v-else class="bi-dash" />
                </div>
                <div class="col form-group flex flex-col items-start">
                  <label>{{ $t('contact.closeDate') }}</label>
                  <p v-if="currentCompany.closed">
                    {{ currentCompany.closeAt }}
                  </p>
                  <i v-else class="bi-dash" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { mapActions, useStore } from 'vuex'
  import LongText from '@/components/LongText/index.vue'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { companyDefaultImage } from '@/utils/data'
  import moment from 'moment'
  import { truncText } from '@/utils'
  import { Company } from '@/services/api/models'

  export default defineComponent({
    name: 'ContactCard',
    components: {
      LongText
    },
    directives: {},
    props: {
      company: { type: Object as () => Company }
    },
    setup(props) {
      const store = useStore()
      const showCardSecondary = ref(true)

      const contextCompany = computed<Company>(
        () => store.getters['organizations/company']
      )

      const companiesLogos = computed(
        () => store.getters['organizations/companiesLogo']
      )
      const companyUrl = ref<string | null>('')
      const companies = computed<Company[]>(
        () => store.getters['organizations/loaderCompanies']
      )
      const currentCompany = computed<Company | undefined>(() => props.company)
      const companyId = ref(contextCompany.value?.id)

      const actionsListItems = computed(() => [
        {
          name: 'Modifier',
          action: 'edit',
          key: 'edit'
        },
        {
          name: 'Fiche individuelle',
          type: 'list',
          key: 'edit',
          childrens: [
            {
              name: 'Pdf',
              type: 'list',
              action: 'exportPDF',
              key: 'edit'
            }
          ]
        }
      ])
      const getCompanies = async (search = '') => {
        store.dispatch(`organizations/GET_LOADER_COMPANIES`, {
          filter: { search, limit: 30 }
        })
      }

      const handleChange = ($event: string) => {
        companyId.value = $event
      }

      const loadImage = async () => {
        if (
          currentCompany.value?.id &&
          companiesLogos.value[currentCompany.value.id] !== undefined
        ) {
          companyUrl.value = companiesLogos.value[currentCompany.value.id]

          return
        }
        const response = await store.dispatch(
          `organizations/LOAD_COMPANY_LOGO`,
          {
            companyId: currentCompany.value?.id
          }
        )
        if (response) companyUrl.value = response
        else companyUrl.value = null
      }

      watch(
        () => currentCompany.value,
        () => {
          loadImage()
        }
      )

      onMounted(() => {
        getCompanies()
        loadImage()
      })
      return {
        showCardSecondary,
        companyUrl,
        companies,
        companyId,
        companyDefaultImage,
        moment,
        currentCompany,
        actionsListItems,
        truncText,
        handleChange,
        getCompanies
      }
    },

    methods: {
      ...mapActions('ui', ['openBook']),
      exportPDF() {
        window.open('/files/Fiche_individuelle_MANOURY.pdf', '_blank')
      }
    }
  })
</script>

<style lang="scss" scoped>
  .contactCard {
    display: flex;
    justify-content: space-between;

    &.contactCard--extended {
      padding-left: 0;
    }

    .contactCard__visual {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 100px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      color: var(--color-primary);
      background: var(--color-contrast-20);
      // box-shadow: 0 6px 32px rgba(0, 0, 0, 0.31);

      img {
        width: 100%;
      }

      h1 {
        margin: 0;
      }
    }

    .contactCard__content {
      width: 100%;
      margin-left: 32px;

      .contactCard__content__information_lvl-1 {
        display: flex;
        justify-content: space-between;
      }

      .contactCard__content__information_lvl-1__name {
        color: var(--color-contrast-70);
        margin: 0;
      }

      .contactCard__content__information_lvl-1__role {
        margin-bottom: 4px;
        font: var(--fs-s);
        font-weight: var(--fw-regular);
        color: var(--color-contrast-40);
      }
    }

    .contactCard__content__information_lvl-2 {
      // border-bottom: var(--border-default);
      padding-bottom: 16px;

      .form-group {
        label {
          color: var(--color-contrast-50) !important;
        }
        p,
        i {
          font-size: var(--fs-s);
        }
      }

      .contactCard__content__information_lvl-2__infos {
        margin-top: 24px;
      }
    }

    .contactCard__content__toggle {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      line-height: 24px;
      color: var(--color-primary);
      cursor: pointer;

      p {
        font-size: var(--fs-s);
        font-weight: var(--fw-medium);
        line-height: 24px;

        i {
          font-size: var(--fs-r);
          margin-left: 10px;
          font-weight: var(--fw-semibold);
          color: var(--color-contrast-40);
        }
      }
    }
  }
</style>
