<template>
  <div class="flex justify-center items-center">
    <div class="pb-10 w-full">
      <h3>{{ $t('committeeBook.form.add') }}</h3>
      <Form
        v-slot="{ errors, meta, resetForm }"
        :validation-schema="schema"
        :initial-values="newCommittee"
        @submit="handleForm"
        @reset="handleReset"
      >
        <div class="w-2/5 mt-3">
          <label class="text-xs uppercase font-semibold" for="name">
            {{ $t('committeeBook.form.name') }}
            <strong class="ml-1 text-error h-4">*</strong>
          </label>
          <Field
            v-slot="{ field }"
            v-model="newCommittee.denomination"
            name="denomination"
          >
            <Input
              v-bind="field"
              id="denomination"
              v-model="newCommittee.denomination"
              name="denomination"
              :disabled="false"
              :placeholder="$t('committeeBook.form.namePlaceholder')"
            />
          </Field>
        </div>

        <div class="w-2/5 mt-3 mb-0 pb-4">
          <label class="text-xs uppercase font-semibold" for="creationDate">
            {{ $t('committeeBook.form.creationDate') }}
            <strong class="ml-1 text-error h-4">*</strong>
          </label>
          <Field
            v-slot="{}"
            v-model="newCommittee.creationDate"
            name="creationDate"
            class="h-10"
          >
            <UplDatePicker
              v-model="newCommittee.creationDate"
              name="creationDate"
              class="hover:border-primary4"
              :enable-time-picker="false"
              :placeholder="$t('committeeBook.form.creationDatePlaceholder')"
              close-on-scroll
            />
          </Field>
        </div>

        <div class="w-2/5 mt-3">
          <label class="text-xs uppercase font-semibold" for="minMembers">{{
            $t('committeeBook.form.minMembers')
          }}</label>
          <Field
            v-slot="{ field }"
            v-model="newCommittee.minMembers"
            name="minMembers"
          >
            <Input
              v-bind="field"
              id="minMembers"
              v-model="newCommittee.minMembers"
              name="minMembers"
              :disabled="false"
              :placeholder="$t('committeeBook.form.minMembersPlaceholder')"
            />
          </Field>
        </div>

        <div class="w-2/5 mt-3">
          <label class="text-xs uppercase font-semibold" for="maxMembers">{{
            $t('committeeBook.form.maxMembers')
          }}</label>
          <Field
            v-slot="{ field }"
            v-model="newCommittee.maxMembers"
            name="maxMembers"
          >
            <Input
              v-bind="field"
              id="maxMembers"
              v-model="newCommittee.maxMembers"
              name="maxMembers"
              :disabled="false"
              :placeholder="$t('committeeBook.form.maxMembersPlaceholder')"
            />
          </Field>
        </div>

        <div
          v-if="currentCommittee && currentCommittee.id && canDo"
          class="mt-5"
        >
          <h3 class="text-md font-bold">{{ $t('global.settings') }}</h3>
          <div
            class="
              book__settings
              flex
              justify-between
              items-center
              border border-error
              rounded
              p-3
              mt-3
              text-xl
            "
          >
            <div>
              <h5 class="text-blackp">
                {{ $t('committeeBook.form.deletionTitle') }}
              </h5>
              <h6>{{ $t('committeeBook.form.deletionMessage') }}</h6>
            </div>
            <div class="ml-auto">
              <el-popconfirm
                :title="$t('committeeBook.form.promptDeletion')"
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                @confirm="deleteCompanyCommittee"
              >
                <template #reference>
                  <Button
                    variant="custom"
                    class="bg-error text-white hover:bg-error hover:border-0"
                    :disabled="false"
                    :label="$t('committeeBook.form.deletionPersist')"
                  >
                  </Button>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <FormActions
          v-if="canDo"
          :errors="errors"
          :meta="meta"
          @reset-form="resetForm"
        />
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import { CompanyCommittee } from '@/services/api/models'
  import { CompanyCommitteeFormData } from '@/types'
  import { committeeSchema } from '@/utils'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_COMPANY_COMMITTEE,
    EDIT_COMPANY_COMMITTEE
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'UplCommittee',
    components: {
      Form,
      Field,
      Input,
      FormActions,
      Button,
      UplDatePicker,
      ElPopconfirm
    },

    setup() {
      const store = useStore()
      const { t } = useI18n()
      const newCommittee = ref<CompanyCommitteeFormData>({
        denomination: '',
        creationDate: new Date().toISOString(),
        minMembers: 1,
        maxMembers: 1,
        documentsId: [],
        isDraft: true
      })

      const resetForm = () => {
        newCommittee.value = {
          isDraft: false,
          denomination: '',
          creationDate: new Date().toISOString(),
          minMembers: 1,
          maxMembers: 1,
          documentsId: []
        }
      }
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-committees'))
      )
      const modalForm = computed(() => store.getters['organizations/form'])

      const currentCommittee = computed<CompanyCommittee>(
        () => store.getters['organizations/currentCommittee']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = committeeSchema

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleForm = (values: CompanyCommitteeFormData) => {
        let edition = false
        let action = CREATE_COMPANY_COMMITTEE
        let formData = {
          companyId: currentCompany.value.id,
          data: { ...values, name: values.denomination, isDraft: false },
          committeeId: ''
        }

        if (currentCommittee.value && currentCommittee.value.id) {
          edition = true
          formData['committeeId'] = currentCommittee.value.id
          action = EDIT_COMPANY_COMMITTEE
        }

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(`committeeBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('committeeBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('committeeBook.form.title'),
              message: t(
                `committeeBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          }
        })
      }

      const deleteCompanyCommittee = () => {
        store.dispatch(`organizations/REMOVE_COMPANY_COMMITTEE`, {
          companyId: currentCompany.value.id,
          committeeId: currentCommittee.value.id,
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('committeeBook.form.title'),
              message: t(`committeeBook.apiResponse.deleted`)
            })
          },
          onError: (error = null) => {
            const message = error || t(`committeeBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('committeeBook.form.title'),
              message
            })
          }
        })
      }
      const handleReset = () => {
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
      }

      watch(currentCommittee, (newValue) => {
        if (newValue) {
          newCommittee.value = {
            denomination: newValue.denomination,
            creationDate: newValue.creationDate,
            minMembers: newValue.minMembers,
            maxMembers: newValue.maxMembers,
            documentsId: newValue.documentsId,
            isDraft: false
          }
        }
      })

      watch(modalForm, (newValues) => {
        if (newValues.initCurrentForm) resetForm()
      })

      return {
        newCommittee,
        canDo,
        schema,
        currentCommittee,
        handleForm,
        deleteCompanyCommittee,
        handleReset
      }
    }
  })
</script>
