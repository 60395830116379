
  import Spinner from '@/components/Spinner/index.vue'

  export default {
    name: 'EmptyState',
    components: { Spinner },
    props: {
      image: {
        type: String,
        required: false,
        default: ''
      },
      loading: Boolean,
      title: {
        type: String,
        required: false,
        default: ''
      },
      subtitle: {
        type: String,
        required: false,
        default: ''
      },
      hasLabel: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
