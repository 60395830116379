
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import { CompanyFreeSharesVesting, CompanyVesting } from '@/services/api/models'
  import { useAcl } from 'vue-simple-acl/src'
  import moment from 'moment'

  export default defineComponent({
    components: { Button, Input, Field },
    setup() {
      const search = ref('')

      const store = useStore()
      const acl = useAcl()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-vestings'))
      )

      const optionsVestings = computed<CompanyVesting[]>(
        () => store.getters['organizations/vestings']
      )
      const freeSharesVestings = computed<CompanyFreeSharesVesting[]>(
        () => store.getters['organizations/freeSharesVestings']
      )

      const vestings = computed(() =>
        [...optionsVestings.value, ...freeSharesVestings.value].sort(
          (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix()
        )
      )

      const usedVestings = computed(() =>
        vestings.value.filter(
          (x: any) =>
            (x?.optionIssuanceParts || x?.freeShareIssuanceParts || []).length > 0
        )
      )
      const currentVesting = computed<CompanyVesting>(
        () => store.getters['organizations/vesting']
      )
      const listedVestings = ref(vestings.value)

      const notUsedVestings = computed(() =>
        vestings.value.filter(
          (x: any) =>
            (x?.optionIssuanceParts || x?.freeShareIssuanceParts || []).length ===
            0
        )
      )
      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })
        store.commit('organizations/SET_CURRENT_VESTING', null)
      }

      const getVestings = (name?: string) => {
        const filter: any = {}
        if (name) filter.name = name
        store.dispatch('organizations/GET_COMPANY_VESTINGS', {
          companyId: currentCompany.value.id,
          filter
        })

        store.dispatch('organizations/GET_FREE_SHARES_VESTINGS', {
          companyId: currentCompany.value.id,
          filter
        })
      }

      const getVesting = (vestingId: string) => {
        if (freeSharesVestings.value.map((x) => x.id).includes(vestingId)) {
          store.dispatch('organizations/GET_FREE_SHARES_VESTING', {
            companyId: currentCompany.value.id,
            vestingId
          })
        } else
          store.dispatch('organizations/GET_COMPANY_VESTING', {
            companyId: currentCompany.value.id,
            vestingId
          })
      }

      watch(search, (text: string) => {
        getVestings(text)
      })

      watch(vestings, (stocks) => {
        listedVestings.value = stocks
      })

      onBeforeMount(() => {
        getVestings()
      })

      return {
        currentCompany,
        currentVesting,
        search,
        canDo,
        notUsedVestings,
        usedVestings,
        initStockForm,
        getVesting
      }
    }
  })
