<template>
  <div
    :class="`flex flex-1 justify-${
      $can.any(['is-manager', 'admin']) ? 'center' : 'start'
    } items-center h-full`"
  >
    <ul class="layout_menu-list">
      <router-link
        v-can.any="['admin', 'is-manager', ['read-corporate']]"
        v-role.not="'employee'"
        :to="{ name: 'portfolioView.corporate' }"
      >
        <li>{{ $t('menu.search') }}</li>
      </router-link>

      <router-link
        v-can.any="['admin', 'is-manager']"
        v-role.not="'employee'"
        :to="{ name: 'portfolioView.charts' }"
      >
        <li>{{ $t('menu.chart') }}</li>
      </router-link>
    </ul>
  </div>
  <div class="flex items-center justify-center h-full">
    <a
      class="text-lg text-primary3"
      :href="portfolioLink"
      rel="noopener noreferrer"
    >
      Vue Par société <span class="bi bi-arrow-right-circle"></span>
    </a>
  </div>
</template>
<script lang="ts">
  import { CompanyModel } from '@/services/api/models1'
  import { computed, defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  import { useAcl } from 'vue-simple-acl/src'
  import { useStore } from 'vuex'

  export default defineComponent({
    inject: ['$access'],

    setup() {
      const acl = useAcl()
      const store = useStore()
      const route = useRoute()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const portfolioLink = computed(() => `${location.origin}`)

      const isInRegisterMenu = computed(
        () =>
          route.name?.toString() !== 'register.stockMovement' &&
          route.name?.toString().split('.').indexOf('register') == 0
      )

      return { currentCompany, isInRegisterMenu, acl, portfolioLink }
    }
  })
</script>
