
  import { VestingAcceleration } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  export default defineComponent({
    name: 'VestingCard',
    components: { ContactBadge, Tag },
    props: {
      event: {
        type: Object as () => VestingAcceleration | undefined,
        required: true
      }
    },
    setup() {
      return { moment }
    }
  })
