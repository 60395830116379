
  import { computed, defineComponent, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { DocumentAuditAnomalies, AuditForm, AuditType } from '@/types'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    components: { Form, Field, Checkbox },
    props: {
      reasons: { type: Array, required: false, default: () => [] },
      type: { type: String as () => AuditType, required: true },
      label: { type: String, required: false, default: null }
    },
    emits: ['change'],
    setup(props, { emit }) {
      const store = useStore()
      const newAudit = ref<AuditForm>({
        motives: [],
        comment: '',
        processed: false
      })
      const modalForm = computed(() => store.getters['organizations/form'])

      const auditReasons = computed<any[]>(() => props.reasons)
      const toggleMotif = (reason: DocumentAuditAnomalies) => {
        if (newAudit.value.motives.includes(reason)) {
          newAudit.value.motives = newAudit.value.motives.filter(
            (x) => x !== reason
          )
          emit('change', newAudit.value)
          return
        }
        newAudit.value.motives.push(reason)
        emit('change', newAudit.value)
      }
      const handleBlur = (values: AuditForm) => {
        emit('change', values)
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newAudit.value = {
            motives: [],
            comment: '',
            processed: false
          }
          return
        }
      })
      return { newAudit, auditReasons, toggleMotif, handleBlur }
    }
  })
