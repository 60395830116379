<template>
  <li
    v-if="document"
    :class="[
      isDocumentSelected ? 'selected text-md' : '',
      'my-2 ml-3 p-1 flex items-center justify-start upl-treeview__document'
    ]"
    @click="emit('selectDocument', document)"
  >
    <i
      :class="[
        document.isDraft
          ? 'bi bi-file-earmark-x-fill text-error'
          : !document.isDraft
          ? 'bi-file-earmark-check-fill text-blueWhite'
          : isDocumentSelected
          ? 'bi-file-earmark-fill'
          : 'bi-file-earmark',
        isDocumentSelected ? 'md' : 'sm'
      ]"
    />
    <div class="ml-2 cursor-pointer flex items-center select-none">
      <span
        :class="[
          { selected: isDocumentSelected },
          'date mr-2 whitespace-nowrap'
        ]"
      >
        <span v-if="byDate" class="text-xs">{{
          document.docSubCategory?.name || '-'
        }}</span>
        <template v-else>
          <p v-if="document.signedAt">
            {{ moment.utc(document.signedAt).format('l') }}
          </p>
          <p v-else>{{ $t('global.dateFormatPlaceHolder') }}</p>
        </template>
      </span>
      <span :class="[{ 'selected text-md': isDocumentSelected }, 'subline']">
        <p>{{ document.label || document.name }}</p>
      </span>
    </div>
  </li>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted } from 'vue'
  import { Document as DocumentModel } from '@/services/api/models'
  import moment from 'moment'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'Document',

    props: {
      document: {
        type: Object,
        required: true
      },
      byDate: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['selectDocument', 'selectedDocument'],

    setup(props, { emit }) {
      const store = useStore()
      const currentDocument = computed<DocumentModel | null>(
        () => store.getters['organizations/currentDocument']
      )
      const isDocumentSelected = computed(
        () => props.document.id === currentDocument.value?.id
      )

      onMounted(() => {
        if (isDocumentSelected.value) {
          emit('selectedDocument')
        }
      })

      return { currentDocument, isDocumentSelected, moment, emit }
    }
  })
</script>

<style lang="scss" scoped>
  .upl-treeview__document:hover {
    background-color: var(--color-primary-lower);
  }
  .upl-treeview__document.selected {
    color: var(--color-primary);
  }
  .upl-treeview__document span.date {
    font: var(--text-medium-s);
    &.selected {
      font-weight: bold;
      font-size: var(--fs-s);
    }
  }
  .upl-treeview__document span.subline {
    font: var(--text-regular-xs);
    &.selected {
      font-weight: 700;
    }
  }
</style>
