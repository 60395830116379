<template>
  <div class="end-of-contract">
    <DataTable :rows="data" :columns="columns" cell-padding="p-0">
      <template #reason="{ row }">
        <span class="bg-active text-black px-2 py-1 rounded-xl">{{
          row.name
        }}</span>
      </template>
      <template #nv_lapse="{ row }">
        <div class="h-10">
          <Field
            v-slot="{ field }"
            v-model="endOfContractBehaviors[row.id].notVestedPercent"
            :name="`endOfContractBehaviors.${row.id}.notVestedPercent`"
          >
            <Input
              id="name"
              v-bind="field"
              v-model="endOfContractBehaviors[row.id].notVestedPercent"
              type="number"
              :name="`endOfContractBehaviors.${row.id}.notVestedPercent`"
              class="h-full"
              :placeholder="
                $t('vestingBook.endOfContracts.nv_lapse_placeholder')
              "
              no-error
            />
          </Field>
        </div>
      </template>
      <template #v_lapse="{ row }">
        <div class="h-10">
          <Field
            v-slot="{ field }"
            v-model="endOfContractBehaviors[row.id].vestedPercent"
            :name="`endOfContractBehaviors.${row.id}.vestedPercent`"
          >
            <Input
              id="name"
              v-bind="field"
              v-model="endOfContractBehaviors[row.id].vestedPercent"
              type="number"
              :name="`endOfContractBehaviors.${row.id}.vestedPercent`"
              class="h-full"
              :placeholder="
                $t('vestingBook.endOfContracts.v_lapse_placeholder')
              "
              no-error
            />
          </Field>
        </div>
      </template>

      <template #nv_lapse_date="{ row }">
        <div class="flex">
          <div class="h-10">
            <Field
              v-slot="{ field }"
              v-model="endOfContractBehaviors[row.id].notVestedPeriod.value"
              :name="`endOfContractBehaviors.${row.id}.notVestedPeriod.value`"
            >
              <Input
                v-model="endOfContractBehaviors[row.id].notVestedPeriod.value"
                v-bind="field"
                type="number"
                :name="`endOfContractBehaviors.${row.id}.notVestedPeriod.value`"
                class="h-full"
                :placeholder="
                  $t('vestingBook.endOfContracts.nv_lapse_date_placeholder')
                "
                no-error
              />
            </Field>
          </div>
          <div class="h-10 w-2/3">
            <Field
              v-slot="{ field }"
              v-model="endOfContractBehaviors[row.id].notVestedPeriod.timeUnit"
              :name="`endOfContractBehaviors.${row.id}.notVestedPeriod.timeUnit`"
            >
              <Multiselect
                id="timeUnit"
                v-model="
                  endOfContractBehaviors[row.id].notVestedPeriod.timeUnit
                "
                open-direction="top"
                v-bind="field"
                mode="single"
                :name="`endOfContractBehaviors.${row.id}.notVestedPeriod.timeUnit`"
                :options="timeUnits"
                :placeholder="$t('vestingBook.form.timeUnitPlaceholder')"
                :groups="false"
                @change="
                  endOfContractBehaviors[row.id].notVestedPeriod.timeUnit =
                    $event
                "
              />
            </Field>
          </div>
        </div>
      </template>
      <template #v_lapse_date="{ row }">
        <div class="flex">
          <div class="h-10">
            <Field
              v-slot="{ field }"
              v-model="endOfContractBehaviors[row.id].vestedPeriod.value"
              :name="`endOfContractBehaviors.${row.id}.vestedPeriod.value`"
            >
              <Input
                v-model="endOfContractBehaviors[row.id].vestedPeriod.value"
                v-bind="field"
                type="number"
                :name="`endOfContractBehaviors.${row.id}.vestedPeriod.value`"
                class="h-full"
                :placeholder="
                  $t('vestingBook.endOfContracts.v_lapse_date_placeholder')
                "
                no-error
              />
            </Field>
          </div>
          <div class="h-10 w-2/3">
            <Field
              v-slot="{ field }"
              v-model="endOfContractBehaviors[row.id].vestedPeriod.timeUnit"
              :name="`endOfContractBehaviors.${row.id}.vestedPeriod.timeUnit`"
            >
              <Multiselect
                id="timeUnit"
                v-model="endOfContractBehaviors[row.id].vestedPeriod.timeUnit"
                open-direction="top"
                v-bind="field"
                mode="single"
                :name="`endOfContractBehaviors.${row.id}.vestedPeriod.timeUnit`"
                :options="timeUnits"
                :placeholder="$t('vestingBook.form.timeUnitPlaceholder')"
                :groups="false"
                @change="
                  endOfContractBehaviors[row.id].vestedPeriod.timeUnit = $event
                "
              />
            </Field>
          </div>
        </div>
      </template>
    </DataTable>
    <div class="mt-5 flex flex-col">
      <span
        ><strong>NV</strong>: {{ $t('vestingBook.endOfContracts.nv') }}</span
      >
      <span><strong>V</strong>: {{ $t('vestingBook.endOfContracts.v') }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { EndOfContractReasons, TIMEUNIT, VestingForm } from '@/types'
  import { useI18n } from 'vue-i18n'
  import { Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'

  export default defineComponent({
    components: { DataTable, Input, Field, Multiselect },
    props: {
      vesting: {
        type: Object as () => VestingForm,
        required: true
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const { t } = useI18n()
      const endOfContractBehaviors = ref<any>(
        props.vesting.endOfContractBehaviors
      )

      const timeUnits = ref(
        Object.keys(TIMEUNIT).map((x) => ({
          name: t(`timeUnits.${x}`),
          value: x,
          id: x
        }))
      )

      const data = ref(
        Object.keys(EndOfContractReasons).map((x) => ({
          name: t(`endOfContractReasons.${x}`),
          value: x,
          id: x,
          class: `tag-reason tag-reason-${x.toLowerCase()}`
        }))
      )

      const columns = ref([
        {
          label: t(`vestingBook.endOfContracts.reason`),
          field: 'reason',
          width: '20%',
          custom: true
        },
        {
          label: `${t(`vestingBook.endOfContracts.v_lapse`)} (V)`,
          field: 'v_lapse',
          width: '7%',
          custom: true
        },
        {
          label: `${t(`vestingBook.endOfContracts.v_lapse_date`)} (V)`,
          field: 'v_lapse_date',
          width: '',
          custom: true
        },
        {
          label: `${t(`vestingBook.endOfContracts.nv_lapse`)} (NV)`,
          field: 'nv_lapse',
          custom: true,
          width: '7%',
          info: 'Info'
        },
        {
          label: `${t(`vestingBook.endOfContracts.nv_lapse_date`)} (NV)`,
          field: 'nv_lapse_date',
          custom: true
        }
      ])

      watch(
        () => props.vesting,
        (newProps: any) => {
          if (props.vesting?.endOfContractBehaviors) {
            Object.keys(EndOfContractReasons).forEach((x) => {
              endOfContractBehaviors.value[x] = {
                notVestedPercent:
                  newProps?.endOfContractBehaviors[x]?.notVestedPercent || 0,
                vestedPercent:
                  newProps?.endOfContractBehaviors[x]?.vestedPercent || 0,
                notVestedPeriod: {
                  timeUnit:
                    newProps?.endOfContractBehaviors[x]?.notVestedPeriod
                      ?.timeUnit || TIMEUNIT.MONTH,
                  value:
                    newProps?.endOfContractBehaviors[x]?.notVestedPeriod
                      ?.value || 90
                },
                vestedPeriod: {
                  timeUnit:
                    newProps?.endOfContractBehaviors[x]?.vestedPeriod
                      ?.timeUnit || TIMEUNIT.MONTH,
                  value:
                    newProps?.endOfContractBehaviors[x]?.vestedPeriod?.value ||
                    90
                }
              }
            })
          }
        }
      )

      return {
        columns,
        data,
        timeUnits,
        endOfContractBehaviors
      }
    }
  })
</script>
<style lang="scss">
  .end-of-contract {
    @apply flex flex-col  w-full;
    .v_lapse_date,
    .nv_lapse_date {
      // @apply bg-secondary1;
      padding: 0;
    }

    .v_lapse,
    .nv_lapse {
      padding: 0;
    }

    .tag-reason {
      @apply px-2 py-1 rounded-xl mx-1;
    }
    .tag-reason-dismissal {
      background: #abecc6;
    }
    .tag-reason-conventionalrupture {
      background: #bdccda;
    }
    .tag-reason-inability {
      background: #fad69d;
    }
    .tag-reason-revocation {
      background: #d5b5e2;
    }
    .tag-reason-resignation {
      color: black;
      background: #f3aba4;
    }
    .tag-reason-death {
      color: black;
      background: #d96458;
    }
  }
</style>
