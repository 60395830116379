
  import { User } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    setup() {
      const store = useStore()
      const user = computed<User>(() => store.getters['auth/profile'])
      const email = computed<string>(() => user.value?.email)
      return { email }
    }
  })
