import { company } from '../endpoints'
import axios from '../axios'
import { ContactGroupFormData } from '@/types/forms'

const getStockGroups = async (companyId: string, page = 1, search = null) => {
  const params: any = {}
  if (search) params.search = search
  const response = await axios.get(company.getStocksGroups(companyId), {
    params
  })
  return response
}

const getStockGroup = async (companyId: string, groupId = '') => {
  const response = await axios.get(company.singleStockGroup(companyId, groupId))
  return response
}

const deleteStockGroup = async (companyId: string, groupId = '') => {
  const response = await axios.delete(
    company.singleStockGroup(companyId, groupId)
  )
  return response
}

const createStockGroup = async (
  companyId: string,
  form: ContactGroupFormData
) => {
  const { data } = await axios.post(company.getStocksGroups(companyId), form)
  return data
}

const editStockGroup = async (
  companyId: string,
  id: string,
  form: ContactGroupFormData
) => {
  const { data } = await axios.put(
    company.singleStockGroup(companyId, id),
    form
  )
  return data
}

export {
  getStockGroups,
  getStockGroup,
  createStockGroup,
  editStockGroup,
  deleteStockGroup
}
