import * as yup from 'yup'
import { PARITYTYPE, STOCKTYPE } from '@/types'

const arraySchema = yup.object({
  parity: yup.number().min(0).positive().integer(),
  underlayingOptionId: yup.string().required()
})

export const sampleStockSchema = yup.object().shape({
  underlayingOption: yup.string().optional().default(''),
  alias: yup.string().required('aliasRequired'),
  stockCategoryId: yup.string().required('categoryIdRequired'),
  areDetached: yup.boolean().optional()
})

export const optionStockSchema = yup.object({
  underlayingStockId: yup.string().required('underlayingStockIdRequired'),
  alias: yup.string().required('aliasRequired'),
  optionCategoryId: yup.string().required('optionIdRequired'),
  exercisePrices: yup
    .array()
    .of(
      yup
        .object({
          value: yup.number().positive().typeError('exercisePricesType')
        })
        .nullable()
    )
    .min(0)
    .optional(),
  parityType: yup
    .string()
    .required('requiredParityType')
    .oneOf([PARITYTYPE.FIXED, PARITYTYPE.VARIABLE]),
  parity: yup
    .number()
    .positive()
    .default(0)
    .min(0, 'minParity')
    .typeError('numberTypeError')
    .when('parityType', {
      is: PARITYTYPE.VARIABLE,
      then: yup.number().nullable().typeError('').optional()
    })
})

export const compositionStockSchema = yup.object({
  mainStockAliasId: yup.string().required('requiredMainStockAliasId'),
  alias: yup.string().required('aliasRequired').typeError('stringTypeError'),
  underlayingOptions: yup
    .array()
    .of(
      yup.object({
        parity: yup
          .number()
          .integer()
          .positive()
          .min(0)
          .typeError('numberTypeError'),
        underlayingOptionId: yup.string().required('underlayingStockIdRequired')
      })
    )
    .required('requiredAssociatedStock')
    .min(1)
})

const aliasSchema = yup.object({
  name: yup.string().required(),
  color: yup.string().optional()
})
export const stockSchema = yup.object({
  categoryId: yup.string().required('categoryIdRequired'),
  alias: yup.string().required(),
  stockCategoryId: yup.string().required(),
  optionData: yup
    .object({
      parity: yup.number().min(0).positive().integer(),
      stockId: yup.string().required('stockIdRequired')
    })
    .when('type', (value: STOCKTYPE, schema: yup.BaseSchema) => {
      return value === STOCKTYPE.OPTION
        ? schema.optional()
        : schema.nullable().optional().notRequired()
    }),
  compositionData: yup
    .array()
    .of(arraySchema)
    .when('type', (value: STOCKTYPE, schema: yup.BaseSchema) => {
      return value == STOCKTYPE.COMPOSITION
        ? schema.required().min(1)
        : schema.nullable().optional().notRequired()
    }),
  secondaryAliases: yup.array().of(aliasSchema).min(0)
})
