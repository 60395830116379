import { useAcl } from 'vue-simple-acl/src'
import { RouteLocationNormalized } from 'vue-router'
const userCanSee: any = (to: RouteLocationNormalized) => {
  const acl = useAcl()

  try {
    const anyCan: any = to.meta?.anyCan || []

    const notCan: any = to.meta?.notCan

    if (anyCan.length === 0) return true
    return acl.anyRoleOrPermission(anyCan) && acl.can.not(notCan)
  } catch (error) {
    console.log('[Error in userCanSee]', error)
    return false
  }
}

export default userCanSee

export const canMakeAction = (rule: string) => {
  const acl = useAcl()
  return acl.anyRoleOrPermission(rule)
}
