import i18n from '@/services/lang'
import { CONTACTTYPE } from '@/types'
import humanizeDuration from 'humanize-duration'
import { isNumber } from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { getFormStorage } from '@/services/Storage'

declare global {
  interface Window {
    Intercom?: any
  }
}

export const abbreviateNumber = (number: number): string => {
  const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E']
  const tier = (Math.log10(Math.abs(number)) / 3) | 0
  if (tier == 0) return `${number}`
  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)
  const scaled = number / scale

  return `+${scaled.toFixed(1)}${suffix}`
}

export const truncText = (text = '', max = 50): string => {
  return `${text.length > max ? `${text.substring(0, max)}...` : text}`
}

export const ucFirst = (text = '') => {
  const value = text
    .split(' ')
    .map((elm) => `${elm.charAt(0).toUpperCase()}${elm.slice(1).toLowerCase()}`)
    .join(' ')
  return value
}

export const createInitials = (value: string): string => {
  return value
    .replaceAll('&', '')
    .replaceAll('  ', ' ')
    .trimEnd()
    .trimStart()
    .split(' ')
    .map((x, i) => (i <= 1 ? x.charAt(0) : ''))
    .join('')
    .toUpperCase()
}

export const groupItemBy = (
  array: Array<any>,
  keyGetter: (it: any) => string
): Map<string, any[]> => {
  const map = new Map()
  array.forEach((item: any) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const rgbToHex = (colorCode = '#ebedf0'): string => {
  if (!colorCode || colorCode?.charAt(0) === '#') {
    return '#3b69da'
  } else {
    const r: any = colorCode?.split(',')[0]?.split('(')[1]
    const g: any = colorCode?.split(',')[1]?.split(' ')[1]
    const b: any = colorCode?.split(',')[2]?.split(')')[0]?.split(' ')[1]
    const hex = ((r << 16) | (g << 8) | b)?.toString(16)
    return '#' + ('000000' + hex).slice(-6)
  }
}

export async function UrlExists(url: any) {
  // const http = new XMLHttpRequest()
  // http.open('HEAD', url, false)
  // http.send()

  const resp = await axios.get(url)
  // if (http.status != 404) return true
  return resp.status !== 404
}

export const sortObjectByKeys = (o: Map<string, any[]>): Map<string, any[]> => {
  return new Map(
    Object.entries(
      [...o.keys()]
        .sort()
        .reduce((r: any, k: any) => ((r[k] = o.get(k)), r), {})
    )
  )
}

export const capitalizeWord = (word: string) => {
  const value = word
    .split(' ')
    .map((w) => {
      const loweredCase = w.toLowerCase()
      return w[0].toUpperCase() + loweredCase.slice(1)
    })
    .join(' ')

  console.log(word, value)
}

export const storageKeys = {
  lastRoute: '@route/last',
  isMenuCompressed: '@ui/menuCompressed',
  currentCompany: `organizations/currentCompany`,
  currentCompanyName: 'organizations/currentCompanyName',
  userLang: '@userLang'
}

export const statusFilterOptionsKeys = [
  'ALL',
  'PENDING',
  'ANOMALY',
  'COMPLETED'
]
export const statusFilterExtractionContactsKeys = [
  'ALL',
  'PENDING',
  'COMPLETED'
]

export enum ENV_TYPE {
  developpement = 'developpement',
  staging = 'staging',
  production = 'production'
}

export const formatCurrency = (
  value: number,
  currency = 'EUR',
  locale = 'de-DE'
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
    currencyDisplay: 'narrowSymbol'
  })
    .format(value)
    .replaceAll(' ', '.')
}

export const formatPercentage = (
  value: number,
  locale = 'fr-FR',
  decimal = 2
): string => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: decimal,
    style: 'percent'
  }).format(value)
}

type FormatNumericalValueOptions = {
  currency?: string
  locale?: string
  style?: string
  decimal?: number
}

export const formatNumericalValue = (
  value: number,
  type?: string,
  options?: FormatNumericalValueOptions
): string => {
  if (type === 'currency') {
    return formatCurrency(value, options?.currency, options?.locale)
  } else {
    if (options?.style === 'percent') {
      return formatPercentage(value, options?.locale)
    }
    return formatNumber(value)
  }
}

export const formatNumber = (number: any) => {
  const lang = `${i18n.global.locale}-${i18n.global.locale.toUpperCase()}`

  if (!isNumber(number)) return number
  if (!number) return 0

  return new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  }).format(number)
}

export const contactTypes = Object.values(CONTACTTYPE)

export const mediumScreenBreakpointWidth = 1280

export const calculateTimeDuration = (
  start: string,
  end: string,
  lang = 'fr'
) => {
  return humanizeDuration(
    moment
      .duration(moment(end).endOf('day').diff(moment(start).startOf('day')))
      .asMilliseconds(),
    { units: ['y', 'mo', 'w', 'd'], language: lang, round: true }
  )
}
