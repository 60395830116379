<template>
  <el-tooltip
    class="box-item"
    effect="dark"
    :content="document?.docSubCategory?.name || document?.label || ''"
    placement="top"
  >
    <span
      :key="document?.id"
      :class="`py-1
        w-auto
        items-center
        mr-1
        my-2
        cursor-pointer
        rounded
        bg-primary2
        text-primary5
        p-1 ${
          currentDocument?.id === document?.id ? 'border border-primary5' : ''
        }`"
      @click="handleDocumentView(document)"
    >
      <span v-if="hasSavedLink" class="py-1">
        <i class="bi bi-file-earmark"></i>
        {{ document?.label || document.name }}
        <el-popconfirm
          :confirm-button-text="$t('confirm.yes')"
          :cancel-button-text="$t('confirm.no')"
          :title="$t('global.confirmDocAttachmentDelete')"
          @confirm.stop="unlinkDocument(eventPart?.id, document?.id)"
        >
          <template #reference>
            <span
              class="
                bi bi-x
                hover:bg-primary15
                p-1
                text-center
                rounded
                bg-primary1
              "
            ></span>
          </template>
        </el-popconfirm>
      </span>
      <span v-else>
        <i class="bi bi-file-earmark"></i>
        {{ document?.label }}
        <i
          class="bi bi-x hover:bg-primary15 p-1 rounded"
          @click.prevent.stop="removeFile(document?.id, eventPart?.id)"
        ></i>
      </span>
    </span>
  </el-tooltip>
</template>
<script lang="ts">
  import { CompanyModel, Event } from '@/services/api/models'
  import { computed, defineComponent, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { useStore } from 'vuex'
  import { Document } from '@/services/api/models/document'
  import { ElPopconfirm, ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'EventDocumentTag',
    components: { ElPopconfirm, ElTooltip },
    props: {
      event: { type: Object as () => Event, default: null },
      document: { type: Object as () => Document, default: null },
      eventPart: { type: Object, default: null },
      hasSavedLink: { type: Boolean, default: false }
    },
    emits: ['fileDeleted'],
    setup(props, { emit }) {
      const { event } = toRefs(props)
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const removeFile = (docId?: string, contactId?: string) => {
        if (!docId || !contactId) return
        store.commit('organizations/SET_CURRENT_PART_CONTACT_ID', contactId)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', {
          files: [],
          remove: true,
          docId
        })
      }

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const handleDocumentView = async (doc?: Document) => {
        if (currentDocument?.value?.id === doc?.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc?.id
        })
      }
      const unlinkDocument = (partId: string, documentId?: string) => {
        if (!partId && documentId) {
          removeDocument(documentId)
          return
        }
        store.dispatch('organizations/DETACH_DOCUMENTS_TO_EVENT_PART', {
          type: event.value?.type,
          eventTypeId:
            event.value?.optionExercise?.id ||
            event.value?.optionIssuance?.id ||
            event.value?.stockIssuance?.id ||
            event.value?.compositionIssuance?.id,
          partId,
          companyId: route.params.id,
          eventId: route.params.eventId,
          documentId,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            emit('fileDeleted', documentId)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      const removeDocument = (docId: string) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: currentCompany.value.id,
          eventId: event.value?.id,
          documentId: docId,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            emit('fileDeleted', docId)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      return {
        currentDocument,
        removeDocument,
        unlinkDocument,
        removeFile,
        handleDocumentView
      }
    }
  })
</script>
