
  import { ExerciseCalendar } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { formatNumber } from '@/utils/global'

  export default defineComponent({
    name: 'StockQuantity',
    props: {
      classes: {
        type: String,
        default: ''
      }
    },
    setup() {
      const store = useStore()
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, v) => (acc += v.exercisableBalance),
          0
        )
      )
      const nonExercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, v) => (acc += v.optionIssuancePart.quantity - v.vestedQuantity),

          0
        )
      )

      return { exercisableQuantity, nonExercisableQuantity, formatNumber }
    }
  })
