
  import {
    AuditForm,
    AuditType,
    ContactAuditAnomalies,
    CONTACTTYPE,
    IndividualContactForm,
    InvestorContactForm,
    ProfessionalContactForm
  } from '@/types'
  import { contactTypes } from '@/utils'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import PersonalForm from './PersonalForm.vue'
  import InvestorForm from './InvestorForm.vue'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import ProfessionalForm from './ProfessionalForm.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { CompanyModel } from '@/services/api/models1'
  import { useI18n } from 'vue-i18n'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { useStore } from 'vuex'
  import { Contact } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      PersonalForm,
      InvestorForm,
      ProfessionalForm,
      Button,
      UplAuditForm,
      Checkbox,
      ElPopconfirm
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const types = ref(contactTypes)
      const { t } = useI18n()
      const hasAnomaly = ref(false)
      const loadedFile = ref<File | null>()
      const audit = ref<AuditForm>()
      const selectedType = ref<CONTACTTYPE>(CONTACTTYPE.INDIVIDUAL)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentContact = computed<Contact>(
        () => store.getters['organizations/contact']
      )
      const acl = useAcl()
      const modalForm = computed(() => store.getters['organizations/form'])
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )
      const contact = ref<Contact | null>(null)

      const allTypes = ref(CONTACTTYPE)

      const setType = (type: CONTACTTYPE) => {
        if (contact.value?.id) return
        selectedType.value = type
      }

      const getContactGroups = () => {
        store.dispatch('toolbox/GET_CONTACT_GROUPS')
      }
      const getLegalStatus = () => {
        store.dispatch('toolbox/GET_LEGAL_STATUS')
      }
      const getGroups = () => {
        store.dispatch('organizations/GET_COMPANY_GROUPS', {
          companyId: currentCompany.value.id
        })
      }
      const updateProfileImage = (contactId: string) => {
        if (!loadedFile.value) return
        const form = new FormData()
        form.append('fileName', loadedFile.value?.name)
        form.append('file', loadedFile.value)
        store.dispatch('organizations/UPDATE_CONTACT_PROFILE_IMAGE', {
          companyId: currentCompany.value.id,
          form,
          contactId,
          onProgress: () => {
            console.log()
          },
          onSuccess: () => {
            loadedFile.value = null
            store.commit('organizations/SET_CONTACT_PICTURE', {
              contactId,
              url: null
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message: t(`contactBook.form.notSaved`)
            })
          }
        })
      }

      const handleForm = async (
        values:
          | IndividualContactForm
          | ProfessionalContactForm
          | InvestorContactForm
      ) => {
        const formValues: any = values
        for (const key in formValues) {
          if (formValues[key] === '' || !formValues[key]) {
            delete formValues[key]
          }
        }

        let data = {
          companyId: currentCompany.value.id,
          contactId: '',
          formData: formValues
        }
        let action = 'CREATE_CONTACT'
        let key = 'new'

        if (currentContact.value && currentContact.value.id) {
          action = 'UPDATE_CONTACT'
          data.contactId = currentContact.value.id
          key = 'edit'
        }

        await store.dispatch(`organizations/${action}`, {
          ...data,
          onError: (error = null) => {
            const message =
              error ||
              t(
                `contactBook.apiResponse.error_${values.type.toLowerCase()}_${key}`
              )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message
            })
          },
          onSuccess: async (contactId: string) => {
            createNewAudit(contactId ?? data.contactId)
            updateProfileImage(contactId ?? data.contactId)
            if (route.params.contactId) {
              await store.dispatch('organizations/GET_COMPANY_CONTACT', {
                companyId: currentCompany.value.id,
                contactId: route.params.contactId
              })
            }
            store.dispatch('auth/GET_CONTACTS', {
              filter: { companyId: route.params?.id }
            })
            store.dispatch('organizations/INIT_MODAL_FORM', {
              modalInit: true,
              initCurrentForm: true
            })
            store.commit('organizations/SET_CURRENT_CONTACT', null)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('contactBook.form.title'),
              message: t(
                `contactBook.apiResponse.success_${values.type.toLowerCase()}_${key}`
              )
            })
          }
        })
      }

      const createNewAudit = (contactId: string) => {
        if (hasAnomaly.value && contactId) {
          const formData = {
            motive: audit.value?.motives.join(','),
            processed: false,
            comment: audit.value?.comment
          }
          store.dispatch('organizations/CREATE_AUDIT', {
            companyId: currentCompany.value.id,
            type: AuditType.CONTACT,
            typeDataId: contactId,
            formData,
            onSuccess: () => {
              hasAnomaly.value = false
            },
            onError: () => {
              console.log('ERROR CREATE_AUDIT')
            }
          })
        }
      }

      const deleteContact = async () => {
        await store.dispatch(`organizations/DELETE_CONTACT`, {
          companyId: currentCompany.value.id,
          contactId: currentContact.value.id,
          onError: (error = null) => {
            const message = error || t(`contactBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            selectedType.value = CONTACTTYPE.INDIVIDUAL
            store.commit('organizations/SET_CURRENT_CONTACT', null)
            store.dispatch('organizations/INIT_MODAL_FORM', {
              ...store.getters['organizations/form'],
              modalInit: false,
              initCurrentForm: true
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('contactBook.form.title'),
              message: t(`contactBook.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      const fileChanged = (file: File) => {
        if (file.size / 1024 / 1024 > 5 * 1024 * 1024) {
          store.dispatch('toolbox/displayToast', {
            type: TYPE.ERROR,
            context: t('contactBook.form.profileImage'),
            message: t(`contactBook.form.profileImageTooLarge`)
          })
          return
        } else loadedFile.value = file
      }

      const updateAudit = (values: AuditForm) => {
        audit.value = values
      }

      watch(currentContact, (c) => {
        if (c) {
          selectedType.value = c.type
        }
        setTimeout(() => {
          contact.value = c
        }, 10)
      })
      watch(modalForm, (value) => {
        if (value.modalInit) {
          selectedType.value = CONTACTTYPE.INDIVIDUAL
          contact.value = null
        }
      })
      onMounted(() => {
        getContactGroups()
        getLegalStatus()
        getGroups()
      })

      return {
        types,
        canDo,
        selectedType,
        allTypes,
        contact,
        setType,
        AuditType,
        handleForm,
        deleteContact,
        ContactAuditAnomalies,
        hasAnomaly,
        updateAudit,
        fileChanged
      }
    }
  })
