<template>
  <div>
    <h3 class="my-5 text-blackp text-left">
      {{ $t('contactBook.form.personalInformation') }}
    </h3>
    <Form
      v-slot="{ errors, meta, setFieldValue }"
      :validation-schema="schema"
      autocomplete="off"
      @submit="handleForm"
      @reset="handleReset"
    >
      <input id="profileImage" type="file" hidden name="profileImage" />
      <div :class="`flex ${topAlignClass} justify-between text-sm`">
        <div class="w-1/2 flex">
          <profile-avatar-form
            :profile-image="contact?.picture?.pictureURL"
            :contact-id="contact?.id"
            @file-changed="fileChanged"
          />
        </div>
        <div :class="`w-1/2 flex ${topAlignClass ? 'mt-2' : ''}`">
          <div class="w-full flex flex-col">
            <label
              class="text-xs text-secondary8 mb-1 text-left font-semibold"
              for="contactGroup"
              >{{ $t('contactBook.fields.group') }}</label
            >

            <Multiselect
              id="contactGroupsId"
              v-model="newContact.contactGroupsId"
              :value="newContact.contactGroupsId"
              :class="`w-full ${
                newContact.contactGroupsId.length > 0 ? 'min-' : ''
              }h-10`"
              mode="multiple"
              elementName="contactBook.groups"
              selectionText="global.actions.selected1"
              :tag-with-initials="false"
              name="contactGroupsId"
              :options="groups"
              :placeholder="$t('contactBook.placeholder.group')"
              :groups="false"
              @change="newContact.contactGroupsId = $event"
            />
          </div>
        </div>
      </div>
      <div class="w-full flex justify-between mt-3">
        <div class="w-1/4 mr-2">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold"
              for="gender"
              >{{ $t('contactBook.fields.gender') }}</label
            >
          </div>

          <Field v-slot="{ field }" v-model="newContact.gender" name="gender">
            <Multiselect
              id="stockType"
              v-model="newContact.gender"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              name="gender"
              :options="genders"
              :placeholder="$t('contactBook.placeholder.gender')"
              :groups="false"
              @change="newContact.gender = $event"
              @search-change="handleSearch"
            />
          </Field>
        </div>

        <div class="w-1/4 mr-2">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold"
              for="firstName"
              >{{ $t('contactBook.fields.firstName') }}</label
            >
            <strong class="ml-1 text-error h-4">*</strong>
          </div>
          <Field
            v-slot="{ field }"
            v-model="newContact.firstName"
            name="firstName"
          >
            <Input
              v-model="newContact.firstName"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="firstName"
              :placeholder="$t('contactBook.placeholder.firstName')"
            />
          </Field>
        </div>

        <div class="w-1/4 mr-2">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold"
              for="lastName"
              >{{ $t('contactBook.fields.lastName') }}</label
            >
            <strong class="ml-1 text-error h-4">*</strong>
          </div>
          <Field
            v-slot="{ field }"
            v-model="newContact.lastName"
            name="lastName"
          >
            <Input
              v-model="newContact.lastName"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="lastName"
              :placeholder="$t('contactBook.placeholder.lastName')"
            />
          </Field>
        </div>

        <div class="w-1/5">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold"
              for="maritalStatus"
              >{{ $t('contactBook.fields.maritalStatus') }}</label
            >
          </div>
          <Field
            v-slot="{ field }"
            v-model="newContact.familySituation"
            name="familySituation"
          >
            <Multiselect
              id="stockType"
              v-model="newContact.familySituation"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              name="familySituation"
              :options="maritalStatus"
              :placeholder="$t('contactBook.placeholder.maritalStatus')"
              :groups="false"
              @change="newContact.familySituation = $event"
              @search-change="handleSearch"
            />
          </Field>
        </div>
      </div>
      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="birthDate"
            >{{ $t('contactBook.fields.birthDate') }}</label
          >
          <UplDatePicker
            v-model="newContact.birthDate"
            class="hover:border-primary4 h-10 text-sm"
            :enable-time-picker="false"
            :placeholder="$t('contactBook.placeholder.birthDate')"
            close-on-scroll
            name="birthDate"
            @on-select="setFieldValue('birthDate', $event)"
          />
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="birthPlace"
            >{{ $t('contactBook.fields.birthPlace') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.birthPlace"
            name="birthPlace"
          >
            <Input
              v-model="newContact.birthPlace"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="birthPlace"
              :placeholder="$t('contactBook.placeholder.birthPlace')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="nationality"
            >{{ $t('contactBook.fields.nationality') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.nationality"
            name="nationality"
          >
            <Input
              v-model="newContact.nationality"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="nationality"
              :placeholder="$t('contactBook.placeholder.nationality')"
            />
          </Field>
        </div>
      </div>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/2 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="address"
            >{{ $t('contactBook.fields.address') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.address" name="address">
            <AddressInput
              v-model="newContact.address"
              v-bind="field"
              autocomplete="off"
              class="h-10"
              name="address"
              :placeholder="$t('contactBook.placeholder.address')"
              @onChange="onAddressInput($event)"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="postalcode"
            >{{ $t('contactBook.fields.postalCode') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.postalcode"
            name="postalcode"
          >
            <Input
              v-model="newContact.postalcode"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="postalcode"
              :placeholder="$t('contactBook.placeholder.postalCode')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="city"
            >{{ $t('contactBook.fields.city') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.city" name="city">
            <Input
              v-model="newContact.city"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="city"
              :placeholder="$t('contactBook.placeholder.city')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="country"
            >{{ $t('contactBook.fields.country') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.country" name="country">
            <Input
              v-model="newContact.country"
              v-bind="field"
              :disabled="false"
              type="text"
              class="h-10"
              name="country"
              :placeholder="$t('contactBook.placeholder.country')"
            />
          </Field>
        </div>
      </div>

      <h3 class="my-5 text-blackp text-left">
        {{ $t('contactBook.form.moreInformations') }}
      </h3>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="email"
            >{{ $t('contactBook.fields.email') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.email" name="email">
            <Input
              id="email"
              v-model="newContact.email"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="email"
              :placeholder="$t('contactBook.placeholder.email')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="phoneNumber"
            >{{ $t('contactBook.fields.phoneNumber') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.phoneNumber"
            name="phoneNumber"
          >
            <Input
              v-model="newContact.phoneNumber"
              v-bind="field"
              :disabled="false"
              type="text"
              class="h-10"
              name="phoneNumber"
              :placeholder="$t('contactBook.placeholder.phoneNumber')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="website"
            >{{ $t('contactBook.fields.website') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.website" name="website">
            <Input
              v-model="newContact.website"
              v-bind="field"
              :disabled="false"
              type="text"
              class="h-10"
              name="website"
              :placeholder="$t('contactBook.placeholder.website')"
            />
          </Field>
        </div>
      </div>
      <div class="w-full flex flex-start mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="internalId1"
            >{{ $t('contactBook.fields.internalId1') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.internalId1"
            name="internalId1"
          >
            <Input
              id="internalId1"
              v-model="newContact.internalId1"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="internalId1"
              :placeholder="$t('contactBook.placeholder.internalId1')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="internalId2"
            >{{ $t('contactBook.fields.internalId2') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.internalId2"
            name="internalId2"
          >
            <Input
              id="internalId2"
              v-model="newContact.internalId2"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="internalId2"
              :placeholder="$t('contactBook.placeholder.internalId2')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="mappingId"
            >{{ $t('contactBook.fields.mappingId') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.mappingId"
            name="mappingId"
          >
            <Input
              id="mappingId"
              v-model="newContact.mappingId"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="mappingId"
              :placeholder="$t('contactBook.placeholder.mappingId')"
            />
          </Field>
        </div>
      </div>
      <FormActions
        v-if="
          $acl.role('admin') ||
          ($acl.permission('is-manager') &&
            $acl.permission('write-contacts')) ||
          $acl.permission('employee-writeContact')
        "
        :errors="errors"
        :meta="meta"
      />
    </Form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, watch, onMounted } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import {
    ContactStatus,
    FamilySituationEnum,
    GenderEnum,
    IndividualContact
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CONTACTTYPE, IndividualContactForm } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { individualSchema } from '@/utils/schema'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import AddressInput from '@/components/Address/index.vue'
  import { AddressData } from '@/types/config'
  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      Input,
      UplDatePicker,
      FormActions,
      ProfileAvatarForm,
      AddressInput
    },
    props: {
      contact: {
        type: Object as () => IndividualContact | null | undefined,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const newValues = (): IndividualContactForm => ({
        id: props.contact?.id,
        lastName: props.contact?.lastName || '',
        firstName: props.contact?.firstName || '',
        gender: props.contact?.gender || GenderEnum.mr,
        type: CONTACTTYPE.INDIVIDUAL,
        nationality: props.contact?.nationality || null,
        country: props.contact?.country || null,
        contactGroupsId: props.contact?.contactGroups.map((x) => x.id) || [],
        contactStatusId: null,
        birthDate: props.contact?.birthDate
          ? props.contact?.birthDate.split('T')[0]
          : '' || null,
        birthPlace: props.contact?.birthPlace || null,
        email: props.contact?.email || null,
        phoneNumber: props.contact?.phoneNumber || null,
        address: props.contact?.address || null,
        postalcode: props.contact?.postcode || null,
        website: props.contact?.website || null,
        city: props.contact?.city || null,
        familySituation:
          props.contact?.familySituation || FamilySituationEnum.single,
        internalId1: props.contact?.internalId1 || null,
        internalId2: props.contact?.internalId2 || null,
        mappingId: props.contact?.mappingId || null
      })

      const modalForm = computed(() => store.getters['organizations/form'])

      const newContact = ref<IndividualContactForm>(newValues())
      const genders = ref(
        Object.values(GenderEnum).map((x) => ({
          name: t(`gender.${x}`),
          id: x
        }))
      )
      const maritalStatus = ref(
        Object.values(FamilySituationEnum)
          .map((x) => ({
            name: t(`familyStatus.${x}`),
            id: x
          }))
          .filter((y) => y.id !== FamilySituationEnum.other)
      )
      maritalStatus.value.push({
        name: t(`familyStatus.${FamilySituationEnum.other}`),
        id: FamilySituationEnum.other
      })

      const contactStatus = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const groups = computed<ContactStatus[]>(
        () => store.getters['organizations/groups']
      )
      const personnalContactGroups = computed(() =>
        contactStatus.value.filter((x) => x.type === CONTACTTYPE.INDIVIDUAL)
      )
      const schema = individualSchema

      const handleForm = async (values: IndividualContactForm) => {
        const form = {
          ...values,
          contactGroupsId: newContact.value.contactGroupsId,
          email: values.email === '' ? null : values.email,
          type: CONTACTTYPE.INDIVIDUAL
        }
        emit('submitForm', form)
      }

      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      const handleSearch = () => {
        console.log()
      }

      const onAddressInput = ($event: AddressData) => {
        newContact.value.postalcode = $event.postalCode
        newContact.value.city = $event.city
        newContact.value.country = $event.country
        newContact.value.address = $event.address
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            gender: newProps.contact.gender,
            contactGroupsId: (newProps.contact.contactGroups || []).map(
              (x) => x.id
            ),
            postalcode: newProps.contact.postcode || '',
            contactStatusId: newProps.contact.status?.id || '',
            internalId1: newProps.contact.internalId1 || '',
            internalId2: newProps.contact.internalId2 || '',
            mappingId: newProps.contact.mappingId || ''
          }
        } else {
          newContact.value = newValues()
        }
      })

      return {
        newContact,
        personnalContactGroups,
        genders,
        groups,
        maritalStatus,
        schema,
        handleForm,
        handleReset,
        handleSearch,
        onAddressInput,
        fileChanged
      }
    }
  })
</script>
