import * as yup from 'yup'
export const constititionSchema = yup.object().shape({
  durationInYears: yup.number().positive(),
  companyName: yup.string().required(),
  nominalValue: yup.number().positive(),
  firstHeadOfficeAddress: yup.string().required(),
  stockAliasId: yup.string().required(),
  firstExerciseClosure: yup.string().required(),
  firstClosure: yup.string().required(),
  stockIssuanceParts: yup
    .array()
    .of(
      yup.object({
        contactId: yup.string(),
        quantity: yup.number().positive(),
        unitPrice: yup.number().positive(),
        totalPrice: yup.number().positive(),
        releasedAmount: yup.number().positive(),
        entryDate: yup.string().nullable()
      })
    )
    .min(1),
  appointments: yup
    .array()
    .of(
      yup.object({
        functionId: yup.string().required(),
        startDate: yup.string().required(),
        endDate: yup.string().required(),
        contactId: yup.string().required()
      })
    )
    .min(1)
})
