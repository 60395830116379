
  import { defineComponent, toRefs, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { isNumber } from 'lodash'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Event } from '@/services/api/models'
  import { EventType } from '@/types/event'

  export default defineComponent({
    name: 'IssuanceCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { nominalValue } = toRefs(props)
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const store = useStore()
      const isLoading = ref(false)
      const issuance = computed<any>(() => props.event?.freeShareIssuance)

      const isOptionIssuance = computed(
        () => props.event?.type?.toString() === EventType.optionIssuance
      )

      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed<any[]>(
        () => props.event?.freeShareIssuance?.freeShareIssuanceParts || []
      )

      const totalQuantity = computed(() => {
        return (parts.value || []).reduce(
          (total: number, p: any) => (total += p.quantity),
          0
        )
      })
      const globalReduction = computed(() => {
        return (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      })

      const globalReleased = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc += parseFloat(line.releasedAmount || 0)),
          0
        )
      )
      const globalEmit = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc +=
              (parseFloat(line.totalPrice) / parseInt(line.quantity || 0) -
                nominalValue.value) *
              parseInt(line.quantity || 0)),
          0
        )
      )

      const globalIssuancePerAction = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc +=
              parseInt(line.totalPrice) / parseInt(line.quantity || 0) -
              nominalValue.value),
          0
        )
      )
      const globalNominalIncrease = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.quantity || 0) * nominalValue.value),
          0
        )
      )
      const globalTotal = computed(() =>
        (parts.value || []).reduce(
          (acc: number, line: any) => (acc += parseFloat(line.totalPrice || 0)),
          0
        )
      )

      const globalUnitPrice = computed(() => {
        let value = 0
        if (globalTotal.value > 0 && totalQuantity.value > 0) {
          value =
            parseFloat(globalTotal.value?.toString()) / totalQuantity.value
        }
        return value
      })

      const calculateUnitPrice = (row: any) => {
        if (!row.totalPrice || !row.quantity) return 0

        return row.totalPrice / row.quantity
      }

      const emitPerAction = (row: any) => {
        const unitPrice = calculateUnitPrice(row)

        if (!isNumber(unitPrice)) {
          return '-'
        }

        return Math.abs(unitPrice - nominalValue.value)
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseFloat(ePerAction.toString()) * row.quantity
      }

      const augNominal = (row: any) => {
        if (nominalValue.value < 0) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }
      console.log(props.event)

      return {
        currency,
        parts,
        issuance,
        moment,
        globalReduction,
        totalQuantity,
        globalReleased,
        globalUnitPrice,
        globalNominalIncrease,
        globalIssuancePerAction,
        globalEmit,
        globalTotal,
        isLoading,
        isOptionIssuance,
        contactsId,
        formatNumber,
        calculateUnitPrice,
        emitPerAction,
        emitTotal,
        augNominal
      }
    }
  })
