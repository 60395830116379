<template>
  <div>
    <h3 class="my-5 text-blackp text-left">
      {{ $t('contactBook.form.personalInformation') }}
    </h3>
    <Form
      v-slot="{ errors, meta, values, setFieldValue }"
      :validation-schema="schema"
      :initial-values="newContact"
      @submit="handleForm"
      @reset="handleReset"
    >
      <div :class="`flex ${topAlignClass} justify-between`">
        <div class="w-1/2 flex">
          <profile-avatar-form
            type="company"
            :profile-image="contact?.picture?.pictureURL"
            :contact-id="contact?.id"
            @file-changed="fileChanged"
          />
        </div>
        <div :class="`w-1/2 ${topAlignClass ? 'mt-2' : ''} flex`">
          <div class="w-full flex flex-col">
            <label
              class="text-xs text-secondary8 font-semibold mb-1 text-left"
              for="contactGroup"
              >{{ $t('contactBook.fields.group') }}</label
            >

            <Multiselect
              id="contactGroupsId"
              v-model="newContact.contactGroupsId"
              :value="newContact.contactGroupsId"
              :class="`w-full ${
                newContact.contactGroupsId.length > 0 ? 'min-' : ''
              }h-10`"
              mode="multiple"
              :tag-with-initials="false"
              name="contactGroupsId"
              :options="groups"
              elementName="contactBook.groups"
              selectionText="global.actions.selected1"
              :placeholder="$t('contactBook.placeholder.group')"
              :groups="false"
              @change="newContact.contactGroupsId = $event"
            />
          </div>
        </div>
      </div>
      <div class="w-full flex justify-between mt-3">
        <div class="w-1/3 mr-2">
          <div class="flex justify-start items-center">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="legalStatus"
              >{{ $t('contactBook.fields.formType') }}</label
            >
          </div>
          <Field
            v-slot="{ field }"
            v-model="newContact.legalStatusId"
            name="legalStatusId"
          >
            <Multiselect
              id="stockType"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              :value="newContact.legalStatusId"
              name="legalStatusId"
              :options="investorLegalStatus"
              :placeholder="$t('contactBook.placeholder.formType')"
              :groups="false"
              @change="newContact.legalStatusId = $event"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="name"
              >{{ $t('contactBook.fields.denomination') }}</label
            >
            <strong class="ml-1 text-error h-4">*</strong>
          </div>
          <Field v-slot="{ field }" v-model="newContact.name" name="name">
            <Input
              v-model="newContact.name"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="name"
              :placeholder="$t('contactBook.placeholder.denomination')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="tradeName"
              >{{ $t('contactBook.fields.commercialName') }}</label
            >
          </div>
          <Field
            v-slot="{ field }"
            v-model="newContact.tradeName"
            name="tradeName"
          >
            <Input
              v-model="newContact.tradeName"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="tradeName"
              :placeholder="$t('contactBook.placeholder.commercialName')"
            />
          </Field>
        </div>
      </div>
      <div class="w-full flex justify-between mt-2">
        <div class="w-1/4 mr-2">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="siren"
              >{{ $t('contactBook.fields.siren') }}</label
            >
          </div>
          <Field v-slot="{ field }" v-model="newContact.siren" name="siren">
            <Input
              v-model="newContact.siren"
              v-bind="field"
              :disabled="false"
              type="text"
              class="h-10"
              name="siren"
              :placeholder="$t('contactBook.placeholder.siren')"
            />
          </Field>
        </div>
        <div class="w-1/4 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="siret"
            >{{ $t('contactBook.fields.siret') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.siret" name="siret">
            <Input
              v-model="newContact.siret"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="siret"
              :placeholder="$t('contactBook.placeholder.siret')"
            />
          </Field>
        </div>
        <div class="w-1/4 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="ape"
            >{{ $t('contactBook.fields.ape') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.ape" name="ape">
            <Input
              v-model="newContact.ape"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="ape"
              :placeholder="$t('contactBook.placeholder.ape')"
            />
          </Field>
        </div>
        <div class="w-1/4">
          <div class="flex justify-start items-start">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="tva"
              >{{ $t('contactBook.fields.tva') }}</label
            >
          </div>
          <Field v-slot="{ field }" v-model="newContact.tva" name="tva">
            <Input
              v-model="newContact.tva"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="tva"
              :placeholder="$t('contactBook.placeholder.tva')"
            />
          </Field>
        </div>
      </div>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2 h-10">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="birthDate"
            >{{ $t('contactBook.fields.constitutedAt') }}</label
          >
          <UplDatePicker
            v-model="newContact.birthDate"
            class="hover:border-primary4 h-10"
            :enable-time-picker="false"
            :placeholder="$t('contactBook.placeholder.constitutedAt')"
            close-on-scroll
            :max-date="new Date()"
            name="birthDate"
            @on-select="setFieldValue('birthDate', $event)"
          />
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="nationality"
            >{{ $t('contactBook.fields.nationality') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.nationality"
            name="nationality"
          >
            <Input
              v-model="newContact.nationality"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="nationality"
              :placeholder="$t('contactBook.placeholder.nationality')"
            />
          </Field>
        </div>
        <div class="w-1/3">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="representativeContactId"
            >{{ $t('contactBook.fields.representedBy') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.representativeContactId"
            name="representativeContactId"
          >
            <Multiselect
              id="representativeContactId"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              :filterResults="false"
              :value="newContact.representativeContactId"
              name="representativeContactId"
              :options="contacts"
              :placeholder="$t('contactBook.placeholder.representedBy')"
              :groups="false"
              @search-change="handleSearch"
              @change="newContact.representativeContactId = $event"
            />
          </Field>
        </div>
      </div>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/2 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="address"
            >{{ $t('contactBook.fields.address') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.address" name="address">
            <AddressInput
              v-model="newContact.address"
              v-bind="field"
              class="h-10"
              autocomplete="off"
              name="address"
              :placeholder="$t('contactBook.placeholder.address')"
              @onChange="onAddressInput($event)"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="postalcode"
            >{{ $t('contactBook.fields.postalCode') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.postalcode"
            name="postalcode"
          >
            <Input
              v-model="newContact.postalcode"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="postalcode"
              :placeholder="$t('contactBook.placeholder.postalCode')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="city"
            >{{ $t('contactBook.fields.city') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.city" name="city">
            <Input
              v-model="newContact.city"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="city"
              :placeholder="$t('contactBook.placeholder.city')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="country"
            >{{ $t('contactBook.fields.country') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.country" name="country">
            <Input
              v-model="newContact.country"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="country"
              :placeholder="$t('contactBook.placeholder.country')"
            />
          </Field>
        </div>
      </div>

      <h3 class="my-5 text-blackp text-left">
        {{ $t('contactBook.form.moreInformations') }}
      </h3>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="email"
            >{{ $t('contactBook.fields.email') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.email" name="email">
            <Input
              v-model="newContact.email"
              v-bind="field"
              :disabled="false"
              class="h-10"
              type="text"
              name="email"
              :placeholder="$t('contactBook.placeholder.email')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="phoneNumber"
            >{{ $t('contactBook.fields.phoneNumber') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.phoneNumber"
            name="phoneNumber"
          >
            <Input
              v-model="newContact.phoneNumber"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="phoneNumber"
              :placeholder="$t('contactBook.placeholder.phoneNumber')"
            />
          </Field>
        </div>

        <div class="w-1/3">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="website"
            >{{ $t('contactBook.fields.website') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.website" name="website">
            <Input
              v-model="newContact.website"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="website"
              :placeholder="$t('contactBook.placeholder.website')"
            />
          </Field>
        </div>
      </div>
      <div class="w-full flex flex-start mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="internalId1"
            >{{ $t('contactBook.fields.internalId1') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.internalId1"
            name="internalId1"
          >
            <Input
              id="internalId1"
              v-model="newContact.internalId1"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="internalId1"
              :placeholder="$t('contactBook.placeholder.internalId1')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="internalId2"
            >{{ $t('contactBook.fields.internalId2') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.internalId2"
            name="internalId2"
          >
            <Input
              id="internalId2"
              v-model="newContact.internalId2"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="internalId2"
              :placeholder="$t('contactBook.placeholder.internalId2')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="mappingId"
            >{{ $t('contactBook.fields.mappingId') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.mappingId"
            name="mappingId"
          >
            <Input
              id="mappingId"
              v-model="newContact.mappingId"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="mappingId"
              :placeholder="$t('contactBook.placeholder.mappingId')"
            />
          </Field>
        </div>
      </div>
      <FormActions
        v-if="
          $acl.role('admin') ||
          ($acl.permission('is-manager') &&
            $acl.permission('write-contacts')) ||
          $acl.permission('employee-writeContact')
        "
        :errors="errors"
        :meta="meta"
        @resetForm="handleReset"
        @onSubmit="handleForm(values)"
      />
    </Form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, watch, onMounted } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { LegalStatus } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CONTACTTYPE, ProfessionalContactForm } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { professionalSchema } from '@/utils/schema'
  import { CompanyModel } from '@/services/api/models1'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import {
    CompanyContact,
    ContactStatus,
    IndividualContact,
    ProfessionalContact
  } from '@/services/api/models/company'
  import AddressInput from '@/components/Address/index.vue'
  import { AddressData } from '@/types/config'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      Input,
      UplDatePicker,
      FormActions,
      ProfileAvatarForm,
      AddressInput
    },
    props: {
      contact: {
        type: Object as () => ProfessionalContact | null,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const newValues = (): ProfessionalContactForm => ({
        id: props.contact?.id,
        name: props.contact?.name || '',
        tradeName: props.contact?.name || '',
        type: CONTACTTYPE.PROFESSIONAL,
        nationality: props.contact?.nationality || null,
        country: props.contact?.country || null,
        contactGroupsId: props.contact?.contactGroups?.map((x) => x.id) || [],
        contactStatusId: null,
        birthDate: props.contact?.birthDate || null,
        email: props.contact?.email || null,
        phoneNumber: props.contact?.phoneNumber || null,
        address: props.contact?.address || null,
        postalcode: props.contact?.postcode || null,
        city: props.contact?.city || null,
        representativeContactId:
          props.contact?.representativeContact?.id || null,
        legalStatusId: props.contact?.legalStatus?.id || '',
        siret: props.contact?.siret || null,
        siren: props.contact?.siren || null,
        website: props.contact?.website || null,
        tva: props.contact?.tva || '',
        ape: props.contact?.ape || null,
        internalId1: props.contact?.internalId1 || null,
        internalId2: props.contact?.internalId2 || null,
        mappingId: props.contact?.mappingId || null
      })

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const modalForm = computed(() => store.getters['organizations/form'])

      const newContact = ref<ProfessionalContactForm>(newValues())

      const allContacts = computed<any[]>(
        () => store.getters['organizations/contacts']
      )

      const contacts = computed(() =>
        allContacts.value.filter(
          (x) =>
            x.id !== route.params?.contactId &&
            [CONTACTTYPE.INDIVIDUAL, CONTACTTYPE.PROFESSIONAL].includes(x.type)
        )
      )

      const groups = computed<ContactStatus[]>(
        () => store.getters['organizations/groups']
      )

      const legalStatus = computed<LegalStatus[]>(
        () => store.getters['toolbox/legalStatus']
      )
      const investorLegalStatus = computed<LegalStatus[]>(() =>
        legalStatus.value.filter(
          (x) => x.contactType === CONTACTTYPE.PROFESSIONAL
        )
      )

      const status = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )
      const professionalStatus = computed(() =>
        status.value.filter((x) => x.type === CONTACTTYPE.PROFESSIONAL)
      )
      const schema = professionalSchema

      const handleForm = async (formData: ProfessionalContactForm) => {
        formData.contactGroupsId = newContact.value.contactGroupsId
        emit('submitForm', formData)
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const onAddressInput = ($event: AddressData) => {
        newContact.value.postalcode = $event.postalCode
        newContact.value.city = $event.city
        newContact.value.country = $event.country
        newContact.value.address = $event.address
      }

      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.INDIVIDUAL,
            name: e,
            limit: 10
          }
        })

        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.PROFESSIONAL,
            name: e,
            limit: 10
          }
        })
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            contactGroupsId:
              newProps.contact.contactGroups?.map((x) => x.id) || [],
            contactStatusId: newProps.contact.status?.id || '',
            legalStatusId: newProps.contact.legalStatus.id || '',
            representativeContactId:
              newProps.contact.representativeContact?.id || '',
            siren: newProps.contact.siren,
            postalcode: newProps.contact.postcode || '',
            internalId1: newProps.contact.internalId1 || '',
            internalId2: newProps.contact.internalId2 || '',
            mappingId: newProps.contact.mappingId || ''
          }
        } else {
          newContact.value = newValues()
        }
      })

      onMounted(() => {
        handleSearch('')
      })

      return {
        newContact,
        professionalStatus,
        contacts,
        groups,
        schema,
        investorLegalStatus,
        handleForm,
        handleSearch,
        fileChanged,
        handleReset,
        onAddressInput
      }
    }
  })
</script>
