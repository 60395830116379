
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'

  export default defineComponent({
    name: 'GenericExercisePrice',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },

      hasLabel: {
        default: true,
        type: Boolean
      },
      options: { type: Object as () => number[] },
      value: {
        default: '',
        type: [String, Array]
      },

      tableId: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const exercisePrice = ref(props.value.toString())
      const prices = computed(() =>
        props.options?.map((x) => ({
          id: x.toString(),
          name: `${x} €`,
          value: x
        }))
      )
      const { data } = toRefs(props)

      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )
      watch(
        () => props.options,
        (val) => {
          if (val?.length === 1) {
            const [firstPrice] = val

            exercisePrice.value = firstPrice.toString()
          } else if (!val?.length) exercisePrice.value = ''

          emit('onUpdated', fieldName.value, exercisePrice.value)
        }
      )

      onMounted(() => {
        if (props.options?.length === 1) {
          const [firstPrice] = props.options

          exercisePrice.value = firstPrice.toString()
        } else if (!props.options?.length) exercisePrice.value = ''

        emit('onUpdated', fieldName.value, exercisePrice.value)
      })

      return { prices, exercisePrice, fieldName }
    }
  })
