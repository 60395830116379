
  import { defineComponent, ref, computed, onMounted, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { groupItemBy, sortObjectByKeys } from '@/utils'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'ModalContactFilter',
    components: { Field, Input, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )

      const currentContact = computed(
        () => store.getters['organizations/contact']
      )

      const allContacts = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contacts'])

      const groupedContacts = computed<Map<string, any>>(() =>
        sortObjectByKeys(
          groupItemBy(
            allContacts.value,
            (item: IndividualContact | ProfessionalContact | InvestorContact) =>
              item.name.charAt(0).toUpperCase()
          )
        )
      )
      const getContacts = async (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }
      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })
        store.commit('organizations/SET_CURRENT_CONTACT', null)
      }

      const handleSelect = (contactId: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: currentCompany.value.id,
          contactId
        })
      }

      watch(search, (newVal) => {
        getContacts(newVal)
      })

      onMounted(() => {
        getContacts()
      })

      return {
        search,
        canDo,
        currentContact,
        groupedContacts,
        initStockForm,
        handleSelect
      }
    }
  })
