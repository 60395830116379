
  import {
    ContactAuditAnomalies,
    AuditType,
    CONTACTTYPE,
    IndividualContactForm,
    InvestorContactForm,
    ProfessionalContactForm,
    AuditForm
  } from '@/types'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import PersonalForm from '@/components/Forms/Configurable/Contact/PersonalForm.vue'
  import ProfessionalForm from '@/components/Forms/Configurable/Contact/ProfessionalForm.vue'
  import InvestorForm from '@/components/Forms/Configurable/Contact/InvestorForm.vue'
  import {
    CompanyModel,
    Contact,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useRoute, useRouter } from 'vue-router'
  import Checkbox from '@/components/Checkbox/index.vue'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'
  import Loader from '@/components/Loader/index.vue'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      Multiselect,
      Field,
      Checkbox,
      PersonalForm,
      ProfessionalForm,
      InvestorForm,
      UplAuditForm,
      Button,
      Modal,
      Loader
    },
    emits: ['saved'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const hasAnomaly = ref(false)
      const canDisplay = ref(false)
      const isLoading = ref(true)
      const canDelete = ref(false)
      const search = ref('')
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )
      const selectedType = ref<CONTACTTYPE | null>()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const audit = ref<AuditForm>()

      const individualContact = ref<IndividualContact | null>(null)

      const investorContact = ref<InvestorContact | null>(null)

      const professionalContact = ref<ProfessionalContact | null>(null)
      const contactId = computed<string | null>(
        () =>
          individualContact.value?.id ||
          investorContact.value?.id ||
          professionalContact.value?.id ||
          route.params.contactId?.toString()
      )

      const types = computed(() =>
        Object.values(CONTACTTYPE).map((x) => ({
          id: x,
          name: t(`contactType.${x}`)
        }))
      )

      const loadedFile = ref()
      const updateAudit = (values: AuditForm) => {
        audit.value = values
      }
      const allTypes = ref(CONTACTTYPE)

      const allContacts = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contactsResult'])

      const updateProfileImage = (contactId: string) => {
        if (!loadedFile.value) return
        const form = new FormData()
        form.append('fileName', loadedFile.value?.name)
        form.append('file', loadedFile.value)
        store.dispatch('organizations/UPDATE_CONTACT_PROFILE_IMAGE', {
          companyId: currentCompany.value.id,
          form,
          contactId,
          onProgress: () => {
            console.log()
          },
          onSuccess: () => {
            loadedFile.value = null
            store.commit('organizations/SET_CONTACT_PICTURE', {
              contactId,
              url: null
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message: t(`contactBook.form.notSaved`)
            })
          }
        })
      }

      const onCancel = () => {
        individualContact.value = null
        investorContact.value = null
        professionalContact.value = null

        emit('saved', false, contactId)

        router.push({
          name: 'company.contact',
          params: { id: route.params.id }
        })
        selectedType.value = null
      }

      const createNewAudit = (contactId: string) => {
        if (hasAnomaly.value && contactId) {
          const formData = {
            motive: audit.value?.motives.join(','),
            processed: false,
            comment: audit.value?.comment
          }
          store.dispatch('organizations/CREATE_AUDIT', {
            companyId: currentCompany.value.id,
            type: AuditType.CONTACT,
            typeDataId: contactId,
            formData,
            onSuccess: () => {
              hasAnomaly.value = false
            },
            onError: () => {
              console.log('ERROR CREATE_AUDIT')
            }
          })
        }
      }

      const handleForm = async (
        values:
          | IndividualContactForm
          | ProfessionalContactForm
          | InvestorContactForm
      ) => {
        const formValues: any = values

        for (const key in formValues) {
          if (formValues[key] === '' || !formValues[key]) {
            delete formValues[key]
          }
        }
        let data = {
          companyId: currentCompany.value.id,
          contactId: '',
          formData: formValues
        }
        let action = 'CREATE_CONTACT'
        let key = 'new'

        if (formValues && contactId.value) {
          action = 'UPDATE_CONTACT'
          data.contactId = contactId.value
          key = 'edit'
        }

        await store.dispatch(`organizations/${action}`, {
          ...data,
          onError: (error = null) => {
            const message =
              error ||
              t(
                `contactBook.apiResponse.error_${values.type.toLowerCase()}_${key}`
              )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message
            })
          },
          onSuccess: async (contactId: string) => {
            createNewAudit(contactId ?? data.contactId)
            updateProfileImage(contactId ?? data.contactId)

            store.dispatch('auth/GET_CONTACTS', {
              filter: { companyId: route.params?.id }
            })

            store.commit('organizations/SET_CURRENT_CONTACT', null)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('contactBook.form.title'),
              message: t(
                `contactBook.apiResponse.success_${values.type.toLowerCase()}_${key}`
              )
            })

            individualContact.value = null
            investorContact.value = null
            professionalContact.value = null

            selectedType.value = null

            emit('saved', true, contactId)
          }
        })
      }

      const selectContact = (contact: any) => {
        if (contact?.type === CONTACTTYPE.INDIVIDUAL)
          individualContact.value = { ...contact }
        else if (contact?.type === CONTACTTYPE.INVESTOR)
          investorContact.value = { ...contact }
        else if (contact?.type === CONTACTTYPE.PROFESSIONAL)
          professionalContact.value = { ...contact }
        selectedType.value = contact?.type
        canDisplay.value = false
        if (contact?.id)
          router.push({
            name: 'company.contact',
            params: { id: route.params.id, contactId: contact.id }
          })
      }

      const fileChanged = (file: File) => {
        if (file.size / 1024 / 1024 > 5 * 1024 * 1024) {
          store.dispatch('toolbox/displayToast', {
            type: TYPE.ERROR,
            context: t('contactBook.form.profileImage'),
            message: t(`contactBook.form.profileImageTooLarge`)
          })
          return
        } else loadedFile.value = file
      }
      const submitDeletion = () => {
        store.dispatch(`organizations/DELETE_CONTACT`, {
          companyId: currentCompany.value.id,
          contactId: contactId.value,
          onError: (error = null) => {
            const message = error || t(`contactBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            selectedType.value = null

            store.commit('organizations/SET_CURRENT_CONTACT', null)

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('contactBook.form.title'),
              message: t(`contactBook.apiResponse.deleteSuccess`)
            })
            router.push({
              name: 'company.contact',
              params: { id: route.params.id }
            })
            emit('saved', false)
          }
        })
      }

      const getContactGroups = () => {
        store.dispatch('organizations/GET_COMPANY_GROUPS', {
          companyId: route.params.id
        })
      }
      const getContact = async () => {
        if (!route.params.contactId) return
        isLoading.value = true
        await store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: route.params.id,
          contactId: route.params.contactId,
          onSuccess: (con: Contact) => {
            selectContact(con)
            isLoading.value = false
          },
          onError: () => {
            router.push({
              name: 'company.contact',
              params: { id: route.params.id }
            })
            emit('saved', false)

            isLoading.value = false
          }
        })
      }
      watch(search, (val: string) => {
        store.dispatch('organizations/FILTER_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: { name: val, limit: 10, offset: 0 }
        })
      })

      watch(
        () => route.params.contactId,
        () => {
          getContact()
        }
      )

      onMounted(() => {
        getContactGroups()
        if (route.params?.contactId) getContact()
        else isLoading.value = false
      })

      return {
        types,
        individualContact,
        professionalContact,
        investorContact,
        allTypes,
        canDelete,
        selectedType,
        ContactAuditAnomalies,
        AuditType,
        hasAnomaly,
        search,
        canDo,
        contactId,
        canDisplay,
        allContacts,
        isLoading,
        updateAudit,
        onCancel,
        selectContact,
        handleForm,
        submitDeletion,
        fileChanged
      }
    }
  })
