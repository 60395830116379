<template>
  <div
    v-can.any="[
      'admin',
      'is-manager',
      'write-operations',
      'read-operations',
      'read-stocks',
      'write-stocks',
      'read-vestings',
      'write-fundRaisingRound',
      'read-fundRaisingRound',
      'write-operations',
      'read-operations',
      'write-vestings',
      'read-committees',
      'write-committees',
      'write-contacts',
      'write-profitSharingPlan',
      'read-profitSharingPlan'
    ]"
    class="flex justify-center items-center"
  >
    <Modal :is-open="isModalOpen">
      <template #title>
        <h3 class="text-xl font-thin text-blackp leading-6">
          {{ $t('stockBook.form.title') }}
        </h3></template
      >
      <template #menu>
        <StockFilter />
      </template>
      <template #content><StockForm /> </template>
    </Modal>
    <Modal :is-open="canDisplayStockGroupModal">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('stockGroupBook.form.title') }}
        </h3></template
      >
      <template #menu><StockGroupFilter /> </template>
      <template #content><StockGroupForm /> </template>
    </Modal>

    <Modal :is-open="displayOrganModal">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('committeeBook.form.title') }}
        </h3></template
      >
      <template #menu> <CommitteeFilter /></template>
      <template #content><CommitteeForm /> </template>
    </Modal>
    <Modal :is-open="canDisplayContactModal">
      <template #title>
        <h3 class="text-xl text-blackp font-bold">
          {{ $t('contactBook.form.title') }}
        </h3></template
      >

      <template #content><ContactForm /> </template>
      <template #menu>
        <ContactFilter />
      </template>
    </Modal>
    <Modal :is-open="displayVestingModal">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('vestingBook.form.title') }}
        </h3></template
      >
      <template #menu><VestingFilter /> </template>
      <template #content><VestingForm /> </template>
    </Modal>

    <Modal :is-open="canDisplayGroupModal">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('groupBook.form.title') }}
        </h3></template
      >
      <template #menu><ContactGroupFilter /> </template>
      <template #content><ContactGroupForm /> </template>
    </Modal>

    <Modal :is-open="canDisplayFundraisingRoundModal">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('fundRaisingRoundBook.form.title') }}
        </h3></template
      >
      <template #menu><FundraisingRoundFilter /> </template>
      <template #content><FundraisingRound /> </template>
    </Modal>

    <Modal :is-open="canDisplayProfitSharingModal">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('profitSharingPlanBook.form.title') }}
        </h3></template
      >
      <template #menu><ProfitSharingPlanFilter /> </template>
      <template #content><ProfitSharingPlan /> </template>
    </Modal>

    <Modal :is-open="canDisplayOperation">
      <template #title>
        <h3 class="text-md text-blackp font-bold">
          {{ $t('operationBook.form.title') }}
        </h3></template
      >
      <template #menu><OperationFilter /> </template>
      <template #content><Operation /> </template>
    </Modal>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'
  // import ActionList from '@/components/ActionList/index.vue'
  import Modal from '@/components/Modal/index.vue'
  import { useStore } from 'vuex'
  import { MAINACTIONS } from '@/types'
  import StockFilter from '@/components/Forms/Configurable/Filters/stock.vue'
  import CommitteeFilter from '@/components/Forms/Configurable/Filters/committee.vue'
  import StockForm from '@/components/Forms/Configurable/Stock/index.vue'
  import VestingForm from '@/components/Forms/Configurable/Vesting/index.vue'
  import VestingFilter from '@/components/Forms/Configurable/Filters/vesting.vue'
  import ContactFilter from '@/components/Forms/Configurable/Filters/contact.vue'
  import ContactForm from '@/components/Forms/Configurable/Contact/index.vue'
  import ContactGroupForm from '@/components/Forms/Configurable/ContactGroup/index.vue'
  import ContactGroupFilter from '@/components/Forms/Configurable/Filters/contactGroup.vue'
  import StockGroupForm from '@/components/Forms/Configurable/StockGroup/index.vue'
  import StockGroupFilter from '@/components/Forms/Configurable/Filters/stockGroup.vue'
  import CommitteeForm from '@/components/Forms/Configurable/Committee/index.vue'
  import { useI18n } from 'vue-i18n'
  import FundraisingRoundFilter from '@/components/Forms/Configurable/Filters/fundraisingRound.vue'
  import FundraisingRound from '@/components/Forms/Configurable/FundraisingRound/index.vue'
  import ProfitSharingPlanFilter from '@/components/Forms/Configurable/Filters/profitSharingPlan.vue'
  import ProfitSharingPlan from '@/components/Forms/Configurable/ProfitSharingPlan/index.vue'
  import OperationFilter from '@/components/Forms/Configurable/Filters/operation.vue'
  import Operation from '@/components/Forms/Configurable/Operation/index.vue'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      Modal,
      StockForm,
      CommitteeForm,
      VestingForm,
      ContactForm,
      StockFilter,
      CommitteeFilter,
      VestingFilter,
      ContactFilter,
      ContactGroupFilter,
      ContactGroupForm,
      StockGroupForm,
      StockGroupFilter,
      FundraisingRoundFilter,
      FundraisingRound,
      ProfitSharingPlanFilter,
      ProfitSharingPlan,
      OperationFilter,
      Operation
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const isModalOpen = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.STOCK
      )

      const displayOrganModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.COMMITTEE
      )

      const displayVestingModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.VESTING
      )

      const canDisplayContactModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.CONTACT
      )
      const canDisplayGroupModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.GROUP
      )

      const canDisplayStockGroupModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.STOCKGROUP
      )

      const canDisplayProfitSharingModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] ===
            MAINACTIONS.PROFIT_SHARING_PLAN
      )

      const canDisplayFundraisingRoundModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.FUNDRAISING_ROUND
      )

      const canDisplayOperation = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.OPERATION
      )

      const actions = computed(() => {
        const value = []

        if (acl.can.any(['read-stocks', 'write-stocks'])) {
          value.push({
            name: t('stockGroupBook.title'),
            icon: 'collection',
            type: 'list',
            key: MAINACTIONS.STOCKGROUP
          })

          value.push({
            name: t('stockBook.title'),
            icon: 'pie-chart',
            type: 'list',
            key: MAINACTIONS.STOCK
          })
        }

        if (acl.can.any(['read-vestings', 'write-vestings'])) {
          value.push({
            name: t('vestingBook.title'),
            icon: 'file-spreadsheet',
            type: 'list',
            key: MAINACTIONS.VESTING
          })
        }

        if (acl.can.any(['read-committees', 'write-committees'])) {
          value.push({
            name: t('committeeBook.title'),
            icon: 'diagram-2-fill',
            type: 'list',
            key: MAINACTIONS.COMMITTEE
          })
        }

        if (acl.can.any(['write-contacts'])) {
          value.push({
            name: t('groupBook.title'),
            icon: 'people-fill',
            type: 'list',
            key: MAINACTIONS.GROUP
          })
        }

        if (
          acl.can.any(['write-profitSharingPlan', 'read-profitSharingPlan'])
        ) {
          value.push({
            name: t('profitSharingPlanBook.title'),
            icon: 'cash-stack',
            type: 'list',
            key: MAINACTIONS.PROFIT_SHARING_PLAN
          })
        }

        if (acl.can.any(['write-fundRaisingRound', 'read-fundRaisingRound'])) {
          value.push({
            name: t('fundRaisingRoundBook.title'),
            icon: 'wallet2',
            type: 'list',
            key: MAINACTIONS.FUNDRAISING_ROUND
          })
        }

        if (acl.can.any(['write-operations', 'read-operations'])) {
          value.push({
            name: t('operationBook.title'),
            icon: 'calendar-range',
            type: 'list',
            key: MAINACTIONS.OPERATION
          })
        }

        return value
      })

      const openModal = (value: MAINACTIONS) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: value
        })
      }

      return {
        actions,
        isModalOpen,
        displayOrganModal,
        displayVestingModal,
        canDisplayContactModal,
        canDisplayGroupModal,
        canDisplayStockGroupModal,
        canDisplayFundraisingRoundModal,
        canDisplayProfitSharingModal,
        canDisplayOperation,
        openModal
      }
    }
  })
</script>
