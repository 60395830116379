<template>
  <Form
    v-slot="{ errors, meta, resetForm, values: formValues }"
    :validation-schema="schema"
    :initial-values="newStock"
    @submit="handleForm"
  >
    <div class="w-3/5 mt-3">
      <label class="text-xs uppercase font-semibold" for="optionCategoryId">{{
        $t('stockBook.form.category')
      }}</label>
      <Field
        v-slot="{ field }"
        v-model="newStock.optionCategoryId"
        name="optionCategoryId"
      >
        <Multiselect
          id="optionCategoryId"
          v-model="newStock.optionCategoryId"
          v-bind="field"
          mode="single"
          name="optionCategoryId"
          :options="optionCategories"
          :placeholder="$t('stockBook.form.categoryPlaceholder')"
          :groups="false"
          @change="categoryChanged"
        />
      </Field>
    </div>
    <div
      v-if="newStock.optionCategoryId"
      class="
        stock__aliasSecondary
        w-full
        form-group
        flex
        justify-start
        items-center
      "
    >
      <div class="stock__aliasSecondary__row">
        <div class="stock__aliasSecondary__rowSubStock form-group ml-5" />
      </div>
      <div class="suboption">
        <i
          class="
            mt-3
            bi-arrow-right
            text-secondary8
            flex
            items-center
            justify-center
          "
        />
        <div
          class="
            w-2/5
            flex flex-col
            justify-center
            items-start
            form-group
            ml-3
            h-16
          "
        >
          <label class="text-xl text-secondary8 uppercase font-semibold mb-1">{{
            $t('stockBook.form.underlayingStock')
          }}</label>
          <Field
            v-slot="{ field }"
            v-model="newStock.underlayingStockId"
            name="underlayingStockId"
          >
            <Multiselect
              v-bind="field"
              id="underlayingStockId"
              v-model="newStock.underlayingStockId"
              mode="single"
              name="underlayingStockId"
              group-options-field="group"
              :value="newStock.underlayingStockId"
              :options="stocks"
              :filterResults="false"
              :placeholder="$t('stockBook.form.categoryPlaceholder')"
              :groups="true"
              class="h-10"
              @change="handleStockChange"
              @search-change="handleSearch"
            />
          </Field>
        </div>
        <div class="form-group ml-3 h-16 w-2/5">
          <label class="text-xs uppercase font-semibold">{{
            $t('stockBook.form.parityType')
          }}</label>
          <Field
            v-slot="{ field }"
            v-model="newStock.parityType"
            name="parityType"
          >
            <Multiselect
              v-bind="field"
              id="parityType"
              v-model="newStock.parityType"
              mode="single"
              name="parityType"
              :value="newStock.parityType"
              :options="parityTypes"
              :placeholder="$t('stockBook.form.categoryPlaceholder')"
              :groups="false"
              class="h-10"
              @change="parityTypeChanged"
            />
          </Field>
        </div>
        <div class="form-group ml-3 h-16">
          <label class="text-xs uppercase font-semibold">{{
            newStock.parityType === PARITYTYPE.FIXED
              ? $t('stockBook.form.parity')
              : $t('stockBook.form.maxQuantity')
          }}</label>
          <Field v-slot="{ field }" v-model="newStock.parity" name="parity">
            <Input
              v-model="newStock.parity"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="number"
              name="parity"
              :placeholder="
                $t(
                  `stockBook.form.${
                    newStock.parityType === PARITYTYPE.FIXED
                      ? 'parity'
                      : 'maxQuantity'
                  }`
                )
              "
              @input="$emit('applyChanges', formValues)"
            />
          </Field>
        </div>
      </div>
    </div>

    <!-- SECTION ALIAS -->
    <section class="stock__section w-full mt-3">
      <h3>Alias</h3>
      <div class="stock__aliasPrincipal form-group flex justify-start w-full">
        <div class="flex flex-col w-3/5">
          <label class="text-sm">{{ $t('global.principalAlias') }}</label>
          <Field
            v-slot="{ field }"
            v-model="newStock.alias"
            type="text"
            name="alias"
          >
            <Input
              v-model="newStock.alias"
              placeholder="(ex: AO)"
              v-bind="field"
              type="text"
              class="h-10"
              :disabled="false"
              name="alias"
            />
          </Field>
        </div>
      </div>
    </section>
    <section class="stock__section w-full mt-3">
      <h3>{{ $t('stockBook.form.exercisePrices') }}</h3>

      <div class="mt-2">
        <div v-for="(price, index) in prices" :key="index" class="flex my-1">
          <div class="flex items-center justify-start">
            <Field
              v-if="price.edition"
              v-slot="{ field }"
              v-model="prices[index].value"
              type="number"
              :name="`prices[${index}].value`"
            >
              <Input
                v-model="prices[index].value"
                :placeholder="$t('global.placeholder.exercisePrice')"
                v-bind="field"
                type="number"
                class="h-10"
                :disabled="false"
                :name="`prices[${index}].value`"
              />
            </Field>
            <span v-else>{{ prices[index].value }}</span>
            <span class="ml-1">{{ currency }}</span>
          </div>
          <Button
            v-if="newStock?.id"
            :disabled="false"
            class="
              flex
              mx-1
              justify-center
              items-center
              h-10
              rounded
              hover:bg-secondary5
            "
            variant="custom"
            size="medium"
            @click="price.edition = !price.edition"
            ><i class="bi bi-pen text-xl"></i>
          </Button>
          <Button
            :disabled="false"
            class="
              flex
              ml-1
              justify-center
              items-center
              h-10
              rounded
              hover:bg-secondary5
            "
            variant="custom"
            size="medium"
            @click="removePrice(index)"
            ><i class="bi bi-trash text-xl"></i>
          </Button>
        </div>
        <div class="flex justify-start mt-2">
          <Button
            :disabled="false"
            class="
              flex
              mt-1
              justify-center
              items-center
              h-10
              rounded
              hover:bg-secondary5
            "
            variant="secondary"
            size="medium"
            @click="addPrice()"
            ><i class="bi bi-plus text-lg"></i> Ajouter
          </Button>
        </div>
      </div>
    </section>

    <section class="stock__section w-full mt-3">
      <h3>
        {{ $t('stockBook.form.duration') }}
        <el-tooltip
          tabindex="1"
          popper-class="z-index-max"
          effect="dark"
          :content="$t('stockBook.form.durationExplained')"
          placement="right"
        >
          <i class="bi bi-info-circle text-lg ml-2"></i
        ></el-tooltip>
      </h3>

      <div class="mt-2">
        <div class="flex my-1">
          <div class="flex items-center justify-start">
            <Field
              v-slot="{ field }"
              v-model="newStock.expirationNumberOfTimeUnits"
              type="number"
              name="expirationNumberOfTimeUnits"
            >
              <Input
                v-model="newStock.expirationNumberOfTimeUnits"
                :placeholder="$t('global.placeholder.duration')"
                v-bind="field"
                type="number"
                class="h-10"
                :disabled="false"
                name="expirationNumberOfTimeUnits"
              />
            </Field>
          </div>

          <div class="flex items-center justify-start mx-2 w-48">
            <Field
              v-slot="{ field }"
              v-model="newStock.expirationTimeUnit"
              type="number"
              name="expirationTimeUnit"
            >
              <Multiselect
                v-model="newStock.expirationTimeUnit"
                :value="newStock.expirationTimeUnit"
                :placeholder="$t('global.placeholder.timeUnit')"
                v-bind="field"
                :options="choiceUnit"
                class="h-10"
                :disabled="false"
                :name="`expirationTimeUnit`"
              />
            </Field>
          </div>
        </div>
      </div>
    </section>

    <FormActions
      v-if="canDo"
      :errors="errors"
      :meta="meta"
      @reset-form="resetForm"
      @onSubmit="handleForm(formValues)"
    />
  </Form>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import {
    OptionStockTypeFormData,
    PARITYTYPE,
    STOCKTYPE,
    STOCKTYPESALIAS
  } from '@/types'
  import { optionStockSchema } from '@/utils'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import Multiselect from '@/components/Select/multiselect.vue'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import { OptionStockAlias, StockAlias } from '@/services/api/models'
  import { CompositionStockAlias } from '@/services/api/models/company'
  import { useI18n } from 'vue-i18n'
  import {
    STOCK_PRIMARY,
    STOCK_TERTIARY
  } from '@/store/modules/Organization/models'
  import { useAcl } from 'vue-simple-acl/src'
  import { TimeUnit } from '@/types/config'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      Input,
      Button,
      Form,
      Field,
      Multiselect,
      FormActions,
      ElTooltip
    },
    props: {
      initForm: {
        type: Boolean,
        required: false,
        default: false
      },
      values: {
        type: Object as () => OptionStockAlias,
        required: false,
        default: () => ({})
      }
    },
    emits: ['saveChanges', 'applyChanges'],

    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )

      const choiceUnit = computed(() =>
        Object.values(TimeUnit).map((x) => ({
          name: t(`events.elements.${x.toLowerCase()}s`),
          id: x
        }))
      )

      const resetForm = (): OptionStockTypeFormData => ({
        alias: '',
        optionCategoryId: '',
        underlayingStockId: '',
        underlayingCompositionId: '',
        parityType: PARITYTYPE.FIXED,
        parity: 1,
        inValuation: false,
        inNFD: false,
        inFD: false,
        inESOP: false,
        exercisePrices: [],
        notInTransactionsRegister: false,
        expirationNumberOfTimeUnits: 10,
        expirationTimeUnit: TimeUnit.YEARS
      })
      const parityTypes = ref([
        {
          id: 'FIXED',
          value: 'FIXE',
          name: t('global.fixed')
        },
        {
          id: 'VARIABLE',
          value: 'VARIABLE',
          name: t('global.variable')
        }
      ])
      const prices = ref<any[]>([])
      const newStock = ref<any>(props.values?.id ? props.values : resetForm())

      const newVal = props.values?.parity
        ? PARITYTYPE.VARIABLE
        : PARITYTYPE.FIXED

      newStock.value.parityType = newVal

      const parityTypeValue = ref(false)

      const currency = computed(() => store.getters['organizations/currency'])

      const simples = ref<StockAlias[]>([])
      const compositionStockAlias = ref<CompositionStockAlias[]>([])
      const stocks = computed(() => {
        return [
          {
            id: 1,
            name: t('companyStockType.SIMPLE'),
            label: t('companyStockType.SIMPLE'),
            value: 'stocks',
            group: simples.value
          },

          {
            id: 3,
            name: t('companyStockType.COMBINED'),
            label: t('companyStockType.COMBINED'),
            value: 'compositionStocks',
            group: compositionStockAlias.value
          }
        ]
      })
      const addPrice = () => {
        prices.value.push({ value: 0, edition: true })
      }
      const removePrice = (index: number) => {
        prices.value.splice(index, 1)
      }

      const handleStockChange = (id: string) => {
        newStock.value.underlayingStockId = id
      }

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      const updateParityType = (
        value: boolean,
        field: string,
        setFieldValue: (field: string, value: any) => void,
        setFieldError: (field: string, value: any) => void
      ) => {
        parityTypeValue.value = value
        const newVal = value ? PARITYTYPE.VARIABLE : PARITYTYPE.FIXED
        newStock.value.parityType = newVal

        setFieldValue('parityType', newVal)
        setFieldError('parityType', null)
        newStock.value.parityType = newVal
        newStock.value.exercisePrices = prices.value.map((x) => x.value)
        emit('applyChanges', newStock.value)
      }
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const stockCategories = computed(
        () => store.getters['toolbox/stockCategories']
      )
      const optionCategories = computed(
        () => store.getters['toolbox/optionCategories']
      )
      const schema = optionStockSchema

      const categoryChanged = (id: string) => {
        newStock.value.optionCategoryId = id
        newStock.value.parity = props.values?.parity || 1
        emit('applyChanges', newStock.value)
      }

      const parityTypeChanged = ($event: string) => {
        newStock.value.parityType = $event
        newStock.value.parity =
          newStock.value.parityType == PARITYTYPE.FIXED ? 1 : 0
        emit('applyChanges', newStock.value)
      }

      const handleForm = (formValues: OptionStockTypeFormData) => {
        const aliases = [...simples.value, ...compositionStockAlias.value]
        const alias = aliases.find(
          (x) => x.id === formValues.underlayingStockId
        )
        formValues.exercisePrices = [...prices.value].map((x) =>
          parseFloat(x.value.toString())
        )
        if (alias && alias?.type === STOCKTYPE.COMPOSITION) {
          formValues.underlayingStockId = null
          formValues.underlayingCompositionId = alias.id
        } else {
          newStock.value.exercisePrices = []

          formValues.underlayingStockId = alias?.id || null
          formValues.underlayingCompositionId = null
        }
        emit(
          'saveChanges',
          formValues,
          STOCKTYPESALIAS.OPTION,
          props.values && props.values.id
        )
      }

      const handleSearch = (search: string) => {
        getStockAlias(search)
        getCompositions(search)
      }

      const setData = (newProps: OptionStockAlias) => {
        parityTypeValue.value = newProps?.parityType === PARITYTYPE.VARIABLE
        newStock.value.optionCategoryId = newProps?.optionCategory?.id
        newStock.value.parityType = newProps?.parityType
        newStock.value.alias = newProps?.alias
        newStock.value.id = newProps?.id
        newStock.value.expirationNumberOfTimeUnits =
          newProps.expirationNumberOfTimeUnits
        newStock.value.expirationTimeUnit = newProps.expirationTimeUnit
        if (
          newProps?.underlayingStockAlias &&
          !simples.value
            .map((x) => x.id)
            .includes(newProps?.underlayingStockAlias?.id || '')
        ) {
          simples.value.push({
            ...newProps?.underlayingStockAlias,
            name: newProps?.underlayingStockAlias?.alias
          })
        }

        if (
          newProps?.underlayingCompositionAlias &&
          !compositionStockAlias.value
            .map((x) => x.id)
            .includes(newProps?.underlayingCompositionAlias?.id || '')
        ) {
          compositionStockAlias.value.push({
            ...newProps?.underlayingCompositionAlias,
            name: newProps?.underlayingCompositionAlias?.alias
          })
        }
        newStock.value.underlayingStockId =
          newProps?.underlayingStockAlias?.id ||
          newProps.underlayingCompositionAlias?.id ||
          null
        newStock.value.parity = parseFloat(newProps?.parity?.toString()) || 1.0
        newStock.value.inNFD = newProps?.inNFD || false
        newStock.value.inValuation = newProps?.inValuation || false
        newStock.value.inFD = newProps?.inFD || false
        newStock.value.inESOP = newProps?.inESOP || false
        prices.value = (newProps?.exercisePrices ?? []).map((x) => ({
          value: x,
          edition: false
        }))
      }

      const getStockAlias = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_STOCK_ALIAS', {
          companyId: currentCompany.value.id,
          filter,
          onSuccess: (data: StockAlias[]) => {
            simples.value = [...data].map((x) => ({
              ...x,
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.SAMPLE,
              class: STOCK_PRIMARY
            }))
          }
        })
      }

      const getCompositions = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_COMPOSITION_STOCK_ALIAS', {
          companyId: currentCompany.value?.id,
          filter,
          onSuccess: (data: CompositionStockAlias[]) => {
            compositionStockAlias.value = [...data].map((x) => ({
              ...x,
              alias: x.alias.toUpperCase(),
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.COMPOSITION,
              class: STOCK_TERTIARY
            }))
          }
        })
      }

      watch(mustInitForm, () => {
        newStock.value = resetForm()
      })

      watch(props, (newProps) => {
        setData(newProps.values)

        emit('applyChanges', newStock.value)
      })

      onMounted(() => {
        getStockAlias()
        getCompositions()
      })

      return {
        schema,
        stockCategories,
        optionCategories,
        newStock,
        PARITYTYPE,
        parityTypeValue,
        stocks,
        parityTypes,
        prices,
        currency,
        canDo,
        choiceUnit,
        updateParityType,
        handleStockChange,
        handleForm,
        categoryChanged,
        addPrice,
        removePrice,
        handleSearch,
        parityTypeChanged
      }
    }
  })
</script>

<style lang="scss" scoped>
  .suboption {
    @apply border-l border-secondary6 px-2 my-2 w-full flex justify-start items-center;
  }
  .subStockname {
    @apply ml-6 border-l border-secondary5 px-3;
  }
</style>
