<template>
  <div class="mt-5">
    <div
      v-if="isLoading"
      class="w-full h-full flex justify-center items-center"
    >
      <Loader />
    </div>
    <div v-else-if="event">
      <Issuance
        v-if="
          event?.type?.toString() === EventType.stockIssuance ||
          event?.type?.toString() === EventType.optionIssuance ||
          event?.type?.toString() === EventType.compositionIssuance
        "
        :nominal-value="nominalValue"
        :event="event"
        :type="event.type"
      />
      <Delegation
        v-else-if="event?.type?.toString() === EventType.delegation"
        :event="event.delegation"
      />
      <sunset
        v-else-if="event?.type?.toString() === EventType.optionSunset"
        :event="event.optionSunsets"
      />
      <Ceiling
        v-else-if="event?.type?.toString() === EventType.commongCeiling"
        :event="event.commonCeiling"
      />
      <parity-change
        v-if="event?.type?.toString() === EventType.parityChange"
        :event="event.parityChange"
      />
      <Transfer
        v-else-if="event?.type?.toString() === EventType.transfer"
        :event="event.transfer"
      />
      <Termination
        v-if="event?.type?.toString() === EventType.appointmentTermination"
        :event="event.appointmentTermination"
      />

      <Nomination
        v-if="event?.type?.toString() === EventType.appointment"
        :event="event.appointment"
      />
      <Exercise
        v-if="event?.type?.toString() === EventType.optionExercise"
        :event="event.optionExercise"
      />
      <nominal-value
        v-if="event?.type?.toString() === EventType.nominalValue"
        :event="event.nominalValue"
      />
      <financial-ending
        v-if="event?.type?.toString() === EventType.financialEnding"
        :event="event.financialEnding"
      />
      <Committee
        v-else-if="event?.type?.toString() === EventType.committee"
        :event="event.committee"
      />
      <vesting
        v-else-if="
          event?.type?.toString() === EventType.exerciseDelayProrogation
        "
        :event="event.exerciseDelayProrogation"
      />
      <approval
        v-else-if="event?.type?.toString() === EventType.accountApproval"
        :event="event.accountApproval"
      />
      <Reduction
        v-else-if="event?.type?.toString() === EventType.capitalReduction"
        :event="event.capitalReduction"
      />
      <vesting
        v-else-if="event?.type?.toString() === EventType.vestingSuspension"
        :event="event.vestingSuspension"
      />
      <conversion
        v-else-if="event?.type?.toString() === EventType.conversion"
        :event="event.conversion"
      />
      <final-acquisition
        :event="event.freeSharesFinalAcquisition"
        v-else-if="
          event.type?.toString() === EventType.freeSharesFinalAcquisition
        "
      />
      <free-share-sunset
        :event="event.freeSharesSunset"
        v-else-if="event.type?.toString() === EventType.freeSharesSunset"
      />
      <FreeSharesIssuance
        v-else-if="event.type?.toString() === EventType.freeShareIssuance"
        :event="event"
        :type="event.type"
      />

      <div
        v-else-if="event?.type?.toString() === EventType.vestingAcceleration"
        class="flex justify-start"
      >
        <span class="font-bold text-lg text-primary border p-2 rounded"
          >{{ event?.vestingAcceleration?.percent }}%</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import Loader from '@/components/Loader/index.vue'
  import Issuance from '@/components/Event/types/issuance.vue'
  import Delegation from '@/components/Event/types/delegation.vue'
  import Exercise from '@/components/Event/types/exercise.vue'
  import Ceiling from '@/components/Event/types/ceiling.vue'
  import Transfer from '@/components/Event/types/transfer.vue'
  import Conversion from '@/components/Event/types/conversion.vue'
  import Committee from '@/components/Event/types/committee.vue'
  import Nomination from '@/components/Event/types/nomination.vue'
  import Termination from '@/components/Event/types/appointmentTermination.vue'
  import NominalValue from '@/components/Event/types/nominalValue.vue'
  import Reduction from '@/components/Event/types/reduction.vue'
  import Vesting from '@/components/Event/types/vesting.vue'
  import Approval from '@/components/Event/types/approval.vue'
  import FinalAcquisition from '@/components/Event/types/finalAcquisition.vue'
  import FreeShareSunset from '@/components/Event/types/freeShareSunset.vue'
  import Sunset from '@/components/Event/types/sunset.vue'
  import FinancialEnding from '@/components/Event/types/finacialEnding.vue'
  import ParityChange from '@/components/Event/types/parityChange.vue'
  import { EventType } from '@/types/event'
  import { Event } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import FreeSharesIssuance from '@/components/Event/types/freeSharesIssuance.vue'

  export default defineComponent({
    name: 'EventOperation',
    components: {
      Loader,
      FinalAcquisition,
      FreeShareSunset,
      Sunset,
      Issuance,
      Delegation,
      Termination,
      Ceiling,
      Transfer,
      Nomination,
      NominalValue,
      Reduction,
      Exercise,
      Vesting,
      FinancialEnding,
      Committee,
      Conversion,
      ParityChange,
      Approval,
      FreeSharesIssuance
    },
    emits: ['on-updated'],
    setup() {
      const store = useStore()
      const isLoading = ref(false)
      const route = useRoute()
      const nominalValues = computed(
        () => store.getters['organizations/nominalValues']
      )
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const nominalValue = ref(0)
      const event = ref<Event | null>(null)

      const calculateNominalValue = async (date: string) => {
        const value = await store.dispatch(
          'organizations/COMPUTE_NOMINAL_VALUE',
          { date, setAsGlobal: false }
        )

        if (value?.nominalValue) nominalValue.value = value?.nominalValue
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      watch([() => event.value, nominalValues], ([val, vals]) => {
        if (val?.date) calculateNominalValue(val?.date || '')
      })

      onMounted(getEvent)
      return { EventType, event, nominalValue, isLoading }
    }
  })
</script>
