<template>
  <div class="w-full h-full overflow-y-scroll">
    <div class="flex px-12">
      <h3 class="text text-blackp text-2xl py-8">
        {{ $t('company.delegates_title') }}
      </h3>
    </div>

    <div class="px-12">
      <Tab :tabs="tabList" :data="tabData" />
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import Tab from '@/components/Tab/index.vue'
  import ByDelegationTab from './tabs/ByDelegationTab.vue'
  import ByHolderTab from './tabs/ByHolderTab.vue'
  export default defineComponent({
    components: { Tab },
    setup() {
      const tabList = [
        {
          key: 'byDelegate',
          langKey: 'byDelegate',
          component: ByDelegationTab
        },
        { key: 'byHolder', langKey: 'byHolder', component: ByHolderTab }
      ]
      const tabData = ref({
        capital: []
      })
      return { tabData, tabList }
    }
  })
</script>
