
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Filter, Tag, Button } from '@up.law/uplaw-ui'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import StockDropdown from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import Checkbox from '@/components/Checkbox/index.vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { Contact } from '@/services/api/models'
  import { MAINACTIONS, STOCKTYPE } from '@/types'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { formatNumber, rgbToHex } from '@/utils'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip, ElPopover } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  import Modal from '@/components/Modal/Modal.vue'
  export default defineComponent({
    name: 'UplStockMovements',
    components: {
      Field,
      ContactBadge,
      UplDatePicker,
      StockDropdown,
      Checkbox,
      DataTable,
      Multiselect,
      EmptyState,
      Loader,
      Filter,
      Tag,
      Button,
      DownloadButton,
      ElTooltip,
      ElPopover,
      Modal
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const search = ref('')
      const selectedContacts = ref<string[]>([])
      const selectedColumns = ref<string[]>([])
      const selectedStocks = ref<string[]>([])
      const dateRange = ref([])
      const canDownload = ref(false)
      const { t } = useI18n()
      const isDownloading = ref(false)

      const filter = ref<{
        contactsId?: string[]
        stockAliasesId?: string[]
        optionAliasesId?: string[]
        compositionAliasesId?: string[]
      }>({
        contactsId: [],
        stockAliasesId: [],
        optionAliasesId: [],
        compositionAliasesId: []
      })

      const stockRegisterColumns = computed(() => [
        {
          label: '#',
          field: 'id',
          custom: true,
          width: '5%'
        },
        {
          label: 'Date',
          field: 'date',
          custom: true,
          width: '5%'
        },
        {
          label: t('datatable.column.sender'),
          field: 'from',
          custom: true
        },
        {
          label: t('datatable.column.receiver'),
          field: 'to',
          custom: true
        },
        {
          label: t('datatable.column.stock'),
          field: 'stock',
          custom: true
        },

        {
          label: t('datatable.column.quantity'),
          field: 'quantity',
          custom: true,
          width: '10%'
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          custom: true,
          width: '5%'
        }
        // {
        //   label: t('datatable.column.comment'),
        //   field: 'comment',
        //   custom: true,
        //   width: '5%'
        // }
      ])

      const transactions = computed(
        () => store.getters['organizations/transactions']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )

      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const pagination = computed(
        () => store.getters['organizations/transactionsPagination']
      )

      const getContacts = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            name: search,
            limit: 10,
            offset: 0
          }
        })
      }
      const isLoading = ref(false)
      const isFiltering = ref(false)

      const resetFilters = () => {
        dateRange.value = []

        dateCleared()
        selectedContacts.value = []
        selectedStocks.value = []
      }

      const getTransactions = (companyId = route.params.id) => {
        if (filter.value?.contactsId?.length === 0)
          delete filter.value?.contactsId
        if (filter.value?.stockAliasesId?.length === 0)
          delete filter.value?.stockAliasesId
        if (filter.value?.optionAliasesId?.length === 0)
          delete filter.value?.optionAliasesId
        if (filter.value?.compositionAliasesId?.length === 0)
          delete filter.value?.compositionAliasesId
        store.dispatch('organizations/GET_COMPANY_TRANSACTIONS', {
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isLoading.value = false
            isFiltering.value = false
          }
        })
      }

      const availableColumns = ref(
        stockRegisterColumns.value
          .filter((x) => x.field !== 'actions')
          .map((x) => ({ id: x.field, value: x.label }))
      )

      const getEventId = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }
        if (row.compositionIssuancePart) {
          exercise = row.compositionIssuancePart.compositionIssuance
        }

        if (row.transferPart) {
          exercise = row.transferPart.transfer
        }

        if (row.conversionPart) {
          exercise = row.conversionPart.conversion
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (row?.optionSunsetPart?.optionSunset) {
          exercise = row?.optionSunsetPart?.optionSunset
        }

        if (row.nominalValue) {
          exercise = row.nominalValue
        }

        if (exercise?.event) return exercise.event.id
        else return null
      }

      const getEventName = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }

        if (row.compositionIssuancePart) {
          exercise = row.compositionIssuancePart.compositionIssuance
        }

        if (row.transferPart) {
          exercise = row.transferPart.transfer
        }

        if (row.conversionPart) {
          exercise = row.conversionPart.conversion
        }
        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (row.nominalValue) {
          exercise = row.nominalValue
        }

        if (exercise?.event) return exercise.event.type
        else return row.eventType
      }

      const columns = ref(stockRegisterColumns.value)

      selectedColumns.value = availableColumns.value.map((x) => x.id)

      const handleFilterSelected = (value: string[]) => {
        columns.value = stockRegisterColumns.value.filter((x) =>
          value.includes(x.field)
        )
      }

      const updateContact = (contactId: string) => {
        if (contactId !== '') {
          filter.value.contactsId = [contactId]
          selectedContacts.value = [contactId]
        } else {
          filter.value.contactsId = []
          selectedContacts.value = []
        }
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', page)
        getTransactions()
      }

      const updateStockFilter = ({ value }: any) => {
        if (typeof value === 'string') return
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }

      const dateCleared = () => {
        const dateFilter = {
          startDate: null,
          endDate: null
        }
        filter.value = { ...filter.value, ...dateFilter }
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const download = (key: MAINACTIONS) => {
        if (key === MAINACTIONS.CONTACTS_RECORD_XLS) {
          isDownloading.value = true
          const companyId = route.params.id
          store.dispatch('organizations/EXPORT_COMPANY_TRANSACTIONS', {
            companyId,
            filter: filter.value,
            onSuccess: () => {
              isDownloading.value = false
            },
            onError: () => {
              isDownloading.value = false
            }
          })
        } else downloadPdf()
      }

      const downloadPdf = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_COMPANY_TRANSACTIONS_PDF', {
          name: `Transactions-${moment().format('L')}`,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const downloadAllAssociatesPdf = () => {
        canDownload.value = true
        const companyId = route.params.id

        store.dispatch('organizations/EXPORT_ALL_ASSOCIATES_RECORD', {
          companyId,
          name: `${t(`global.allAssociatesRecord`)}-${moment().format('L')}`
        })
      }

      watch(dateRange, (newDateRange) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)

        const dateFilter = {
          startDate:
            newDateRange && newDateRange[0]
              ? moment.utc(newDateRange[0]).startOf('day').toISOString()
              : null,
          endDate:
            newDateRange && newDateRange[1]
              ? moment.utc(newDateRange[1]).endOf('day').toISOString()
              : null
        }

        filter.value = { ...filter.value, ...dateFilter }

        getTransactions()
      })

      onMounted(() => {
        isLoading.value = true

        getContacts()
        getTransactions()
      })

      watch([selectedContacts, selectedStocks], ([contacts, stocks]) => {
        isFiltering.value = true
        if (typeof stocks === 'string') return
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)
        filter.value.contactsId = contacts
        updateStockFilter({ value: stocks })
        getTransactions()
      })

      watch(
        () => route.params.id,
        (val) => {
          getTransactions(val)
        }
      )
      return {
        search,
        dateRange,
        transactions,
        selectedContacts,
        selectedStocks,
        columns,
        availableColumns,
        selectedColumns,
        moment,
        isDownloading,
        isFiltering,
        pagination,
        contacts,
        isLoading,
        handleFilterSelected,
        gotoEvent,
        getContacts,
        getEventId,
        getEventName,
        updateStockFilter,
        updateContact,
        dateCleared,
        onPageChange,
        formatNumber,
        resetFilters,
        download,
        downloadAllAssociatesPdf,
        canDownload,
        rgbToHex
      }
    }
  })
