
  import { Field } from 'vee-validate'
  import { Input } from '@up.law/uplaw-ui'
  interface DataInputString {
    name: string
    type: string
    label: string
    fieldId: string
    hint?: string
  }

  import { defineComponent, ref, computed, toRefs, watch, onMounted } from 'vue'
  export default defineComponent({
    name: 'InputGeneric',
    components: { Field, UplInput: Input },
    inheritAttrs: false,
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputString,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      value: {
        type: [String, Number],
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      },
      index: {
        type: Number,
        default: 0
      },
      label: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      step: {
        type: Number,
        default: 1
      },
      min: {
        type: Number,
        default: 0
      },
      tableId: {
        type: String,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { typeField, data, value } = toRefs(props)
      const formValue = ref(value.value ?? '')

      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      const type = computed(() => {
        if (typeField.value === 'float') {
          return 'number'
        }
        return typeField.value
      })

      watch(
        () => props.value,
        (val) => {
          formValue.value = val
        }
      )
      const updateValue = (value: any) => {
        formValue.value = value.target.value
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      return { formValue, type, fieldName, updateValue }
    }
  })
