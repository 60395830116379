<template>
  <div v-if="event">
    <div class="flex overflow-scroll grid grid-cols-8">
      <div class="col-span-2 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.contact') }}
        </h1>
      </div>
      <div class="col-span-2 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.fromTitles') }}
        </h1>
      </div>
      <div class="col-span-2 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.toTitles') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.parity') }}
        </h1>
      </div>
    </div>
    <div class="event-bg">
      <div
        v-for="(part, index) in parts"
        :key="part.id"
        :class="`w-full flex-wrap flex grid grid-cols-8 ${
          contactsId.includes(part.contact?.id) ? 'bg-active' : ''
        } ${index > 0 ? 'border-t border-secondary6 ' : ''}`"
      >
        <div
          class="
            p-2
            border-r border-secondary6
            col-span-2
            flex
            items-center
            justify-start
          "
        >
          <p v-if="part.contact" class="text-blackp text-sm text-left">
            <router-link
              :target="`${company?.id ? '_blank' : ''}`"
              :to="{
                name: 'company.directory',
                params: {
                  id: company?.id || $route.params.id,
                  contactId: part.contact.id
                }
              }"
            >
              <ContactBadge
                class="p-0 text-xs"
                :contact="part.contact"
                :details="false"
                type="light"
              />
            </router-link>
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div class="p-3 border-r border-secondary6 col-span-2">
          <Tag
            v-if="part.fromCompositionAlias"
            :text="part.fromCompositionAlias.alias.toUpperCase()"
            :value="part.fromCompositionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="part.fromOptionAlias"
            :text="part.fromOptionAlias.alias.toUpperCase()"
            :value="part.fromOptionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="part.fromStockAlias"
            :text="part.fromStockAlias.alias.toUpperCase()"
            :value="part.fromStockAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div class="p-3 border-r border-secondary6 col-span-2">
          <Tag
            v-if="part.toCompositionAlias"
            :text="part.toCompositionAlias.alias.toUpperCase()"
            :value="part.toCompositionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="part.toOptionAlias"
            :text="part.toOptionAlias.alias.toUpperCase()"
            :value="part.toOptionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <Tag
            v-else-if="part.toStockAlias"
            :text="part.toStockAlias.alias.toUpperCase()"
            :value="part.toStockAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p class="text-blackp text-sm text-left">
            {{ formatNumber(part.quantity) }}
          </p>
        </div>

        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p class="text-blackp text-sm text-left">
            {{ part.parity }}
          </p>
        </div>
      </div>
      <div
        :class="`grid grid-cols-8 ${
          parts.length > 0 ? 'border-t border-secondary6 ' : ''
        } h-10 flex`"
      >
        <div
          class="
            col-span-6
            border-r border-secondary6
            h-10
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>

        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalQuantity) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, toRefs, computed } from 'vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  import { formatNumber } from '@/utils'
  import { isNumber } from 'lodash'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Company, Conversion, NominalValue } from '@/services/api/models'

  export default defineComponent({
    name: 'ConversionCard',
    components: { Tag, ContactBadge },
    props: {
      event: {
        type: Object as () => Conversion | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      }
    },
    setup(props) {
      const { event } = toRefs(props)
      const store = useStore()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed(() => props.event?.conversionParts || [])
      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.conversionDate).unix() >
            moment.utc(x.date).unix()
        )
        return nv?.nominalValue
      })
      const totalQuantity = computed(() => {
        return (props.event?.conversionParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return nominalValue.value * row.quantity
      }

      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)
        if (ePerAction === '-') {
          return '-'
        }

        return parseInt(ePerAction.toString()) * row.quantity
      }

      const emitPerAction = (row: any) => {
        if (!nominalValue.value || !isNumber(row.unitPrice)) {
          return '-'
        }

        return row.unitPrice - nominalValue.value
      }

      const globalTotal = computed(() => {
        return (props.event?.conversionParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })

      return {
        parts,
        totalQuantity,
        currency,
        contactsId,
        emitPerAction,
        moment,
        formatNumber,
        emitTotal,
        augNominal
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
