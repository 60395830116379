
  import {
    CompanyModel,
    ContactStatus,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { sortObjectByKeys } from '@/utils'
  import { computed, defineComponent, ref, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import ContactForm from './form.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import ContactsTree from '@/components/ContactsTree/index.vue'
  import { useAcl } from 'vue-simple-acl/src'
  import { ElTooltip } from 'element-plus'
  export default defineComponent({
    components: {
      EmptyState,
      ContactForm,
      DocumentTreeview,
      Button,
      ElTooltip,
      ContactsTree
    },

    setup(props, { emit }) {
      const store = useStore()
      const showTreeview = ref(true)
      const filter = ref<any>({
        name: '',
        contactStatusId: '',
        type: '',
        contactGroupId: ''
      })
      const documentDisplayed = ref(true)
      const isVisible = ref(false)
      const isLoadingData = ref(false)
      const router = useRouter()
      const route = useRoute()
      const contactStatusId = ref('')
      const contactType = ref('')

      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          acl.permission('is-manager') ||
          acl.permission('write-contacts')
      )
      const filtered = computed(
        () =>
          aliasesId.value?.length !== 0 ||
          contactType.value !== '' ||
          contactStatusId.value != '' ||
          filter.value.name != ''
      )
      const displayForm = ref(!!route.params.contactId)
      const groupsDisplay = ref<any>({})
      const aliasesId = ref([])
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const currentContact = computed<
        IndividualContact | ProfessionalContact | InvestorContact
      >(() => store.getters['organizations/contactDetails'])

      const allContactGroups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )
      const allGroups = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contactsResult'])
      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )
      const groups = computed(() => {
        const noGroupContacts = allGroups.value.filter(
          (c) => c.contactGroups?.length == 0
        )
        const map = new Map()
        if (noGroupContacts.length > 0) map.set('zz-other', noGroupContacts)
        allGroups.value
          .filter((c) => c.contactGroups?.length)
          .forEach((elm, i) => {
            elm?.contactGroups?.forEach((group) => {
              const collection = map.get(group.name)
              if (!collection) {
                map.set(group.name, [elm])
              } else {
                collection.push(elm)
              }
            })
          })

        return sortObjectByKeys(map)
      })
      const handleSelection = ({ selected }: any) => {
        filter.value = { ...filter.value, ...selected }
      }

      const createNewContact = (value: boolean) => {
        displayForm.value = !value ? value : canDo.value

        router.push({
          name: 'company.contact',
          params: { id: route.params.id }
        })
      }

      const handleUserSelection = (contact: any) => {
        displayForm.value = canDo.value
        router.push({
          name: 'company.contact',
          params: { id: route.params.id, contactId: contact?.id }
        })
      }

      const getContacts = () => {
        if (!currentCompany.value) return
        store.dispatch('organizations/FILTER_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: { ...filter.value, limit: 10, offset: 0 }
        })
      }

      const getLegalStatus = () => {
        store.dispatch('toolbox/GET_LEGAL_STATUS')
      }

      onMounted(getLegalStatus)
      return {
        showTreeview,
        canDo,
        documentDisplayed,
        currentContact,
        filtered,
        isVisible,
        filter,
        aliasesId,
        displayForm,
        handleSelection,
        getContacts,
        handleUserSelection,
        createNewContact
      }
    }
  })
