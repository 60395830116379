export enum SelectionType {
  CATEGORY = 0,
  DATE = 1
}

export enum DocumentMarkAs {
  assemblies = 'ASSEMBLIES',
  president_decisions = 'PRESIDENT_DECISIONS',
  committees = 'COMMITTEES_MINUTES'
}
