
  import {
    defineComponent,
    ref,
    computed,
    onMounted,
    onErrorCaptured,
    watch
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { envName } from '@/config'
  import FileLoader from '@/components/FileLoader/index.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import Header from '@/components/Header/index.vue'
  import SubHeader from '@/components/Header/Subnav.vue'
  import PortfolioSubHeader from '@/components/Header/SubnavPortfolio.vue'
  import Popper from 'vue3-popper'
  import { createInitials, storageKeys, truncText } from '@/utils'
  import { getFormStorage } from '@/services/Storage'
  import MainActions from '@/components/MainActionList/index.vue'
  import DocPreview from '@/components/DocPreview/index.vue'
  import { CompanyModel, User } from '@/services/api/models'
  import useBreakpoints from '@/plugins/breakpoint'
  import * as Sentry from '@sentry/vue'

  export default defineComponent({
    name: 'Logged',
    components: {
      DocPreview,
      ContactBadge,
      Header,
      SubHeader,
      PortfolioSubHeader
      // Popper,
      // MainActions,
      // FileLoader
    },

    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const displaySelector = ref(false)
      const companySearch = ref('')
      const isModalOpen = ref(true)
      const selectorInput = ref<HTMLInputElement | null>(null)
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const displayStyle = computed<string | undefined>(
        () => route?.meta?.docPreview as string
      )

      const portfolioLink = computed(
        () => `${location.origin}/app/portfolio-view`
      )
      const documentToShow = computed(
        () => store.getters['organizations/currentDocument']
      )
      const { type } = useBreakpoints()
      const isPreviewDisplayed = computed(() => {
        return store.getters['ui/displayDocumentPreview']
      })

      const handleClick = () => {
        minifyDocPreview.value = !minifyDocPreview.value
        store.commit('ui/COLLAPSE_DOC_PREVIEW', !minifyDocPreview.value)
      }

      const loadCompanyInformations = async (companyId: string | null) => {
        store.dispatch(`organizations/GET_SINGLE_COMPANY`, {
          companyId,
          onSuccess: () => {
            store.dispatch(`organizations/INIT_ORGANIZATION`, {
              companyId: companyId || route.params?.id?.toString()
            })
          },
          onError: (error: any) => {
            Sentry.captureException(error)
          }
        })
      }

      const getCompanies = async (search = '') => {
        await store.dispatch(`organizations/GET_LOADER_COMPANIES`, {
          filter: { search, limit: 20 }
        })
      }

      const loadCompanyState = async (companyId: string) => {
        if (!companyId) return
        // await store.dispatch(`organizations/GET_COMPANY_DOCUMENTS`, {
        //   companyId,
        //   filter: { limit: 300 }
        // })
      }

      const user = computed<User>(() => store.getters['auth/profile'])

      const companies = computed<CompanyModel[]>(
        () => store.getters['organizations/loaderCompanies']
      )

      const minifyDocPreview = ref(false)

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      onErrorCaptured((err) => {
        console.log(err)
      })

      const handleUserSearch = (event: any) => {
        getCompanies(event.target.value)
      }

      const navigateToDocuments = (e: Event) => {
        e.preventDefault()

        if (currentCompany.value)
          router.push({
            name: 'companyDocuments',
            params: {
              id:
                currentCompany.value.id ||
                getFormStorage(storageKeys.currentCompany)
            }
          })
        else router.push({ name: 'companyOverview' })
      }

      const navigateToContacts = (e: Event) => {
        e.preventDefault()

        if (currentCompany.value)
          router.push({
            name: 'company.contact',
            params: {
              id: currentCompany.value.id
            }
          })
        else router.push({ name: 'companyOverview' })
      }

      const setCurrentCompany = async (company: CompanyModel) => {
        store.commit(`organizations/SET_CONTEXT_COMPANY`, company)

        store.dispatch(`organizations/GET_SINGLE_COMPANY`, {
          companyId: company.id,
          onSuccess: (cmp: CompanyModel) => {
            companySearch.value = ''
            displaySelector.value = false
            location.reload()
          },
          onError: (error: any) => {
            Sentry.captureException(error)
          }
        })
      }

      const closePreView = async () => {
        store.commit(`organizations/SET_COMPANY_SINGLE_DOCUMENT`, null)
        store.commit('ui/DISPLAY_PREVIEW', false)
      }

      const appEnv = ref(envName)
      const canDisplayEnv = appEnv.value !== 'production'

      watch(currentCompany, (newCmp) => {
        loadCompanyState(newCmp?.id || '')
      })

      watch(
        () => documentToShow.value,
        (val) => {
          minifyDocPreview.value = !val
        }
      )

      const autoCloseDocumentPreview = () => {
        if (route.meta?.docPreview !== 'integred') {
          // documentToShow.value = null
        }
      }
      const buildInitials = (value: string) => {
        return createInitials(value)
      }
      const companySelectorOpen = () => {
        displaySelector.value = !displaySelector.value
        setTimeout(() => {
          selectorInput.value?.focus()
        }, 500)
      }

      const closeCompanySelector = () => {
        if (displaySelector.value) {
          displaySelector.value = false
        }
      }

      onMounted(async () => {
        getCompanies()

        setTimeout(() => {
          const cmpId: string | null =
            route?.params?.id?.toString() ||
            getFormStorage(storageKeys.currentCompany) ||
            null
          loadCompanyInformations(cmpId)
        }, 1000)

        await store.dispatch('config/init')
        await store.dispatch('toolbox/getDocumentCategories')
        await store.dispatch('toolbox/GET_STOCK_CATEGORIES')
        await store.dispatch('toolbox/GET_OPTION_CATEGORIES')
        await store.dispatch('toolbox/GET_CONTACT_GROUPS')
      })

      return {
        appEnv,
        isModalOpen,
        displayStyle,
        canDisplayEnv,
        companies,
        user,
        type,
        documentToShow,
        portfolioLink,
        isPreviewDisplayed,
        currentCompany,
        isPortfolioRoute,
        displaySelector,
        companySearch,
        selectorInput,
        minifyDocPreview,
        handleClick,
        truncateText: truncText,
        closePreView,
        navigateToDocuments,
        navigateToContacts,
        setCurrentCompany,
        closeCompanySelector,
        buildInitials,
        handleUserSearch,
        autoCloseDocumentPreview,
        companySelectorOpen
      }
    }
  })
