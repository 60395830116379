
  import { Button, Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import CompanyCreatorModal from '@/components/CompanyCreator/index.vue'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import EditCompanyForm from '@/components/CompanyCreator/edit.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Company, CompanyModel } from '@/services/api/models'
  import Modal from '@/components/Modal/Modal.vue'
  import { comnpaniesListColumns } from '@/utils/data'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'AccountCompanies',
    components: {
      EditCompanyForm,
      CompanyCreatorModal,
      Button,
      Input,
      DataTable,
      Modal,
      ElTooltip,
      Field
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const search = ref<string>('')
      let outerVisible = ref(false)
      const canEdit = ref(false)
      const canDelete = ref(false)
      const actualCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )
      const currentCompany = ref<CompanyModel | null>(null)
      const companyDeleteName = ref('')
      const toDeleteName = computed(() =>
        currentCompany.value?.name.replaceAll(/\s/g, '').toLowerCase()
      )
      const compareCompanieText = computed(() =>
        t('account.accountCompanies.deltionCompanyName', {
          name: toDeleteName.value
        })
      )

      const companies = computed(() =>
        store.getters['organizations/companies'].map((x: any) => ({
          ...x,
          current: actualCompany.value?.id === x.id
        }))
      )
      const pagination = computed(
        () => store.getters['organizations/companiesPagination']
      )
      const company = computed(() => store.getters['auth/company'])

      const getCompanies = () => {
        store.dispatch('organizations/GET_COMPANIES', {
          filter: { search: search.value }
        })
      }
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_COMPANIES_PAGINATION_PAGE', page)
        getCompanies()
      }

      const columns = comnpaniesListColumns

      const hideEdit = (value: boolean) => {
        canEdit.value = value
      }
      const editCompany = async (company: Company) => {
        await store.dispatch('auth/GET_COMPANY', { companyId: company.id })
        canEdit.value = true
      }

      const displayModal = (cmp: CompanyModel) => {
        companyDeleteName.value = ''
        currentCompany.value = { ...cmp }
        canDelete.value = true
      }

      const closeModal = () => {
        companyDeleteName.value = ''
        currentCompany.value = null
        canDelete.value = false
      }

      const deleteCompany = (companyId: string) => {
        store.dispatch('organizations/REMOVE_COMPANY', {
          companyId,
          onSuccess: () => {
            if (actualCompany.value.id == companyId) {
              store.commit(`organizations/SET_COMPANY`, null)
            }
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('newCompany.title'),
              message: t('newCompany.apiResponse.deleted')
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('newCompany.title'),
              message: t(`newCompany.apiResponse.deletionError`)
            })
          }
        })
      }

      const loadCompanyInformations = async () => {
        store
          .dispatch(`organizations/GET_LOADER_COMPANIES`, {})
          .then(async () => {
            await store.dispatch(`organizations/GET_COMPANIES`, {}).then(() => {
              store.dispatch(`organizations/INIT_ORGANIZATION`, {
                companyId: route.params.id
              })
            })
          })
      }

      const submitDeletion = async () => {
        if (
          currentCompany.value &&
          currentCompany.value.id &&
          companyDeleteName.value === compareCompanieText.value
        ) {
          await deleteCompany(currentCompany.value.id)

          companyDeleteName.value = ''
          closeModal()
        }
      }

      const changeCurrentContext = async (cmp: CompanyModel) => {
        if (cmp && cmp.id) {
          await store.commit(`organizations/SET_COMPANY`, cmp)
          router.push({
            name: route.name || undefined,
            params: { ...route.params, id: cmp.id }
          })
          await loadCompanyInformations()
          search.value = ''
          store.dispatch('toolbox/displayToast', {
            type: TYPE.DEFAULT,
            context: t('account.accountCompanies.context'),
            message: t('account.accountCompanies.updateContext', {
              name: cmp.name
            })
          })
        }
      }

      watch(search, () => {
        store.commit('organizations/SET_COMPANIES_PAGINATION_PAGE', 1)
        getCompanies()
      })

      onMounted(() => {
        getCompanies()
      })

      return {
        search,
        outerVisible,
        columns,
        companies,
        company,
        canEdit,
        pagination,
        canDelete,
        currentCompany,
        toDeleteName,
        companyDeleteName,
        editCompany,
        hideEdit,
        onPageChange,
        displayModal,
        submitDeletion,
        changeCurrentContext,
        closeModal,
        moment
      }
    }
  })
