<template>
  <individual-contact-display
    v-if="contact.type === CONTACTTYPE.INDIVIDUAL"
    :contact="contact"
    @delete-contact="handleDeletion"
  />
  <professional-contact-display
    v-if="contact.type === CONTACTTYPE.PROFESSIONAL"
    :contact="contact"
    @delete-contact="handleDeletion"
  />
  <investor-contact-display
    v-if="contact.type === CONTACTTYPE.INVESTOR"
    :contact="contact"
    @delete-contact="handleDeletion"
  />

  <transition name="modal">
    <Modal v-if="canDelete" size="w-2/5" @close="canDelete = false">
      <template #header>
        <h3 class="text-md font-semibold text-error">
          {{ $t('contactBook.deleteModalTextHeader') }}
        </h3>
      </template>

      <template #body>
        <p class="select-none" v-html="$t('contactBook.deleteModalText')"></p>
      </template>
      <template #footer>
        <div class="flex text-xl justify-end">
          <Button
            class="flex text-white bg-primary rounded mr-2"
            variant="custom"
            :label="$t('account.accountCompanies.deletionModalCancel')"
            @click="canDelete = false"
          />
          <Button
            class="flex text-white bg-error rounded"
            variant="custom"
            :label="$t('account.accountCompanies.deletionModalText')"
            @click.prevent="submitDeletion"
          />
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script lang="ts">
  import { useStore } from 'vuex'
  import { computed, defineComponent, ref } from 'vue'
  import moment from 'moment'
  import { CompanyModel } from '@/services/api/models1'
  import IndividualContactDisplay from './IndividualDisplay.vue'
  import ProfessionalContactDisplay from './ProfessionalDisplay.vue'
  import InvestorContactDisplay from './InvestorDisplay.vue'
  import { CONTACTTYPE } from '@/types'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import Modal from '@/components/Modal/Modal.vue'
  import { TYPE } from 'vue-toastification'
  import { Button } from '@up.law/uplaw-ui'

  export default defineComponent({
    name: 'ContactCard',
    components: {
      IndividualContactDisplay,
      ProfessionalContactDisplay,
      InvestorContactDisplay,
      Modal,
      Button
    },
    directives: {},
    props: {
      contact: {
        type: Object,
        required: true
      },
      noPadding: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['update-contact-list'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const canDelete = ref(false)
      const showCardSecondary = ref(true)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const contactId = ref()

      const handleDeletion = (id: string) => {
        contactId.value = id
        canDelete.value = id !== null
      }

      const submitDeletion = () => {
        store.dispatch(`organizations/DELETE_CONTACT`, {
          companyId: currentCompany.value.id,
          contactId: contactId.value,
          onError: (error = null) => {
            const message = error || t(`contactBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            contactId.value = null
            store.commit('organizations/SET_CURRENT_CONTACT', null)
            emit('update-contact-list')
            router.push({
              name: 'company.directory',
              params: { id: route.params.id }
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('contactBook.form.title'),
              message: t(`contactBook.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      return {
        CONTACTTYPE,
        showCardSecondary,
        canDelete,
        currentCompany,
        moment,
        handleDeletion,
        submitDeletion
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'contactCard.scss';
</style>
