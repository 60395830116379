
  import { CommonCeiling } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import moment from 'moment'
  export default defineComponent({
    name: 'CommonCeilingCard',
    props: {
      event: {
        type: Object as () => CommonCeiling | undefined,
        required: true
      }
    },
    setup() {
      return { moment }
    }
  })
