
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field, Form, FieldArray } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { InvitationForm } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import * as yup from 'yup'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import { Contact, Role } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { groupItemBy } from '@/utils'
  import { Company } from '@/store/modules/Toolbox/models'
  import { TYPE } from 'vue-toastification'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    components: {
      Multiselect,
      Field,
      Form,
      Input,
      Button,
      FormActions,
      FieldArray,
      DropdownCompany,
      DropdownGeneric,
      Checkbox
    },
    props: {
      companyId: {
        type: String,
        required: false,
        default: null
      },
      account: { type: Object as () => InvitationForm, required: true }
    },
    emits: ['editAccount', 'onClose'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t, te } = useI18n()

      const companyContacts = ref<any[]>([])

      const currentUser = ref<any>({
        ...props.account,
        roles: props.account.roles,
        useMFA: props.account?.useMFA || false,
        accounts: props.account.accounts
          ? props.account.accounts
          : [
              {
                contactId: props.account?.contactId ?? '',
                roleId: props.account?.roles,
                companyId: props.account?.companyId ?? props.companyId ?? ''
              }
            ]
      })

      const hasAdminRole = computed(() => currentUser.value.roles.length > 0)

      const companies = computed<Company[]>(
        () => store.getters['organizations/loaderCompanies']
      )
      const allRoles = computed<Role[]>(() => store.getters['toolbox/roles'])

      const userRoles = computed(() => store.getters['auth/roles'])
      const profile = computed(() => store.getters['auth/profile'])
      const isUserGlobalRoute = computed(() => route.name === 'accountUsers')

      const highestPriority = computed(() =>
        Math.max(...(profile.value?.roles || []).map((x: Role) => x.priority))
      )

      const handleContact = ($event: any, index: number) => {
        currentUser.value.accounts[index].contactId = $event?.value
      }
      const addRows = (push: any) => {
        currentUser.value.accounts?.push({
          companyId: props.companyId || '',
          roleId: '',
          contactId: ''
        })
        push({
          companyId: props.companyId || '',
          roleId: '',
          contactId: ''
        })
      }
      const removeRow = (
        index: number,
        id: string,
        companyId: string,
        remove: any
      ) => {
        if (id) removeCompanyUserProfile(id, companyId, index, remove)
        else {
          remove(index)
          const elms = [...currentUser.value.accounts].filter(
            (x, i) => i !== index
          )
          currentUser.value.accounts = elms
        }
      }
      const companyRoles = computed(() =>
        allRoles.value.filter((r) => r.priority > 1)
      )
      const roles = computed(() =>
        allRoles.value
          .filter(
            (x) =>
              x.priority >=
              (![0, 2].includes(highestPriority.value)
                ? highestPriority.value + 1
                : highestPriority.value)
          )
          .filter((role) =>
            props.companyId
              ? role.priority > 1
              : [0, 1].includes(highestPriority.value)
              ? role.priority <= 1
              : role.priority > 1 && role.priority <= 7
          )
          .sort((a, b) => a.priority - b.priority)
          .map((r) => ({
            ...r,
            name: te(`roles.${r.slug}`) ? t(`roles.${r.slug}`) : r.name
          }))
      )

      const getCompanyContacts = (id: string) => {
        return allContacts.value.filter((x: Contact) => x.company?.id === id)
      }

      const handleContactSearch = (value: string) => {
        getContacts(value)
      }

      const allContacts = computed(() =>
        store.getters['auth/contacts'].filter((x: Contact) => x.company)
      )

      const contacts = computed(() => {
        const cs = groupItemBy(
          allContacts.value,
          (contact: Contact) => contact?.company?.name || 'zz-other'
        )

        const data: any[] = []
        cs.forEach(function (value, key) {
          data.push({
            id: key,
            name: key === 'zz-other' ? t('global.zz-other') : key,
            children: value
          })
        })

        return isUserGlobalRoute.value ? data : allContacts.value
      })

      const schema = computed(() =>
        yup.object().shape({
          email: yup.string().email().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          roles: yup.array(yup.string()).nullable().default([]),
          accounts: yup
            .array()
            .of(
              yup.object().shape({
                contactId: yup.string().when('roleId', {
                  is: (value: string) => {
                    return allRoles.value
                      .filter((r) => r.isContactRequired)
                      .map((x) => x.id)
                      .includes(value)
                  },
                  then: (schema) => schema.required('roleMustHaveContact'),
                  otherwise: (schema) => schema.optional().nullable()
                }),
                companyId: yup.string().required('companyRequired'),
                roleId: yup.string().required('roleRequired')
              })
            )
            .default([])
        })
      )

      const getContacts = (search = '') => {
        store.dispatch('auth/GET_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0,
            search
          }
        })
      }

      const getRoles = () => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id
        })
      }

      const removeCompanyUserProfile = (
        accountId: string,
        companyId: string,
        index: number,
        removeLine: any
      ) => {
        store.dispatch('organizations/DELETE_USER_COMPANY_PROFILE', {
          userId: currentUser.value.id,
          companyAccountId: accountId,
          companyId,
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.userCompanyAccountDeletionError`
              )
            })
          },
          onSuccess: () => {
            removeLine(index)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.userCompanyAccountDeletionSuccess`
              )
            })
          }
        })
      }

      const handleForm = (values: InvitationForm) => {
        emit('editAccount', values)
      }

      watch(props, (acc: any) => {
        currentUser.value = acc.account
      })

      onMounted(() => {
        console.log(props.account)

        getContacts()
        getRoles()
      })

      return {
        currentUser,
        isUserGlobalRoute,
        highestPriority,
        contacts,
        roles,
        schema,
        companies,
        companyRoles,
        hasAdminRole,
        handleContactSearch,
        handleContact,
        addRows,
        removeCompanyUserProfile,
        removeRow,
        companyContacts,
        getCompanyContacts,
        handleForm
      }
    }
  })
