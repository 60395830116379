import { PasswordArg } from '@/store/modules/User'
import axios from './axios'
import { auth, company } from './endpoints'
import { InvitationForm } from '@/types/forms'
import { UserModel } from './models'

const getUserProfile = async (auth0Id: string): Promise<UserModel> => {
  return (await axios.get(auth.profile(auth0Id), {})).data
}

const getUserData = async (auth0Id: string): Promise<UserModel> => {
  return axios.get(auth.profile(auth0Id), {})
}

const updateUserProfile = async (
  auth0Id: string,
  profile: UserModel
): Promise<UserModel> => {
  const { data } = await axios.put(auth.profile(auth0Id), { ...profile })
  return data
}
const updateUserPassword = async (
  auth0Id: string,
  pwd: PasswordArg
): Promise<any> => {
  const { data } = await axios.put(auth.password(auth0Id), pwd)

  return data
}

const uploadPicture = async (file: FormData): Promise<void> => {
  // const { data } = await axios.put(auth.picture(), file)
  // return data
}

const createUserAccount = async (
  id: string,
  form: InvitationForm
): Promise<void> => {
  const { data } = await axios.post(company.users(id), form)
  return data
}

const sendInvitationMail = async (
  id: string,
  userId: string
): Promise<void> => {
  const { data } = await axios.put(company.mail(id, userId))
  return data
}

const editUserAccount = async (
  id: string,
  userId: string,
  form: InvitationForm
): Promise<any> => {
  const { data } = await axios.put(company.getUser(id, userId), form)
  return data
}

const removeUserAccount = async (id: string, userId: string): Promise<void> => {
  return await axios.delete(company.getUser(id, userId))
}

const removeUserCompanyAccount = async (
  id: string,
  userId: string,
  accountId: string
): Promise<void> => {
  return await axios.delete(
    company.getUserCompanyAccount(id, userId, accountId)
  )
}

export {
  getUserProfile,
  getUserData,
  updateUserProfile,
  uploadPicture,
  updateUserPassword,
  createUserAccount,
  editUserAccount,
  removeUserAccount,
  removeUserCompanyAccount,
  sendInvitationMail
}
