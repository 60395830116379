import * as yup from 'yup'
import { useI18n } from 'vue-i18n'
import {
  Primitive,
  FloatPrimitive,
  StringPrimitive,
  IntergerPrimitive,
  EnumPrimitive
} from '@/services/api/models/document'

//TODO union type when the models are integrated
type Form = any
type Entry = any

import moment from 'moment'
import { ArrayPrimitive } from '@/services/api/models/document'
import { EventFormPrimitive } from '@/types/event'
import i18n from '@/services/lang'
export const DATETYPE = 'date'
export const STRINGTYPE = 'string'
export const BOOLEANTYPE = 'boolean'
export const NUMBERTYPE = 'number'
export const FLOATNUMBER = 'float'
export const ARRAYTYPE = 'array'
interface Config {
  id: string
  validations: Array<any>
  validationType: string
}

type FormGlobalPrimitive =
  | Primitive
  | FloatPrimitive
  | StringPrimitive
  | IntergerPrimitive
  | EnumPrimitive
  | ArrayPrimitive

interface ValidationData {
  type: string
  params: any[]
}

interface ValidationField {
  id: string
  validationType: string
  validations: ValidationData[]
  rows?: any[]
}
export const primitivesFields = [
  'booleanPrimitives',
  'stringPrimitives',
  'dateTimePrimitives',
  'enumPrimitives',
  'floatPrimitives',
  'integerPrimitives'
]
export const primitivesTypes = [
  'boolean',
  'string',
  'dateTime',
  'enum',
  'float',
  'integer'
]

export const createYupSchema = (schema: any, config: any) => {
  const { id, validationType, validations = [], rows = [] } = config
  if (!(yup as any)[validationType]) {
    return schema
  }

  let validator = (yup as any)[validationType]().label(id)
  validations.forEach((validation: any) => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    validator = validator[type](...params)
  })

  if (rows.length > 0) {
    const obj = rows.reduce(createYupSchema, {})
    validator = (yup as any)
      [validationType]()
      .of(yup.object(obj))
      .min(1, 'min-array')
  }

  schema[id] = validator
  return schema
}

export const buildFormField = (
  field: FormGlobalPrimitive,
  validationType = STRINGTYPE,
  type = NUMBERTYPE
): ValidationField => {
  const element: ValidationField = {
    validationType,
    id: field.name,
    validations: [],
    rows: undefined
  }
  if (field.rows) {
    // console.log(field.rows)
    element.rows = generateValidationsSingle(field.rows)
  }

  if (validationType === NUMBERTYPE) {
    element.validations.push(
      ...[
        { type, params: [i18n.global.t(`errors.rules.${type}`)] },
        { type: 'typeError', params: [i18n.global.t(`errors.rules.${type}`)] }
      ]
    )
  }
  if (validationType === STRINGTYPE) {
    element.validations.push(
      ...[
        { type, params: [i18n.global.t(`errors.rules.${type}`)] },
        { type: 'typeError', params: [i18n.global.t(`errors.rules.string`)] }
      ]
    )
  }
  if (validationType === DATETYPE) {
    element.validations.push(
      ...[{ type: 'typeError', params: [i18n.global.t(`errors.rules.date`)] }]
    )
  }
  if (field.isMandatory) {
    element.validations.push({
      type: 'required',
      params: [i18n.global.t(`errors.rules.required`)]
    })
  }

  if (field.requiredIf && field.requiredIf.length) {
    const [testField, check] = field.requiredIf
    element.validations.push({
      type: 'when',
      params: [
        testField,
        {
          is: check,
          then: (schema: any) =>
            schema.required(i18n.global.t(`errors.rules.required`)),
          otherwise: (schema: any) => schema.optional().nullable()
        }
      ]
    })
  }

  if (field.isNullable) {
    element.validations.push({
      type: 'nullable',
      params: [true]
    })
    element.validations.push({
      type: 'transform',
      params: [(_: any, val: any) => (val ? Number(val) : null)]
    })
  }
  if ('max' in field) {
    element.validations.push({
      type: 'max',
      params: [
        field.max,
        i18n.global.t(
          `errors.rules.${
            validationType === DATETYPE ? 'dateMax' : 'valueMax'
          }`,
          {
            value:
              validationType === DATETYPE
                ? moment(field.max).toDate()
                : field.max
          }
        )
      ]
    })
  }

  if ('min' in field) {
    element.validations.push({
      type: 'min',
      params: [
        field.min || 0,
        i18n.global.t(
          `errors.rules.${
            validationType === DATETYPE ? 'dateMin' : 'valueMin'
          }`,
          {
            value:
              validationType === DATETYPE
                ? moment(field.min).toDate()
                : field.min
          }
        )
      ]
    })
  }

  if ('maxLength' in field) {
    element.validations.push({
      type: 'max',
      params: [
        field.maxLength,
        i18n.global.t(`errors.rules.max`, {
          value: field.maxLength
        })
      ]
    })
  }

  if ('minLength' in field) {
    element.validations.push({
      type: 'min',
      params: [
        field.minLength,
        i18n.global.t(`errors.rules.max`, {
          value: field.minLength
        })
      ]
    })
  }

  return element
}

export const generateValidations = (form: Form): ValidationField[] => {
  return form.reduce((acc: ValidationField[], entry: Entry) => {
    entry.primitives.forEach((field: FormGlobalPrimitive) => {
      acc.push(buildFormField(field, field.type))
    })
    return acc
  }, [])
}

export const generateValidationsSingle = (form: Form): ValidationField[] => {
  return form.reduce((acc: ValidationField[], field: FormGlobalPrimitive) => {
    acc.push(buildFormField(field, field.type))
    return acc
  }, [])
}

export const generateFormDefaultValue = (form: EventFormPrimitive[]) => {
  const data: any = {}
  form.forEach((element) => {
    if (element.type === STRINGTYPE) {
      data[element.name] = ''
    } else if (element.type === NUMBERTYPE) {
      data[element.name] = 0
    } else if (element.type === DATETYPE) {
      data[element.name] = moment().toISOString()
    } else if (element.type === ARRAYTYPE) {
      data[element.name] = [generateFormDefaultValue(element.rows || [])]
    } else if (element.type === FLOATNUMBER) {
      data[element.name] = 0.0
    } else if (element.type === BOOLEANTYPE) {
      data[element.name] = false
    }
  })

  return data
}
