
  import { mapGetters, useStore } from 'vuex'
  import ActionList from '@/components/ActionList/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import {
    computed,
    defineComponent,
    ref,
    onBeforeUnmount,
    watch,
    onMounted
  } from 'vue'
  import DocumentForm from '@/components/Forms/DocumentForm/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Document } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { TYPE } from 'vue-toastification'
  export default defineComponent({
    name: 'ExtractionLv1',
    components: {
      ActionList,
      EmptyState,
      DocumentForm
    },
    props: {
      uuid: {
        type: String,
        required: true,
        default: ''
      }
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const router = useRouter()
      const picked = ref(new Date())
      const onOptionSelected = () => {
        console.log('Selected Option')
      }
      const formUpdated = (values: any) => {
        console.log(values)
      }
      const document = ref<Document | null>(null)
      const currentDocument = computed(
        () => store.getters[`organizations/currentDocument`]
      )

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      const categories = computed(
        () => store.getters[`toolbox/documentCategories`]
      )

      const deleteDocument = () => {
        if (currentDocument.value) {
          store.dispatch('organizations/DELETE_COMPANY_DOCUMENT', {
            companyId: currentCompany.value.id,
            documentId: currentDocument.value.id,
            onError: (error = null) => {
              const message = error || t(`documents.apiResponse.deleteError`)
              store.dispatch('toolbox/displayToast', {
                type: TYPE.ERROR,
                context: t('documents.context'),
                message
              })
            },
            onSuccess: () => {
              router.push({
                name: 'companyDocuments',
                params: { id: route.params.id }
              })
              store.dispatch('toolbox/displayToast', {
                type: TYPE.SUCCESS,
                context: t('documents.context'),
                message: t(`documents.apiResponse.deleteSuccess`)
              })
            }
          })
        }
      }

      // onBeforeUnmount(() => {
      //   store.commit(`organizations/SET_COMPANY_SINGLE_DOCUMENT`, null)
      //   store.commit('ui/DISPLAY_PREVIEW', false)
      // })

      onMounted(async () => {
        if (route.params.documentId)
          await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
            documentId: route.params.documentId,
            companyId: route.params.id
          })
      })

      watch(currentDocument, (val) => {
        document.value = val
      })

      return {
        document,
        currentDocument,
        categories,
        currentCompany,
        onOptionSelected,
        picked,
        formUpdated,
        deleteDocument
      }
    },
    data() {
      return {
        documentForCancel: null,
        cleared: undefined,
        comment: undefined,
        auditForCancel: null,
        audit: {
          uuid: null,
          cleared: false,
          clearedAt: null,
          comment: '',
          targetType: null,
          targetId: null
        }
      }
    },
    computed: {
      ...mapGetters('company', ['currentCompany']),
      hasMissingOther: {
        get() {
          return this.audit && !!this.audit.comment
        },
        set(newValue: boolean) {
          console.log(newValue)
        }
      },
      canCancel() {
        return {}
        // return !(
        //   JSON.stringify(this.currentDocument) ===
        //     JSON.stringify(this.documentForCancel) &&
        //   JSON.stringify(this.audit) === JSON.stringify(this.auditForCancel)
        // )
      }
    },
    watch: {
      uuid() {
        if (this.uuid !== null) {
          this.getDocument(this.uuid)
        }
      }
    },
    created() {
      if (
        this.$route.params.uuid !== '' &&
        this.$route.params.uuid !== undefined
      ) {
        this.getDocument(this.$route.params.uuid)
      }
    },
    methods: {
      resetDocument() {
        // this.currentDocument = JSON.parse(JSON.stringify(this.documentForCancel))
        this.audit = JSON.parse(JSON.stringify(this.auditForCancel))
      },
      getDocument(documentId: string | Array<string>) {
        // this.currentDocument = null

        this.audit = {
          uuid: null,
          cleared: false,
          clearedAt: null,
          comment: '',
          targetType: null,
          targetId: null
        }
      },

      updateDocument() {
        // if (this.currentDocument.signedAt === '') {
        //   this.currentDocument.signedAt = null
        // }

        const docInfos = JSON.parse(JSON.stringify(this.currentDocument))

        docInfos.Signatory = docInfos.Signatory.map((docSign: any) => {
          const signatory = docSign
          delete signatory.Documents
          delete signatory.Company

          return signatory
        })

        const data = {
          documentId: this.$route.params.uuid,
          companyId: this.currentCompany.uuid,
          docInfos
        }

        // apiCompany
        //   .updateDocument(data, (response) => {
        //     this.documentForCancel = JSON.parse(
        //       JSON.stringify(this.currentDocument)
        //     )
        //     this.$emit('create-toast', {
        //       title: 'Edition',
        //       msg: `Document mis à jour`,
        //       toastStyle: 'success',
        //       autoClose: true,
        //     })
        //     this.$emit('reloadTreeview', this.currentDocument)
        //   })
        //   .catch((error) => {
        //     this.$emit('create-toast', {
        //       title: 'Edition',
        //       msg: `Erreur dans la mise à jour du document. ${error}`,
        //       toastStyle: 'danger',
        //       autoClose: true,
        //     })
        //   })

        // if (!this.audit.uuid && !this.audit.comment) {
        //   this.$store
        //     .dispatch('audit/postAudit', {
        //       companyUuid: this.currentCompany.uuid,
        //       audit: this.audit,
        //     })
        //     .then(() => {
        //       this.auditForCancel = JSON.parse(JSON.stringify(this.audit))
        //     })
        // } else if (this.audit.uuid) {
        //   if (!this.audit.comment) {
        //     apiCompany.deleteAudit(
        //       {
        //         companyId: this.currentCompany.uuid,
        //         audit: { uuid: this.audit.uuid },
        //       },
        //       () => {}
        //     )
        //   } else {
        //     this.$store
        //       .dispatch('audit/putAudit', {
        //         companyUuid: this.currentCompany.uuid,
        //         audit: this.audit,
        //       })
        //       .then(() => {
        //         this.auditForCancel = JSON.parse(JSON.stringify(this.audit))
        //       })
        //   }
        // }
      }
    }
  })
