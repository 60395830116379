
  import { StockAlias } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'

  export default defineComponent({
    props: {
      stock: {
        type: Object as () => StockAlias,
        required: true
      },
      isCurrent: {
        type: Boolean,
        required: false,
        default: false
      },
      color: {
        type: String,
        required: false,
        default: '#09065F'
      }
    },
    emits: ['onSelect'],
    setup(props, { emit }) {
      const currentStock = computed(() => props.isCurrent)
      const onClick = () => {
        emit('onSelect', props.stock)
      }
      return { currentStock, onClick }
    }
  })
