<template>
  <div class="px-4 py-5 md:w-full">
    <div class="flex justify-between lg:w-3/4">
      <h1 class="text-left text-secondary9">
        {{ $t('account.accountInformations.baseline') }}
      </h1>
    </div>
    <section class="py-4 mt-4 mb-3 md:w-full lg:w-full">
      <profile-avatar-form
        :profile-image="userProfilImage"
        class="mb-5"
        @fileChanged="fileChanged"
      />

      <Form
        v-slot="{ errors, values }"
        :validation-schema="schema"
        :initial-values="account"
      >
        <div class="flex mt-3 justify-between">
          <div id="form_firstname" class="w-1/4 form-group pr-2">
            <label class="text-left">{{
              $t('account.accountInformations.form.firstname.label')
            }}</label>
            <Field
              v-slot="{ field }"
              v-model="account.firstName"
              type="text"
              name="firstName"
              autocomplete="false"
            >
              <Input
                v-model="account.firstName"
                v-bind="field"
                name="firstName"
                :disabled="false"
                class="h-10"
                :error="
                  errors.firstName ? $t(`errors.${errors.firstName}`) : null
                "
                :placeholder="
                  $t('account.accountInformations.form.firstname.placeholder')
                "
              />
            </Field>
          </div>

          <div id="form_lastname" class="w-1/4 form-group pl-2">
            <label class="text-left">{{
              $t('account.accountInformations.form.lastname.label')
            }}</label>
            <Field
              v-slot="{ field }"
              v-model="account.lastName"
              type="text"
              name="lastName"
            >
              <Input
                v-model="account.lastName"
                v-bind="field"
                :disabled="false"
                class="mw-100 w-11/12 h-10"
                :error="
                  errors.lastName ? $t(`errors.${errors.lastName}`) : null
                "
                type="text"
                :placeholder="
                  $t('account.accountInformations.form.lastname.placeholder')
                "
              />
            </Field>
          </div>

          <div id="form_email" class="w-1/4 form-group">
            <label class="text-left">{{
              $t('account.accountInformations.form.email.label')
            }}</label>
            <Field
              v-slot="{ field }"
              v-model="account.email"
              type="text"
              name="email"
            >
              <Input
                v-model="account.email"
                v-bind="field"
                disabled
                class="w-full h-10"
                :error="errors.error ? $t(`errors.${errors.email}`) : null"
                type="email"
                :placeholder="
                  $t('account.accountInformations.form.email.placeholder')
                "
              />
            </Field>
          </div>

          <div class="w-1/4 form-group pl-3">
            <label class="text-left">{{
              $t('account.accountInformations.language')
            }}</label>

            <LangSelector
              id="language"
              class="h-10"
              :value="languageSelected"
              name="language"
              :placeholder="
                $t('account.accountInformations.languagePlaceholder')
              "
              :groups="false"
              @on-selected="switchLanguage"
            />
          </div>
        </div>
        <div class="mt-7 w-100 flex justify-end items-center text-xl">
          <Button
            class="text-xl flex justify-center items-center"
            variant="primary"
            size="medium"
            name="fo_accountInformation_submit"
            :label="$t('account.accountInformations.form.submit')"
            type="submit"
            :disabled="false"
            @click.prevent="updateProfile(values)"
          />
        </div>
      </Form>
    </section>
  </div>
</template>

<script lang="ts">
  import { Button, Input } from '@up.law/uplaw-ui'
  import { computed, defineComponent, ref, onMounted, watch } from 'vue'
  import { log } from 'loglevel'
  import { Form, Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import * as yup from 'yup'
  import ProfileAvatarForm from '@/components/Forms/Configurable/Contact/ProfileAvatarForm.vue'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import LangSelector from '@/components/LangSelector/index.vue'
  import { Company, User } from '@/services/api/models'

  export default defineComponent({
    name: 'AccountInformations',

    components: {
      Button,
      Input,
      Form,
      Field,
      ProfileAvatarForm,
      LangSelector
    },
    setup() {
      const { t } = useI18n()
      const pictureBase64 = ref()
      const loadedFile = ref<File | null>()
      const imgPreview = ref()
      const loading = ref(false)
      const store = useStore()

      const schema = yup.object({
        firstName: yup.string().min(3).required(),
        lastName: yup.string().min(3).required(),
        email: yup.string().min(8).required().email(),
        lang: yup.string().required().oneOf(['fr', 'en'])
      })

      const currentLocale = computed(() => store.getters['config/lang'])
      const user = computed<User>(() => store.getters['auth/profile'])

      const languageSelected = ref(user.value.lang || currentLocale.value)

      const userProfilImage = computed(
        () => store.getters['organizations/usersProfilesImages'][user.value?.id]
      )

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )
      const account = ref({
        email: '',
        lastName: '',
        firstName: '',
        lang: currentLocale.value
      })

      const loadImage = async () => {
        await store.dispatch(`organizations/LOAD_USER_PROFILE_IMAGE`, {
          userId: user.value.id,
          companyId: currentCompany.value?.id
        })
      }
      const switchLanguage = (lang: string) => {
        languageSelected.value = lang
      }

      const fileChanged = (file: File) => {
        loadedFile.value = file
      }

      const updateProfileImage = () => {
        if (!loadedFile.value) return
        const form = new FormData()
        form.append('fileName', loadedFile.value?.name)
        form.append('file', loadedFile.value)
        store.dispatch('organizations/UPDATE_USER_PROFILE_IMAGE', {
          companyId: currentCompany.value?.id,
          userId: user.value.id,
          form,
          onProgress: () => {
            console.log()
          },
          onSuccess: () => {
            loadedFile.value = null
            store.commit('organizations/SET_USER_PROFILE_IMAGE', {
              userId: user.value.id,
              url: null
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message: t(`contactBook.form.notSaved`)
            })
          }
        })
      }

      const previewImage = (event: Event) => {
        const target = event.target as HTMLInputElement
        const file: File = (target.files as FileList)[0]

        loadedFile.value = file

        const reader = new FileReader()
        reader.onloadend = () => {
          pictureBase64.value = reader.result
          imgPreview.value = reader.result
        }

        reader.readAsDataURL(file)
      }

      const updateInformations = () => {
        // console.log(values, errors)
      }

      const removeImage = () => {
        pictureBase64.value = ''
        imgPreview.value = null
      }

      const updateProfile = (values: any) => {
        updateProfileImage()
        store.dispatch('auth/UPDATE_USER_PROFILE', {
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            lang: languageSelected.value
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.profileContext'),
              message: t(
                `account.accountInformations.apiResponse.successProfilEdition`
              )
            })
          },
          onError: () => {
            log('')
          }
        })
      }

      onMounted(() => {
        store.dispatch(`auth/GET_USER_PROFILE`, {})
      })

      watch(user, (val) => {
        if (val) {
          account.value = {
            lastName: val.lastName,
            firstName: val.firstName,
            email: val.email,
            lang: currentLocale.value
          }
        }
      })

      // watch(currentLocale, (locale) => {
      //   languageSelected.value = locale
      // })

      return {
        user,
        account,
        loading,
        schema,
        userProfilImage,
        languageSelected,
        previewImage,
        updateInformations,
        updateProfile,
        removeImage,
        fileChanged,
        switchLanguage
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/informations.scss';
</style>
