
  import { Document as DocumentElement } from '@/services/api/models/document'
  import { computed, defineComponent, onErrorCaptured, ref } from 'vue'
  import Document from './document.vue'
  import { Document as DocumentModel } from '@/services/api/models'
  import { Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  export default defineComponent({
    name: 'UplFolder',
    components: { Document, Button },
    props: {
      category: {
        type: String,
        required: true
      },

      data: {
        type: Object as () => Map<string, DocumentElement[]>,
        required: true
      },
      canOpen: {
        type: Boolean,
        required: false,
        default: false
      },
      isDateFormatted: {
        type: Boolean,
        required: false,
        default: false
      },
      count: {
        type: Number,
        required: false,
        default: 0
      }
    },
    emits: ['selectDocument', 'loadMore'],
    setup(props, { emit }) {
      const store = useStore()
      const open = ref(props.canOpen)
      const display = ref(false)
      const containsFiles = ref(props.data instanceof Array)
      const totalyCompleted = ref(false)
      const currentDocument = computed<DocumentModel | null>(
        () => store.getters['organizations/currentDocument']
      )

      const isFilesCompleted = computed<boolean>(() =>
        Array.from(props.data.values())
          .flat()
          .every((x: any) => !x.isDraft)
      )

      const dropFolder = computed(() => false)
      const isSelected = computed(() => false)
      const onSelect = (document: DocumentModel) => {
        emit('selectDocument', document)
      }
      const handleReloadDisplay = (value: boolean) => {
        display.value = value
      }

      const loadMore = (value: string) => {
        open.value = true
        emit('loadMore', value)
      }

      const childSelected = () => {
        console.log('called')
        open.value = true
      }

      onErrorCaptured(() => {
        console.warn('Error occured')
      })

      return {
        currentDocument,
        dropFolder,
        isSelected,
        open,
        totalyCompleted,
        containsFiles,
        display,
        isFilesCompleted,
        emit,
        onSelect,
        handleReloadDisplay,
        loadMore,
        childSelected
      }
    },
    computed: {}
  })
