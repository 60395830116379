<template>
  <div></div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { User } from '../services/api/models/user'
  import { getFormStorage } from '@/services/Storage'
  import { storageKeys } from '@/utils'
  import { Company } from '@/services/api/models'

  export default defineComponent({
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const redirect = () => {
        const cmpId: string = route.params.id?.toString()
        const company = cmpId || getFormStorage(storageKeys.currentCompany)

        if (company) buildCompany(company)
        else
          store.dispatch(`organizations/GET_LOADER_COMPANIES`, {
            filter: {},
            onSuccess: (data: Company[]) => {
              const [firstCmp] = data

              const companyId = company || firstCmp?.id

              buildCompany(companyId)
            },
            onError: () => {
              window.location.reload()
            }
          })
      }

      const buildCompany = (companyId: string) => {
        const key = getFormStorage(storageKeys.lastRoute)
        if (key?.toString().includes('portfolioView')) {
          return router.push({ name: key })
        }
        store.dispatch(`organizations/GET_SINGLE_COMPANY`, {
          companyId,
          onSuccess: (newComp: Company) => {
            store.commit(`organizations/SET_CONTEXT_COMPANY`, newComp)

            if (newComp?.id) {
              store.dispatch(`auth/GET_USER_PROFILE`, {
                onSuccess: (user: User) => {
                  const permissions = [
                    ...(user?.companiesAccounts || [])
                      .filter((x) => x.companyId === newComp.id)
                      ?.flatMap((cmp) =>
                        cmp.role.permissions.map((r) => r.slug)
                      ),
                    ...(user?.roles || []).flatMap((r) =>
                      r.permissions.map((p) => p.slug)
                    )
                  ]
                  if (permissions.includes('read:dashboard')) {
                    return router.push(`/company/${newComp.id}/dashboard`)
                  } else {
                    return router.push(`/company/${newComp.id}/directory`)
                  }
                },
                onError: () => {
                  window.location.reload()
                }
              })
              return router.push(`/company/${newComp.id}/directory`)
            } else return router.push('/account/informations')
          }
        })
      }
      onBeforeMount(() => {
        store.dispatch(`auth/GET_USER_PROFILE`, {})
      })
      onMounted(() => {
        redirect()
      })
      return {}
    }
  })
</script>
