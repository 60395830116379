
  import { Button } from '@up.law/uplaw-ui'
  import { computed, defineComponent, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    name: 'EventFormFooter',
    components: { Button },
    props: {
      onlySave: { type: Boolean, default: false },
      submitting: { type: Boolean, default: false },
      errors: {
        type: Object as () => Record<string, string>,
        required: true
      },
      meta: {
        type: Object as () => Record<string, boolean>,
        required: true
      }
    },
    emits: ['submit'],
    setup(props) {
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()

      const displayedErrors = computed(() =>
        Object.keys(props.errors)
          .filter((x, i) => i <= 3)
          .reduce((res: any, err) => ({ ...res, [err]: props.errors[err] }), {})
      )
      const buildErrorField = (key: string) => {
        let message = ''
        let line = 1
        const field = key.split('.')[key.split('.').length - 1]
        let data: string[] = key.split('[')
        if (data.length > 1) {
          line += parseInt(data[key.split('[').length - 1].charAt(0))
          message += `${t('global.line', { number: line })} - `
        }

        message += `${t(`errors.fields.${field}`)}`
        return message
      }

      const goBack = () => {
        router.push({
          name: 'company.corporateEvents',
          params: { id: route.params.id },
          query: { type: 'event' }
        })
      }

      return {
        displayedErrors,
        goBack,
        buildErrorField
      }
    }
  })
