
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    onBeforeMount,
    onMounted,
    watch
  } from 'vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { Field } from 'vee-validate'
  import { MAINACTIONS } from '@/types'
  import { OptionStockAlias, StockGroup } from '@/services/api/models'

  export default defineComponent({
    name: 'DropdownStocksByGroup',
    components: { Multiselect, Field, Tag },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: [String, Array]
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'top'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      canAddNew: { type: Boolean, default: true },
      optionPerPrice: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const { data } = toRefs(props)

      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'afterlist' : 'beforelist'
      )

      const formValue = ref('')
      const stock = ref(props.value)

      const searchText = ref('')
      const stockGroups = computed<StockGroup[]>(
        () => store.getters['organizations/stockGroups']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed<OptionStockAlias[]>(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )

      const isOption = (id: string) => {
        return (
          optionStockAliases.value.find((item: any) => item.id === id) !==
          undefined
        )
      }

      const isComposition = (id: string) => {
        return (
          compositionStockAlias.value.find((item: any) => item.id === id) !==
          undefined
        )
      }

      const aliases = computed<any[]>(() =>
        (stockGroups.value || []).map((x) => ({
          ...x,
          children: [
            ...(x.optionAliases?.map((o) => ({ ...o, name: o.alias })) || []),
            ...(x.stockAliases?.map((o) => ({ ...o, name: o.alias })) || []),
            ...(x.compositionAliases?.map((o) => ({ ...o, name: o.alias })) ||
              [])
          ]
        }))
      )

      const openModal = () => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
      }

      const updateValue = (selected: string[]) => {
        const stockAliasIds: any[] = []
        const optionAliasIds: any[] = []
        const compositionAliasIds: any[] = []

        selected.forEach((s) => {
          if (isComposition(s)) compositionAliasIds.push(s)
          else if (isOption(s)) optionAliasIds.push(s)
          else stockAliasIds.push(s)
        })

        emit('onUpdated', {
          ...data.value,
          value: selected,
          selected: { compositionAliasIds, optionAliasIds, stockAliasIds }
        })
      }

      const getGroups = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_GROUPS', {
          companyId: route.params.id,
          filter: { search }
        })
      }

      onMounted(async () => {
        await store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id
        })

        getGroups()
      })

      watch(
        () => props.value,
        (values) => {
          stock.value = values
        }
      )

      return {
        formValue,
        stock,
        searchText,
        addActionSlot,
        fieldName,
        isOption,
        aliases,
        openModal,
        updateValue
      }
    }
  })
