
  import { CompanyModel, Event } from '@/services/api/models'
  import { computed, defineComponent, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { useStore } from 'vuex'
  import { Document } from '@/services/api/models/document'
  import { ElPopconfirm, ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'EventDocumentTag',
    components: { ElPopconfirm, ElTooltip },
    props: {
      event: { type: Object as () => Event, default: null },
      document: { type: Object as () => Document, default: null },
      eventPart: { type: Object, default: null },
      hasSavedLink: { type: Boolean, default: false }
    },
    emits: ['fileDeleted'],
    setup(props, { emit }) {
      const { event } = toRefs(props)
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const removeFile = (docId?: string, contactId?: string) => {
        if (!docId || !contactId) return
        store.commit('organizations/SET_CURRENT_PART_CONTACT_ID', contactId)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', {
          files: [],
          remove: true,
          docId
        })
      }

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const handleDocumentView = async (doc?: Document) => {
        if (currentDocument?.value?.id === doc?.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc?.id
        })
      }
      const unlinkDocument = (partId: string, documentId?: string) => {
        if (!partId && documentId) {
          removeDocument(documentId)
          return
        }
        store.dispatch('organizations/DETACH_DOCUMENTS_TO_EVENT_PART', {
          type: event.value?.type,
          eventTypeId:
            event.value?.optionExercise?.id ||
            event.value?.optionIssuance?.id ||
            event.value?.stockIssuance?.id ||
            event.value?.compositionIssuance?.id,
          partId,
          companyId: route.params.id,
          eventId: route.params.eventId,
          documentId,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            emit('fileDeleted', documentId)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      const removeDocument = (docId: string) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: currentCompany.value.id,
          eventId: event.value?.id,
          documentId: docId,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            emit('fileDeleted', docId)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      return {
        currentDocument,
        removeDocument,
        unlinkDocument,
        removeFile,
        handleDocumentView
      }
    }
  })
