<template>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 w-1/4">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.accelerationDate') }}
      </h1>
      <p v-if="event.contacts?.length" class="text-blackp text-sm text-left">
        <ContactBadge
          v-for="contact in event.contacts"
          :key="contact.id"
          class="p-1 my-3 m-0 border border-secondary5 rounded"
          :contact="contact"
          :details="false"
          type="light"
        />
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-r border-secondary6 w-1/4">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.options') }}
      </h1>
      <div
        v-if="event.optionAlias?.length"
        class="text-blackp text-sm text-left"
      >
        <Tag
          v-for="option in event.optionAlias"
          :key="option.id"
          :text="option.alias.toUpperCase()"
          :value="option.id"
          :with-initials="false"
          :with-remove="false"
        />
      </div>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>

    <div class="p-3 border-secondary6 w-1/4">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.accelerationType') }}
      </h1>
      <p v-if="event.adjustmentType" class="text-blackp text-sm text-left">
        {{ $t(`VestingAdjustment.${event.adjustmentType}`) }}
      </p>

      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-l border-secondary6 w-1/4">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.accelerationPercent') }}
      </h1>
      <p v-if="event.percent" class="text-blackp text-sm text-left">
        {{ event.percent }} %
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { VestingAcceleration } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  import { Tag } from '@up.law/uplaw-ui'
  export default defineComponent({
    name: 'VestingCard',
    components: { ContactBadge, Tag },
    props: {
      event: {
        type: Object as () => VestingAcceleration | undefined,
        required: true
      }
    },
    setup() {
      return { moment }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
  }
</style>
