<template>
  <div class="w-full mb-5 flex flex-col delegations">
    <div class="flex justify-between mt-5">
      <div class="flex md:w-4/5 lg:w-3/5 xl:w-3/5">
        <div class="w-1/5 mr-2">
          <Field v-slot="{ field }" v-model="search" name="search">
            <Input
              v-model="search"
              name="search"
              v-bind="field"
              :placeholder="$t('global.placeholder.search')"
            >
              <template #left>
                <i class="bi bi-search"></i>
              </template>
            </Input>
          </Field>
        </div>
        <div class="filter w-1/5 xl:w-1/4 lg:w-1/4 mr-2">
          <DatePicker
            v-model="picked"
            class="rounded text-xs h-10"
            placeholder="--/--/----"
          />
        </div>
        <div class="w-1/4 mr-2">
          <DropdownCeilings
            v-model="commonceiling"
            :value="commonceiling"
            :has-label="false"
            name="commonCeiling"
            open-direction="bottom"
            @on-updated="commonceiling = $event.value"
          >
          </DropdownCeilings>
        </div>
        <div class="w-1/4 mr-2">
          <Multiselect
            v-model="status"
            :value="status"
            :options="statusChoices"
            name="commonCeiling"
            open-direction="bottom"
            :placeholder="$t('global.placeholder.status')"
            @change="status = $event"
          >
          </Multiselect>
        </div>
      </div>
      <div class="flex h-10">
        <DownloadButton
          v-if="!isDownloading"
          class="mr-2"
          :on-click="download"
        ></DownloadButton>

        <el-tooltip
          v-else-if="isDownloading"
          class="box-item"
          effect="dark"
          :content="$t('tooltips.downloading')"
          placement="top"
        >
          <div
            class="
              border
              rounded-3
              cursor-not-allowed
              bg-white
              border-secondary6
              px-3
              flex
              items-center
            "
          >
            <i
              class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"
            ></i>
          </div>
        </el-tooltip>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.resetFilter')"
          placement="top"
          ><Button
            variant="custom"
            class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
            @click="resetFilters"
          >
            <i class="bi bi-filter text-2xl"></i> </Button
        ></el-tooltip>
      </div>
    </div>

    <div class="delegations text-sm mt-2">
      <DataTable
        v-if="delegations.length"
        :has-checkbox="false"
        :columns="columns"
        :rows="delegations"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #createdAt="{ row }">
          <p class="text-left">{{ moment.utc(row.date).format('L') }}</p>
        </template>
        <template #stock="{ row }">
          <p v-if="row.stockCategory">{{ row.stockCategory.name }}</p>
          <p v-else-if="row.optionCategory">{{ row.optionCategory.name }}</p>
          <p v-else>-</p>
        </template>
        <template #ceiling="{ row }">
          <p v-if="row.commonCeiling" class="text-left">
            {{ row.commonCeiling?.label }}
            <strong>({{ formatNumber(row.commonCeiling.quantity) }})</strong>
          </p>
          <p v-else class="text-left">-</p>
        </template>

        <template #delQuantity="{ row }">
          <p v-if="row.quantity" class="text-left">
            {{ formatNumber(row.quantity) }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #quantity="{ row }">
          <p class="text-left">
            {{ formatNumber(row.usedQuantity) }}
          </p>
        </template>
        <template #balance="{ row }">
          <p :class="`text-left ${row.balance < 0 ? 'text-error' : ''}`">
            {{ formatNumber(row.balance) }}
          </p>
        </template>
        <template #endDate="{ row }">
          <p class="text-left">{{ moment.utc(row.endDate).format('L') }}</p>
        </template>

        <template #status="{ row }">
          <p
            v-if="
              moment
                .utc(picked || moment.now())
                .endOf('day')
                .isBefore(moment.utc(row.endDate))
            "
            class="text-left text-success"
          >
            {{ $t('events.elements.pending') }}
          </p>
          <p v-else class="text-left text-error">
            {{ $t('events.elements.expired') }}
          </p>
        </template>
        <template #eventName="{ row }">
          <div class="flex justify-end">
            <div
              class="
                text-left
                cursor-pointer
                bg-primary1
                rounded
                w-8
                h-8
                flex
                justify-center
                items-center
                hover:bg-primary2
              "
              @click="gotoEvent(row.event.id)"
            >
              <i class="bi bi-lightning text-xl text-primary4"></i>
            </div>
          </div>
        </template>
      </DataTable>
      <div v-else class="flex justify-center items-center w-full mt-4">
        <EmptyState
          class="d-block mx-auto datatable"
          :title="$t('global.noResult')"
          :subtitle="$t('global.selectFilters')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { delegationColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import DropdownCeilings from '@/components/Forms/DropDowns/DropdownCeilings.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import { ElTooltip } from 'element-plus'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { DelegationStatus } from '@/types/event'
  export default defineComponent({
    components: {
      Field,
      Input,
      DataTable,
      DownloadButton,
      DropdownCeilings,
      DatePicker,
      Button,
      ElTooltip,
      EmptyState,
      Multiselect
    },
    setup() {
      const search = ref('')
      const status = ref('')
      const commonceiling = ref('')
      const store = useStore()
      const picked = ref('')
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const columns = computed(() => delegationColumns)
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )
      const isDownloading = ref(false)

      const statusChoices = computed(() =>
        Object.values(DelegationStatus).map((x) => ({
          id: x,
          name: t(`DelegationStatus.${x}`)
        }))
      )
      const ceilings = computed(() => store.getters['organizations/ceilings'])
      const pagination = computed(
        () => store.getters['organizations/delegationsPagination']
      )

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', page)
        getDelegations()
      }
      const getDelegations = (companyId = route.params.id) => {
        const filter: any = {}
        if (search.value) filter.search = search.value
        if (commonceiling.value) filter.commonCeilingId = commonceiling.value
        if (picked.value) filter.usableAtDate = picked.value
        if (status.value) filter.status = status.value

        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId,
          filter
        })
      }

      const getCeilings = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_CEILINGS', {
          companyId
        })
      }

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }

      const resetFilters = () => {
        picked.value = ''
        commonceiling.value = ''
        search.value = ''
        status.value = ''
      }

      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_DELEGATIONS', {
          name: `${t('exports.delegations')}`,
          companyId,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      onMounted(() => {
        getCeilings()

        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
        getDelegations()
      })

      watch(
        () => route.params.id,
        (val) => {
          store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
          getDelegations(val)
        }
      )

      watch(
        [
          () => commonceiling.value,
          () => search.value,
          () => picked.value,
          () => status.value
        ],
        () => {
          store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
          getDelegations()
        }
      )
      return {
        search,
        delegations,
        columns,
        moment,
        commonceiling,
        pagination,
        ceilings,
        picked,
        statusChoices,
        status,
        isDownloading,
        gotoEvent,
        resetFilters,
        download,
        formatNumber,
        onPageChange
      }
    }
  })
</script>
<style lang="scss">
  .delegations {
    .button-filter {
      @apply h-full flex justify-center items-center border border-inputBorder rounded-sm hover:bg-primary5 hover:text-white;
      &:hover img {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
      }
    }
  }
</style>
