import { App, Plugin } from 'vue'

const devtools: Plugin = {
  install(app: App) {
    console.log(
      '__VUE_DEVTOOLS_GLOBAL_HOOK__',
      '__VUE_DEVTOOLS_GLOBAL_HOOK__' in window
    )

    if (
      process.env.NODE_ENV === 'development' &&
      '__VUE_DEVTOOLS_GLOBAL_HOOK__' in window
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app
    }
  }
}

export { devtools }
