
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { defineComponent, ref, toRefs } from 'vue'
  interface DataInputDD {
    name: string
    type: string
    label: string
    fieldId: string
    hint?: string
  }

  export default defineComponent({
    name: 'DropdownGeneric',
    components: { Multiselect, Field },
    inheritAttrs: false,
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputDD,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      value: {
        type: [String, Number, Array],
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: 'mt-4'
      },
      group: {
        default: false,
        type: Boolean
      },
      groupBy: {
        type: String,
        required: false,
        default: ''
      },
      choices: {
        default: () => [],
        type: Array
      },
      hasLabel: {
        default: true,
        type: Boolean
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref(value.value ?? '')

      const updateValue = (value: any) => {
        formValue.value = value

        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }
      return { formValue, updateValue }
    }
  })
