<template>
  <div class="filters w-full">
    <div class="flex items-end upl-treeview__body w-full">
      <div class="w-2/5 upl-treeview__search relative">
        <label class="text-left font-semibold text-secondary9 text-md" for="">{{
          $t('global.placeholder.textSearch')
        }}</label>
        <Field
          v-slot="{ field }"
          v-model="filtersData.search"
          name="search"
          class="relative"
        >
          <Input
            v-model="filtersData.search"
            v-bind="field"
            class="h-10 corporate-search"
            type="search"
            :no-error="true"
            name="search"
            :disabled="false"
            :placeholder="$t('global.placeholder.search')"
            @input="updateFilters($event, 'document', 'search')"
          />
        </Field>
        <div class="relative">
          <div
            v-if="filtersData.search"
            v-click-away="hideSearchResults"
            style="z-index: 999"
            class="upl-treeview__search__hints flex z-50 bg-white"
          >
            <SearchResult
              :filter="filtersData.search"
              :data="searchResults"
              @close="initSearch"
            />
          </div>
        </div>
      </div>
      <div class="w-2/5 ml-2">
        <label class="text-left font-semibold text-secondary9" for="">{{
          $t('global.placeholder.period')
        }}</label>
        <Datepicker
          v-model="picked"
          range
          class="rounded h-10 text-xs mr-2"
          placeholder="--/--/----"
        />
      </div>

      <div class="w-1/5">
        <div class="flex flex-col w-full">
          <label class="text-left font-semibold text-secondary9" for="">{{
            $t('global.placeholder.draft')
          }}</label>
          <multiselect
            v-model="draft"
            :value="draft"
            mode="multiple"
            class="min-h-full"
            open-direction="bottom"
            :tag-with-initials="false"
            :options="status"
            :groups="false"
            :placeholder="$t('events.draftStatus.placeholder')"
            @change="updateFilters($event, 'draft')"
          />
        </div>
      </div>

      <div class="h-10 flex justify-end w-1/4">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.resetFilter')"
          placement="left"
        >
          <Button
            variant="tertiary"
            class="button-filter"
            @click="resetFilters"
          >
            <i class="bi bi-filter text-2xl"></i> </Button
        ></el-tooltip>
      </div>
    </div>
    <div class="flex mt-4">
      <div class="w-1/4 mr-2">
        <div class="flex flex-col w-full">
          <label class="text-left font-semibold text-secondary9" for="">{{
            $t('global.placeholder.documentType')
          }}</label>
          <multiselect
            v-model="categories"
            :value="categories"
            mode="multiple"
            class="min-h-full"
            open-direction="bottom"
            :tag-with-initials="false"
            :options="documentCategories"
            elementName="documents.categories"
            selectionText="global.actions.selected2"
            :groups="true"
            group-options-field="docSubCategories"
            :placeholder="$t('documents.types')"
            @change="updateFilters($event, 'documentSubCategoriesId')"
          />
        </div>
      </div>
      <div class="w-1/4 mr-2">
        <div class="flex flex-col w-full">
          <label class="text-left font-semibold text-secondary9" for="">{{
            $t('events.elements.eventTypes')
          }}</label>
          <Multiselect
            :value="eventTypes"
            :show-labels="true"
            mode="multiple"
            :tag-with-initials="false"
            :options="eventCategories"
            group-options-field="group"
            elementName="events.events"
            selectionText="global.actions.selected1"
            :placeholder="$t('global.placeholder.eventTypes')"
            :groups="true"
            class="w-full h-full"
            @change="($event) => updateFilters($event, 'eventTypes')"
          >
          </Multiselect>
        </div>
      </div>
      <div class="w-1/4 mr-2">
        <div class="flex flex-col w-full">
          <label class="text-left font-semibold text-secondary9" for="">{{
            $t('global.placeholder.contacts')
          }}</label>
          <dropdown-contacts
            v-model="contactsId"
            :value="contactsId"
            :elements="contacts"
            :filterResults="true"
            mode="multiple"
            class="h-full"
            open-direction="bottom"
            :can-add-new="false"
            @search-change="handleContactSearch"
            @update:modelValue="updateFilters($event, 'contactsId')"
          />
        </div>
      </div>
      <div class="w-1/4">
        <div class="flex flex-col w-full">
          <label class="text-left font-semibold text-secondary9" for="">{{
            $t('global.placeholder.companies')
          }}</label>

          <dropdown-company
            v-model="companyIds"
            :value="companyIds"
            :has-label="false"
            :has-defaut="true"
            mode="multiple"
            @on-updated="updateFilters($event, 'companyIds')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Datepicker from '@/components/DatePicker/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { eventCategoriesList } from '@/utils/data'
  import moment from 'moment'
  import { Field } from 'vee-validate'
  import SearchResult from '@/components/DocumentSearchResult/index.vue'
  import { ElTooltip } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'FiltersCorporate',
    components: {
      Datepicker,
      SearchResult,
      DropdownContacts,
      Multiselect,
      DropdownCompany,
      Input,
      ElTooltip,
      Button,
      Field
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object
      },
      type: {
        type: String,
        required: true
      }
    },
    emits: ['update-filters'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const search = ref('')
      const { t } = useI18n()
      const resultPopover = ref(null)
      const { filters } = toRefs(props)
      const filtersData = ref<any>(filters.value)
      const contactsId = ref([])
      const draft = ref([])
      const categories = ref([])
      const stockIds = ref<string[]>([])
      const aliasesId = ref<string[]>([])
      const companyIds = ref<string[]>([])
      const eventTypes = ref([])
      const operationsId = ref([])
      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])
      const picked = ref([])
      const status = computed(() => [
        { id: true, name: t('events.draftStatus.isDraft') },
        { id: false, name: t('events.draftStatus.validated') }
      ])
      const allContacts = computed(() => store.getters['portfolio/contacts'])
      const contacts = computed(() =>
        allContacts.value.reduce((acc: any[], c: any) => {
          if (!c?.internalId1) acc.push(c)
          else if (
            c?.internalId1 &&
            !acc.map((x: any) => x.internalId1).includes(c.internalId1)
          )
            acc.push(c)
          else {
            const already = acc.find((y) => y.internalId1 === c.internalId1)

            if (already) already.id = `${already.id}@_${c.id}`
          }

          return acc
        }, [])
      )
      const organs = computed(() => store.getters['organizations/committees'])
      const eventCategories = ref(eventCategoriesList)
      const documents = computed(
        () => store.getters['organizations/corporateDocuments']
      )
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const operations = computed(
        () => store.getters['organizations/operations']
      )
      const resetFilters = () => {
        picked.value = []

        filtersData.value = {
          endDate: moment().format('YYYY-MM-DD'),
          startDate: '1970-01-01'
        }
        aliasesId.value = []
        stockIds.value = []
        contactsId.value = []
        categories.value = []
        eventTypes.value = []
        operationsId.value = []
        getContacts()
        emit('update-filters', filtersData.value, props.type)
      }

      const updateFilters = (e: any, filter: string, field = '') => {
        filtersData.value[filter] = e
        const data = { ...filtersData.value }
        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft

        emit('update-filters', data, props.type, field)

        if (filter == 'companyIds') {
          getContacts()
        }
      }

      watch(picked, (newDateRange) => {
        let dateFilter: any = {
          endDate: moment.utc().format('YYYY-MM-DD'),
          startDate: '1970-01-01'
        }
        if (newDateRange && newDateRange.length > 0) {
          dateFilter = {
            startDate:
              newDateRange && newDateRange[0]
                ? moment.utc(newDateRange[0]).startOf('day').toISOString()
                : '1970-01-01',
            endDate:
              newDateRange && newDateRange[1]
                ? moment.utc(newDateRange[1]).endOf('day').toISOString()
                : moment().format('YYYY-MM-DD')
          }
        }
        filtersData.value = { ...filtersData.value, ...dateFilter }
        emit('update-filters', filtersData.value, props.type)
      })

      const initSearch = () => {
        filtersData.value.search = null
      }
      const hideSearchResults = () => {
        filtersData.value.search = null
      }

      const handleContactSearch = (e: string) => {
        getContacts(e)
      }

      const getContacts = (search = '') => {
        let data: any = {
          companyIds: filtersData.value['companyIds'],
          limit: 20,
          offset: 0
        }

        if (search) data = { ...data, name: search }
        else delete data.name
        store.dispatch('portfolio/GET_CONTACTS', {
          filter: data
        })
      }

      onMounted(() => {
        getContacts()
      })

      return {
        search,
        eventTypes,
        status,
        draft,
        documentCategories,
        eventCategories,
        categories,
        contacts,
        contactsId,
        organs,
        documents,
        stockIds,
        aliasesId,
        picked,
        companyIds,
        filtersData,
        updateFilters,
        resetFilters,
        hideSearchResults,
        handleContactSearch,
        searchResults,
        resultPopover,
        initSearch,
        operationsId,
        operations
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import './style.scss';
</style>
