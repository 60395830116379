
  import {
    CompanyModel,
    FundRaisingRound,
    Operation
  } from '@/services/api/models'
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()

      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-operations'))
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const operation = computed<Operation | null>(
        () => store.getters['organizations/operation']
      )

      const getOperations = (search = '') => {
        store.dispatch('organizations/GET_OPERATIONS', {
          companyId: currentCompany.value.id,
          filter: { search }
        })
      }

      const operations = computed<Operation[]>(
        () => store.getters['organizations/operations']
      )

      const initForm = () => {
        store.commit('organizations/SET_OPERATION', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (operationId: string) => {
        store.dispatch('organizations/GET_OPERATION', {
          companyId: currentCompany.value.id,
          id: operationId
        })
      }

      onMounted(() => {
        getOperations()
      })

      watch(search, (text: string) => {
        getOperations(text)
      })

      return { canDo, operation, search, operations, initForm, handleSelect }
    }
  })
