import { company } from '../endpoints'
import axios from '../axios'
import { OperationFormData } from '@/types/forms'

const getOperations = async (companyId: string, filter = {}) => {
  const response = await axios.get(company.operation(companyId), {
    params: filter
  })
  return response
}

const getOperation = async (companyId: string, planId = '') => {
  const response = await axios.get(company.singleOperation(companyId, planId))
  return response
}

const deleteOperation = async (companyId: string, planId = '') => {
  const response = await axios.delete(
    company.singleOperation(companyId, planId)
  )
  return response
}

const createOperation = async (companyId: string, form: OperationFormData) => {
  const { data } = await axios.post(company.operation(companyId), form)
  return data
}

const editOperation = async (
  companyId: string,
  id: string,
  form: OperationFormData
) => {
  const { data } = await axios.put(company.singleOperation(companyId, id), form)
  return data
}

export {
  editOperation,
  createOperation,
  deleteOperation,
  getOperation,
  getOperations
}
