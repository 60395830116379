<template>
  <div class="team-wrapper container-fluid px-4 py-5 w-full">
    <div class="w-full">
      <h1 class="text-left text-secondary9">
        {{ $t('account.accountTeam.baseline') }}
      </h1>
      <section class="w-full">
        <div class="row flex grid gap-4 grid-cols-3">
          <div id="form_immatriculation" class="col-lg-4 form-group w-full">
            <label class="text-left">{{
              $t('account.accountTeam.informations.form.immatriculation.label')
            }}</label>
            <UplInput
              v-model="teamImmatriculation"
              autocomplete="off"
              class="mw-100 w-full"
              type="number"
              :placeholder="
                $t(
                  'account.accountTeam.informations.form.immatriculation.placeholder'
                )
              "
            />
          </div>

          <div id="form_denomination" class="col-lg-4 form-group w-full">
            <label class="text-left">{{
              $t('account.accountTeam.informations.form.denomination.label')
            }}</label>
            <UplInput
              v-model="teamName"
              autocomplete="off"
              class="mw-100 w-100"
              type="text"
              :placeholder="
                $t(
                  'account.accountTeam.informations.form.denomination.placeholder'
                )
              "
            />
          </div>

          <div id="form_commercialName" class="col-lg-4 form-group w-full">
            <label class="text-left">{{
              $t('account.accountTeam.informations.form.commercialName.label')
            }}</label>
            <UplInput
              v-model="teamCommercialName"
              autocomplete="off"
              class="mw-100 w-100"
              type="text"
              :placeholder="
                $t(
                  'account.accountTeam.informations.form.commercialName.placeholder'
                )
              "
            />
          </div>
          <div id="form_address" class="col-lg-6 form-group">
            <label class="text-left">{{
              $t('account.accountTeam.informations.form.address.label')
            }}</label>
            <UplInput
              v-model="teamAddress"
              autocomplete="off"
              class="mw-100 w-100"
              type="text"
              :placeholder="
                $t('account.accountTeam.informations.form.address.placeholder')
              "
            />
          </div>
          <div id="form_postalCode" class="col-lg-3 form-group">
            <label class="text-left">{{
              $t('account.accountTeam.informations.form.postalCode.label')
            }}</label>
            <UplInput
              v-model="teamPostalCode"
              autocomplete="off"
              class="mw-100 w-100"
              type="text"
              :placeholder="
                $t(
                  'account.accountTeam.informations.form.postalCode.placeholder'
                )
              "
            />
          </div>
          <div id="form_city" class="col-lg-3 form-group">
            <label class="text-left">{{
              $t('account.accountTeam.informations.form.city.label')
            }}</label>
            <UplInput
              v-model="teamCity"
              autocomplete="off"
              class="mw-100 w-100"
              type="text"
              :placeholder="
                $t('account.accountTeam.informations.form.city.placeholder')
              "
            />
          </div>
        </div>

        <div class="mt-7 w-100 flex justify-end items-center">
          <Btn
            size="medium"
            variant="tertiary"
            class="secondary mr-2"
            name="fo_accountTeam_cancel"
            :label="$t('account.accountTeam.informations.form.cancel')"
          />
          <Btn
            size="medium"
            variant="primary"
            name="fo_accountTeam_update"
            :label="$t('account.accountTeam.informations.form.submit')"
            @action="updateTeamInfos()"
          />
        </div>
      </section>
    </div>
    <hr />

    <div class="teamUsers">
      <h2 class="text-left">
        {{ $t('account.accountTeam.teamUser.baseline') }}
      </h2>
    </div>

    <div>
      <el-table
        ref="multipleTable"
        border
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="Date" width="120">
          <template #default="scope">{{ scope.row.date }}</template>
        </el-table-column>
        <el-table-column property="name" label="Nom" width="120">
        </el-table-column>
        <el-table-column
          property="address"
          label="Adresse"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column fixed="right" label="" width="100">
          <template #default class="flex items-center justify-center">
            <Btn
              variant="custom"
              size="large"
              class="text-error"
              icon="el-icon-trash"
            >
              <i class="las la-trash"></i>
            </Btn>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-5 flex items-end justify-end">
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </div>

    <div class="mt-7">
      <h2 class="text-left">
        {{ $t('account.accountTeam.teamUser.newInvitation') }}
      </h2>

      <div class="mt-5 flex items-center justify-between w-full">
        <div class="flex items-center justify-start">
          <span class="mr-2">Ajouter</span>
          <el-input-number
            v-model="countInvitation"
            class="mr-2"
            :min="0"
          ></el-input-number>
          <span class="mr-2">invitations</span>
          <Btn
            size="medium"
            variant="secondary"
            :disabled="countInvitation <= 0"
            label="Add"
            @click="confirmList"
          >
            <i class="las la-plus"></i>
          </Btn>
        </div>
        <div>
          <Btn
            size="medium"
            variant="primary"
            :disabled="!canSendInvitations"
            label="Send invitations"
            icon="el-icon-trash"
            @click="confirmList"
          >
            <i class="bi-plus"></i>
          </Btn>
        </div>
      </div>

      <div v-if="countInvitation > 0" class="">
        <InvitationForm
          v-for="(invitee, index) in invitees"
          :key="index"
          :index="index"
          :handle-change="updateInviteeList"
          :handle-remove="removeInvitation"
        />
      </div>
    </div>

    <template v-if="unsentInvites && unsentInvites.length">
      <div
        v-for="(invite, i) in unsentInvites"
        :key="i"
        class="user__row"
        :class="{ error: invite.error }"
      >
        <div class="row_item mail">
          <i class="bi-person-plus" />
        </div>
        <div class="row__item w-100">
          <input
            v-model="invite.email"
            type="email"
            :placeholder="
              $t('account.accountTeam.teamUser.addUser.form.email.placeholder')
            "
          />
        </div>
        <div class="row__item w-100">
          <input
            v-model="invite.firstname"
            type="text"
            :placeholder="
              $t(
                'account.accountTeam.teamUser.addUser.form.firstname.placeholder'
              )
            "
          />
        </div>
        <div class="row__item w-100">
          <input
            v-model="invite.lastname"
            type="text"
            :placeholder="
              $t(
                'account.accountTeam.teamUser.addUser.form.lastname.placeholder'
              )
            "
          />
        </div>
        <div class="row__item w-100">
          <multiselect
            v-model="invite.profileAlias"
            class="tableselect w-100"
            :show-labels="false"
            :options="teamProfiles"
            label="name"
            track-by="uuid"
            :placeholder="
              $t('account.accountTeam.teamUser.addUser.form.profileAlias.label')
            "
          />
        </div>
        <div class="row__item w-100">
          <Multiselect
            class="tableselect w-100"
            :show-labels="false"
            :options="companyProfiles"
            label="name"
            track-by="uuid"
          />
        </div>
        <div class="'row__item invite_row_button">
          <span class="bi-trash md danger" @click="removeInvitation(i)" />
        </div>
      </div>
    </template>

    <div class="mt-2">
      <Btn
        v-if="unsentInvites && unsentInvites.length"
        class="companyUsers-modal-button float-right"
        icon="bi-plus"
        :label="$t('account.accountTeam.teamUser.addUser.label_3')"
        @action="handleSendInvitesClick"
      />
      <!-- <AddRows v-model="inviteRowsNumber" @add="handleInviteClick">
        <template #suffix="{ value }">
          <p>invitation<template v-if="value > 1"> s </template></p>
        </template>
      </AddRows> -->
    </div>
  </div>
</template>

<script lang="ts">
  import Multiselect from '@vueform/multiselect'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { UplTheme } from '@up.law/uplaw-ui'
  import InvitationForm from '@/components/Forms/Account/invitationForm.vue'
  import { computed, defineComponent, ref } from 'vue'
  import { UserInvitationFields } from '@/types/forms'
  import {
    ElTable,
    ElTableColumn,
    ElPagination,
    ElInputNumber
  } from 'element-plus'

  export default defineComponent({
    name: 'AccountTeam',
    components: {
      Btn: Button,
      Multiselect,
      UplInput: Input,
      InvitationForm,
      ElTable,
      ElTableColumn,
      ElPagination,
      ElInputNumber
    },
    setup() {
      const countInvitation = ref(0)
      console.log(UplTheme)

      const invitees = ref<UserInvitationFields[]>([])

      const canSendInvitations = computed(
        (): boolean =>
          invitees.value.length > 0 &&
          !invitees.value
            .map((x) => !Object.values(x).includes(''))
            .includes(false)
      )

      return { countInvitation, invitees, canSendInvitations }
    },
    data() {
      return {
        UplTheme,
        invitationCount: 0,
        tableData: [
          {
            date: '2016-05-03',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-02',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-04',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-01',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-08',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-06',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          },
          {
            date: '2016-05-07',
            name: 'Tom',
            address: 'No. 189, Grove St, Los Angeles'
          }
        ],
        multipleSelection: [],
        teamImmatriculation: null,
        teamName: null,
        teamCommercialName: null,
        teamAddress: null,
        teamPostalCode: null,
        teamCity: null,
        invites: [],
        inviteRowsNumber: 1,
        teamProfiles: null,
        companyProfiles: null,
        teamUsers: null
      }
    },
    computed: {},
    mounted() {
      // this.teamImmatriculation = this.me.team.immatriculation
      // this.teamName = this.me.team.name
      // this.teamCommercialName = this.me.team.commercialName
      // this.teamAddress = this.me.team.address
      // this.teamPostalCode = this.me.team.postalCode
      // this.teamCity = this.me.team.city
      // this.getProfilesAlias()
      // this.getTeamUsers(this.me.team.uuid)
    },
    methods: {
      confirmList() {
        this.invitationCount = this.countInvitation

        this.invitees = []
        for (let index = 0; index < this.countInvitation; index++) {
          this.invitees.push({
            lastName: '',
            firstName: '',
            email: '',
            profileAlias: '',
            inheritProfileAlias: ''
          })
        }
      },
      updateInviteeList(i: number, value: UserInvitationFields) {
        this.invitees[i] = value
      },
      removeInvitation(index: number) {
        this.invitees = this.invitees.filter((x, i) => i !== index)
        this.countInvitation = this.invitees.length
      },

      sendInvitations() {
        // const newInvites = Array.from(
        //   { length: this.inviteRowsNumber },
        //   () => ({
        //     email: null,
        //     firstname: null,
        //     lastname: null,
        //     profileAlias: null,
        //     inheritProfileAlias: null,
        //     isActive: true,
        //     success: false,
        //   })
        // )
        // this.invites = [].concat(this.invites, newInvites)
      },
      handleRemoveInviteClick(i: number) {
        // this.$delete(this.invites, i)
      },
      async handleSendInvitesClick() {
        // await Promise.all(
        //   this.invites.map(async (invite, i) => {
        //     if (invite.success) {
        //       return
        //     }
        //     try {
        //       await this.inviteUserToTeam(invite)
        //       this.$set(this.invites, i, {
        //         ...invite,
        //         success: true,
        //         error: false,
        //       })
        //     } catch (e) {
        //       this.$set(this.invites, i, {
        //         ...invite,
        //         success: false,
        //         error: true,
        //       })
        //     }
        //   })
        // )
        // this.getTeamUsers(this.me.team.uuid)
      },
      // inviteUserToTeam(inv) {
      //   const invite = inv
      //   invite.inheritMode = !!invite.inheritProfileAlias

      //   const data = {
      //     teamUuid: this.me.team.uuid,
      //     invitation: invite,
      //   }

      //   if (invite.profileAlias) {
      //     data.invitation.profileAliasId = invite.profileAlias.uuid
      //     delete data.invitation.profileAlias
      //   }

      //   if (invite.inheritProfileAlias) {
      //     data.invitation.inheritProfileAliasId =
      //       invite.inheritProfileAlias.uuid
      //     delete data.invitation.inheritProfileAlias
      //   }
      //   // return apiMe.inviteUserToTeam(data)
      // },
      getTeamUsers(teamUuid: string) {
        // apiMe.getTeamUsers(teamUuid, (response) => {
        //   this.teamUsers = response.data.rows
        // })
      },
      getProfilesAlias() {
        // apiMe.getProfileAlias((response) => {
        //   const { data } = response
        //   this.teamProfiles = data.filter(
        //     (profile) => profile.category === 'TEAM'
        //   )
        //   this.companyProfiles = data.filter(
        //     (profile) => profile.category === 'COMPANY'
        //   )
        // })
      },
      removeTeamUser(uuid: string) {
        const data = {
          // teamUuid: this.me.team.uuid,
          userUuid: uuid
        }
        // apiMe.removeTeamUser(data, (reponse) => {
        //   this.getTeamUsers(this.me.team.uuid)
        // })
      },
      updateTeamInfos() {
        // const data = {
        //   teamUuid: this.me.team.uuid,
        //   infos: {
        //     ...this.me.team,
        //     immatriculation: this.teamImmatriculation,
        //     name: this.teamName,
        //     commercialName: this.teamCommercialName,
        //     address: this.teamAddress,
        //     postalCode: this.teamPostalCode,
        //     city: this.teamCity,
        //   },
        // }
        // apiMe.updateTeamInfos(data, (response) => {
        //   this.$store.dispatch('me/updateMe')
        // })
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/team.scss';
</style>
