<template>
  <div class="transition transition-all">
    <div class="flex flex-col justify-start my-3">
      <div class="flex items-center justify-between">
        <div class="flex w-1/2 h-10">
          <Field
            v-slot="{ field }"
            v-model="optionId"
            name="optionId"
            class="w-1/3"
            as="div"
          >
            <Multiselect
              id="optionId"
              v-bind="field"
              v-model="optionId"
              :value="optionId"
              open-direction="bottom"
              mode="single"
              name="optionId"
              :options="options"
              :placeholder="$t('vestingBook.form.option')"
              :groups="false"
              @change="optionId = $event"
            />
          </Field>
        </div>
      </div>

      <div>
        <div
          v-if="selectedOption?.vestingCalendar?.hasObjective"
          class="
            flex
            items-center
            justify-start
            bg-warning bg-opacity-25
            text-warning
            my-5
            text-lg
            rounded-md
            px-2
            py-3
          "
        >
          <i
            class="
              bi bi-info
              flex
              justify-center
              items-center
              text-white
              mx-3
              w-5
              text-lg
              h-5
              rounded-full
              bg-warning
            "
          >
          </i>
          <h3 class="text-warning font-medium">
            {{ $t('vestingBook.labels.hasObjectiveLabel') }}
          </h3>
        </div>
        <h3 class="text-left text-blackp mt-4 mb-3">
          {{
            $t(`global.${mode === 'list' ? 'vestingSchedule' : 'vestingGraph'}`)
          }}
        </h3>

        <div v-if="mode === 'list'" class="exercise-table">
          <div
            v-if="isLoading"
            class="flex w-full h-full justify-center items-center relative"
          >
            <Loader />
          </div>
          <data-table
            v-else-if="(period?.exercisePeriods ?? []).length > 0"
            index-key="id"
            :columns="columns"
            :rows="period?.exercisePeriods ?? []"
            :custom-data="true"
          >
            <template #body>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in exercisePeriods ?? []"
                  :key="rowIndex"
                  :class="`
                ${
                  row.isLastVestedPeriod
                    ? 'bg-current'
                    : row.isVestedPeriod
                    ? 'bg-active'
                    : ''
                }
          hover:bg-secondary2 hover:text-secondary9
          transition-transform transition-colors`"
                >
                  <td class="data-table-cell-cb text-left">
                    {{ row.vestingPeriod.order }}
                  </td>
                  <td class="data-table-cell-cb text-left">
                    {{ moment.utc(row.endDate).format($t('dateFormat')) }}
                  </td>
                  <td class="data-table-cell-cb text-left">
                    {{ formatNumber(row.vestingPeriod.value) }}
                    {{ vestingType === 'PERCENT' ? '%' : '' }}
                  </td>
                  <td class="data-table-cell-cb text-left">
                    {{ formatNumber(row.quantity) }}
                    <span v-if="row.reportedQuantity">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="
                          $tc(
                            `tooltips.${
                              period?.vestingCalendar.fractionReport
                                ? 'reportedValueEnd'
                                : 'reportedValue'
                            }`,
                            { quantity: row.reportedQuantity }
                          )
                        "
                        placement="top"
                        ><i class="bi bi-info-circle text-sm"></i
                      ></el-tooltip>
                    </span>
                  </td>
                  <td
                    :class="`data-table-cell-cb text-left ${
                      row.periodAccelerations.length ? 'qAccelerated' : ''
                    }`"
                  >
                    <div class="flex justify-between">
                      <div class="px-1">
                        {{
                          formatNumber(
                            row.periodAccelerations.reduce(
                              (ac, x) => (ac += x.quantity),
                              0
                            )
                          )
                        }}
                      </div>

                      <el-popover
                        class="shadow-md z-30"
                        width="50%"
                        placement="top"
                      >
                        <template #reference>
                          <div
                            v-if="row.periodAccelerations.length"
                            class="px-1"
                          >
                            {{ row.periodAccelerations.length }}
                            <i class="bi bi-journals"></i>
                          </div>
                        </template>
                        <template #default>
                          <div class="flex flex-col border-b border-secondary6">
                            <div class="flex">
                              <div class="w-1/3 px-2">
                                {{ $t('events.fieldsLabels.accelerationDate') }}
                              </div>
                              <div class="w-1/3 bg-primary2 px-2">
                                {{
                                  $t('events.fieldsLabels.accelerationQuantity')
                                }}
                              </div>
                              <div class="w-1/3 px-2">
                                {{
                                  $t('events.fieldsLabels.accelerationPercent')
                                }}
                              </div>
                            </div>
                            <div
                              v-for="acceleration in row.periodAccelerations"
                              :key="acceleration.period.id"
                              class="
                                flex
                                border-l border-t border-r border-secondary6
                              "
                            >
                              <div class="w-1/3 px-2">
                                {{
                                  moment
                                    .utc(acceleration.acceleration.startDate)
                                    .format('L')
                                }}
                              </div>
                              <div class="w-1/3 bg-primary2 px-2">
                                {{ acceleration.quantity }}
                              </div>
                              <div class="w-1/3 px-2">
                                {{ acceleration.acceleration.percent }} %
                              </div>
                            </div>
                          </div>
                        </template>
                      </el-popover>
                    </div>
                  </td>
                  <td class="data-table-cell-cb text-left">
                    {{ formatNumber(row.cumulativeQuantity) }}
                  </td>
                </tr>
                <tr class="font-bold h-10">
                  <td
                    colspan="2"
                    class="px-2 data-table-cell-cb text-right text-primary8"
                  >
                    Total
                  </td>
                  <td class="data-table-cell-cb text-left text-primary8">
                    {{ formatNumber(totalVested) }}
                    {{ vestingType === 'PERCENT' ? '%' : '' }}
                  </td>
                  <td class="data-table-cell-cb text-left text-primary8">
                    {{ formatNumber(totalQuantity) }}
                  </td>
                  <td
                    :class="`data-table-cell-cb text-left text-primary8  ${
                      totalAcceleratedQuantity ? 'qAccelerated' : ''
                    }`"
                  >
                    {{ formatNumber(totalAcceleratedQuantity) }}
                  </td>
                  <td class="data-table-cell-cb text-left text-primary8">-</td>
                </tr>
              </tbody>
            </template>
          </data-table>
          <div v-else class="flex justify-center items-center w-full mt-4">
            <EmptyState
              class="d-block mx-auto datatable"
              :title="$t('global.noData')"
              :subtitle="$t('global.noVestingData')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { ExercicePeriod, ExerciseCalendar } from '@/services/api/models'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { formatNumber } from '@/utils'
  import Loader from '@/components/Loader/index.vue'
  import { VESTINGVALUEUNIT } from '@/types'
  import { ElPopover, ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      DataTable,
      Multiselect,
      ElPopover,
      Field,
      EmptyState,
      Loader,
      ElTooltip
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const date = ref(new Date().toDateString())
      const mode = ref('list')
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const isLoading = ref(false)

      const optionId = ref('')

      const selectedOption = computed<ExerciseCalendar | undefined>(() =>
        exerciceCalendars.value.find(
          (x) => x.id === optionId.value.split('@_')[0]
        )
      )

      const selectedOptionId = computed(() =>
        optionId.value && optionId.value.split('@_').length > 1
          ? optionId.value.split('@_')[1]
          : ''
      )
      const vestingType = computed(
        () => period.value?.vestingCalendar?.vestingValueUnit
      )
      const period = computed<ExerciseCalendar | undefined>(() =>
        exerciceCalendars.value.find(
          (x) => x.id === optionId.value.split('@_')[0]
        )
      )

      const sold = computed(
        () =>
          assignedQuantity.value -
          lapsedQuantity.value -
          (period.value?.exercisedQuantity || 0)
      )
      const lapsedQuantity = ref(0)

      const assignedQuantity = ref(0)

      const exercisePeriods = computed<ExercicePeriod[]>(() =>
        [...(period.value?.exercisePeriods ?? [])].sort(
          (a, b) => a.vestingPeriod.order - b.vestingPeriod.order
        )
      )

      const options = computed(() =>
        exerciceCalendars.value.map((x) => ({
          ...x.optionAlias,
          name: `${x.optionAlias.alias} ${
            x.optionIssuancePart?.vestingCalendar
              ? `(${x.optionIssuancePart?.vestingCalendar?.name})`
              : ''
          }`,
          id: `${x.id}@_${x.optionAlias.id}`,
          exerciseId: x.id
        }))
      )

      const columns = computed(() => [
        {
          label: '#',
          field: 'index',
          custom: true,
          width: '5%'
        },
        {
          label: t(`datatable.column.date`),
          field: 'date',
          custom: true,
          width: '20%'
        },
        {
          label:
            vestingType.value === 'PERCENT'
              ? t(`datatable.column.pVested`)
              : vestingType.value === VESTINGVALUEUNIT.FRACTION
              ? t(`datatable.column.fVested`)
              : t(`datatable.column.qVested`),
          field: 'pVested',
          custom: true,
          width: '10%'
        },
        {
          label: t(`datatable.column.qVested`),
          field: 'qVested',
          custom: true,
          width: '12%'
        },
        {
          label: t(`datatable.column.qAccelerated`),
          field: 'qAccelerated',
          custom: true,
          width: '13%'
        },
        {
          label: t(`datatable.column.cumulatedVestedQuantity`),
          field: 'cumulatedVestedQuantity',
          custom: true,
          width: '25%'
        }
      ])

      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            contactId: route.params.contactId,
            date: moment(date.value).endOf('day').toISOString()
          },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const updateSold = ({ attributedQuantity, obsoleteQuantity }: any) => {
        lapsedQuantity.value = obsoleteQuantity
        assignedQuantity.value = attributedQuantity
      }

      const totalVested = computed(() =>
        period.value?.exercisePeriods.reduce(
          (acc, elm) => (acc += elm.vestingPeriod?.value || 0),
          0
        )
      )

      const totalQuantity = computed(() =>
        period.value?.exercisePeriods.reduce(
          (acc, elm) => (acc += elm.quantity),
          0
        )
      )

      const totalAcceleratedQuantity = computed(() =>
        period.value?.exercisePeriods.reduce((acc, elm) => {
          const [lastAcceleration] = elm.periodAccelerations.sort(
            (x: any, y: any) =>
              moment(y.createdAt).unix() - moment(x.createdAt).unix()
          )

          return (acc += lastAcceleration?.quantity || 0)
        }, 0)
      )

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_EXERCISE_CALENDARS_PAGE', page)
        getVesting()
      }

      onMounted(() => {
        isLoading.value = true
        onPageChange(1)
      })

      watch(
        () => route.params.contactId,
        (newContactId: any) => {
          if (newContactId) {
            optionId.value = options.value[0]?.id
            getVesting()
          }
        }
      )

      watch(options, (val: any) => {
        optionId.value = val.map((op: any) => op.id).includes(optionId.value)
          ? optionId.value
          : options.value[0]?.id
      })

      return {
        columns,
        moment,
        optionId,
        options,
        mode,
        period,
        totalQuantity,
        totalAcceleratedQuantity,
        totalVested,
        vestingType,
        date,
        selectedOptionId,
        exercisePeriods,
        sold,
        selectedOption,
        isLoading,
        formatNumber,
        updateSold
      }
    }
  })
</script>
<style lang="scss">
  .exercise-table {
    .qAccelerated {
      background-color: var(--color-primary-lower) !important;
    }
    .data-table-cell-cb {
      height: 40px;
      @apply border-t-0 px-2  border-l border-r whitespace-nowrap p-0 border-b border-secondary4;
      .data-table-cell-cb-label {
        @apply select-none  relative cursor-pointer pr-6 -inset-y-2;
      }
    }
  }
</style>
