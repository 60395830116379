
  import { Appointment } from '@/services/api/models'
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import moment from 'moment'

  export default defineComponent({
    name: 'DropdownAppointments',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: String
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'bottom'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      canAddNew: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { data } = toRefs(props)
      const searchText = ref('')
      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )

      const appointments = computed<Appointment[]>(
        () => store.getters['organizations/nominations']
      )

      const option = ref(
        props.value ||
          (appointments.value.length === 1 ? appointments.value[0]?.id : '')
      )

      const currentNomination = ref<Appointment | undefined | null>(
        appointments.value.find((x) => x.id === option.value)
      )

      const options = computed(() => {
        const apps = appointments.value.map((n) => ({
          id: n.id,
          name: `${n.contact.name} / ${n.function.name} `
        }))
        if (
          currentNomination.value?.id &&
          !apps.map((x) => x.id).includes(currentNomination.value?.id)
        ) {
          apps.push({
            id: currentNomination.value?.id,
            name: `${currentNomination.value.contact.name} / ${currentNomination.value.function.name} `
          })
        }

        return apps
      })

      const updateValue = (selected: string) => {
        getAppointment(selected)

        option.value = selected
        emit('onUpdated', {
          ...data.value,
          value: selected
        })
      }

      watch([options, appointments], ([val]) => {
        if (val.length === 1) {
          const [appt] = appointments.value

          option.value =
            option.value ||
            (options.value.map((x) => x.id).includes(appt.id) ? appt.id : '')

          emit('onUpdated', {
            ...data.value,
            value: option.value
          })
        }
      })

      const handleSearch = (text: string) => {
        getAppointments(text)
      }

      const getAppointments = (search = '') => {
        const filter: any = {}

        if (search) filter.search = search
        store.dispatch('organizations/GET_NOMINATIONS', {
          companyId: route.params.id,
          filter
        })
      }

      const getAppointment = (id: string) => {
        if (!id) {
          currentNomination.value = null
          return
        }
        store.dispatch('organizations/GET_NOMINATION', {
          companyId: route.params.id,
          appointmentId: id,
          onSuccess: (data: Appointment | undefined) => {
            currentNomination.value = data
          },
          onError: () => {
            // console.log();
          }
        })
      }

      onMounted(() => {
        getAppointments()

        updateValue(props.value)
      })

      return {
        options,
        option,
        fieldName,
        searchText,
        currentNomination,
        updateValue,
        moment,
        handleSearch
      }
    }
  })
