import { Contact, Event } from '@/services/api/models'
import { Document } from '@/services/api/models/document'
import { Pagination } from '@/store/modules/Organization/models'
import { Company } from '@/services/api/models/company'
import { DocumentResult } from '../Organization/models'
import {
  IndividualContact,
  ProfessionalContact,
  InvestorContact
} from '@/services/api/models/company'

export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENT = 'GET_EVENT'
export const LOAD_EVENTS = 'LOAD_EVENTS'
export const SET_LOADING = 'SET_LOADING'
export const SET_EVENT = 'SET_EVENT'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'
export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const GET_DOCUMENT = 'GET_DOCUMENT'
export const BUILD_CHARTS = 'BUILD_CHARTS'
export const SET_DOC_SEARCH_RESULT = 'SET_DOC_SEARCH_RESULT'
export const SEARCH_IN_DOCUMENTS = 'SEARCH_IN_DOCUMENTS'
export const IS_DOCUMENT_SEARCHING = 'IS_DOCUMENT_SEARCHING'
export const SET_TEXT_SEARCHED = 'SET_TEXT_SEARCHED'
export const GET_CONTACTS = 'GET_CONTACTS'
export const SET_CONTACTS = 'SET_CONTACTS'
export const SET_COMPANIES = 'SET_COMPANIES'
export const GET_COMPANIES = 'GET_COMPANIES'
export const SET_DOCUMENTS_PAGINATION_PAGE = 'SET_DOCUMENTS_PAGINATION_PAGE'
export const LOAD_DOCUMENTS = 'LOAD_DOCUMENTS'
export const SET_DOCUMENTS_GETTING = 'SET_DOCUMENTS_GETTING'
export const SET_COMPANIES_LOGOS = 'SET_COMPANIES_LOGOS'
export const GET_EVENT_DOCUMENTS = 'GET_EVENT_DOCUMENTS'
export const GET_COMPANY_SINGLE_DOCUMENT = 'GET_COMPANY_SINGLE_DOCUMENT'
export const SET_COMPANY_SINGLE_DOCUMENT = 'SET_COMPANY_SINGLE_DOCUMENT'
export const SET_LOADER_COMPANIES = 'SET_LOADER_COMPANIES'
export const GET_LOADER_COMPANIES = 'GET_LOADER_COMPANIES'
export interface ResultPagination {
  count: number
  currentPage: number
  lastPage: number
}
export interface PaginatedListResult<TValue> {
  metadata: ResultPagination
  data: TValue
}
export interface PortfolioMutations {
  [SET_EVENTS](
    state: PortfolioState,
    result: PaginatedListResult<Event[]>
  ): void
  [SET_DOCUMENTS](state: PortfolioState, documents: any): void
  [SET_CONTACTS](
    state: PortfolioState,
    contacts: (IndividualContact | ProfessionalContact | InvestorContact)[]
  ): void
  [SET_COMPANIES](state: PortfolioState, companies: Company[]): void
}

export interface PortfolioGetters {
  events(state: PortfolioState): Event[]
  documents(state: PortfolioState): Document[]
  eventsPagination(state: PortfolioState): Pagination
  documentsPagination(state: PortfolioState): Pagination
}

export interface PortfolioState {
  corporateEvents: Event[]
  loaderCompanies: Company[]
  searching: boolean
  documentSearchResult: DocumentResult[]
  event: Event | null
  documents: Document[]
  documentList: Document[]
  companies: Company[]
  contacts: (IndividualContact | ProfessionalContact | InvestorContact)[]
  documentsPagination: Pagination
  eventsPagination: Pagination
  chart?: any
  isLoading: boolean
  companyLogos: any
  gettingDocuments: boolean
}
