
  import { Appointment, Company } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { calculateTimeDuration } from '@/utils/global'
  import { useRoute, useRouter } from 'vue-router'
  export default defineComponent({
    name: 'AppointmentCard',
    components: { ContactBadge },
    props: {
      event: {
        type: Object as () => Appointment | undefined,
        required: true
      },
      company: {
        type: Object as () => Company | undefined,
        default: null
      }
    },
    setup(props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const lang = computed(() => store.getters['config/lang'])

      const gotoEvent = () => {
        if (!props.event?.appointmentTermination?.event) return
        router.push({
          name: 'company.eventDetails',
          params: {
            id: route.params.id,
            eventId: props.event?.appointmentTermination?.event.id
          }
        })
      }
      return { lang, moment, calculateTimeDuration, gotoEvent }
    }
  })
