
  import { computed, defineComponent, ref, watch } from 'vue'
  import { Field, useForm } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    EndOfContractReasons,
    VestingPlanning,
    VESTINGTYPE
  } from '@/types/forms'
  import {
    ENDOFPERIOD,
    PERIODBEHAVIOR,
    ROUNDINGMETHOD,
    STARTOFPERIOD,
    TIMEUNIT,
    VestingForm,
    VESTINGVALUEUNIT
  } from '@/types'
  import { useI18n } from 'vue-i18n'
  import { vestingSchema } from '@/utils/schema'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import { TYPE } from 'vue-toastification'
  import VestingDatatable from './VestingDatatable.vue'
  import EndOfContractDatatable from './EndOfContratDatatable.vue'
  import { CompanyVesting } from '@/services/api/models'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  import Loader from '@/components/Loader/index.vue'
  import { VestingMode } from '@/types/vesting'

  export default defineComponent({
    components: {
      Field,
      Input,
      Multiselect,
      FormActions,
      VestingDatatable,
      Button,
      EndOfContractDatatable,
      Checkbox,
      Loader,
      ElPopconfirm
    },
    setup() {
      const vestingType = ref(VestingMode.OPTIONS)
      const { t } = useI18n()
      const store = useStore()
      const acl = useAcl()

      const modes = computed(() =>
        Object.values(VestingMode).map((x) => ({
          id: x,
          name: t(`VestingMode.${x}`)
        }))
      )
      const vesting = computed<CompanyVesting>(
        () => store.getters['organizations/vesting']
      )

      const isSubmitting = ref(false)

      const isLoadingVesting = computed(
        () => store.getters['organizations/isLoadingVesting']
      )

      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-vestings'))
      )

      const newValues = (): VestingForm => {
        let endOfContractBehaviors: any = {}
        Object.keys(EndOfContractReasons).forEach((x) => {
          endOfContractBehaviors = {
            ...endOfContractBehaviors,
            [x]: {
              notVestedPercent: 0,
              vestedPercent: 0,
              notVestedPeriod: {
                timeUnit: TIMEUNIT.MONTH,
                value: 90
              },
              vestedPeriod: {
                timeUnit: TIMEUNIT.MONTH,
                value: 90
              }
            }
          }
        })
        return {
          name: '',
          vestingValueUnit: VESTINGVALUEUNIT.PERCENT,
          fractionReport: false,
          hasObjective: false,
          vestingPeriods: [],
          endOfContractBehaviors
        }
      }
      const types = ref(
        Object.keys(VESTINGVALUEUNIT).map((x) => ({
          name: t(`vestingValueUnit.${x}`),
          value: x,
          id: x
        }))
      )
      const newVesting = ref<any>(newValues())
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentVesting = computed<CompanyVesting>(
        () => store.getters['organizations/vesting']
      )

      const modalForm = computed(() => store.getters['organizations/form'])
      const updateDataRow = (
        index: number,
        field: string,
        value: string | number
      ) => {
        newVesting.value.vestingPeriods[index][field] = value
      }
      const reOrderList = () => {
        newVesting.value.vestingPeriods = newVesting.value.vestingPeriods.map(
          (x: any, i: number) => ({ ...x, order: i + 1 })
        )
      }

      const addNewRow = (nb: number, order: number) => {
        for (let index = 0; index < nb; index++) {
          newVesting.value.vestingPeriods.push({
            value: null,
            order: order + 1,
            startOfPeriod:
              order == 1
                ? STARTOFPERIOD.DEFAULT_START_DATE
                : STARTOFPERIOD.LAST_PERIOD_END_PLUS_ONE,
            vestingType: VESTINGTYPE.START_OF_PERIOD,
            roundingMethod: ROUNDINGMETHOD.INF,
            nbTimeUnits: null,
            endOfPeriod: ENDOFPERIOD.NEXT_MONTH_FIRST_DAY,
            timeUnit: TIMEUNIT.MONTH,
            periodBehavior: PERIODBEHAVIOR.WAIT
          })
        }

        reOrderList()
      }

      const duplicate = (index: number, count: number) => {
        const line = newVesting.value.vestingPeriods[index]

        for (let i = 1; i <= count; i++) {
          newVesting.value.vestingPeriods.splice(index + i, 0, {
            ...line,
            startOfPeriod: STARTOFPERIOD.LAST_PERIOD_END_PLUS_ONE
          })
        }

        reOrderList()
      }

      const schema = computed(() => vestingSchema)
      const { errors, setValues, setFieldValue, values, meta } = useForm({
        initialValues: currentVesting.value,
        validationSchema: schema.value,
        validateOnMount: false
      })

      const removeFromRow = (row: VestingPlanning) => {
        newVesting.value.vestingPeriods =
          newVesting.value.vestingPeriods.filter(
            (x: any) => x.order !== row.order
          )
        const periods = values.vestingPeriods.filter(
          (x: any) => x.order !== row.order
        )
        setValues({ ...values, vestingPeriods: periods })
        // reOrderList()
      }

      const handleForm = async () => {
        let action =
          vestingType.value === VestingMode.OPTIONS
            ? 'CREATE_VESTING'
            : 'CREATE_FREE_SHARES_VESTING'
        let key = 'create'
        const form = {
          ...values,
          [vestingType.value === VestingMode.OPTIONS
            ? 'hasObjective'
            : 'isAutoAcquisition']: values.hasObjective,
          [vestingType.value === VestingMode.OPTIONS
            ? 'vestingPeriods'
            : 'freeShareVestingPeriods']: values.vestingPeriods?.map(
            (x: any, index: number) => ({
              ...x,
              order: index + 1,
              nbTimeUnits: parseInt(x.nbTimeUnits),
              value: parseFloat(x.value)
            })
          )
        }
        form.optionIssuanceParts = []
        form.exerciseCalendars = []
        const formValue: any = { form }

        if (values.id) {
          action =
            vestingType.value === VestingMode.OPTIONS
              ? 'UPDATE_VESTING'
              : 'EDIT_FREE_SHARES_VESTING'
          formValue.vestingId = values.id
          key = 'edit'
        }

        isSubmitting.value = true
        await store.dispatch(`organizations/${action}`, {
          companyId: currentCompany.value.id,
          ...formValue,
          onError: (msg = null) => {
            const message = msg || t(`vestingBook.apiResponse.${key}Error`)
            isSubmitting.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('vestingBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            newVesting.value = newValues()
            isSubmitting.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('vestingBook.form.title'),
              message: t(`vestingBook.apiResponse.${key}Success`)
            })
          }
        })
      }

      const deleteVesting = () => {
        store.dispatch(`organizations/REMOVE_COMPANY_VESTING`, {
          companyId: currentCompany.value.id,
          vestingId: currentVesting.value.id,
          onSuccess: () => {
            handleFormReset()
            store.commit('organizations/SET_CURRENT_VESTING', null)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('vestingBook.form.title'),
              message: t('vestingBook.apiResponse.deleteSuccess')
            })
          },
          onError: (msg = null) => {
            const message = msg || t('vestingBook.apiResponse.deleteError')
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('vestingBook.form.title'),
              message
            })
          }
        })
      }

      const handleFormReset = () => {
        newVesting.value = newValues()
      }

      watch(modalForm, (value) => {
        if (value.initCurrentForm) {
          const fvalues = newValues()
          newVesting.value = fvalues
          setValues(fvalues)
          return
        }
      })

      watch(currentVesting, (value: any) => {
        if (value) {
          const formValues = { ...value }

          Object.keys(EndOfContractReasons).forEach((elm) => {
            if (
              'endOfContractBehaviors' in value &&
              !value.endOfContractBehaviors[elm]?.vestedPeriod
            ) {
              formValues.endOfContractBehaviors[elm] = {
                ...formValues.endOfContractBehaviors[elm],
                vestedPeriod: {
                  timeUnit: TIMEUNIT.MONTH,
                  value: 90
                }
              }
            }

            if (
              'endOfContractBehaviors' in value &&
              !value.endOfContractBehaviors[elm]?.notVestedPeriod
            ) {
              formValues.endOfContractBehaviors[elm] = {
                ...formValues.endOfContractBehaviors[elm],
                notVestedPeriod: {
                  timeUnit: TIMEUNIT.MONTH,
                  value: 90
                }
              }
            }
          })

          vestingType.value = value.isFreeShareVesting
            ? VestingMode.FREE_SHARES
            : VestingMode.OPTIONS

          if (value.isFreeShareVesting) {
            vestingType.value = VestingMode.FREE_SHARES
            formValues.vestingPeriods = [...value.freeShareVestingPeriods]
            formValues.hasObjective = value.isAutoAcquisition
          }
          newVesting.value = formValues

          setValues(formValues)
        } else {
          vestingType.value = VestingMode.OPTIONS
        }
      })

      return {
        newVesting,
        canDo,
        vesting,
        isLoadingVesting,
        values,
        currentVesting,
        errors,
        meta,
        types,
        schema,
        modes,
        VestingMode,
        vestingType,
        isSubmitting,
        handleForm,
        handleFormReset,
        addNewRow,
        removeFromRow,
        deleteVesting,
        duplicate,
        setFieldValue,
        updateDataRow
      }
    }
  })
