import { MAINACTIONS } from '@/types'

// Mutations
export const SET_MODAL_SETTINGS = 'SET_MODAL_SETTINGS'
export const DISPLAY_PREVIEW = 'DISPLAY_PREVIEW'
export const COLLAPSE_DOC_PREVIEW = 'COLLAPSE_DOC_PREVIEW'

export interface ModalSettings {
  display: boolean
  contentType: MAINACTIONS
}

export interface A {
  [key: string]: ModalSettings
}

export interface UIState {
  modalSettings: ModalSettings
  displayDocumentPreview: boolean
  documentPreviewCollapsed: boolean
}

export interface UIMutations {
  [SET_MODAL_SETTINGS](state: UIState, modalSettings: ModalSettings): void
}

export interface UIGetters {
  isModalOpen(state: UIState): boolean
  modalContentType(state: UIState): MAINACTIONS
  displayDocumentPreview(state: UIState): boolean
  documentPreviewCollapsed(state: UIState): boolean
}
