
  import { NominalValue } from '@/services/api/models'
  import { computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'IssuanceCard',
    components: {},
    props: {
      event: {
        type: Object as () => NominalValue | undefined,
        required: true
      }
    },
    setup() {
      const store = useStore()
      const currency = computed(() => store.getters['organizations/currency'])

      return { currency }
    }
  })
