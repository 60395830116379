<template>
  <div :class="`${grid ? '' : ''}`">
    <div class="col-span-12">
      <label
        v-if="hasLabel"
        class="capitalize text-xs font-semibold text-secondary8 text-left"
        >{{ data.label }}</label
      >
      <Field v-slot="{ field }" v-model="formValue" :name="data.name">
        <Multiselect
          v-model="formValue"
          v-bind="field"
          :value="formValue"
          :show-labels="true"
          mode="single"
          :name="data.name"
          :options="fundRounds"
          :placeholder="$t('events.elements.fundRaisingRound')"
          :groups="false"
          class="w-full h-10"
          group-label="name"
          :open-direction="openDirection"
          @search-change="searchText = $event"
          @change="updateValue"
        >
          <template #noOptions>
            <span v-if="false"> {{ $t('events.elements.noVesting') }} </span>
            <span v-else>{{ $t('events.elements.search') }}</span>
          </template>

          <template #noResults>
            <span>{{ $t('global.placeholder.noResult') }}</span>
          </template>
        </Multiselect>
      </Field>
    </div>
    <div class="col-span-3 dede">
      <slot :name="data.name" :value="formValue" />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    onBeforeMount,
    watch
  } from 'vue'
  import { Field } from 'vee-validate'
  import { useRoute } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { FundRaisingRound } from '@/services/api/models'
  export default defineComponent({
    name: 'DropdownProfitSharingPlan',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      allData: {
        default: () => ({}),
        type: Object
      },

      classItem: {
        type: String,
        default: 'w-full'
      },
      type: {
        type: String,
        default: null
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: String
      },
      endDate: {
        default: '',
        type: String
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'top'
      },
      grid: {
        default: true,
        type: Boolean
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { data } = toRefs(props)
      const route = useRoute()

      // allData will be useful for filter if delegation is selected for example
      const formValue = ref<any>(props.value)
      const searchText = ref('')

      const fundRounds = computed<FundRaisingRound[]>(
        () => store.getters['organizations/fundRaisingRounds']
      )

      const updateValue = (value: any) => {
        formValue.value = value
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      watch(fundRounds, (opts) => {
        if (!opts.map((x) => x.id).includes(props.value)) {
          formValue.value = ''
        }
      })

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_FUND_RAISING_ROUNDS', {
          companyId: route.params.id
        })
      })

      return {
        formValue,
        searchText,
        fundRounds,
        updateValue
      }
    }
  })
</script>
