import axios from '../axios'
import { DocumentContactsForm, DocumentEditForm } from '@/types/forms'
import { company } from '../endpoints'
import { DocumentSearchProps } from '@/types/file'

const allAssociatesRecord = async (companyId: string) => {
  return await axios.get(company.allAssociatesRecord(companyId))
}

const attachContactToDocument = async (
  companyId: string,
  documentId: string,
  values: DocumentContactsForm
) => {
  return await axios.post(
    company.documentContacts(companyId, documentId),
    values
  )
}

const editDocument = async (
  companyId: string,
  documentId: string,
  values: DocumentEditForm
) => {
  return await axios.put(company.getDocument(companyId, documentId), values)
}

const deleteDocument = async (companyId: string, documentId: string) => {
  return await axios.delete(company.getDocument(companyId, documentId))
}

const removeEventDocument = async (
  companyId: string,
  eventId: string,
  documentId: string
) => {
  return await axios.delete(
    company.eventDocument(companyId, eventId, documentId)
  )
}

const updateEventDocuments = async (
  companyId: string,
  eventId: string,
  form: any
) => {
  return await axios.put(company.eventDocuments(companyId, eventId), form)
}

const getEventDocuments = async (
  companyId: string,
  eventId: string,
  offset = 0,
  limit = 50
) => {
  return await axios.get(company.eventDocuments(companyId, eventId), {
    params: { offset, limit }
  })
}

const uploadFiles = async (
  form: FormData,
  companyId: string,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const resp = await axios.post(company.uploadFiles(companyId), form, {
    onUploadProgress
  })
  return resp
}

const replaceDocument = async (
  companyId: string,
  documentId: string,
  form: FormData,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const resp = await axios.post(
    company.getDocument(companyId, documentId),
    form,
    {
      onUploadProgress
    }
  )
  return resp
}

const getCompanyDocuments = async (
  companyId: string,
  filter: DocumentSearchProps
): Promise<any> => {
  const params = { ...filter }
  return await axios.get(company.documents(companyId), { params })
}

const searchInDocuments = async (
  companyId: string,
  query: string
): Promise<Document[]> => {
  return await axios.get(company.searchInDocuments(companyId), {
    params: { q: query }
  })
}

const getSingleCompanyDocuments = async (
  companyId: string,
  documentId: string
): Promise<Document[]> => {
  return await await axios.get(company.getDocument(companyId, documentId))
}

export {
  uploadFiles,
  editDocument,
  deleteDocument,
  attachContactToDocument,
  searchInDocuments,
  getCompanyDocuments,
  getSingleCompanyDocuments,
  replaceDocument,
  removeEventDocument,
  updateEventDocuments,
  allAssociatesRecord,
  getEventDocuments
}
