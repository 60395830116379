<template>
  <div>
    <p>{{ text }}.</p>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      text: { type: String, default: '' }
    },
    setup() {
      return {}
    }
  })
</script>
