
  import { defineComponent, onMounted, ref, watch } from 'vue'
  import { ColorPicker } from 'vue3-colorpicker'
  import 'vue3-colorpicker/style.css'
  export default defineComponent({
    name: 'UplColorPicker',
    components: { ColorPicker },
    props: {
      name: { type: String, value: '' },
      value: {
        type: String,
        required: true
      }
    },
    emits: ['change'],
    setup(props, { emit }) {
      const pureColor = ref<string>(props.value || '#3b69da')
      const gradientColor = ref(
        'linear-gradient(0deg, rgba(100, 100, 255, 1) 0%, rgba(0, 0, 0, 1) 100%)'
      )
      const changeColor = (color: any) => {
        emit('change', color)
      }

      watch(props, (newProps) => {
        pureColor.value = newProps.value || '#3b69da'
        changeColor(pureColor.value)
      })

      onMounted(() => {
        changeColor(pureColor.value)
      })
      return { changeColor, pureColor, gradientColor }
    }
  })
