
  import { Event, FreeSharesSunset } from '@/services/api/models'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { defineComponent, computed } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  export default defineComponent({
    name: 'FreeShareSunset',
    components: { ContactBadge, Tag },
    props: {
      event: {
        type: Object as () => FreeSharesSunset | undefined,
        default: null
      }
    },
    setup(props) {
      const part = computed(
        () => props.event?.acquisitionCalendar.freeSharesIssuancePart
      )

      return { moment, part, formatNumber }
    }
  })
