<template>
  <div class="h-full relative">
    <Field v-slot="{ field }" v-model="search" type="text" name="search">
      <Input
        v-bind="field"
        :disabled="false"
        :placeholder="$t('global.search')"
      >
      </Input>
    </Field>
    <div class="alias-list h-4/5 overflow-scroll">
      <div
        v-for="[item, contacts] in groupedContacts"
        :key="item"
        class="flex justify-start mb-2"
      >
        <div class="">
          <h5 class="uppercase text-secondary7 font-semibold mt-2 text-lg">
            {{ item }}
          </h5>
        </div>
        <div class="pl-2 w-full">
          <h3
            v-for="contact in contacts"
            :key="contact.id"
            :class="`
            ${
              currentContact && contact.id === currentContact.id
                ? 'bg-secondary5'
                : ''
            }
              text-blackp
              font-medium
              capitalize
              text-sm
              my-2
              hover:bg-secondary5
              rounded
              px-2
              py-1
              cursor-pointer
              transition`"
            @click="handleSelect(contact.id)"
          >
            {{ contact.name }}
          </h3>
        </div>
      </div>
    </div>
    <div v-if="canDo" class="bottom-container bg-white text-xl">
      <Button
        :disabled="false"
        :label="$t('contactBook.form.new')"
        size="medium"
        variant="secondary"
        class="w-full h-10 flex items-center justify-center text-xl"
        @click="initStockForm"
      >
        <i class="bi bi-plus text-md"></i>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, onMounted, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { groupItemBy, sortObjectByKeys } from '@/utils'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'ModalContactFilter',
    components: { Field, Input, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )

      const currentContact = computed(
        () => store.getters['organizations/contact']
      )

      const allContacts = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contacts'])

      const groupedContacts = computed<Map<string, any>>(() =>
        sortObjectByKeys(
          groupItemBy(
            allContacts.value,
            (item: IndividualContact | ProfessionalContact | InvestorContact) =>
              item.name.charAt(0).toUpperCase()
          )
        )
      )
      const getContacts = async (search = '') => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }
      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })
        store.commit('organizations/SET_CURRENT_CONTACT', null)
      }

      const handleSelect = (contactId: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: currentCompany.value.id,
          contactId
        })
      }

      watch(search, (newVal) => {
        getContacts(newVal)
      })

      onMounted(() => {
        getContacts()
      })

      return {
        search,
        canDo,
        currentContact,
        groupedContacts,
        initStockForm,
        handleSelect
      }
    }
  })
</script>
<style lang="scss" scoped>
  .alias-list::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .alias-list {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .bottom-container {
    @apply absolute bottom-0 right-0 left-0 z-10 h-20 flex justify-center items-center;
  }
</style>
