import { company } from '../endpoints'
import axios from '../axios'
import { ProfitSharingPlanFormData } from '@/types/forms'

const getProfitSharingPlans = async (companyId: string, filter = {}) => {
  const response = await axios.get(company.profitSharingPlan(companyId), {
    params: filter
  })
  return response
}

const getProfitSharingPlan = async (companyId: string, planId = '') => {
  const response = await axios.get(
    company.singleProfitSharingPlan(companyId, planId)
  )
  return response
}

const deleteProfitSharingPlan = async (companyId: string, planId = '') => {
  const response = await axios.delete(
    company.singleProfitSharingPlan(companyId, planId)
  )
  return response
}

const createProfitSharingPlan = async (
  companyId: string,
  form: ProfitSharingPlanFormData
) => {
  const { data } = await axios.post(company.profitSharingPlan(companyId), form)
  return data
}

const editProfitSharingPlan = async (
  companyId: string,
  id: string,
  form: ProfitSharingPlanFormData
) => {
  const { data } = await axios.put(
    company.singleProfitSharingPlan(companyId, id),
    form
  )
  return data
}

export {
  editProfitSharingPlan,
  createProfitSharingPlan,
  getProfitSharingPlan,
  getProfitSharingPlans,
  deleteProfitSharingPlan
}
