
  import { CompanyGroup } from '@/services/api/models'
  import { CompanyModel } from '@/services/api/models'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: { Input, Field, Button },
    setup() {
      const store = useStore()
      const search = ref('')
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const group = computed<CompanyGroup>(
        () => store.getters['organizations/group']
      )

      const getGroups = () => {
        store.dispatch('organizations/GET_COMPANY_GROUPS', {
          companyId: currentCompany.value.id
        })
      }

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )

      const listedGroups = ref(groups.value)

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_CONTACT_GROUP', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const handleSelect = (groupId: string) => {
        store.dispatch('organizations/GET_CONTACT_GROUP', {
          companyId: currentCompany.value.id,
          groupId
        })
      }

      onBeforeMount(() => {
        getGroups()
      })

      watch(search, (text: string) => {
        listedGroups.value = groups.value.filter((x) =>
          x.name.toLowerCase().includes(text.toLowerCase())
        )
      })

      watch(groups, (list) => {
        listedGroups.value = list
      })
      return { canDo, listedGroups, search, group, initForm, handleSelect }
    }
  })
