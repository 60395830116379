<template>
  <div v-if="event" class="w-full flex-wrap flex event-bg">
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ $t('events.fieldsLabels.denomination') }}
      </h1>
      <p v-if="event?.denomination" class="text-blackp text-sm text-left">
        {{ event.denomination }}
      </p>
      <p v-else class="text-blackp text-sm text-left">-</p>
    </div>
    <div class="p-3 border-r border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.nbMembers') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{
          event.minMembers === event.maxMembers
            ? event.maxMembers
            : `${event.minMembers} - ${event.maxMembers}`
        }}
        {{ $t('events.fieldsLabels.members') }}
      </p>
    </div>
    <div class="p-3 border-secondary6 w-1/3">
      <h1 class="text-left text-secondary7 text-xs font-light">
        {{ $t('events.fieldsLabels.createdAt') }}
      </h1>
      <p class="text-blackp text-sm text-left">
        {{ moment.utc(event.creationDate).format($t('dateFormat')) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { Committee } from '@/services/api/models'
  import { defineComponent, watch } from 'vue'
  import moment from 'moment'
  export default defineComponent({
    name: 'CommitteeCard',
    props: {
      event: {
        type: Object as () => Committee | undefined,
        required: true
      }
    },
    setup(props) {
      watch(
        () => props.event,
        (val) => {
          console.log(val, 'props.event')
        }
      )
      return { moment }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
