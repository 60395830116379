<template>
  <div class="flex justify-center items-center">
    <div class="pb-10 w-full">
      <h3>{{ $t('stockGroupBook.form.add') }}</h3>
      <Form
        v-slot="{ errors, meta, resetForm }"
        :validation-schema="schema"
        @submit="handleForm"
        @reset="handleReset"
      >
        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="name"
            >{{ $t('stockGroupBook.form.name') }}</label
          >
          <Field v-slot="{ field }" v-model="newGroup.name" name="name">
            <Input
              v-bind="field"
              id="name"
              v-model="newGroup.name"
              name="name"
              :disabled="false"
              :placeholder="$t('stockGroupBook.form.namePlaceholder')"
            />
          </Field>
        </div>
        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="colorCode"
            >{{ $t('stockGroupBook.form.color') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newGroup.colorCode"
            name="colorCode"
          >
            <div class="group-color-block">
              <ColorPicker
                v-bind="field"
                :value="newGroup.colorCode"
                name="colorCode"
                @change="newGroup.colorCode = $event"
              />
            </div>
          </Field>
        </div>

        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="description"
            >{{ $t('stockGroupBook.form.description') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newGroup.description"
            name="description"
          >
            <div class="group-color-block">
              <textarea
                v-bind="field"
                id=""
                name="description"
                class="text-area"
              ></textarea>
            </div>
          </Field>
        </div>

        <div class="w-2/5 mt-3">
          <label
            class="text-xs uppercase font-semibold text-secondary9"
            for="contactsId"
            >{{ $t('stockGroupBook.form.stocks') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newGroup.stocksId"
            name="stocksId"
            :placeholder="$t('stockGroupBook.form.stocks')"
          >
            <dropdown-stocks
              v-model="newGroup.stocksId"
              v-bind="field"
              name="stocksId"
              :can-add-new="false"
              open-direction="top"
              :option-per-price="false"
              :callback="callbackFunc"
              mode="tags"
              :value="newGroup.stocksId"
              @onUpdated="handleStocksSelection"
            />
          </Field>
        </div>

        <div v-if="group && group.id && canDo" class="mt-5">
          <h3 class="text-md font-bold">{{ $t('global.settings') }}</h3>
          <div
            class="
              book__settings
              flex
              justify-between
              items-center
              border border-error
              rounded
              p-3
              mt-3
              text-xl
            "
          >
            <div>
              <h5 class="text-blackp">
                {{ $t('stockGroupBook.form.deletionTitle') }}
              </h5>
              <h6>{{ $t('stockGroupBook.form.deletionMessage') }}</h6>
            </div>
            <div class="ml-auto">
              <el-popconfirm
                :title="$t('stockGroupBook.form.promptDeletion')"
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                @confirm="deleteGroup"
              >
                <template #reference>
                  <Button
                    variant="custom"
                    class="bg-error text-white hover:bg-error hover:border-0"
                    :disabled="false"
                    :label="$t('stockGroupBook.form.deletionPersist')"
                  >
                  </Button>
                </template>
              </el-popconfirm>
            </div>
            <i
              v-if="false"
              v-tooltip.bottom="
                `Ce titre est utilisé dans un ou plusieurs évènements.`
              "
              class="bi-lock-fill text-warning xxl"
            />
          </div>
        </div>
        <FormActions
          v-if="canDo"
          :errors="errors"
          :meta="meta"
          @reset-form="resetForm"
        />
      </Form>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import {
    CompositionStockAlias,
    OptionStockAlias,
    StockAlias,
    StockGroup
  } from '@/services/api/models'
  import { StockGroupFormData, STOCKTYPE } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { CompanyModel } from '@/services/api/models1'
  import {
    CREATE_COMPANY_STOCK_GROUP,
    EDIT_COMPANY_STOCK_GROUP
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { groupSchema } from '@/utils/schema/group'
  import DropdownStocks from '../../DropDowns/DropdownStocks.vue'
  import ColorPicker from '@/components/ColorPicker/index.vue'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'UplStockGroup',
    components: {
      Form,
      ElPopconfirm,
      Field,
      Input,
      FormActions,
      Button,
      ColorPicker,
      DropdownStocks
    },

    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const stockAliasIds = ref<string[]>([])
      const optionAliasIds = ref<string[]>([])
      const compositionAliasIds = ref<string[]>([])
      const newGroup = ref<StockGroupFormData>({
        name: '',
        colorCode: '',
        description: null,
        stocksId: []
      })

      const resetForm = () => {
        newGroup.value = {
          name: '',
          colorCode: '',
          description: null,
          stocksId: []
        }
      }
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )

      const groupedStocks = computed(() => [
        {
          id: 1,
          name: t('companyStockType.SIMPLE'),
          label: t('companyStockType.SIMPLE'),
          value: 'stocks',
          groups: stocksAliases.value.map((x: any) => ({
            name: x.alias,
            label: x.alias,
            id: x.id
          }))
        },
        {
          id: 2,
          name: t('companyStockType.COMPOSED'),
          label: t('companyStockType.COMPOSED'),
          value: 'optionStock',
          groups: optionStockAliases.value.map((x: any) => ({
            name: x.alias,
            label: x.alias,
            id: x.id
          }))
        },
        {
          id: 3,
          name: t('companyStockType.COMBINED'),
          label: t('companyStockType.COMBINED'),
          value: 'compositionStocks',
          groups: compositionStockAlias.value.map((x: any) => ({
            name: x.alias,
            label: x.alias,
            id: x.id
          }))
        }
      ])

      const modalForm = computed(() => store.getters['organizations/form'])

      const group = computed<StockGroup>(
        () => store.getters['organizations/stockGroup']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const schema = groupSchema

      const initForm = () => {
        store.commit('organizations/SET_CURRENT_STOCK_GROUP', null)
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: true,
          initCurrentForm: true
        })
      }

      const stocks = computed<
        (StockAlias | OptionStockAlias | CompositionStockAlias)[]
      >(() => store.getters['organizations/allStocks'])

      const handleForm = (values: StockGroupFormData) => {
        let edition = false
        let action = CREATE_COMPANY_STOCK_GROUP

        values.stocksId?.forEach((id) => {
          const stock = stocks.value.find((x) => x.id === id)
          if (stock && stock?.type === STOCKTYPE.COMPOSITION)
            compositionAliasIds.value.push(stock.id)
          else if (stock && stock?.type === STOCKTYPE.OPTION)
            optionAliasIds.value.push(stock.id)
          else stockAliasIds.value.push(id)
        })
        const data = {
          ...values,
          stockAliasIds: stockAliasIds.value,
          optionAliasIds: optionAliasIds.value,
          compositionAliasIds: compositionAliasIds.value
        }
        delete data.stocksId
        let formData = {
          companyId: currentCompany.value.id,
          data,
          groupId: ''
        }
        if (group.value && group.value.id) {
          edition = true
          formData['groupId'] = group.value.id
          action = EDIT_COMPANY_STOCK_GROUP
        }

        store.dispatch(`organizations/${action}`, {
          ...formData,
          onError: (error = null) => {
            const message =
              error ||
              t(
                `stockGroupBook.apiResponse.${edition ? 'edit' : 'create'}Error`
              )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('stockGroupBook.form.title'),
              message
            })
          },
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('stockGroupBook.form.title'),
              message: t(
                `stockGroupBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          }
        })
      }

      const deleteGroup = () => {
        store.dispatch(`organizations/DELETE_COMPANY_STOCKS_GROUP`, {
          companyId: currentCompany.value.id,
          groupId: group.value.id,
          onSuccess: () => {
            initForm()
            resetForm()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('stockGroupBook.form.title'),
              message: t(`stockGroupBook.apiResponse.deleted`)
            })
          },
          onError: (error = null) => {
            const message = error || t(`stockGroupBook.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('stockGroupBook.form.title'),
              message
            })
          }
        })
      }

      const handleStocksSelection = (data: any) => {
        newGroup.value.stocksId = data.value ?? []
      }

      const handleReset = () => {
        initForm()
      }

      const getStocks = async () => {
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: currentCompany.value.id
        })
      }

      const callbackFunc = (stock: any) => {
        return !stock.group
      }

      const toggleModal = (state: boolean) =>
        store.dispatch('ui/toggleModal', { display: state })

      watch(group, (newValue) => {
        if (newValue) {
          const simples = newValue.stockAliases || []
          const options = newValue.optionAliases || []
          const compositions = newValue.compositionAliases || []

          newGroup.value = {
            stocksId:
              [...simples, ...options, ...compositions].map((x) => x.id) || [],
            colorCode: newValue.colorCode || null,
            name: newValue.name,
            description: newValue.description || null
          }
        }
      })

      watch(modalForm, (newValues) => {
        if (newValues.initCurrentForm) resetForm()
      })

      onMounted(() => {
        getStocks()
      })

      return {
        newGroup,
        group,
        canDo,
        schema,
        stocks,
        toggleModal,
        handleForm,
        deleteGroup,
        handleReset,
        handleStocksSelection,
        callbackFunc
      }
    }
  })
</script>
<style lang="scss" scoped>
  .text-area {
    @apply resize-none w-full p-2 rounded border border-secondary4 focus:border-primary4 text-secondary9;
    font-size: 0.9em;
  }
</style>
