<template>
  <div v-if="visible" :class="`box position-static box-${type}`">
    <i
      :class="[
        { 'bi-exclamation-triangle-fill': type === 'danger' },
        { 'bi-exclamation-circle-fill': type === 'warning' },
        { 'bi-check-circle-fill': type === 'success' },
        { 'bi-info-circle-fill': type === 'primary' }
      ]"
    />
    <slot>message</slot>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, onMounted, ref } from 'vue'
  export default defineComponent({
    name: 'Box',
    props: {
      type: {
        type: String,
        default: ''
      },
      autoHideAfter: {
        type: Number,
        default: -1
      }
    },
    setup(props) {
      const state = reactive({ isVisible: true })
      const visible = ref(false)
      onMounted(() => {
        if (props.autoHideAfter >= 0) {
          setTimeout(() => {
            state.isVisible = false
          }, props.autoHideAfter)
        }
      })

      return {
        state,
        visible
      }
    }
  })
</script>
