<template>
  <div class="flex justify-center items-center h-screen w-full">
    <div class="flex justify-center items-center h-full w-full">
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    setup() {
      return {}
    }
  })
</script>
