
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import ActionList from '@/components/ActionList/index.vue'
  import LongText from '@/components/LongText/index.vue'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import moment from 'moment'
  import { CompanyModel } from '@/services/api/models1'
  import { truncText } from '@/utils'
  import { MAINACTIONS, ActionListItem } from '@/types'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { useAcl } from 'vue-simple-acl/src'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'ContactCard',
    components: {
      ActionList,
      ContactBadge,
      LongText,
      ElTooltip
    },
    directives: {},
    props: {
      contact: {
        type: Object,
        required: true
      },
      noPadding: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['delete-contact'],
    setup(props, { emit }) {
      const store = useStore()
      const showCardSecondary = ref(true)
      const ContactType = ref(props.contact.type)
      const router = useRouter()

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const display = ref(false)
      const { t } = useI18n()
      const route = useRoute()
      const url = ref('')

      const acl = useAcl()
      const canDelete = computed(
        () =>
          acl.role('admin') ||
          acl.permission('is-manager') ||
          acl.permission('write-contacts')
      )
      const actionsListItems = ref<ActionListItem[]>([
        {
          name: t('global.actions.edit'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.EDIT_CONTACT,
          type: ''
        }
      ])

      if (canDelete.value) {
        actionsListItems.value.push({
          name: t('global.actions.delete'),
          action: 'delete',
          children: [],
          icon: '',
          key: MAINACTIONS.DELETE_CONTACT,
          type: ''
        })
      }

      const openContactModal = () => {
        router.push({
          name: 'company.contact',
          params: {
            id: currentCompany.value?.id,
            contactId: route.params.contactId
          }
        })
      }

      const deleteContact = () => {
        emit('delete-contact', props.contact.id)
      }
      const handleAction = (key: MAINACTIONS) => {
        if (key === MAINACTIONS.EDIT_CONTACT) {
          openContactModal()
        } else if (key === MAINACTIONS.DELETE_CONTACT) {
          deleteContact()
        }
      }
      const loadImage = async () => {
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_PICTURE',
          {
            companyId: currentCompany.value.id,
            contactId: props.contact.id
          }
        )
        if (response) url.value = response
        else url.value = ''
      }

      watch(
        () => props.contact,
        async () => {
          await loadImage()
        }
      )
      onBeforeMount(async () => {
        await loadImage()
      })

      return {
        ContactType,
        showCardSecondary,
        moment,
        currentCompany,
        url,
        display,
        actionsListItems,
        handleAction,
        truncText
      }
    }
  })
